import { useEffect, useState, ChangeEvent } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { accountUpdate } from '../Utils/signUpDescription';
import { UserKindEnum, UserAgencyStateLicense } from '@/types';
import { typographyH3Style, typographyBodyStyle } from '../Utils/styled';
import BannerOnlineProperty from '../Utils/Img/account-update.png';

export const AccountUpdate = ({
  user
}: {
  user: UserAgencyStateLicense;
}) => {
  return (
    <>
      <Box>
        <img
          style={{ width: '100%', maxWidth: '450px', marginTop: '20px', objectFit: 'fill' }}
          src={BannerOnlineProperty}
          alt='banner online property'
        />
      </Box>
      <Stack>
        <Typography variant='h3' sx={typographyH3Style}>
          {accountUpdate.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBodyStyle}>
          {accountUpdate.bodyTitle.description}{' '}
          <span style={{ color: '#1650FF', fontWeight: 600 }}>{user.email}</span>
          {' '}{accountUpdate.bodyTitle.descriptionTwo}{' '}
          <span style={{ color: '#1650FF', fontWeight: 600 }}>{user.nameEn}</span>
          {' '}{accountUpdate.bodyTitle.descriptionThree}{' '}
          <span style={{ color: '#1650FF', fontWeight: 600 }}>{accountUpdate.bodyTitle.descriptionFour}</span>
          {' '}{accountUpdate.bodyTitle.descriptionFive}
        </Typography>
      </Stack>
    </>
  );
};