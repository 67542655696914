import { useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FormControl, FormHelperText, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { googleMapsOptions } from '@/utils/googleMapsOptions';
import { FormInput } from '@/components';

import styles from './PropertyLocation.module.scss';
import { IOffPlanModel } from '@/types';

import { MapUi } from '@/components/Map/Map';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';
import {
  getDepthLabel,
  LocationAutocomplete,
} from '@/components/LocationAutocomplete/LocationAutocomplete';
import { rightArrowSVGString } from '@/components/Ad/LightSlider/assets';

const OPFormHelperText = styled(FormHelperText)`
  margin: 0 0 5px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  span {
    color: var(--secondary, #f00);
  }
`;

interface IPropertyLocationProps {
  setNewValue?: (field: Partial<IOffPlanModel>) => void;
}

export const PropertyLocation = ({ setNewValue }: IPropertyLocationProps) => {
  const { isLoaded } = useJsApiLoader(googleMapsOptions);

  const {
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const formValues = getValues();
  const { addressEn, locationName } = formValues;

  const {
    selectedLocation,
    locations,
    setSelectedLocation,
    setInputAddressValue,
    inputAddressValue,
    setLocations,
  } = useLocationAutocomplete({
    defaultValue: addressEn,
    searchByDistrict: true,
  });

  const updateLocationDetails = (location: LocationModel | null) => {
    setNewValue?.({ addressEn: location?.[location?.kind] || inputAddressValue });
    setSelectedLocation(location);
    setValue(
      'addressEn',
      location ? `${getDepthLabel(location)}, ${location[location.kind]}` : null
    );
    setValue('districtId', location?.id || null);
  };

  const handleClear = () => {
    setSelectedLocation(null);
    setValue('addressEn', null);
    setValue('coordinatesLat', null);
    setValue('coordinatesLong', null);
    setValue('districtId', null)
  };

  const saveUserMarker = (coordinatesLong: string, coordinatesLat: string) => {
    setValue('coordinatesLat', coordinatesLat);
    setValue('coordinatesLong', coordinatesLong);
    clearErrors(['coordinatesLong', 'coordinatesLat']);
  };

  const isMarkerError = !!errors?.['coordinatesLat'];

  return (
    <div>
      {isLoaded && (
        <>
          <div className={styles.group}>
            <FormControl sx={{ width: '100%' }}>
              <div className={styles.locationGroup}>
                <div className={styles.location}>
                  <OPFormHelperText className={styles.label}>
                    Enter District name
                    <span>*</span>
                  </OPFormHelperText>

                  <LocationAutocomplete
                    inputAddressValue={inputAddressValue}
                    setInputAddressValue={setInputAddressValue}
                    locations={locations}
                    handleClear={handleClear}
                    onSelect={updateLocationDetails}
                  />
                </div>
                <FormInput
                  uiVariant='blank'
                  name='locationName'
                  required
                  fullWidth
                  label='Enter building name'
                  placeholder='Enter building name'
                />
              </div>
            </FormControl>
          </div>
          <MapUi
            selectedLocation={selectedLocation}
            source='offPlan'
            setLocations={setLocations}
            isLocation={true}
            saveUserMarker={saveUserMarker}
            locationName={locationName}
            isAddNewLocationVisible={true}
          />
          {isMarkerError && 
            <Typography sx={{ fontSize: 12, textAlign: 'right', color: 'red' }}>
              You need to put a marker on the map
            </Typography>
          }
        </>
      )}
    </div>
  );
};
