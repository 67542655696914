export const messageContainerStyle = () => {
  return {
    alignSelf: 'flex-start',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '65%',
    minWidth: '340px',
    clear: 'both',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
  };
};

export const messageWrapperStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    margin: '0 5px 10px',
    float: 'left',
    flexShrink: '0',
  };
};

export const messageDetailStyle = () => {
  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  };
};

export const messageVideoContainerStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    display: 'flex',
    alignSelf: 'flex-start',
  };
};

export const messageVideoWrapperStyle = () => {
  return {
    display: 'inline-block',
    alignSelf: 'flex-end',
    ' > video': {
      maxWidth: '250px',
      borderRadius: '12px',
      display: 'inherit',
    },
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '25px',
    gap: '2px',
  };
};

export const messageReactionsWrapperStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '5px',
    paddingTop: '6px',
  };
};
