export const modalRowStyle = ({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    '&:hover': {
      backgroundColor: `${theme.backgroundColor.lightBlue3}`,
    },
  };
};

export const nameColumnStyle = () => {
  return {
    display: 'flex',
    gap: '10px',
    width: '350px',
    alignItems: 'center',
  };
};

export const scopeColumnStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    width: '200px',
    i: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  };
};

export const actionsColumnStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    width: '60px',
    i: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  };
};

export const avatarStyle = () => {
  return {
    width: '40px',
    height: '40px',
    flexShrink: '0',
  };
};

export const nameStyle = ({ theme }) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: '500',
    color: `${theme.color.primary}`,
  };
};

export const roleStyle = ({ theme }) => {
  return {
    fontSize: '14px',
    color: `${theme.color.primary}`,
    width: '100px',
  };
};

export const scopeSelectionStyle = ({ theme }) => {
  return {
    width: '138px',
    backgroundColor: `${theme.backgroundColor.white}`,
    border: `1px solid ${theme.borderColor.primary}`,
    borderRadius: '4px',
    color: `${theme.color.primary}`,
    padding: '5px 8px',
    boxShadow: '-3px 2px 8px 0px #0000002B',
  };
};

export const scopeIconStyle = (img, { theme }) => {
  return {
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
  };
};

export const banIconStyle = (img, { theme }) => {
  return {
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
  };
};

export const kickIconStyle = (img, { theme }) => {
  return {
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.red}`,
  };
};
