import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert, Slide } from '@mui/material';

import { State } from '@/types';
import { appSetNotification } from '@/store';

import styles from './NotificationsSnackbar.module.scss';

export const NotificationsSnackbar = (): JSX.Element => {
  const dispatch = useDispatch();
  const { notification } = useSelector(({ app }: State) => app);
  const { type, message, status } = notification;

  const handleCloseError = () => {
    dispatch(appSetNotification());
  };

  return (
    <Snackbar
      open={!!status || !!message}
      autoHideDuration={5000}
      onClose={handleCloseError}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={(props) => <Slide direction='left' {...props} />}
    >
      <Alert
        onClose={handleCloseError}
        severity={type}
        sx={{
          width: '100%',
          fontWeight: '800',
          fontFamily: '"Poppins", sans-serif',
          fontSize: '1.5rem',
        }}
      >
        <div className={styles.notificationTitle}>
          {type}
          {status && ` (Status: ${status})`}
        </div>
        <div className={styles.notificationMessage}>{message || '...'}</div>
        {/* Something went wrong... */}
      </Alert>
    </Snackbar>
  );
};
