import { FormControl, FormHelperText, SxProps, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import styles from './FormInputNumeric.module.scss';

export const FormInputNumeric = ({
  name,
  suffix,
  handleChange,
  handleFocus,
  handleBlur,
  uiVariant,
  placeholder,
  ...otherProps
}: {
  name: string;
  suffix?: string;
  handleChange?: (value: number) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  uiVariant?: 'blank';
} & TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorText = (name: string) => {
    if (name === 'agencyFee') return 'Agency fee % must be more than 0';
    if (name === 'priceFrom') return '"Price from" is a required field';
    if (name === 'priceTo') {
      if (
        errors['priceTo']?.message === '"Price to" must be greater than or equal to "Price from"'
      ) {
        return '"Price to" must be greater than or equal to "Price from"';
      }
      return '"Price to" is a required field';
    }
  };

  const defaultStyles: SxProps =
    uiVariant === 'blank' ? { width: '100%' } : { m: 1, width: '100%', margin: '0 20px 14px 0' };

  return (
    <FormControl sx={defaultStyles}>
      {otherProps.label && (
        <FormHelperText className={styles.label}>
          {otherProps.label}
          {otherProps.required && <span>*</span>}
        </FormHelperText>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            valueIsNumericString={true}
            allowNegative={false}
            placeholder={placeholder}
            decimalScale={suffix ? 1 : 0}
            value={value}
            suffix={suffix}
            isAllowed={(values) => {
              const { value } = values;
              const numericValue = parseInt(value, 10);
              if (suffix) {
                if (numericValue > 100) {
                  return false;
                }
                if (value.startsWith('0') && value.length > 1) {
                  return false;
                }
              }
              return true;
            }}
            onValueChange={({ value }) => {
              onChange(value);
              if (handleChange) {
                handleChange(parseFloat(value));
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={!!errors[name]}
            helperText={errors[name] ? errorText(name) : ''}
          />
        )}
      />
    </FormControl>
  );
};
