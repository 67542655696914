import moment from 'moment';
import { Typography, Stack } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Title } from './styled';
import { NewBuildingModel } from '@/types';
import { Section } from '@/components/Ad/Section';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#ff8e09',
  },
}));

export const ForecastBlock = ({ page }: { page: NewBuildingModel }) => {
  return (
    <Section>
      <Title>Forecast and actual data</Title>
      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Current progress</Typography>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography sx={{ fontSize: '12px' }}>
          <span style={{ color: '#ff8e09' }}>{page?.forecast?.currentProgress}% </span>
          from {moment(page.createdAt).format('DD.MM.YYYY')}
        </Typography>
        <Typography sx={{ fontSize: '12px', opacity: 0.5 }}>
          Last update: {moment(page?.forecast?.lastUpdate).format('DD.MM.YYYY')}
        </Typography>
      </Stack>
      <BorderLinearProgress variant='determinate' value={page?.forecast?.currentProgress} />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography sx={{ fontSize: '12px' }}>
          {`Planned Completion Date: `}
          <span style={{ fontWeight: 600 }}>
            {moment(page?.forecast?.plannedCompletion).format('DD.MM.YYYY')}
          </span>
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#1650ff', textAlign: 'right' }}>
          {`Estimated Completion Date: `}
          <span style={{ fontWeight: 600 }}>
            {moment(page?.forecast?.estimatedCompletion).format('DD.MM.YYYY')}
          </span>
        </Typography>
      </Stack>
    </Section>
  );
};
