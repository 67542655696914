import { Avatar, Stack, Typography } from '@mui/material';

import { UserModel } from '@/types';
import { stringAvatar, getKindLabel } from '@/utils';

import styles from './MemberItem.module.scss';
import { ReactComponent as UserIcon } from './user.svg';

interface MemberModel {
  id: string;
  name: string;
  avatar: string;
  isRegistration: boolean;
  isPayment: boolean;
}

interface MemberItemProps {
  user: UserModel;
  registrationUserCount: number;
  paymentUserCount: number;
}

export const MyInfo = ({
  user: { media, name },
  registrationUserCount,
  paymentUserCount,
}: MemberItemProps) => {
  const avatar = media?.[0]?.fileUrl || undefined;

  return (
    <Stack
      className={styles.myContainer}
      spacing={{ sm: 0.5, xs: 3 }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <Stack className={styles.blockMyAvatar} direction='row'>
        <Stack className={styles.avatarImage}>
          {avatar ? (
            <img src={avatar} alt={name} />
          ) : (
            <Avatar {...stringAvatar(name)} sx={{ width: 48, height: 48 }} />
          )}
        </Stack>
        <Stack direction='column'>
          <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#2A3842' }}>
            {name}
          </Typography>
          <Typography sx={{ fontSize: '16px', color: '#2A384250' }}>My account</Typography>
        </Stack>
      </Stack>

      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#2A384240' }}>
          Total invites
        </Typography>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{
            borderRadius: '8px',
            backgroundColor: '#C5DFFF40',
            padding: '5px 13px',
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <UserIcon />
          </Stack>
          <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#1650FF' }}>
            {registrationUserCount} / {paymentUserCount}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
