import { List, Typography, ListItem, ListItemText } from '@mui/material';

interface IAgreement {
  title: string;
  listItemsFirstLevel: IListItemsFirstLevel[];
}

interface IListItemsFirstLevel {
  value: string;
  listItemsSecondLevel?: IListItemsSecondLevel[];
}

interface IListItemsSecondLevel {
  value: string;
  listItemsThirdLevel?: string[];
}

interface ITableBlock {
    purpose: string;
    description?: string;
    data: string;
    legalBasis: string[];
}

export const agreements = [
  {
    title: '1. Subject Matter',
    listItemsFirstLevel: [
      { value: '1.1. These Terms are designed to govern the relationship arising from your acceptance of the terms and conditions for provision of the Services by means of the Website. Your registration with the Website shall be doubtless evidence for your acceptance of these Terms and adherence to their provisions.' },
      { value: '1.2. Should you decide to withhold from registering with us, you might use the Website in observation of the restrictions and reservations set out in section 7.' },
      { value: '1.3. Where the Customer is a company or any other legal entity, the parties hereto shall enter into a separate written agreement wherein to possibility of incorporating these Terms into such an agreement by reference.' },
    ],
  },
  {
    title: '2. Entire Agreement',
    listItemsFirstLevel: [
      { value: '2.1. Except for the cases mentioned in section 1.3 above, These Terms along with the documents and agreement referred to in forth constitute an entire agreement between you and the Company on the subject matter described hereto. Where section 1.3 applies, the parties might decide whether to incorporate these Terms or decide otherwise.' },
      {
        value: '2.2. Following documents subordinated to these Terms and are ancillary to their provisions:',
        listItemsSecondLevel: [
          { value: '(a) Customer Agreement.' },
          { value: '(b) Privacy Policy.' },
        ],
      },
      { value: '2.3. When you intend to use the Services, you are made to carefully review and accept these Terms and the documents regarded in section 2.2 above by putting a mark in respective checkbox during the registration as Customer.' },
      { value: '2.4. These Terms and their constituents become binding to you upon registering with us as per the procedure set in Customer Agreement.' },
      { value: '2.5. These Terms are without prejudice to any consumer’s rights you might be entitled to pursuant to applicable law.' },
      { value: '2.6. These Terms supersede any prior advertisement, communication, agreements, or protocols delivered or concluded on their subject matter between you and the Company.' },
    ],
  },
  {
    title: '3. Services',
    listItemsFirstLevel: [
      { value: '3.1.	Upon successful completion of the registration and acquisition of the Subscription as per the terms set out in Customer Agreement, you shall become entitled to use the Platform pursuant to the conditions set out in these Terms and Customer Agreement.' },
      { value: '3.2.	The Services contemplate access to the Platform functionality which becomes available to Customers for the sake of promoting, offering, inviting-to-treat regarding the real estate property and associated services only. ' },
      { value: '3.3.	The scope of the Services is dependent on the Subscription plan you opted to wherein to opportunity to change the latter as per the provisions set out in Customer Agreement. ' },
      { value: '3.4.	The Services are carried out on PaaS (Platform as a Service) basis wherein to reservations and limitation of liability referred to in these Terms. ' },
      { value: '3.5.	The Services are not designed for private or household use and the Customers certify that they shall use the Services in the course of commercial or business conduct only.' },
    ],
  },
  {
    title: '4.	Consideration and Payments',
    listItemsFirstLevel: [
      { value: '4.1.	In consideration for the detriment of carrying out the Services, the Customer shall settle the Subscription Fees pursuant to provisions of Customer Agreement. ' },
      { value: '4.2.	Any matters which relate to finance and payments under these Terms are addressed in the Customer Agreement. ' },
    ],
  },
  {
    title: '5.	Registration',
    listItemsFirstLevel: [
      { value: '5.1.	Subject to the Customer Agreement.' },
    ],
  },
  {
    title: '6.	Data Privacy',
    listItemsFirstLevel: [
      { value: '6.1.	We shall use our best endeavours to process your data in accordance with the provisions of the applicable law, and with employ of reasonable safeguards and standards if we required to adhere to any pursuant to the law.' },
      { value: '6.2.	For any further details on how we collect and process your personal data and the associated rights of the data subject, please check our Privacy Policy.' },
    ],
  },
  {
    title: '7.	Intellectual Property',
    listItemsFirstLevel: [
      { value: '7.1.	Except for the User Content, the Company (and its licensors) hereby reserve any rights, title, and interest in copyright, related (neighbouring rights), patents, inventions, designs, trademarks (whether registered or not), trade names, commercial secret, know-how, and any other intellectual property and intangible assets which are brought to you within (or is association) with the Website and Platform, including any text, visual materials, software, audio, and content of any other type, and nothing in these Terms contemplates transfer of rights, title, or interest, or licence whether statutory or implied to you, unless otherwise expressly set forth in these Terms and their constituents. ' },
      { value: '7.2.	Unless otherwise expressly set out by the applicable law, you are not permitted to reproduce, copy, distribute or otherwise disseminate, alter, modify, undergo reverse engineering, or decompile any intellectual property referred to in section 7.1, brought to you by means of Website and Platform, and you agree to withhold from the said actions. ' },
      {
        value: '7.3.	User Content. When User publishes any content by means of the Platform, they: ',
        listItemsSecondLevel: [
          { value: '(a)	grant a worldwide, non-exclusive, royalty-free, limited, sublicensable, irrevocable, and temporary licence to reproduce, adapt, modify, translate, publish, and distribute such a content by means of Platform, making it visible and operatable by the other Users subject to preferences which relate to publication, with the express entitlement to form the extracts of advertisements in any printable format by means of Platform. The Company shall not use User Content for any other reasons than intrinsically bind with the tender of Services, wherein to the Company’s right to retain and log the User Content for the records and defence of legal claims at least three (3) years following User’s account shutdown;' },
          { value: '(b)	represent that the content they upload to the Platform, shall be clear from any rights, claim, or bailment a third party might bring or hold, and that the uploading User shall hold any rights necessary to use the content on the Platform; ' },
          { value: '(c)	warrant that their content shall be compliant with the requirements of the applicable law and does not violate the rules of conduct expressed in these Terms.' },
        ],
      },
    ],
  },
  {
    title: '8.	Customer Misconduct',
    listItemsFirstLevel: [
      { value: '8.1.	You are not permitted to use the Website and / or Platform for any activity which is inappropriate or illegal pursuant to the laws applicable to the Website and / or Platform.' },
      {
        value: '8.2.	Following activities are deemed illegal at all times:',
        listItemsSecondLevel: [
          { value: '(a)	using any automated device, software process, or means to access, retrieve, scrape, or index the Website or any content on the Website.' },
          { value: '(b)	using any device, software, process, or means to interfere or attempt to interfere with the proper working of the Website or Platform.' },
          { value: '(c)	undertaking any action that will impose a burden or make excessive traffic demands on our infrastructure that we consider to be unreasonable or disproportionate Website or Platform usage.' },
          { value: '(d)	attempting to decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website or Platform.' },
          {
            value: '(e)	using or indexing any content or data on the Website or Platform for purposes of:',
            listItemsThirdLevel: [
              '-	constructing or populating a searchable database of properties.',
              '-	building a database of property information; or',
              '-	competing with us in any manner that we have not specifically authorized.',
              '-	transmitting spam, chain letters, contests, junk email, surveys, or other mass messaging, whether commercial in nature or not.',
              '-	using the Website or any content from the Website in any manner which we determine as not reasonable and/or not for the purpose for which it is made available.',
              '-	violating the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right.',
              '-	posing as any person or entity or attempt to solicit money, passwords, or personal information from any person.',
              '-	acting in violation of any such terms or other condition stipulated by us or any applicable law.',
              '-	reproducing, republishing, retransmitting, modifying, adapting, distributing, translating, creating derivative works or adaptation of, publicly displaying, selling, trading, or in any way exploiting the Website or any content on the Website, except as expressly authorized by us.',
              '-	transmitting or attempting to transmit any computer viruses, worms, defects, or other items in a destructive manner.',
              '-	gaining or attempting to gain unauthorized access to the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website; or',
              '-	attacking or attempting to attack the Website via a denial-of-service attack or a distributed denial-of-service attack.',
            ],
          },
        ],
      },
      {
        value: '8.3.	Where you upload User Content to Platform, you warrant and represent that:',
        listItemsSecondLevel: [
          { value: '(a)	you have the lawful right including all necessary licenses, rights, consents, and permissions to use and authorize us to display your User Content;' },
          { value: '(b)	you will not make any User Content that infringe the intellectual property rights of any third party, and you agree to pay all royalties, fees, or other monies payable by reason of any User Content made by you; and' },
          { value: '(c)	you will not make any User Content that are:' },
          { value: '(d)	misleading;' },
          { value: '(e)	deceptive;' },
          { value: '(f)	materially incorrect;' },
          { value: '(g)	likely to cause offense;' },
          { value: '(h)	directly or indirectly involve the advertising or marketing of any products or services;' },
          { value: '(i)	obscene, including pornographic, hateful, racially or ethnically offensive material;' },
          { value: '(j)	defamatory;' },
          { value: '(k)	otherwise unlawful or encourage unlawful conduct; or' },
          { value: '(l)	otherwise inappropriate having regard to the purpose of our Website.' },
        ],
      },
      {
        value: '8.4.	Notwithstanding any other rights and reservations, the Company retains its right to suspend your account or publication for indefinite time if:',
        listItemsSecondLevel: [
          { value: '(a)	your User Content is in breach of any law or regulation;' },
          { value: '(b)	your User Content infringe the intellectual property rights of any third party;' },
          { value: '(c)	we are required to do so by a regulatory body or any relevant authority pursuant to an interim or final take-down notice;' },
          {
            value: '(d)	your User Content is',
            listItemsThirdLevel: [
              '-	misleading or deceptive;',
              '-	inappropriate having regard to the purpose of the Website;',
              '-	likely to cause offense;',
              '-	materially incorrect;',
              '-	obscene;',
              '-	defamatory;',
              '-	otherwise unlawful and/or against the customs or norms of the region in which this Website is referred to; or',
              '-	corrupted, due to the presence of a virus or other disabling code.',
            ],
          },
        ],
      },
      { value: '8.5.	If any kind of the said misconduct was reported, the Company retains its right to suspend provision of the Services as per the provisions set out in Customer Agreement.' },
    ],
  },
  {
    title: '9.	Limitation of Liability',
    listItemsFirstLevel: [
      { value: '9.1.	No agency. Company is not a real estate agency or other agency, or commissioner of any kind to Customers, wherein to no obligations to act in the interests or for the benefit of Customer or follow their mandates or guidance. Nothing in relationship between Company and either Customer could be deemed as an agency or joint venture. ' },
      { value: '9.2.	No advertisement. Our Services contemplate that the Customers are eligible to use the Platform which is delivered as a software wherein to no obligations for the Company to advertise, endorse, or otherwise promote the goods or services offered by Customers by means of Platform to other Customers. Albeit certain functions of the Platform might allow Customers to promote their accounts or offers within the Platform, they are not visible or accessible to any other individual or entity beyond the Platform. ' },
      { value: '9.3.	No intermediary. When you disseminate an invitation to treat, or offer, or accept either of them, or enter into an agreement with the other Customer, either within or beyond the Platform, the Company is neither an intermediary nor an entity responsible to facilitate the contract between its parties, or involved into any further communication between the parties, or participating in any other way except for providing technical means to communicate within the Platform functionality. ' },
      { value: '9.4.	Moderation. The Company might conduct pre-moderation for the offers and content disseminated by Customers within the Platform, wherein to no obligation to do it on regular basis. We rely on your diligence and warranties given pursuant to these Terms in course of doing that. The Company is not responsible for preliminary and post-verification of the details, information, and contents disseminated by our Customers wherein to our reserved right to suspend or take down specific content if we: a) found that it was in breach of the warranties given pursuant to the Terms, or b) receive a court order or any other ruling from a competent body we are made to adhere to. ' },
      { value: '9.5.	No warranty for third-party content. At any times we are not responsible for possible wrongs, misconduct, or offence committed by our Customers within the Platform or beyond, thus not giving a warranty that the information or any other content our Customers provide by means of Platform are correct or aligned with the law. You are responsible for making your own inquiries and we provide no warranty and accept no responsibility for the accuracy or completeness of any information contained within the third-party information and contents. You are responsible for: checking, confirming, and satisfying yourself as to the accuracy of any information; instructing a surveyor and/or obtaining legal advice or other professional consultation that you consider appropriate before committing to any purchase; and ensuring that you act in good faith towards any other parties.' },
      { value: '9.6.	To the extent permitted by law, we will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising out of the use or inability to use the Website or Platform; or the use of or reliance on any content displayed on the Website or Platform.' },
      {
        value: '9.7.	We will not be liable for any loss or damage arising under or in connection with:',
        listItemsSecondLevel: [
          { value: '•	any failures due to software or internet errors or unavailability, or any other circumstances beyond our reasonable control;' },
          { value: '•	any loss of your password or account if caused by a breakdown, error, loss of power, or otherwise caused by or to your computer system and/or your account;' },
          { value: '•	the use of, or inability to use, our Website or Platform;' },
          { value: '•	the reliance on any content or information displayed on our Website or Platform;' },
          { value: '•	any direct, consequential, special, or punitive loss, damage, costs, and expenses;' },
          { value: '•	loss of profit;' },
          { value: '•	loss of business;' },
          { value: '•	loss of reputation;' },
          { value: '•	depletion of goodwill; or' },
          { value: '•	loss of, damage to, or corruption of data.' },
        ],
      },
      { value: '9.8.	Unless we otherwise expressly agree in writing, you agree not to use the Website for any commercial or business purposes.' },
      { value: '9.9.	We do not guarantee that the Website or Platform will be secure or free from errors, bugs, or viruses. We are not liable to you or anyone else for any loss or damage caused by a virus, distributed denial of service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary materials due to your use of the Website or Platform or to your downloading of any content on it, or any website linked to it. You are responsible for configuring your information technology, computer programs, and platform to access the Website or Platform. You should use your own virus protection software.' },
      { value: '9.10.	If you enquire about a property on this Website or Platform, you acknowledge and agree that your details will be sent by email or other communication means, such as WhatsApp, directly to the agent, estate agent, landlord, or developer marketing the property or properties you are enquiring about. We do not accept any liability for any subsequent communications you receive directly from that estate agent, landlord or developer and/or any third party.' },
    ],
  },
  {
    title: '10.	Disclaimer',
    listItemsFirstLevel: [
      { value: 'THE WEBSITE AND PLATFORM ARE PROVIDED “AS IS” AND “AS AVAILABLE.” THE COMPANY DOES NOT WARRANT THE ACCURACY OR COMPLETENESS, OR THE ADEQUACY OF THE WEBSITE AND PLATFORM, AND EXPRESSLY DISCLAIM LIABILITY FOR ERRORS IN OR OMISSIONS FROM THE WEBSITE AND PLATFORM CONTENT. NO WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF THIRD-PARTY RIGHTS OR FREEDOM FROM VIRUSES OR OTHER CODE DEFECTS, IS MADE BY COMPANY IN RELATION TO THE WEBSITE AND PLATFORM ' },
    ],
  },
  {
    title: '11.	Indemnity',
    listItemsFirstLevel: [
      { value: 'You agree to indemnify and hold us and our affiliates (and our officers, agents, partners, and employees) against any and all loss, liability, claim, or demand, including reasonable attorney’s fees, arising out of, or in connection with your use of and access to the Website or making Contributions not in accordance with the Terms.' },
    ],
  },
  {
    title: '12.	Links to the other Websites',
    listItemsFirstLevel: [
      { value: '12.1.	The Website may contain links, hyperlinks, and pointers to third-party products, services, and/or websites that are not affiliated with us and which are provided for your information only. We have no control over the content, products, or services of those sites, or resources of third parties and we do not guarantee or take responsibility for them. The Website may also contain advertising from third parties and we are not responsible, nor do we make any warranties or representations for any misleading or inaccurate advertisements which are the sole responsibility of the advertiser.' },
      { value: '12.2.	Any links or advertisements on the Website should not be interpreted as approval by us of those linked sites or information you may obtain from them. The Website also contains data provided by third parties and we are not responsible for, nor do we make any warranties or representations for any inaccuracies in such data. You agree to release us from any claims or disputes of any kind arising from or in any way connected to such disputes with third parties.' },
      { value: '12.3.	By using the Website, you grant us an irrevocable, worldwide, royalty-free license to commercialize, copy, license to other persons, use, and adapt for any purpose any material you generate or submit to make use of the Website. We do not warrant that the content, links, or sub-domains contained on, or associated with the Website will be always available and accessible to you. Information on our publications, posts, inserts, information, and content should not be regarded as a substitute for professional legal, financial, or real estate advice.' },
    ],
  },
  {
    title: '13.	Changes to the Terms',
    listItemsFirstLevel: [
      { value: 'We may revise these Terms from time to time without notice. Each time you wish to use the Website, please check these Terms to ensure that you understand the terms that apply at that time. In addition, we may also update and change the Website from time to time without notice.' },
    ],
  },
  {
    title: '14.	Governing Law and Jurisdiction',
    listItemsFirstLevel: [
      { value: 'These Terms and any non-contractual obligations arising in connection with these Terms and your access to the Website shall be governed by and construed in accordance with the laws of the Dubai International Financial Centre (DIFC) in the Emirate of Dubai, United Arab Emirates. You agree to submit to the exclusive jurisdiction of the Courts of the Dubai International Financial Centre (DIFC).' },
    ],
  },
  {
    title: '15.	Severability',
    listItemsFirstLevel: [
      { value: 'If any term of these Terms is or may become for any reason invalid or unenforceable at law, the validity and enforceability of the remainder will not be affected.' },
    ],
  },
  {
    title: '16.	Contacts',
    listItemsFirstLevel: [
      { value: 'If you have any queries relating to these Terms or the Service, or you would like to report a misleading or fraudulent activity committed by our Customer, please contact us at the following address: info@onlineproperty.io. ' },
    ],
  },
];

export const privacyFirst = [
  {
    title: '1. Introduction',
    listItemsFirstLevel: [
      { value: '1.1.	This Privacy Policy (the “Policy”) is brought to you by ONLINEPROPERTY FZCO, a company registered in Dubai, UAE, with registration number DSO-FZCO-30673 with its registered place of business at IFZA Dubai area within Dubai Silicon Oasis, Business Park, DDP, premises number 001-32, Dubai, United Arab Emirates, which carries the services to you by mean of this Website and mobile application. ' },
      { value: '1.2.	We are committed to protecting your privacy and handling your data in an open and transparent manner and with the observance of the relevant data protection legislation. ' },
      { value: '1.3.	The Policy provides an overview on how we collect, use, and otherwise process your personal data as data controllers when you sign for our services/ or access our Website and mobile application(s) (the “Apps”). ' },
      { value: '1.4.	This Policy applies to either our Customers or visitors who search across the Website without committing themselves to purchasing our Services. ' },
    ],
  },
  {
    title: '2. Personal data we process',
    listItemsFirstLevel: [
      { value: '2.1.	Depending on whether you are Customer or visitor, we collect and process different categories of personal data, namely: ',
        listItemsSecondLevel: [
          { value: 'a)	Identification Data: username, name, surname, password, data of birth, address, unique identification number, other identification card data, citizenship for the purpose of all the features of the Services. ' },
          { value: 'b)	Contact and Communication Data: phone number, email address, place of residence, saved phone calls and text live chats for the purpose of communication with us and providing Customer support. ' },
          { value: 'c)	Data related to illegal or fraudulent activities: data related to criminal investigations or sanctions against you following any fraudulent or illegal activities. ' },
          { value: 'd)	Financial Transactions Data: the payment method used, bank account number, and other payment details, account financial history (payments log). ' },
          { value: 'e)	Geolocation data: the country, the city, and the ISP from which you access our Services (based on your IP address). ' },
          { value: 'f)	Online Identifiers: cookie and other similar identifiers. ' },
          { value: 'g)	Device and connection data: type of device you use to access the Services. ' },
          { value: 'h)	Online activity data: the data collected via cookies and tracking technologies implemented on the Website and in Apps. ' },
          { value: 'i)	Preferences Data: include the data concerning your permissions given to process personal data requiring your consent. ' },
        ],
     },
    ],
  },
  {
    title: '3. Data sources',
    listItemsFirstLevel: [
      { value: '3.1.	We collect your personal data either from you or from external sources, if it fits the data processing purpose. ' },
      { value: '3.2.	When we collect personal data directly from you, it means that you communicated your personal data to us in writing or verbally, or when we spectate your actions within the Website, Apps or Platform (i.e. you set your preferences in the account, or browse the Website). ' },
      { value: '3.3.	When we collect information from external sources, it means that we might obtain your data from: ',
        listItemsSecondLevel: [
          { value: 'a)	Public sources providing information on sanctions list. ' },
          { value: 'b)	Our vendors whose services are integrated into our platform (i.e., in relation to payments). ' },
          { value: 'c)	Public authorities or institutions which provide information about offences, investigations, judgements etc. ' },
        ],
     },
    ],
  },
  {
    title: '4.	Are you obliged to provide your personal data?',
    listItemsFirstLevel: [
      { value: 'Although you are not generally obliged to provide it, it might be inevitable that you provided your personal data for commencement and execution of commercial relationships associated with the Services you subscribe to. Please be advised that if you did not provide sufficient personal data, we are unable to carry out our Services to you. ' },
    ],
  },
]

export const privacyTable = [
  {
    purpose: 'Registration with the Services, account management and provision of the Services',
    data: 'Identification Data, Contact and communication Data, Financial Transactions Data, Geolocation Data, Device and Connection Data, Preferences Data',
    legalBasis: [
      '(a) Conclusion and performance of a contract; and ',
      '(b) Our legitimate interest in adjusting our Service to the best needs of our customers',
    ],
  },
  {
    purpose: 'Customer support and management / Complaints handling',
    description: 'From time to time, you might bring a complaint or request a customer support from our side which are happy to review or tender, wherein require certain personal data from you to pass the decision on your complaint or resolve your issue',
    data: 'Identification Data, Contact and communication Data, Financial Transactions Data, Geolocation Data, Device and Connection Data; depending on the complaint substance we might request provision for additional personal data within the categories listed in section 2 above.',
    legalBasis: [
      '(a) Performance of a contract;', 
      '(b) compliance with our legal obligations pursuant to the Terms and customer support;', 
      '(c) our legitimate interest in handling and keeping records of requests from authorities related to your complaint, and complaint handling process.',
    ],
  },
  {
    purpose: 'Dispute resolution and litigation.',
    description: 'We might require certain personal data to defend legal claims which might be brought against us by you or on behalf of you. We shall be unable to defend a claim without having record of your personal data referred to in this section',
    data: 'Identification Data, Contact and communication Data, Financial Transactions Data, Geolocation Data, Device and Connection Data, Preferences Data; Data related to illegal or fraudulent activities.',
    legalBasis: ['Our legitimate interest which consists in defending legal claims.'],
  },
  {
    purpose: 'Marketing and advertising Data',
    description: 'We may offer you to become a recipient of our advertising and marketing communication regarding our products, Services, events etc., which aim to raise your awareness about us and our products. However, you are not made to give your consent to receiving the information regarded and may unsubscribe from the advertising at any time by clicking the “unsubscribe” link provided in our messages.',
    data: 'Identification Data, Contact and communication Data, Geolocation Data, Device and Connection Data, Preferences Data',
    legalBasis: ['Your consent to receive the news about us and our Services, advertising and marketing materials and messages, including in-app advertising and pop-up messages'],
  },
  {
    purpose: 'Functioning of IT systems, Security and Abusive Actions. ',
    description: 'When you use the Website or our Services, our systems automatically collect certain Device and Connection Data which are used to prevent and investigate potential abuses in using our systems or Services as well as wo ensure the stability of our system.',
    data: 'Device and Connection Data',
    legalBasis: ['Our legitimate interest to ensure the stability of IT systems, prevention of abusive actions towards them'],
  },
  {
    purpose: 'Cookies and Similar Technologies',
    description: 'We use cookies on our Website and other technologies which serve for either technical means of providing the Services, or analysing the interactions you have with our resources. In addition, we use them for tailored online advertising as set above. ',
    data: 'According to Cookies Policy',
    legalBasis: ['Contract necessity for essential cookies and consent for non-essential ones. '],
  },
  {
    purpose: 'Compliance with regulatory requirements',
    description: 'We are made to observe and adhere to multiple laws and regulations worldwide where our business partners and affiliates are located, wherein to necessity to withhold from establishing business relationships with the individuals and companies under sanctions. Therefore, and in order to comply with the legal obligations imposed, we might process your personal data. ',
    data: 'Identification Data, Contact and communication Data, Financial Transactions Data, Geolocation Data, Device and Connection Data, Preferences Data; Data related to illegal or fraudulent activities.',
    legalBasis: ['Compliance with legal obligations'],
  },
];

export const privacySecond = [
  {
    title: '6. Who receives your personal data',
    listItemsFirstLevel: [
      { value: 'We may disclose your personal data to processors - our business partners and other persons authorised to receive and process your personal data under the reasonable safeguards whether reliant on statute or contract; and to the controllers which are third parties and state authorities if we are explicitly required to do so pursuant to the law or statute. ',
        listItemsSecondLevel: [
          { value: '6.1.	Data processors: ' },
          { value: '6.2.	Public authorities: Law enforcement agencies, courts. ' },
          { value: '6.3.	Other third partes: providers of payment services for processing payments made by Customers; consultants on legal, tax, and financial matters on a “need-to-know” basis; the providers of cookies and similar technologies, whenever such partners act as controllers; other entities if we are required or have a valid legal basis for the disclosure. ' },
        ],
     },
    ],
  },
  {
    title: '7. Outbound transfers',
    listItemsFirstLevel: [
      { value: 'Your personal data may be transferred to third countries wherein to observance of the legal requirements for such a transfer and employ of appropriate safeguards if required to do so pursuant to the law or statute. ' },
    ],
  },
  {
    title: '8. How long do we keep your personal data. ',
    listItemsFirstLevel: [
      { value: 'We shall process your personal data for the reasonable period compatible with the purposes of processing and during the data retention policy we adopted. However, the data retention period constitutes: ',
        listItemsSecondLevel: [
          { value: '(a)	General terms for data retention: until the Customer has an active user account in either Website or Apps. ' },
          { value: '(b)	Documents and information relating to complaints shall be stored for at least two (2) years following the date when complaint was lodged. ' },
          { value: '(c)	The personal data collected for Advertising and Marketing purposes and processed by virtue of your consent, shall be retained and used until you exercise your right to withdraw the consent, or you exercise you right to the data erasure. ' },
          { value: '(d)	The data related to cookies and similar technologies shall be retained according to specific periods set for such a technologies, wherein to the storage period between the period of the browsing session and up to two (2) years. ' },
        ],
     },
    ],
  },
  {
    title: '9. Processing personal data of children',
    listItemsFirstLevel: [
      { value: 'We do not design any Services for persons under the age of eighteen (18) thus we neither collect nor process personal data of children. Where it would be revealed that a Customer or User is under 18 years of age, we shall delete their personal data. ' },
    ],
  },
  {
    title: '10. Your rights',
    listItemsFirstLevel: [
      { value: 'Receive access to your personal data. You can obtain from us confirmation that we are processing your personal data, as well as information regarding the specifics of the processing such as: the purpose, the categories of personal data processed, the recipients of the data, the period for which the data is kept, the existence of the right to rectification, deletion or restriction of processing.' },
      { value: 'This also enables you to e.g. receive a copy of the personal data we hold about you and to check that we are lawfully processing it.' },
      { value: 'Request correction of the personal data we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected.' },
      { value: 'Request erasure of your personal information. This enables you to request us the erasure of your personal data where: (i) the personal data are no longer necessary in relation to the purposes for which we collected and process them; (ii) you withdrew consent for the personal data processing and we have no other legal grounds for the processing; (iii) the personal data are unlawfully processed; respectively (iv) the personal data have to be erased according to the relevant legislation.' },
      { value: 'Object to processing of your personal data. Where we are relying on a legitimate interest and there is something about your particular situation you may object to processing on this ground. If you lodge an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.' },
      { value: 'Withdrawal of the consent. You can withdraw your consent for processing of personal data on this legal basis at any time. Note that any withdrawal of consent shall not affect the lawfulness of processing based on consent before it was withdrawn / revoked by you.' },
      { value: 'Right to data portability. You may request us to provide you with the personal data that you provided to us in a structured, commonly used and machine-readable format where the processing is carried out based on contract or consent. Also, if you expressly request us, we may transmit your personal data to another entity, where technically feasible.' },
      { value: 'Rights regarding automated decision making. You have a number of rights in relation to automated individual decisions that we make. You have the right to (i) express your point of view, and/or (ii) challenge the automated decision, and/or (iii) or request human intervention. If you request human intervention, the (automated) decision will be reviewed by our specialized staff who will be able to modify or adjust the decision (as appropriate).' },
      { value: 'Rights to restriction of processing. You may request the restriction of your personal data processing if : (i) you contest the accuracy of the personal data, for a period enabling us to verify the accuracy of the personal data at issue; (ii) the processing is unlawful and you oppose the erasure of the personal data and request the restriction of their use instead; (iii) we no longer need the personal data for the purposes of the processing, but they are required by you for a legal claim; respectively (iv) if you have objected to processing, pending the verification whether the Company’s legitimate interests as controller override your rights as data subject.' },
      { value: 'TO EXERCISE THE RIGHTS MENTIONED ABOVE, YOU CAN CONTACT US by info@onlineproperty.io ' },
    ],
  },
  {
    title: '11. Right to lodge a complaint',
    listItemsFirstLevel: [
      { value: 'If after complaining to us you still feel that your personal data has not been handled appropriately, according to the law, you can lodge a complaint with the Supervisory authority for personal data processing' },
    ],
  },
  {
    title: '12. Changes to this privacy statement ',
    listItemsFirstLevel: [
      { value: 'We may modify or amend this privacy statement from time to time.' },
      { value: 'We will notify you appropriately when we make changes to this privacy policy and we will amend the revision date at the top of this page. We do however encourage you to review this statement periodically so as to be always informed about how we are processing and protecting your personal information.' },
    ],
  },
]


export const ListBlock = ({ listBlock }: { listBlock: IAgreement }) => {
  const { title, listItemsFirstLevel } = listBlock;
  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
      <List sx={{ pl: { xs: 0, sm: 1 } }}>
        {listItemsFirstLevel.map((listItemFirst: IListItemsFirstLevel, indexFirst: number) => (
          <ListItem key={indexFirst} sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography variant="body1" component="span">{listItemFirst.value}</Typography>
              }
            />
            {listItemFirst.listItemsSecondLevel && (
              <List sx={{ pl: { xs: 0, sm: 1 } }}>
                {listItemFirst.listItemsSecondLevel.map((listItemSecond: IListItemsSecondLevel, indexSecond: number) => (
                  <ListItem key={indexSecond} sx={{ display: 'list-item' }}>
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="span">{listItemSecond.value}</Typography>
                      }
                    />
                    {listItemSecond.listItemsThirdLevel && (
                      <List sx={{ pl: { xs: 0, sm: 1 } }}>
                        {listItemSecond.listItemsThirdLevel.map((listItemThird: string, indexThird: number) => (
                          <ListItem key={indexThird} sx={{ display: 'list-item' }}>
                            <ListItemText
                              primary={
                                <Typography variant="body1" component="span">{listItemThird}</Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};


export const TableBlock = ({ tableBlock }: { tableBlock: ITableBlock }) => {
  const { purpose, description, data, legalBasis } = tableBlock;
  return (
    <>
      <Typography sx={{ fontWeight: 600, margin: '5px 0 0 10px' }}>Purpose:</Typography>
      <Typography sx={{ margin: '5px 0 0 25px' }}>{purpose}</Typography>
      {description && <Typography sx={{ fontStyle: 'italic', margin: '5px 0 0 25px' }}>{description}</Typography>}
      <Typography sx={{ fontWeight: 600, margin: '10px 0 0 10px' }}>Data:</Typography>
      <Typography sx={{ margin: '5px 0 0 25px' }}>{data}</Typography>
      <Typography sx={{ fontWeight: 600, margin: '10px 0 0 10px' }}>Legal basis:</Typography>
      <List sx={{ pl: { xs: 0, sm: 1 } }}>
        {legalBasis.map((list: string, index: number) => (
          <ListItem key={index} sx={{ display: 'list-item' }}>
            <ListItemText
              primary={
                <Typography variant="body1" component="span">{list}</Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};