import { useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import { Typography, Paper, Container, Button, Stack } from '@mui/material';
import html2canvas from 'html2canvas';
import { MyButton } from '@/components';
import { MetricsChurnRateModel, AdminPeriodEnum } from '@/types';

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, height, value } = props;
  if (value === 0) return null;
  return (
    <text
      x={x + width / 2}
      y={y + height}
      fill="#fff"
      textAnchor="middle"
      dy={-6}
    >
      {value}
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  const { value } = payload;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
        {value}
      </text>
    </g>
  );
};

export const GroupedBarChart = ({
  color,
  metricsName,
  metricsData,
  setIsPeriodVisible,
}: {
  metricsData: MetricsChurnRateModel;
  setIsPeriodVisible: (value: boolean) => void;
  metricsName: string;
  color: string;
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const { period, frequency, list } = metricsData;

  const frequencyValues = [
    { label: 'Day', value: AdminPeriodEnum.Day },
    { label: 'Week', value: AdminPeriodEnum.Week },
    { label: 'Two Week', value: AdminPeriodEnum.TwoWeek },
    { label: 'Month', value: AdminPeriodEnum.Month },
    { label: 'Quarter', value: AdminPeriodEnum.Quarter },
  ];

  const frequencyLabel = frequencyValues.find((freq) => freq.value === frequency)?.label;

  const data = list.map((item) => ({
    name: item.date,
    value: item.value,
  }));

  const handleSaveImage = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/jpeg');
        link.download = 'chart.jpg';
        link.click();
      });
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: 25 }}>
        <Typography variant='subtitle1' gutterBottom>
          {`${metricsName} from ${period.startDate} to ${period.endDate} with frequency ${frequencyLabel}`}
        </Typography>
        <div ref={chartRef}>
          <BarChart
            width={800}
            height={450}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 25,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' tick={<CustomizedAxisTick />} />
            <YAxis domain={[0, 'dataMax + 10']} /> {/* Добавлен domain для оси Y */}
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey='value' fill={color}>
              <LabelList dataKey="value" content={renderCustomizedLabel} position="top" />
            </Bar>
          </BarChart>
        </div>
        <Stack direction='row' justifyContent='space-between'>
          <MyButton
            data={{
              buttonName: 'Clear',
              customWidth: '155px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => setIsPeriodVisible(true)}
          />
          <MyButton
            data={{
              buttonName: 'Save as Image',
              customWidth: '155px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleSaveImage}
          />
        </Stack>
      </Paper>
    </Container>
  );
};
