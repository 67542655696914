import { StyledButton } from '@/components/Header/stylesHeader';
import styles from './EmptyList.module.scss';
import Image from './emptyList.png';
import AddRequest from './addRequest.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State, HeaderDialogsEnum, UserKindEnum, UserTariffPlanEnum } from '@/types';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

interface EmptyListProps {
  statusBtn: boolean;
}

export const EmptyList = ({ statusBtn }: EmptyListProps) => {
  const { openDialog, setOpenDialog } = useDialogs();
  const navigate = useNavigate();
  const user = useSelector(({ auth }: State) => auth.user);
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  return (
    <div className={styles.container}>
      <img className={styles.containerImg} src={Image} alt='Empty list' />
      {!statusBtn && (
        <>
          <div>
            There are no requests yet. Create your first request to find properties for your
            clients. Start now!
          </div>
          <StyledButton
            style={{ maxWidth: '220px', width: 'auto', marginTop: '20px', color: '#fff' }}
            type='submit'
            variant='outlined'
            color='primary'
            onClick={() =>
              isTariffFree
                ? handleClickOpenDialog(HeaderDialogsEnum.Tariff)
                : navigate('/client-request/create')
            }
          >
            <img style={{ color: '#fff' }} src={AddRequest} alt='Add client request' />
            Add client request
          </StyledButton>
        </>
      )}
      {statusBtn && (
        <div>No search results. Change the search criteria to display advertisements.</div>
      )}
    </div>
  );
};
