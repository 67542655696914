import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationTypeEnum, State, HeaderDialogsEnum } from '@/types';
import { authSetIsLoggedIn, appSetNotification } from '@/store';
import { Loader, Pagination, MyButton } from '@/components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { adminProvider, chatProvider, sessionProvider } from '@/providers';

import { PaginationType } from '@/types';
import UserMenu from './UserComponents/UserMenu';
import UserTable from './UserComponents/UserTable';
import VerificationRequestsTable from './UserComponents/VerificationRequestsTable';
import BlockUser from '../Dialogs/BlockUser';
import UnBlockUser from '../Dialogs/UnBlockUser';
import BlockHistory from '../Dialogs/BlockHistory';
import ImpersonateUser from '../Dialogs/ImpersonateUser';
import { User } from './types/types';
import EmploymentRequest from '../Dialogs/EmploymentRequest';
import ForeignBrokerVerification from '../Dialogs/ForeignBrokerVerification';
import UserSearch from './UserComponents/UserSearch';
import { useChangeQueryParams } from '@/hooks';

import { Box, Tabs, Tab, styled } from '@mui/material';
import { get } from 'http';

import { dialogUserTypeDataAdmin } from '../../AccountManagement/utils/dialogDescription';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import UnregisteredTable from './UserComponents/UnregisteredTable';

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #2a3842;
  }

  .MuiTabs-flexContainer {
    justify-content: flex-start;
  }

  margin-bottom: 20px;
`;

const StyledTab = styled(Tab)`
  color: #2a3842;
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 24px;
  opacity: 0.5;
  text-transform: none;

  &.Mui-selected {
    opacity: 1;
    color: #2a3842;
  }
`;

export const Users = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { openDialog, setOpenDialog } = useDialogs();

  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const [users, setUsers] = useState<User[]>([]);
  const [userTotal, setUserTotal] = useState<number>(0);

  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const changeQueryParams = useChangeQueryParams('admin/users');
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const [search, setSearch] = useState<string>('');

  const [type, setType] = useState<string>('all');

  const [openBlockUserDialog, setOpenBlockUserDialog] = useState<boolean>(false);
  const [openUnBlockUserDialog, setOpenUnBlockUserDialog] = useState<boolean>(false);
  const [openBlockHistoryDialog, setOpenBlockHistoryDialog] = useState<boolean>(false);
  const [openImpersonateUserDialog, setOpenImpersonateUserDialog] = useState<boolean>(false);
  const [openEmploymentRequestDialog, setOpenEmploymentRequestDialog] = useState<boolean>(false);
  const [openForeignBrokerVerificationDialog, setOpenForeignBrokerVerificationDialog] =
    useState<boolean>(false);

  const [tabValue, setTabValue] = useState<number>(0);

  // non-verified users
  const [nonVerifiedUsers, setNonVerifiedUsers] = useState<User[]>([]);
  const [nonVerifiedUserTotal, setNonVerifiedUserTotal] = useState<number>(0);
  const [nonVerifiedUsersOffset, setNonVerifiedUsersOffset] = useState<number>(0);
  const [nonVerifiedUsersLimit, setNonVerifiedUsersLimit] = useState<number>(10);
  const [nonVerifiedUsersPaginationObj, setNonVerifiedUsersPaginationObj] =
    useState<PaginationType>();

  // unregistred users
  const [unregistredUsers, setUnregistredUsers] = useState<User[]>([]);
  const [unregistredUsersTotal, setUnregistredUsersTotal] = useState<number>(0);
  const [unregistredUsersPaginationObj, setUnregistredUsersPaginationObj] =
    useState<PaginationType>();

  const handleMenuClick = (event: any, userId: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
    setAnchorPosition({ top: event.clientY, left: event.clientX });
  };

  const handleMenuClose = () => {
    setAnchorEl(undefined);
    setAnchorPosition(undefined);
  };

  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | undefined>();

  const getUserList = async (newLimit?: number, newOffset?: number) => {
    const updatedOffset = newOffset !== undefined ? newOffset : offset;
    const { ok, data } = await adminProvider.getUsers(search, newLimit || limit, updatedOffset, type);
    if (ok) {
      setUsers((data as any).items);
      setUserTotal((data as any).total);

      setPaginationObj({
        page: Math.floor(updatedOffset / (newLimit || limit)) + 1,
        offset: offset,
        limit: limit,
        total: (data as any).total,
      });
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  };

  const getNonVerifiedUserList = async (newLimit?: number, newOffset?: number) => {
    const updatedOffset = newOffset !== undefined ? newOffset : offset;
    const { ok, data } = await adminProvider.getUsers(
      search,
      newLimit || limit,
      updatedOffset,
      type,
      false
    );
    if (ok) {
      setNonVerifiedUsers((data as any).items);
      setNonVerifiedUserTotal((data as any).total);

      setNonVerifiedUsersPaginationObj({
        page: Math.floor(updatedOffset / (newLimit || limit)) + 1,
        offset: offset,
        limit: limit,
        total: (data as any).total,
      });
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  };

  const getUnregisteredUserList = async (newLimit?: number, newOffset?: number) => {
    const updatedOffset = newOffset !== undefined ? newOffset : offset;
    const { ok, data } = await adminProvider.unregisteredUser(newLimit || limit, updatedOffset);
    if (ok) {
      setUnregistredUsers((data as any).items);
      setUnregistredUsersTotal((data as any)?.total);

      setUnregistredUsersPaginationObj({
        page: Math.floor(updatedOffset / (newLimit || limit)) + 1,
        offset: offset,
        limit: limit,
        total: (data as any)?.total,
      });
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  };

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setIsLoading(true);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    changeQueryParams({ page: page });
    switch (tabValue) {
      case 0:
        return getUserList(limit, newOffset);
      case 1:
        return getNonVerifiedUserList(limit, newOffset);
      case 2:
        return getUnregisteredUserList(limit, newOffset);
    }
  };

  const handleBanUser = async (reason: string) => {
    const userId = selectedUserId;
    setOpenBlockUserDialog(false);
    setIsLoading(true);
    const { ok, data } = await adminProvider.banUser(userId!, reason);
    if (ok) {
      getUserList();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error when ban user'));
    }
    setIsLoading(false);
  };

  const handleUnBanUser = async () => {
    const userId = selectedUserId;
    setOpenUnBlockUserDialog(false);
    setIsLoading(true);
    const { ok, data } = await adminProvider.unBanUser(userId!);
    if (ok) {
      getUserList();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error when unban user'));
    }
    setIsLoading(false);
  };

  const handleImpersonateUser = async () => {
    const userId = selectedUserId;
    setOpenImpersonateUserDialog(false);
    setIsLoading(true);
    try {
      const data = await adminProvider.impersonate(userId!);
      if (data.chatAuthToken && data.token) {
        await chatProvider.logout();
        await chatProvider.login(data.chatAuthToken);
        sessionProvider.initAuthSession(data.token);
        window.location.href = '/';
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, data.message));
      }
    } catch (error) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error when impersonate user'));
    }
    setIsLoading(false);
  };

  const handleEmploymentRequest = async () => {
    setIsLoading(true);
    getUserList();
  };

  const handleForeignBrokerVerification = async () => {
    setOpenForeignBrokerVerificationDialog(false);
    setIsLoading(true);
    await adminProvider.verifyUser(selectedUserId!);
    getUserList();
    getNonVerifiedUserList();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    const page = queryParams.page ? parseInt(queryParams.page as string) : 1;
    const newOffset = (page - 1) * limit;
    getUserList(limit, newOffset);
    getNonVerifiedUserList(limit, newOffset);
    getUnregisteredUserList(limit, newOffset);
  }, [search, type]);

  useEffect(() => {
    setIsLoading(true);
    const page = queryParams.page ? parseInt(queryParams.page as string) : 1;
    const newOffset = (page - 1) * limit;
    getNonVerifiedUserList(limit, newOffset);
  }, []);

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const handleAddNewUser = () => {
    handleClickOpenDialog(HeaderDialogsEnum.SignUp);
    handleClickOpenDialog(HeaderDialogsEnum.SelectUserType);
  };

  const selectedUser =
    tabValue === 0
      ? users.find((user: User) => user.id === selectedUserId)
      : nonVerifiedUsers.find((user: User) => user.id === selectedUserId);
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <Box
            sx={{
              width: '100%',
              typography: 'body1',
              justifyContent: 'flex-start',
            }}
          >
            <StyledTabs value={tabValue} onChange={handleTabChange} centered>
              <StyledTab label={`Verified users (${userTotal})`} />
              <StyledTab label={`Verification requests (${nonVerifiedUserTotal})`} />
              <StyledTab label={`Unregistered users (${unregistredUsersTotal})`} />
            </StyledTabs>
          </Box>
          {tabValue !== 2 && 
            <UserSearch 
              search={search} 
              tabValue={tabValue} 
              typeUsers={type}
              setTypeUsers={setType}
              setSearch={setSearch} 
              onAddNewUser={handleAddNewUser} 
            />}
          {tabValue === 0 && (
            <>
              <UserTable total={userTotal} users={users} handleMenuClick={handleMenuClick} />
              {paginationObj?.total && paginationObj?.total > 0 ? (
                <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
              ) : null}
            </>
          )}
          {tabValue === 1 && (
            <>
              <VerificationRequestsTable
                total={nonVerifiedUserTotal}
                users={nonVerifiedUsers}
                handleMenuClick={handleMenuClick}
              />
              {nonVerifiedUsersPaginationObj?.total && nonVerifiedUsersPaginationObj?.total > 0 ? (
                <Pagination
                  paginationObj={nonVerifiedUsersPaginationObj}
                  onChangePage={handleChangePage}
                />
              ) : null}
            </>
          )}
          {tabValue === 2 && (
            <>
              <UnregisteredTable
                users={unregistredUsers}
                handleMenuClick={handleMenuClick}
              />
              {unregistredUsersPaginationObj?.total && unregistredUsersPaginationObj?.total > 0 ? (
                <Pagination
                  paginationObj={unregistredUsersPaginationObj}
                  onChangePage={handleChangePage}
                />
              ) : null}
            </>
          )}
          <UserMenu
            anchorEl={anchorEl}
            anchorPosition={anchorPosition}
            chatId={users.find((user: User) => user.id === selectedUserId)?.supportChatId}
            isBlocked={selectedUser?.status === 'blocked'}
            isVerificationEnabled={
              selectedUser?.kind === 'foreign' && selectedUser?.verified === false
            }
            handleMenuClose={handleMenuClose}
            handleBlockUserDialog={() => {
              setOpenBlockUserDialog(true);
            }}
            handleUnBlockUserDialog={() => {
              setOpenUnBlockUserDialog(true);
            }}
            handleBlockHistoryDialog={() => {
              setOpenBlockHistoryDialog(true);
            }}
            handleImpersonateUserDialog={() => {
              setOpenImpersonateUserDialog(true);
            }}
            handleEmploymentRequestDialog={() => {
              setOpenEmploymentRequestDialog(true);
            }}
            handleVerifyUserDialog={() => {
              setOpenForeignBrokerVerificationDialog(true);
            }}
          />
          <BlockUser
            open={openBlockUserDialog}
            userName={users.find((user: any) => user.id === selectedUserId)?.name!}
            onConfirm={handleBanUser}
            onClose={() => {
              setOpenBlockUserDialog(false);
            }}
          />
          <UnBlockUser
            open={openUnBlockUserDialog}
            userName={users.find((user: any) => user.id === selectedUserId)?.name!}
            onConfirm={handleUnBanUser}
            onClose={() => {
              setOpenUnBlockUserDialog(false);
            }}
          />
          <BlockHistory
            open={openBlockHistoryDialog}
            userId={selectedUserId!}
            onClose={() => setOpenBlockHistoryDialog(false)}
          />
          <ImpersonateUser
            open={openImpersonateUserDialog}
            userName={users.find((user: any) => user.id === selectedUserId)?.name!}
            onConfirm={handleImpersonateUser}
            onClose={() => {
              setOpenImpersonateUserDialog(false);
            }}
          />
          <EmploymentRequest
            open={openEmploymentRequestDialog}
            selectedUserId={selectedUserId!}
            onConfirm={handleEmploymentRequest}
            onClose={() => {
              setOpenEmploymentRequestDialog(false);
            }}
          />
          <ForeignBrokerVerification
            open={openForeignBrokerVerificationDialog}
            onConfirm={handleForeignBrokerVerification}
            onClose={() => {
              setOpenForeignBrokerVerificationDialog(false);
            }}
          />
        </div>
      )}
    </>
  );
};
