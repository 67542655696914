import queryString from 'query-string';

import {
  ResponseType,
  TeamGetLinkResponseType,
  TeamGetAgencyInfoByLinkResponseType,
  PaginationType,
  TeamGetAllMembersResponseType,
  TeamGetAllRequestsResponseType,
  TeamGetMyRequestResponseType,
  TeamGetAllAnnouncersResponseType,
} from '@/types';
import { apiClient } from '@/providers';

export const teamProvider = {
  getMembers: async ({ limit, offset }: PaginationType): Promise<ResponseType> => {
    const params = queryString.stringify({ limit, offset });
    const response = await apiClient(`/user/me/employment/team?${params}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetAllMembersResponseType;
    return { ...response, data };
  },

  getRequests: async ({ limit, offset }: PaginationType): Promise<ResponseType> => {
    const params = queryString.stringify({ limit, offset });
    const response = await apiClient(`/user/me/employment/application?${params}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetAllRequestsResponseType;
    return { ...response, data };
  },

  getLink: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/employment/link', 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetLinkResponseType;
    return { ...response, data };
  },

  refreshLink: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/employment/link', 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetLinkResponseType;
    return { ...response, data };
  },

  getAgencyInfoByLink: async (code: string): Promise<ResponseType> => {
    const params = queryString.stringify({ code });
    const response = await apiClient(`/user/me/invite/employment/info?${params}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetAgencyInfoByLinkResponseType;
    return { ...response, data };
  },

  inviteToAgencyByLink: async (code: string): Promise<ResponseType> => {
    const params = queryString.stringify({ code });
    const response = await apiClient(`/user/me/employment/invite?${params}`, 'GET');

    return { ...response, data: null };
  },

  getMyRequest: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/employment/request', 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetMyRequestResponseType;
    return { ...response, data };
  },

  sendMyRequest: async (registryId: string): Promise<ResponseType> => {
    const params = queryString.stringify({ registryId });
    const response = await apiClient(`/user/me/employment/request?${params}`, 'PUT');

    return { ...response, data: null };
  },

  cancelMyRequest: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/employment/request', 'DELETE');

    return { ...response, data: null };
  },

  removeMyInvite: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/employment/team', 'DELETE');

    return { ...response, data: null };
  },

  cancelMemberRequest: async (requestId: string): Promise<ResponseType> => {
    const response = await apiClient(`/user/me/employment/application/${requestId}/reject`, 'GET');

    return { ...response, data: null };
  },

  applyMemberRequest: async (requestId: string): Promise<ResponseType> => {
    const response = await apiClient(`/user/me/employment/application/${requestId}/accept`, 'GET');

    return { ...response, data: null };
  },

  removeMember: async (memberId: string): Promise<ResponseType> => {
    const response = await apiClient(`/user/me/employment/team/${memberId}`, 'DELETE');

    return { ...response, data: null };
  },

  getAnnouncers: async (): Promise<ResponseType> => {
    const params = queryString.stringify({ limit: 50, offset: 0 });
    const response = await apiClient(`/user/me/employment/announcer?${params}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetAllAnnouncersResponseType;
    return { ...response, data };
  },

  addMemberToTeamTariff: async (brokersIds: string[]) => {
    const response = await apiClient('/subscription/add-brokers', 'POST', { brokersIds: brokersIds });
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  removeMemberFromTeamTariff: async (brokersIds: string[]) => {
    const response = await apiClient('/subscription/delete-brokers', 'POST', { brokersIds: brokersIds });
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },
};
