import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MyButton, SectionHeader, SectionTitle } from '@/components';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Link
} from '@mui/material';
import { FormCheckboxWithWatch } from '@/components/uiComponents/FormCheckbox/FormCheckboxWithWatch';
import { AdMediaType, CreateNewPlanType } from '@/types';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfPreviewCard } from './PdfSettingsComponents/PdfPreviewCard/PdfPreviewCard';
import { IUnit } from '@/types/offplans';

export const UnitsPdfSettings = ({
  showMyContactsCheckbox,
  onSubmit,
  pdfFile,
  isViewPdfVisible,
  hideViewPdf,
  units
}: {
  showMyContactsCheckbox?: boolean;
  images?: AdMediaType[];
  onSubmit: (data: Record<string, boolean>) => void;
  pdfFile: Blob | null
  isViewPdfVisible: boolean
  hideViewPdf: () => void;
  units?: IUnit[];
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const pdfPreviewVariants = [
    { name: 'classic-view', label: `Classic View (include 5 photo's)` },
    // {
    //   name: 'all-in-one-view',
    //   label: `All-in-One View (include 3 photo's)`,
    // },
    // { name: 'lux-view', label: `Lux View (include 3 photo's)` },
    // { name: 'one-page', label: `Onepage View (include 3 photo's)` },
    // { name: 'portable-view', label: 'Mobile Version (not for print)' },
  ];

  const form = useForm({
    defaultValues: {
      radioOption: 'classic-view',
    },
  });


  const checkboxOptions = [
    {
      label: 'Show my contacts',
      name: 'brokerDetailsShow',
      showCheckbox: showMyContactsCheckbox,
      checkedByDefault: true,
    },
    {
      label: 'Show agency contacts',
      name: 'agencyDetailsShow',
      showCheckbox: true,
      checkedByDefault: false,
    },
    {
      label: 'Show price',
      name: 'showPrice',
      showCheckbox: true,
      checkedByDefault: true,
    },
  ];

  return (
    <>
      <FormProvider {...form}>
        {/* @ts-ignore */}
        <form onSubmit={form.handleSubmit(onSubmit)} onChange={() => isViewPdfVisible && hideViewPdf()}>
          <SectionHeader title={'Save as PDF'} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '56px' }} />
          <Stack sx={{ marginTop: '40px', gap: '20px' }}>
            <SectionTitle variant='blank' number={1} title='PDF settings' />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0 58px' }}>
              {checkboxOptions.map((option, i) =>
                option.showCheckbox ? (
                  <FormCheckboxWithWatch
                    checked={option.checkedByDefault}
                    label={option.label}
                    name={option.name}
                    key={option.name + i}
                  />
                ) : null
              )}
            </Box>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              marginTop: '56px',
            }}
          >
            <SectionTitle variant='blank' number={2} title='PDF Preview' />
            <Controller
              name='radioOption'
              control={form.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Grid container spacing={2}>
                    {pdfPreviewVariants.map((variant) => (
                      <Grid item key={variant.name}>
                        <Grid container direction='column'>
                          <PdfPreviewCard cardName={variant.name} />
                          <FormControlLabel
                            value={variant.name}
                            control={<Radio disableRipple />}
                            label={<Typography variant='body1'>{variant.label}</Typography>}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              )}
            />
          </Stack>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
            style={{ marginTop: '20px' }}
          >
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              {isViewPdfVisible ? <MyButton
                onClick={() => pdfFile && window.open(URL.createObjectURL(pdfFile))}
                data={{
                  buttonName: 'View PDF',
                  variant: 'contained',
                  sx: { height: '40px' },
                }}
              /> : 
              <MyButton
                data={{
                  buttonName: 'Save',
                  variant: 'contained',
                  buttonType: 'submit',
                  sx: { height: '40px' },
                }}
              />
               } 


            </Box>
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              <MyButton
                data={{
                  buttonName: 'Cancel',
                  variant: 'outlined',
                  styleType: 'cancel',
                  sx: { height: '40px' },
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};
