import { ISubscriptionsTable } from '@/types';

const subscriptionsTable: ISubscriptionsTable = {
  header: [
    'Subscription ID',
    'User Name',
    'Tariff Name',
    'Sub. Status',
    'Start Date',
    'End Date',
    'User ID',
  ],
  body: ['subId', 'userName', 'tariffPlan', 'status', 'startDate', 'endDate', 'userId'],
};

const transactionsTable: ISubscriptionsTable = {
  header: [
    'Transaction ID',
    'User ID',
    'User Email',
    'Tariff Name',
    'Amount',
    'Currency',
    'Payment Status',
    'Payment Date',
  ],
  body: [
    'transId',
    'userId',
    'userEmail',
    'tariffPlan',
    'amount',
    'currency',
    'status',
    'paymentDate',
  ],
};

const paymentHistoryTable: ISubscriptionsTable = {
  header: [
    'Transaction ID',
    'Amount',
    'Currency',
    'Payment Status',
    'Payment Date',
    'Payment Method',
  ],
  body: [
    'transId',
    'amount',
    'currency',
    'status',
    'paymentDate',
    'paymentMethod',
  ],
};

const tariffPlanTable: ISubscriptionsTable = {
  header: [
    'Name',
    'Pricing',
    'Interval',
    'Date of creation',
    'Update date',
  ],
  body: [
    'name',
    'price',
    'interval',
    'createdAt',
    'updatedAt',
  ],
}

export { transactionsTable, subscriptionsTable, paymentHistoryTable, tariffPlanTable };
