import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { TooltipComponent } from '@/components';
import { IMyLinkProps } from '../types';
import { StyledLink } from '../styled';

export const MyLink: FC<IMyLinkProps> = ({ data, disabled, onClick, onClickSecondLink }) => {
  const {
    label,
    labelSecond,
    tooltip,
    name,
    passiveLabel,
    passiveLabelFinish,
    fontSize,
    fontWeight,
    alignment,
    dataQa,
  } = data;

  return (
    <Stack
      direction='row'
      justifyContent={alignment || 'flex-end'}
      alignItems='center'
      display='flex'
    >
      <Typography
        data-qa={dataQa}
        style={{
          textAlign:
            name === 'error' ||
            name === 'error409' ||
            name === 'error400' ||
            name === 'signUp' ||
            name === 'logIn'
              ? 'center'
              : 'right',
          color:
            name === 'error' || name === 'error409' || name === 'error400' ? '#FF0000' : '#2A3842',
          fontSize: fontSize || '12px',
        }}
      >
        {passiveLabel}{' '}
        <StyledLink key={name} to='#' onClick={onClick} fontSize={fontSize} fontWeight={fontWeight}>
          {label}
        </StyledLink>{' '}
        {passiveLabelFinish}
        {labelSecond && (
          <StyledLink
            key={`${name}+1`}
            to='#'
            onClick={onClickSecondLink}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {' '}
            {labelSecond}
          </StyledLink>
        )}
      </Typography>
      {tooltip && name && <TooltipComponent tooltip={tooltip} name={name} />}
    </Stack>
  );
};
