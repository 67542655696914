import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/system';
import { Box, IconButton, MenuItem, Select, TextField } from '@mui/material';

const Wrapper = styled('div')({
  width: '100%',
  background: '#F9FCFF',
  borderRadius: 20,
  border: '1px #E9EEF1 solid',
  paddingBottom: 20,
  marginBottom: 20,
});

const Header = styled('div')({
  borderBottom: '1px #E9EEF1 solid',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 20,
  padding: '0 30px',
  paddingBottom: 15,
});

const Complaint = styled('span')({
  color: '#2A3842',
  fontSize: 18,
  fontFamily: 'Poppins',
  fontWeight: '600',
});

const ComplaintBlue = styled('span')({
  color: '#1650FF',
});

const Date = styled('div')({
  color: '#2A3842',
  fontSize: 16,
  fontWeight: '400',
  opacity: 0.5,
});

const Text = styled('div')({
  color: '#2A3842',
  fontSize: 16,
  fontWeight: '400',
  paddingTop: 5,
});

const Building = styled('div')({
  color: '#2A3842',
  fontSize: 18,
  fontWeight: '600',
  paddingTop: 5,
});

const Body = styled('div')({
  borderBottom: '1px #E9EEF1 solid',
  marginTop: 20,
  padding: '0 30px',
  paddingBottom: 15,
});

const Footer = styled('div')({
  marginTop: 20,
  padding: '0 30px',
});

const ActionBlock = styled('span')({
  color: '#2A3842',
  fontSize: 16,
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  marginLeft: 10,
});

export const Comment = (): JSX.Element => {
  return (
    <Wrapper>
      <Header>
        <Complaint>
          Complaint: <ComplaintBlue>Fraud, Spam</ComplaintBlue>
        </Complaint>
        <Box
          sx={{
            width: {
              sm: '166px',
              marginLeft: 'auto',
            },
          }}
        >
          Nicolas Kage
        </Box>
      </Header>
      <Body>
        <Date>July 10, 2023, 2:30 p.m</Date>
        <Text>
          2 days ago I went to see this object. It is beautiful inside, there is furniture,
          renovation, parking, swimming pool. I liked it, but decided to look for housing in another
          area. 4 days ago I went to see this object. 2 days ago I went to see this
        </Text>
        <Building>Building by the sea</Building>
      </Body>
      <Footer>
        <ActionBlock
          onClick={() => {
            console.log('Open comment');
          }}
        >
          Open comment
        </ActionBlock>
        <ActionBlock
          onClick={() => {
            console.log('Delete');
          }}
        >
          Delete
        </ActionBlock>
        <ActionBlock
          onClick={() => {
            console.log('Reject');
          }}
        >
          Reject
        </ActionBlock>
      </Footer>
    </Wrapper>
  );
};
