import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Radio, Typography } from '@mui/material';
import {
  ActivityKindEnum,
  AdFieldCurrencyEnum,
  AdFieldRentFrequencyEnum,
  CompletionStatusEnum,
  RentPeriodEnum,
  OffPlanStatusEnum,
  AdFormModel,
} from '@/types';
import { FormInputNumeric, FormSelect, SectionTitle } from '@/components';

import styles from './PropertyPrice.module.scss';

export const PropertyPriceRent = ({
  isPropertyTypeVisible,
  setIsPropertyTypeVisible,
  setIsLocationVisible,
  setIsBtnVisible,
  isPageChanged,
  setIsPageChanged,
  errorRentPrice,
  setErrorRentPrice,
}: {
  isPropertyTypeVisible: boolean;
  setIsPropertyTypeVisible: (value: boolean) => void;
  setIsLocationVisible: (value: boolean) => void;
  setIsBtnVisible: (value: boolean) => void;
  isPageChanged: boolean;
  setIsPageChanged: (value: boolean) => void;
  errorRentPrice: boolean;
  setErrorRentPrice: (value: boolean) => void;
}) => {
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const formValues = getValues();
  const { defaultRentFrequency, priceYearly, priceMonthly, priceWeekly, priceDaily } = formValues;

  const [selectedValuePrice, setSelectedValuePrice] = useState<string>('');
  const [priceYearlyValue, setPriceYearlyValue] = useState<number | null | undefined>();
  const [priceMonthlyValue, setPriceMonthlyValue] = useState<number | null | undefined>();
  const [priceWeeklyValue, setPriceWeeklyValue] = useState<number | null | undefined>();
  const [priceDailyValue, setPriceDailyValue] = useState<number | null | undefined>();
  const [errorsName, setErrorsName] = useState<string | null>();

  useEffect(() => {
    setSelectedValuePrice(defaultRentFrequency || '');
    setPriceYearlyValue(priceYearly);
    setPriceMonthlyValue(priceMonthly);
    setPriceWeeklyValue(priceWeekly);
    setPriceDailyValue(priceDaily);
  }, []);

  useEffect(() => {
    const selectedPrice = {
      [RentPeriodEnum.Yearly]: priceYearlyValue,
      [RentPeriodEnum.Monthly]: priceMonthlyValue,
      [RentPeriodEnum.Weekly]: priceWeeklyValue,
      [RentPeriodEnum.Daily]: priceDailyValue,
    }[selectedValuePrice];
    if (!isPageChanged) {
      if (selectedPrice && selectedPrice > 0) {
        setIsPropertyTypeVisible(true);
        setErrorRentPrice(false);
      } else {
        // setIsPropertyTypeVisible(false);
        // setIsLocationVisible(false);
        // setIsBtnVisible(false);
        setErrorRentPrice(true);
        const name = rentFrequencyValues.filter((value) => value.value === selectedValuePrice)[0]?.name;
        setErrorsName(name);
        // setValue('propertyKind', null);
      }
    }
  }, [priceYearlyValue, priceMonthlyValue, priceWeeklyValue, priceDailyValue, selectedValuePrice]);

  const currencyValues = [
    { label: 'AED', value: AdFieldCurrencyEnum.AED },
    { label: 'USD', value: AdFieldCurrencyEnum.USD },
  ];

  const rentFrequencyValues = [
    { label: 'Year', value: RentPeriodEnum.Yearly, name: 'priceYearly' },
    { label: 'Month', value: RentPeriodEnum.Monthly, name: 'priceMonthly' },
    { label: 'Week', value: RentPeriodEnum.Weekly, name: 'priceWeekly' },
    { label: 'Day', value: RentPeriodEnum.Daily, name: 'priceDaily' },
  ];

  useEffect(() => {
    if (errors['defaultRentFrequency'] && Object.keys(errors).length === 1) {
      const container = document.querySelector(`.${styles.rentFrequencyContainer}`);
      if (container instanceof HTMLElement) {
        container.focus();
        setTimeout(() => {
          container.focus();
        }, 100);
      }
    }
  }, [errors]);

  const handleChangeRentPrice = (newValue: number, radioValue: RentPeriodEnum, name: string) => {
    if (selectedValuePrice === '') {
      setSelectedValuePrice(radioValue);
      setValue('defaultRentFrequency', radioValue);
    }
    if (name === 'priceYearly') {
      setPriceYearlyValue(newValue);
      setValue('priceYearly', newValue);
    }
    if (name === 'priceMonthly') {
      setPriceMonthlyValue(newValue);
      setValue('priceMonthly', newValue);
    }
    if (name === 'priceWeekly') {
      setPriceWeeklyValue(newValue);
      setValue('priceWeekly', newValue);
    }
    if (name === 'priceDaily') {
      setPriceDailyValue(newValue);
      setValue('priceDaily', newValue);
    }
    if (isPageChanged) {
      setIsPageChanged(false);
    }
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValuePrice(event.target.value);
    setValue('defaultRentFrequency', event.target.value);
    if (isPageChanged) {
      setIsPageChanged(false);
    }
  };

  return (
    <div className={styles.container}>
      <SectionTitle number={2} title='Information about rental period' />
      <div className={styles.singleItem}>
        <div className={styles.currency}>
          <FormSelect
            uiVariant='blank'
            name='currency'
            data={currencyValues}
            defaultValue={currencyValues[0].value}
            label='Currency'
            required
          />
        </div>
      </div>

      <div className={styles.frequencyContainer}>
        <p className={styles.label}>
          Period (Choose only one rental period; others are optional)
          <span>*</span>
        </p>
        <div className={`${styles.rentFrequencyContainer}`} tabIndex={-1}>
          {rentFrequencyValues?.map((item, index) => {
            return (
              <div
                key={index}
                className={`${styles.periodContainer}
                ${
                  errors['defaultRentFrequency']
                    ? styles.errorPeriodContainer
                    : item.value === selectedValuePrice
                      ? styles.colorPeriodContainer
                      : styles.grayPeriodContainer
                }`}
              >
                <div className={styles.name}>{item.label}</div>
                <div className={styles.periodPrice}>
                  <FormInputNumeric
                    uiVariant='blank'
                    name={item.name}
                    placeholder='Enter price'
                    handleChange={(e) => handleChangeRentPrice(e, item.value, item.name)}
                    error={errorRentPrice}
                    errorsName={errorsName}
                  />
                </div>
                <div className={styles.radio}>
                  <Radio
                    disableRipple
                    checked={selectedValuePrice === item.value}
                    onChange={handleChangeRadio}
                    value={item.value}
                  />
                  <Typography sx={{ fontSize: '15px', fontWeight: 600, marginTop: '-5px' }}>
                    Default price
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
        {errors['defaultRentFrequency'] && (
          <p className={styles.error}>Please fill in the rental period field</p>
        )}
      </div>
    </div>
  );
};
