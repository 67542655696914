import { useNavigate } from 'react-router-dom';

import {
  StyledContainer,
  StyledTitle,
  StyledDescription,
  StyledButton,
} from './stylesPageNotFound';
import NotFoundImg from './notFound.png';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <img
        style={{ width: '100%', maxWidth: '440px', objectFit: 'cover' }}
        src={NotFoundImg}
        alt='Page not found'
      />
      <div style={{ maxWidth: '440px', textAlign: 'center' }}>
        <StyledTitle>Page not found</StyledTitle>
        <StyledDescription>
          Oops! The page you are looking for cannot be found. It may have been moved, deleted, or
          never existed in the first place. Please double-check the URL or return to our homepage to
          explore other options.
          <br />
          <br />
          If you need further assistance, feel free to contact our support team.
        </StyledDescription>
        <StyledButton variant='contained' color='primary' onClick={() => navigate('/')}>
          Main page
        </StyledButton>
      </div>
    </StyledContainer>
  );
};
