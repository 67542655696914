import { Divider, Stack, Typography, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { MyDialog } from '@/components';
import { ISubscription, ISubscriptionsTable } from '@/types';
import { TransactionsTable } from '../TransactionsTable';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  paymentInfo: ISubscription;
  paymentHistoryTable: ISubscriptionsTable;
}

interface Payment {
  name: string;
  title: string;
  value: string;
  width: string;
}

const StyleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '700',
  color: '#2A3842',
  marginBottom: '10px',
  textTransform: 'uppercase',
  padding: '15px 0 0',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const PaymentInformationDialog: React.FC<DialogProps> = ({ open, onClose, paymentInfo, paymentHistoryTable }) => {

  const paymentInformation: Payment[] = [
    {
      name: 'subId',
      title: 'Subscription ID',
      value: paymentInfo.subId,
      width: '430px',
    },
    {
      name: 'userName',
      title: 'User Name',
      value: paymentInfo.userName,
      width: '440px',
    },
    {
      name: 'tariffPlan',
      title: 'Tariff Name',
      value: paymentInfo.tariffPlan,
      width: '180px',
    },
    {
      name: 'status',
      title: 'Sub. Status',
      value: paymentInfo.status,
      width: '160px',
    },
    {
      name: 'startDate',
      title: 'Start Date',
      value: paymentInfo.startDate,
      width: '160px',
    },
    {
      name: 'endDate',
      title: 'End Date',
      value: paymentInfo.endDate,
      width: '160px',
    },
    {
      name: 'userId',
      title: 'User ID',
      value: paymentInfo.userId,
      width: '160px',
    },
  ];

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Payment information'
      width='950'
      titleAlign='left'
    >
      <Divider />
      <Stack direction='column' spacing={2} alignItems='flex-start' sx={{ marginTop: '15px' }}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {paymentInformation.slice(0, 2).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {paymentInformation.slice(2, 7).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
      </Stack>
      <Stack direction='column' spacing={2} alignItems='flex-start' sx={{ marginTop: '15px' }}>
        <StyleDialogTitle>
          Payment History
        </StyleDialogTitle>
        <TransactionsTable
          transactionsTable={paymentHistoryTable}
        />
      </Stack>
    </MyDialog>
  );
};

const InfoBlock = ({ payment }: { payment: Payment }) => {
  return (
    <Stack direction='column' alignItems='flex-start'>
      <Typography>{payment.title}</Typography>
      <Stack
        sx={{
          backgroundColor: '#F8F8F8',
          borderRadius: '12px',
          border: '1px solid #E9EEF1',
          width: payment.width,
          height: '40px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingLeft: '10px',
        }}
      >
        <Typography sx={{ color: '#2A384270' }}>{payment.value}</Typography>
      </Stack>
    </Stack>
  );
};
