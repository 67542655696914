import { useSelector } from 'react-redux';

import { State, UserKindEnum } from '@/types';
import { Dashboard, PageNotFound, Team } from '@/components';

export const MyTeamPage = () => {
  const { kind } = useSelector(({ auth }: State) => auth.user);

  return (
    <Dashboard openLogIn>{kind === UserKindEnum.Agency ? <Team /> : <PageNotFound />} </Dashboard>
  );
};
