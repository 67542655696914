import { IPersonalInfo } from './types';

const brokerPersonalInfo: IPersonalInfo = {
  name: 'broker',
  groupSizes: [3, 3, 3, 3, 1, 1],
  data: [
    {
      name: 'gender',
      label: 'Gender',
      icon: 'Gender',
      width: '25%',
    },
    {
      name: 'nationality',
      label: 'Nationality',
      icon: 'Nationality',
      width: '30%',
    },
    {
      name: 'activityKinds',
      label: 'Specialization',
      icon: 'Specialization',
      width: '45%',
    },
    {
      name: 'telegram',
      label: 'Telegram',
      icon: 'Telegram',
      width: '25%',
    },
    {
      name: 'whatsapp',
      label: 'Whatsapp',
      icon: 'Whatsapp',
      width: '30%',
    },
    {
      name: 'additionalPhones',
      label: 'Phone number',
      icon: 'PhoneNumber',
      width: '45%',
    },
    {
      name: 'linkedin',
      label: 'Linkedin',
      icon: 'Linkedin',
      width: '25%',
    },
    {
      name: 'instagram',
      label: 'Instagram',
      icon: 'Instagram',
      width: '30%',
    },
    {
      name: 'links',
      label: 'Additional Link',
      icon: 'Link',
      width: '45%',
    },
    {
      name: 'languages',
      label: 'Language',
      icon: 'Language',
      width: '25%',
    },
    {
      name: 'operationRegions',
      label: 'Areas',
      icon: 'Location',
      width: '30%',
    },
    {
      name: 'additionalEmails',
      label: 'Email',
      icon: 'Email',
      width: '45%',
    },
    {
      name: 'address',
      label: 'Actual Address',
      icon: 'Address',
    },
    {
      name: 'description',
      label: 'Description',
      icon: 'Description',
      multiline: true,
      typeOfComponent: 'input',
    },
  ],
};

const managerPersonalInfo: IPersonalInfo = {
  name: 'manager',
  groupSizes: [3, 3, 3, 3, 1],
  data: [
    {
      name: 'gender',
      label: 'Gender',
      icon: 'Gender',
      width: '25%',
    },
    {
      name: 'nationality',
      label: 'Nationality',
      icon: 'Nationality',
      width: '30%',
    },
    {
      name: 'languages',
      label: 'Language',
      icon: 'Language',
      width: '45%',
    },
    {
      name: 'telegram',
      label: 'Telegram',
      icon: 'Telegram',
      width: '25%',
    },
    {
      name: 'whatsapp',
      label: 'Whatsapp',
      icon: 'Whatsapp',
      width: '30%',
    },
    {
      name: 'additionalPhones',
      label: 'Phone number',
      icon: 'PhoneNumber',
      width: '45%',
    },
    {
      name: 'linkedin',
      label: 'Linkedin',
      icon: 'Linkedin',
      width: '25%',
    },
    {
      name: 'instagram',
      label: 'Instagram',
      icon: 'Instagram',
      width: '30%',
    },
    {
      name: 'links',
      label: 'Additional Link',
      icon: 'Link',
      width: '45%',
    },
    {
      name: 'operationRegions',
      label: 'Areas',
      icon: 'Location',
      width: '25%',
    },
    {
      name: 'address',
      label: 'Actual Address',
      icon: 'Address',
      width: '30%',
    },
    {
      name: 'additionalEmails',
      label: 'Email',
      icon: 'Email',
      width: '45%',
    },
    {
      name: 'description',
      label: 'Description',
      icon: 'Description',
    },
  ],
};

const foreignBrokerPersonalInfo: IPersonalInfo = {
  name: 'foreign',
  groupSizes: [3, 3, 3, 3, 1],
  data: [
    {
      name: 'gender',
      label: 'Gender',
      icon: 'Gender',
      width: '25%',
    },
    {
      name: 'nationality',
      label: 'Nationality',
      icon: 'Nationality',
      width: '30%',
    },
    {
      name: 'languages',
      label: 'Language',
      icon: 'Language',
      width: '45%',
    },

    {
      name: 'telegram',
      label: 'Telegram',
      icon: 'Telegram',
      width: '25%',
    },
    {
      name: 'whatsapp',
      label: 'Whatsapp',
      icon: 'Whatsapp',
      width: '30%',
    },
    {
      name: 'additionalPhones',
      label: 'Phone number',
      icon: 'PhoneNumber',
      width: '45%',
    },
    {
      name: 'linkedin',
      label: 'Linkedin',
      icon: 'Linkedin',
      width: '25%',
    },
    {
      name: 'instagram',
      label: 'Instagram',
      icon: 'Instagram',
      width: '30%',
    },
    {
      name: 'links',
      label: 'Additional Link',
      icon: 'Link',
      width: '45%',
    },
    {
      name: 'formOfOrganization',
      label: 'Form of organization',
      icon: 'Organization',
      width: '25%',
    },
    {
      name: 'address',
      label: 'Actual Address',
      icon: 'Address',
      width: '30%',
    },
    {
      name: 'additionalEmails',
      label: 'Email',
      icon: 'Email',
      width: '45%',
    },
    {
      name: 'description',
      label: 'Description',
      icon: 'Description',
    },
  ],
};

const companyPersonalInfo: IPersonalInfo = {
  name: 'agency',
  groupSizes: [3, 3, 3, 2, 1],
  data: [
    {
      name: 'languages',
      label: 'Language',
      icon: 'Nationality',
      width: '33%',
    },
    {
      name: 'operationRegions',
      label: 'Areas',
      icon: 'Location',
      width: '33%',
    },
    {
      name: 'activityKinds',
      label: 'Specialization',
      icon: 'Specialization',
      width: '33%',
    },
    {
      name: 'telegram',
      label: 'Telegram',
      icon: 'Telegram',
      width: '33%',
    },
    {
      name: 'whatsapp',
      label: 'Whatsapp',
      icon: 'Whatsapp',
      width: '33%',
    },
    {
      name: 'additionalPhones',
      label: 'Phone number',
      icon: 'PhoneNumber',
      width: '33%',
    },
    {
      name: 'instagram',
      label: 'Instagram',
      icon: 'Instagram',
      width: '33%',
    },
    {
      name: 'linkedin',
      label: 'Linkedin',
      icon: 'Linkedin',
      width: '33%',
    },
    {
      name: 'links',
      label: 'Additional Link',
      icon: 'Link',
      width: '33%',
    },
    {
      name: 'address',
      label: 'Actual Address',
      icon: 'Address',
      width: '45%',
    },
    {
      name: 'additionalEmails',
      label: 'Email',
      icon: 'Email',
      width: '50%',
    },
    {
      name: 'description',
      label: 'Description',
      icon: 'Description',
    },
  ],
};

export { brokerPersonalInfo, managerPersonalInfo, foreignBrokerPersonalInfo, companyPersonalInfo };
