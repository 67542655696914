import React from 'react';
import {
  IconButton,
  ListItemIcon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled, shouldForwardProp } from '@mui/system';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
// import { User } from '../types/types';

interface LocalizationsTableProps {
  localizations: any[];
  languages: string;
  total: number;
  onRowClickHandler: (userId: number) => void;
}

const LocalizationsTable: React.FC<LocalizationsTableProps> = ({
  localizations,
  languages,
  total,
  onRowClickHandler,
}) => {
  const StyledTableCell = styled(TableCell)({
    border: 0,
    borderRight: '1px solid #E9EEF1',
    '&:last-child': {
      borderRight: 'none',
    },
    verticalAlign: 'middle',
  });

  const TableCellWithMenu = styled(StyledTableCell)`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const StyledIconButton = styled(IconButton)`
    width: 40px;
    height: 40px;
    border-radius: 14px;
    border: 2px solid #c5dfff;
    margin-left: 20px;
  `;

  const UserCell = styled('div')`
    display: flex;
    align-items: center;
    padding-left: 10px;
    & svg {
      margin-left: 5px;
      vertical-align: text-top;
    }
  `;

  const Avatar = styled('img')`
    margin-right: 10px;
    width: 40px;
    height: 40px;
  `;

  const UserInfo = styled('div')`
    display: flex;
    flex-direction: column;
  `;

  const UserName = styled('div')`
    font-weight: bold;
    font-size: 18px;
  `;

  const UserRole = styled('div')`
    color: gray;
    font-size: 16px;
  `;

  const UserAgency = styled('span')`
    color: #01439b;
  `;

  const UserDate = styled('span')`
    font-size: 12px;
  `;

  const StyledTableContainer = styled(TableContainer)({
    background: 'transparent',
    boxShadow: 'none',
  });

  const StyledTableRow = styled(TableRow)({
    border: '1px solid #E9EEF1',
    background: 'inherit',
  });

  const StyledTableHead = styled(TableHead)({
    background: '#fff',
  });

  const langs = languages.split(',');
  const formatDate = (value: string | null) =>
    value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell>Namespace</TableCell>
            <TableCell>Alias</TableCell>
            {langs.map((language, index) => (
              <TableCell key={index}>{language}</TableCell>
            ))}
            <TableCell>Edit</TableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {localizations.map((localization, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <UserDate>{localization.namespace}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate>{localization.alias}</UserDate>
              </StyledTableCell>
              {langs.map((language, index) => (
                <StyledTableCell key={index}>
                  <UserDate>{localization.translation[language]}</UserDate>
                </StyledTableCell>
              ))}
              <StyledTableCell>
                <UserDate>
                  <ListItemIcon>
                    <EditIcon onClick={(event) => onRowClickHandler(localization.id)} />
                  </ListItemIcon>
                </UserDate>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default LocalizationsTable;
