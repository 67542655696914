import { styled } from '@mui/system';

export const sectionStyle = { 
  alignSelf: 'center', 
  // margin: '-5px auto -20px auto' 
  margin: { xs: '0 auto -40px auto', sm: 'auto' } 
};
export const divStyle: React.CSSProperties = {
  display: 'flex',
  gap: '100px',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '650px',
};

export const stackStyle = {
  backgroundColor: 'white',
  display: 'flex',
  width: { xs: '100%', sm: '720px' },
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: { sm: '5px' },
  padding: '10px 0 20px',
  minHeight: '650px',
  borderRadius: { xs: '8px', sm: 0 },
};
export const typographyH5Style = {
  textTransform: 'none',
  fontSize: {
    xs: '18px',
    sm: '20px',
  },
  fontWeight: '600',
};
export const typographyBody2Style = {
  fontSize: {
    xs: '13px',
    sm: '14px',
  },
  opacity: 0.5,
};
export const boxStyle = {
  display: 'flex',
  gap: '20px',
  marginTop: '12px',
  justifyContent: 'space-between',
  flexDirection: 'row',
};

export const boxInnerStyle = {
  minWidth: '100px',
  margin: '0',
};

export const boxInnerStyleBottom = {
  minWidth: {
    xs: '160px',
    sm: '170px',
  },
};

export const typographyH4Style = {
  textTransform: 'uppercase',
  fontSize: {
    xs: '24px',
    sm: '28px',
  },
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontWeight: '700',
  minWidth: {
    xs: '300px',
    sm: '450px',
  },
};

export const typographyH3Style = {
  textTransform: 'uppercase',
  fontSize: {
    xs: '24px',
    sm: '28px',
  },
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontWeight: '700',
  minWidth: {
    xs: '300px',
    sm: '600px',
  },
  marginBottom: '10px',
};

export const typographyBody1Style = {
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontSize: {
    xs: '14px',
    sm: '16px',
  },
  color: '#2A3842',
};

export const typographyBodyStyle = {
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontSize: {
    xs: '14px',
    sm: '16px',
  },
  color: '#2A3842',
  minWidth: {
    xs: '300px',
    sm: '550px',
  },
};

export const typographyBody1StyleError = {
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontSize: {
    xs: '14px',
    sm: '16px',
  },
  color: '#ff0000',
};

export const supportButtonStyle = {
  color: '#1650FF',
  backgroundColor: '#C5DFFF',
  textTransform: 'none',
  borderRadius: '14px',
  width: '120px',
  height: '48px',
  flex: 1,
  ':hover': {
    color: '#FFF',
  },
};

export const divStyleLogIn: React.CSSProperties = {
  display: 'flex',
  gap: '100px',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '650px',
};

export const stackStyleLogIn = {
  //display: 'flex',
  //width: { xs: '100%', sm: '720px' },
  //height: '100%',
  //alignItems: 'center',
  //justifyContent: 'center',
  //paddingTop: '10px',
  //paddingBottom: '20px',
  //minHeight: '650px',



  backgroundColor: 'white',
  display: 'flex',
  width: { xs: '100%', sm: '720px' },
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: { sm: '5px' },
  paddingTop: '10px',
  paddingBottom: '20px',
  minHeight: '650px',
  borderRadius: { xs: '8px', sm: 0 },
};

export const LogInStyle = {
  backgroundColor: 'white',
  borderRadius: '14px',
  display: 'flex',
  width: { xs: '100%', sm: '500px' },
  maxWidth: { sm: '504px', xs: '100%' },
  height: '100%',
  minHeight: '550px',
  // minHeight: '400px',
  alignItems: { sm: 'flex-start', xs: 'center' },
  justifyContent: 'flex-start',
  gap: '25px',
  //padding: '24px 24px',
  // boxShadow: '0 0 7px 2px #00000026',
  padding: { sm: '20px 30px', xs: '20px 20px' },
};

export const typographyH4StyleLogIn = {
  textTransform: 'uppercase',
  fontSize: {
    xs: '24px',
    sm: '28px',
  },
  textAlign: 'center',
  fontWeight: '700',
  minWidth: {
    xs: '300px',
    sm: '450px',
  },
};

export const StyledLogo = styled('img')({
  height: '48px',
});