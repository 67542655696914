import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';

import { ActionsTypes, State } from '@/types';
import { authReducer, chatReducer, appReducer } from '@/store';

const rootReducer = combineReducers<State, ActionsTypes>({
  auth: authReducer,
  chat: chatReducer,
  app: appReducer,
});

export const configureStore = () => {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(rootReducer, undefined, composedEnhancers);

  return store;
};
