import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

import { Box, useMediaQuery, useTheme, FormControl, MenuItem, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { appSetNotification } from '@/store';
import {
  AdminPeriodEnum,
  NotificationTypeEnum,
  MetricsChurnRateModel,
  AdminMetricsEnum,
  MetricsNotLoggedInModel,
} from '@/types';
import { metricsProvider } from '@/providers';

import { MyButton, LabelComponent, Loader } from '@/components';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { ValidationError } from '@/components/OffPlanView/styled';
import styles from './PeriodAndFrequency.module.scss';

interface DataList {
  label: string;
  value: AdminPeriodEnum;
}
interface PeriodAndFrequencyProps<T> {
  onSetValue: (value: T) => void;
  setIsPeriodVisible: (value: boolean) => void;
  type: 'churnRate' | 'notLoggedIn' | 'lastPropertyAdPublished' | 'lastClientRequestPublished';
}

export const PeriodAndFrequency = <T,>({
  onSetValue,
  setIsPeriodVisible,
  type,
}: PeriodAndFrequencyProps<T>) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [convertedStartDate, setConvertedStartDate] = useState<string>();
  const [convertedEndDate, setConvertedEndDate] = useState<string>();
  const [frequency, setFrequency] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const frequencyValues = [
    { label: 'Day', value: AdminPeriodEnum.Day },
    { label: 'Week', value: AdminPeriodEnum.Week },
    { label: 'Two Week', value: AdminPeriodEnum.TwoWeek },
    { label: 'Month', value: AdminPeriodEnum.Month },
    { label: 'Quarter', value: AdminPeriodEnum.Quarter },
  ];

  const handleChangeDates = (type: any) => (v: dayjs.Dayjs | null) => {
    if (v) {
      const isoString = v || dayjs();
      const formattedDate = v.format('YYYY-MM-DD');
      if (type === 'start') {
        setStartDate(v);
        setConvertedStartDate(formattedDate);
        v;
      } else {
        setEndDate(v);
        setConvertedEndDate(formattedDate);
      }
    }
  };

  const isFrequencyVisible = type !== AdminMetricsEnum.NotLoggedIn;
  const isBtnDisabled = isFrequencyVisible
    ? convertedStartDate && convertedEndDate && frequency
    : convertedStartDate && convertedEndDate;

  const onHandlerApply = async () => {
    if (isFrequencyVisible && convertedStartDate && convertedEndDate && frequency) {
      const dataSubmit = {
        period: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        },
        frequency: frequency,
      };
      setIsLoading(true);
      let response;
      if (type === AdminMetricsEnum.ChurnRate) {
        response = await metricsProvider.churnRate(dataSubmit);
      } else if (type === AdminMetricsEnum.LastPropertyAdPublished) {
        response = await metricsProvider.lastAdPublished(dataSubmit);
      } else if (type === AdminMetricsEnum.LastClientRequestPublished) {
        response = await metricsProvider.lastClientRequestPublished(dataSubmit);
      } else {
        response = { ok: false, status: 400, message: 'Invalid type', data: null };
      }
      const { ok, data, status, message } = response;
      if (ok) {
        onSetValue(data as T);
        setIsPeriodVisible(false);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    }
    if (!isFrequencyVisible && convertedStartDate && convertedEndDate) {
      const dataSubmit = {
        period: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        },
        limit: 50,
        offset: 0,
      };
      setIsLoading(true);
      const { ok, data, status, message } = await metricsProvider.notLoggedIn(dataSubmit);
      if (ok) {
        onSetValue(data as T);
        setIsPeriodVisible(false);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        flexDirection: isMobile ? 'column' : 'row',
        marginTop: '10px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
            <LabelComponent isRequired label='Start Date' sx={{ marginBottom: '5px' }} />
            <DatePicker
              label=''
              value={startDate}
              onChange={handleChangeDates('start')}
              slots={{
                openPickerIcon: KeyboardArrowDownIcon,
              }}
            />
            <ValidationError>
              {/* {errors?.forecast?.plannedCompletion?.message} */}
            </ValidationError>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
            <LabelComponent isRequired label='End Date' sx={{ marginBottom: '5px' }} />
            <DatePicker
              label=''
              value={endDate}
              onChange={handleChangeDates('end')}
              slots={{
                openPickerIcon: KeyboardArrowDownIcon,
              }}
            />
            <ValidationError>
              {/* {errors?.forecast?.estimatedCompletion?.message} */}
            </ValidationError>
          </Box>
          {isFrequencyVisible && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
              <FormControl sx={{ gap: '5px' }}>
                <Typography className={styles.label}>
                  Frequency
                  <span>*</span>
                </Typography>
                <Select
                  sx={{
                    borderRadius: '14px',
                    border: `1px solid '#E9EEF1'`,
                  }}
                  IconComponent={(_props) => {
                    const rotate = _props.className.toString().includes('iconOpen');
                    return (
                      <div
                        className={styles.caretDown}
                        style={{
                          transform: rotate ? 'rotate(180deg)' : 'none',
                        }}
                      >
                        <CaretDown />
                      </div>
                    );
                  }}
                  value={frequency}
                  onChange={(e: SelectChangeEvent) => setFrequency(e.target.value as string)}
                >
                  {frequencyValues.map(({ value, label }: DataList, index: number) => (
                    <MenuItem
                      divider={frequencyValues.length - 1 !== index}
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: 'capitalize',
                      }}
                      key={index}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <MyButton
            data={{
              buttonName: 'Apply',
              customWidth: '155px',
              variant: 'contained',
              buttonType: 'button',
            }}
            disabled={!isBtnDisabled}
            onClick={onHandlerApply}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};
