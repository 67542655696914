import React, { KeyboardEvent, useState, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, IconButton, TextField } from '@mui/material';
import { MyButton } from '@/components/uiComponents';
import ClearIcon from '@mui/icons-material/Clear';

interface LicenseSearchProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onRowClickHandler: (id?: string) => void;
}

const LicenseSearch: React.FC<LicenseSearchProps> = ({ search, setSearch, onRowClickHandler }) => {
  const StyledRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    gap: '12px',
  });

  const SearchFieldStyle = styled(TextField)({
    maxWidth: '676px',
    width: '100%',
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [query, setQuery] = useState<string>(search);

  const handleAddressPressEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearch(query);
    }

    // TODO: focus should not go out
    if (inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      });
    }
  };

  return (
    <StyledRow>
      <SearchFieldStyle
        placeholder='Search by name, phone or email'
        inputProps={{ ref: inputRef }}
        value={query}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
        onKeyDown={handleAddressPressEnter}
        onKeyUp={handleAddressPressEnter}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: query ? 'visible' : 'hidden',
              }}
              onClick={() => setSearch('')}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <MyButton
          data={{
            buttonName: 'Search',
            styleType: 'cancel',
            variant: 'contained',
          }}
          onClick={() => setSearch(query)}
        />
        <MyButton
          data={{
            buttonName: 'Add licence',
            styleType: 'button',
            variant: 'contained',
          }}
          onClick={() => onRowClickHandler()}
        />
      </Box>
    </StyledRow>
  );
};

export default LicenseSearch;
