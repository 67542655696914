import { useDispatch } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import { State, AdInfoType, NotificationTypeEnum, SocialButtonEnum } from '@/types';
import Logo from './logoOnloneProperty.svg';
import { ReactComponent as CircleWavyCheck } from './CircleWavyCheck.svg';
import { propertyAdRequestProvider } from '@/providers';
import { appSetNotification } from '@/store';

const Request = ({
  adInfo,
  open,
  onClose,
  actionKey,
  actionName,
  switchActionKey,
}: {
  adInfo: AdInfoType;
  open: boolean;
  actionKey: string;
  actionName: string;
  onClose: () => void;
  switchActionKey: (actionKey: string, name: string, message: string) => void;
}) => {
  const dispatch = useDispatch();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onHandlerClick = async () => {
    const currentUrl = window.location.href;
    const messageText = encodeURIComponent(`Hello ${adInfo.name}! 
    I'd like more details about your ${adInfo.activityKind} listing on Online Property: ${adInfo.title}, ${adInfo.currency} ${adInfo.price}, located ${adInfo.location},
    ${currentUrl}
    Can you provide me with more information?`);
    switchActionKey(actionKey, actionName, messageText);
    onClose();
    const { ok, message, status } = await propertyAdRequestProvider.createAdRequest(
      adInfo.id,
      actionName
    );
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  if (!open) return null;
  return (
    <MyDialog open={open} onClose={onClose} dialogTitle='' width='760'>
      <Box display='flex' width='100%'>
        <Stack spacing={2} direction='column' justifyContent='center' alignItems='center'>
          <img
            style={{ width: '100%', maxWidth: '88px', objectFit: 'fill' }}
            src={Logo}
            alt='wait image'
          />

          <Stack
            spacing={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ width: '90%' }}
          >
            <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>
              Online Property
            </Typography>
            <CircleWavyCheck style={{ width: '24px', height: '24px' }} />
          </Stack>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography style={{ textAlign: 'center' }}>
              Hello <span style={{ fontWeight: 600 }}>{adInfo.name}!</span>
              <br />
              I'd like more details about your {adInfo.activityKind} listing:{' '}
              <span style={{ fontWeight: 600, color: '#1650FF' }}>{adInfo.title}, </span>
              <span style={{ fontWeight: 600 }}>
                {adInfo.currency} {adInfo.price},
              </span>{' '}
              located <span style={{ fontWeight: 600 }}>{adInfo.location}.</span>
              <br />
              Can you provide me with more information?
            </Typography>

            <Stack spacing={2} alignItems='center' sx={{ width: '100%' }}>
              <MyButton
                data={{
                  buttonName: `Open ${capitalizeFirstLetter(actionName)}`,
                  variant: 'contained',
                  buttonType: 'submit',
                  customWidth: '230px',
                }}
                onClick={onHandlerClick}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </MyDialog>
  );
};

export default Request;
