import { LabelComponent, MyButton, MyDialog } from '@/components';
import { Box, TextField, Typography } from '@mui/material';
import { UNITS_MODAL_TYPE } from '../UnitsView.init';
import { IModalDialogProps, IUnitState } from './ModalDialogs.types';
import { getWordings } from './ModalDialogs.utils';
import { useEffect, useState } from 'react';

export const ModalDialogs = ({
  modalType,
  curUnits,
  selectedUnits,
  openModal = false,
  handleCloseModal,
  fetchUnitEdit,
  fetchUnitsDelete,
  generateUnitsPDF,
}: IModalDialogProps) => {
  const selectedUnitsCount = selectedUnits
    ? Object.entries(selectedUnits).filter(([_k, v]) => !!v).length
    : 0;
  const dlgWordings = getWordings(modalType, selectedUnitsCount);
  const singleUnitAction =
    modalType === UNITS_MODAL_TYPE.deleteOne || modalType === UNITS_MODAL_TYPE.saveAsPdfOne;
  const multipleUnitAction =
    modalType === UNITS_MODAL_TYPE.deleteAll || modalType === UNITS_MODAL_TYPE.saveAsPdfAll;

  const [unitState, setUnitState] = useState<IUnitState>({
    id: curUnits?.[0].id.toString() || '',
    areas_sqft: curUnits?.[0].areaSQFT || 0,
    price_aed: curUnits?.[0].price || 0,
    apartment_num: curUnits?.[0].apartmentNumber || 0,
    floor_num: curUnits?.[0].floor || 0,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUnitState((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
  };

  useEffect(() => {
    setUnitState({
      id: curUnits?.[0].id.toString() || '',
      areas_sqft: curUnits?.[0].areaSQFT || 0,
      price_aed: curUnits?.[0].price || 0,
      apartment_num: curUnits?.[0].apartmentNumber || 0,
      floor_num: curUnits?.[0].floor || 0,
    });
  }, [curUnits]);

  const handleLeftBtnClick = (e: any) => {
    switch (modalType) {
      case UNITS_MODAL_TYPE.deleteOne:
        if (curUnits && curUnits[0]) return fetchUnitsDelete([curUnits[0].id]);
        break;
      case UNITS_MODAL_TYPE.deleteAll:
        if (selectedUnits && selectedUnitsCount > 0) {
          const ids = Object.keys(selectedUnits);
          return fetchUnitsDelete(ids);
        }
        break;
      case UNITS_MODAL_TYPE.saveAsPdfOne:
        if (curUnits && curUnits[0]) return generateUnitsPDF([curUnits[0].id]);
        break;
      case UNITS_MODAL_TYPE.saveAsPdfAll:
        if (selectedUnits && selectedUnitsCount > 0) {
          const ids = Object.keys(selectedUnits);
          return generateUnitsPDF(ids);
        }
        break;
      case UNITS_MODAL_TYPE.editOne: {
        if (curUnits && curUnits[0] && unitState) {
          return fetchUnitEdit(unitState);
        }
        break;
      }
    }
  };

  return (
    <MyDialog
      open={openModal}
      onClose={handleCloseModal}
      width='700'
      dialogTitle={dlgWordings.title}
    >
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
        {(singleUnitAction || multipleUnitAction) && (
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {dlgWordings.typography}
          </Typography>
        )}
        {modalType === UNITS_MODAL_TYPE.editOne && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '50%',
              }}
            >
              <LabelComponent label='Areas (sqft)' />
              <TextField
                name='areas_sqft'
                type='number'
                label=''
                placeholder='Enter area in sqft'
                onChange={handleInputChange}
                defaultValue={curUnits?.[0].areaSQFT.toString()}
              />
              <LabelComponent label='Price (AED)' />
              <TextField
                name='price_aed'
                type='number'
                label=''
                placeholder='Enter price in AED'
                onChange={handleInputChange}
                defaultValue={curUnits?.[0].price.toString()}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '50%',
              }}
            >
              <LabelComponent label='Apartment num.' />
              <TextField
                name='apartment_num'
                type='number'
                label=''
                placeholder='Enter apartment number'
                onChange={handleInputChange}
                defaultValue={curUnits?.[0].apartmentNumber.toString()}
              />
              <LabelComponent label='Floor' />
              <TextField
                name='floor_num'
                type='number'
                label=''
                placeholder='Enter floor number'
                onChange={handleInputChange}
                defaultValue={curUnits?.[0].floor.toString()}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          <MyButton
            data={{
              buttonName: dlgWordings.leftBtnText,
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={handleLeftBtnClick}
          />
          <MyButton
            data={{
              buttonName: dlgWordings.rightBtnText,
              customWidth: '226px',
              variant: 'outlined',
              buttonType: 'button',
              styleType: 'cancel',
            }}
            onClick={handleCloseModal}
          />
        </Box>
      </Box>
    </MyDialog>
  );
};
