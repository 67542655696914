import { FC } from 'react';
import { StyleLabel } from '../styled';
import { TooltipComponent } from '@/components';

import { ILabelComponentProps } from '../types';

export const LabelComponent: FC<ILabelComponentProps> = ({
  label,
  isRequired = false,
  iconInfo = false,
  tooltip,
  opacity,
  sx,
  errorMessage,
}) => {
  return (
    <StyleLabel opacity={opacity} sx={sx}>
      {label}
      {isRequired && <span style={{ color: 'red' }}>*{errorMessage ? ` - ${errorMessage}` : ""}</span>}
      {iconInfo && <TooltipComponent tooltip={tooltip || ''} />}
    </StyleLabel>
  );
};
