export const chatHeaderStyle = (context) => {
  return {
    padding: '16px 20px',
    width: '100%',
    zIndex: '1',
    borderBottom: `1px solid ${context.theme.borderColor.primary}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };
};

export const chatDetailStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 83px)',
    [`@media (min-width: 600px)`]: {
      width: 'calc(100% - 116px)',
    },
  };
};

export const chatSideBarBtnStyle = (img, props, context) => {
  const displayValue =
    props.hasOwnProperty('sidebar') && props.sidebar === 0 ? { display: 'none!important' } : {};

  const mq = [...context.theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `#2A3842`,
    width: '24px',
    height: '24px',
    float: 'left',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      display: 'block',
    },
    ...displayValue,
  };
};

export const chatThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    flexShrink: '0',
    marginRight: '16px',
  };
};

export const chatUserStyle = (context) => {
  const mq = [...context.theme.breakPoints];

  return {
    width: 'calc(100% - 50px)',
    padding: '0',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      width: 'calc(100% - 80px)!important',
    },
  };
};

export const chatNameStyle = (context) => {
  return {
    margin: '0',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: `${context.theme.color.primary}`,
  };
};

export const chatStatusStyle = (_, context) => {
  return {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    color: `${context.theme.color.primary}`,
  };
};

export const chatOptionWrapStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
  };
};

export const chatOptionStyle = (img, context, ongoingCall) => {
  const bgColor = ongoingCall
    ? {
        backgroundColor: `${context.theme.secondaryTextColor}`,
      }
    : {
        backgroundColor: `${context.theme.color.primary}`,
      };

  return {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 8px',
    borderRadius: '50%',
    backgroundColor: `${context.theme.backgroundColor.lightBlue3}`,
    i: {
      width: '20px',
      height: '20px',
      display: 'inline-block',
      mask: `url(${img}) no-repeat`,
      ...bgColor,
    },
  };
};

export const chatOptionSearchStyle = (img, context, ongoingCall) => ({
  ...chatOptionStyle(img, context, ongoingCall),
});

export const chatTypingAnimationStyle = () => {
  return {
    display: 'inline-block',
    width: '24px',
    marginRight: '7px',
  };
};
