import {
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  FormGroup,
} from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import { ChangeEvent, FC, useState } from 'react';
import styles from './../../AdForm/AdForm.module.scss';

const UnBlockUser = ({
  open,
  userName,
  onClose,
  onConfirm,
}: {
  open: boolean;
  userName: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Are you sure you want to unblock the user?'
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          Do you want to unblock the user <b>"{userName}"</b> in the system? After confirming the
          action, the user will be able to fully use the system.
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Confirm',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={onConfirm}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default UnBlockUser;
