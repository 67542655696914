import { ResponseType, gptResponseType } from '@/types';
import { gptClient } from '@/providers';

export const gptProvider = {
  getTitle: async (request: string | string[]): Promise<ResponseType> => {
    let description: string, keywords: string[];
    if (Array.isArray(request)) {
      description =
        'GhatGPT 3.5 Create a 16-word maximum title for a sale/rent real estate ad using the following:';
      keywords = request;
    } else {
      description = request;
      keywords = [];
    }

    const response = await gptClient('/completions', 'POST', {
      description,
      keywords,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as gptResponseType;
    return { ...response, data };
  },

  getDescription: async (request: string | string[]): Promise<ResponseType> => {
    let description: string, keywords: string[];
    if (Array.isArray(request)) {
      description =
        'Create a 120-word description for a real estate ad using the following keywords:';
      keywords = request;
    } else {
      description = request;
      keywords = [];
    }

    const response = await gptClient('/completions', 'POST', {
      description,
      keywords,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as gptResponseType;
    return { ...response, data };
  },
};
