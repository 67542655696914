import { TMediaObject, TOrganizedMedia } from "@/types/offplans";

export const generateJsomToPostMedia = (formMediaValues: TMediaObject[], offPlanId: string): TOrganizedMedia => {
  const result: TOrganizedMedia = {
    externalId: offPlanId,
    entityType: formMediaValues[0].entityType,
    media: [],
  };

  formMediaValues.forEach((obj) => {
    result.media.push({
      mediaType: obj.mediaType,
      fileId: obj.fileId,
      linkUrl: obj.fileUrl ?? "null",
      order: obj.order,
    });

  });

  return result;
};
