import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';

import { customTheme } from '@/utils';

import styles from './FormCheckbox.module.scss';

export const FormCheckboxWithWatch = ({
  name,
  label,
  checked = false,
  disabled = false,
  defaultValue,
}: {
  defaultValue?: boolean | string;
  name: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
}) => {
  const outerTheme = useTheme();
  const { register, watch } = useFormContext();

  const currentValue = watch(name, checked);

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <FormGroup>
        <FormControlLabel
          style={{ marginRight: 0 }}
          disabled={disabled}
          control={
            <Checkbox
              {...register(name)}
              checkedIcon={<span className={styles.checked} />}
              icon={<span />}
              checked={currentValue}
              inputProps={{ 'aria-label': 'controlled' }}
              value={defaultValue}
            />
          }
          label={label}
          className={styles.label}
        />
      </FormGroup>
    </ThemeProvider>
  );
};
