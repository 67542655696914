import { CometChat } from '@cometchat-pro/chat';

export const messageContainerStyle = () => {
  return {
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '65%',
    minWidth: '340px',
    clear: 'both',
    flexShrink: '0',
    alignSelf: 'flex-start',
  };
};

export const messageWrapperStyle = () => {
  return {
    flex: '1 1',
    position: 'relative',
    width: '100%',
  };
};

export const messageTxtWrapperStyle = ({ theme }) => {
  return {
    display: 'inline-block',
    borderRadius: '4px 14px 14px 14px',
    padding: '10px 16px',
    Width: '100%',
    backgroundColor: `${theme.backgroundColor.lightGrey1}`,
    alignSelf: 'flex-start',
  };
};

export const messageTxtStyle = (context) => {
  return {
    fontSize: '14px!important',
    margin: '0',
    lineHeight: '20px!important',
    color: `${context.theme.color.primary}`,
    opacity: '0.5',
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
  };
};

export const messageTimeStampStyle = ({ theme }) => {
  return {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: `${theme.color.primary}`,
    opacity: '0.4',
  };
};

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    marginBottom: '12px',
    float: 'left',
  };
};

export const messageDetailStyle = (props) => {
  let paddingSpace = {};
  if (props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
    paddingSpace = {
      paddingLeft: '5px',
    };
  }

  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...paddingSpace,
  };
};
