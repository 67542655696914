import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import styles from './FormButtons.module.scss';

export const FormButtons = ({
  options,
  onClick,
  activeButton,
  size,
  name,
  dataQa,
  disabled,
}: {
  options: any[];
  onClick: (value: any, name?: string) => void;
  activeButton: any;
  size: 'large' | 'big' | 'middle' | 'small' | 'little' | 'circle';
  name?: string;
  dataQa?: string;
  disabled?: boolean;
}) => {
  const formContext = useFormContext();
  const errors = formContext?.formState.errors || {};
  const error = name && !!errors[name];

  // Determine if a button is active
  const isActive = (value: any) => {
    if (Array.isArray(activeButton)) {
      return activeButton.includes(value);
    }
    return activeButton === value;
  };

  return (
    <Stack direction='row' className={styles.container} data-qa={`${dataQa}_buttons_block`}>
      {options.map((option) => (
        <Stack
          data-qa={`${dataQa}_${option.value}_btn`}
          key={option.value}
          className={`${styles.button} ${styles[`button${size.charAt(0).toUpperCase() + size.slice(1)}`]} ${error ? styles.buttonError : disabled ? styles.buttonDisable : isActive(option.value) ? styles.buttonActive : styles.buttonPassive}`}
          onClick={() => onClick(option.value, name)}
        >
          <span>{option.label}</span>
        </Stack>
      ))}
    </Stack>
  );
};
