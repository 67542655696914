import { Avatar, Button, Link } from '@mui/material';

import { MemberModel } from '@/types';
import { stringAvatar, getKindLabel } from '@/utils';

import styles from './MemberItem.module.scss';
import activeAdsImg from './activeAdsIcon.svg';
import adParticipantImg from './adParticipantIcon.svg';
import removeMember from './removeMemberIcon.svg';

interface MemberItemProps {
  item: MemberModel;
  onRemoveMember: () => void;
}

export const MemberItem = ({
  item: { id, avatar, name, kind, activeAds, adParticipant },
  onRemoveMember,
}: MemberItemProps) => (
  <div className={styles.container}>
    <div className={styles.blockAvatar}>
      <div className={styles.avatarImage}>
        <Link href={`/users/${id}`} title={name}>
          {avatar ? (
            <img src={avatar} alt={name} />
          ) : (
            <Avatar {...stringAvatar(name)} sx={{ width: 72, height: 72 }} />
          )}
        </Link>
      </div>
      <div className={styles.nameLabel}>
        <Link href={`/users/${id}`} title={name}>
          {name}
        </Link>
        <div>{getKindLabel(kind)}</div>
      </div>
    </div>

    <div className={styles.blockAdData}>
      <div className={styles.adData}>
        <img src={activeAdsImg} alt='Active ads' />
        Active ads:
        <span>{activeAds}</span>
      </div>
      <div className={styles.adData}>
        <img src={adParticipantImg} alt='Ad participant' />
        Ad participant:
        <span>{adParticipant}</span>
      </div>
    </div>

    <div className={styles.blockButtons}>
      <Button type='button' variant='outlined' color='primary' onClick={onRemoveMember}>
        <img src={removeMember} alt='Remove' />
        Remove
      </Button>
    </div>
  </div>
);
