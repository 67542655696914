/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme, shouldForwardProp } from '@mui/system';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum, ITransactions, ISubscriptionsTable } from '@/types';
import { Loader } from '@/components';
import { adminProvider } from '@/providers';
import moment from 'moment';

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'weight',
})<{ color?: string; weight?: number }>(({ color, weight }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: color,
    fontWeight: weight,
    maxWidth: '180px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FCFF',
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}


export const TransactionsTable = ({
  onClick,
  transactionsTable,
}: {
  onClick?: (row: ITransactions, type: string) => void;
  transactionsTable: ISubscriptionsTable;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<ITransactions[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [nextPages, setNextPages] = useState<string[] | null>([])

  const dispatch = useDispatch();

  const getAllSubscription = async () => {
    setIsLoading(true);
    const limit = rowsPerPage;
    const oldNextPage = nextPages?.length && nextPages?.length > 0 ? nextPages[nextPages?.length - 1] : undefined;
    const dataRequest = {
      limit: limit,
      page: oldNextPage,
    };
    const { ok, data, status, message } = await adminProvider.getTransactions(dataRequest);
    if (ok && data) {
      const { items, hasMore, nextPage } = data;
      const newItems = items.map((item: ITransactions) => {
        const paymentDate = item.paymentDate ? moment(item.paymentDate).format('DD.MM.YYYY') : null;
        const currency = item.currency.toUpperCase();
        return {...item, paymentDate, currency}
      });
      setRows(newItems as ITransactions[]);
      const count = hasMore ? items.length + 1 : items.length;
      setTotalCount(totalCount === 0 ? count : totalCount + count - 1);
      if (nextPage) {
        setNextPages((prevNextPages) => (prevNextPages ? [...prevNextPages, nextPage] : [nextPage]));
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllSubscription();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTotalCount(0);
  };

  const getCellProperties = (
    row: ITransactions,
    body: string
  ): { cell: string | number; color: string; weight: number } => {
    const cell = (row as ITransactions)[body as keyof ITransactions]
    const isGreen = cell === 'Active' || cell === 'Successful';
    const isOrange = cell === 'Suspended' || cell === 'Unsuccessful';
    const isRed = cell === 'Canceled' || cell === 'Return';

    const color = isGreen ? '#1CC700' : isOrange ? '#FF8C05' : isRed ? 'red' : '#2A3842';

    const weight = body === 'status' ? 600 : 400;

    return { cell, color, weight };
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
      setNextPages([]);
      setTotalCount(0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
      setNextPages((prevNextPages) => {
        if (!prevNextPages || prevNextPages.length === 0) return prevNextPages;
        return prevNextPages.slice(0, -2);
      });
      setTotalCount(totalCount - (2 * rowsPerPage));
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label='first page'
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='next page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Box>
    );
  };

  if (isLoading) return <Loader />;

  return (
    <TableContainer component={Paper} sx={{ mt: '40px' }}>
      <Table sx={{ minWidth: 350 }} aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            {transactionsTable.header.map((header: string, index: number) => {
              return (
                <StyledTableCell key={header}>
                  {header}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: ITransactions, index) => (
            <StyledTableRow key={index} onClick={() => onClick?.(row, 'transaction')}>
              {transactionsTable.body.map((body: string, bodyIndex: number) => {
                const { cell, color, weight } = getCellProperties(row, body);
                return (
                  <StyledTableCell
                    key={bodyIndex}
                    component='th'
                    scope='row'
                    color={color}
                    weight={weight}
                  >
                    {cell}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              // colSpan={4}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
