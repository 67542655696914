import React, { useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, MenuItem, Typography, Button } from '@mui/material';
import { FormSelectCustom, MyButton } from '../uiComponents';
import { IUnitSearchQuerySort } from '@/types/requests';
import { UNITS_MODAL_TYPE, sortOptions } from './UnitsView.init';
import { FilePdfBlue, TrashSimpleBlue } from '@/assets/pdf';
import { IUnitSearchItems } from '@/types/responses';

interface IUnitsViewMiddleSectionProps {
  itemsTotal?: number;
  itemsOnPage?: number;
  onChangeSort: (...args: any) => void;
  defaultSortOptions: IUnitSearchQuerySort;
  onClickForAllSelectedUnits: (modalType: string) => () => void;
}
type SelectOption = {
  value: any;
  label?: string;
  Icon?: any;
  field?: string;
  order?: string;
};
export const UnitsViewMiddleSection = ({
  itemsTotal,
  itemsOnPage,
  onChangeSort,
  defaultSortOptions,
  onClickForAllSelectedUnits,
}: IUnitsViewMiddleSectionProps) => {
  const defaultSort = sortOptions.filter(
    ({ field, order }) => defaultSortOptions.field === field && defaultSortOptions.order === order
  )[0];
  const [selectedSort, setSelectedSort] = useState<SelectOption>(defaultSort);
  const [checked, setChecked] = useState(false);

  const handleChange = (value: SelectOption) => {
    const newSelectedOption = sortOptions.find((option) => option.value === value.value);
    if (
      newSelectedOption &&
      (newSelectedOption.field !== selectedSort.field ||
        newSelectedOption.order !== selectedSort.order)
    ) {
      setSelectedSort(newSelectedOption);
      onChangeSort({
        sort: [{ field: newSelectedOption.field, order: newSelectedOption.order }],
      });
    }
  };

  const selectStartAdornment = useMemo(() => {
    if (selectedSort && selectedSort.Icon) {
      return React.createElement(selectedSort.Icon);
    }
    return null;
  }, [selectedSort]);

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked || false;
    setChecked(isChecked);
    onClickForAllSelectedUnits(
      isChecked ? UNITS_MODAL_TYPE.selectAll : UNITS_MODAL_TYPE.unSelectAll
    )();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Typography color='#2A3842' fontSize='24px' fontWeight='bold'>
            {itemsOnPage} of
          </Typography>
          <Typography color='#1650FF' fontWeight='bold' fontSize='24px'>
            {itemsTotal} Units
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', minWidth: '150px' }}>
          <Box>
            <Typography noWrap>Sort by:</Typography>
          </Box>
          <FormSelectCustom
            value={selectedSort.value}
            displayEmpty
            name='sorting'
            required
            endAdornment={selectStartAdornment}
          >
            {sortOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => handleChange(option)}
              >
                {option.label} <option.Icon style={{ marginLeft: 'auto' }} />
              </MenuItem>
            ))}
          </FormSelectCustom>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '16px' }}>
        <Checkbox checked={checked} onChange={handleCheckboxChange} />
        <Button
          sx={{ textTransform: 'none', color: '#2A3842', gap: '8px' }}
          onClick={onClickForAllSelectedUnits(UNITS_MODAL_TYPE.saveAsPdfAll)}
        >
          <img src={FilePdfBlue} />
          <Typography>Save as PDF</Typography>
        </Button>
        <Button
          sx={{ textTransform: 'none', color: '#2A3842', gap: '8px' }}
          onClick={onClickForAllSelectedUnits(UNITS_MODAL_TYPE.deleteAll)}
        >
          <img src={TrashSimpleBlue} />
          <Typography>Delete</Typography>
        </Button>
      </Box>
    </Box>
  );
};
