import {
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  FormGroup,
} from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import { ChangeEvent, FC, useState } from 'react';
import styles from './../../AdForm/AdForm.module.scss';

const MyLabel: FC<any> = ({ data }) => {
  return (
    <>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{data.label}</Typography>
    </>
  );
};

const radioGroup = [
  {
    name: 'fraud',
    label: 'Fraud',
  },
  {
    name: 'spam',
    label: 'Spam',
  },
  {
    name: 'rudeness',
    label: 'Rudeness',
  },
];

const BlockUser = ({
  open,
  userName,
  onClose,
  onConfirm,
}: {
  open: boolean;
  userName: string;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}) => {
  const [reason, setReason] = useState('fraud');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Are you sure you want to block the user in the system?'
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          <div style={{ marginBottom: '20px' }}>
            You want to block the user <b>"{userName}"</b> in the system, after confirming the
            action, the user will not be able to log in.
          </div>
          <FormControl key={'from'}>
            <RadioGroup value={reason} onChange={handleChange}>
              <FormGroup row>
                {radioGroup?.map((radio) => (
                  <FormControlLabel
                    key={radio.name}
                    value={radio.name}
                    control={
                      <Radio
                        sx={{
                          padding: '0',
                          alignSelf: 'flex-start',
                          marginLeft: '20px',
                        }}
                      />
                    }
                    label={<MyLabel data={radio} />}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  />
                ))}
              </FormGroup>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Confirm',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={() => onConfirm(reason)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default BlockUser;
