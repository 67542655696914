import { useEffect, useState } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import {
  AnnouncerModel,
  NotificationTypeEnum,
  State,
  TeamGetAllAnnouncersResponseType,
} from '@/types';
import { customTheme, getKindLabel } from '@/utils';
import { teamProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { SectionTitle, MyAutocomplete } from '@/components';

import styles from './ResponsibleUser.module.scss';

interface IErrors {
  [key: string]: { message: string } | undefined;
}

interface ResponsibleUserProps {
  setCreatorId?: (value: string) => void;
  isCreatorError?: boolean;
  setIsCreatorError?: (value: boolean) => void;
  withTitle?: boolean;
}

export const ResponsibleUser = ({
  setCreatorId,
  isCreatorError,
  setIsCreatorError,
  withTitle = true,
}: ResponsibleUserProps) => {
  const outerTheme = useTheme();
  const dispatch = useDispatch();
  const { formState: { errors }, control } = useFormContext();

  const { id: userId } = useSelector(({ auth }: State) => auth.user);

  const [users, setUsers] = useState<AnnouncerModel[]>([]);
  const [respUser, setRespUser] = useState<string[]>([]);
  const [creator, setCreator] = useState<AnnouncerModel | null>();

  useEffect(() => {
    const getUsers = async () => {
      const { ok, data, status, message } = await teamProvider.getAnnouncers();
      if (ok) {
        const { items } = data as TeamGetAllAnnouncersResponseType;
        setUsers(items || []);
        setRespUser(
          items?.map((item: AnnouncerModel) => item.user.name));
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };

    getUsers();
  }, []);

  const handleChange = (value: string) => {
    if (value) {
      const creator = users.find((item) => item.user.name === value);
      const creatorId = creator?.user.id;
      setCreator(creator);
      setIsCreatorError?.(false);
      if (creatorId) {
        setCreatorId?.(creatorId);
      }
    } else {
      setCreatorId?.(userId);
      setCreator(null);
    }
  };

  return (
    <>
      {withTitle && <SectionTitle number={8} title='Responsible for announcement' required />}
      <div className={withTitle ? '' : styles.responsibleContainer}>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <MyAutocomplete
            errors={errors as IErrors}
            control={control}
            changeNewAutocompleteValue={handleChange}
            data={{
              name: 'creator',
              options: respUser,
              placeholder: 'Choose responsible for announcement',
              numOfSelected: 1,
              multiple: true,
            }}
          />
          {isCreatorError && (
            <Typography sx={{ color: 'red', fontSize: 12, textAlign: 'right' }}>
              To publish an ad you need to assign a responsible for announcement
            </Typography>
          )}
        </ThemeProvider>
        <div className={styles.usersContainer}>
          {creator ? (
            <div className={styles.container}>
              <div className={styles.image}>
                {creator.user.avatar ? (
                  <img src={creator.user.avatar} alt={creator.user.name} />
                ) : (
                  <span>{creator.user.name[0]}</span>
                )}
              </div>
              <div className={styles.name} title={creator.user.name}>
                {creator.user.name}
              </div>
              <div className={styles.title}>{getKindLabel(creator.user.kind)}</div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
