import { FC } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TooltipComponent } from '@/components';
import { StyledButton, StyledButtonIcon } from '../styled';
import { IMyButtonProps } from '../types';

export const MyButton: FC<IMyButtonProps> = ({ data, disabled, onClick }) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    label,
    buttonName,
    iconInfo,
    tooltip,
    customWidth,
    variant,
    size = 'medium',
    buttonType,
    styleType,
    icon,
    colorIcons,
    buttonsIcon,
    iconPosition = 'start',
    sx,
    dataQa,
    buttonId,
    className,
  } = data;

  return (
    <Stack
      direction='row'
      justifyContent={
        styleType === 'dialog' ? 'center' : styleType === 'discount' ? 'flex-end' : 'flex-start'
      }
      alignItems={styleType === 'iconButton' ? 'flex-end' : 'center'}
      display='flex'
    >
      {styleType !== 'iconButton' ? (
        <StyledButton
          id={buttonId}
          className={className}
          data-qa={dataQa}
          type={buttonType}
          styleType={styleType}
          colorIcons={colorIcons}
          variant={variant}
          size={size}
          customWidth={customWidth}
          onClick={onClick}
          disabled={disabled}
          startIcon={buttonsIcon && iconPosition === 'start' ? icon : null}
          endIcon={buttonsIcon && iconPosition === 'end' ? icon : null}
          sx={sx}
        >
          {styleType === 'onlyIcon' || styleType === 'OPchat' ? icon : buttonName}
        </StyledButton>
      ) : (
        <StyledButtonIcon aria-label='add' onClick={onClick} disabled={disabled}>
          <AddIcon sx={{ fontSize: '32px' }} />
        </StyledButtonIcon>
      )}
      {iconInfo && <TooltipComponent tooltip={tooltip || ''} />}
    </Stack>
  );
};
