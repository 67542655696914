import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MyTextField, MyButton, MyDialog, MyPhoneInput } from '@/components';

export const SupportContentRenderer = ({
  open,
  onClose,
  control,
  dialogData,
  onSubmit,
  errors,
  setValue,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <MyDialog
        dataQa={dialogData.dataQa}
        open={open}
        onClose={onClose}
        dialogTitle={dialogData.dialogTitle.label}
        width={dialogData.width}
      >
        <form onSubmit={onSubmit} style={{ width: '100%', margin: '5px 0' }}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {dialogData.dialogContent.map((content, index) => {
              const commonProps = {
                data: content,
                control,
                errors: errors,
                disabled: content.disabled,
              };
              if (!content.initialVisibility) {
                return null;
              }
              switch (content.typeOfComponent) {
                case 'input':
                  return (
                    <MyTextField
                      key={`${content.name}-${index}`}
                      {...commonProps}
                    />
                  );
                case 'phoneInput':
                  return <MyPhoneInput 
                    key={content.name}
                    setValue={setValue}
                    {...commonProps} 
                  />;
                case 'button':
                  return (
                    <MyButton
                      key={`${content.name}-${index}`}
                      data={content}
                    />
                  );
              }
            })}
          </Stack>
        </form>
      </MyDialog>
    </>
  );
};
