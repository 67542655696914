import { useEffect, useState, FC } from 'react';
import { FormControlLabel, FormGroup, Checkbox, Stack } from '@mui/material';

import styles from './FormCheckbox.module.scss';

interface IMyCheckboxProps {
  data: {
    name: string;
    label: string;
    checked?: boolean;
    widthAuto?: boolean;
  };
  onChange: (name: string, checked: boolean) => void;
}

export const MyCheckbox: FC<IMyCheckboxProps> = (props) => {
  const { data, onChange } = props;

  const [value, setValue] = useState<boolean>(data.checked || false);

  useEffect(() => setValue(data.checked || false), [data.checked]);

  const handleCheckboxChange = (isChecked: boolean) => {
    setValue(isChecked);
    onChange(data.name, isChecked);
  };

  return (
    <Stack sx={{ width: props.data.widthAuto ? 'auto' : '100%' }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={<span className={styles.checked} />}
              icon={<span />}
              checked={value}
              onChange={(_, checked) => handleCheckboxChange(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={data.label}
          className={styles.label}
        />
      </FormGroup>
    </Stack>
  );
};
