import { AdsFilteringType, NewBuildingsFilteringType, ClientRequestFilteringType } from '@/types';

interface FilterAddressParamsType {
  addressParamAddress: string | undefined;
  addressParamLat: string | undefined;
  addressParamLong: string | undefined;
  addressParamName: string | undefined;
  addressParamPlaceId: string | undefined;
}

export const setAddressParamsInFilteringObj = (
  filteringObj: AdsFilteringType | NewBuildingsFilteringType | ClientRequestFilteringType | null | undefined
) => {
  if (!filteringObj) {
    return {};
  }

  const addressObj = filteringObj?.address?.[0];
  let adressParams: FilterAddressParamsType = {
    addressParamAddress: undefined,
    addressParamLat: undefined,
    addressParamLong: undefined,
    addressParamName: undefined,
    addressParamPlaceId: undefined,
  };
  if (addressObj) {
    const { addressEn, placeId, coordinatesLat, coordinatesLong, nameEn } = addressObj;
    adressParams = {
      addressParamAddress: addressEn,
      addressParamLat: coordinatesLat,
      addressParamLong: coordinatesLong,
      addressParamName: nameEn,
      addressParamPlaceId: placeId,
    };
  }

  let filteringObjWithoutAddress;
  if (filteringObj?.address) {
    const { address, ...rest } = filteringObj;
    filteringObjWithoutAddress = { ...rest };
  }

  return {
    ...filteringObjWithoutAddress,
    ...adressParams,
  };
};
