import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationTypeEnum } from '@/types';
import { authSetIsLoggedIn, appSetNotification } from '@/store';
import { Loader, Pagination } from '@/components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { localizationsProvider } from '@/providers';
import { Localization, LocalizationDataEdit } from './types/types';

import { PaginationType } from '@/types';
import LocalizationEdit from '../Dialogs/LocalizationEdit';
import LocalizationsSearch from './Components/LocalizationsSearch';
import { useChangeQueryParams } from '@/hooks';
import LocalizationsTable from './LocalizationsTable';

export const Localizations = (): JSX.Element => {
  const dispatch = useDispatch();

  const [openLocalizationEdit, setOpenLocalizationEdit] = useState<boolean>(false);
  const [rowId, setRowId] = useState<number | undefined>(undefined);

  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [limit, setLimit] = useState<number>(30);
  const [offset, setOffset] = useState<number>(0);

  const [itemsTotal, setItemsTotal] = useState<number>(0);

  const changeQueryParams = useChangeQueryParams('admin/localizations');
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const [localizations, setLocalizations] = useState<any[]>([]);

  const [languages, setLanguages] = useState<string>('en,ua,ar');
  const [namespaces, setNamespaces] = useState<string>('');
  const [keys, setKeys] = useState<string>('');

  const getLocalizationsList = async (
    newLimit?: number,
    newOffset?: number,
    newLanguages?: string,
    newNamespaces?: string,
    newKeys?: string
  ) => {
    const updatedOffset = newOffset !== undefined ? newOffset : offset;
    const { ok, data } = await localizationsProvider.getLocalizations(
      newLanguages !== undefined ? newLanguages : languages,
      newNamespaces !== undefined ? newNamespaces : namespaces,
      newKeys !== undefined ? newKeys : keys,
      newLimit || limit,
      updatedOffset
    );
    if (ok) {
      setLocalizations(transformItems(data.items));
      setItemsTotal(data.total);

      setPaginationObj({
        page: Math.floor(updatedOffset / (newLimit || limit)) + 1,
        offset: offset,
        limit: limit,
        total: data.total,
      });
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  };

  const transformItems = (items: Localization[]) => {
    return items.map((item) => {
      const translations: Record<string, string> = {};
      item.translations?.forEach((tr) => {
        translations[tr.language] = tr.translation;
      });
      return {
        ...item,
        translation: translations,
        translations: undefined,
      };
    });
  };

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setIsLoading(true);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    setPaginationObj({
      page: page,
      offset: newOffset,
      limit: limit,
      total: paginationObj?.total || 0,
    });
    changeQueryParams({ page: page });
    getLocalizationsList(limit, newOffset);
  };

  const handleSearch = (newLanguages: string, newNamespaces: string, newKeys: string) => {
    setIsLoading(true);
    setLanguages(newLanguages);
    setNamespaces(newNamespaces);
    setKeys(newKeys);
    getLocalizationsList(limit, offset, newLanguages, newNamespaces, newKeys);
  };

  useEffect(() => {
    getLocalizationsList();
  }, []);

  const createLocalization = async (data: LocalizationDataEdit) => {
    const createdLocalizationId = await localizationsProvider.createLocalizations({
      language: languages.split(',')[0],
      translation: data[`translate_${languages.split(',')[0]}`],
      namespace: data.namespace,
      alias: data.alias,
    });

    if (!createdLocalizationId) {
      dispatch(
        appSetNotification(NotificationTypeEnum.Error, 'error during localization creation')
      );
      return;
    }

    const promises: Promise<any>[] = [];
    languages.split(',').forEach((lang) => {
      if (data[`translate_${lang}`]) {
        const promise = localizationsProvider.updateLocalizations(createdLocalizationId, {
          language: lang,
          translation: data[`translate_${lang}`],
        });
        promises.push(promise);
      }
    });

    if (!promises.length) {
      dispatch(
        appSetNotification(NotificationTypeEnum.Error, 'error during localization creation')
      );
      return;
    }

    await Promise.all(promises.slice(1))
      .then(() => {
        getLocalizationsList();
      })
      .catch(() => {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, 'error during localization update')
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const editLocalization = async (data: LocalizationDataEdit) => {
    const promises: Promise<any>[] = [];
    languages.split(',').forEach((lang) => {
      if (data[`translate_${lang}`]) {
        const promise = localizationsProvider.updateLocalizations(parseInt(data.id), {
          language: lang,
          translation: data[`translate_${lang}`],
        });
        promises.push(promise);
      }
    });

    await Promise.all(promises)
      .then(() => {
        getLocalizationsList();
      })
      .catch(() => {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, 'error during localization update')
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteLocalization = async (id: number) => {
    setOpenLocalizationEdit(false);
    await localizationsProvider
      .deleteLocalizations(id)
      .then(() => {
        getLocalizationsList();
      })
      .catch(() => {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, 'error during localization delete')
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onRowClickHandler = (id?: number) => {
    setRowId(id);
    setOpenLocalizationEdit(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <LocalizationsSearch
            languages={languages}
            namespaces={namespaces}
            keys={keys}
            setLanguages={setLanguages}
            setNamespaces={setNamespaces}
            setKeys={setKeys}
            addLocalization={() => setOpenLocalizationEdit(true)}
            handleSearch={handleSearch}
          />
          <LocalizationsTable
            total={itemsTotal}
            languages={languages}
            localizations={localizations}
            onRowClickHandler={onRowClickHandler}
          />
          {!!paginationObj?.total && (
            <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
          )}
          <LocalizationEdit
            languages={languages}
            open={openLocalizationEdit}
            localization={localizations?.find((l) => l.id === rowId)}
            actions={{
              onCreate: (data: LocalizationDataEdit) => createLocalization(data),
              onEdit: (data: LocalizationDataEdit) => editLocalization(data),
              onDelete: (id: number) => deleteLocalization(id),
            }}
            onClose={() => {
              setRowId(undefined);
              setOpenLocalizationEdit(false);
            }}
          />
        </div>
      )}
    </>
  );
};
