import styles from './EmptyList.module.scss';
import { StyledButton } from '@/components/Header/stylesHeader';
import { useNavigate } from 'react-router-dom';
import AddAd from '../../Header/addAd.svg';
import ImageSearch from './emptySearch.png';
import Image from './emptyList.png';

interface EmptyListProps {
  description?: string;
  changedFilter?: boolean;
}

export const EmptyList = ({ changedFilter, description }: EmptyListProps) => {
  const navigate = useNavigate();

  const isButtonVisible = !changedFilter && !description;
  const descriptionText = description
    ? description
    : !changedFilter
      ? 'Ready to start listing? Add your first property and encourage interest!'
      : 'No search results. Change the search criteria to display advertisements.';

  return (
    <div className={styles.container}>
      <img
        className={styles.containerImg}
        src={!changedFilter ? Image : ImageSearch}
        alt='Empty list'
      />
      <div className={styles.description}>{descriptionText}</div>
      {isButtonVisible && (
        <StyledButton
          style={{ maxWidth: '220px', width: 'auto', marginTop: '20px' }}
          type='submit'
          variant='contained'
          color='primary'
          onClick={() => navigate('/ads/create')}
        >
          <img src={AddAd} alt='Add Ad' />
          Add more listing
        </StyledButton>
      )}
    </div>
  );
};
