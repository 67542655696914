import React, { memo, useCallback, useState } from 'react';
import { Ruler, Floor, Door, Buildings, NotePencil, FilePdf, TrashSimple } from '@/assets/pdf';
import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  Box,
  IconButton,
  Popover,
  MenuItem,
  Divider,
} from '@mui/material';
import { DataCardBlock } from './DataCardBlock';
import { ThreeDotsVerticalIcon } from '../AdsMyProperty/AdsMyListItem/svg';
import { IUnitSearchItems } from '@/types/responses';
import { UNITS_MODAL_TYPE } from './UnitsView.init';

const UnitCard = ({
  unit,
  isSelected = false,
  onClickUnit,
  onSelectUnit,
}: {
  unit: IUnitSearchItems;
  isSelected: boolean;
  onClickUnit: (modalType: string, unit: IUnitSearchItems[]) => () => void;
  onSelectUnit: (unitId: string, isChecked: boolean) => () => void;
}) => {
  // const [checked, setChecked] = useState(isSelected);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuItems = [
    { modalType: UNITS_MODAL_TYPE.editOne, iconSrc: NotePencil, text: 'Edit' },
    {
      modalType: UNITS_MODAL_TYPE.saveAsPdfOne,
      iconSrc: FilePdf,
      text: 'Save as PDF',
    },
    { modalType: UNITS_MODAL_TYPE.deleteOne, iconSrc: TrashSimple, text: 'Delete' },
  ];

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectUnit(unit.id, event.target.checked)();
    // setChecked(event.target.checked);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseAndOpenModal = useCallback(
    (modalType: string, unit: IUnitSearchItems) => () => {
      handleClose();
      onClickUnit(modalType, [unit])();
    },
    [onClickUnit, handleClose]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card
      key={unit.id}
      sx={{
        mb: 2,
        height: '112px',
        display: 'flex',
        gap: '16px',
        backgroundColor: '#F1F7FF',
        alignItems: 'center',
      }}
    >
      <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
      <img
        height='88'
        width='88'
        src={unit.media[0]?.fileUrl || ''}
        alt={`Floor plan for apartment number ${unit.apartmentNumber}`}
        style={{ width: '88px' }}
      />
      <CardContent sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <DataCardBlock title='Areas:' icon={Ruler} data={unit.areaSQFT.toString()} />
            <Divider orientation='vertical' flexItem />
            <DataCardBlock title='Floor:' icon={Floor} data={unit.floor.toString()} />
            <Divider orientation='vertical' flexItem />
            <DataCardBlock
              title='Apartment num.'
              icon={Door}
              data={unit.apartmentNumber.toString()}
            />
            <Divider orientation='vertical' flexItem />
            <DataCardBlock
              title='Unit type:'
              icon={Buildings}
              data={unit.constrationType || 'apartments'}
            />
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
              <Typography color='#2A3842' fontSize='14px'>
                Price:
              </Typography>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Typography color='#1650FF' fontSize='16px' fontWeight='bold'>
                  {unit.price}
                </Typography>
                <Typography color='#2A3842' fontSize='16px' fontWeight='bold'>
                  {unit.currency || 'AED'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <IconButton onClick={handleClick} aria-label='more options'>
            <ThreeDotsVerticalIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {menuItems.map(({ modalType, iconSrc, text }) => (
              <MenuItem
                key={text}
                onClick={handleCloseAndOpenModal(modalType, unit)}
                sx={{ gap: '8px' }}
              >
                <img src={iconSrc} alt={text} />
                {text}
              </MenuItem>
            ))}
          </Popover>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(UnitCard);
