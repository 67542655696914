import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { State } from '@/types';
import MainInfo from '../ViewProfile/MainInfo';
import EditInfo from './EditInfo';
import { dialogUserTypeData } from '../../utils/dialogDescription';
import { getKindLabel } from '@/utils';
import styles from './EditInfo.module.scss';

const EditProfile = () => {
  const user = useSelector(({ auth }: State) => auth.user);
  const userType = user.kind ? getKindLabel(user.kind) : '';
  return (
    <div className={styles.mainContainer}>
      <MainInfo user={user} userType={userType} type='edit' dataQa='edit_profile'/>
      <EditInfo user={user} />
    </div>
  );
};

export default EditProfile;
