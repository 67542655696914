export const messageContainerStyle = () => {
  return {
    alignSelf: 'flex-start',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '65%',
    minWidth: '340px',
    clear: 'both',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
  };
};

export const messageWrapperStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    margin: '0 5px 10px',
    float: 'left',
    flexShrink: '0',
  };
};

export const messageDetailStyle = () => {
  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  };
};

export const messageFileContainerStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageFileWrapperStyle = (context) => {
  return {
    display: 'inline-block',
    borderRadius: '4px 14px 14px 14px',
    border: `1px solid ${context.theme.borderColor.primary}`,
    backgroundColor: `${context.theme.backgroundColor.white}`,
    color: `${context.theme.color.secondary}`,
    padding: '8px 16px',
    alignSelf: 'flex-start',
    width: 'auto',
    '> a': {
      background: '0 0',
      textDecoration: 'none',
      backgroundColor: 'transparent',
      color: `${context.theme.color.primary}`,
      width: 'auto',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:visited, &:active, &:hover': {
        color: `${context.theme.color.primary}`,
        textDecoration: 'none',
      },
      label: {
        cursor: 'pointer',
      },
    },
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '25px',
    gap: '7px',
  };
};

export const messageReactionsWrapperStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '5px',
    paddingTop: '6px',
  };
};

export const iconStyle = (img, context) => {
  return {
    width: '24px',
    height: '24px',
    display: 'inline-block',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.color.primary}`,
    marginRight: '8px',
  };
};
