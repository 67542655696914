import { useSelector } from 'react-redux';
import { State } from '@/types';
import { Dashboard, NewLogIn, Ads } from '@/components';

export const LogInPage = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  return (
    <Dashboard>
      {isLoggedIn ? <Ads /> : <NewLogIn />}
    </Dashboard>
  );
};
