import { dialogUserTypeData } from '../components/AccountManagement/utils/dialogDescription';

export const getKindLabel = (kind: string): string => {
  if (!dialogUserTypeData || !dialogUserTypeData.dialogContent) {
    return kind;
  }
  // @ts-ignore
  const foundItem = dialogUserTypeData.dialogContent[0]?.radioGroup.find(
    (item) => item.name === kind
  );
  return foundItem ? foundItem.label : kind;
};
