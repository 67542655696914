declare const rewardful: any;
import { useState, useEffect, useRef, ChangeEvent } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChangeQueryParams } from '@/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Stack, TextField, Typography, InputAdornment, Grid } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { emailConfirmationData } from './Utils/signUpDescription';
import {
  UserAgencyStateLicense,
  AuthSignupRequestType,
  UserModel,
  NotificationTypeEnum,
  AuthResetPasswordRequestType,
} from '@/types';
import { authLogin, appSetNotification, appSetLoading } from '@/store';
import { LabelComponent, MyTextField } from '@/components';
import { authProvider } from '../../../providers';
import {
  StyledSquareTextField,
  StyleLabel,
  StyledButtonSubmit,
  CenterAlignStyleText,
  StyledButtonText,
} from '../../uiComponents/styled';

interface IData {
  firstNum: string;
  secondNum: string;
  thirdNum: string;
  fourthNum: string;
}

interface IState {
  attemptCounter: number;
  codeCounter: number;
  error: boolean;
  formInformation: Record<string, unknown>;
  countdown: number | null;
  canResend: boolean;
  flag: boolean;
  time: boolean;
  timeoutId: NodeJS.Timeout | null;
  dialogTitleDescription: string;
  messageError: React.ReactNode;
  countdownTimer: NodeJS.Timeout | null;
}

const fields: Array<'firstNum' | 'secondNum' | 'thirdNum' | 'fourthNum'> = [
  'firstNum',
  'secondNum',
  'thirdNum',
  'fourthNum',
];

const defaultState: IState = {
  attemptCounter: 3,
  codeCounter: 3,
  error: false,
  formInformation: {},
  countdown: null,
  canResend: false,
  flag: false,
  time: false,
  timeoutId: null,
  dialogTitleDescription: '',
  messageError: '',
  countdownTimer: null,
};

const TIME_DELAY = 600000;
const COUNT_DOWN = 120;

const typographyH4Style = {
  textTransform: 'uppercase',
  fontSize: {
    xs: '24px',
    sm: '28px',
  },
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontWeight: '700',
};
const typographyBody1Style = {
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontSize: 16,
  color: '#2A3842',
};

export const EmailConfirmation = ({
  helperText,
  error,
  setError,
  user,
  userDataSubmit,
  loginDataSubmit,
  type,
  setLoginDataSubmit,
  stepValue,
  setStepValue,
  inviteCode,
}: {
  helperText?: string;
  error: boolean;
  setError: (error: boolean) => void;
  user?: UserAgencyStateLicense;
  userDataSubmit?: AuthSignupRequestType;
  loginDataSubmit?: AuthResetPasswordRequestType;
  type?: string;
  setLoginDataSubmit?: (value: AuthResetPasswordRequestType) => void;
  stepValue?: number;
  setStepValue?: (stepValue: number) => void;
  inviteCode?: string;
}) => {
  const navigate = useNavigate();
  const isSignUp = type === 'signUp';
  const changeQueryParams = useChangeQueryParams(isSignUp ? 'signUp' : 'login');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  useEffect(() => {
    if (isSignUp) {
      changeQueryParams({ ...queryParams, step: 'five' });
    }
  }, []);

  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const fourthRef = useRef<HTMLInputElement>(null);
  const refs = [firstRef, secondRef, thirdRef, fourthRef];
  const [state, setState] = useState<IState>(defaultState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<IData>({
    defaultValues: fields.reduce((acc, field) => ({ ...acc, [field]: '' }), {}),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) {
      const focusTimeout = setTimeout(() => {
        if (firstRef.current) {
          firstRef.current.focus();
        }
      }, 0);
  
      const timeoutId = setTimeout(() => {
        setState(prev => ({ ...prev, time: true, timeoutId: null }));
      }, TIME_DELAY);
  
      const countdownTimer = setInterval(() => {
        setState(prev => {
          if (prev.countdown !== null) {
            const newCountdown = prev.countdown - 1;
            if (newCountdown < 1) {
              clearInterval(countdownTimer);
              return { ...prev, canResend: true, countdown: null, countdownTimer: null };
            }
            return { ...prev, countdown: newCountdown };
          }
          return prev;
        });
      }, 1000);
  
      setState(prev => ({
        ...prev,
        timeoutId,
        countdownTimer,
        countdown: COUNT_DOWN,
        canResend: false
      }));
  
      return () => {
        clearTimeout(focusTimeout);
        if (timeoutId) clearTimeout(timeoutId);
        if (countdownTimer) clearInterval(countdownTimer);
      };
    } else {
      if (state.timeoutId) clearTimeout(state.timeoutId);
      if (state.countdownTimer) clearInterval(state.countdownTimer);
    }
  }, [type, state.flag]);

  const handleClose = () => {
    reset();
    if (state.countdownTimer) clearInterval(state.countdownTimer);
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
      setState((prev) => ({
        ...prev,
        time: false,
      }));
    }
    setState(defaultState);
  };

  const handleFailure = (code: string) => {
    if (code) {
      if (state.attemptCounter === 1) {
        setState((prev) => ({
          ...prev,
          codeCounter: 0,
          error: true,
          messageError: (
            <span style={{ fontWeight: 600 }}>
              {/* Attention! You entered the wrong code three times. Wait 20 minutes before attempting
              again. */}
            </span>
          ),
        }));
        if (state.countdownTimer) clearInterval(state.countdownTimer);
      } else {
        setState((prev) => ({
          ...prev,
          attemptCounter: prev.attemptCounter - 1,
          error: true,
          messageError: (
            <span>
              The entered verification code is incorrect. Please double-check and try again. You
              have <strong>{prev.attemptCounter - 1}</strong> attempts left.
            </span>
          ),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        error: true,
        messageError: <span style={{ fontWeight: 600 }}>Code should not be empty.</span>,
      }));
    }
  };

  const onSubmit: SubmitHandler<IData> = async (data) => {
    if (!userDataSubmit && !loginDataSubmit) {
      console.error('userDataSubmit is undefined');
      return;
    }
    const code = data.firstNum + data.secondNum + data.thirdNum + data.fourthNum;
    if (code) {
      if (isSignUp) {
        const dataSubmit = { ...userDataSubmit, code } as AuthSignupRequestType;
        if (dataSubmit) {
          setIsLoading(true);
          const { ok, data, status, message } = await authProvider.signupFinish(dataSubmit);
          if (ok) {
            dispatch(authLogin(data as UserModel));
            handleClose();
            localStorage.removeItem('inviteCode');
            localStorage.removeItem('referralCode');
            window.addEventListener('load', () => {
              rewardful('convert', { email: dataSubmit.email });
            });
            if (inviteCode) {
              navigate(`/invitelink/${inviteCode}`);
            } else {
              navigate('/profile/settings');
            }
          } else {
            const messageErr = status === 400 ? 'Code should not be empty' : message;
            dispatch(appSetNotification(NotificationTypeEnum.Error, messageErr, status));
            handleFailure(code);
          }
          setIsLoading(false);
        }
      } else {
        const dataSubmit = { ...loginDataSubmit, code } as AuthResetPasswordRequestType;
        if (dataSubmit) {
          setIsLoading(true);
          const { ok, data, status, message } = await authProvider.postRecoverPasswordCheck(dataSubmit);
          if (ok && setLoginDataSubmit && setStepValue && stepValue) {
            setStepValue(stepValue + 1);
            setLoginDataSubmit(dataSubmit);
            handleClose();
          } else {
            const messageErr = status === 400 ? 'Code should not be empty' : message;
            dispatch(appSetNotification(NotificationTypeEnum.Error, messageErr, status));
            handleFailure(code);
          }
          setIsLoading(false);
        }
      }
    } else {
      handleFailure(code);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
      const prevInput = refs[index - 1].current;
      if (prevInput) {
        prevInput.focus();
        prevInput.value = '';
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      const allFieldsFilled = fields.every((fieldName) => watch(fieldName));
      if (allFieldsFilled) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    if (text.length === 4 && /^[0-9]{4}$/.test(text)) {
      const chars = text.split('');
      chars.forEach((char, index) => {
        const fieldName = fields[index];
        if (fieldName) {
          setValue(fieldName, char, { shouldValidate: true });
        }
      });
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (e.target.value.length === 1 && index < refs.length - 1) {
      const nextInput = refs[index + 1].current;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleResendClick = async () => {
    setState((prev) => ({
      ...prev,
      countdown: COUNT_DOWN,
      flag: !prev.flag,
      attemptCounter: 3,
      error: false,
      codeCounter: prev.codeCounter - 1,
    }));
    if (isSignUp) {
      const dataSubmit = { ...userDataSubmit } as AuthSignupRequestType;
      if (userDataSubmit) {
        dispatch(appSetLoading(true));
        const { ok, message, status } = await authProvider.signupStart(dataSubmit);
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        }
        dispatch(appSetLoading(false));
      }
    } else {
      const dataSubmit = { ...loginDataSubmit } as AuthResetPasswordRequestType;
      if (loginDataSubmit) {
        dispatch(appSetLoading(true));
        const { ok, message, status } = await authProvider.postRecoverPasswordRequest(dataSubmit);
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        }
        dispatch(appSetLoading(false));
      }
    }
  };

  const isPhone = !isSignUp && /^\+?[1-9]\d{1,14}$/.test(loginDataSubmit?.login || '');

  const buttonName = isSignUp ? 'Get Started' : 'Confirm';

  const buttonId = isSignUp ? 'id_button_get_started_registration' : 'id_button_confirm_forgot_password';
  const className = isSignUp ? 'className_button_get_started_registration' : 'className_button_confirm_forgot_password';

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {emailConfirmationData.bodyTitle.label}
        </Typography>
        {isSignUp ? (
          <Typography variant='body1' sx={typographyBody1Style}>
            A one-time verification code has been sent to your mail
            <strong>{` "${userDataSubmit?.email}" `}</strong>and phone number
            <strong>{` "${userDataSubmit?.phone}"`}</strong>. Please enter it in the field below.
          </Typography>
        ) : (
          <Typography variant='body1' sx={typographyBody1Style}>
            {`A one-time verification code has been sent to your ${isPhone ? 'phone number' : 'mail'}`}
            <strong>{` "${loginDataSubmit?.login}" `}</strong>. Please enter it in the field below.
          </Typography>
        )}
      </Stack>
      <form className='form' onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack
            direction='row'
            spacing={1}
            justifyContent='space-evenly'
            alignItems='center'
            display='flex'
          >
            {fields.map((fieldName, index) => (
              <Stack key={index} justifyContent='center'>
                <Controller
                  control={control}
                  name={fieldName}
                  render={({ field }) => (
                    <StyledSquareTextField
                      {...field}
                      inputRef={refs[index]}
                      autoComplete={fieldName}
                      name={fieldName}
                      variant='outlined'
                      fullWidth
                      inputProps={{
                        maxLength: 1,
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleKeyDown(e, index),
                      }}
                      onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) =>
                        handleKeyPress(event, index)
                      }
                      onPaste={handlePaste}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(e, index);
                      }}
                    />
                  )}
                />
              </Stack>
            ))}
          </Stack>

          {state.time ? (
            <Grid>
              <Grid item xs={12}>
                <StyleLabel
                  style={{
                    textAlign: 'center',
                    color: 'red',
                    marginBottom: '15px',
                  }}
                >
                  10 minutes have passed since the code was sent. Your code is not valid.
                </StyleLabel>
              </Grid>
              <Grid item xs={12}>
                <StyledButtonSubmit
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={handleClose}
                  disabled
                >
                  {buttonName}
                </StyledButtonSubmit>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              {state.error && state.attemptCounter !== 0 && (
                <Grid item xs={12}>
                  <StyleLabel
                    style={{
                      textAlign: 'center',
                      color: 'red',
                      marginBottom: '15px',
                    }}
                  >
                    {' '}
                    {state.messageError}
                  </StyleLabel>
                </Grid>
              )}
              {state.codeCounter !== 0 ? (
                <Grid item xs={12}>
                  <CenterAlignStyleText>
                    {state.canResend ? (
                      <StyledButtonText variant='text' disableRipple onClick={handleResendClick}>
                        You can now resend the code
                      </StyledButtonText>
                    ) : (
                      state.countdown !== null && (
                        <span>Resend after {state.countdown} seconds</span>
                      )
                    )}
                  </CenterAlignStyleText>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <StyleLabel
                    style={{
                      textAlign: 'center',
                      color: 'red',
                      marginBottom: '15px',
                      fontWeight: '600',
                    }}
                  >
                    {' '}
                    Attention! Maximum limit of verification code requests exceeded. Wait 20 minutes
                    before attempting again.
                  </StyleLabel>
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledButtonSubmit
                  id={buttonId}
                  className={className}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isLoading || state.codeCounter === 0}
                >
                  {buttonName}
                </StyledButtonSubmit>
              </Grid>
            </Grid>
          )}
        </Stack>
      </form>
    </>
  );
};
