import styles from './EmptyList.module.scss';
import Image from './emptyList.png';

interface EmptyListProps {
  description: string;
}

export const EmptyList = ({ description }: EmptyListProps) => (
  <div className={styles.container}>
    <img className={styles.containerImg} src={Image} alt='Empty list' />
    <div className={styles.description}>{description}</div>
  </div>
);
