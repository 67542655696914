import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  AdsFilteringType,
  RentPeriodEnum,
  UserSettingsResponseType,
  TransactionTypeEnum,
} from '@/types';

import { classifyPrice } from '@/utils';

const activityKindValues = [
  { label: 'Sale', value: ActivityKindEnum.Sale },
  { label: 'Rent', value: ActivityKindEnum.Rent },
];
const completionStatusValues = [
  { label: 'Ready', value: CompletionStatusEnum.Ready },
  { label: 'Off-Plan', value: CompletionStatusEnum.OffPlan },
];
const transactionTypeValues = [
  { label: 'Ready', value: TransactionTypeEnum.Ready },
  { label: 'Resale', value: TransactionTypeEnum.Resale },
  { label: 'Distress', value: TransactionTypeEnum.Distress },
];
const rentFrequencyValues = [
  { label: 'Yearly', value: RentPeriodEnum.Yearly },
  { label: 'Monthly', value: RentPeriodEnum.Monthly },
  { label: 'Weekly', value: RentPeriodEnum.Weekly },
  { label: 'Daily', value: RentPeriodEnum.Daily },
];
const propertyKindValues = [
  { label: 'Apartment', value: PropertyKindEnum.Apartment },
  { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
  { label: 'Villa', value: PropertyKindEnum.Villa },
  { label: 'Floor', value: PropertyKindEnum.Floor },
  { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
  { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
  { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
  { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
  { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
  { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
  { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
  { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
  { label: 'Office', value: PropertyKindEnum.Office },
  { label: 'Shop', value: PropertyKindEnum.Shop },
];
const bedsValues = [
  { label: 'Studio', value: AdFieldBedsEnum.Studio },
  { label: '1', value: AdFieldBedsEnum.One },
  { label: '2', value: AdFieldBedsEnum.Two },
  { label: '3', value: AdFieldBedsEnum.Three },
  { label: '4', value: AdFieldBedsEnum.Four },
  { label: '5', value: AdFieldBedsEnum.Five },
  { label: '6', value: AdFieldBedsEnum.Six },
  { label: '7', value: AdFieldBedsEnum.Seven },
  { label: '8+', value: AdFieldBedsEnum.EightPlus },
];
const bathsValues = [
  { label: '1', value: AdFieldBathsEnum.One },
  { label: '2', value: AdFieldBathsEnum.Two },
  { label: '3', value: AdFieldBathsEnum.Three },
  { label: '4', value: AdFieldBathsEnum.Four },
  { label: '5', value: AdFieldBathsEnum.Five },
  { label: '6', value: AdFieldBathsEnum.Six },
  { label: '7', value: AdFieldBathsEnum.Seven },
  { label: '8+', value: AdFieldBathsEnum.EightPlus },
];

const emptyFilteringObj: AdsFilteringType = {
  address: [],
  activityKind: undefined,
  propertyKind: undefined,
  areaMin: undefined,
  areaMax: undefined,
  locationId: undefined,
  beds: undefined,
  baths: undefined,
  priceMin: undefined,
  priceMax: undefined,
  rentFrequency: undefined,
  completionStatus: undefined,
  transactionType: undefined,
};

const predefinedVars = {
  activityKindValues,
  completionStatusValues,
  transactionTypeValues,
  rentFrequencyValues,
  propertyKindValues,
  bedsValues,
  bathsValues,
  emptyFilteringObj,
};

export const getLabelsAndTexts = (
  filteringObj: AdsFilteringType,
  userSettings: UserSettingsResponseType | null
) => {
  const { beds, baths, areaMin, areaMax, priceMin = 0, priceMax = 0 } = filteringObj;

  const formatLabel = (value: number | string, single: string, plural: string) =>
    value === 1 ? `${value} ${single}` : `${value} ${plural}`;

  const bedsLabel =
    beds === AdFieldBedsEnum.Studio
      ? 'Studio'
      : beds
        ? formatLabel(beds as AdFieldBedsEnum, 'Bed', 'Beds')
        : '';

  const bathsLabel = baths ? formatLabel(baths as AdFieldBathsEnum, 'Bath', 'Baths') : '';

  const bedsBathsText =
    bedsLabel || bathsLabel
      ? `${bedsLabel} / ${bathsLabel}`.trim().replace(' / ', ' & ')
      : 'Beds & Baths';

  const areaLabel =
    areaMin || areaMax
      ? `${areaMin} - ${areaMax}`
      : `Area (${userSettings?.areaUnits.toLowerCase()})`;

  const priceLabel =
    priceMin || priceMax
      ? `${classifyPrice(priceMin)} - ${classifyPrice(priceMax)}`
      : `Price (${userSettings?.currency ? userSettings?.currency : 'AED'})`;

  return { bedsBathsText, areaLabel, priceLabel };
};

const hasStringValue = (value: string | undefined) =>
  value && value !== 'any' && value.trim() !== '';
const hasValidNumber = (value: number | undefined) => value !== undefined && value > 0;

export const getMoreFiltersControlsVisibility = (filterObj: AdsFilteringType) => {
  //! morefilters show
  const hasQuarterOrYear = !!(
    (filterObj.handover?.quarter && filterObj.handover.quarter.trim() !== '') ||
    (filterObj.handover?.year && filterObj.handover.year.trim() !== '')
  );
  // const isMoreFiltersVisible =
  //   hasQuarterOrYear ||
  //   hasStringValue(filterObj.furniture) ||
  //   hasStringValue(filterObj.renovation) ||
  //   hasStringValue(filterObj.floor) ||
  //   hasValidNumber(filterObj.floorsNumFrom) ||
  //   hasValidNumber(filterObj.floorsNumTo) ||
  //   hasStringValue(filterObj.keywords) ||
  //   (filterObj?.creator?.name && filterObj?.creator.name?.length > 0) ||
  //   hasStringValue(filterObj.handover?.quarter) ||
  //   hasStringValue(filterObj.handover?.year) ||
  //   hasStringValue(filterObj.downPayment) ||
  //   filterObj.postHandover ||
  //   // hasStringValue(filterObj.propertyKind?.[0]) ||
  //   false;

  const isResetFiltersVisible =
    hasQuarterOrYear ||
    hasStringValue(filterObj.downPayment ? filterObj.downPayment.toString() : undefined) ||
    filterObj.postHandover ||
    hasStringValue(filterObj.keywords) ||
    hasStringValue(filterObj.activityKind) ||
    hasValidNumber(filterObj.areaMax) ||
    hasValidNumber(filterObj.areaMin) ||
    hasStringValue(filterObj.baths as AdFieldBathsEnum) ||
    hasStringValue(filterObj.beds as AdFieldBedsEnum) ||
    hasStringValue(filterObj.furniture as string) ||
    hasStringValue(filterObj.renovation as string) ||
    hasStringValue(filterObj.floor as string) ||
    hasValidNumber(filterObj.floorsNumFrom) ||
    hasValidNumber(filterObj.floorsNumTo) ||
    (filterObj?.creator?.name && filterObj?.creator.name?.length > 0) ||
    hasValidNumber(filterObj.priceMax as number) ||
    hasValidNumber(filterObj.priceMin as number) ||
    hasStringValue(filterObj.propertyKind?.[0]) ||
    false;

  return { isResetFiltersVisible };
};

export { predefinedVars };
