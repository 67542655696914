import * as yup from 'yup';

import { AdFieldCurrencyEnum, ActivityKindEnum, RentPeriodEnum, PropertyKindEnum, AdFieldBedsEnum, AdFieldBathsEnum } from '@/types/enums';

export const clientRequestSchemasForm = yup.object().shape({
  typeOfActivity: yup
    .string()
    .oneOf(
      [ActivityKindEnum.Rent, ActivityKindEnum.Sale],
      'Choose one value from the list'
    )
    .required('Type of activity is a required field'),

  period: yup
    .string()
    .oneOf(
      [RentPeriodEnum.Monthly, RentPeriodEnum.Yearly, RentPeriodEnum.Daily, RentPeriodEnum.Weekly],
      'Choose one value from the list'
    )
    .nullable(),

  propertyKind: yup
    .string()
    .oneOf(
      [
        PropertyKindEnum.Apartment,
        PropertyKindEnum.Townhouse,
        PropertyKindEnum.Villa,
        PropertyKindEnum.Floor,
        PropertyKindEnum.Penthouse,
        PropertyKindEnum.ResidentialLand,
        PropertyKindEnum.VillaCompound,
        PropertyKindEnum.HotelApartment,
        PropertyKindEnum.CommercialVilla,
        PropertyKindEnum.Warehouse,
        PropertyKindEnum.IndustrialLand,
        PropertyKindEnum.CommercialLand,
        PropertyKindEnum.Office,
        PropertyKindEnum.LabourCamp,
        PropertyKindEnum.CommercialBuilding,
        PropertyKindEnum.Shop,
      ],
      'Choose one value from the list'
    )
    .required('Property type is a required field'),

  areaFrom: yup
    .number()
    .transform((value, originalValue) => (typeof originalValue === 'string' && originalValue.trim() === '' ? null : value))
    .min(1, '"Area from" must be more than 0')
    .nullable(), // Сделать поле необязательным
  areaTo: yup
    .number()
    .transform((value, originalValue) => (typeof originalValue === 'string' && originalValue.trim() === '' ? null : value))
    .min(1, '"Area from" must be more than 0')
    .when('areaFrom', (areaFrom, schema) => {
      return areaFrom
        ? schema.moreThan(yup.ref('areaFrom'), '"Area to" must be greater than or equal to "Area from"')
        : schema;
    })
    .nullable(), // Сделать поле необязательным

  // bedsArr: yup
  //   .string()
  //   .oneOf(
  //     [
  //       AdFieldBedsEnum.Studio,
  //       AdFieldBedsEnum.One,
  //       AdFieldBedsEnum.Two,
  //       AdFieldBedsEnum.Three,
  //       AdFieldBedsEnum.Four,
  //       AdFieldBedsEnum.Five,
  //       AdFieldBedsEnum.Six,
  //       AdFieldBedsEnum.Seven,
  //       AdFieldBedsEnum.EightPlus,
  //     ],
  //     'Choose one value from the list'
  //   )
  //   .when('propertyKind', {
  //     is: (value: PropertyKindEnum) =>
  //       ![
  //         PropertyKindEnum.ResidentialLand,
  //         PropertyKindEnum.Office,
  //         PropertyKindEnum.Warehouse,
  //         PropertyKindEnum.IndustrialLand,
  //         PropertyKindEnum.CommercialLand,
  //         PropertyKindEnum.CommercialBuilding,
  //         PropertyKindEnum.Shop,
  //         null,
  //         undefined,
  //       ].includes(value),
  //     then: (schema) => schema.required('Beds is a required field'),
  //   })
  //   .nullable(),
  // baths: yup
  //   .string()
  //   .oneOf(
  //     [
  //       AdFieldBathsEnum.One,
  //       AdFieldBathsEnum.Two,
  //       AdFieldBathsEnum.Three,
  //       AdFieldBathsEnum.Four,
  //       AdFieldBathsEnum.Five,
  //       AdFieldBathsEnum.Six,
  //       AdFieldBathsEnum.Seven,
  //       AdFieldBathsEnum.EightPlus,
  //     ],
  //     'Choose one value from the list'
  //   )
  //   .when('propertyKind', {
  //     is: (value: PropertyKindEnum) =>
  //       ![
  //         PropertyKindEnum.ResidentialLand,
  //         PropertyKindEnum.CommercialVilla,
  //         PropertyKindEnum.Warehouse,
  //         PropertyKindEnum.LabourCamp,
  //         PropertyKindEnum.IndustrialLand,
  //         PropertyKindEnum.CommercialLand,
  //         null,
  //         undefined,
  //       ].includes(value),
  //     then: (schema) => schema.required('Baths is a required field'),
  //   })
  //   .nullable(),

  currency: yup
    .string()
    .oneOf([AdFieldCurrencyEnum.AED, AdFieldCurrencyEnum.USD], 'Choose one value from the list')
    .required('Currency is a required field'),
  priceFrom: yup
    .number()
    .transform((value, originalValue) => (typeof originalValue === 'string' && originalValue.trim() === '' ? null : value))
    .nullable(),
    priceTo: yup
    .number()
    .transform((value, originalValue) => (typeof originalValue === 'string' && originalValue.trim() === '' ? null : value))
    .nullable()
    .test(
      'is-greater-than-priceFrom',
      '"Price to" must be greater than or equal to "Price from"',
      function (value) {
        const { priceFrom } = this.parent;
        if (value == null || isNaN(priceFrom)) {
          return true;
        }
        return value > priceFrom;
      }
    ),

  addressEn: yup.string().required('Location is a required field'),

  title: yup
    .string()
    .trim()
    .required('Title is a required field')
    .min(3, 'Title must be longer than or equal to 3 characters')
    .max(60, 'Title must be shorter than or equal to 60 characters'),
  description: yup
    .string()
    .trim()
    .max(800, 'Description must be shorter than or equal to 800 characters'),
});
