import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { NotificationTypeEnum, IDndFileUploaderProps } from '@/types';
import { AUTH_ACCESSTOKEN } from '@/constants';
import { appSetNotification } from '@/store';
import { Loader, MyButton } from '@/components';

import styles from './DndFileUploader.module.scss';

export const DndFileUploader = ({
  url,
  method = 'POST',
  type,
  labelButton,
  labelFormat,
  labelSize,
  limit,
  onUpload,
  disabled,
  accept,
  bodyData = {},
  uploadFileVarNameForFetch = 'file',
}: IDndFileUploaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  async function uploadAcceptedFile(file: File, extraBody: Record<string, string>): Promise<any> {
    const formData = new FormData();
    const token = localStorage.getItem(AUTH_ACCESSTOKEN);

    formData.append(uploadFileVarNameForFetch, file);

    Object.entries(extraBody).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      dispatch(
        appSetNotification(NotificationTypeEnum.Success, `${file.name} was successfully uploaded`)
      );
      return await response.json();
    } catch (error) {
      console.error('Error uploading file:', error);
      dispatch(appSetNotification(NotificationTypeEnum.Error, `Error uploading ${file.name}`));
    }
  }

  const processFile = async (file: File): Promise<void> => {
    const data = await uploadAcceptedFile(file, bodyData);
    if (!data) return;

    onUpload(data);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const limitedFiles = limit > 0 ? acceptedFiles.slice(0, limit) : acceptedFiles;

      for (const file of limitedFiles) {
        try {
          setIsLoading(true);
          await processFile(file);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          dispatch(appSetNotification(NotificationTypeEnum.Error, 'Error processing image file'));
        }
      }
    },
    [limit, type, processFile, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    disabled: disabled,
  });

  return (
    <div {...getRootProps()} className={styles.uploader}>
      <input {...getInputProps()} />
      {isLoading && <Loader />}
      <MyButton
        disabled={disabled}
        data={{
          buttonName: labelButton || 'Upload file',
          customWidth: '217px',
          variant: 'contained',
          buttonType: 'button',
        }}
      />
      <p className={styles.text}>or drag them here</p>
      {labelFormat && (
        <p style={{ textAlign: 'center' }}>
          <span>{labelFormat}</span>
        </p>
      )}
      {labelSize && (
        <p>
          <span>{labelSize}</span>
        </p>
      )}
    </div>
  );
};
