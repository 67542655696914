import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Button,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  ActivityKindEnum,
  AdFieldStatusEnum,
  UserKindEnum,
  AdModel,
  State,
  RentPeriodEnum,
  AdsFilteringType,
  NotificationTypeEnum,
  PropertyKindEnum,
  TransactionTypeEnum,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
  UserModel,
} from '@/types';
import { priceFormat } from '@/utils';
import { adsProvider } from '@/providers';
import { appSetNotification } from '@/store';

import styles from './AdsMyListItem.module.scss';
// import IconMarker from './svg/iconMarker.svg';
import IconCalendar from './svg/iconCalendar.svg';
import IconTimer from './svg/iconTimer.svg';
import IconTimerRed from './svg/iconTimerRed.svg';
import { ReactComponent as IconMarker } from './svg/iconMarker.svg';
import { MyDialog, MyButton, DialogTariffPlan } from '@/components';
import { ResponsibleUser } from '../../AdForm/ResponsibleUser';

import {
  ArchiveIcon,
  PDFIcon,
  EditIcon,
  PublishIcon,
  ThreeDotsVerticalIcon,
  UnpublishIcon,
  UpdateIcon,
  ViewIcon,
  RequestIcon,
} from './svg';

interface AdsListItemProps {
  item: AdModel;
  onChangeStatus: (id: string, status: AdFieldStatusEnum) => void;
  filteringObj?: AdsFilteringType;
  setChangeStatus: (value: string) => void;
  handleCheckboxChange: (id: string) => void;
  selectedAds: string[];
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}

type PreferredPropertyKinds =
  | 'shop'
  | 'office'
  | 'warehouse'
  | 'residentialLand'
  | 'industrialLand'
  | 'commercialLand';

export const AdsMyListItem = ({
  item: {
    activityKind,
    nameEn,
    addressEn,
    currency,
    id,
    price,
    propertyAdMedia,
    rentFrequency,
    status,
    title,
    updatedAt,
    convertedPrice,
    convertedCurrency,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    defaultRentFrequency,
    endOfPublishDayLeft,
    propertyKind,
    transactionType,
    convertedSellingPrice,
    locationId,
    fromXml,
    notifications,
    views,
  },
  onChangeStatus,
  filteringObj,
  setChangeStatus,
  handleCheckboxChange,
  selectedAds,
  user,
  handleClickOpenDialog,
}: AdsListItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formMethods = useForm();
  const { id: userId, kind: userKind, tariffPlan } = user;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [creatorId, setCreatorId] = useState<string>(userId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isTariffFree = tariffPlan?.name === UserTariffPlanEnum.Free;

  const activityKindLabels = {
    [ActivityKindEnum.Sale]: 'Sale',
    [ActivityKindEnum.Rent]: 'Rent',
  };
  const rentFrequencyLabels = {
    [RentPeriodEnum.Yearly]: 'Yearly',
    [RentPeriodEnum.Monthly]: 'Monthly',
    [RentPeriodEnum.Weekly]: 'Weekly',
    [RentPeriodEnum.Daily]: 'Daily',
  };

  const preferredPropertyKinds = [
    PropertyKindEnum.Shop,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ];

  const propertyKindLabel: Record<PreferredPropertyKinds, string> = {
    shop: 'Shop',
    office: 'Office',
    warehouse: 'Warehouse',
    residentialLand: 'Residential Land',
    industrialLand: 'Industrial Land',
    commercialLand: 'Commercial Land',
  };

  const removeAfterLastComma = (str: string) => {
    const lastCommaIndex = str.lastIndexOf(',');
    if (lastCommaIndex === -1) {
      return str;
    }
    return str.substring(0, lastCommaIndex);
  };

  const isPrefTitle = preferredPropertyKinds.includes(propertyKind);
  const titlePref = propertyKind && propertyKindLabel[propertyKind as PreferredPropertyKinds];
  const titleText = isPrefTitle ? `${titlePref} at ${nameEn}` : nameEn;
  const addressText = locationId ? removeAfterLastComma(addressEn || nameEn) : addressEn || nameEn;
  const activityLabel = activityKindLabels[activityKind];
  const isAgency = userKind === UserKindEnum.Agency;

  const handleChangeStatus = (status: AdFieldStatusEnum) => {
    setAnchorEl(null);
    if (isAgency && status === AdFieldStatusEnum.Published) {
      return setOpenAssignDialog(true);
    }
    onChangeStatus(id, status);
  };

  const handleUpdateOrAssign = async (newData: Partial<AdModel>, changeStatusSuffix: string) => {
    setIsLoading(true);

    const { ok, status, message } = await adsProvider.updateAdById(id, newData as AdModel);
    if (ok) {
      setChangeStatus(changeStatusSuffix + id);
      setAnchorEl(null);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleUpdate = () => {
    const newData = { updatedAt: new Date().toISOString() };
    handleUpdateOrAssign(newData, 'update');
  };

  const handleAssign = () => {
    const newData = { creatorId: creatorId };
    handleUpdateOrAssign(newData, 'assign');
  };

  const isSale = activityKind !== ActivityKindEnum.Rent;
  const filterRentFrequency = filteringObj?.rentFrequency;

  let newPrice = 0;
  if (isSale) {
    if (transactionType === TransactionTypeEnum.Distress) {
      newPrice = convertedSellingPrice || 0;
    } else {
      newPrice = convertedPrice || 0;
    }
  } else {
    const rentFrequency = filterRentFrequency || defaultRentFrequency;
    switch (rentFrequency) {
      case RentPeriodEnum.Yearly:
        newPrice = convertedPriceYearly || 0;
        break;
      case RentPeriodEnum.Monthly:
        newPrice = convertedPriceMonthly || 0;
        break;
      case RentPeriodEnum.Weekly:
        newPrice = convertedPriceWeekly || 0;
        break;
      case RentPeriodEnum.Daily:
        newPrice = convertedPriceDaily || 0;
        break;
    }
  }

  const isVerifyRequestLocation = !locationId && !fromXml;

  const imgUrl = isMobile
    ? `url('${propertyAdMedia[0]?.fileUrl}_382x133')`
    : `url('${propertyAdMedia[0]?.fileUrl}_88x88')`;

  return (
    <div className={styles.listItem}>
      <Checkbox
        className={styles.itemCheckbox}
        icon={<span />}
        checked={selectedAds.includes(id)}
        checkedIcon={<span className={styles.checked} />}
        onChange={() => handleCheckboxChange(id)}
      />
      <div
        className={styles.listItemImage}
        style={{
          backgroundImage: propertyAdMedia.length > 0 ? `${imgUrl}` : `url('/Img/newLogo.png')`,
        }}
      >
        {isVerifyRequestLocation && (
          <div className={styles.notLocation}>location not confirmed</div>
        )}
      </div>
      <div className={styles.addressBlock}>
        <div className={styles.titleBlock}>
          <Link href={`/ads/${id}`} title={titleText} className={styles.title}>
            {titleText}
          </Link>
          <div className={styles.adsId}>#{id}</div>
        </div>
        {(addressEn || nameEn) && (
          <div
            className={`${styles.itemAddress} ${isVerifyRequestLocation ? styles.itemAddressRed : styles.itemAddressBlack}`}
          >
            <div className={styles.menuIcon}>
              <IconMarker />
            </div>
            {addressText}
          </div>
        )}
        <div className={styles.statistic}>
          <div className={styles.view}>
            <div className={styles.menuIcon}>
              <ViewIcon />
            </div>
            Views: <span>{views}</span>
          </div>
          <div className={styles.request}>
            <div className={styles.menuIcon}>
              <RequestIcon />
            </div>
            Requests: <span>{notifications}</span>
          </div>
        </div>
      </div>
      <div className={styles.priceDateBlock}>
        {newPrice ? (
          <div className={styles.currency}>
            {activityLabel}: <span>{priceFormat(newPrice)}</span>
            {convertedCurrency}
            {!isSale && (
              <div className={styles.frequency}>
                <span style={{ fontSize: '14px', color: '#1650FF', fontWeight: 400 }}>
                  {defaultRentFrequency &&
                    rentFrequencyLabels[
                      filterRentFrequency
                        ? (filterRentFrequency as RentPeriodEnum)
                        : defaultRentFrequency
                    ]}
                </span>
              </div>
            )}
          </div>
        ) : null}
        <div className={styles.date}>
          <img src={IconCalendar} alt='' className={styles.menuIcon} />
          Update: <span>{moment(updatedAt).format('D MMMM, h:mm a')}</span>
        </div>
        {status === AdFieldStatusEnum.Published && (
          <div className={`${styles.timer} ${endOfPublishDayLeft > 2 ? '' : styles.endingTimer}`}>
            <img
              src={endOfPublishDayLeft > 2 ? IconTimer : IconTimerRed}
              alt=''
              className={styles.menuIcon}
            />
            Active until:
            <span>
              {endOfPublishDayLeft} {endOfPublishDayLeft === 1 ? 'day' : 'days'}
            </span>
          </div>
        )}
      </div>
      <div className={styles.pdfButtonSection}>
        <Button
          variant='contained'
          startIcon={<PDFIcon />}
          onClick={() =>
            isTariffFree
              ? handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)
              : navigate(`/ads/create-pdf/${id}`)
          }
          sx={{
            color: '#2A3842',
            backgroundColor: '#C5DFFF',
            textTransform: 'none',
            borderRadius: '14px',
            flex: 1,
            ':hover': {
              color: '#FFF',
            },
          }}
        >
          Save&nbsp;as&nbsp;PDF
        </Button>
        <IconButton
          sx={{
            width: '40px',
            height: '40px',
            border: '2px solid #C5DFFF',
            borderRadius: { xs: '14px', sm: '32px' },
            ':hover': {
              borderColor: '#1650FF',
              color: '#1650FF',
            },
          }}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          <ThreeDotsVerticalIcon />
        </IconButton>

        <Menu
          sx={{
            marginTop: '10px',
            '.MuiMenuItem-root': {
              display: 'flex',
              gap: '8px',
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => navigate(`/ads/edit/${id}`)}>
            <EditIcon />
            Edit
          </MenuItem>
          {status !== AdFieldStatusEnum.Published && (
            <MenuItem onClick={() => handleChangeStatus(AdFieldStatusEnum.Published)}>
              <PublishIcon />
              {isAgency ? 'Assign' : `Publish`}
            </MenuItem>
          )}
          {status !== AdFieldStatusEnum.Unpublished && (
            <MenuItem onClick={() => handleChangeStatus(AdFieldStatusEnum.Unpublished)}>
              <UnpublishIcon />
              Unpublish
            </MenuItem>
          )}
          {status !== AdFieldStatusEnum.Archived && (
            <MenuItem onClick={() => handleChangeStatus(AdFieldStatusEnum.Archived)}>
              <ArchiveIcon />
              Archive
            </MenuItem>
          )}
          {status === AdFieldStatusEnum.Published && (
            <MenuItem onClick={() => handleUpdate()}>
              <UpdateIcon />
              Update
            </MenuItem>
          )}
        </Menu>
      </div>
      <MyDialog
        open={openAssignDialog}
        onClose={() => setOpenAssignDialog(false)}
        dialogTitle='Responsible for announcement'
        width='750'
      >
        <FormProvider {...formMethods}>
          <ResponsibleUser withTitle={false} setCreatorId={setCreatorId} />
        </FormProvider>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '351px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => {
              handleAssign();
              setOpenAssignDialog(false);
            }}
          />
        </div>
      </MyDialog>
    </div>
  );
};
