/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme, shouldForwardProp } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { appSetNotification } from '@/store';
import { SortingOrderEnum, NotificationTypeEnum, ISubscription, ISubscriptionsTable } from '@/types';
import { Loader, TablePaginationActions } from '@/components';
import { adminProvider } from '@/providers';
import moment from 'moment';

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'weight',
})<{ color?: string; weight?: number }>(({ color, weight }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: color,
    fontWeight: weight,
    maxWidth: '180px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FCFF',
  },
}));

export const SubscriptionsTable = ({
  onClick,
  setTotalSubscriptions,
  subscriptionsTable
}: {
  onClick: (row: ISubscription, type: string) => void;
  setTotalSubscriptions: (value: number) => void;
  subscriptionsTable: ISubscriptionsTable;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<ISubscription[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('subId');

  const dispatch = useDispatch();

  const getAllSubscription = async () => {
    setIsLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    // const sort = [{
    //   field: orderBy,
    //   order: order.toUpperCase() as SortingOrderEnum,
    // }]
    const { ok, data, status, message } = await adminProvider.getAllSubscription({ limit, offset });
    if (ok && data) {
      const { items, total } = data;
      const newItems = items.map((item: ISubscription) => {
        const startDate = item.startDate ? moment(item.startDate).format('DD.MM.YYYY') : null;
        const endDate = item.endDate ? moment(item.endDate).format('DD.MM.YYYY') : null;
        return {...item, startDate, endDate}
      });
      setRows(newItems as ISubscription[]);
      setTotalCount(+total);
      setTotalSubscriptions(+total);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllSubscription();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCellProperties = (
    row: ISubscription,
    body: string
  ): { cell: string | number; color: string; weight: number } => {
    const cell = (row as ISubscription)[body as keyof ISubscription]
    const isGreen = cell === 'Active' || cell === 'Successful';
    const isOrange = cell === 'Suspended' || cell === 'Unsuccessful';
    const isRed = cell === 'Canceled' || cell === 'Return';

    const color = isGreen ? '#1CC700' : isOrange ? '#FF8C05' : isRed ? 'red' : '#2A3842';

    const weight = body === 'status' ? 600 : 400;

    return { cell, color, weight };
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, headerSort: string) => {
    const isAsc = orderBy === headerSort && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(headerSort);
  };

  const createSortHandler = (headerSort: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, headerSort);
  };

  if (isLoading) return <Loader />;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            {subscriptionsTable.header.map((header: string, index: number) => {
              const headerSort = subscriptionsTable.body[index];
              return (
                <StyledTableCell key={header}>
                  <TableSortLabel
                    active={orderBy === headerSort}
                    direction={orderBy === headerSort ? order : 'asc'}
                    onClick={createSortHandler(headerSort)}
                  >
                    {header}
                    {orderBy === headerSort ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: ISubscription, index) => (
            <StyledTableRow key={index} onClick={() => onClick(row, 'subscription')}>
              {subscriptionsTable.body.map((body: string, bodyIndex: number) => {
                const { cell, color, weight } = getCellProperties(row, body);
                return (
                  <StyledTableCell
                    key={bodyIndex}
                    component='th'
                    scope='row'
                    color={color}
                    weight={weight}
                  >
                    {cell}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              // colSpan={4}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
