import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { offPlanProvider } from '@/providers';
import { locationProvider } from '@/providers/locationProvider';
import { appSetNotification } from '@/store';
import { offPlanSchemaForm } from '@/schemas';
import { Loader, MyButton, MyDialog, PageNotFound, SectionHeader } from '@/components';

import { PropertyInformation } from './PropertyInformation';
import { PropertyPrice } from './PropertyPrice';
import { PropertyLocation } from './PropertyLocation';
import { FeaturesAmenities } from './FeaturesAmenities';
import { PropertyDescription } from './PropertyDescription';
import { UploadMedia, generateJsomToPostMedia } from './UploadMedia';
import { AgencyFee } from './AgencyFee';
import { ForecastAndActualData } from './ForecastAndActualData';
import { PaymentPlan } from './PaymentPlan';
import {
  getInintOffPlanData,
  initFormData,
  buttonsConfig,
  photoUploaderProps,
  videoUploaderProps,
  brochureUploaderProps,
} from './OffPlanAdmin.init';
import {
  getPropertyInformationFromState,
  getPropertyLocationState,
  getAmentities,
  getMediaGallery,
  prepareData,
} from './OffPlanAdmin.utils';
import styles from './OffPlanAdmin.module.scss';
import type { IButtonConfig, IButtonHandlers } from './OffPlanAdmin.types';
import {
  AdFieldStatusEnum,
  IOffPlanModel,
  NotificationTypeEnum,
  State,
  UserKindEnum,
} from '@/types';
import { LocationModel } from '@/types/location';
import { getDepthLabel } from '@/components/LocationAutocomplete/LocationAutocomplete';
const MIN_PHOTOS = 3;

function scrollToError(selector: string) {
  const elementForFocus = document.querySelector(selector);
  if (elementForFocus) {
    const offset = elementForFocus.getBoundingClientRect().top + window.scrollY - 150;
    window.scrollTo({
      behavior: 'smooth',
      top: offset,
    });
  }
}

export const OffplanAdmin = () => {
  const dispatch = useDispatch();
  const { kind: userKind } = useSelector(({ auth }: State) => auth.user);
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isEditForm = !!id;

  const [curOffPlanId, setCurOffPlanId] = useState<string>(id || '');
  const [mediaErrors, setMediaErrors] = useState<{ [key: string]: string } | undefined>();
  const [offPlanState, setOffPlanState] = useState<IOffPlanModel | undefined>();
  const [publishType, setPublishType] = useState<AdFieldStatusEnum>(AdFieldStatusEnum.Unpublished);
  const [isLoading, setIsLoading] = useState<boolean>(isEditForm);
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [isShownPublishedDialog, setIsShownPublishedDialog] = useState<boolean>(false);
  const [isFileUploaderTouched, setIsFileUploaderTouched] = useState(false);
  const [isLocation, setIsLocation] = useState<boolean>(true);
  const [isPublish, setIsPublish] = useState<boolean>(true);

  const updateOffPlanState = (field: Partial<IOffPlanModel>) => {
    const key = Object.keys(field)[0] as keyof IOffPlanModel;
    const value = field[key];
    setValue(key, value);
    setOffPlanState((prevState: any) => ({ ...prevState, ...field }));
  };

  const formMethods = useForm<IOffPlanModel>({
    resolver: yupResolver(offPlanSchemaForm) as any,
    mode: 'all',
    shouldFocusError: true,
  });

  const {
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
  } = formMethods;

  async function fetchData() {
    let data, response;
    setIsLoading(true);
    if (id) {
      try {
        response = await offPlanProvider.getAdById(id);
        if (response.ok && response.data) {
          data = response.data as IOffPlanModel;
        } else {
          dispatch(
            appSetNotification(NotificationTypeEnum.Error, response.message, response.status)
          );
          setIsLoading(false);
          return response;
        }
      } catch (error) {
        console.error('Error fetching ad data:', error);
        dispatch(
          appSetNotification(
            NotificationTypeEnum.Error,
            'Failed to fetch ad data.',
            response?.status
          )
        );
        setIsLoading(false);
        return response;
      }
    }

    const initResult = data ? getInintOffPlanData(data) : getInintOffPlanData();
    const { stateData, formData } = initResult;

    initFormData(formData, stateData, setValue);

    updateOffPlanState(stateData as IOffPlanModel);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setIsFileUploaderTouched(false);
  }, [isShownPublishedDialog]);

  useEffect(() => {
    const firstErrorElementKey = Object.keys(errors)[0];
    const hasMediaError = mediaErrors && Object.keys(mediaErrors).length > 0;

    if (firstErrorElementKey) {
      scrollToError('.Mui-error');
      setIsShownConfirmDialog(false);
    } else if (hasMediaError) {
      scrollToError('.media-error');
      setIsShownConfirmDialog(false);
    }
  }, [errors, mediaErrors]);

  const renderButtons = (buttons: IButtonConfig[], className: string) => {
    return (
      <div className={className}>
        {buttons.map((button: any) => (
          <MyButton key={button.key} data={{ ...button }} onClick={buttonHandlers[button.key]} />
        ))}
      </div>
    );
  };

  const handleCloseConfirmDialog = () => setIsShownConfirmDialog(false);

  const handleClosePublishedDialog = () => setIsShownPublishedDialog(false);

  const handleDonePublishedDialog = () => navigate(`/offplan/${curOffPlanId}`);

  if (isLoading) return <Loader />;

  if (userKind !== UserKindEnum.Admin) {
    return <PageNotFound />;
  }

  const handleCancel = () => navigate('/admin/offplans');

  const handleSave = () => {
    trigger();

    const hasErrors =
      Object.entries(errors).length > 0 || Object.entries(mediaErrors ?? {}).length > 0;

    if (!hasErrors && isFileUploaderTouched) {
      setIsShownConfirmDialog(true);
      setPublishType(AdFieldStatusEnum.Unpublished);
    }

    if (!isFileUploaderTouched) setIsFileUploaderTouched(true);
  };

  const handlePublish = () => {
    trigger();

    const hasErrors =
      Object.entries(errors).length > 0 || Object.entries(mediaErrors ?? {}).length > 0;

    if (!hasErrors && isFileUploaderTouched) {
      setIsShownConfirmDialog(true);
      setPublishType(AdFieldStatusEnum.Published);
    }

    if (!isFileUploaderTouched) setIsFileUploaderTouched(true);
  };

  const handleMediaUploaded = (data: {
    customError?: string;
    uploadType: string;
    count: number;
  }) => {
    const { customError, uploadType, count } = data;

    const errorCondition = customError
      ? mediaErrors?.[uploadType] !== customError
      : mediaErrors?.hasOwnProperty(uploadType);
    if (errorCondition) {
      const updatedMediaErrors = {
        ...mediaErrors,
        ...(customError ? { [uploadType]: customError } : {}),
      };
      if (!customError) delete updatedMediaErrors[uploadType];
      setMediaErrors(updatedMediaErrors);
    }

    if (!isFileUploaderTouched && count > 0) {
      setIsFileUploaderTouched(true);
    }
  };

  const submitHandler: SubmitHandler<IOffPlanModel> = async (values) => {
    const { id, media, pushInInstallments, ...allData } = values;
    setIsLoading(true);

    if (!pushInInstallments) {
      allData.paymentPlan = undefined;
    }

    const preparedValues = prepareData(allData);
    if (preparedValues?.forecast && !preparedValues?.forecast?.currentProgress) {
      preparedValues.forecast.currentProgress = 0;
    }
    if (!preparedValues.reviewLinkGoogle || preparedValues?.reviewLinkGoogle?.length === 0) {
      preparedValues.reviewLinkGoogle = null;
    }
    if (!preparedValues.serviceCharge) {
      preparedValues.serviceCharge = null;
    }

    let location = null;

    if (!isLocation) {
      setIsPublish(false);
      const { addressEn, coordinatesLat, coordinatesLong } = values;
      const { ok, data, status, message } = await locationProvider.createByMarker({
        name: addressEn,
        latitude: Number(coordinatesLat),
        longitude: Number(coordinatesLong),
      });
      if (!ok) {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, message || 'Unknown error', status)
        );
        setIsShownConfirmDialog(false);
        setIsLoading(false);
        return;
      } else {
        location = data as LocationModel;
        setIsPublish(true);
      }
    } else {
      setIsPublish(true);
    }
  
    const formData: any = {
      ...preparedValues,
      status: publishType,
      addressEn: location ? `${getDepthLabel(location)}, ${location[location.kind]}` : allData.addressEn,
      nameEn: location?.[location?.kind] ?? allData.nameEn,
      locationId: Number(location?.id) || Number(preparedValues.locationId),
      coordinatesLat: location?.latitude || allData.coordinatesLat,
      coordinatesLong: location?.longitude || allData.coordinatesLong,
    };
  
    if (isPublish) {
      try {
        const performAction =
          id && isEditForm
            ? offPlanProvider.updateById.bind(offPlanProvider, id)
            : offPlanProvider.create.bind(offPlanProvider);
        const response = await performAction(formData);
        const offPlanId = isEditForm && id ? id : (response?.data as string);
        setCurOffPlanId(offPlanId);
  
        if (offPlanId && Number(offPlanId) > 0 && media) {
          const newMedia = generateJsomToPostMedia(media, offPlanId.toString());
          await offPlanProvider.createMdeia(newMedia);
        }
  
        if (response?.ok) {
          if (publishType === AdFieldStatusEnum.Published) {
            // debugger;
            updateOffPlanState({ title: values.title });
            reset();
            setIsShownConfirmDialog(false);
            // const t = await fetchData(); // TODO: looks like redundant
            setIsShownPublishedDialog(true);
          } else {
            navigate('/admin/offplans');
          }
        } else {
          dispatch(
            appSetNotification(
              NotificationTypeEnum.Error,
              response?.message || 'Unknown error',
              response?.status
            )
          );
          setIsShownConfirmDialog(false);
        }
      } catch (error) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, 'An error occurred while saving'));
        navigate('/admin/offplans');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buttonHandlers: IButtonHandlers = {
    publish: handlePublish,
    saveDraft: handleSave,
    cancel: handleCancel,
    confirm: handleSubmit(submitHandler),
    done: handleDonePublishedDialog,
  };

  const clearErrorByName = (fName: string) => {
    clearErrors(fName as keyof IOffPlanModel);
  };

  return (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <Box component='form' autoComplete='off'>
          <SectionHeader title={isEditForm ? 'Edit listing' : 'Create a new off-plan'} />
          <PropertyInformation
            fields={getPropertyInformationFromState(offPlanState)}
            setNewValue={updateOffPlanState}
            errors={errors}
            control={control}
            clearErrors={clearErrors}
          />
          <PropertyPrice />
          <PropertyLocation
            fields={getPropertyLocationState(offPlanState)}
            setNewValue={updateOffPlanState}
            errors={errors}
            source='offPlan'
            isLocation={isLocation}
            setIsLocation={setIsLocation}
          />
          <PropertyDescription isLocation={isLocation} />
          <AgencyFee />
          <FeaturesAmenities fields={getAmentities(getValues())} setNewValue={updateOffPlanState} />
          <ForecastAndActualData forecast={offPlanState?.forecast} errors={errors} />
          <PaymentPlan
            paymentPlan={offPlanState?.paymentPlan}
            pushInInstallments={offPlanState?.pushInInstallments}
            setNewValue={updateOffPlanState}
            cleanError={clearErrorByName}
          />
          <UploadMedia
            forceCheck={isFileUploaderTouched && !isShownPublishedDialog}
            uploadType={'image'}
            id={`upload-image`}
            entityType='newConstruction'
            formElement={'media'}
            sectionNumber={9}
            sectionTitle={'Upload photos'}
            mediaGallery={getMediaGallery(getValues(), 'image') || []}
            minMediaCount={MIN_PHOTOS} //MIN_PHOTOS
            maxMediaCount={25}
            offPlanId={offPlanState?.id!}
            required={true}
            uploaderProps={photoUploaderProps}
            onUploaded={handleMediaUploaded}
          />

          <UploadMedia
            uploadType={'video'}
            entityType='newConstruction'
            formElement={'media'}
            maxMediaCount={10}
            sectionNumber={10}
            sectionTitle={'Upload videos'}
            mediaGallery={getMediaGallery(getValues(), 'video') || []}
            offPlanId={offPlanState?.id!}
            uploaderProps={videoUploaderProps}
          />

          <UploadMedia
            uploadType={'brochure'}
            entityType='newConstruction'
            formElement={'media'}
            sectionNumber={11}
            sectionTitle={'Upload brochures'}
            mediaGallery={getMediaGallery(getValues(), 'brochure') || []}
            offPlanId={offPlanState?.id!}
            uploaderProps={brochureUploaderProps}
          />

          {renderButtons(buttonsConfig.submitButtons, styles.submitButtons)}
        </Box>
        <MyDialog
          open={isShownConfirmDialog && Object.keys(errors).length === 0}
          onClose={handleCloseConfirmDialog}
          dialogTitle='Save Changes:'
          width='750'
        >
          <div className={styles.dialogDescription}>Confirm your updated information.</div>
          {renderButtons(buttonsConfig.confirmDialogButtons, styles.dialogButtons)}
        </MyDialog>
        <MyDialog
          open={isShownPublishedDialog}
          onClose={handleClosePublishedDialog}
          dialogTitle={'Your list has been successfully published'}
          width='750'
        >
          <div className={styles.dialogDescription}>
            Your listing <strong>&quot;{offPlanState?.title}&quot;</strong> has been successfully
            published and is available for other user's reviews.
          </div>
          {renderButtons(buttonsConfig.publishedDialogButtons, styles.dialogButtons)}
        </MyDialog>
      </FormProvider>
    </div>
  );
};
