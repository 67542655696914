import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
interface IFormInputs {
  name: string;
  phoneNumber: string;
}

const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/;

const instagramRegex = /instagram\.com\/.+/;
const linkedinRegex = /linkedin\.com\/.+/;

const emailSchema = yup
  .string()
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Enter a valid email'
  )
  .required('Enter email');

const phoneSchema = yup
  .string()
  .matches(/^\+\d{1,3}(\d{7,12})$/, 'Enter a valid phone number in international format')
  .nullable()
  .transform((value) => (value === '' ? null : value));

const websiteSchema = yup
  .string()
  .matches(
    /^(www\.[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+.*)$/,
    'Enter a valid website starting with www.'
  )
  .nullable()
  .transform((value) => (value === '' ? null : value));

const urlSchema = yup
  .string()
  .required('Enter a valid website')
  .matches(urlRegex, 'Enter a valid website')
  .nullable()
  .transform((value) => (value === '' ? null : value));

const instagramSchema = yup
  .string()
  .notRequired()
  .matches(instagramRegex, 'Enter a valid Instagram link')
  .nullable()
  .transform((value) => (value === '' ? null : value));

const linkedinSchema = yup
  .string()
  .notRequired()
  .matches(linkedinRegex, 'Enter a valid Linkedin link')
  .nullable()
  .transform((value) => (value === '' ? null : value));

export const schemaLogInWithEmail = yup.object().shape({
  emailOrPhone: yup.string().required('Enter email/phone'),
  password: yup.string().required('Enter your password'),
});

export const schemaLogInWithPhone = yup.object().shape({
  phoneNumber: phoneSchema,
  password: yup.string().required('Enter your password'),
});

export const schemaResetPasswordWithEmail = yup.object().shape({
  emailOrPhone: yup.string().email('Enter a valid email').required('Email is a required field'),
});

export const schemaResetPasswordWithPhone = yup.object().shape({
  phoneNumber: yup.string().required('Email is a required field'),
});

export const schemaConsultationOperator = yup.object<IFormInputs>().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, 'Name should not contain numbers')
    .required('Name is a required field'),
  emailOrPhone: yup.string().email('Enter a valid email').required('Email is a required field'),
  phoneNumber: yup.string()
    .nullable()
    .test(
      'isValidPhoneNumber',
      'Phone number is not valid',
      value => !value || isValidPhoneNumber(value)
    ),
});

export const schemaChangeLoginSettings = yup.object<IFormInputs>().shape({
  email: emailSchema,
  phone: yup.string()
    .required('Phone is a required field')
    .test(
      'isValidPhoneNumber',
      'Phone number is not valid',
      value => !value || isValidPhoneNumber(value)
    ),
});

export const schemaEditProfileBrokerAndManager = yup.object().shape({
  userName: yup.string().required('Name is a required field'),
  email: emailSchema,
  phone: phoneSchema.required('Phone number is a required field'),
  phoneNumberSecond: phoneSchema.notRequired(),
  whatsapp: phoneSchema.notRequired(),
  telegram: phoneSchema.notRequired(),
  instagram: instagramSchema.notRequired(),
  linkedin: linkedinSchema.notRequired(),
  additionalLinks: urlSchema.notRequired(),
  additionalLinksSecond: urlSchema.notRequired(),
  additionalLinksThird: urlSchema.notRequired(),
});

export const schemaCreatePassword = yup.object().shape({
  password: yup
    .string()
    .required('Specify a password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export const schemaSignUpBrokerAndAgency = yup.object().shape({
  profileVerificationOptions: yup
    .string()
    // .matches(/^([^0-9]*)$/, "Name should not contain numbers")
    .required('Name is a required field'),
  password: yup
    .string()
    .required('Enter your password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export const schemaSignUpManagerWithEmail = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, 'Name should not contain numbers')
    .required('Name is a required field'),
  company: yup.string().required('Company name is a required field'),
  emailOrPhone: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^\d{10,15}$/,
      'Enter a valid email or phone number'
    )
    .required('Enter email/phone'),
  password: yup
    .string()
    .required('Enter your password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});
export const schemaSignUpManagerWithPhone = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, 'Name should not contain numbers')
    .required('Name is a required field'),
  company: yup.string().required('Company name is a required field'),
  password: yup
    .string()
    .required('Enter your password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

const baseSignUpForeignBrokerSchema = yup.object().shape({
  formOfOrganization: yup.string().required('Form of organization is a required field'),
  country: yup.string().required('Country is a required field'),
  city: yup
    .string()
    .matches(/^([^0-9]*)$/, 'City should not contain numbers')
    .required('City is a required field'),
  social: yup
    .string()
    .matches(urlRegex, 'Enter a valid website')
    .required('Social media is a required field')
    .max(200, 'Limit exceeds, must be under 200 characters'),
  other: yup
    .string()
    .nullable()
    .notRequired()
    .matches(urlRegex, {
      message: 'Enter a valid website',
      excludeEmptyString: true,
    })
    .max(200, 'Limit exceeds, must be under 200 characters'),
  password: yup
    .string()
    .required('Enter your password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export const schemaSignUpForeignBrokerPersonWithEmail = baseSignUpForeignBrokerSchema.shape({
  name: yup.string().required('Name is a required field'),
  emailOrPhone: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^\d{10,15}$/,
      'Enter a valid email or phone number'
    )
    .required('Enter email/phone'),
});

export const schemaSignUpForeignBrokerPersonWithPhone = baseSignUpForeignBrokerSchema.shape({
  name: yup.string().required('Name is a required field'),
});

export const schemaSignUpForeignBrokerOrganizationWithEmail = baseSignUpForeignBrokerSchema.shape({
  companyName: yup.string().required('Company name is a required field'),
  emailOrPhone: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^\d{10,15}$/,
      'Enter a valid email or phone number'
    )
    .required('Enter email/phone'),
});

export const schemaSignUpForeignBrokerOrganizationWithPhone = baseSignUpForeignBrokerSchema.shape({
  companyName: yup.string().required('Company name is a required field'),
});
