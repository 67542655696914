import { SectionTitle } from '@/components';
import {Checkbox} from "@mui/material";
import {Control, Controller} from "react-hook-form";

export const NotificationData = ({ control }: {
  control: Control;
}) => {

  return (
    <div>
      <SectionTitle number={4} title='Manage Email notifications' />
      <div>
        <div>
          <FormCheckbox control={control} name="sendCommentEmails"  />
          <label htmlFor='sendCommentEmails'>New listing comments</label>
        </div>
        <div>
          <FormCheckbox control={control} name="sendMessageEmails"  />
          <label htmlFor='sendMessageEmails'>New listing notifications</label>
        </div>
        <div>
          <FormCheckbox control={control} name="sendClientRequestEmails"  />
          <label htmlFor='sendClientRequestEmails'>New listing client requests</label>
        </div>
        <div>
          <FormCheckbox control={control} name="sendTimersEmails"  />
          <label htmlFor='sendTimersEmails'>Expiring time of your active listing or client request</label>
        </div>
        <div>
          <FormCheckbox control={control} name="sendCometchatEmails"  />
          <label htmlFor='sendCometchatEmails'>New Cometchat message</label>
        </div>
        <div>
          <FormCheckbox control={control} name="sendFriendRequestEmails"  />
          <label htmlFor='sendFriendRequestEmails'>New friends request</label>
        </div>
      </div>
    </div>
  );
};

const FormCheckbox = ({control, name}: { control: Control, name: string}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const updateValue = (_e: any, checked: boolean) => {
          field.onChange(checked);
        };
        return (
          <Checkbox checked={field.value} onChange={updateValue}/>
        )
      }}
      />
  )
}