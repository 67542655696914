export const conversationActionStyle = (context) => {
  return {
    display: 'flex',
    listStyleType: 'none',
    padding: '0',
    margin: '0',
    width: '40px',
    backgroundColor: `${context.theme.backgroundColor.lightBlue3}`,
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '12px',
    bottom: '0',
    height: '50%',
  };
};

export const groupButtonStyle = (actionInProgress, progressIcon, actionIcon) => {
  const backgroundImage = actionInProgress ? progressIcon : actionIcon;

  return {
    outline: '0',
    border: '0',
    height: '20px',
    width: '20px',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    background: `url(${backgroundImage}) center center no-repeat`,
    padding: '0',
    cursor: 'pointer',
  };
};
