import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatNavBar } from './CometChatNavBar';
import { CometChatMessages } from '../Messages';
import { CometChatIncomingCall, CometChatIncomingDirectCall } from '../Calls';

import { CometChatContextProvider } from '../../util/CometChatContext';
import * as enums from '../../util/enums.js';
import { theme } from '../../resources/theme';
import Translator from '../../resources/localization/translator';

import {
  unifiedStyle,
  unifiedSidebarStyle,
  unifiedMainStyle,
  unifiedSelectChatMessageStyle,
} from './style';

class CometChatUI extends React.Component {
  loggedInUser = null;

  constructor(props) {
    super(props);
    this.state = {
      sidebarview: false,
      isChatSelected: false,
    };

    this.navBarRef = React.createRef();
    this.contextProviderRef = React.createRef();
  }

  uidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
  parts = window.location.pathname.split('/');
  lastPart = this.parts.pop();

  componentDidMount() {
    if (this.props.chatWithUser.length === 0 && this.props.chatWithGroup.length === 0) {
      this.toggleSideBar();
    }

    const uid =
      this.lastPart === 'chat' || this.uidPattern.test(this.lastPart)
        ? this.lastPart
        : this.parts[this.parts.length - 1];

    if (uid !== 'chat') {
      if (uid.startsWith('group_')) {
        const parts = uid.split('_');
        const guid = parts[1];
        CometChat.getGroup(guid)
          .then((group) => {
            this.setState({ isChatSelected: true });
            this.contextProviderRef.setTypeAndItem(CometChat.ACTION_TYPE.TYPE_GROUP, group);
            this.toggleSideBar();
          })
          .catch((error) => {
            console.error('Error with getting group by uid, group is not found');
          });
      } else {
        // manual: https://www.cometchat.com/docs/javascript-chat-sdk/users-retrieve-users#search-in
        let usersRequest = new CometChat.UsersRequestBuilder()
          .setLimit(1)
          .setSearchKeyword(uid)
          .searchIn(['uid'])
          .build();
        usersRequest
          .fetchNext()
          .then((response) => {
            const userByUid = response[0];
            if (userByUid) {
              this.setState({ isChatSelected: true });
              this.contextProviderRef.setTypeAndItem(CometChat.ACTION_TYPE.TYPE_USER, userByUid);
              this.toggleSideBar();
            } else {
              console.error('Error with getting user by uid, user is not founded');
            }
          })
          .catch((error) => this.errorHandler('SOMETHING_WRONG'));
      }
    }
  }

  navBarAction = (action, type, item) => {
    switch (action) {
      case enums.ACTIONS['ITEM_CLICKED']:
        this.setState({ isChatSelected: true });
        this.itemClicked(item, type);
        break;
      case enums.ACTIONS['TOGGLE_SIDEBAR']:
        this.toggleSideBar();
        break;
      default:
        break;
    }
  };

  itemClicked = (item, type) => {
    this.contextProviderRef.setTypeAndItem(type, item);
    this.toggleSideBar();
  };

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS['TOGGLE_SIDEBAR']:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };

  toggleSideBar = () => {
    const { sidebarview } = this.state;
    this.setState({ sidebarview: !sidebarview });
  };

  /**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
  groupUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (
          this.contextProviderRef.state.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          this.contextProviderRef.setItem({});
          this.contextProviderRef.setType('');
        }
        break;
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (
          this.contextProviderRef.state.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          const newObject = Object.assign({}, this.contextProviderRef.state.item, {
            scope: options['scope'],
          });
          this.contextProviderRef.setItem(newObject);
          this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    const message =
      this.lastPart !== 'chat' && !this.uidPattern.test(this.lastPart)
        ? decodeURIComponent(this.lastPart)
        : undefined;

    let messageScreen = (
      <CometChatMessages
        theme={this.props.theme}
        lang={this.props.lang}
        _parent='unified'
        actionGenerated={this.actionHandler}
        navBarAction={this.navBarAction}
        message={message}
      />
    );

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={this.props.lang}
      >
        <div
          css={unifiedStyle(this.props)}
          className='cometchat cometchat--unified'
          dir={Translator.getDirection(this.props.lang)}
        >
          <div css={unifiedSidebarStyle(this.state, this.props)} className='unified__sidebar'>
            <CometChatNavBar
              ref={(el) => (this.navBarRef = el)}
              theme={this.props.theme}
              actionGenerated={this.navBarAction}
            />
          </div>
          <div css={unifiedMainStyle(this.state, this.props)} className='unified__main'>
            {this.state.isChatSelected ? (
              messageScreen
            ) : (
              <div css={unifiedSelectChatMessageStyle(this.props)}>
                {Translator.translate('SELECT_CHAT_MESSAGE', this.props.lang)}
              </div>
            )}
          </div>
          <CometChatIncomingCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
          <CometChatIncomingDirectCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
        </div>
      </CometChatContextProvider>
    );
  }
}

// Specifies the default values for props:
CometChatUI.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  chatWithUser: '',
  chatWithGroup: '',
};

CometChatUI.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

export { CometChatUI };
