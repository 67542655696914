export const modalRowStyle = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    borderRadius: '5px',
    marginRight: '3px',
    '&:hover': {
      backgroundColor: `${theme.backgroundColor.lightBlue3}`,
    },
  };
};

export const modalColumnStyle = () => {
  return {
    display: 'flex',
    gap: '10px',
    width: 'calc(100% - 70px)',
    alignItems: 'center',
  };
};

export const avatarStyle = () => {
  return {
    width: '40px',
    height: '40px',
    flexShrink: '0',
  };
};

export const nameStyle = (theme) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: '500',
    color: `${theme.color.primary}`,
  };
};

export const actionStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
};

export const checkboxStyle = (img, theme) => {
  return {
    display: 'none',
    ' + label': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      userSelect: 'none',
      borderRadius: '3px',
      border: `2px solid ${theme.borderColor.primary}`,
      backgroundColor: `${theme.backgroundColor.white}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&:checked + label': {
      borderRadius: '5px',
    },
    '&:checked + label::after': {
      content: `""`,
      display: 'block',
      width: '14px',
      height: '14px',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.active}`,
    },
  };
};
