import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UserKindEnum, UserAgencyStateLicense, AuthSignupRequestType, State, NotificationTypeEnum, StateLicense } from '@/types';
import { appSetNotification, appSetLoading } from '@/store';
import { MyButton } from '@/components';
import {
  logIn,
  selectUserTypeData,
  findYourselfData,
  findYourCompanyData,
  personalData,
  contactDataForeign,
  contactDataManager,
  checkDetailsData,
  accountUpdate,
  createPasswordData,
  emailConfirmationData,
} from './Utils/signUpDescription';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import { StyledLink } from '@/components/uiComponents/styled';
import { stateLicenseProvider } from '@/providers';
import {
  SelectUserType,
  SecondStep,
  ThirdStep,
  CreatePassword,
  EmailConfirmation,
  LeftSide,
  Header,
} from '../NewSignUp/';
import {
  PersonalDateOfForeign,
  ContactDateOfForeign,
  ContactDateOfManager,
} from './Utils/types';
import {
  schemaCreatePassword,
  schemaForeignContactData,
  schemaBroker,
  schemaManagerContactData,
} from './Utils/schemas';
import { authProvider } from '@/providers';
import { sectionStyle, divStyle, stackStyle, boxInnerStyleBottom } from './Utils/styled';
import { formOfOrganization } from '../utils/fakeData';
const DIALOG_LOGIN = 'logIn';
const MAX_STEP = 5;

export const NewSignUp = () => {
  const navigate = useNavigate();
  const { setOpenDialog } = useDialogs();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ app }: State) => app);

  const firstStep = selectUserTypeData.header.numOfStep;
  const secondStep = findYourselfData.header.numOfStep;
  const thirdStep = checkDetailsData.header.numOfStep;
  const fourthStep = createPasswordData.header.numOfStep;
  const fifthStep = emailConfirmationData.header.numOfStep;
  const [inviteCode, setInviteCode] = useState<string | null>(localStorage.getItem('inviteCode'));
  const [referralCode, setReferralCode] = useState<string | null>(localStorage.getItem('referralCode'));
  const [stepValue, setStepValue] = useState<number>(firstStep);
  const [headerName, setHeaderName] = useState<string>(selectUserTypeData.header.name);
  const [headerNext, setHeaderNext] = useState<string>(selectUserTypeData.header.nextStep);
  const [radioValue, setRadioValue] = useState<string>('');
  const [radioUserValue, setRadioUserValue] = useState<string>('');
  const [isUsersVisible, setIsUsersVisible] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const [kind, setKind] = useState<UserKindEnum>();
  const [newAutocompleteValue, setNewAutocompleteValue] = useState<string | null>('');
  const [user, setUser] = useState<UserAgencyStateLicense | null>();
  const [usersAgencyId, setUsersAgencyId] = useState<string>();
  const [helperText, setHelperText] = useState<string>('');
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [userDataSubmit, setUserDataSubmit] = useState<AuthSignupRequestType>();
  const [newPhone, setNewPhone] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [isAcceptError, setIsAcceptError] = useState<boolean>(false);
  const [isChangeBroker, setIsChangeBroker] = useState<boolean>(false);
  const [newAgency, setNewAgency] = useState<StateLicense>();
  const [referralId, setReferralId] = useState<string>();

  const [personalDateOfForeign, setPersonalDateOfForeign] = useState<PersonalDateOfForeign>({
    formOfOrganization: '',
    country: '',
    city: '',
  });
  const [contactDateOfForeign, setContactDateOfForeign] = useState<ContactDateOfForeign>({
    name: '',
    email: '',
    phone: '',
    link: '',
  });
  const [contactDateOfManager, setContactDateOfManager] = useState<ContactDateOfManager>({
    name: '',
    email: '',
    phone: '',
  });

  const changeNextStepInFirstStep = () => {
    if (kind === UserKindEnum.Manager) {
      setHeaderNext(selectUserTypeData.header.nextStepManager);
    }
    if (kind === UserKindEnum.Foreign) {
      setHeaderNext(selectUserTypeData.header.nextStepForeign);
    }
    if (
      kind === UserKindEnum.Agency ||
      kind === UserKindEnum.Broker ||
      kind === UserKindEnum.Developer
    ) {
      setHeaderNext(selectUserTypeData.header.nextStep);
    }
  };

  const getReferralCookie = () => {
    const referralCookie = Cookies.get('rewardful.referral');
    if (referralCookie) {
      try {
        const referralData = JSON.parse(decodeURIComponent(referralCookie));
        setReferralId(referralData.id)
        console.log('Referral id: ', referralId);
      } catch (error) {
        console.error('Error parsing referral cookie:', error);
      }
    } else {
      console.log('Referral cookie not found');
    }
  };

  useEffect(() => {
    getReferralCookie();
  }, [referralCode]);

  useEffect(() => {
    changeNextStepInFirstStep();
    if (isChangeBroker) {
      const newRadioValue = selectUserTypeData.bodyContent.map(
        (content) => content.radioGroup.find((radio) => radio.type === kind)?.label
      );
      setRadioValue(newRadioValue[0] || radioValue);
    }
  }, [kind]);

  useEffect(() => {
    setNewAutocompleteValue(null);
    getReferralCookie();
  }, [radioValue]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const nextStep = (data: any) => {
    setHeaderName(data.header.name);
    setHeaderNext(data.header.nextStep);
    setStepValue(stepValue + 1);
    setError(false);
    setIsAcceptError(false);
  };

  const backStep = (data: any) => {
    setHeaderName(data.header.name);
    setHeaderNext(data.header.nextStep);
  };

  const handlerNext = async () => {
    getReferralCookie();
    if (stepValue === firstStep) {
      if (radioValue.length) {
        if (kind === UserKindEnum.Manager) {
          if (inviteCode) {
            const { ok, data, status, message } = await authProvider.signupAgencyByLink(inviteCode);
            if (ok && data) {
              setNewAgency(data as StateLicense);
              setUser(data as UserAgencyStateLicense);
            }
          }
          nextStep(findYourCompanyData);
        } else if (kind === UserKindEnum.Foreign) {
          nextStep(personalData);
        } else if (kind === UserKindEnum.Broker) {
          if (inviteCode) {
            const { ok, data, status, message } = await authProvider.signupAgencyByLink(inviteCode);
            if (ok && data) {
              setNewAgency(data as StateLicense);
            }
          }
          nextStep(findYourselfData);
        } else {
          nextStep(findYourselfData);
        }
        setIsUsersVisible(false);
      } else {
        setError(true);
      }
    }

    if (stepValue === secondStep) {
      const findAllEmptyFields = (data: PersonalDateOfForeign): string[] => {
        const emptyFields = Object.entries(data)
          .filter(([key, value]) => !value.trim())
          .map(([key]) => key);
        return emptyFields;
      };
      if (isAccept) {
        if (kind === UserKindEnum.Foreign) {
          const emptyFields = findAllEmptyFields(personalDateOfForeign);
          if (emptyFields.length > 0) {
            setError(true);
            setErrorFields(emptyFields);
          } else {
            nextStep(contactDataForeign);
          }
        } else {
          if (inviteCode && kind === UserKindEnum.Manager) {
            nextStep(contactDataManager);
            setUsersAgencyId(user?.id);
          } else {
            if (newAutocompleteValue) {
              if (kind === UserKindEnum.Manager) {
                nextStep(contactDataManager);
                setUsersAgencyId(user?.id);
                setUser(null);
              } else {
                if (!isUsersVisible) {
                  if (inviteCode && kind === UserKindEnum.Broker && newAgency) {
                    // @ts-ignore
                    setUser({...user, agency: newAgency});
                  }
                  nextStep(checkDetailsData);
                  return;
                }
                if (isUsersVisible && radioUserValue.length) {
                  if (inviteCode && kind === UserKindEnum.Broker && newAgency) {
                    // @ts-ignore
                    setUser({...user, agency: newAgency});
                  }
                  nextStep(checkDetailsData);
                } else {
                  setError(true);
                }
              }
            } else {
              setError(true);
            }
          }
        }
      } else {
        setIsAcceptError(true);
      }
    }

    if (stepValue === thirdStep && kind) {
      let dataSubmit: AuthSignupRequestType;
      const userVerify = async (dataSubmit: AuthSignupRequestType) => {
        const { ok, data, status, message } = await authProvider.signupNext(dataSubmit);
        if(!ok) {
          setError(true);
        } else {
          nextStep(createPasswordData);
        }
      };
      const passStep = async (user: UserAgencyStateLicense) => {
        const fullPhone = user?.phone?.length < 11
          ? (user.phone.startsWith('0') ? `+971${user.phone.slice(1)}` : `+971${user.phone}`)
          : user.phone;
        const phone = user.phone ? fullPhone : newPhone;
        const email = user.email ? user.email : newEmail;
        try {
          await schemaBroker.validate({ email, phone }, { abortEarly: false });
          setUser({ ...user, phone: phone, email: email });
          dataSubmit = {
            kind: kind,
            registryId: user.id,
            name: user.nameEn,
            phone: phone,
            email: email,
            country: null,
            city: null,
            personKind: null,
            links: [],
            referralId: referralId,
          };
          setUserDataSubmit(dataSubmit);
          userVerify(dataSubmit);
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            setHelperText(err.errors.join(', '));
            setErrorFields(err.errors);
          }
        }
      };
      if (kind === UserKindEnum.Foreign) {
        const { name, email, phone, link } = contactDateOfForeign;
        try {
          await schemaForeignContactData.validate(
            { name, link, email, phone },
            { abortEarly: false }
          );
          const newLink = `https://${link.replace(/^https?:\/\//, '').replace(/^www\./, '')}`
          setContactDateOfForeign({ ...contactDateOfForeign, link: newLink });
          const personKind = formOfOrganization.find(
            (el: any) => el.label === personalDateOfForeign.formOfOrganization,
          )?.name;
          dataSubmit = {
            kind: kind,
            registryId: null,
            name: contactDateOfForeign.name,
            phone: contactDateOfForeign.phone,
            email: contactDateOfForeign.email,
            country: personalDateOfForeign.country,
            city: personalDateOfForeign.city,
            personKind: personKind,
            links: [newLink],
            referralId: referralId,
          };
          setUserDataSubmit(dataSubmit);
          userVerify(dataSubmit);
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            console.log(err.errors)
            setHelperText(err.errors.join(', '));
            setErrorFields(err.errors);
          }
        }
      } else if (kind === UserKindEnum.Manager) {
        const { name, email, phone } = contactDateOfManager;
        try {
          await schemaManagerContactData.validate({ name, email, phone }, { abortEarly: false });
          dataSubmit = {
            kind: kind,
            registryId: usersAgencyId,
            name: contactDateOfManager.name,
            phone: contactDateOfManager.phone,
            email: contactDateOfManager.email,
            country: null,
            city: null,
            personKind: null,
            links: [],
            referralId: referralId,
          };
          setUserDataSubmit(dataSubmit);
          userVerify(dataSubmit);
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            setHelperText(err.errors.join(', '));
            setErrorFields(err.errors);
          }
        }
      } else {
        if (user) {
          const searchAgency = async (params: string) => {
            const result = await stateLicenseProvider.searchAgency({ query: params });
            const { items } = result.data as any;
            if (items.length > 0) {
              const user = items[0];
              setUser(user);
              setKind(UserKindEnum.Agency);
              setHeaderName(accountUpdate.header.name);
              setHeaderNext(accountUpdate.header.nextStep);
              setError(false);
              setIsChangeBroker(true);
            } else {
              passStep(user);
            }
          };

          if (kind === UserKindEnum.Broker) {
            if (user.email) {
              searchAgency(user.email);
            } else {
              passStep(user);
            }
          } else {
            if (isChangeBroker) {
              setHeaderName(checkDetailsData.header.name);
              setHeaderNext(checkDetailsData.header.nextStep);
              setError(false);
              setIsChangeBroker(false);
            } else {
              passStep(user);
            }
          }
        }
      }
    }

    if (stepValue === fourthStep) {
      try {
        await schemaCreatePassword.validate({
          password,
          confirmPassword,
        });
        if (password && userDataSubmit) {
          const dataSubmit = {...userDataSubmit, password: password, referralVia: referralCode}
          setUserDataSubmit(dataSubmit);
          dispatch(appSetLoading(true));
          const response = await authProvider.signupStart(dataSubmit);
          const { ok, message, status } = response;
          if (ok) {
            nextStep(emailConfirmationData);
          } else {
            dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
          }
          dispatch(appSetLoading(false));
        }
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          setHelperText(err.errors[0]);
          setError(true);
        }
      }
    }
  };

  const handlerBack = () => {
    if (stepValue === firstStep) {
      return navigate('/');
    }
    if (stepValue === secondStep) {
      setHeaderName(selectUserTypeData.header.name);
      changeNextStepInFirstStep();
    }
    if (stepValue === thirdStep) {
      if (kind === UserKindEnum.Manager) {
        backStep(findYourCompanyData);
      } else if (kind === UserKindEnum.Foreign) {
        backStep(personalData);
        setErrorFields([]);
      } else {
        backStep(findYourselfData);
      }
    }
    if (stepValue === fourthStep) {
      if (kind === UserKindEnum.Manager) {
        backStep(contactDataManager);
      } else if (kind === UserKindEnum.Foreign) {
        backStep(contactDataForeign);
      } else {
        backStep(checkDetailsData);
      }
    }
    if (stepValue === fifthStep) {
      backStep(createPasswordData);
    }
    setStepValue(stepValue - 1);
    setError(false);
    setPassword('');
    setConfirmPassword('');
  };

  const handlerDialogOpen = (dialogName: string) => {
    const key = dialogName.toLowerCase();

    setOpenDialog((prevState) => ({
      ...prevState,
      logIn: key === DIALOG_LOGIN.toLowerCase(),
    }));
  };

  const handlePasswordChange = (value: string, name: string) => {
    if (name === 'password') {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  return (
    <Stack sx={sectionStyle}>
      <Stack direction={{ xs: 'column', sm: 'row' }} style={divStyle}>
        {!isMobile && <LeftSide type='signUp' />}
        <Stack sx={stackStyle}>
          <Header
            stepValue={stepValue}
            headerName={headerName}
            headerNext={headerNext}
            MAX_STEP={MAX_STEP}
            type='signUp'
          />
          <Divider sx={{ border: `1px solid #E9EEF1`, width: '100%' }} />
          <Stack
            spacing={3}
            sx={{
              padding: { sm: '10px 30px', xs: '15px' },
              maxWidth: { sm: '504px', xs: '100%' },
              minHeight: '520px',
            }}
          >
            {stepValue === firstStep && (
              <SelectUserType
                radioValue={radioValue}
                setRadioValue={setRadioValue}
                error={error}
                setError={setError}
                setKind={setKind}
              />
            )}
            {kind && stepValue === secondStep && (
              <SecondStep
                isUsersVisible={isUsersVisible}
                setIsUsersVisible={setIsUsersVisible}
                radioUserValue={radioUserValue}
                setRadioUserValue={setRadioUserValue}
                radioValue={radioValue}
                setError={setError}
                isMobile={isMobile}
                kind={kind}
                newAutocompleteValue={newAutocompleteValue}
                setNewAutocompleteValue={setNewAutocompleteValue}
                setUser={setUser}
                error={error}
                helperText={isUsersVisible ? '' : findYourselfData.error.label}
                personalDateOfForeign={personalDateOfForeign}
                setPersonalDateOfForeign={setPersonalDateOfForeign}
                errorFields={errorFields}
                setErrorFields={setErrorFields}
                isAccept={isAccept}
                setIsAccept={setIsAccept}
                newAgency={newAgency}
                inviteCode={inviteCode || undefined}
                isAcceptError={isAcceptError}
                setIsAcceptError={setIsAcceptError}
              />
            )}
            {kind && stepValue === thirdStep && (
              <ThirdStep
                user={user}
                kind={kind}
                contactDateOfForeign={contactDateOfForeign}
                setContactDateOfForeign={setContactDateOfForeign}
                errorFields={errorFields}
                setErrorFields={setErrorFields}
                helperText={helperText}
                setError={setError}
                error={error}
                personalDateOfForeign={personalDateOfForeign}
                setUser={setUser}
                newPhone={newPhone}
                setNewPhone={setNewPhone}
                newEmail={newEmail}
                setNewEmail={setNewEmail}
                contactDateOfManager={contactDateOfManager}
                setContactDateOfManager={setContactDateOfManager}
                isChangeBroker={isChangeBroker}
                setIsChangeBroker={setIsChangeBroker}
              />
            )}
            {stepValue === fourthStep && (
              <CreatePassword
                onChange={handlePasswordChange}
                error={error}
                helperText={helperText}
                setError={setError}
                type='signUp'
              />
            )}
            {stepValue === fifthStep && (
              <EmailConfirmation
                error={error}
                helperText={helperText}
                setError={setError}
                userDataSubmit={userDataSubmit}
                type='signUp'
                inviteCode={inviteCode || undefined}
              />
            )}
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: { sm: 'flex-start', xs: 'space-between' },
              alignItems: 'center',
              padding: { sm: '0 30px 20px', xs: '15px' },
              width: { sm: '504px', xs: '100%' },
            }}
          >
            {isMobile && (
              <Stack direction='row' alignItems='center' display='flex' sx={{ maxWidth: '100%' }}>
                <Typography
                  sx={{
                    color: '#2A3842',
                    fontSize: '15px',
                    textAlign: isMobile ? 'center' : 'left',
                  }}
                >
                  {logIn.passiveLabel}{' '}
                  <StyledLink to='/logIn' fontSize='15px' fontWeight='800'>
                    {logIn.label}
                  </StyledLink>{' '}
                </Typography>
              </Stack>
            )}
            <Stack
              spacing={{ sm: 3, xs: 1 }}
              direction='row'
              sx={{
                display: 'flex',
                justifyContent: { sm: 'flex-start', xs: 'space-evenly' },
                padding: { sm: '10px 30px', xs: '10px 2px' },
                width: { sm: '504px', xs: '100%' },
              }}
            >
              <Box sx={boxInnerStyleBottom}>
                <MyButton
                  data={{
                    buttonName: 'Back',
                    variant: 'outlined',
                    styleType: 'cancel',
                    dataQa: 'sign_up_btn_back',
                    customWidth: '170px',
                  }}
                  onClick={() => handlerBack()}
                />
              </Box>
              {stepValue !== fifthStep && (
                <Box sx={boxInnerStyleBottom}>
                  <MyButton
                    data={{
                      buttonName: 'Next',
                      variant: 'contained',
                      buttonType: 'submit',
                      dataQa: 'sign_up_btn_next',
                      customWidth: '170px',
                    }}
                    onClick={() => handlerNext()}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
