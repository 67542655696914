import { CircularProgress, Stack } from '@mui/material';
import { styled } from '@mui/system';

const Wrapper = styled(Stack)({
  position: 'fixed', // Изменено на fixed
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  height: '100%', // Используем height: 100%
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  opacity: 0.8,
  background: '#eee',
});

export const LoaderOverlay = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);
