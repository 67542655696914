import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';

import { customTheme } from '@/utils';

import styles from './FormCheckbox.module.scss';

interface FormCheckboxProps {
  name: string;
  label: string;
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const FormCheckbox = ({ name, label, checked = false, onChange }: FormCheckboxProps): JSX.Element => {
  const outerTheme = useTheme();
  const { getValues, setValue, register } = useFormContext();
  const values = getValues();
  const value = values[name];

  const [val, setVal] = useState<boolean>(checked);

  useEffect(() => setVal(checked), [checked]);
  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(name, target.checked); // for Safari browser https://github.com/react-hook-form/react-hook-form/issues/1079
    setVal(target.checked);
    if (onChange) onChange(target.checked);
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...register(name)}
              checkedIcon={<span className={styles.checked} />}
              icon={<span />}
              checked={val}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={label}
          className={styles.label}
        />
      </FormGroup>
    </ThemeProvider>
  );
};
