import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IMyButtonData } from '../../../uiComponents/types';

export const buttons: IMyButtonData[] = [
  {
    buttonName: 'Upload new',
    variant: 'outlined',
    customWidth: '170px',
    styleType: 'cancel',
    actionKey: 'uploadPhotoAction',
    buttonsIcon: true,
    icon: <FileUploadOutlinedIcon />,
  },
  {
    buttonName: 'Edit photo',
    variant: 'outlined',
    customWidth: '170px',
    styleType: 'cancel',
    actionKey: 'editPhotoAction',
    buttonsIcon: true,
    icon: <EditOutlinedIcon />,
  },
  {
    buttonName: 'Delete',
    variant: 'outlined',
    customWidth: '170px',
    styleType: 'cancel',
    actionKey: 'deletePhotoAction',
    buttonsIcon: true,
    icon: <DeleteOutlinedIcon />,
  },
];