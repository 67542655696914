import { Avatar, Stack, Link } from '@mui/material';
import { stringAvatar } from '@/utils';
import { UserName, UserType } from './styled';
import { UserModel } from '@/types';

interface IContactBlockProps {
  user: {
    name: string | undefined;
    id: string | undefined;
    avatar: string | null | undefined;
  };
  userType?: string;
  userMe?: UserModel;
}

export const ContactBlock: React.FC<IContactBlockProps> = ({ user, userType, userMe }) => {
  const isCheckedMy = userMe?.id === user.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const capitalizeFirstLetter = (string: string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Stack alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
      <Link
        href={isCheckedMy ? '/profile/view' : `/users/${user.id}`}
        title={user.name}
        style={{ textDecoration: 'none', color: '#2A3842', overflow: 'hidden' }}
      >
        <Stack direction='row' alignItems='center' spacing={1} style={{ position: 'relative' }}>
          <Avatar
            src={user.avatar ? user.avatar : ''}
            {...stringAvatar(user.name)}
            sx={{ width: 24, height: 24 }}
          />
          <Stack sx={{ maxWidth: '145px' }}>
            <UserName>{user.name}</UserName>
            {userType && <UserType>{userType}</UserType>}
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};
