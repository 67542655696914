import { styled } from '@mui/system';
import { Tooltip, tooltipClasses } from '@mui/material';

import { ITooltipComponentProps, ILightTooltipProps } from '../types';
import { icons } from '../../AccountManagement/Profile/Icons/icons';

const LightTooltip = styled(({ className, ...props }: ILightTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    fontFamily: '"Poppins", sans-serif',
    color: '#2A3842',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    maxWidth: 370,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffffff',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    borderColor: `transparent transparent ${'#ffffff'} ${'#ffffff'}`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

export const TooltipComponent = ({ tooltip, name }: ITooltipComponentProps) => {
  return (
    <LightTooltip placement='top-start' title={tooltip} arrow>
      <span
        style={{
          verticalAlign: 'middle',
          marginLeft: '10px',
          fontSize: '0',
          cursor: 'pointer',
        }}
      >
        {name === 'support' ? <icons.InfoColor /> : <icons.Info />}
      </span>
    </LightTooltip>
  );
};
