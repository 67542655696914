import { AdMediaType } from '@/types';
import React, { useMemo } from 'react';
import { Picture } from '@/components/PdfManager/PdfDocument/ClassicView/Picture';
import { Title } from '@/components/PdfManager/PdfDocument/ClassicView/Section';
import { StyleSheet, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export interface GallerySectionProps {
  title?: string;
  images?: AdMediaType[] | NewBuildingsMediaType[];
  wrap?: boolean;
  style?: Style;
}

const styles = StyleSheet.create({
  gallery: {
    width: '100%',
  },
  galleryImage: {
    marginTop: 32,
    objectFit: 'cover',
    borderRadius: 24,
  },
});

export const GallerySection = ({ title, images = [], wrap = true, style }: GallerySectionProps) => {
  if (!images.length) return null;

  const pictures = useMemo(() => {
    return images.map((img) => (
      <Picture key={img.id} style={styles.galleryImage} src={img.fileUrl} />
    ));
  }, [images]);

  return (
    <View wrap={wrap}>
      <Title style={{ fontSize: '112' }}>{title}</Title>
      <View wrap={true}>{pictures}</View>
    </View>
  );
};
