import { DeleteIcon, MoreIcon } from '@/assets/svg';
import { FormCheckbox, LabelComponent, SectionTitle } from '@/components/uiComponents';
import { Box, Button, IconButton, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { IOffPlanModel } from '@/types';
import { useFormContext } from 'react-hook-form';
import { ValidationError } from '@/components/OffPlanView/styled';

interface IPaymentPlan {
  name: string;
  items: {
    name: string;
    description: string;
    value: string;
  }[];
}

export const PaymentPlan = ({
  paymentPlan,
  pushInInstallments = false,
  setNewValue,
  cleanError,
}: {
  paymentPlan?: IPaymentPlan;
  pushInInstallments?: boolean;
  setNewValue: (field: Partial<IOffPlanModel>) => void;
  cleanError?: (f: string) => any;
}) => {
  const [curPaymentPlan, setCurPaymentPlan] = useState<IPaymentPlan | undefined>(paymentPlan);
  const [isChecked, setIsChecked] = useState<boolean>(pushInInstallments);
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setNewValue({ paymentPlan: curPaymentPlan });
  }, [curPaymentPlan]);

  useEffect(() => {
    if (!isChecked && cleanError) cleanError("paymentPlan");

  }, [isChecked]);

  const newPlanItem = () => {
    const currentPlan = curPaymentPlan || { name: '', items: [] };
    const newItem = { name: '', description: '', value: '' };
    const updatedItems = [...currentPlan.items, newItem];

    setCurPaymentPlan({ ...currentPlan, items: updatedItems });
  };

  const handleChangePlanItem =
    ({ index, type }: { index: number; type: string }) =>
    (e: any) => {
      if (curPaymentPlan) {
        const editedPlan = { ...curPaymentPlan.items[index], [type]: e.target.value };
        const updatedItems = [...curPaymentPlan.items];
        updatedItems[index] = editedPlan;
        setCurPaymentPlan({ ...curPaymentPlan, items: updatedItems });
      }
    };

  const deletePlanItem = (index: number) => (e: any) => {
    if (curPaymentPlan && curPaymentPlan.items) {
      const newItems = curPaymentPlan.items.filter((_, i) => i !== index);
      setCurPaymentPlan({ ...curPaymentPlan, items: newItems });
    }
  };

  return (
    <Box sx={{ marginTop: '55px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <SectionTitle variant='blank' number={8} title='Payment plan' />
        <FormCheckbox
          name='pushInInstallments'
          label='Possibility of purchasing in installments'
          checked={isChecked}
          onChange={setIsChecked}
        />
      </Box>
      {isChecked && (
        <>
          <LabelComponent label='Payment plan name' isRequired={isChecked}/>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <TextField
              type='text'
              name='pplanName'
              value={curPaymentPlan?.name}
              onChange={(e) => {
                setCurPaymentPlan({
                  ...curPaymentPlan,
                  name: e.target.value,
                  items: curPaymentPlan?.items || [],
                });
              }}
              label=''
              error={!!errors['paymentPlan']}
              helperText={errors['paymentPlan'] && errors['paymentPlan']?.message?.toString()}
              placeholder='Payment Plan 50/50'
              sx={{ width: '100%' }}
            />
            {curPaymentPlan?.items &&
              curPaymentPlan?.items.map((pplan, i) => (
                <Box
                  key={`paymentPlanFields-${i}`}
                  sx={{ display: 'flex', gap: '20px',  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <LabelComponent label={`${i + 1} field name`} isRequired={isChecked} />
                    
                      <TextField
                        type='text'
                        fullWidth
                        name={`pplan.item.${i}`}
                        value={pplan?.name}
                        label=''
                        error={!curPaymentPlan.items[i].name}
                        onChange={handleChangePlanItem({ index: i, type: 'name' })}
                        placeholder='some name'
                      />
                      {!curPaymentPlan.items[i].name && <ValidationError>Name is required</ValidationError>}

                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelComponent label='Description' isRequired={isChecked} />

                      <TextField
                        type='text'
                        fullWidth
                        name={`pplan.description.${i}`}
                        value={pplan?.description}
                        error={!curPaymentPlan.items[i].description}
                        label=''
                        onChange={handleChangePlanItem({ index: i, type: 'description' })}
                        placeholder='some description'
                      />
                      {!curPaymentPlan.items[i].description && <ValidationError>Description is required</ValidationError>}

                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelComponent label='Value' isRequired={isChecked} />

                      <TextField
                        type='text'
                        fullWidth
                        name={`pplan.value.${i}`}
                        error={!curPaymentPlan.items[i].value}
                        value={pplan?.value}
                        label=''
                        onChange={handleChangePlanItem({ index: i, type: 'value' })}
                        placeholder='some value'
                      />
                      {!curPaymentPlan.items[i].value && <ValidationError>Value is required</ValidationError>}

                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '48px' }}>
                    <IconButton
                      sx={{
                        height: '48px',
                        width: '48px',
                        border: '2px solid #C5DFFF',
                        borderRadius: '10px',
                      }}
                      onClick={deletePlanItem(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            <Button fullWidth onClick={newPlanItem} variant='outlined'>
              Add new stage
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
