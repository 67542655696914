import { CSSProperties, ReactElement } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import moment from 'moment';

import {
  AdModel,
  PropertyKindEnum,
  AdFieldFurnitureEnum,
  AdFieldRenovationEnum,
  CompletionStatusEnum,
  TransactionTypeEnum,
  AdFieldOccupancyEnum,
} from '@/types';
import { Section } from '@/components/Ad/Section';

import { ShellBlack, SpecificationsContainer } from './styled';
import { icons } from './Icons/icons';

export const SpecificationsBlock = ({ page }: { page: AdModel }) => {
  const propertyKindLabels = {
    [PropertyKindEnum.Apartment]: 'Apartment',
    [PropertyKindEnum.Townhouse]: 'Townhouse',
    [PropertyKindEnum.Villa]: 'Villa',
    [PropertyKindEnum.Floor]: 'Floor',
    [PropertyKindEnum.Penthouse]: 'Penthouse',
    [PropertyKindEnum.ResidentialLand]: 'Residential Land',
    [PropertyKindEnum.VillaCompound]: 'Villa Compound',
    [PropertyKindEnum.HotelApartment]: 'Hotel Apartment',
    [PropertyKindEnum.CommercialVilla]: 'Commercial Villa',
    [PropertyKindEnum.Warehouse]: 'Warehouse',
    [PropertyKindEnum.IndustrialLand]: 'Industrial Land',
    [PropertyKindEnum.CommercialLand]: 'Commercial Land',
    [PropertyKindEnum.Office]: 'Office',
    [PropertyKindEnum.LabourCamp]: 'Labour Camp',
    [PropertyKindEnum.CommercialBuilding]: 'Commercial Building',
    [PropertyKindEnum.Shop]: 'Shop',
  };
  const transactionTypeLabels = {
    [TransactionTypeEnum.Ready]: 'Ready',
    [TransactionTypeEnum.Resale]: 'Resale',
    [TransactionTypeEnum.Distress]: 'Distress',
  };
  const completionStatusLabels = {
    [CompletionStatusEnum.All]: 'All',
    [CompletionStatusEnum.Ready]: 'Ready',
    [CompletionStatusEnum.OffPlan]: 'OffPlan',
  };
  const furnitureLabels = {
    [AdFieldFurnitureEnum.Furnished]: 'Furnished',
    [AdFieldFurnitureEnum.PartlyFurnished]: 'Partly furnished',
    [AdFieldFurnitureEnum.Unfurnished]: 'Unfurnished',
  };
  const renovationLabels = {
    [AdFieldRenovationEnum.Renovation]: 'Renovated',
    [AdFieldRenovationEnum.NonRenovation]: 'Non renovated',
  };
  const availabilityLabels = {
    [AdFieldOccupancyEnum.Tenanted]: 'Rented',
    [AdFieldOccupancyEnum.Vacant]: 'Vacant',
  };

  const isOccupancy = page.occupancy === AdFieldOccupancyEnum.Vacant || page.occupancy === AdFieldOccupancyEnum.Tenanted;
  const availabilityText = page.occupancy && isOccupancy && availabilityLabels[page.occupancy];
  const rentedTillText = page.rentedTill ? `till ${moment(page.rentedTill).format('MMM YYYY')}` : '';
  const availabilityValue = `${availabilityText} ${rentedTillText}`;

  const isNotANumber = (value: any) => {
    return typeof value !== 'number' || isNaN(value);
  };

  const capitalizeFirstLetter = (string: string) => {
    if (string && isNotANumber(string)) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };

  const completionStatusIsVisible =
    !page.propertyKind ||
    (!!page.propertyKind &&
      ![
        PropertyKindEnum.LabourCamp,
        PropertyKindEnum.ResidentialLand,
        PropertyKindEnum.IndustrialLand,
        PropertyKindEnum.CommercialLand,
      ].includes(page.propertyKind));

  const characteristics = [
    {
      name: 'activity',
      value: capitalizeFirstLetter(page.activityKind),
      icon: <icons.Dollar />,
    },
    {
      name: 'transactionType',
      value: (page?.transactionType && transactionTypeLabels[page.transactionType]) || '',
      icon: <icons.HouseLine />,
    },
    {
      name: 'property',
      value: propertyKindLabels[page.propertyKind],
      icon: <icons.Buildings />,
    },
    {
      name: 'completionStatus',
      value: completionStatusIsVisible 
        ? (page?.completionStatus && completionStatusLabels[page.completionStatus]) || ''
        : null,
      icon: <icons.ComplStatus />,
    },
    {
      name: 'availabilityStatus',
      value: isOccupancy
        ? availabilityValue
        : null,
      icon: <icons.RentBuildings />,
    },
  ];

  const viewCharacteristics = page?.view && page?.view?.length > 0 ? page.view.map((view) => {
    return {
      name: view,
      value: `${view} view`,
      icon: null,
    }
  }) : [];

  const allCharacteristics = [...characteristics, ...viewCharacteristics];

  const textBeds = page.beds === 'Studio' ? '' : Number(page.beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(page.baths) > 1 ? 'baths' : 'bath';

  const convertedPlotArea = page?.plotArea
    ? page.convertedAreaUnits === 'SQFT'
      ? page?.plotArea
      : Math.round(Number(page?.plotArea) / 10.764)
    : 0;

  const aria = page.convertedArea && page?.plotArea
    ? `${page.convertedArea} / ${convertedPlotArea}`
    : page.convertedArea
      ? page.convertedArea
      : convertedPlotArea;

  return (
    <Section>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
        {allCharacteristics?.map((character) => {
          if (!character.value) return null;
          return (
            <ShellBlack key={character.name}>
              {character.icon}
              <Typography sx={{ fontSize: { xs: '15px', sm: '16px' } }}>
                {character.value}
              </Typography>
            </ShellBlack>
          );
        })}
      </div>

      <SpecificationsContainer
        direction='row'
        spacing={{ xs: 2, sm: 2 }}
        divider={<Divider orientation='vertical' flexItem />}
      >
        {aria && (
          <IconText style={{ minWidth: '135px', maxWidth: '200px' }} icon={<icons.Ruler />}>
            {`${aria} ${page.convertedAreaUnits.toLowerCase()}`}
          </IconText>
        )}
        {page.beds && (
          <IconText
            style={{ width: '135px' }}
            icon={<icons.Bed />}
          >{`${page.beds} ${textBeds}`}</IconText>
        )}
        {page.furniture && (
          <IconText icon={<icons.Furnishing />}>{`${furnitureLabels[page.furniture]}`}</IconText>
        )}
      </SpecificationsContainer>
      <SpecificationsContainer
        direction='row'
        spacing={{ xs: 2, sm: 2 }}
        divider={<Divider orientation='vertical' flexItem />}
      >
        {page.baths && (
          <IconText
            style={{ width: '135px' }}
            icon={<icons.Shower />}
          >{`${page.baths} ${textBaths}`}</IconText>
        )}
        {page.floor && isNotANumber(page.floor) && (
          <IconText
            style={{ width: '135px' }}
            icon={<icons.Floor />}
          >{`${capitalizeFirstLetter(page.floor)} floor`}</IconText>
        )}
        {page.renovation && (
          <IconText icon={<icons.PaintRoller />}>{`${renovationLabels[page.renovation]}`}</IconText>
        )}
      </SpecificationsContainer>
    </Section>
  );
};

const IconText = ({
  icon,
  children,
  style,
}: {
  icon: ReactElement;
  children?: string;
  style?: CSSProperties;
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='left' spacing={1} style={style}>
      {icon}
      <Typography sx={{ fontSize: { xs: '14px', sm: '15px' } }}>{children}</Typography>
    </Stack>
  );
};
