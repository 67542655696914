import {
  BalconyIcon,
  MaidsRoomIcon,
  SharedPoolIcon,
  BuiltInWardrobesIcon,
  ChildrenPlayAreaIcon,
  SharedSpaIcon,
  SharedGymIcon,
  StudyIcon,
  CentralAcIcon,
  CoveredParkingIcon,
  BarbecueAreaIcon,
  ViewOfWaterIcon,
  ViewOfLandmarkIcon,
  PetsAllowedIcon,
  ConciergeIcon,
  PublicParkingIcon,
  KitchenAppliancesIcon,
  LobbyInBuildingIcon,
  MaidServiceIcon,
  PetsNotAllowedIcon,
  PrivateGardenIcon,
  PrivateGymIcon,
  PrivatePoolIcon,
  SecurityIcon,
  TennisCourtsIcon,
  ViewOfParkIcon,
  ViewOfCityIcon,
  PrivateSpaIcon,
  LaundryFacilityIcon,
  BedIcon,
  BuildingsIcon,
  CurrencyCircleDollarIcon,
  FloorIcon,
  FurnishingIcon,
  HouseLineIcon,
  LocationIcon,
  MapIcon,
  NoteIcon,
  PaintRollerIcon,
  RulerIcon,
  ShowerIcon,
  AnswerIcon,
  EditCommentIcon,
  ReportIcon,
  LikeIcon,
  UnlikeIcon,
  ReplayIcon,
  DeleteIcon,
  MoreIcon,
  DevelopStatusIcon,
  CalendarIcon,
  ParkingIcon,
  PaymentPlanIcon,
  ArchiveIcon,
  UploadIcon,
  AddPlanIcon,
  AddBuildingIcon,
  LinkIcon,
  ComplStatusIcon,
  RentBuildingsIcon,
} from '@/assets/svg';

import { ReactComponent as ActiveAdsIcon } from './activeAds.svg';
import { ReactComponent as ArrowClockwiseIcon } from './arrowClockwise.svg';
import { ReactComponent as CalendarPlusIcon } from './calendarPlus.svg';
import { ReactComponent as CirclePlusIcon } from './circlePlus.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as LandIcon } from './land.svg';
import { ReactComponent as PdfIcon } from './pdf.svg';
import { ReactComponent as UserIcon } from './user.svg';
import { ReactComponent as UsersIcon } from './users.svg';
import { ReactComponent as UserSquareIcon } from './userSquare.svg';
import { ReactComponent as HandoverIcon } from './handover.svg';
import { ReactComponent as PostHandoverIcon } from './postHandover.svg';
import { ReactComponent as DeveloperIcon } from './toDeveloper.svg';
import { ReactComponent as SellerIcon } from './toSeller.svg';
import { ReactComponent as ServiceChargeIcon } from './serviceCharge.svg';
import { ReactComponent as ResaleIcon } from './resale.svg';
import { ReactComponent as ReviewGoogleIcon } from './reviewGoogle.svg';

export const icons = {
  Balcony: BalconyIcon,
  MaidsRoom: MaidsRoomIcon,
  SharedPool: SharedPoolIcon,
  BuiltInWardrobes: BuiltInWardrobesIcon,
  ChildrenPlayArea: ChildrenPlayAreaIcon,
  SharedSpa: SharedSpaIcon,
  SharedGym: SharedGymIcon,
  Study: StudyIcon,
  CentralAc: CentralAcIcon,
  CoveredParking: CoveredParkingIcon,
  BarbecueArea: BarbecueAreaIcon,
  ViewOfWater: ViewOfWaterIcon,
  ViewOfLandmark: ViewOfLandmarkIcon,
  PetsAllowed: PetsAllowedIcon,
  Concierge: ConciergeIcon,
  PublicParking: PublicParkingIcon,
  KitchenAppliances: KitchenAppliancesIcon,
  LobbyInBuilding: LobbyInBuildingIcon,
  MaidService: MaidServiceIcon,
  PetsNotAllowed: PetsNotAllowedIcon,
  PrivateGarden: PrivateGardenIcon,
  PrivateGym: PrivateGymIcon,
  PrivatePool: PrivatePoolIcon,
  Security: SecurityIcon,
  TennisCourts: TennisCourtsIcon,
  ViewOfPark: ViewOfParkIcon,
  ViewOfCity: ViewOfCityIcon,
  PrivateSpa: PrivateSpaIcon,
  LaundryFacility: LaundryFacilityIcon,

  ActiveAds: ActiveAdsIcon,
  ArrowClockwise: ArrowClockwiseIcon,
  Bed: BedIcon,
  Buildings: BuildingsIcon,
  RentBuildings: RentBuildingsIcon,
  ComplStatus: ComplStatusIcon,
  CalendarPlus: CalendarPlusIcon,
  CirclePlus: CirclePlusIcon,
  Dollar: CurrencyCircleDollarIcon,
  Floor: FloorIcon,
  Furnishing: FurnishingIcon,
  HouseLine: HouseLineIcon,
  Edit: EditIcon,
  Land: LandIcon,
  Location: LocationIcon,
  Map: MapIcon,
  Note: NoteIcon,
  PaintRoller: PaintRollerIcon,
  Pdf: PdfIcon,
  Ruler: RulerIcon,
  Shower: ShowerIcon,
  User: UserIcon,
  Users: UsersIcon,
  UserSquare: UserSquareIcon,

  Answer: AnswerIcon,
  EditComment: EditCommentIcon,
  Report: ReportIcon,
  Unlike: UnlikeIcon,
  Replay: ReplayIcon,
  Delete: DeleteIcon,
  Like: LikeIcon,
  More: MoreIcon,

  DevelopStatus: DevelopStatusIcon,
  Calendar: CalendarIcon,
  Parking: ParkingIcon,
  PaymentPlan: PaymentPlanIcon,
  Archive: ArchiveIcon,
  Upload: UploadIcon,
  AddPlan: AddPlanIcon,
  AddBuilding: AddBuildingIcon,
  Link: LinkIcon,

  Handover: HandoverIcon,
  PostHandover: PostHandoverIcon,
  Developer: DeveloperIcon,
  Seller: SellerIcon,

  ServiceCharge: ServiceChargeIcon,
  Resale: ResaleIcon,
  ReviewGoogle: ReviewGoogleIcon

};
