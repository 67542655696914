import { ResponseType } from '@/types';

export const dialCoverage = async (
  request: () => Promise<ResponseType>,
  countAttempts: number = 3,
  delay: number = 10000
): Promise<ResponseType> =>
  new Promise(async (resolve) => {
    const data = await request();
    if (data.ok) {
      resolve(data);
      return;
    }

    let counter = 2;

    console.log(`Attempt #${counter}...`);
    const intervalId = setInterval(async () => {
      const data = await request();
      if (data.ok) {
        console.log(`SUCCESS - attempt #${counter}`);
        resolve(data);
        clearInterval(intervalId);
        return;
      } else {
        console.log(`ERROR - attempt #${counter} - ${data.message}`);
        counter++;
        if (counter > countAttempts) {
          console.log('Attempts ended');
          resolve(data);
          clearInterval(intervalId);
          return;
        } else {
          console.log(`Attempt #${counter}...`);
        }
      }
    }, delay);
  });
