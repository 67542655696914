export const emojiItemStyle = ({ theme }) => {
  return {
    display: 'inline-flex',
    height: '24px',
    backgroundColor: `${theme.backgroundColor.lightBlue4}`,
    border: `1px solid ${theme.borderColor.lightBlue1}`,
    cursor: 'pointer',
    borderRadius: '4px',
    alignItems: 'center',
    padding: '0px 5px 0px 2px',
    gap: '2px',
    '.emoji': {
      paddingTop: '1px',
      fontSize: '16px',
    },
    span: {
      fontSize: '12px',
      fontWeight: '600',
      color: `${theme.color.primary}`,
    },
  };
};

export const addEmojiButtonStyle = (img, { theme }) => {
  return {
    display: 'inline-block',
    height: '20px',
    width: '20px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}!important`,
  };
};
