import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';

export const useChangeQueryParams = (page: string) => {
  const navigate = useNavigate();

  return (paramsObj: Record<string, string | number | undefined>) => {
    const paramsStr = queryString.stringify(paramsObj);
    navigate(`/${page}?${paramsStr}`, { replace: true });
  };
};
