import {
  ResponseType,
} from '@/types';
import { apiClient } from '@/providers';
import {
  LocationSearchResponse,
  LocationVerificationRequest,
  LocationVerificationRequestSearch,
  LocationVerificationRequestSearchResponse,
} from "@/types";

export const locationProvider = {
  getAds: async ({
    name,
    locationId,
    propertyKind,
    searchByDistrict,
  }: { name?: string, locationId?: string, propertyKind?: string, searchByDistrict?: boolean }
  ): Promise<ResponseType<LocationSearchResponse>> => {
    const response = await apiClient(`/location/search`, 'POST', { name, locationId, propertyKind, searchByDistrict, limit: 20, offset: 0 });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as LocationSearchResponse;
    return { ...response, data };
  },

  createByMarker: async ({
    name,
    latitude,
    longitude,
  }: { name?: string, latitude?: number, longitude?: number }
  ): Promise<ResponseType> => {
    const response = await apiClient(`/location/createByMarker`, 'POST', { name, latitude, longitude });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  verificationRequest: async ({
    userId,
    propertyAdId,
    districtId,
    propertyName,
  }: LocationVerificationRequest
  ): Promise<ResponseType> => {
    const response = await apiClient(`/location/verificationRequest`, 'POST', { userId, propertyAdId, districtId, propertyName });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  verificationRequestSearch: async (data: LocationVerificationRequestSearch): Promise<ResponseType<LocationVerificationRequestSearchResponse>> => {
    const response = await apiClient(`/location/verificationRequest/search`, 'POST', data);
    if (!response.ok) {
      return { ...response, data: null };
    }
    const responseData = response.data as LocationVerificationRequestSearchResponse;
    return { ...response, data: responseData };
  },

  locationClarify: async ({
    name,
    districtId,
    latitude,
    longitude,
  }: { name?: string, districtId?: string, latitude?: number, longitude?: number }
  ): Promise<ResponseType> => {
    const response = await apiClient(`/location/clarify`, 'POST', { name, districtId, latitude, longitude });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  deleteVerificationRequest: async (verificationId: string) => {
    const response = await apiClient(`/location/verificationRequest/${verificationId}`, 'DELETE');
    return response;
  },
};
