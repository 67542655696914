import { IOffPlanModel, CreateNewPlanType } from '@/types';
export interface IAmenity {
  key: string;
  name: string;
  value: boolean;
}

export interface IAmenitiesCategories {
  "View Amenities": IAmenity[];
  "Private Amenities": IAmenity[];
  "Shared Amenities": IAmenity[];
  "Building And Area": IAmenity[];
  "Services And Features": IAmenity[];
}

type TAllDataType = {
  [key: string]: any;
  forecast?: {
    currentProgress?: number;
  };
};

export const getPropertyInformationFromState = (offPlanState?: IOffPlanModel) => ({
  constructionType: offPlanState?.constructionType,
  quarters: offPlanState?.quarters,
  years: offPlanState?.years,
});

export const getPropertyPriceFromState = (offPlanState?: IOffPlanModel) => ({
  sellingAgencyFeePercent: offPlanState?.sellingAgencyFeePercent,
});

export const getPropertyLocationState = (offPlanState?: IOffPlanModel) => ({
  addressEn: offPlanState?.addressEn,
  nameEn: offPlanState?.nameEn,
  coordinatesLat: offPlanState?.coordinatesLat
    ? parseFloat(offPlanState.coordinatesLat)
    : undefined,
  coordinatesLong: offPlanState?.coordinatesLong
    ? parseFloat(offPlanState.coordinatesLong)
    : undefined,
});

export const getAmentities = (data?: IOffPlanModel): IAmenitiesCategories => ({
  "View Amenities": [
    { key: "cityView", name: "City view", value: data?.cityView  || false },    
    { key: "landmarkView", name: "Landmark view", value: data?.landmarkView  || false },
    { key: "parkView", name: "Park view", value: data?.parkView  || false },
    { key: "waterView", name: "Water view", value: data?.waterView  || false },
  ],

  "Private Amenities": [
    { key: "privateGarden", name: "Private garden", value: data?.privateGarden  || false },
    { key: "privateGym", name: "Private gym", value: data?.privateGym  || false },
    { key: "privatePool", name: "Private pool", value: data?.privatePool  || false },
    { key: "privateSpa", name: "Private spa", value: data?.privateSpa  || false },
  ],

  "Shared Amenities": [
    { key: "sharedGym", name: "Shared gym", value: data?.sharedGym  || false },
    { key: "sharedPool", name: "Shared pool", value: data?.sharedPool  || false },
    { key: "sharedSpa", name: "Shared spa", value: data?.sharedSpa  || false },
  ],

  "Building And Area": [
    { key: "balcony", name: "Balcony", value: data?.balcony  || false },
    { key: "barbecueArea", name: "Barbecue area", value: data?.barbecueArea  || false },
    { key: "childrenPlayArea", name: "Children's play area", value: data?.childrenPlayArea  || false },
    { key: "coveredParking", name: "Covered parking", value: data?.coveredParking  || false },
    { key: "lobbyInBuilding", name: "Lobby in building", value: data?.lobbyInBuilding  || false },
    { key: "maidsRoom", name: "Maids room", value: data?.maidsRoom  || false },
    { key: "publicParking", name: "Public parking", value: data?.publicParking  || false },
    { key: "tennisCourts", name: "Tennis courts", value: data?.tennisCourts  || false },
    { key: "study", name: "Study", value: data?.study  || false },
    { key: "kitchenAppliances", name: "Kitchen appliances", value: data?.kitchenAppliances  || false },
  ],

  "Services And Features": [
    { key: "buildInWardrobes", name: "Built in wardrobes", value: data?.buildInWardrobes  || false },
    { key: "centralAC", name: "Central A/C", value: data?.centralAC || false },
    { key: "concierge", name: "Concierge", value: data?.concierge  || false },
    { key: "laundryFacility", name: "Laundry facility", value: data?.laundryFacility  || false },
    { key: "maidService", name: "Maid service", value: data?.maidService  || false },
    { key: "security", name: "Security", value: data?.security || false }
  ],    
});

export const getMediaGallery = (data: IOffPlanModel | CreateNewPlanType, mediaType: string = "image") => {
  const gallery = data.media ? data.media
    .filter((el: any) => el.mediaType === mediaType)
    .map((el: any) => ({ id: el.id, url: el.fileUrl, mediaType: el.mediaType, fileId: el.fileId })) : [];

  return gallery
}
  
export const prepareData = (allData: TAllDataType): TAllDataType => {
  const numericFields = [
    'priceFrom',
    'priceTo',
    'plotArea',
    'builtUpArea',
    'buildUpAreaFrom',
    'buildUpAreaTo',
    'numberOfFloors',
    'forecast.currentProgress',
    'sellingAgencyFeePercent',
    'serviceCharge',
  ];

  const dataCopy = JSON.parse(JSON.stringify(allData));

  return numericFields.reduce((acc, fieldPath) => {
    const fieldParts = fieldPath.split('.');
    let parent: TAllDataType | undefined = acc;
    let lastKey = fieldParts.pop();

    fieldParts.forEach(part => {
      parent = parent ? parent[part] as TAllDataType : undefined;
    });

    if (parent && lastKey && Object.prototype.hasOwnProperty.call(parent, lastKey)) {
      const value = parent[lastKey];
      if (value === '' || value === undefined || isNaN(Number(value))) {
        delete parent[lastKey];
      } else {
        parent[lastKey] = Number(value);
      }
    }

    return acc;
  }, dataCopy);
};
