import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  State,
  NewBuildingModel,
  FileKindEnum,
  NotificationTypeEnum,
  BuildingsDataType,
  CreateNewPlanType,
  UserKindEnum,
} from '@/types';
import { appSetNotification } from '@/store';
import { newBuildingsProvider } from '@/providers';
import { Loader, MyButton } from '@/components';

import {
  BasicDataBlock,
  DescriptionBlock,
  ForecastBlock,
  ObjectFeatureBlock,
  PaymentPlanBlock,
  MapBlock,
  AmenitiesBlock,
  MediaBlock,
  ContactBlock,
  PlansBlock,
  NewBuildingModal,
  StatisticBlock,
} from '../OffPlanView';

export const OffPlanView = () => {
  const [page, setPage] = useState<NewBuildingModel>();
  const [buildings, setBuildings] = useState<BuildingsDataType[]>();
  const [plans, setPlans] = useState<CreateNewPlanType[]>();
  const [totalPlans, setTotalPlans] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [isAddBuilding, setIsAddBuilding] = useState<boolean>(false);

  const { isLoggedIn, user } = useSelector(({ auth }: State) => auth);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getBuildingsData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getBuildings(id!);
    if (ok && data) {
      const { items, total } = data;
      setBuildings(items as BuildingsDataType[]);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getPlans = async (buildingId?: string | null) => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getPlans(id!, buildingId!);
    if (ok && data) {
      const { items, total } = data;
      setPlans(items as CreateNewPlanType[]);
      setTotalPlans(total);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getOffPlanData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getOffPlanById(id!);
    if (ok && data) {
      setPage(data as NewBuildingModel);
      await getBuildingsData();
      await getPlans();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && id) {
      getOffPlanData();
    }
  }, []);

  useEffect(() => {
    getBuildingsData();
  }, [isAddBuilding]);

  const videos = page?.media && page?.media.filter((item) => item.mediaType === FileKindEnum.Video);
  const brochures = page?.media && page?.media.filter((item) => item.mediaType === 'brochure');
  const description = page?.description?.trim() || '';

  const handleCloseConfirmDialog = () => setIsShownConfirmDialog(false);

  const isOwner = page?.developerId === user.id || user.kind === UserKindEnum.Admin;
  const isShowMedia = (videos && videos?.length > 0) || (brochures && brochures?.length > 0);
  const isShowStatistic = page && page?.projectNumber?.length > 0;

  return isLoading ? (
    <Loader />
  ) : !page ? null : (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='flex-start'
      justifyContent='center'
      spacing={2}
      sx={{ '@media (max-width: 600px)': { width: 'inherit' } }}
    >
      <Stack
        alignItems='flex-start'
        spacing={2}
        sx={{ flexGrow: '1', width: '100%', minWidth: { sm: '580px' } }}
      >
        <BasicDataBlock
          page={page}
          isMobile={isMobile}
          setIsShownConfirmDialog={setIsShownConfirmDialog}
        />
        {description.length > 0 && <DescriptionBlock description={description} />}
        {isShowStatistic && <StatisticBlock projectNumber={page?.projectNumber} />}
        <PlansBlock
          page={page}
          buildings={buildings}
          isAddBuilding={isAddBuilding}
          setIsAddBuilding={setIsAddBuilding}
          totalPlans={totalPlans}
          plans={plans}
        />
        {isOwner && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{
              width: '100%',
              height: '112px',
              padding: '5px',
              borderRadius: { xs: '10px', sm: '20px' },
              border: '2px dashed #1650ff',
            }}
          >
            <MyButton
              data={{
                buttonName: 'Add plan',
                customWidth: '192px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={() => navigate(`/admin/plan/create/${page.id}`)}
            />
          </Stack>
        )}
        {page.forecast && <ForecastBlock page={page} />}
        <ObjectFeatureBlock page={page} />
        {page.paymentPlan && <PaymentPlanBlock paymentPlan={page.paymentPlan} />}
        {isShowMedia && <MediaBlock videos={videos} brochures={brochures} isMobile={isMobile} />}
        <MapBlock coordinatesLat={page?.coordinatesLat} coordinatesLong={page?.coordinatesLong} />
        <AmenitiesBlock page={page} />
      </Stack>
      <ContactBlock page={page} />
      <NewBuildingModal
        open={isShownConfirmDialog}
        onClose={handleCloseConfirmDialog}
        id={page.id}
        isAddBuilding={isAddBuilding}
        setIsAddBuilding={setIsAddBuilding}
        typeOfDialog='create'
      />
    </Stack>
  );
};
