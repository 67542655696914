import {
  ResponseType,
  AuthSignupRequestType,
  AuthLoginResponseType,
  UserModel,
  AuthSignupResponseType,
  AuthGetTokenResponseType,
  AuthResetPasswordRequestType,
  UserAgencyStateLicense,
} from '@/types';
import { dialCoverage } from '@/utils';
import { apiClient, sessionProvider, usersProvider, chatProvider } from '@/providers';

export const authProvider = {
  login: async (login: string, password: string): Promise<ResponseType> => {
    const responseLogin = await apiClient('/auth/login', 'POST', {
      login,
      password,
    });
    if (!responseLogin.ok) {
      return { ...responseLogin, data: null };
    }

    const dataLogin = responseLogin.data as AuthLoginResponseType;
    sessionProvider.initAuthSession({ ...dataLogin.token });
    sessionProvider.initRefreshToken();
    const responseGetMyUser = await usersProvider.getMyUser();
    if (!responseGetMyUser.ok) {
      sessionProvider.removeAuthSession();
      return { ...responseGetMyUser, data: null };
    }

    const dataGetMyUser = responseGetMyUser.data as UserModel;
    const responseLoginChat = await dialCoverage(() =>
      chatProvider.login(dataLogin.chatAuthToken || '')
    );
    if (!responseLoginChat.ok) {
      sessionProvider.removeAuthSession();
      return { ...responseLoginChat, data: null };
    }

    return { ...responseLoginChat, data: dataGetMyUser };
  },

  logout: () => {
    sessionProvider.removeAuthSession();
    chatProvider.logout();
  },

  signup: async (payload: AuthSignupRequestType): Promise<ResponseType> => {
    const responseSignup = await apiClient('/auth/register', 'POST', payload);
    if (!responseSignup.ok) {
      return { ...responseSignup, data: null };
    }
    return { ...responseSignup };
  },

  signupStart: async (payload: AuthSignupRequestType): Promise<ResponseType> => {
    const responseSignup = await apiClient('/auth/register/start', 'POST', payload);
    if (!responseSignup.ok) {
      return { ...responseSignup, data: null };
    }
    return { ...responseSignup };
  },

  signupFinish: async (payload: AuthSignupRequestType): Promise<ResponseType> => {
    const responseSignup = await apiClient('/auth/register/finish', 'POST', payload);
    if (!responseSignup.ok) {
      return { ...responseSignup, data: null };
    }

    const data = responseSignup.data as AuthSignupResponseType;
    sessionProvider.initAuthSession({ ...data.token });
    sessionProvider.initRefreshToken();
    const responseGetMyUser = await usersProvider.getMyUser();
    if (!responseGetMyUser.ok) {
      sessionProvider.removeAuthSession();
      return { ...responseGetMyUser, data: null };
    }

    const dataGetMyUser = responseGetMyUser.data as UserModel;
    const responseLoginChat = await chatProvider.login(data.chatAuthToken || '');
    if (!responseLoginChat.ok) {
      sessionProvider.removeAuthSession();
      return { ...responseLoginChat, data: null };
    }

    return { ...responseLoginChat, data: dataGetMyUser };
  },

  signupNext: async (payload: AuthSignupRequestType): Promise<ResponseType> => {
    const responseSignup = await apiClient('/auth/partiallyRegistered', 'POST', payload);
    if (!responseSignup.ok) {
      return { ...responseSignup, data: null };
    }
    return { ...responseSignup };
  },

  signupAgencyByLink: async (payload: string): Promise<ResponseType> => {
    const responseSignup = await apiClient(`/auth/agency-by-link/${payload}`, 'GET');
    if (!responseSignup.ok) {
      return { ...responseSignup, data: null };
    }
    const data = responseSignup.data as any;
    return { ...responseSignup, data };
  },

  refreshToken: async (refreshToken: string): Promise<ResponseType> => {
    const response = await apiClient('/auth/refreshToken', 'POST', {
      refreshToken,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AuthGetTokenResponseType;
    sessionProvider.initAuthSession({ ...data });
    sessionProvider.initRefreshToken();

    return { ...response, data: null };
  },

  postRecoverPasswordRequest: async (
    payload: AuthResetPasswordRequestType
  ): Promise<ResponseType> => {
    const response = await apiClient('/auth/recoverPassword/request', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postRecoverPasswordCheck: async (
    payload: AuthResetPasswordRequestType
  ): Promise<ResponseType> => {
    const response = await apiClient('/auth/recoverPassword/check', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postRecoverPasswordChange: async (
    payload: AuthResetPasswordRequestType
  ): Promise<ResponseType> => {
    const response = await apiClient('/auth/recoverPassword/change', 'POST', payload);
    const data = response.ok ? (response.data as UserModel) : null;
    return { ...response, data };
  },
};
