import { useSelector } from 'react-redux';
import { State } from '@/types';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Dashboard, NewSignUp, Ads } from '@/components';

export const ReferralLinkPage = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viaCode = searchParams.get('via');

  useEffect(() => {
    if (viaCode) {
      localStorage.setItem('referralCode', viaCode);
    }
  }, [viaCode]);

  return (
    <Dashboard>
      {isLoggedIn ? <Ads /> : <NewSignUp />}
    </Dashboard>
  );
};
