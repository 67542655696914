import { CometChat } from '@cometchat-pro/chat';
import { apiClient } from '@/providers';

import { ResponseType } from '@/types';

interface ErrorType {
  code: string;
  name: string;
}

interface GetCountOfUnreadType {
  groups?: object;
  users?: object;
}

const getErrorMessage = (code = '', name = '') =>
  `CometChat error! Code: "${code}", Name: "${name}".`;

export const chatProvider = {
  login: async (chatAuthToken: string): Promise<ResponseType> => {
    try {
      await CometChat.login(chatAuthToken);
      return { data: null, ok: true };
    } catch (error) {
      const errorObj = error as ErrorType;
      const message = getErrorMessage(errorObj?.code, errorObj?.name);
      return {
        data: null,
        ok: false,
        message,
      };
    }
  },

  logout: () => CometChat.logout(),

  getCountOfUnread: async (): Promise<ResponseType> => {
    try {
      const response: GetCountOfUnreadType = await CometChat.getUnreadMessageCount(true);
      let countGroupsMsg = 0;
      let countUsersMsg = 0;
      if (response?.groups) {
        countGroupsMsg = Object.keys(response.groups).length;
        // countGroupsMsg = Object.values(array.groups).reduce((acc, item) => acc + item, 0);
      }
      if (response?.users) {
        countUsersMsg = Object.keys(response.users).length;
        // countUsersMsg = Object.values(array.users).reduce((acc, item) => acc + item, 0);
      }
      return {
        data: {
          countOfUnread: countGroupsMsg + countUsersMsg,
        },
        ok: true,
      };
    } catch (error) {
      const errorObj = error as ErrorType;
      const message = getErrorMessage(errorObj?.code, errorObj?.name);
      return {
        data: null,
        ok: false,
        message,
      };
    }
  },

  getSupportConversations: async (uuids: string[]): Promise<ResponseType> => {
    const response = (await apiClient(`/admin/support-convesations`, 'POST', uuids)) as any;
    if (!response.ok) {
      return { ...response, data: null };
    }

    return response?.data;
  },
};
