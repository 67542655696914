import { ActionsTypes, UserModel } from '@/types';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_TARIFF, AUTH_GET_MY_USER, AUTH_SET_IS_LOGGED_IN, AUTH_ADD_TARIFF_PRICE } from '@/store';

export const authLogin = (payload: UserModel): ActionsTypes => ({
  type: AUTH_LOGIN,
  payload,
});

export const authLogout = (): ActionsTypes => ({
  type: AUTH_LOGOUT,
  payload: null,
});

export const authTariff = (payload: any): ActionsTypes => ({
  type: AUTH_TARIFF,
  payload,
});

export const authAddTariffPrice = (payload: any): ActionsTypes => ({
  type: AUTH_ADD_TARIFF_PRICE,
  payload,
});

export const authGetMyUser = (payload: UserModel): ActionsTypes => ({
  type: AUTH_GET_MY_USER,
  payload,
});

export const authSetIsLoggedIn = (payload: boolean): ActionsTypes => ({
  type: AUTH_SET_IS_LOGGED_IN,
  payload,
});
