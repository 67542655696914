import { CSSProperties, ReactElement, useState, ChangeEvent } from 'react';
import { Stack, Typography, Checkbox, Tabs, Tab, TextField, Grid } from '@mui/material';
import { usersProvider } from '@/providers';
import { SectionTitle, FormButtons } from '@/components';
import { DownPaymentSearch } from '@/components/uiComponents/DownPaymentSearch';
import { KeywordSearch } from '@/components/uiComponents/KeywordSearch';
import { FormSelectWithFetch } from '@/components/uiComponents/FormSelect';
import styles from './MoreFiltersDialog.module.scss';
import { NumericFormat } from 'react-number-format';

export const FiltersBlock = ({
  name,
  secondName,
  icon,
  title,
  subTitle,
  type,
  size,
  options = [],
  onClick,
  activeButton,
  dataQa,
  propertyTypeTab,
  setPropertyTypeTab,
  onChange,
  isMyAgency,
  changeMyAgency,
  myFriends,
  changeMyFriends,
  disabled,
}: {
  name: string;
  secondName?: string[];
  icon?: ReactElement;
  title?: string;
  subTitle?: string[];
  type: 'button' | 'doubleButton' | 'input' | 'numberInput' | 'checkbox';
  size?: 'large' | 'big' | 'middle' | 'small' | 'little' | 'circle';
  options?: any[];
  onClick?: (value: any, name?: string) => void;
  activeButton?: any;
  dataQa?: string;
  propertyTypeTab?: number;
  setPropertyTypeTab?: (value: number) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>, name?: string) => void;
  isMyAgency?: boolean;
  changeMyAgency?: (value: boolean) => void;
  myFriends?: boolean;
  changeMyFriends?: (value: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='left'
      spacing={1}
      data-qa={`${dataQa}_block`}
    >
      {icon && title && <IconText icon={icon}>{title}</IconText>}
      {(propertyTypeTab === 0 || propertyTypeTab === 1) && (
        <div className={styles.container}>
          <Tabs
            value={propertyTypeTab}
            onChange={(_, val) => setPropertyTypeTab?.(val)}
            aria-label='property type tabs'
            className={styles.tabsContainer}
          >
            <Tab
              label='Residential'
              id='property-type-tab-0'
              aria-controls='property-type-tabpanel-0'
              className={styles.tabItem}
            />
            <Tab
              label='Commercial'
              id='property-type-tab-1'
              aria-controls='property-type-tabpanel-1'
              className={styles.tabItem}
            />
          </Tabs>
        </div>
      )}
      {type === 'button' && onClick && size && (
        <FormButtons
          name={name}
          options={options}
          onClick={onClick}
          activeButton={activeButton}
          size={size}
          dataQa={dataQa}
        />
      )}
      {type === 'doubleButton' && subTitle && onClick && size && (
        <Stack spacing={2}>
          <Stack>
            <Typography
              sx={{ fontSize: '13px', fontWeight: 800, marginBottom: '5px', textAlign: 'left' }}
            >
              {subTitle[0]}
            </Typography>
            <FormButtons
              name='quarter'
              options={options?.[0].quarters}
              onClick={onClick}
              activeButton={activeButton.quarter}
              size={size}
              dataQa={`${dataQa}_${subTitle[0]}`}
              disabled={disabled}
            />
          </Stack>
          <Stack>
            <Typography
              sx={{ fontSize: '13px', fontWeight: 800, marginBottom: '5px', textAlign: 'left' }}
            >
              {subTitle[1]}
            </Typography>
            <FormButtons
              name='year'
              options={options?.[0].years}
              onClick={onClick}
              activeButton={activeButton.year}
              size={size}
              dataQa={`${dataQa}_${subTitle[1]}`}
            />
          </Stack>
        </Stack>
      )}
      {type === 'numberInput' && subTitle && onChange && secondName && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          data-qa={`${dataQa}_text_fields_block`}
        >
          <NumericFormat
            sx={{ width: { xs: '100%', sm: '300px' } }}
            customInput={TextField}
            value={activeButton[secondName[0]]}
            allowNegative={false}
            thousandSeparator=' '
            decimalScale={0}
            placeholder={subTitle[0]}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, secondName?.[0])}
            data-qa={`${dataQa}_${secondName[0]}`}
          />
          <NumericFormat
            sx={{ width: { xs: '100%', sm: '300px' } }}
            customInput={TextField}
            value={activeButton[secondName[1]]}
            allowNegative={false}
            thousandSeparator=' '
            decimalScale={0}
            placeholder={subTitle[1]}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, secondName?.[1])}
            error={
              Number(activeButton[secondName[0]]) > Number(activeButton[secondName[1]]) &&
              Number(activeButton[secondName[1]]) !== 0
            }
            helperText={
              Number(activeButton[secondName[0]]) > Number(activeButton[secondName[1]]) &&
              Number(activeButton[secondName[1]]) !== 0
                ? 'max value should be more or equal than min value'
                : ''
            }
            data-qa={`${dataQa}_${secondName[1]}`}
          />
        </Stack>
      )}
      {name === 'downPayment' && type === 'input' && onClick && (
        <DownPaymentSearch
          placeholder={title}
          defaultDownPayment={activeButton}
          handleChange={onClick}
          dataQa={`${dataQa}_${type}`}
        />
      )}
      {name === 'keywords' && type === 'input' && onChange && (
        <KeywordSearch
          placeholder={title}
          defaultKeywords={activeButton}
          handleChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          dataQa={`${dataQa}_${type}`}
        />
      )}
      {type === 'checkbox' && onClick && (
        <Stack direction={{ xs: 'column' }} justifyContent='left'>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} justifyContent='space-between'>
            <Stack direction={'row'} alignItems='center' justifyContent='left' spacing={1} data-qa={`${dataQa}_${type}`}>
              <Checkbox
                icon={<span />}
                checkedIcon={<span className={styles.checked} />}
                style={{ color: '#1650ff' }}
                checked={isMyAgency}
                onChange={(e) => {
                  const val = e.target.checked;
                  changeMyAgency?.(val);
                }}
              />
              <Typography
                sx={{
                  color: '#1650ff',
                  fontSize: '16px',
                  textAlign: 'left',
                }}
              >
                My agency listings
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems='center' justifyContent='left' spacing={1} data-qa={`${dataQa}_${type}`}>
              <Checkbox
                icon={<span />}
                checkedIcon={<span className={styles.checked} />}
                style={{ color: '#1650ff' }}
                checked={myFriends}
                onChange={(e) => {
                  const val = e.target.checked;
                  changeMyFriends?.(val);
                }}
              />
              <Typography
                sx={{
                  color: '#1650ff',
                  fontSize: '16px',
                  textAlign: 'left',
                }}
              >
                My friends listings
              </Typography>
            </Stack>
            <Stack>
              <FormSelectWithFetch
                cbSearchBy={searchCreators}
                inputPlaceholder='Search agency / agent'
                handleChange={onClick}
                defaultData={activeButton}
                isMyAgency={isMyAgency}
                changeMyAgency={changeMyAgency}
                dataQa={`${dataQa}_select`}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const IconText = ({ icon, children }: { icon: ReactElement; children?: string }) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='left'
      spacing={1}
      sx={{ opacity: 0.6 }}
    >
      {icon}
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{children}</Typography>
    </Stack>
  );
};

const searchCreators = async (searchTerm: string) => {
  if (searchTerm?.length > 2) {
    const response = await usersProvider.getCreatorsByName({ name: searchTerm });
    if (response?.data?.items) {
      return response?.data?.items;
    }
  } else if (searchTerm?.length === 0) {
    return [];
  }
};
