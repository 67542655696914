import moment from 'moment';
import { Avatar, Box, Button, Link } from '@mui/material';

import { RequestModel } from '@/types';
import { stringAvatar, getKindLabel } from '@/utils';
import { MyButton } from '@/components';

import styles from './RequestItem.module.scss';
import calendarIcon from './calendarIcon.svg';
import removeMember from '@/components/Team/MemberItem/removeMemberIcon.svg';

interface RequestItemProps {
  item: RequestModel;
  onAcceptRequest: () => void;
  onCancelRequest: () => void;
}

export const RequestItem = ({
  item: {
    user: { id, avatar, name, kind },
    createdAt,
  },
  onAcceptRequest,
  onCancelRequest,
}: RequestItemProps) => (
  <div className={styles.container}>
    <div className={styles.blockAvatar}>
      <div className={styles.avatarImage}>
        <Link href={`/users/${id}`} title={name}>
          {avatar ? (
            <img src={avatar} alt={name} />
          ) : (
            <Avatar {...stringAvatar(name)} sx={{ width: 72, height: 72 }} />
          )}
        </Link>
      </div>
      <div className={styles.nameLabel}>
        <Link href={`/users/${id}`} title={name}>
          {name}
        </Link>
        <div>{getKindLabel(kind)}</div>
      </div>
    </div>

    <div className={styles.blockDate}>
      <div className={styles.dateLabel}>
        <img src={calendarIcon} alt='Requests date' />
        Requests date:
      </div>
      <div className={styles.dateValue}>{moment(createdAt).format('DD MMMM, YYYY')}</div>
    </div>

    <div className={styles.blockButtons}>
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '150px',
          },
        }}
      >
        <MyButton
          data={{
            buttonName: 'Accept',
            customWidth: '148px',
            variant: 'contained',
            buttonType: 'button',
          }}
          onClick={onAcceptRequest}
        />
      </Box>

      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '150px',
          },
        }}
      >
        <MyButton
          data={{
            buttonName: 'Cancel',
            customWidth: '148px',
            variant: 'outlined',
            styleType: 'cancel',
            buttonType: 'button',
          }}
          onClick={onCancelRequest}
        />
      </Box>
    </div>
  </div>
);
