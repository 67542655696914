import { FC, ElementType } from 'react';
import { Controller } from 'react-hook-form';
import { StyledTextField, StyledIconButton } from '../styled';
import { InputAdornment } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { ITextFieldComponentProps } from '../types';
import { icons } from '../../AccountManagement/Profile/Icons/icons';

const TextFieldComponent: FC<ITextFieldComponentProps> = ({
  control,
  name,
  placeholder,
  error,
  helperText,
  disabled,
  type,
  showPassword,
  setShowPassword,
  endAdornment,
  onChange,
  onClick,
  fillColor,
  customWidth,
  multiline,
  emailOrPhoneInput,
  maxLength,
  dataQa,
}) => {
  let IconComponent: ElementType;
  if (name === 'password' || name === 'confirmPassword') {
    IconComponent = showPassword ? VisibilityOffOutlined : VisibilityOutlined;
  } else if (name === 'passwordSettings') {
    IconComponent = icons.EditProfile;
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, updateValue: (value: string) => void) => {
    if (name === 'whatsapp' || name === 'telegram') {
      e.preventDefault();
      let text = e.clipboardData.getData('text');
      text = text.replace(/(?!^\+)\D/g, '');
      if (/^\+?\d+$/.test(text)) {
        updateValue(text);
      } else {
        console.log('The inserted text is not a phone number');
      }
    }
  };
  
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const updateValue = (value: string) => {
            field.onChange(value);
          };
          return (
            <StyledTextField
              data-qa={dataQa}
              fillColor={fillColor}
              {...field}
              value={(name === 'emailOrPhone' && emailOrPhoneInput) || field.value || ''}
              autoComplete={name}
              variant='outlined'
              fullWidth
              autoFocus={name === 'emailOrPhone'}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              disabled={disabled}
              inputProps={{ maxLength: maxLength ? maxLength : 80 }}
              onClick={onClick}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => handlePaste(e, updateValue)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (name === 'emailOrPhone' && onChange) {
                  const { value } = e?.target;
                  onChange(value, name);
                }
                field.onChange(e);
              }}
              type={type || 'text'}
              customWidth={customWidth}
              multiline={multiline}
              minRows='8'
              rows={8}
              FormHelperTextProps={{
                style: { fontFamily: 'Poppins', fontSize: '14px' },
              }}
              InputProps={
                endAdornment
                  ? {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <StyledIconButton
                            data-qa='open_pass_btn'
                            disabled={disabled}
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword && setShowPassword((prev) => !prev)}
                            edge='end'
                          >
                            <IconComponent />
                          </StyledIconButton>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          );
        }}
      />
    </>
  );
};

export default TextFieldComponent;
