import * as yup from 'yup';

import {
  AdFieldBedsEnum,
  FileKindEnum,
} from '@/types';

export const schemaNewPlanForm = yup.object().shape({
  beds: yup
    .string()
    .oneOf(
      [
        AdFieldBedsEnum.Studio,
        AdFieldBedsEnum.One,
        AdFieldBedsEnum.Two,
        AdFieldBedsEnum.Three,
        AdFieldBedsEnum.Four,
        AdFieldBedsEnum.Five,
        AdFieldBedsEnum.Six,
        AdFieldBedsEnum.Seven,
        AdFieldBedsEnum.EightPlus,
      ],
      'Choose one value from the list'
    )
    .required('Beds is a required field'),
    // .nullable(),

  planCode: yup
    .string()
    .trim()
    .required('Plan code is a required field')
    .nullable(),

  buildingId: yup
    .array()
    .of(yup.string())
    .when('$isBuildingVisible', (isBuildingVisible, schema) => {
      const isVisible = Array.isArray(isBuildingVisible) 
        ? isBuildingVisible.some(value => value === true) 
        : isBuildingVisible;

      return isVisible
        ? schema.min(1, 'Building is a required field')
        : schema;
    })
    .nullable(),

  media: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        fileId: yup.string(),
        mediaType: yup
          .string()
          .oneOf([FileKindEnum.Image, FileKindEnum.Video, FileKindEnum.Plan, FileKindEnum.Link])
          .required('PropertyAdMedia[Kind] is a required field'),
        order: yup.number().required('PropertyAdMedia[Order] is a required field'),
        fileUrl: yup.string(),
      })
    )
    .required('PropertyAdMedia is a required field')
    .test(
      'min-images',
      `A minimum of one photo is required for a comprehensive listing.`,
      function (media) {
        const imageCount = media.filter(
          (med) => med.mediaType === FileKindEnum.Image
        ).length;
        return imageCount >= 1;
      }
    ),
});
