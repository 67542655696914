import React from 'react';
import { Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Logout from '@mui/icons-material/Logout';
import { icons } from '../AccountManagement/Profile/Icons/icons';
import { useNavigate } from 'react-router-dom';
import { authProvider } from '@/providers';
import { authLogout, appGetRequestCount } from '@/store';
import { useDispatch } from 'react-redux';

export const AccountMenu = ({
  anchorEl,
  open,
  handleClose,
}: {
  anchorEl: Element | null;
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{
        color: '#2A3842',
        '& .MuiPaper-root': {
          elevation: 0,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 46,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
    >
      <MenuItem onClick={() => navigate('/profile/view')}>
        <ListItemIcon style={{ color: 'inherit' }}>
          <AccountCircleOutlinedIcon fontSize='small' />
        </ListItemIcon>
        View Profile
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile/edit')}>
        <ListItemIcon style={{ color: 'inherit' }}>
          <icons.EditProfile />
        </ListItemIcon>
        Edit Profile
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile/settings')}>
        <ListItemIcon style={{ color: 'inherit' }}>
          <SettingsOutlinedIcon fontSize='small' />
        </ListItemIcon>
        Settings
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          authProvider.logout();
          dispatch(authLogout());
          dispatch(appGetRequestCount(null));
          navigate('/');
        }}
      >
        <ListItemIcon style={{ color: 'inherit' }}>
          <Logout fontSize='small' />
        </ListItemIcon>
        Exit
      </MenuItem>
    </Menu>
  );
};
