import { ResponseType, SupportRequestType } from '@/types';
import { apiClient } from '@/providers';

export const supportProvider = {
  support: async ({ name, email, phone, text }: SupportRequestType): Promise<ResponseType> => {
    const responseSupport = await apiClient('/support/email', 'POST', {
      name,
      email,
      phone,
      text,
    });

    // console.log('responseSupport:', responseSupport)
    if (!responseSupport.ok) {
      return { ...responseSupport, data: null };
    }
    return { ...responseSupport };
  },
};
