import { ILogInData } from './types';
import { UserKindEnum } from '@/types';
import { formOfOrganization, country } from '../../utils/fakeData';

export const selectUserTypeData = {
  header: {
    numOfStep: 1,
    name: 'Select user type',
    nextStep: 'Next: Find Yourself',
    nextStepManager: 'Next: Find your company',
    nextStepForeign: 'Next: Personal data',
  },
  bodyTitle: {
    label: 'Welcome to online property',
    description: 'Please select the type of user you will use on our platform',
  },
  bodyContent: [
    {
      name: 'radioGroup',
      label: '',
      radioGroup: [
        {
          name: UserKindEnum.Broker,
          label: 'Licensed Agent',
          type: 'broker',
          description: 'A Dubai-based broker, verified with a RERA license.',
        },
        {
          name: UserKindEnum.Manager,
          label: 'Agency Manager',
          type: 'manager',
          description: 'A Dubai real estate professional handles administrative tasks and client interactions in an agency, but without a RERA license.',
        },
        {
          name: UserKindEnum.Agency,
          label: 'Real estate agency',
          type: 'agency',
          description: 'DLD-licensed company trading properties, hiring users to work under it.',
        },
        {
          name: UserKindEnum.Developer,
          label: 'Developer',
          type: 'agency',
          description: 'Constructs, markets properties, and trades while employing personnel.',
        },
        {
          name: UserKindEnum.Foreign,
          label: 'Foreign Agent',
          type: 'foreign',
          description:
            `A broker operating outside of the UAE without a Dubai license.
            (Can’t post listings but can share client requests).`,
        },
      ],
      initialVisibility: true,
      typeOfComponent: 'radioGroup',
    },
  ],
  error: {
    name: 'errorText',
    label: 'Please select your type',
  },
};

export const findYourselfData = {
  header: {
    numOfStep: 2,
    name: 'Find Yourself',
    nextStep: 'Next: Check details',
  },
  bodyTitle: {
    label: 'Find Yourself',
    description: 'Find Yourself in the DLD License List',
  },
  bodyContent: [
    {
      data: {
        dataQa: 'selected_user_field',
        name: 'selectUserType',
        label: '',
        placeholder: 'Select user type',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'profile_option_field',
        name: 'profileVerificationOptions',
        label: 'Your full name',
        placeholder: 'Enter your full name or license data',
        options: [],
      },
      initialVisibility: true,
      typeOfComponent: 'autocomplete',
    },
  ],
  error: {
    name: 'errorText',
    label: 'Name required',
  },
};

export const checkDetailsData = {
  header: {
    numOfStep: 3,
    name: 'Check details',
    nextStep: 'Next: Create password',
  },
  bodyTitle: {
    label: 'Check details',
    description: `If details don't match or you don't have access to the phone number or email address, please contact support.`,
  },
  bodyContent: [
    {
      data: {
        dataQa: 'name_field',
        name: 'name',
        label: 'Your full name',
        placeholder: 'Enter your full name',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'company_field',
        name: 'companyName',
        label: 'Your agency',
        placeholder: 'Enter your company name',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'email_field',
        name: 'email',
        label: 'Your email',
        placeholder: 'Your email',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'inputEmail',
      errorText: 'Valid email address required'
    },
    {
      data: {
        dataQa: 'phone_field',
        name: 'phone',
        label: 'Your phone number',
        placeholder: 'Your phone number',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'phoneInput',
      errorText: 'Valid phone number required'
    },
  ],
};

export const checkDetailsDataAgency = {
  header: {
    numOfStep: 3,
    name: 'Check details',
    nextStep: 'Next: Create password',
  },
  bodyTitle: {
    label: 'Check details',
    description: `If details don't match or you don't have access to the phone number or email address, please contact support.`,
  },
  bodyContent: [
    {
      data: {
        dataQa: 'name_field',
        name: 'name',
        label: 'Company name',
        placeholder: 'Enter your full name',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'office_number_field',
        name: 'officeNumber',
        label: 'Office number',
        placeholder: 'Enter your office number',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'email_field',
        name: 'email',
        label: 'Your email',
        placeholder: 'Your email',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'inputEmail',
      errorText: 'Valid email address required'
    },
    {
      data: {
        dataQa: 'phone_field',
        name: 'phone',
        label: 'Your phone number',
        placeholder: 'Your phone number',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'phoneInput',
      errorText: 'Valid phone number required'
    },
  ],
};

export const createPasswordData = {
  header: {
    numOfStep: 4,
    name: 'Create password',
    nextStep: 'Next: Email confirmation',
  },
  bodyTitle: {
    label: 'Create password',
    description: `Create a strong password to log in to the system. Use up to 8 uppercase and lowercase characters`,
  },
  bodyContent: [
    {
      data: {
        dataQa: 'create_pass_field',
        name: 'password',
        label: 'Create password',
        placeholder: 'Create your password',
        endAdornment: true,
        maxLength: 20,
      },
      initialVisibility: true,
      disabled: false,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'confirm_pass_field',
        name: 'confirmPassword',
        label: 'Confirm password',
        placeholder: 'Confirm your password',
        endAdornment: true,
        maxLength: 20,
      },
      initialVisibility: true,
      disabled: false,
      typeOfComponent: 'input',
    },
  ],
};

export const emailConfirmationData = {
  header: {
    numOfStep: 5,
    name: 'Email confirmation',
    nextStep: 'Next: Start use system',
  },
  bodyTitle: {
    label: 'Email confirmation',
    description: `A one-time verification code has been sent to your mail and phone number "testmail@org.net" “+77 43 344 44”. Please enter it in the field below.`,
  },
};

export const findYourCompanyData = {
  header: {
    numOfStep: 2,
    name: 'Find your company',
    nextStep: 'Next: Contact data',
  },
  bodyTitle: {
    label: 'Find your company',
    description: 'Find your company in the DLD License List',
  },
  bodyContent: [
    {
      data: {
        dataQa: 'selected_user_field',
        name: 'selectUserType',
        label: '',
        placeholder: 'Select user type',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      data: {
        dataQa: 'profile_option_field',
        name: 'profileVerificationOptions',
        label: 'Your company full name',
        placeholder: 'Enter your company full name or license data',
        options: [],
      },
      initialVisibility: true,
      typeOfComponent: 'autocomplete',
    },
  ],
  error: {
    name: 'errorText',
    label: 'Name required',
  },
};

export const personalData = {
  header: {
    numOfStep: 2,
    name: 'Personal data',
    nextStep: 'Next: Contact data',
  },
  bodyTitle: {
    label: 'Personal data',
    description: 'Enter your personal information',
  },
  bodyContent: [
    {
      data: {
        dataQa: 'input_user_field',
        name: 'selectUserType',
        label: '',
        placeholder: 'Select user type',
      },
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'disableInput',
    },
    {
      data: {
        dataQa: 'select_form_of_organization',
        name: 'formOfOrganization',
        label: 'Form of organization',
        placeholder: 'Select form of organization',
        options: formOfOrganization.map((el) => el.label),
      },
      initialVisibility: true,
      typeOfComponent: 'select',
      errorText: 'Form of organization is required',
    },
    {
      data: {
        dataQa: 'select_country',
        name: 'country',
        label: 'Choose your country',
        placeholder: 'Select country',
        options: country,
      },
      initialVisibility: true,
      typeOfComponent: 'select',
      errorText: 'Country is required'
    },
    {
      data: {
        dataQa: 'input_city',
        name: 'city',
        label: 'Choose your city',
        placeholder: 'Enter city name',
        maxLength: 80,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'City is required'
    },
  ],
  error: {
    name: 'errorText',
    label: 'Name required',
  },
};

export const contactDataForeign = {
  header: {
    numOfStep: 3,
    name: 'Contact data',
    nextStep: 'Next: Create password',
  },
  bodyTitle: {
    label: 'Contact data',
    description: 'Please fill in your contact information to verify you as a Foreign Agent',
  },
  bodyContent: [
    {
      data: {
        dataQa: 'input_name',
        name: 'name',
        label: 'Your full name',
        labelSecond: 'Your company name',
        placeholder: 'Enter your full name',
        placeholderSecond: 'Enter your company name',
        maxLength: 80,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'Valid name required'
    },
    {
      data: {
        dataQa: 'input_email',
        name: 'email',
        label: 'Your email',
        placeholder: 'Enter your email',
        maxLength: 80,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'Valid email address required'
    },
    {
      data: {
        dataQa: 'phone_field',
        name: 'phone',
        label: 'Your phone number',
        placeholder: 'Your phone number',
      },
      initialVisibility: true,
      typeOfComponent: 'phoneInput',
      errorText: 'Valid phone number required'
    },
    {
      data: {
        dataQa: 'input_instagram',
        name: 'link',
        label: 'Instagram or Linkedin',
        placeholder: 'Enter links to your instagram or linkedin',
        maxLength: 200,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'Valid Instagram or LinkedIn required'
    },
  ],
};

export const contactDataManager = {
  header: {
    numOfStep: 3,
    name: 'Contact data',
    nextStep: 'Next: Create password',
  },
  bodyTitle: {
    label: 'Contact data',
    description: 'Please fill in your contact information to verify you as a Agency Manager',
  },
  bodyContent: [
    {
      data: {
        dataQa: 'input_name',
        name: 'name',
        label: 'Your full name',
        labelSecond: 'Your company name',
        placeholder: 'Enter your full name',
        placeholderSecond: 'Enter your company name',
        maxLength: 80,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'Valid name required'
    },
    {
      data: {
        dataQa: 'input_email',
        name: 'email',
        label: 'Your email',
        placeholder: 'Enter your email',
        maxLength: 80,
      },
      initialVisibility: true,
      typeOfComponent: 'input',
      errorText: 'Valid email address required'
    },
    {
      data: {
        dataQa: 'phone_field',
        name: 'phone',
        label: 'Your phone number',
        placeholder: 'Your phone number',
      },
      initialVisibility: true,
      typeOfComponent: 'phoneInput',
      errorText: 'Valid phone number required'
    },
  ],
};

export const accountUpdate = {
  header: {
    numOfStep: 3,
    name: 'Check details',
    nextStep: 'Next: Check details',
  },
  bodyTitle: {
    label: 'Account Registration Update',
    description: 'Your email',
    descriptionTwo: ' is currently associated with the',
    descriptionThree: `agency. Therefore, your account will be registered as the`,
    descriptionFour: `'Real Estate Agency'`,
    descriptionFive: 'type instead of a broker.',
  },
};

export const logInData: ILogInData = {
  logInTitle: {
    label: 'Authorization',
  },
  logInContent: [
    {
      name: 'emailOrPhone',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'phoneNumber',
      label: 'Your phone number in international format: +971',
      placeholder: 'Enter your phone number',
      initialVisibility: false,
      typeOfComponent: 'phoneInput',
    },
    {
      name: 'error',
      label: 'Sign up',
      passiveLabel: 'Invalid login. Check your email/password or',
      passiveLabelFinish: '',
      background: true,
      actionKey: 'signUpAction',
      fontSize: '14px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'error400',
      label: '',
      passiveLabel: 'Login and password should not be empty.',
      background: true,
      fontSize: '14px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'password',
      label: 'Your password',
      placeholder: 'Password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'forgotPassword',
      label: 'Forgot password?',
      background: true,
      actionKey: 'forgotPasswordAction',
      fontSize: '14px',
      fontWeight: '500',
      alignment: 'flex-end',
      initialVisibility: true,
      typeOfComponent: 'link',
    },
    {
      name: 'logIn',
      buttonName: 'Log in',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'logInAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

export const resetPasswordData: ILogInData = {
  logInTitle: {
    label: 'Reset password',
    description: 'To reset your password, enter your email address or phone number.',
  },
  logInContent: [
    {
      name: 'emailOrPhone',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'phoneNumber',
      label: 'Your phone number in international format: +971',
      placeholder: 'Enter your phone number',
      initialVisibility: false,
      typeOfComponent: 'phoneInput',
    },
    {
      name: 'error',
      label: '',
      passiveLabel: 'Please check your email or phone',
      background: true,
      actionKey: 'signUpAction',
      fontSize: '14px',
      fontWeight: '500',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'error400',
      label: '',
      passiveLabel: 'Login can only be email, phone.',
      background: true,
      fontSize: '14px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
  ],
};

export const logIn = {
  name: 'logIn',
  label: 'Log in.',
  passiveLabel: 'Already on Online Property?',
  background: true,
  actionKey: 'logInAction',
  fontSize: '16px',
  fontWeight: '800',
  alignment: 'center',
  initialVisibility: true,
  typeOfComponent: 'link',
};

export const signUp = {
  name: 'signUp',
  label: 'Sign up',
  passiveLabel: "Don't have an account yet?",
  background: true,
  actionKey: 'signUpAction',
  fontSize: '16px',
  fontWeight: '800',
  alignment: 'center',
  initialVisibility: true,
  typeOfComponent: 'link',
};

export const createPasswordDataLogIn: ILogInData = {
  logInTitle: {
    label: 'Reset password',
    description: 'Create a new strong password for further authorization in our system.',
  },
  logInContent: [
    {
      name: 'password',
      label: 'New password',
      placeholder: 'Create new password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'confirmPassword',
      label: 'Confirm password',
      placeholder: 'Confirm your password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'createPassword',
      buttonName: 'Create password',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'createPassword',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

export const error409 = {
  name: 'error409',
  label: 'Log in',
  passiveLabel:
    'This phone or email is already registered. Enter a different phone or email number or',
  passiveLabelFinish: 'to your account.',
  fontSize: '12px',
  fontWeight: '800',
  alignment: 'center',
  background: true,
  actionKey: 'logInAction',
  typeOfComponent: 'errorLink',
};

export const dialogOperatorData = {
  width: '640',
  dialogTitle: {
    label: 'Formation of an application for consultation by the operator',
    description:
      'In order for the operator to be able to quickly and efficiently help you solve your problem, please describe its essence. This will speed up the process.',
  },
  dialogContent: [
    {
      name: 'name',
      label: 'Your Name',
      placeholder: 'Enter your name',
      isRequired: true,
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'emailOrPhone',
      label: 'Your email',
      placeholder: 'Enter your email',
      initialVisibility: true,
      isRequired: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'phoneNumber',
      label: 'Your phone number',
      placeholder: 'Enter your phone number',
      // isRequired: true,
      initialVisibility: true,
      typeOfComponent: 'phoneInput',
    },
    {
      name: 'description',
      label: 'Describe the problem',
      placeholder: 'Enter your message',
      multiline: true,
      initialVisibility: true,
      maxLength: 2000,
      typeOfComponent: 'input',
    },
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      customWidth: '358px',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      styleType: 'dialog',
      typeOfComponent: 'button',
    },
  ],
};

export const dialogOperatorSuccessData = {
  width: '646',
  dialogTitle: {
    label: 'Your request has been successfully generated',
  },
  dialogContent: [
    {
      name: 'picture',
      label: 'picture',
      initialVisibility: true,
      customWidth: '230px',
      typeOfComponent: 'picture',
    },
    {
      name: 'description',
      label:
        'Your request has been successfully generated and submitted for consideration to our operator. We will contact you as soon as possible and help you solve your problem.',
      multiline: true,
      initialVisibility: true,
      typeOfComponent: 'text',
    },
    {
      // name: 'close',
      buttonName: 'Close',
      variant: 'contained',
      customWidth: '358px',
      actionKey: 'closeOperatorSuccessAction',
      initialVisibility: true,
      styleType: 'dialog',
      typeOfComponent: 'button',
    },
  ],
};

export const acceptData = {
  data: {
    dataQa: 'agreement',
    name: 'agreement',
    label: 'Terms of the User Agreement',
    labelSecond: 'Privacy Policy',
    passiveLabel: 'Yes, I accept the',
    passiveLabelFinish: 'and',
    background: true,
    fontSize: '14px',
    fontWeight: '800',
  },
  alignment: 'flex-end',
  actionKey: 'agreementAction',
  actionKeySecond: 'privacyPolicyAction',
  initialVisibility: true,
  typeOfComponent: 'link',
};