import { DialogActions, DialogContent, List, Typography, ListItem, ListItemText } from '@mui/material';
import { StyledButtonText } from '../../uiComponents/styled';
import { MyDialog } from '@/components';
import { agreements, ListBlock } from '../utils/AgreementPolicy';

const Agreement = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <MyDialog open={open} onClose={onClose} dialogTitle='Terms of the User Agreement' width='900'>
      <>
        <DialogContent
          dividers
          sx={{
            maxHeight: '600px',
            overflowY: 'auto',
            margin: 0,
            color: '#2A3842',
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>Definitions</Typography>
          <List sx={{ pl: { xs: 0, sm: 1 } }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (a) <strong>Website</strong> shall mean the website available at onlineproperty.io.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (b) <strong>Platform</strong> shall mean the software and software tools available to
                    the Customers.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (c) <strong>Customers</strong> shall mean individuals or legal entities permitted to
                    carry out professional services related to real estate markets, fit into criteria
                    described herein and registered with the Website, and granted access to the Services.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (d) <strong>Subscription</strong> means the scope of Services carried out to the
                    Customers dependent on the Subscription type carried within the agreed period.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (e) <strong>Subscription Fees</strong> shall mean recurrent payments (consideration)
                    payable in return of the Services under the Subscription.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (f) <strong>Users</strong> shall mean individuals granted with access to the Services
                    under the Subscription on behalf of the Customer.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={
                  <Typography variant='body1' component='span'>
                    (g) <strong>Company</strong> shall mean ONLINEPROPERTY FZCO (registration number
                    DSO-FZCO-30673, registered office: IFZA Dubai area within Dubai Silicon Oasis, Business
                    Park, DDP, premises number 001-32, Dubai, United Arab Emirates) and its worldwide
                    affiliates or business partners.
                  </Typography>
                }
              />
            </ListItem>
          </List>
          {agreements.map((agreement, index) => (
            <ListBlock key={index} listBlock={agreement} />
          ))}
        </DialogContent>
        <DialogActions>
          <StyledButtonText onClick={onClose}>Back</StyledButtonText>
        </DialogActions>
      </>
    </MyDialog>
  );
};

export default Agreement;
