import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  PaginationType,
  AdsSortingType,
  SortingOrderEnum,
  ActivityKindEnum,
  PropertyKindEnum,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  NotificationTypeEnum,
  State,
  UserKindEnum,
  ClientRequestModel,
  ClientRequestsButtonStatusEnum,
  ClientRequestFilteringType,
  RentPeriodEnum,
  ClientRequestGetAllResponseType,
  HeaderDialogsEnum,
} from '@/types';
import { setAddressParamsInFilteringObj } from '@/utils';
import { clientRequestProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination, DialogTariffPlan } from '@/components';

import { COUNT_ADS_PER_PAGE } from './constants';
import { RequestsFilters } from './RequestsFilters';
import { RequestsListControls } from './RequestsListControls';
import { EmptyList } from './EmptyList';
import { RequestsMyListItem } from './RequestsMyListItem';
import styles from './PropertyRequests.module.scss';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

export const PropertyRequests = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);
  const changeQueryParams = useChangeQueryParams('client-requests');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const [isLoadingGetRequests, setIsLoadingGetRequests] = useState<boolean>(true);
  const [requests, setRequests] = useState<ClientRequestModel[]>([]);
  const [filteringObj, setFilteringObj] = useState<ClientRequestFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const [totalRequests, setTotalRequests] = useState(0);
  const [deleteRequest, setDeleteRequest] = useState<string>();

  const isForeign = user.kind === UserKindEnum.Foreign;

  const queryParam = new URLSearchParams(window.location.search);
  const status = queryParam.get('status');
  const statusBtn = status
    ? status === ClientRequestsButtonStatusEnum.ClientRequests
      ? true
      : false
    : true;

  const sortingFields = ['createdAt'];

  const getAdRequest = async () => {
    const address =
      queryParams.addressParamLat && queryParams.addressParamLong
        ? [
            {
              nameEn: queryParams.addressParamName as string,
              coordinatesLat: queryParams.addressParamLat as string,
              coordinatesLong: queryParams.addressParamLong as string,
            },
          ]
        : [];

    const newFilteringObj: ClientRequestFilteringType = {
      address,
      locationId: (queryParams.locationId as string) || undefined,
      typeOfActivity: queryParams.typeOfActivity as ActivityKindEnum,
      period: queryParams.period as RentPeriodEnum,
      propertyKind: queryParams.propertyKind
        ? (queryParams.propertyKind as PropertyKindEnum)
        : undefined,
      areaFrom: parseInt(queryParams.areaFrom as string) || undefined,
      areaTo: parseInt(queryParams.areaTo as string) || undefined,
      beds: queryParams.beds as AdFieldBedsEnum,
      baths: queryParams.baths as AdFieldBathsEnum,
      priceFrom: parseInt(queryParams.priceFrom as string) || undefined,
      priceTo: parseInt(queryParams.priceTo as string) || undefined,
      myRequests: queryParams.status === ClientRequestsButtonStatusEnum.MyRequests ? true : false,
    };

    const newSortingObj = {
      field: 'createdAt',
      order: (queryParams.order as SortingOrderEnum) || SortingOrderEnum.Desc,
    };
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };

    setIsLoadingGetRequests(true);

    const { ok, data, status, message } = await clientRequestProvider.getClientRequest(
      newFilteringObj,
      newSortingObj,
      newPaginationObj
    );
    if (ok) {
      const { items, total } = data as ClientRequestGetAllResponseType;
      setRequests(items);
      setFilteringObj({ ...newFilteringObj });
      setSortingObj({ ...newSortingObj });
      setTotalRequests(total);
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      setRequests([]);
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetRequests(false);
  };

  useEffect(() => {
    getAdRequest();
  }, []);

  useEffect(() => {
    getAdRequest();
  }, [queryParamsStr, statusBtn, deleteRequest]);

  const handleChangeFilter = (
    newFilteringObj: ClientRequestFilteringType,
    shouldSendRequest?: boolean
  ) => {
    if (newFilteringObj.typeOfActivity === ActivityKindEnum.Rent) {
      // newFilteringObj.completionStatus = undefined;
    } else if (newFilteringObj.typeOfActivity === ActivityKindEnum.Sale) {
      newFilteringObj.period = undefined;
    } else {
      newFilteringObj.period = undefined;
      newFilteringObj.typeOfActivity = undefined;
    }

    if (!newFilteringObj.propertyKind?.length) {
      newFilteringObj.propertyKind = undefined;
    }

    setFilteringObj(newFilteringObj);
    if (shouldSendRequest) {
      changeQueryParams({
        ...queryParams,
        ...setAddressParamsInFilteringObj(newFilteringObj),
        page: 1,
      });
    }
  };

  const handleChangeSort = (newSortingObj: AdsSortingType) =>
    changeQueryParams({
      ...queryParams,
      ...newSortingObj,
    });

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleMyReqBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      status: ClientRequestsButtonStatusEnum.MyRequests,
      page: 1,
    });
  };

  const handleClientReqBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      status: ClientRequestsButtonStatusEnum.ClientRequests,
      page: 1,
    });
  };

  if (isLoadingGetRequests) {
    return <Loader />;
  }

  return (
    <div className={styles.requestsContainer}>
      {filteringObj && (
        <RequestsFilters
          filteringObj={filteringObj}
          onChangeFilters={handleChangeFilter}
          handleClientReqBtnClick={handleClientReqBtnClick}
          handleMyReqBtnClick={handleMyReqBtnClick}
          btnClientRequest={statusBtn}
          isForeign={isForeign}
        />
      )}
      <div className={styles.templateRequestsContainer}>
        <Typography sx={{ fontSize: '28px', fontWeight: '500' }}>
          Total <span style={{ fontWeight: 800, color: '#1650FF' }}>{totalRequests}</span> requests
        </Typography>
        {sortingObj && (
          <RequestsListControls
            sortingFields={sortingFields}
            sortingObj={sortingObj}
            onChangeSort={handleChangeSort}
          />
        )}
      </div>
      {!requests?.length ? (
        <EmptyList statusBtn={statusBtn} />
      ) : (
        <div className={styles.adsList}>
          {(requests as ClientRequestModel[]).map((item) => (
            <RequestsMyListItem
              key={item.id}
              item={item}
              setRequests={setRequests}
              btnClientRequest={statusBtn}
              deleteRequest={setDeleteRequest}
            />
          ))}
        </div>
      )}
      {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE && (
        <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
      )}
    </div>
  );
};
