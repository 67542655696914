import { useState, useEffect } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BannerOnlineProperty from './Utils/Img/banner-online-property.png';
import { logIn, signUp } from './Utils/signUpDescription';
import { StyledLink } from '@/components/uiComponents/styled';

export const LeftSide = ({type}: {type: string}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [url, setUrl] = useState<string>('');
  const [passiveLabel, setPassiveLabel] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const isSignUp = type==='signUp';

  useEffect(() => {
    setUrl(isSignUp ? '/logIn' : '/signUp');
    setPassiveLabel(isSignUp ? logIn.passiveLabel : signUp.passiveLabel);
    setLabel(isSignUp ? logIn.label : signUp.label);
  }, []);

  return (
    <Stack direction='column' spacing={1} alignItems='center'>
      <Box>
        <img
          style={{ width: '100%', maxWidth: '400px', objectFit: 'fill' }}
          src={BannerOnlineProperty}
          alt='banner online property'
        />
      </Box>
      <Stack direction='row' alignItems='center' display='flex' sx={{ maxWidth: '300px' }}>
        <Typography
          sx={{
            color: '#2A3842',
            fontSize: '16px',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          {passiveLabel}{' '}
          <StyledLink to={url} fontSize='16px' fontWeight='800'>
            {label}
          </StyledLink>{' '}
        </Typography>
      </Stack>
    </Stack>
  );
};
