import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, ChatGetCountOfUnreadResponseType, NotificationTypeEnum } from '@/types';
import { chatProvider } from '@/providers';
import { appSetNotification, chatGetCountOfUnread } from '@/store';

export const useUnreadChatCount = () => {
  const dispatch = useDispatch();
  const { chat: chatState } = useSelector((state: State) => state);

  useEffect(() => {
    const getCountOfUnread = async () => {
      const { ok, data, status, message } = await chatProvider.getCountOfUnread();
      if (ok) {
        dispatch(chatGetCountOfUnread(data as ChatGetCountOfUnreadResponseType));
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };

    getCountOfUnread();
  }, [dispatch, chatState.countOfUnread]);

  return { chatState };
};
