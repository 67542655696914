import { useState, MouseEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, Stack } from '@mui/material';
import { NewBuildingModel, FileKindEnum, AdFieldRentFrequencyEnum, State } from '@/types';
import {
  StyledPriceBlock,
  Title,
  Term,
  NumOfAd,
  StyledIconButton,
  Shell,
  StyledPriceSpan,
} from './styled';
import ImgArrayLeftBtn from '../Ads/AdsListItem/svg/imgArrayLeftBtn.svg';
import ImgArrayRightBtn from '../Ads/AdsListItem/svg/imgArrayRightBtn.svg';
import ItemIconImages from '../Ads/AdsListItem/svg/itemIconImages.svg';
import ItemIconVideo from '../Ads/AdsListItem/svg/itemIconVideo.svg';
import ItemIconPlan from '../Ads/AdsListItem/svg/itemIconPlan.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { priceFormat } from '@/utils';
import { OffPlanViewMenu } from './OffPlanViewMenu';
import { icons } from '../Ad/Icons/icons';
import styles from './Ad.module.scss';
import { Section } from '@/components/Ad/Section';
import { LightSlider, createLightSlider } from './LightSlider';

export const BasicDataBlock = ({
  page,
  isMobile,
  setIsShownConfirmDialog,
}: {
  page: NewBuildingModel;
  isMobile: boolean;
  setIsShownConfirmDialog: (value: boolean) => void;
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [lightboxPhotos, setLightboxPhotos] = useState<any>(null);

  const user = useSelector(({ auth }: State) => auth.user);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClickMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  useEffect(() => {
    const lbPhotos = createLightSlider({
      gallery: '#pswp-gallery-photos',
      children: 'a',
      onImageChange: setImageIndex,
    });
    setLightboxPhotos(lbPhotos);
    return () => lbPhotos?.destroy();
  }, []);

  const { images, pswpPhotos, hasVideo, hasPlan } = useMemo(() => {
    const media = page.media || [];
    const images = media
      .filter((item) => item.mediaType === FileKindEnum.Image && item.fileUrl)
      .sort((a, b) => a.order - b.order);

    const pswpPhotos = images.map((item) => ({
      fileUrl: item.fileUrl as string,
      order: item.order,
      id: item.id || `fallback-${item.order}`,
    }));

    const hasVideo = media.some((item) => item.mediaType === FileKindEnum.Video);
    const hasPlan = media.some((item) => item.mediaType === FileKindEnum.Plan);

    return { images, pswpPhotos, hasVideo, hasPlan };
  }, [page.media]);

  const handleChangeImageIndex = (newIndex: number) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (images.length === 0) return;
    setImageIndex((newIndex + images.length) % images.length);
  };

  const handleAdMediaClick =
    (index = 0) =>
    () =>
      lightboxPhotos?.loadAndOpen(index);

  return (
    <Stack className={styles.list}>
      <Stack className={styles.listItem}>
        {images && images?.length > 0 ? (
          <div
            className={styles.itemImg}
            onClick={handleAdMediaClick(imageIndex)}
            style={{ position: 'relative' }}
          >
            {images && images?.length > 0 && (
              <img data-id={imageIndex} src={images[imageIndex].fileUrl} alt={page.title} />
            )}
            {images && images.length > 1 && (
              <div className={styles.sliderControls}>
                <img
                  src={ImgArrayLeftBtn}
                  alt='Prev image'
                  onClick={handleChangeImageIndex(imageIndex - 1)}
                />
                <img
                  src={ImgArrayRightBtn}
                  alt='Next image'
                  onClick={handleChangeImageIndex(imageIndex + 1)}
                />
              </div>
            )}
            <div className={styles.itemImgInfo}>
              <div className={styles.itemImgIcons}>
                <div className={styles.itemImgIconsImages} style={{ position: 'relative' }}>
                  <div className={styles.imgTotal}></div>
                  <img src={ItemIconImages} alt='' style={{ position: 'relative', zIndex: 2 }} />
                  <div className={styles.imgTotalNumber}>{images?.length}</div>
                  {hasVideo && (
                    <img
                      src={ItemIconVideo}
                      style={{ position: 'relative', zIndex: 2 }}
                      className={styles.imgTotal}
                      alt=''
                    />
                  )}
                  {hasPlan && (
                    <img
                      src={ItemIconPlan}
                      style={{ position: 'relative', zIndex: 2 }}
                      className={styles.imgTotal}
                      alt=''
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.imgBottomCenterInfo}>
              {imageIndex + 1} of {images?.length}
            </div>
          </div>
        ) : null}
        <Section>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ margin: '-10px 0px' }}
          >
            <NumOfAd style={{ lineHeight: '0.7' }}>#101438{page.id}</NumOfAd>
            {isMobile && user.kind !== 'foreign' && (
              <StyledIconButton size='small' onClick={handleClickMenu}>
                <MoreVertIcon />
              </StyledIconButton>
            )}
            {!isMobile && user.kind !== 'foreign' && (
              <StyledIconButton
                style={{ color: '#2A3842', fontSize: '22px' }}
                size='small'
                onClick={handleClickMenu}
              >
                <MoreVertIcon />
              </StyledIconButton>
            )}
            <OffPlanViewMenu
              page={page}
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
              user={user}
              setIsShownConfirmDialog={setIsShownConfirmDialog}
            />
          </Stack>
          <Stack direction='row' alignItems='start' justifyContent='space-between' spacing={2}>
            <Title>{page.title}</Title>
          </Stack>
          <Stack direction='row' alignItems='end' justifyContent='left' spacing={2}>
            <StyledPriceBlock>
              from
              <StyledPriceSpan>{priceFormat(page.priceFrom)}</StyledPriceSpan>
              to
              <StyledPriceSpan>{priceFormat(page.priceTo)}</StyledPriceSpan>
              <div>AED</div>
            </StyledPriceBlock>
          </Stack>
          <Stack direction='row' alignItems='end' justifyContent='left' spacing={10}>
            <StyledPriceBlock>
              from
              <StyledPriceSpan>{priceFormat(page?.buildUpAreaFrom)}</StyledPriceSpan>
              <div>sqft</div>
            </StyledPriceBlock>
            {page.sellingAgencyFeePercent ? (
              <StyledPriceBlock>
                {`developer's fee`}
                <StyledPriceSpan>{page.sellingAgencyFeePercent} %</StyledPriceSpan>
              </StyledPriceBlock>
            ) : null}
          </Stack>
          {page.nameEn && (
            <Stack direction='row' justifyContent='left' alignItems='flex-start' spacing={1}>
              <icons.Map className={styles.locationIcon} />
              <div>{page.nameEn}</div>
            </Stack>
          )}
          <Link
            className={styles.linkOnMap}
            href={`https://www.google.com/maps/place/${page.coordinatesLat},${page.coordinatesLong}/@${page.coordinatesLat},${page.coordinatesLong},17z`}
            target='_blank'
          >
            <icons.Location className={styles.locationIcon} />
            <span>{page.addressEn}</span>
          </Link>
        </Section>
      </Stack>
      <LightSlider images={pswpPhotos} id='pswp-gallery-photos' />
    </Stack>
  );
};
