import axios, { AxiosRequestHeaders, AxiosResponse, isAxiosError } from 'axios';

import { ResponseDataType, ResponseType } from '@/types';

export const awsClient = async (url: string, method: string, file: File): Promise<ResponseType> => {
  const headers = {
    ['Content-disposition']: `attachment;filename="${file.name}"`,
    ['Content-Type']: file.type,
  } as AxiosRequestHeaders & {
    ['Content-disposition']: string;
  };

  try {
    const response: AxiosResponse<ResponseDataType> = await axios({
      url,
      method,
      headers,
      data: file,
    });

    return {
      data: response.data,
      ok: true,
      status: response.status,
      message: response.statusText,
    };
  } catch (error) {
    let status, message;
    if (isAxiosError(error)) {
      status = error?.response?.data.statusCode || error?.response?.status || error?.code;
      message = error?.response?.data.message || error?.response?.statusText || error?.message;
    } else {
      message = `Not Axios error was received from the server (awsClient request). Error: ${JSON.stringify(
        error
      )}`;
    }

    return {
      data: null,
      ok: false,
      status,
      message,
    };
  }
};
