import { FC, ChangeEvent, useState } from 'react';
import { Stack, Radio, RadioGroup, FormControl, FormControlLabel, Typography } from '@mui/material';
import { MyButton, MyDialog } from '@/components';
import { ISelectUserTypeProps, IMyLabelProps } from './types';

const MyLabel: FC<IMyLabelProps> = ({ data }) => {
  return (
    <>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{data.label}</Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{data.description}</Typography>
    </>
  );
};

const SelectUserType: FC<ISelectUserTypeProps> = ({
  open,
  onClose,
  dialogData,
  value,
  changeValue,
  onSubmit,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeValue(event.target.value);
  };

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle={dialogData.dialogTitle.label}
      width={dialogData.width.toString()}
    >
      <Stack spacing={3} sx={{ width: '100%', marginBottom: '20px' }}>
        {dialogData.dialogContent.map((content) => {
          if (!content.initialVisibility) {
            return null;
          }
          switch (content.typeOfComponent) {
            case 'radioGroup':
              return (
                <FormControl key={content.name}>
                  <RadioGroup value={value} onChange={handleChange}>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                      {content.radioGroup?.map((radio) => (
                        <FormControlLabel
                          key={radio.name}
                          value={radio.label}
                          control={<Radio sx={{ padding: '0', alignSelf: 'flex-start' }} />}
                          label={<MyLabel data={radio} />}
                          sx={{ justifyContent: 'space-between' }}
                        />
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              );
            case 'errorText':
              return (
                <Typography
                  key={content.name}
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    textAlign: 'center',
                    color: '#ff0000',
                  }}
                >
                  {content.label}
                </Typography>
              );
            case 'button':
              return <MyButton key={content.name} data={content} onClick={onSubmit} />;
          }
        })}
      </Stack>
    </MyDialog>
  );
};

export default SelectUserType;
