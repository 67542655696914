import { MouseEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnnouncerModel,
  NotificationTypeEnum,
  State,
  TeamGetAllAnnouncersResponseType,
} from '@/types';
import { customTheme } from '@/utils';
import { teamProvider } from '@/providers';
import { appSetNotification } from '@/store';
import styles from './ResponsibleUser.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ResponsibleUserProps {
  propertyAdAnnouncerId?: string;
  setPropertyAdAnnouncerId: (value: string) => void;
}

export const ResponsibleUser = ({
  propertyAdAnnouncerId: selectedId = '',
  setPropertyAdAnnouncerId: setSelectedId,
}: ResponsibleUserProps) => {
  const outerTheme = useTheme();
  const dispatch = useDispatch();

  const { id: userId } = useSelector(({ auth }: State) => auth.user);

  const [users, setUsers] = useState<AnnouncerModel[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      const { ok, data, status, message } = await teamProvider.getAnnouncers();
      if (ok) {
        const { items } = data as TeamGetAllAnnouncersResponseType;
        setUsers(items.filter((item) => item.user.id !== userId));
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };

    getUsers();
  }, []);

  const handleChange = ({ target }: SelectChangeEvent<string>) => {
    setSelectedId(target.value);
  };

  const handleDelete = (e: MouseEvent) => {
    setSelectedId(userId);
    e.preventDefault();
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <FormControl
        sx={{
          width: '100%',
          paddingBottom: '16px',
        }}
      >
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          sx={{
            borderRadius: '14px',
            border: '1px solid #E9EEF1',
          }}
          className={styles.fieldset}
          value={selectedId}
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' />}
          renderValue={(selected) => {
            if (!selected) {
              return null;
            }
            const contact = users.find((item) => item.user.id === String(selected));
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  paddingLeft: '45px',
                  marginTop: '-4px',
                }}
              >
                {contact ? (
                  <Chip
                    key={selected}
                    label={contact.user.name}
                    clickable
                    onDelete={handleDelete}
                    deleteIcon={<span className={styles.closeIcon} />}
                    onMouseDown={(e) => e.stopPropagation()}
                    className={styles.label}
                    sx={{
                      marginRight: '32px',
                      borderRadius: '2px',
                      background: '#F1F7FF',
                    }}
                  />
                ) : null}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {users.map(({ user: { id, name } }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
