import { useEffect, useState } from 'react';
import { AgencyType } from './types/types';
import { useDispatch } from 'react-redux';

import { licenseProvider } from '@/providers';

import { NotificationTypeEnum } from '@/types';
import { authSetIsLoggedIn, appSetNotification } from '@/store';
import { Loader, Pagination } from '@/components';

import { PaginationType } from '@/types';
import LicenseSearch from './LicenseComponents/LicenseSearch';
import LicenseTable from './LicenseComponents/AgencyLicenseTable';
import { useChangeQueryParams } from '@/hooks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LicenseEdit from '../Dialogs/LicenseEdit';

export const AgencyLicenses = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [paginationObj, setPaginationObj] = useState<PaginationType>();

  const [licenses, setLicenses] = useState<AgencyType[]>([]);
  const [search, setSearch] = useState<string>('');

  const [rowId, setRowId] = useState<string | undefined>(undefined);
  const [openLicenseEdit, setOpenLicenseEdit] = useState<boolean>(false);

  const changeQueryParams = useChangeQueryParams('admin/agency/licenses');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const getLicenseList = async (newLimit?: number, newOffset?: number) => {
    const updatedOffset = newOffset !== undefined ? newOffset : offset;
    const { ok, data } = await licenseProvider.searchAgency(
      newLimit || limit,
      updatedOffset,
      search
    );
    if (ok) {
      setLicenses(data.data);

      setPaginationObj({
        page: Math.floor(updatedOffset / (newLimit || limit)) + 1,
        offset: offset,
        limit: limit,
        total: data.total,
      });
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLicenseList();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const page = queryParams.page ? parseInt(queryParams.page as string) : 1;
    const newOffset = (page - 1) * limit;
    getLicenseList(limit, newOffset);
  }, [search]);

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setIsLoading(true);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    setPaginationObj({
      page: page,
      offset: newOffset,
      limit: limit,
      total: paginationObj?.total || 0,
    });
    changeQueryParams({ page: page });
    getLicenseList(limit, newOffset);
  };

  const onRowClickHandler = (id?: string) => {
    setRowId(id);
    setOpenLicenseEdit(true);
  };

  const createAgencyLicense = async (data: AgencyType) => {
    try {
      setIsLoading(true);
      const { ok, data: res } = await licenseProvider.createAgencyLicense(data);
      if (ok) {
        getLicenseList();
        setOpenLicenseEdit(false);
      }
    } catch (e) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error during agency creation'));
    } finally {
      setIsLoading(false);
    }
  };

  const editAgencyLicense = async (data: AgencyType) => {
    try {
      setIsLoading(true);
      const { ok, data: res } = await licenseProvider.editAgencyLicense(data);
      if (ok) {
        getLicenseList();
        setOpenLicenseEdit(false);
      }
    } catch (e) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error during agency editing'));
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAgencyLicense = async (id: string) => {
    try {
      setIsLoading(true);
      const { ok, data: res } = await licenseProvider.deleteAgencyLicense(id);
      if (ok) {
        getLicenseList();
        setOpenLicenseEdit(false);
      }
    } catch (e) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'error during agency deletion'));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!licenses?.length) {
    return <div>No data</div>;
  }

  return (
    <div style={{ width: '100%' }}>
      <LicenseSearch search={search} setSearch={setSearch} onRowClickHandler={onRowClickHandler} />
      <LicenseTable licenses={licenses} onRowClickHandler={onRowClickHandler} />
      {!!paginationObj?.total && (
        <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
      )}
      <LicenseEdit
        type='agency'
        open={openLicenseEdit}
        license={licenses?.find((l) => l.id === rowId)}
        actions={{
          onCreate: (data: AgencyType) => createAgencyLicense(data),
          onEdit: (data: AgencyType) => editAgencyLicense(data),
          onDelete: (id: string) => deleteAgencyLicense(id),
        }}
        onClose={() => setOpenLicenseEdit(false)}
      />
    </div>
  );
};
