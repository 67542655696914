import { useEffect, useState } from 'react';
import { Container, DialogActions, DialogContent } from '@mui/material';
import { MyDialog, MyButton, Loader } from '@/components';
import styles from './../../AdForm/AdForm.module.scss';
import { styled } from '@mui/system';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import { useEmploymentRequests } from '../hooks/useEmploymentRequests';

const Avatar = styled('img')`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

const EmploymentRequest = ({
  open,
  selectedUserId,
  onConfirm,
  onClose,
}: {
  open: boolean;
  selectedUserId: number;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { employmentRequests, isLoading, acceptEmploymentRequest, rejectEmploymentRequest } =
    useEmploymentRequests(selectedUserId, open, onConfirm, onClose);

  return (
    <MyDialog open={open} onClose={onClose} dialogTitle='Employment request' width='900'>
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          {isLoading && <Loader />}
          {!isLoading && !!employmentRequests?.length && (
            <Table>
              <TableBody>
                {employmentRequests?.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {request.employer.avatar && <Avatar src={request.employer.avatar} />}
                      {request.employer.name}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', float: 'right' }}>
                        `{' '}
                        <MyButton
                          data={{
                            buttonName: 'Confirm',
                            customWidth: '126px',
                            variant: 'contained',
                            buttonType: 'submit',
                          }}
                          onClick={() => acceptEmploymentRequest(request.employer.id)}
                        />
                        <MyButton
                          data={{
                            buttonName: 'Cancel',
                            customWidth: '126px',
                            variant: 'outlined',
                            buttonType: 'button',
                            styleType: 'cancel',
                          }}
                          onClick={() => rejectEmploymentRequest(request.employer.id)}
                        />
                        `
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!isLoading && !employmentRequests?.length && (
            <div>
              There are no requests for <br />
              employment connections at this time.
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}></div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default EmploymentRequest;
