import { ReactComponent as GenderIcon } from './gender.svg';
import { ReactComponent as NationalityIcon } from './nationality.svg';
import { ReactComponent as SpecializationIcon } from './specialization.svg';
import { ReactComponent as PhoneNumberIcon } from '../Icons/phonenumber.svg';
import { ReactComponent as LanguageIcon } from './language.svg';
import { ReactComponent as LocationIcon } from './location.svg';
import { ReactComponent as LinkIcon } from './link.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as AddressIcon } from './address.svg';
import { ReactComponent as DescriptionIcon } from './description.svg';
import { ReactComponent as EditProfileIcon } from './editProfileNew.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as InfoColorIcon } from './infoColor.svg';
import { ReactComponent as OrganizationIcon } from './organization.svg';
import { ReactComponent as LinkedinIcon } from './linkedin.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as TelegramIcon } from './telegram.svg';
import { ReactComponent as WhatsappIcon } from './whatsapp.svg';

export const icons = {
  Gender: GenderIcon,
  Nationality: NationalityIcon,
  Specialization: SpecializationIcon,
  PhoneNumber: PhoneNumberIcon,
  Language: LanguageIcon,
  Location: LocationIcon,
  Link: LinkIcon,
  Email: EmailIcon,
  Address: AddressIcon,
  Description: DescriptionIcon,
  EditProfile: EditProfileIcon,
  Info: InfoIcon,
  InfoColor: InfoColorIcon,
  Organization: OrganizationIcon,
  Linkedin: LinkedinIcon,
  Instagram: InstagramIcon,
  Telegram: TelegramIcon,
  Whatsapp: WhatsappIcon,
};
