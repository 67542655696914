import {
  AuthState,
  ActionsTypes,
  UserModel,
  GenderEnum,
  UserKindEnum,
  PersonKindEnum,
  UserPermissions,
} from '@/types';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_TARIFF, AUTH_GET_MY_USER, AUTH_SET_IS_LOGGED_IN, AUTH_ADD_TARIFF_PRICE } from '@/store';

export const userDefaultValues = {
  additionalEmails: [],
  additionalPhones: [],
  address: '',
  activityKinds: [],
  chatId: '',
  city: '',
  country: '',
  createdAt: '',
  description: '',
  email: '',
  employer: {
    id: '',
    name: '',
  },
  gender: GenderEnum.Male,
  id: '',
  kind: UserKindEnum.Admin,
  languages: [],
  links: [],
  media: [],
  name: '',
  nationality: '',
  personKind: PersonKindEnum.Physical,
  phone: '',
  rating: 0,
  stateLicense: {
    agency: {
      email: '',
      id: '',
      licenseEnd: '',
      licenseId: '',
      licenseStart: '',
      manual: true,
      nameAr: '',
      nameEn: '',
      phone: '',
      stateId: '',
    },
    email: '',
    id: '',
    licenseEnd: '',
    agencyStateLicenseId: '',
    licenseStart: '',
    manual: true,
    nameAr: '',
    nameEn: '',
    phone: '',
    stateId: '',
  },
  status: '',
  telegram: '',
  updatedAt: '',
  whatsapp: '',
  permissions: [],
};

export const tariffDefaultValues = {
  priceId: '',
};

const initialState: AuthState = {
  isLoggedIn: null,
  user: { ...userDefaultValues },
  tariff: null,
  tariffPriceId: null,
};

const authLogin = (state: AuthState, user: UserModel): AuthState => ({
  ...state,
  isLoggedIn: true,
  user,
});

const authLogout = (state: AuthState): AuthState => ({
  ...state,
  isLoggedIn: false,
  user: { ...userDefaultValues },
  tariff: null,
});

const authTariff = (state: AuthState, tariff: any): AuthState => ({
  ...state,
  isLoggedIn: true,
  tariff,
});

const authAddTariffPrice = (state: AuthState, tariffPriceId: any): AuthState => ({
  ...state,
  isLoggedIn: true,
  tariffPriceId,
});

const authGetMyUser = (state: AuthState, user: UserModel): AuthState => ({
  ...state,
  isLoggedIn: true,
  user,
});

const authSetIsLoggedIn = (state: AuthState, isLoggedIn: boolean): AuthState => ({
  ...state,
  isLoggedIn,
});

export const authReducer = (state = initialState, action: ActionsTypes) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return authLogin(state, action.payload as UserModel);
    case AUTH_LOGOUT:
      return authLogout(state);
    case AUTH_TARIFF:
      return authTariff(state, action.payload);
    case AUTH_ADD_TARIFF_PRICE:
      return authAddTariffPrice(state, action.payload)
    case AUTH_GET_MY_USER:
      return authGetMyUser(state, action.payload as UserModel);
    case AUTH_SET_IS_LOGGED_IN:
      return authSetIsLoggedIn(state, action.payload as boolean);
    default:
      return state;
  }
};
