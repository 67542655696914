import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Stack,
  TextField,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  SelectChangeEvent,
  Checkbox,
} from '@mui/material';
import { useChangeQueryParams } from '@/hooks';
import { UserKindEnum, UserAgencyStateLicense, StateLicense } from '@/types';
import { FindYourself, PersonalData } from '../SecondStep';
import { PersonalDateOfForeign } from '../Utils/types';
import Agreement from '@/components/AccountManagement/Dialogs/Agreement';
import PrivacyPolicy from '@/components/AccountManagement/Dialogs/PrivacyPolicy';
import { acceptData } from '../Utils/signUpDescription';
import styles from '@/components/uiComponents/FormCheckbox/FormCheckbox.module.scss';
import { StyledLink, PlaceholderTypography, StyledMenuItem } from '../../../uiComponents/styled';

type DialogType = 'agreement' | 'privacyPolicy' | null;

export const SecondStep = ({
  isUsersVisible,
  setIsUsersVisible,
  radioUserValue,
  setRadioUserValue,
  radioValue,
  setError,
  isMobile,
  kind,
  newAutocompleteValue,
  setNewAutocompleteValue,
  setUser,
  error,
  helperText,
  setPersonalDateOfForeign,
  personalDateOfForeign,
  errorFields,
  setErrorFields,
  isAccept,
  setIsAccept,
  newAgency,
  inviteCode,
  isAcceptError,
  setIsAcceptError,
}: {
  isUsersVisible: boolean;
  setIsUsersVisible: (isUsersVisible: boolean) => void;
  radioUserValue: string;
  setRadioUserValue: (radioUserValue: string) => void;
  radioValue: string;
  setError: (error: boolean) => void;
  isMobile: boolean;
  kind: UserKindEnum;
  newAutocompleteValue: string | null;
  setNewAutocompleteValue: (value: string | null) => void;
  setUser: (user: UserAgencyStateLicense) => void;
  error: boolean;
  helperText: string;
  setPersonalDateOfForeign: (value: PersonalDateOfForeign) => void;
  personalDateOfForeign: PersonalDateOfForeign;
  errorFields: string[];
  setErrorFields: (fields: string[]) => void;
  isAccept: boolean;
  setIsAccept: (accept: boolean) => void;
  newAgency?: StateLicense;
  inviteCode?: string;
  isAcceptError: boolean;
  setIsAcceptError: (error: boolean) => void;
}) => {
  const changeQueryParams = useChangeQueryParams('signUp');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [openedDialog, setOpenedDialog] = useState<DialogType>(null);

  useEffect(() => {
    changeQueryParams({ ...queryParams, step: 'two' });
  }, []);

  return (
    <>
      {kind !== UserKindEnum.Foreign ? (
        <FindYourself
          isUsersVisible={isUsersVisible}
          setIsUsersVisible={setIsUsersVisible}
          radioUserValue={radioUserValue}
          setRadioUserValue={setRadioUserValue}
          radioValue={radioValue}
          setError={setError}
          isMobile={isMobile}
          kind={kind}
          newAutocompleteValue={newAutocompleteValue}
          setNewAutocompleteValue={setNewAutocompleteValue}
          setUser={setUser}
          error={error}
          helperText={helperText}
          newAgency={newAgency}
          inviteCode={inviteCode || undefined}
        />
      ) : (
        <PersonalData
          radioValue={radioValue}
          isMobile={isMobile}
          setPersonalDateOfForeign={setPersonalDateOfForeign}
          personalDateOfForeign={personalDateOfForeign}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
        />
      )}
      <Stack direction='row'>
        <Checkbox
          icon={<span />}
          checkedIcon={<span className={styles.checked} />}
          style={{ transform: 'scale(0.75)', marginLeft: '-11px', color: 'red' }}
          checked={isAccept}
          onChange={(e) => {
            const val = e.target.checked;
            setIsAccept(val);
            setIsAcceptError(!val);
          }}
        />
        <Stack
          direction='row'
          alignItems='center'
          display='flex'
          sx={{
            maxWidth: {
              xs: '100%',
              sm: '280px',
            },
          }}
        >
          <Typography
            sx={{
              color: '#2A3842',
              fontSize: '14px',
              textAlign: 'left',
            }}
          >
            {acceptData.data.passiveLabel}{' '}
            <StyledLink
              to='#'
              onClick={() => setOpenedDialog('agreement')}
              fontSize='14px'
              fontWeight='800'
            >
              {acceptData.data.label}
            </StyledLink>{' '}
            {acceptData.data.passiveLabelFinish}
            <StyledLink
              to='#'
              onClick={() => {
                setOpenedDialog('privacyPolicy');
              }}
              fontSize='14px'
              fontWeight='800'
            >
              {' '}
              {acceptData.data.labelSecond}
            </StyledLink>
          </Typography>
          <Agreement open={openedDialog === 'agreement'} onClose={() => setOpenedDialog(null)} />
          <PrivacyPolicy
            open={openedDialog === 'privacyPolicy'}
            onClose={() => setOpenedDialog(null)}
          />
        </Stack>
      </Stack>
      {isAcceptError && (
        <Typography
          sx={{
            color: 'red',
            fontSize: '12px',
            textAlign: 'center',
          }}
        >
          To continue registration you should accept the Terms of the User Agreement and Privacy
          Policy
        </Typography>
      )}
    </>
  );
};
