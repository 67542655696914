import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import {
  CometChatSmartReplyPreview,
  CometChatCreatePoll,
  CometChatStickerKeyboard,
} from '../Extensions';
import { CometChatEmojiKeyboard } from '../';

import { CometChatContext } from '../../../util/CometChatContext';
import { checkMessageForExtensionsData, ID, getUnixTimestamp } from '../../../util/common';
import * as enums from '../../../util/enums.js';
import { SoundManager } from '../../../util/SoundManager';

import { theme } from '../../../resources/theme';
import Translator from '../../../resources/localization/translator';

import { CometChatMentionUserPopup } from '../CometChatMentionUserPopup';

import {
  chatComposerStyle,
  editPreviewContainerStyle,
  previewHeadingStyle,
  previewCloseStyle,
  previewTextStyle,
  previewMessageStyle,
  composerInputStyle,
  messageInputStyle,
  inputStickyStyle,
  stickyAttachmentStyle,
  filePickerStyle,
  fileListStyle,
  fileItemStyle,
  stickyAttachButtonStyle,
  stickyButtonStyle,
  emojiButtonStyle,
  sendButtonStyle,
  stickerBtnStyle,
} from './style';

import btnAddIcon from './resources/btn-add.svg';
import btnAddOpenedIcon from './resources/btn-add-opened.svg';
import attachVideoIcon from './resources/attach-video.svg';
import attachAudioIcon from './resources/attach-audio.svg';
import attachImageIcon from './resources/attach-image.svg';
import attachDocumentIcon from './resources/attach-document.svg';
import btnStickerIcon from './resources/btn-sticker.svg';
import btnEmojiIcon from './resources/btn-emoji.svg';
import sendBlue from './resources/send-message.svg';
import pollIcon from './resources/polls.svg';
import closeIcon from './resources/close.svg';
import documentIcon from './resources/collaborative-document.svg';
import whiteboardIcon from './resources/collaborative-whiteboard.svg';
import heartIcon from './resources/heart.png';

class CometChatMessageComposer extends React.PureComponent {
  static contextType = CometChatContext;
  range;
  sel;
  chatWindow;
  constructor(props) {
    super(props);

    this.imageUploaderRef = React.createRef();
    this.fileUploaderRef = React.createRef();
    this.audioUploaderRef = React.createRef();
    this.videoUploaderRef = React.createRef();
    this.messageInputRef = React.createRef();
    this.liveReactionInProgress = false;
    this.isTyping = false;

    this.state = {
      showFilePicker: false,
      messageInput: '',
      messageType: '',
      emojiViewer: false,
      mentionUserPopup: false,
      mentionCursorPosition: 0,
      createPoll: false,
      messageToBeEdited: '',
      replyMessage: '',
      replyPreview: null,
      stickerViewer: false,
      messageToReact: '',
      shareDocument: false,
      shareWhiteboard: false,
      enableLiveReaction: false,
      enablePolls: false,
      enableTypingIndicator: false,
      enableStickers: false,
      enablePhotosVideos: false,
      enableFiles: false,
      enableEmojis: false,
      enableCollaborativeDocument: false,
      enableCollaborativeWhiteboard: false,
      showMessage: true,
    };
  }

  componentDidMount() {
    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch((error) => this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG'));
    this.chatWindow = this.context.UIKitSettings.chatWindow;
    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
      this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();

    setTimeout(() => {
      if (this.state.showMessage && this.props.message) {
        const element = this.messageInputRef.current;
        if (!this.sel && !this.range) {
          this.focusOnMessageComposer();
        } else {
          element.focus();
        }
        this.pasteHtmlAtCaret(this.props.message);
        this.setState({
          showMessage: false,
          messageInput: this.props.message,
          messageType: 'text',
        });
      }
    }, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messageToBeEdited !== this.props.messageToBeEdited) {
      const messageToBeEdited = this.props.messageToBeEdited;

      this.setState({
        messageInput: messageToBeEdited,
        messageToBeEdited: messageToBeEdited,
      });

      const element = this.messageInputRef.current;
      if (messageToBeEdited) {
        let messageText = messageToBeEdited.text;

        //xss extensions data
        const xssData = checkMessageForExtensionsData(messageToBeEdited, 'xss-filter');
        if (
          xssData &&
          xssData.hasOwnProperty('sanitized_text') &&
          xssData.hasOwnProperty('hasXSS') &&
          xssData.hasXSS === 'yes'
        ) {
          messageText = xssData.sanitized_text;
        }

        element.focus();
        element.textContent = '';
        this.pasteHtmlAtCaret(messageText);
      } else {
        element.textContent = '';
      }
    }

    if (prevProps.replyPreview !== this.props.replyPreview) {
      this.setState({ replyPreview: this.props.replyPreview });
    }

    if (prevProps.replyMessage !== this.props.replyMessage) {
      this.setState({
        messageInput: '',
        replyMessage: this.props.replyMessage,
      });
      const element = this.messageInputRef.current;
      element.focus();
    }

    const previousMessageStr = JSON.stringify(prevProps.messageToReact);
    const currentMessageStr = JSON.stringify(this.props.messageToReact);

    if (previousMessageStr !== currentMessageStr) {
      this.setState({ messageToReact: this.props.messageToReact });
    }

    if (this.context.item !== this.item) {
      this.messageInputRef.current.textContent = '';
      this.setState({
        stickerViewer: false,
        emojiViewer: false,
        replyPreview: null,
        messageToBeEdited: '',
        replyMessage: '',
        messageInput: '',
      });

      this.focusOnMessageComposer();
    }

    if (prevState.messageInput !== this.state.messageInput) {
      this.focusOnMessageComposer();
    }

    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
      this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();
  }

  /**
   * if live reactions feature is disabled
   */
  enableLiveReaction = () => {
    this.context.FeatureRestriction.isLiveReactionsEnabled()
      .then((response) => {
        if (response !== this.state.enableLiveReaction) {
          this.setState({ enableLiveReaction: response });
        }
      })
      .catch((error) => {
        if (this.state.enableLiveReaction !== false) {
          this.setState({ enableLiveReaction: false });
        }
      });
  };

  /**
   * if polls feature is disabled
   */
  enablePolls = () => {
    this.context.FeatureRestriction.isPollsEnabled()
      .then((response) => {
        if (response !== this.state.enablePolls) {
          this.setState({ enablePolls: response });
        }
      })
      .catch((error) => {
        if (this.state.enablePolls !== false) {
          this.setState({ enablePolls: false });
        }
      });
  };

  /**
   * if typing indicator feature is disabled
   */
  enableTypingIndicator = () => {
    this.context.FeatureRestriction.isTypingIndicatorsEnabled()
      .then((response) => {
        if (response !== this.state.enableTypingIndicator) {
          this.setState({ enableTypingIndicator: response });
        }
      })
      .catch((error) => {
        if (this.state.enableTypingIndicator !== false) {
          this.setState({ enableTypingIndicator: false });
        }
      });
  };

  /**
   * if stickers feature is disabled
   */
  enableStickers = () => {
    this.context.FeatureRestriction.isStickersEnabled()
      .then((response) => {
        if (response !== this.state.enableStickers) {
          this.setState({ enableStickers: response });
        }
      })
      .catch((error) => {
        if (this.state.enableStickers !== false) {
          this.setState({ enableStickers: false });
        }
      });
  };

  /**
   * if uploding photos, videos feature is disabled
   */
  enablePhotosVideos = () => {
    this.context.FeatureRestriction.isPhotosVideosEnabled()
      .then((response) => {
        if (response !== this.state.enablePhotosVideos) {
          this.setState({ enablePhotosVideos: response });
        }
      })
      .catch((error) => {
        if (this.state.enablePhotosVideos !== false) {
          this.setState({ enablePhotosVideos: false });
        }
      });
  };

  /**
   * if uploding files feature is disabled
   */
  enableFiles = () => {
    this.context.FeatureRestriction.isFilesEnabled()
      .then((response) => {
        if (response !== this.state.enableFiles) {
          this.setState({ enableFiles: response });
        }
      })
      .catch((error) => {
        if (this.state.enableFiles !== false) {
          this.setState({ enableFiles: false });
        }
      });
  };

  /**
   * if sending emojis feature is disabled
   */
  enableEmojis = () => {
    this.context.FeatureRestriction.isEmojisEnabled()
      .then((response) => {
        if (response !== this.state.enableEmojis) {
          this.setState({ enableEmojis: response });
        }
      })
      .catch((error) => {
        if (this.state.enableEmojis !== false) {
          this.setState({ enableEmojis: false });
        }
      });
  };

  /**
   * if sharing collborative document feature is disabled
   */
  enableCollaborativeDocument = () => {
    this.context.FeatureRestriction.isCollaborativeDocumentEnabled()
      .then((response) => {
        if (response !== this.state.enableCollaborativeDocument) {
          this.setState({ enableCollaborativeDocument: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCollaborativeDocument !== false) {
          this.setState({ enableCollaborativeDocument: false });
        }
      });
  };

  /**
   * if sharing collborative whiteboard feature is disabled
   */
  enableCollaborativeWhiteboard = () => {
    this.context.FeatureRestriction.isCollaborativeWhiteBoardEnabled()
      .then((response) => {
        if (response !== this.state.enableCollaborativeWhiteboard) {
          this.setState({ enableCollaborativeWhiteboard: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCollaborativeWhiteboard !== false) {
          this.setState({ enableCollaborativeWhiteboard: false });
        }
      });
  };

  focusOnMessageComposer = () => {
    if (this.messageInputRef && this.messageInputRef.current) {
      this.messageInputRef.current.focus();
      this.updateSelection();
    }
  };

  /**
   * Updates caret selection object
   */
  updateSelection() {
    try {
      if (this.chatWindow.getSelection) {
        this.sel = this.chatWindow.getSelection();
        if (this.sel.getRangeAt && this.sel.rangeCount) {
          this.range = this.sel.getRangeAt(0);
        }
      }
    } catch (error) {
      console.log('Error updating selection', error);
    }
  }

  /**
   * Pastes given html at caret position
   */
  pasteHtmlAtCaret(html) {
    try {
      if (this.sel && this.range) {
        this.range.deleteContents();
        let el = document.createElement('div');
        el.innerHTML = html;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        this.range.insertNode(frag);

        if (lastNode) {
          this.range = this.range.cloneRange();
          this.range.setStartAfter(lastNode);
          this.range.collapse(true);
          this.sel.removeAllRanges();
          this.sel.addRange(this.range);
        }
      } else if (document.selection && document.selection.type != 'Control') {
        document.selection.createRange().pasteHTML(html);
      }
    } catch (error) {
      console.log('Error pasting html to caret', error);
    }
  }

  emojiClicked = (emoji) => {
    if (this.state.messageToReact) {
      this.reactToMessages(emoji);
      return;
    }
    const element = this.messageInputRef.current;
    if (!this.sel && !this.range) {
      //  focus when we open emoji keyboard for the first time
      this.focusOnMessageComposer();
    } else {
      element.focus();
    }
    this.pasteHtmlAtCaret(emoji.char);
    console.log(element.innerText);
    this.setState({ messageInput: element.innerText, messageType: 'text' });
  };

  changeHandler = (event) => {
    this.startTyping();

    const elem = event.currentTarget;
    let messageInput = elem.textContent.trim();

    if (!messageInput.length) {
      event.currentTarget.textContent = messageInput;
      //return false;
    }

    this.setState({ messageInput: elem.innerText, messageType: 'text' });
  };

  toggleFilePicker = () => {
    const currentState = !this.state.showFilePicker;
    this.setState({ showFilePicker: currentState });
  };

  openFileDialogue = (fileType) => {
    switch (fileType) {
      case 'image':
        this.imageUploaderRef.current.click();
        break;
      case 'file':
        this.fileUploaderRef.current.click();
        break;
      case 'audio':
        this.audioUploaderRef.current.click();
        break;
      case 'video':
        this.videoUploaderRef.current.click();
        break;
      default:
        break;
    }
  };

  onImageChange = (e) => {
    if (!this.imageUploaderRef.current.files['0']) {
      return false;
    }

    const uploadedFile = this.imageUploaderRef.current.files['0'];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      'load',
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.IMAGE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onFileChange = (e) => {
    if (!this.fileUploaderRef.current.files['0']) {
      return false;
    }

    const uploadedFile = this.fileUploaderRef.current.files['0'];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      'load',
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.FILE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onAudioChange = (e) => {
    if (!this.audioUploaderRef.current.files['0']) {
      return false;
    }

    const uploadedFile = this.audioUploaderRef.current.files['0'];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      'load',
      () => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.AUDIO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onVideoChange = (e) => {
    if (!this.videoUploaderRef.current.files['0']) {
      return false;
    }

    const uploadedFile = this.videoUploaderRef.current.files['0'];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      'load',
      () => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.VIDEO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
      receiverId = this.context.item.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      receiverId = this.context.item.guid;
      receiverType = CometChat.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  sendMediaMessage = (messageInput, messageType) => {
    this.toggleFilePicker();
    this.endTyping(null, null);

    const { receiverId, receiverType } = this.getReceiverDetails();

    let mediaMessage = new CometChat.MediaMessage(
      receiverId,
      messageInput,
      messageType,
      receiverType
    );
    if (this.props.parentMessageId) {
      mediaMessage.setParentMessageId(this.props.parentMessageId);
    }

    mediaMessage.setSender(this.loggedInUser);
    mediaMessage.setReceiver(this.context.type);
    mediaMessage.setType(messageType);
    mediaMessage.setMetadata({
      [enums.CONSTANTS['FILE_METADATA']]: messageInput,
    });
    mediaMessage._composedAt = getUnixTimestamp();
    mediaMessage._id = ID();

    SoundManager.play(enums.CONSTANTS.AUDIO['OUTGOING_MESSAGE'], this.context);
    this.props.actionGenerated(enums.ACTIONS['MESSAGE_COMPOSED'], [mediaMessage]);

    CometChat.sendMessage(mediaMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: mediaMessage._id };
        this.props.actionGenerated(enums.ACTIONS['MESSAGE_SENT'], [newMessageObj]);
      })
      .catch((error) => {
        const newMessageObj = { ...mediaMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS['ERROR_IN_SENDING_MESSAGE'], [newMessageObj]);
      });
  };

  sendMessageOnEnter = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.sendTextMessage();
      return true;
    } else if (event.key === '@' && this?.context?.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      const sel = window.getSelection();
      this.setState({
        mentionUserPopup: true,
        mentionCursorPosition: sel.focusOffset,
      });
      this.messageInputRef.current.blur();
    }
  };

  sendTextMessage = () => {
    if (this.state.emojiViewer) {
      this.setState({ emojiViewer: false });
    }

    if (!this.state.messageInput.trim().length) {
      return false;
    }

    if (this.state.messageToBeEdited) {
      this.editMessage();
      return false;
    }

    this.endTyping(null, null);

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageInput = this.state.messageInput.trim();

    if (this.state.replyMessage) {
      let replyMessage = '';
      replyMessage = enums.CUSTOM_MESSAGE_KEYS.REPLY_PARENT_MESSAGE_NAME_BEGIN;
      replyMessage += this.state.replyMessage.sender.name;
      replyMessage += enums.CUSTOM_MESSAGE_KEYS.REPLY_PARENT_MESSAGE_NAME_END;
      replyMessage += enums.CUSTOM_MESSAGE_KEYS.REPLY_PARENT_MESSAGE_TEXT_BEGIN;
      replyMessage += this.state.replyMessage.text;
      replyMessage += enums.CUSTOM_MESSAGE_KEYS.REPLY_PARENT_MESSAGE_TEXT_END;
      replyMessage += enums.CUSTOM_MESSAGE_KEYS.REPLY_MESSAGE_TEXT_BEGIN;
      replyMessage += messageInput;
      replyMessage += enums.CUSTOM_MESSAGE_KEYS.REPLY_MESSAGE_TEXT_END;
      messageInput = replyMessage;
    }

    let textMessage = new CometChat.TextMessage(receiverId, messageInput, receiverType);
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage.setText(messageInput);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS['MESSAGE_COMPOSED'], [textMessage]);
    this.setState({ messageInput: '', replyPreview: false });

    this.messageInputRef.current.textContent = '';
    SoundManager.play(enums.CONSTANTS.AUDIO['OUTGOING_MESSAGE'], this.context);

    if (this.state.replyMessage) {
      this.closeReplyMessage();
    }

    CometChat.sendMessage(textMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS['MESSAGE_SENT'], [newMessageObj]);
      })
      .catch((error) => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS['ERROR_IN_SENDING_MESSAGE'], [newMessageObj]);

        if (error && error.hasOwnProperty('code') && error.code === 'ERR_GUID_NOT_FOUND') {
          //this.context.setDeletedGroupId(this.context.item.guid);
        }
      });
  };

  editMessage = () => {
    this.endTyping(null, null);

    const messageToBeEdited = this.props.messageToBeEdited;

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageText = this.state.messageInput.trim();
    let textMessage = new CometChat.TextMessage(receiverId, messageText, receiverType);
    textMessage.setId(messageToBeEdited.id);

    const newMessage = Object.assign({}, textMessage, {
      messageFrom: messageToBeEdited.messageFrom,
    });
    this.props.actionGenerated(enums.ACTIONS['MESSAGE_EDITED'], newMessage);

    this.setState({ messageInput: '' });
    this.messageInputRef.current.textContent = '';
    SoundManager.play(enums.CONSTANTS.AUDIO['OUTGOING_MESSAGE'], this.context);

    this.closeEditPreview();

    CometChat.editMessage(textMessage)
      .then((message) => {
        this.props.actionGenerated(enums.ACTIONS['MESSAGE_EDITED'], {
          ...message,
        });
      })
      .catch((error) => this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG'));
  };

  closeEditPreview = () => {
    this.props.actionGenerated(enums.ACTIONS['CLEAR_EDIT_PREVIEW']);
  };

  closeReplyMessage = () => {
    this.props.actionGenerated(enums.ACTIONS['CLEAR_REPLY_MESSAGE']);
  };

  startTyping = (timer, metadata) => {
    let typingInterval = timer || 5000;

    //if typing indicator feature is disabled
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    if (this.isTyping) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();
    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.startTyping(typingNotification);

    this.isTyping = setTimeout(() => {
      this.endTyping(null, typingMetadata);
    }, typingInterval);
  };

  endTyping = (event, metadata) => {
    this.updateSelection(); //updating selection onblur
    //fixing synthetic issue
    if (event) {
      event.persist();
    }

    //if typing indicator is disabled for chat wigdet in dashboard
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();

    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.endTyping(typingNotification);

    clearTimeout(this.isTyping);
    this.isTyping = null;
  };

  toggleStickerPicker = () => {
    const stickerViewer = this.state.stickerViewer;
    this.setState({ stickerViewer: !stickerViewer, emojiViewer: false });
  };

  toggleEmojiPicker = () => {
    const emojiViewer = this.state.emojiViewer;
    this.setState({ emojiViewer: !emojiViewer, stickerViewer: false });
  };

  toggleCreatePoll = () => {
    const createPoll = this.state.createPoll;
    this.setState({ createPoll: !createPoll });
  };

  toggleCollaborativeDocument = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension('document', 'POST', 'v1/create', {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with document url
        if (response && response.hasOwnProperty('document_url')) {
          this.context.setToastMessage('success', 'DOCUMENT_SUCCESS');
        } else {
          this.context.setToastMessage('error', 'DOCUMENT_FAIL');
        }
      })
      .catch((error) => this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG'));
  };

  toggleCollaborativeBoard = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension('whiteboard', 'POST', 'v1/create', {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with board_url
        if (response && response.hasOwnProperty('board_url')) {
          this.context.setToastMessage('success', 'WHITEBOARD_SUCCESS');
        } else {
          this.context.setToastMessage('error', 'WHITEBOARD_FAIL');
        }
      })
      .catch((error) => this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG'));
  };

  closeCreatePoll = () => {
    this.toggleCreatePoll();
    this.toggleFilePicker();
  };

  actionHandler = (action, message) => {
    switch (action) {
      case enums.ACTIONS['POLL_CREATED']:
        this.toggleCreatePoll();
        this.toggleFilePicker();
        break;
      case enums.ACTIONS['SEND_STICKER']:
        this.sendSticker(message);
        break;
      case enums.ACTIONS['CLOSE_STICKER_KEYBOARD']:
        this.toggleStickerPicker();
        break;
      default:
        break;
    }
  };

  sendSticker = (stickerMessage) => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    const customData = {
      sticker_url: stickerMessage.stickerUrl,
      sticker_name: stickerMessage.stickerName,
    };
    const customType = enums.CUSTOM_TYPE_STICKER;

    const customMessage = new CometChat.CustomMessage(
      receiverId,
      receiverType,
      customType,
      customData
    );
    if (this.props.parentMessageId) {
      customMessage.setParentMessageId(this.props.parentMessageId);
    }
    customMessage.setSender(this.loggedInUser);
    customMessage.setReceiver(this.context.type);
    customMessage.setMetadata({ incrementUnreadCount: true });
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS['MESSAGE_COMPOSED'], [customMessage]);
    SoundManager.play(enums.CONSTANTS.AUDIO['OUTGOING_MESSAGE'], this.context);

    CometChat.sendCustomMessage(customMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: customMessage._id };
        this.props.actionGenerated(enums.ACTIONS['MESSAGE_SENT'], [newMessageObj]);
      })
      .catch((error) => {
        const newMessageObj = { ...customMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS['ERROR_IN_SENDING_MESSAGE'], [newMessageObj]);
      });
  };

  sendReplyMessage = (messageInput) => {
    let { receiverId, receiverType } = this.getReceiverDetails();

    let textMessage = new CometChat.TextMessage(receiverId, messageInput, receiverType);
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS['MESSAGE_COMPOSED'], [textMessage]);

    SoundManager.play(enums.CONSTANTS.AUDIO['OUTGOING_MESSAGE'], this.context);
    this.setState({ replyPreview: null });

    CometChat.sendMessage(textMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS['MESSAGE_SENT'], [newMessageObj]);
      })
      .catch((error) => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS['ERROR_IN_SENDING_MESSAGE'], [newMessageObj]);
      });
  };

  clearReplyPreview = () => {
    this.setState({ replyPreview: null });
  };

  startLiveReaction = (event) => {
    //if a live reaction is already in progress, return
    if (this.animationInProgress === true) {
      return false;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    //fetch the interval from the constants
    const liveReactionInterval = enums.CONSTANTS['LIVE_REACTION_INTERVAL'];

    //mount the live reaction component
    this.props.actionGenerated(enums.ACTIONS['SEND_LIVE_REACTION']);
    this.sendTransientMessage();

    //set the timer to stop the live reaction
    this.timeout = setTimeout(this.stopLiveReaction, liveReactionInterval);
  };

  stopLiveReaction = () => {
    //unmount the live reaction component
    this.props.actionGenerated(enums.ACTIONS['STOP_LIVE_REACTION']);

    //set the animation flag to false
    this.animationInProgress = false;
  };

  sendTransientMessage = () => {
    //fetching the metadata type from constants
    const metadata = {
      type: enums.CONSTANTS['METADATA_TYPE_LIVEREACTION'],
      reaction: this.props.reaction,
    };

    const receiverType =
      this?.context?.type === CometChat.ACTION_TYPE.TYPE_USER
        ? CometChat.ACTION_TYPE.TYPE_USER
        : CometChat.ACTION_TYPE.TYPE_GROUP;
    const receiverId =
      this?.context?.type === CometChat.ACTION_TYPE.TYPE_USER
        ? this?.context?.item?.uid
        : this?.context?.item?.guid;

    let transientMessage = new CometChat.TransientMessage(receiverId, receiverType, metadata);
    CometChat.sendTransientMessage(transientMessage);
  };

  reactToMessages = (emoji) => {
    //close the emoji keyboard
    this.toggleEmojiPicker();

    //message object data structure
    let messageObject = { ...this.state.messageToReact };
    let newMessageObject = {};
    let reactionObject = {};

    const userObject = {};
    if (this.loggedInUser.avatar && this.loggedInUser.avatar.length) {
      userObject['name'] = this.loggedInUser.name;
      userObject['avatar'] = this.loggedInUser.avatar;
    } else {
      userObject['name'] = this.loggedInUser.name;
    }

    const emojiObject = {
      [emoji.char]: { [this.loggedInUser.uid]: userObject },
    };

    const reactionExtensionsData = checkMessageForExtensionsData(messageObject, 'reactions');
    //if the message object has reactions extension data in metadata
    if (reactionExtensionsData) {
      //if the reactions metadata has the selected emoji/reaction
      if (reactionExtensionsData[emoji.char]) {
        //if the reactions metadata has the selected emoji/reaction for the loggedin user
        if (reactionExtensionsData[emoji.char][this.loggedInUser.uid]) {
          reactionObject = {
            ...messageObject['metadata']['@injected']['extensions']['reactions'],
          };
          delete reactionObject[emoji.char][this.loggedInUser.uid];
        } else {
          reactionObject = {
            ...messageObject['metadata']['@injected']['extensions']['reactions'],
            [emoji.char]: {
              ...messageObject['metadata']['@injected']['extensions']['reactions'][emoji.char],
              [this.loggedInUser.uid]: userObject,
            },
          };
        }
      } else {
        reactionObject = {
          ...messageObject['metadata']['@injected']['extensions']['reactions'],
          ...emojiObject,
        };
      }
    } else {
      if (messageObject.hasOwnProperty('metadata') === false) {
        messageObject['metadata'] = {};
      }

      if (messageObject['metadata'].hasOwnProperty('@injected') === false) {
        messageObject['metadata']['@injected'] = {};
      }

      if (messageObject['metadata']['@injected'].hasOwnProperty('extensions') === false) {
        messageObject['metadata']['@injected']['extensions'] = {};
      }

      if (
        messageObject['metadata']['@injected']['extensions'].hasOwnProperty('reactions') === false
      ) {
        messageObject['metadata']['@injected']['extensions']['reactions'] = {};
      }

      reactionObject = {
        ...emojiObject,
      };
    }

    const metadatObject = {
      metadata: {
        ...messageObject['metadata'],
        '@injected': {
          ...messageObject['metadata']['@injected'],
          extensions: {
            ...messageObject['metadata']['@injected']['extensions'],
            reactions: {
              ...reactionObject,
            },
          },
        },
      },
    };

    newMessageObject = {
      ...messageObject,
      data: {
        ...messageObject,
        ...metadatObject,
      },
      ...metadatObject,
    };

    this.props.actionGenerated(enums.ACTIONS['MESSAGE_EDITED'], newMessageObject);

    CometChat.callExtension('reactions', 'POST', 'v1/react', {
      msgId: this.state.messageToReact.id,
      emoji: emoji.char,
    })
      .then((response) => {
        // Reaction failed
        if (!response || !response.success || response.success !== true) {
          this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG');
        }
      })
      .catch((error) => this.props.actionGenerated(enums.ACTIONS['ERROR'], [], 'SOMETHING_WRONG'));
  };

  mentionUser = (user) => {
    const mentionedUser = ` @{${user.name}} `;
    let mentionedUserMessage = '';
    mentionedUserMessage += this.state.messageInput.substring(0, this.state.mentionCursorPosition);
    mentionedUserMessage += mentionedUser;
    mentionedUserMessage += this.state.messageInput.substring(this.state.mentionCursorPosition);

    const element = this.messageInputRef.current;
    element.textContent = mentionedUserMessage;

    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(element);
    range.setStart(element.childNodes[0], this.state.mentionCursorPosition + mentionedUser.length);
    range.setEnd(element.childNodes[0], this.state.mentionCursorPosition + mentionedUser.length);
    range.collapse(false);
    selection.addRange(range);
    element.focus();

    this.setState({
      messageInput: mentionedUserMessage,
      mentionUserPopup: false,
    });
  };

  render() {
    let liveReactionBtn = null;
    if (enums.CONSTANTS['LIVE_REACTIONS'].hasOwnProperty(this.props.reaction)) {
      const reactionName = this.props.reaction;
      liveReactionBtn = (
        <div
          title={Translator.translate('LIVE_REACTION', this.context.language)}
          className='button__reactions'
          onClick={this.startLiveReaction}
        >
          <img src={heartIcon} alt={reactionName} />
        </div>
      );
    }

    let disabledState = false;
    if (this.context.item.blockedByMe) {
      disabledState = true;
    }

    let docs = (
      <div
        title={Translator.translate('ATTACH_FILE', this.context.language)}
        css={fileItemStyle(attachDocumentIcon, this.context)}
        className='filelist__item item__file'
        onClick={() => this.openFileDialogue('file')}
      >
        <i />
        <input onChange={this.onFileChange} type='file' id='file' ref={this.fileUploaderRef} />
      </div>
    );

    let avp = (
      <React.Fragment>
        <div
          title={Translator.translate('ATTACH_VIDEO', this.context.language)}
          css={fileItemStyle(attachVideoIcon, this.context)}
          className='filelist__item item__video'
          onClick={() => this.openFileDialogue('video')}
        >
          <i />
          <input
            onChange={this.onVideoChange}
            accept='video/*'
            type='file'
            ref={this.videoUploaderRef}
          />
        </div>
        <div
          title={Translator.translate('ATTACH_AUDIO', this.context.language)}
          css={fileItemStyle(attachAudioIcon, this.context)}
          className='filelist__item item__audio'
          onClick={() => this.openFileDialogue('audio')}
        >
          <i />
          <input
            onChange={this.onAudioChange}
            accept='audio/*'
            type='file'
            ref={this.audioUploaderRef}
          />
        </div>
        <div
          title={Translator.translate('ATTACH_IMAGE', this.context.language)}
          css={fileItemStyle(attachImageIcon, this.context)}
          className='filelist__item item__image'
          onClick={() => this.openFileDialogue('image')}
        >
          <i />
          <input
            onChange={this.onImageChange}
            accept='image/*'
            type='file'
            ref={this.imageUploaderRef}
          />
        </div>
      </React.Fragment>
    );

    const pollText = Translator.translate('CREATE_POLL', this.context.language);
    let createPollBtn = (
      <div
        title={pollText}
        css={fileItemStyle(pollIcon, this.context)}
        className='filelist__item item__poll'
        onClick={this.toggleCreatePoll}
      >
        <i />
      </div>
    );

    const collaborativeDocText = Translator.translate(
      'COLLABORATE_USING_DOCUMENT',
      this.context.language
    );
    let collaborativeDocBtn = (
      <div
        title={collaborativeDocText}
        css={fileItemStyle(documentIcon, this.context)}
        className='filelist__item item__document'
        onClick={this.toggleCollaborativeDocument}
      >
        <i />
      </div>
    );

    const collaborativeBoardText = Translator.translate(
      'COLLABORATE_USING_WHITEBOARD',
      this.context.language
    );
    let collaborativeBoardBtn = (
      <div
        title={collaborativeBoardText}
        css={fileItemStyle(whiteboardIcon, this.context)}
        className='filelist__item item__whiteboard'
        onClick={this.toggleCollaborativeBoard}
      >
        <i />
      </div>
    );

    let emojiBtn = (
      <div
        title={Translator.translate('EMOJI', this.context.language)}
        css={emojiButtonStyle(btnEmojiIcon, this.context)}
        className='button__emoji'
        onClick={() => {
          this.toggleEmojiPicker();
          this.setState({ messageToReact: '' });
        }}
      >
        <i />
      </div>
    );

    let stickerBtn = (
      <div
        title={Translator.translate('STICKER', this.context.language)}
        css={stickerBtnStyle(btnStickerIcon, this.context)}
        className='button__sticker'
        onClick={this.toggleStickerPicker}
      >
        <i />
      </div>
    );
    stickerBtn = null; // Should be uncommented and reworked (add more stickers) after MVP

    let sendBtn = (
      <div
        title={Translator.translate('SEND_MESSAGE', this.context.language)}
        css={sendButtonStyle(sendBlue, this.context)}
        className='button__send'
        onClick={this.sendTextMessage}
      >
        <i />
      </div>
    );

    //if uploading photos, videos feature is disabled
    if (this.state.enablePhotosVideos === false) {
      avp = null;
    }

    //if files upload are disabled for chat widget in dashboard
    if (this.state.enableFiles === false) {
      docs = null;
    }

    //if polls feature is disabled
    if (this.state.enablePolls === false || this.props.parentMessageId) {
      createPollBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (this.state.enableCollaborativeDocument === false || this.props.parentMessageId) {
      collaborativeDocBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (this.state.enableCollaborativeWhiteboard === false || this.props.parentMessageId) {
      collaborativeBoardBtn = null;
    }

    //if emojis are disabled for chat widget in dashboard
    if (this.state.enableEmojis === false) {
      emojiBtn = null;
    }

    //if live reactions is disabled for chat widget in dashboard
    if (
      this.state.enableLiveReaction === false ||
      this.state.messageInput.length ||
      this.props.parentMessageId
    ) {
      liveReactionBtn = null;
    }

    //if stickers is disabled for chat widget in dashboard
    if (this.state.enableStickers === false) {
      stickerBtn = null;
    }

    if (!this.state.messageInput.length) {
      sendBtn = null;
    }

    const attachIcon = this.state.showFilePicker ? btnAddOpenedIcon : btnAddIcon;
    let attach = (
      <div css={stickyAttachmentStyle()} className='input__sticky__attachment'>
        <div
          css={stickyAttachButtonStyle(attachIcon, this.context)}
          className='attachment__icon'
          onClick={this.toggleFilePicker}
          title={Translator.translate('ATTACH', this.context.language)}
        >
          <i />
        </div>
        <div
          css={filePickerStyle(this.state)}
          className='attachment__filepicker'
          dir={Translator.getDirection(this.context.language)}
        >
          <div css={fileListStyle()} className='filepicker__filelist'>
            {avp}
            {docs}
            {createPollBtn}
            {collaborativeDocBtn}
            {collaborativeBoardBtn}
          </div>
        </div>
      </div>
    );

    if (
      avp === null &&
      docs === null &&
      createPollBtn === null &&
      collaborativeDocBtn === null &&
      collaborativeBoardBtn === null
    ) {
      attach = null;
    }

    let createPoll = null;
    if (this.state.createPoll) {
      createPoll = (
        <CometChatCreatePoll close={this.closeCreatePoll} actionGenerated={this.actionHandler} />
      );
    }

    let editPreview = null;
    if (this.state.messageToBeEdited) {
      let messageText = this.state.messageToBeEdited.text;

      //xss extensions data
      const xssData = checkMessageForExtensionsData(this.state.messageToBeEdited, 'xss-filter');
      if (
        xssData &&
        xssData.hasOwnProperty('sanitized_text') &&
        xssData.hasOwnProperty('hasXSS') &&
        xssData.hasXSS === 'yes'
      ) {
        messageText = xssData.sanitized_text;
      }

      //datamasking extensions data
      const maskedData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        'data-masking'
      );
      if (
        maskedData &&
        maskedData.hasOwnProperty('data') &&
        maskedData.data.hasOwnProperty('sensitive_data') &&
        maskedData.data.hasOwnProperty('message_masked') &&
        maskedData.data.sensitive_data === 'yes'
      ) {
        messageText = maskedData.data.message_masked;
      }

      //profanity extensions data
      const profaneData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        'profanity-filter'
      );
      if (
        profaneData &&
        profaneData.hasOwnProperty('profanity') &&
        profaneData.hasOwnProperty('message_clean') &&
        profaneData.profanity === 'yes'
      ) {
        messageText = profaneData.message_clean;
      }

      editPreview = (
        <div css={editPreviewContainerStyle(this.context, keyframes)}>
          <div css={previewHeadingStyle()}>
            <div css={previewTextStyle(this.context)}>
              {Translator.translate('EDIT_MESSAGE', this.context.language)}
            </div>
            <span
              css={previewCloseStyle(closeIcon, this.context)}
              onClick={this.closeEditPreview}
            ></span>
          </div>
          <div css={previewMessageStyle(this.context)}>{messageText}</div>
        </div>
      );
    }

    let replyMessagePreview = null;
    if (this.state.replyMessage) {
      let messageText = this.state.replyMessage.text;

      replyMessagePreview = (
        <div css={editPreviewContainerStyle(this.context, keyframes)}>
          <div css={previewHeadingStyle()}>
            <div css={previewTextStyle(this.context)}>
              {Translator.translate('MESSAGE_REPLY', this.context.language)}
            </div>
            <span
              css={previewCloseStyle(closeIcon, this.context)}
              onClick={this.closeReplyMessage}
            ></span>
          </div>
          <div css={previewMessageStyle(this.context)}>{messageText}</div>
        </div>
      );
    }

    let smartReplyPreview = null;
    if (this.state.replyPreview) {
      const message = this.state.replyPreview;

      const smartReplyData = checkMessageForExtensionsData(message, 'smart-reply');
      if (smartReplyData && smartReplyData.hasOwnProperty('error') === false) {
        const options = [
          smartReplyData['reply_positive'],
          smartReplyData['reply_neutral'],
          smartReplyData['reply_negative'],
        ];
        smartReplyPreview = (
          <CometChatSmartReplyPreview
            options={options}
            clicked={this.sendReplyMessage}
            close={this.clearReplyPreview}
          />
        );
      }
    }

    let stickerViewer = null;
    if (this.state.stickerViewer) {
      stickerViewer = <CometChatStickerKeyboard actionGenerated={this.actionHandler} />;
    }

    let emojiViewer = null;
    if (this.state.emojiViewer) {
      emojiViewer = <CometChatEmojiKeyboard onClick={this.emojiClicked} />;
    }

    let mentionUserPopup = null;
    if (this.state.mentionUserPopup && this?.context?.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      mentionUserPopup = (
        <CometChatMentionUserPopup
          guid={this?.context?.item?.guid}
          close={() => this.setState({ mentionUserPopup: false })}
          selectUser={this.mentionUser}
        />
      );
    }

    return (
      <div css={chatComposerStyle()} className='chat__composer'>
        {editPreview}
        {replyMessagePreview}
        {smartReplyPreview}
        {stickerViewer}
        {emojiViewer}
        {mentionUserPopup}
        <div
          css={composerInputStyle(this.state, this.context)}
          className='composer__input'
          tabIndex='-1'
        >
          <div
            css={messageInputStyle(disabledState, this.state, this.context)}
            className='input__message-input'
            contentEditable='true'
            placeholder={Translator.translate('ENTER_YOUR_MESSAGE_HERE', this.context.language)}
            dir={Translator.getDirection(this.context.language)}
            onInput={this.changeHandler}
            onBlur={(event) => this.endTyping(event)}
            onKeyDown={this.sendMessageOnEnter}
            ref={this.messageInputRef}
          />
          <div css={inputStickyStyle(disabledState, this.context)} className='input__sticky'>
            {attach}
            <div css={stickyButtonStyle(this.state)} className='input__sticky__buttons'>
              {stickerBtn}
              {emojiBtn}
              {sendBtn}
              {liveReactionBtn}
            </div>
          </div>
        </div>
        {createPoll}
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatMessageComposer.defaultProps = {
  theme: theme,
  reaction: 'heart',
};

CometChatMessageComposer.propTypes = {
  theme: PropTypes.object,
  reaction: PropTypes.string,
};

export { CometChatMessageComposer };
