import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  AdsFilteringType,
  RentPeriodEnum,
  UserSettingsResponseType,
  TransactionTypeEnum,
  AdFieldRenovationEnum,
  AdFieldFloorEnum,
  AdFieldFurnitureEnum,
  AdFieldOccupancyEnum,
} from '@/types';

import { classifyPrice } from '@/utils';

const activityKindValues = [
  { label: 'Sale', value: ActivityKindEnum.Sale },
  { label: 'Rent', value: ActivityKindEnum.Rent },
];
const completionStatusValues = [
  { label: 'Ready', value: CompletionStatusEnum.Ready },
  { label: 'Off-Plan', value: CompletionStatusEnum.OffPlan },
];
const transactionTypeValues = [
  { label: 'Ready', value: TransactionTypeEnum.Ready },
  { label: 'Resale', value: TransactionTypeEnum.Resale },
  { label: 'Distress', value: TransactionTypeEnum.Distress },
];
const rentFrequencyValues = [
  { label: 'Yearly', value: RentPeriodEnum.Yearly },
  { label: 'Monthly', value: RentPeriodEnum.Monthly },
  { label: 'Weekly', value: RentPeriodEnum.Weekly },
  { label: 'Daily', value: RentPeriodEnum.Daily },
];
const propertyKindValues = [
  { label: 'Apartment', value: PropertyKindEnum.Apartment },
  { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
  { label: 'Villa', value: PropertyKindEnum.Villa },
  { label: 'Floor', value: PropertyKindEnum.Floor },
  { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
  { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
  { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
  { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
  { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
  { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
  { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
  { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
  { label: 'Office', value: PropertyKindEnum.Office },
  { label: 'Shop', value: PropertyKindEnum.Shop },
];
const propertyKindValuesResidentialTab = [
  { label: 'Apartment', value: PropertyKindEnum.Apartment },
  { label: 'Villa', value: PropertyKindEnum.Villa },
  { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
  { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
  { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
  { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
  { label: 'Floor', value: PropertyKindEnum.Floor },
  { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
];
const propertyKindValuesCommercialTab = [
  { label: 'Shop', value: PropertyKindEnum.Shop },
  { label: 'Office', value: PropertyKindEnum.Office },
  { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
  { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
  { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
  { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
];
const bedsValues = [
  { label: 'Studio', value: AdFieldBedsEnum.Studio },
  { label: '1', value: AdFieldBedsEnum.One },
  { label: '2', value: AdFieldBedsEnum.Two },
  { label: '3', value: AdFieldBedsEnum.Three },
  { label: '4', value: AdFieldBedsEnum.Four },
  { label: '5', value: AdFieldBedsEnum.Five },
  { label: '6', value: AdFieldBedsEnum.Six },
  { label: '7', value: AdFieldBedsEnum.Seven },
  { label: '8+', value: AdFieldBedsEnum.EightPlus },
];
const bathsValues = [
  { label: '1', value: AdFieldBathsEnum.One },
  { label: '2', value: AdFieldBathsEnum.Two },
  { label: '3', value: AdFieldBathsEnum.Three },
  { label: '4', value: AdFieldBathsEnum.Four },
  { label: '5', value: AdFieldBathsEnum.Five },
  { label: '6', value: AdFieldBathsEnum.Six },
  { label: '7', value: AdFieldBathsEnum.Seven },
  { label: '8+', value: AdFieldBathsEnum.EightPlus },
];
const furnitureValues = [
  { label: 'Any', value: 'any' },
  { label: 'Furnished', value: AdFieldFurnitureEnum.Furnished },
  { label: 'Partly furnished', value: AdFieldFurnitureEnum.PartlyFurnished },
  { label: 'Unfurnished', value: AdFieldFurnitureEnum.Unfurnished },
];
const renovationValues = [
  { label: 'Any', value: 'any' },
  { label: 'Renovated', value: AdFieldRenovationEnum.Renovation },
  { label: 'Non renovated', value: AdFieldRenovationEnum.NonRenovation },
];
const floorValues = [
  { label: 'Any', value: 'any' },
  { label: 'Low', value: AdFieldFloorEnum.Low },
  { label: 'Middle', value: AdFieldFloorEnum.Middle },
  { label: 'High', value: AdFieldFloorEnum.High },
];
const availabilityValues = [
  { label: 'Vacant', value: AdFieldOccupancyEnum.Vacant },
  { label: 'Rented', value: AdFieldOccupancyEnum.Tenanted },
];
const postHandoverValues = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const handoverQuartersValues = [
  { label: 'Q1', value: 'Q1' },
  { label: 'Q2', value: 'Q2' },
  { label: 'Q3', value: 'Q3' },
  { label: 'Q4', value: 'Q4' },
];

const years = Array.from({ length: 24 }, (_, i) =>
  (Number(new Date().getFullYear()) - 14 + i).toString()
);
const yearObjects = years.map(year => ({ value: year, label: year }));
const handoverYearsValues = [...yearObjects ];
const handover = [{
  quarters: handoverQuartersValues,
  years: handoverYearsValues
}]

const emptyFilteringObj: AdsFilteringType = {
  address: [],
  activityKind: undefined,
  propertyKind: undefined,
  transactionType: undefined,
  areaMin: undefined,
  areaMax: undefined,
  locationId: undefined,
  beds: undefined,
  baths: undefined,
  priceMin: undefined,
  priceMax: undefined,
  rentFrequency: undefined,
  completionStatus: undefined,
  furniture: undefined,
  renovation: undefined,
  floor: undefined,
  floorsNumFrom: undefined,
  floorsNumTo: undefined,
  handover: undefined,
  postHandover: undefined,
  'handover.quarter': undefined,
  'handover.year': undefined,
  downPayment: undefined,
  creator: undefined,
  'creator.name': undefined,
  'creator.kind': undefined,
  'creator.id': undefined,
  // 'creator.myAgency': undefined,
  keywords: undefined,
  occupancy: undefined,
  isMyAgency: undefined,
  myFriends: undefined,
};

const predefinedVars = {
  activityKindValues,
  completionStatusValues,
  rentFrequencyValues,
  propertyKindValues,
  bedsValues,
  bathsValues,
  emptyFilteringObj,
  transactionTypeValues,
  furnitureValues,
  renovationValues,
  floorValues,
  availabilityValues,
  postHandoverValues,
  propertyKindValuesCommercialTab,
  propertyKindValuesResidentialTab,
  handoverQuartersValues,
  handoverYearsValues,
  handover,
};

export const getLabelsAndTexts = (
  filteringObj: AdsFilteringType,
  userSettings: UserSettingsResponseType | null
) => {
  const { beds, baths, areaMin, areaMax, priceMin = 0, priceMax = 0 } = filteringObj;

  const formatLabel = (value: number | string, single: string, plural: string) =>
    value === 1 ? `${value} ${single}` : `${value} ${plural}`;

  const bedsLabel =
    beds === AdFieldBedsEnum.Studio
      ? 'Studio'
      : beds
        ? formatLabel(beds as AdFieldBedsEnum, 'Bed', 'Beds')
        : '';

  const bathsLabel = baths ? formatLabel(baths as AdFieldBathsEnum, 'Bath', 'Baths') : '';

  const bedsBathsText =
    bedsLabel ? `${bedsLabel}`.trim().replace(' / ', ' & ') : 'Beds';

  const areaLabel =
    areaMin || areaMax
      ? `${areaMin} - ${areaMax}`
      : `Area (${userSettings?.areaUnits.toLowerCase()})`;

  const priceLabel =
    priceMin && !priceMax
      ? `Price from ${classifyPrice(priceMin)}`
      : !priceMin && priceMax
        ? `Price to ${classifyPrice(priceMax)}`
        : priceMin && priceMax
          ? `Price ${classifyPrice(priceMin)} - ${classifyPrice(priceMax)}`
          : `Price (${userSettings?.currency})`;

  return { bedsBathsText, areaLabel, priceLabel };
};

const hasStringValue = (value: string | undefined) =>
  value && value !== 'any' && value.trim() !== '';
const hasValidNumber = (value: number | undefined) => value !== undefined && value > 0;
const hasArrayValue = (value: any[] | undefined) =>
  value &&
  value.length > 0 &&
  value.some(
    (item) => item !== undefined && item !== null && item !== 'any' && item.toString().trim() !== ''
  );

export const getMoreFiltersControlsVisibility = (filterObj: AdsFilteringType) => {
  //! morefilters show
  const hasQuarterOrYear = !!(
    (filterObj.handover?.quarter && filterObj.handover.quarter.trim() !== '') ||
    (filterObj.handover?.year && filterObj.handover.year.trim() !== '')
  );
  // const isMoreFiltersVisible =
  //   hasQuarterOrYear ||
  //   hasStringValue(filterObj.furniture) ||
  //   hasStringValue(filterObj.renovation) ||
  //   hasStringValue(filterObj.floor) ||
  //   hasValidNumber(filterObj.floorsNumFrom) ||
  //   hasValidNumber(filterObj.floorsNumTo) ||
  //   hasStringValue(filterObj.keywords) ||
  //   (filterObj?.creator?.name && filterObj?.creator.name?.length > 0) ||
  //   hasStringValue(filterObj.handover?.quarter) ||
  //   hasStringValue(filterObj.handover?.year) ||
  //   hasStringValue(filterObj.downPayment) ||
  //   filterObj.postHandover ||
  //   // hasStringValue(filterObj.propertyKind?.[0]) ||
  //   false;

  const isResetFiltersVisible =
    hasQuarterOrYear ||
    hasStringValue(filterObj.downPayment ? filterObj.downPayment.toString() : undefined) ||
    filterObj.postHandover ||
    hasStringValue(filterObj.keywords) ||
    hasStringValue(filterObj.activityKind) ||
    hasValidNumber(filterObj.areaMax) ||
    hasValidNumber(filterObj.areaMin) ||
    hasArrayValue(filterObj.baths as AdFieldBathsEnum[]) ||
    hasArrayValue(filterObj.beds as AdFieldBedsEnum[]) ||
    hasArrayValue(filterObj.furniture as AdFieldFurnitureEnum[]) ||
    hasArrayValue(filterObj.renovation as AdFieldRenovationEnum[]) ||
    hasArrayValue(filterObj.floor as AdFieldFloorEnum[]) ||
    hasValidNumber(filterObj.floorsNumFrom) ||
    hasValidNumber(filterObj.floorsNumTo) ||
    (filterObj?.creator?.name && filterObj?.creator.name?.length > 0) ||
    hasStringValue(filterObj.priceMax as string) ||
    hasStringValue(filterObj.priceMin as string) ||
    hasStringValue(filterObj.propertyKind?.[0]) ||
    false;

  return { isResetFiltersVisible };
};

export { predefinedVars };
