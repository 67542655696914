import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactPlayer from 'react-player';

import { AdMediaType, FileKindEnum } from '@/types';
import { FileUploader, SectionTitle } from '@/components';

import { Image } from '../';
import { ReactComponent as DeleteIcon } from '../UploadPhotos/PhotoItem/trash.svg';
import styles from './UploadVideo.module.scss';

const MAX_VIDEOS = 3;

interface UploadVideoProps {
  gallery: Image[];
}

export const UploadVideo = ({ gallery }: UploadVideoProps) => {
  const [videos, setVideos] = useState<Image[]>([]);
  const [countVideo, setCountVideo] = useState(gallery.length || 0);

  const { getValues, setValue } = useFormContext();

  useEffect(() => setVideos(gallery), [gallery]);

  useEffect(() => {
    setCountVideo(videos.length);
    const itemsWithoutVideo =
      getValues('propertyAdMedia')?.filter(
        (item: AdMediaType) => item.kind !== FileKindEnum.Video
      ) || [];
    const newVideos = videos.map((video, index) => ({
      fileId: video.id,
      kind: FileKindEnum.Video,
      order: index + 1,
    }));
    setValue('propertyAdMedia', [...itemsWithoutVideo, ...newVideos]);
  }, [videos]);

  const handleVideoUploaded = (id: string, url: string) => setVideos([...videos, { id, url }]);

  const handleRemoveItem = (index: number) => {
    setCountVideo(countVideo - 1);
    setVideos(videos.filter((_, position) => index !== position + 1));
  };

  return (
    <div>
      <SectionTitle number={7} title='Upload video' />
      <div className={styles.videosContainer}>
        {videos.map(({ url }, index) => (
          <div key={url + index} className={styles.container}>
            <ReactPlayer url={url} controls width='315px' height='190px' />
            <div className={styles.buttons}>
              <div className={styles.delete} onMouseDown={() => handleRemoveItem(index + 1)}>
                <DeleteIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
      <FileUploader
        type='video'
        fileTypes = {['video/mp4', 'video/quicktime']}
        labelButton='Upload video'
        labelFormat='Only MPEG4 format'
        labelSize='Maximum 300 MB'
        limit={MAX_VIDEOS - countVideo}
        onUploaded={handleVideoUploaded}
        accept={{ 'video/mp4': ['.mp4'] }}
        disabled={videos.length >= MAX_VIDEOS}
      />
    </div>
  );
};
