import { CometChat } from '@cometchat-pro/chat';

export class MessagesManager {
  receiverType = null;
  receiver = null;

  constructor(context) {
    this.context = context;
    this.receiverType = this.context.type;
    this.receiver = this.context.item?.uid || this.context.item?.guid;
  }

  fetchPinnedMessage() {
    const URL = `v1/fetch?receiverType=${this.receiverType}&receiver=${this.receiver}`;

    return CometChat.callExtension('pin-message', 'GET', URL, null);
  }

  pinMessage({ id }) {
    return CometChat.callExtension('pin-message', 'POST', 'v1/pin', {
      msgId: id,
    });
  }

  unpinMessage(message, { uid }) {
    const receiverId = message.receiver?.uid || message.receiver?.guid || message.receiver;
    const senderId = message.sender?.uid || message.sender;
    const receiver = uid === receiverId ? senderId : receiverId;

    return CometChat.callExtension('pin-message', 'DELETE', 'v1/unpin', {
      msgId: message.id,
      receiverType: message.receiverType,
      receiver,
    });
  }
}
