import { styled, shouldForwardProp } from '@mui/system';
import { Button } from '@mui/material';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) &&
    prop !== 'customWidth' &&
    prop !== 'styleType' &&
    prop !== 'colorIcons',
})<{
  customWidth?: string | number;
  styleType?: string;
  colorIcons?: string;
}>(({ theme, customWidth, styleType, colorIcons }) => ({
  background:
    styleType === 'OPchat' ? 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)' : '#c5dfff',
  color:
    styleType === 'cancel' || styleType === 'unread'
      ? '#015cd5'
      : colorIcons
        ? colorIcons
        : undefined,
  // border: styleType === 'cancel' || styleType === 'unread' ? '2px solid #015cd5' : undefined,
  fontFamily: '"Poppins", sans-serif',
  // fontSize: size === 'medium' ? '16px' : size === 'small' ? '14px' : '20px',
  width: customWidth || '64px',
  minWidth: '64px',
  height: '40px',
  padding: styleType === 'OPchat' ? '6px' : '6px 16px',
  textTransform: 'none',
  borderRadius: '12px',
  fontWeight: 500,

  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
    background:
      styleType === 'unread'
        ? 'rgba(255, 255, 255, 0)'
        : styleType === 'cancel'
          ? '#ffffff'
          : 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
    color: colorIcons ? '#ffffff' : undefined,
  },

  [theme.breakpoints.down('sm')]: {
    width: '50px',
    margin: 0,
    minWidth: '50px',
  },
}));
