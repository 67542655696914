import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as ArchiveIcon } from './archive.svg';
import { ReactComponent as UnpublishedIcon } from './unpublished.svg';
import { ReactComponent as PublishedIcon } from './published.svg';

export const icons = {
  Edit: EditIcon,
  Upload: UploadIcon,
  Archive: ArchiveIcon,
  Unpublished: UnpublishedIcon,
  Published: PublishedIcon,
};
