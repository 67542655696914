import { useState, SyntheticEvent } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Tabs, Tab, Link, Stack, Paper } from '@mui/material';
import { AdminLocationListEnum, NotificationTypeEnum, AdModel } from '@/types';
import {
  VerificationRequestModel,
  VerificationRequestFormModel,
  LocationModel,
} from '@/types/location';
import { LocationListTable } from './LocationListTable';
import { useChangeQueryParams } from '@/hooks';
import { MyButton, MyDialog, Loader } from '@/components';
import { PropertyLocation } from './PropertyLocation';
import { schemaLocationForm } from '@/schemas';
import { locationProvider } from '@/providers/locationProvider';
import { adsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { getDepthLabel } from '@/components/LocationAutocomplete/LocationAutocomplete';

import styles from './LocationList.module.scss';

export const LocationList = () => {
  const dispatch = useDispatch();
  const changeQueryParams = useChangeQueryParams(`admin/locationList/`);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationRequestData, setVerificationRequestData] =
    useState<VerificationRequestModel>();
  const [isChangedRequestData, setIsChangedRequestData] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(AdminLocationListEnum.VerificationRequests);
  const [isClarifyDialog, setIsClarifyDialog] = useState<boolean>(false);
  const [lastPages, setLastPages] = useState({
    listings: '1',
    staff: '1',
    request: '1',
  });

  const formMethods = useForm<VerificationRequestFormModel>({
    resolver: yupResolver(schemaLocationForm) as any,
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    getValues,
    watch,
  } = formMethods;

  const isVerifiedLocations = activeTab === AdminLocationListEnum.VerifiedLocations;
  const isVerificationRequests = activeTab === AdminLocationListEnum.VerificationRequests;

  const handleChangeTab = (_: SyntheticEvent, newStatus: AdminLocationListEnum) => {
    setActiveTab(newStatus);
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as AdminLocationListEnum]: page });
    return changeQueryParams({
      ...queryParams,
      page: '1',
      status: newStatus,
    });
  };

  const handleClarify = (data: VerificationRequestModel) => {
    setVerificationRequestData(data);
    setIsClarifyDialog(true);
    setValue('addressEn', data.districtName);
    setValue('locationName', data.locationName);
    setValue('districtId', data.districtId);
  };

  const onCloseClarifyDialog = () => {
    setIsClarifyDialog(false);
    clearErrors(['addressEn', 'locationName', 'coordinatesLat', 'coordinatesLong']);
    setValue('coordinatesLat', null);
    setValue('coordinatesLong', null);
    setValue('districtId', null);
  };

  const verificationRequestDelete = async (verificationId: string) => {
    const { ok, message, status } = await locationProvider.deleteVerificationRequest(
      verificationId!
    );
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
    }
  };

  const performUpdate = async (propertyAdId: string, newData: Partial<AdModel>) => {
    const { ok, status, message } = await adsProvider.updateAdById(
      propertyAdId,
      newData as AdModel
    );
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const submitHandler: SubmitHandler<VerificationRequestFormModel> = async (values) => {
    const dataRequest = {
      name: values.locationName,
      districtId: values.districtId || '',
      latitude: Number(values.coordinatesLat),
      longitude: Number(values.coordinatesLong),
    };
    setIsLoading(true);
    const { ok, data, status, message } = await locationProvider.locationClarify(dataRequest);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      setIsLoading(false);
      return;
    } else {
      const { id: verificationId, propertyAdId } =
        verificationRequestData as VerificationRequestModel;
      verificationRequestDelete(verificationId);

      const location = data as LocationModel;
      const { id: locationId } = location;
      const newDate = {
        locationId: locationId,
        nameEn: values.locationName,
        coordinatesLat: values.coordinatesLat,
        coordinatesLong: values.coordinatesLong,
        addressEn: `${getDepthLabel(location)}, ${location[location.kind]}`,
      };
      performUpdate(propertyAdId, newDate as Partial<AdModel>);
    }
    setIsLoading(false);
    onCloseClarifyDialog();
    setIsChangedRequestData(!isChangedRequestData);
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label='view tabs'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-verified-locations'
            aria-controls='tabpanel-verifiedLocations'
            label='Verified Locations'
            value={AdminLocationListEnum.VerifiedLocations}
            className={styles.tabItem}
          />
          <Tab
            id='tab-verification-requests'
            aria-controls='tabpanel-verificationRequests'
            label='Verification Requests'
            value={AdminLocationListEnum.VerificationRequests}
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      {isVerificationRequests ? (
        <Stack sx={{ margin: '40px 10px' }}>
          <LocationListTable
            handleClarify={handleClarify}
            isChangedRequestData={isChangedRequestData}
          />
        </Stack>
      ) : null}
      <MyDialog
        open={isClarifyDialog}
        onClose={onCloseClarifyDialog}
        dialogTitle='clarify location'
        width='946'
      >
        <FormProvider {...formMethods}>
          <PropertyLocation />
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Save',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={handleSubmit(submitHandler)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={onCloseClarifyDialog}
            />
          </div>
        </FormProvider>
      </MyDialog>
    </div>
  );
};
