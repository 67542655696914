import { useState, MouseEvent, useRef, RefObject, createRef } from 'react';
import { Typography, Stack, Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import { NewBuildingModel, BuildingsDataType } from '@/types';
import { Shell, StyledIconButtonBuilding } from '../styled';
import { OffPlanBuildingMenu } from './OffPlanBuildingMenu';
import { NewBuildingModal } from '../NewBuildingModal';
import moment from 'moment';

const ScrollContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const ScrollContainerWrapper = styled(Box)({
  position: 'relative',
  width: 'calc(100% - 96px)',
  height: 'fit-content',
  margin: '0 auto',
});

export const Buildings = ({
  page,
  isAdmin,
  buildings,
  isAddBuilding,
  setIsAddBuilding,
  selectedBuildingId,
  setSelectedBuildingId,
}: {
  page: NewBuildingModel;
  isAdmin: boolean;
  buildings?: BuildingsDataType[];
  isAddBuilding: boolean;
  setIsAddBuilding: (value: boolean) => void;
  selectedBuildingId: string | null;
  setSelectedBuildingId: (value: string) => void;
}) => {
  const [selectedBuildingName, setSelectedBuildingName] = useState<string | null>(null);
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [typeOfDialog, setTypeOfDialog] = useState<string>();

  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const shellRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});

  const open = Boolean(anchorEl);
  const handleClickMenu = (buildingId: string) => (event: MouseEvent<HTMLElement>) => {
    setActiveMenu(buildingId);
    setAnchorEl(event.currentTarget);
  };

  const scroll = (scrollOffset: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleCloseMenu = () => {
    setActiveMenu(null);
    setAnchorEl(null);
  };

  const handleShellClick = (buildingId: string, buildingName: string) => {
    setSelectedBuildingId(buildingId);
    setSelectedBuildingName(buildingName);
    const shellElement = shellRefs.current[buildingId];
    if (shellElement && shellElement.current) {
      shellElement.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  const allBuildings = {
    id: 'All',
    name: 'All Buildings',
    newConstructionId: page.id,
    updatedAt: '2024-02-06T19:43:25.148Z',
  };
  const updatedBuildings =
    buildings && buildings.length > 1 ? [allBuildings, ...buildings] : buildings;

  return (
    <>
      <Box
        position='relative'
        sx={{
          width: {
            xs: '100%',
            sm: '580px',
          },
          overflow: 'hidden',
        }}
      >
        <IconButton
          onClick={() => scroll(-150)}
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <ScrollContainerWrapper>
          <ScrollContainer ref={scrollRef}>
            {updatedBuildings?.map((building) => {
              if (!shellRefs.current[building.id]) {
                shellRefs.current[building.id] = createRef();
              }
              return (
                <Shell
                  key={building.id}
                  spacing={5}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  ref={shellRefs.current[building.id]}
                  isSelected={selectedBuildingId === building.id}
                  onClick={() => handleShellClick(building.id, building.name)}
                >
                  <Stack
                    key={building.id}
                    alignItems='left'
                    justifyContent='center'
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    <Typography sx={{ fontSize: '15px' }}>{building.name}</Typography>
                  </Stack>
                  {isAdmin && building?.name !== 'All Buildings' && (
                    <StyledIconButtonBuilding size='small' onClick={handleClickMenu(building.id)}>
                      <MoreVertIcon sx={{ fontSize: '16px' }} />
                    </StyledIconButtonBuilding>
                  )}
                  {building?.name !== 'All Buildings' && activeMenu === building.id && (
                    <OffPlanBuildingMenu
                      page={page}
                      open={open}
                      handleClose={handleCloseMenu}
                      anchorEl={anchorEl}
                      setIsShownConfirmDialog={setIsShownConfirmDialog}
                      setTypeOfDialog={setTypeOfDialog}
                    />
                  )}
                </Shell>
              );
            })}
          </ScrollContainer>
        </ScrollContainerWrapper>
        <IconButton
          onClick={() => scroll(150)}
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <NewBuildingModal
        open={isShownConfirmDialog}
        onClose={() => setIsShownConfirmDialog(false)}
        id={page.id}
        isAddBuilding={isAddBuilding}
        setIsAddBuilding={setIsAddBuilding}
        buildingId={selectedBuildingId}
        buildingName={selectedBuildingName}
        typeOfDialog={typeOfDialog}
      />
    </>
  );
};
