import { FileKindEnum, IPdfDocument } from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { GallerySection } from './GallerySection';
import { PaymentPlanSection } from './PaymentPlanSection';

import {
  BedIcon,
  BuildingsBlue,
  BuildingsIcon,
  CalendarBlue,
  CurrencyCircleDollarIcon,
  FloorBlue,
  FloorIcon,
  FurnishingBlue,
  FurnishingIcon,
  HouseLineIcon,
  LocationIcon,
  NoteIcon,
  PaintRollerBlue,
  PaintRollerIcon,
  ParkingBlue,
  RulerIcon,
  ShowerIcon,
} from '@/assets/pdf';

import { AddressIconText } from './AddressIconText';
import { ContactSection } from './ContactSection';
import { Picture } from './Picture';
import { IOffplanPdfDocument } from '@/types/pdf';
import { splitAndReformat } from '../ClassicView/UnitsPdfDocument';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const baseStyles = StyleSheet.create({
  poppinsBold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  informationInsideImage: {
    display: 'flex',
    borderRadius: 30,
    position: 'absolute',
    bottom: '40px',
    backgroundColor: '#f9fcffcc',
    padding: 23,
  },
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    padding: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  bold: {
    fontSize: 40,
    ...baseStyles.poppinsBold,
  },
  price: {
    fontSize: 56,
    lineHeight: 1.3,
    color: '#2A3842',
    ...baseStyles.poppinsBold,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#2A3842',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2A3842',
    fontSize: 56,
    ...baseStyles.poppinsBold,
  },
  pageTitleWrapper: {
    flexDirection: 'row',
    left: '0px',
    maxWidth: '917px',
    ...baseStyles.informationInsideImage,
  },
  image: {
    height: '48vh',
    borderRadius: 10,
    objectFit: 'cover',
    position: 'relative',
  },
  featureSection: {
    flexDirection: 'row',
    right: '0px',
    ...baseStyles.informationInsideImage,
  },
  addressAndPriceContainer: {
    flexDirection: 'column',
    gap: '10px',
    maxWidth: '1300px',
    left: '0px',
    ...baseStyles.informationInsideImage,
  },
  characteristicsAbsoluteContainer: {
    ...baseStyles.informationInsideImage,
  },
  characteristicsContainer: {
    padding: '24px',
    borderRadius: '8px',
    width: '35vw',
  },
  characteristicsMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  characteristicsInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  characteristicsText: {
    color: '#2A3842',
    opacity: 0.5,
  },
  values: {
    color: '#1650FF',
    fontWeight: 600,
    fontSize: 56,
  },
  priceText: {
    color: '#2A3842',
    fontWeight: 600,
    fontSize: 40,
  },
});

export function PdfDocument({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  units,
}: IOffplanPdfDocument) {
  const characteristics = [
    {
      name: 'Developent status',
      value: offplanData?.developmentStatus
        ? splitAndReformat(offplanData?.developmentStatus)
        : null,
      icon: PaintRollerBlue,
    },
    {
      name: 'Property type',
      value: offplanData?.constructionType ? splitAndReformat(offplanData?.constructionType) : null,
      icon: BuildingsBlue,
    },
    {
      name: 'Floor',
      value: offplanData?.numberOfFloors,
      icon: FloorBlue,
    },
    {
      name: 'Handover',
      value: offplanData?.handover,
      icon: CalendarBlue,
    },
    {
      name: 'Furnishing',
      value: offplanData?.furnishing ? 'Yes' : 'No',
      icon: FurnishingBlue,
    },
    {
      name: 'Parking',
      value: offplanData?.coveredParking ? 'Yes' : 'No',
      icon: ParkingBlue,
    },
  ];
  const splitPoint = Math.ceil(characteristics.length / 2);

  const firstHalf = characteristics.slice(0, splitPoint);
  const secondHalf = characteristics.slice(splitPoint);

  const paymentPlan = offplanData?.paymentPlan;
  const renderCharacteristics = (chars: any[]) =>
    chars.map((character, index, arr) => (
      <View key={character.name} style={styles.characteristicsContainer}>
        <View style={styles.characteristicsInnerContainer}>
          <IconText iconSrc={character.icon}>{character.name}</IconText>
          <Text style={styles.characteristicsText}> {character.value}</Text>
        </View>
        {arr.length > index + 1 && <Viewider />}
      </View>
    ));
  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <View>
            {showContent.images ? (
              <>
                <View>
                  <Picture src={showContent.images[0].fileUrl} style={styles.image} />
                  <View style={styles.pageTitleWrapper}>
                    <Text style={styles.pageTitle}>{offplanData?.title}</Text>
                  </View>
                </View>
                <View>
                  <Picture src={showContent.images[1].fileUrl} style={styles.image} />
                  <View style={styles.characteristicsAbsoluteContainer}>
                    <View>{renderCharacteristics(firstHalf)}</View>
                  </View>
                  <View style={{ ...baseStyles.informationInsideImage, right: '20px' }}>
                    <View>{renderCharacteristics(secondHalf)}</View>
                  </View>
                </View>
                <View>
                  <Picture src={showContent.images[2].fileUrl} style={styles.image} />
                  <View style={styles.addressAndPriceContainer}>
                    <AddressIconText iconSrc={LocationIcon}>{offplanData?.nameEn}</AddressIconText>
                    <View style={{ flexDirection: 'row', gap: '32px', alignItems: 'flex-end' }}>
                      <View style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        <Text style={styles.priceText}>from</Text>
                        <Text style={styles.values}>{offplanData?.convertedPriceFrom}</Text>
                        <Text style={styles.priceText}>to</Text>
                        <Text style={styles.values}>{offplanData?.convertedPriceTo}</Text>
                        <Text style={styles.priceText}>{offplanData?.currency}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        <Text style={styles.priceText}>from</Text>
                        <Text style={styles.values}>{offplanData?.convertedBuildUpAreaFrom}</Text>
                        <Text style={styles.priceText}>sqft</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            ) : null}
          </View>

          <Section wrap={true}>
            <Text>{offplanData?.description}</Text>
          </Section>

 
          {paymentPlan && <PaymentPlanSection paymentPlan={paymentPlan} />}
          <FeaturesSection data={offplanData} />
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>

          <GallerySection
            wrap={true}
            title='Gallery'
            images={showContent.images}
            style={{ marginTop: 113 }}
          />
        </View>
      </Page>
    </Document>
  );
}

const Separator = () => (
  <View
    style={{
      height: '100%',
      width: 4,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);

const numberFormat = new Intl.NumberFormat('en', {
  maximumSignificantDigits: 3,
});

const capitalizeFirstLetter = (string?: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Viewider = () => (
  <View
    style={{
      width: '100%',
      height: 2,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);
