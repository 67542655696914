import styles from './emptyListRequest.module.scss';
import Image from './emptyListRequest.png';

export const EmptyListRequest = () => {
  return (
    <div className={styles.container}>
      <img className={styles.containerImg} src={Image} alt='Empty list' />
      <div>
        The user does not have any client requests created yet!
      </div>
    </div>
  );
};
