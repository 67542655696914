export const messageContainerStyle = () => ({
  alignSelf: 'flex-start',
  marginBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  clear: 'both',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  width: '100%',
  transition: 'background-color 0.5s ease',

  '&.selected': {
    backgroundColor: '#CBE3FF66',
  },
});

export const messageWrapperStyle = () => ({
  width: 'auto',
  flex: '1 1',
  alignSelf: 'flex-start',
  display: 'flex',
  maxWidth: '65%',
  minWidth: '340px',
});

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    margin: '0 5px 10px',
    float: 'left',
    flexShrink: '0',
  };
};

export const messageDetailStyle = () => {
  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  };
};

export const messageTxtContainerStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    display: 'flex',
    alignSelf: 'flex-start',
  };
};

export const messageTxtWrapperStyle = ({ theme }) => {
  return {
    display: 'inline-block',
    borderRadius: '4px 14px 14px 14px',
    border: `1px solid ${theme.borderColor.primary}`,
    backgroundColor: `${theme.backgroundColor.white}`,
    padding: '10px 16px 9px',
    width: 'auto',
  };
};

export const messageTxtStyle = ({ theme }, showVariation, count) => {
  let emojiAlignmentProp = {
    ' > img': {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      verticalAlign: 'top',
      zoom: '1',
      margin: '0 2px',
    },
  };

  let emojiProp = {};

  if (count === 1) {
    emojiProp = {
      '> img': {
        width: '48px',
        height: '48px',
      },
    };
  } else if (count === 2) {
    emojiProp = {
      '> img': {
        width: '36px',
        height: '36px',
      },
    };
  } else if (count > 2) {
    emojiProp = {
      '> img': {
        width: '24px',
        height: '24px',
      },
    };
  }

  if (showVariation === false) {
    emojiProp = {
      '> img': {
        width: '24px',
        height: '24px',
      },
    };
  }

  return {
    margin: '0',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    textAlign: 'left',
    width: 'auto',
    color: `${theme.color.primary}`,
    ' a': {
      color: '#0432FF',
      '&:hover': {
        color: '#04009D',
      },
    },
    " a[href^='mailto:']": {
      color: '#F38C00',
      '&:hover': {
        color: '#F36800',
      },
    },
    " a[href^='tel:']": {
      color: '#3802DA',
      '&:hover': {
        color: '#2D038F',
      },
    },
    ...emojiAlignmentProp,
    ...emojiProp,
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '25px',
    gap: '7px',
  };
};

export const messageReactionsWrapperStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '5px',
    paddingTop: '6px',
  };
};

export const messageReplyStyle = ({ theme }) => {
  return {
    position: 'relative',
    paddingLeft: '12px',
    marginBottom: '8px',
    '&::before': {
      content: `""`,
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      width: '4px',
      borderRadius: '8px',
      backgroundColor: `${theme.borderColor.secondary}`,
    },
  };
};

export const messageReplyNameStyle = () => {
  return {
    fontWeight: '600',
  };
};
