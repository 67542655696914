import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import {
  NewBuildingModel,
  BuildingsDataType,
  CreateNewPlanType,
  AdFieldBedsEnum,
  NotificationTypeEnum,
  State,
  UserKindEnum,
} from '@/types';
import { Buildings } from './Buildings';
import { Plans } from './Plans';
import { Title, StyledAccordion } from '../styled';
import { Loader } from '@/components';

export const PlansBlock = ({
  page,
  buildings,
  isAddBuilding,
  setIsAddBuilding,
  totalPlans,
  plans,
}: {
  page: NewBuildingModel;
  buildings?: BuildingsDataType[];
  plans?: CreateNewPlanType[];
  isAddBuilding: boolean;
  totalPlans: number;
  setIsAddBuilding: (value: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [newPlans, setNewPlans] = useState<CreateNewPlanType[]>(plans || []);
  const [totalPlansWithUnits, setTotalPlansWithUnits] = useState<number>(totalPlans);
  const [totalPlansWithoutUnits, setTotalPlansWithoutUnits] = useState<number>(totalPlans);
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | null>(null);

  const { user } = useSelector(({ auth }: State) => auth);
  const isAdmin = user.kind === UserKindEnum.Admin;

  const dispatch = useDispatch();

  const getPlans = async (selectedBuildingId?: string | null) => {
    const buildingId = selectedBuildingId !== 'All' ? selectedBuildingId : null;
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getPlans(
      page.id!,
      buildingId!
    );
    if (ok && data) {
      const { items, total } = data;
      setNewPlans(items as CreateNewPlanType[]);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPlans(selectedBuildingId);
  }, [selectedBuildingId]);

  useEffect(() => {
    setTotalPlansWithUnits(newPlans?.filter((plan: CreateNewPlanType) => plan.units).length);
    setTotalPlansWithoutUnits(newPlans?.filter((plan: CreateNewPlanType) => !plan.units).length);
  }, [newPlans]);

  const roomTypes = [
    { bedType: AdFieldBedsEnum.Studio, name: 'Studio' },
    { bedType: AdFieldBedsEnum.One, name: '1 Bed' },
    { bedType: AdFieldBedsEnum.Two, name: '2 Beds' },
    { bedType: AdFieldBedsEnum.Three, name: '3 Beds' },
    { bedType: AdFieldBedsEnum.Four, name: '4 Beds' },
    { bedType: AdFieldBedsEnum.Five, name: '5 Beds' },
    { bedType: AdFieldBedsEnum.Six, name: '6 Beds' },
    { bedType: AdFieldBedsEnum.Seven, name: '7 Beds' },
    { bedType: AdFieldBedsEnum.EightPlus, name: '8+ Beds' },
  ];

  const filteredPlansWithUnits = roomTypes.map((type) => ({
    ...type,
    plans: newPlans.filter((plan) => plan.beds === type.bedType && plan.units),
  }));
  const filteredPlansWithoutUnits = roomTypes.map((type) => ({
    ...type,
    plans: newPlans.filter((plan) => plan.beds === type.bedType && !plan.units),
  }));
  const isUnits = filteredPlansWithoutUnits.filter((rooms) => rooms.plans.length > 0).length > 0;

  return (
    <>
      <Title>
        {totalPlansWithUnits > 0 ? `${totalPlansWithUnits} Available Plans` : 'Available Plans'}
      </Title>
      <Buildings
        page={page}
        isAdmin={isAdmin}
        buildings={buildings}
        isAddBuilding={isAddBuilding}
        setIsAddBuilding={setIsAddBuilding}
        selectedBuildingId={selectedBuildingId}
        setSelectedBuildingId={setSelectedBuildingId}
      />
      {filteredPlansWithUnits.map(
        ({ name, plans }) =>
          plans.length > 0 && (
            <Plans
              key={name}
              isAdmin={isAdmin}
              page={page}
              plans={plans}
              name={name}
              isAddBuilding={isAddBuilding}
              setIsAddBuilding={setIsAddBuilding}
            />
          )
      )}
      {/* {isUnits && (
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Title>{totalPlansWithoutUnits} Plans without Units</Title>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, gap: 10 }}>
            {filteredPlansWithoutUnits.map(
              ({ name, plans }) =>
                plans.length > 0 && (
                  <Plans
                    key={name}
                    isAdmin={isAdmin}
                    page={page}
                    plans={plans}
                    name={name}
                    isAddBuilding={isAddBuilding}
                    setIsAddBuilding={setIsAddBuilding}
                  />
                )
            )}
          </AccordionDetails>
        </StyledAccordion>
      )} */}
      {isUnits &&
        filteredPlansWithoutUnits.map(
          ({ name, plans }) =>
            plans.length > 0 && (
              <Plans
                key={name}
                isAdmin={isAdmin}
                page={page}
                plans={plans}
                name={name}
                isAddBuilding={isAddBuilding}
                setIsAddBuilding={setIsAddBuilding}
              />
            )
        )}
    </>
  );
};
