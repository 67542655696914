import { useLocation, useNavigate } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { ReactNode, ReactElement } from 'react';
import { MenuVariant } from '@/components/Sidebar/SidebarMenu';

export const OpListItem = ({
  variant = 'primary',
  href,
  icon,
  content,
  onClick,
}: {
  variant?: MenuVariant;
  href?: string;
  icon?: ReactElement;
  content: ReactNode;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <ListItem
      style={{ padding: 0 }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <ListItemButton
        selected={currentPage === href}
        sx={{
          padding: '16px',
          gap: '12px',
          '&.Mui-selected': {
            backgroundColor:
              variant === 'primary' || variant === 'icon' ? '#CBE3FF' : 'transparent',
          },
          borderRadius: variant === 'primary' || variant === 'icon' ? '37px' : undefined,
        }}
        onClick={() => {
          href && navigate(href);
        }}
      >
        {icon && <ListItemIcon style={{ minWidth: '24px', color: 'inherit' }}>{icon}</ListItemIcon>}
        {variant !== 'icon' && content}
      </ListItemButton>
    </ListItem>
  );
};
