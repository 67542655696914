import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableFooter,
  TablePagination,
  Box,
  Tabs,
  Tab,
  Stack,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled, useTheme } from '@mui/material/styles';
import styles from './StatisticBlock.module.scss';
import {
  ActivityKindEnum,
  ITransactionsPayload,
  NotificationTypeEnum,
  ITransactionsType,
  ITransaction,
  SortingOrderEnum,
} from '@/types';
import { Loader } from '@/components';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { priceFormat } from '@/utils';

interface ButtonData {
  label: string;
  value: string;
  price: number;
}
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const StyledButton = styled(Button)<{ selected: boolean }>(({ theme, selected }) => ({
  color: '#2A3842',
  width: '100%',
  maxWidth: '150px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  border: selected ? '1px solid #1650FF' : '1px solid #E9EEF1',
  padding: '30px 0',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: selected ? 'rgba(0, 0, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
  },
}));

const commonStyle = {
  border: 0,
  borderRight: '1px solid #E9EEF1',
  '&:last-child': {
    borderRight: 'none',
  },
  verticalAlign: 'middle',
};

const StyledTableCellHeader = styled(TableCell)({
  ...commonStyle,
  fontSize: '16px',
  fontWeight: 600,
});

const StyledTableCellBody = styled(TableCell)({
  ...commonStyle,
  borderTop: '1px solid #E9EEF1',
});

const initialButtons: ButtonData[] = [
  { label: 'Studio', value: 'studio', price: 0 },
  { label: '1 Bedroom', value: '1', price: 0 },
  { label: '2 Bedrooms', value: '2', price: 0 },
  { label: '3+ Bedrooms', value: '3', price: 0 },
];

export const StatisticBlock = ({ projectNumber }: { projectNumber: string }) => {
  const [transactions, setTransactions] = useState<ITransaction[]>();
  const [tabValue, setTabValue] = useState<number>(0);
  const [activityKind, setActivityKind] = useState<ActivityKindEnum>(ActivityKindEnum.Sale);
  const [beds, setBeds] = useState<string | undefined>(undefined);
  const [bedroomsButton, setBedroomsButton] = useState<ButtonData[]>(initialButtons);
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const isRent = activityKind === ActivityKindEnum.Rent;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setActivityKind(newValue === 0 ? ActivityKindEnum.Sale : ActivityKindEnum.Rent);
    setPage(0);
    setRowsPerPage(10);
  };

  const handleClick = (value: string, index: number) => {
    setSelectedButton((prevIndex) => (prevIndex === index ? null : index));
    setBeds((prevBeds) => (prevBeds === value ? undefined : value));
    setPage(0);
    setRowsPerPage(10);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTransactions = async (payload: ITransactionsPayload) => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getTransactions(payload);
    if (ok && data) {
      const {
        items,
        total,
        priceMaxStudio = 0,
        priceMaxOneBedroom = 0,
        priceMaxTwoBedroom = 0,
        priceMaxThreeBedroom = 0,
      } = data as ITransactionsType;
      setTransactions(items as ITransaction[]);
      setTotalCount(+total);
      setBedroomsButton((prevState) =>
        prevState.map((button) => {
          switch (button.value) {
            case 'studio':
              return { ...button, price: priceMaxStudio };
            case '1':
              return { ...button, price: priceMaxOneBedroom };
            case '2':
              return { ...button, price: priceMaxTwoBedroom };
            case '3':
              return { ...button, price: priceMaxThreeBedroom };
            default:
              return button;
          }
        })
      );
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const payload: ITransactionsPayload = {
      projectNumber: projectNumber,
      activityKind: activityKind,
      beds: beds,
      limit: limit,
      offset: offset,
      sort: [
        {
          field: 'instanceDate',
          order: SortingOrderEnum.Desc,
        },
      ],
    };
    getTransactions(payload);
  }, [page, rowsPerPage, activityKind, beds]);

  if (isLoading) return <Loader />;

  return !transactions ? null : (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label='type'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-sale'
            aria-controls='tabpanel-sale'
            label='Sale'
            className={styles.tabItem}
          />
          <Tab
            id='tab-rent'
            aria-controls='tabpanel-rent'
            label='Rent'
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      <Box className={styles.priceTitle}>
        {isRent ? 'Average rent price' : 'Average selling price'}
      </Box>
      <Stack spacing={2} direction='row'>
        {bedroomsButton.map((button, index) => (
          <StyledButton
            key={index}
            selected={selectedButton === index}
            onClick={() => handleClick(button.value, index)}
          >
            <Typography sx={{ fontWeight: 400, textTransform: 'none' }} variant='body2'>
              {button.label}
            </Typography>
            <Typography sx={{ fontWeight: 600, color: '#1650FF', fontSize: '15px' }}>
              {priceFormat(button.price)} AED
            </Typography>
          </StyledButton>
        ))}
      </Stack>
      <TableContainer sx={{ borderRadius: '16px' }}>
        <Table sx={{ border: '1px solid #E9EEF1' }}>
          <TableHead sx={{ background: '#C5DFFF' }}>
            <TableRow>
              <StyledTableCellHeader sx={{ maxWidth: '130px' }}>
                Transaction date
              </StyledTableCellHeader>
              <StyledTableCellHeader>Price</StyledTableCellHeader>
              <StyledTableCellHeader sx={{ maxWidth: '110px' }}>
                Num. of bedrooms
              </StyledTableCellHeader>
              {isRent && (
                <StyledTableCellHeader sx={{ maxWidth: '110px' }}>
                  Rental duration
                </StyledTableCellHeader>
              )}
              <StyledTableCellHeader>Area</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions?.map((row, index) => (
              <TableRow key={`${row.instanceDate + index}`}>
                <StyledTableCellBody>
                  {moment(row.instanceDate).format('DD.MM.YYYY')}
                </StyledTableCellBody>
                <StyledTableCellBody>{priceFormat(row.price)} AED</StyledTableCellBody>
                <StyledTableCellBody>{row.rooms}</StyledTableCellBody>
                {isRent && <StyledTableCellBody>{row.contractTime} months</StyledTableCellBody>}
                <StyledTableCellBody>{row.area} sqft</StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={7}
                count={totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};
