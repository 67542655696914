import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeVideoPlugin from 'photoswipe-video-plugin/dist/photoswipe-video-plugin.esm.js';
import {
  leftArrowSVGString as leftArrowIcon,
  rightArrowSVGString as rightArrowIcon,
  closeIconSVGString as closeIcon,
} from './assets';

interface LightboxConfig {
  gallery: string;
  children: string;
  onImageChange: (index: number) => void;
  leftArrowSVGString?: string;
  rightArrowSVGString?: string;
  closeIconSVGString?: string;
}

export const createLightSlider = (config: LightboxConfig): PhotoSwipeLightbox => {
  const {
    leftArrowSVGString = leftArrowIcon,
    rightArrowSVGString = rightArrowIcon,
    closeIconSVGString = closeIcon,
  } = config || {};

  const lightSlider = new PhotoSwipeLightbox({
    arrowPrevSVG: leftArrowSVGString,
    arrowNextSVG: rightArrowSVGString,
    closeSVG: closeIconSVGString,
    gallery: config.gallery,
    children: config.children,
    pswpModule: () => import('photoswipe'),
  });

  lightSlider.init();

  lightSlider.on('close', () => {
    const currentIndex = lightSlider.pswp?.currIndex || 0;
    if (!isNaN(currentIndex) && config.onImageChange) {
      config.onImageChange(currentIndex);
    }
  });

  return lightSlider;
};

export const createLightVideoSlider = (config: LightboxConfig): PhotoSwipeLightbox => {
  const {
    leftArrowSVGString = leftArrowIcon,
    rightArrowSVGString = rightArrowIcon,
    closeIconSVGString = closeIcon,
  } = config || {};

  const lightSlider = new PhotoSwipeLightbox({
    arrowPrevSVG: leftArrowSVGString,
    arrowNextSVG: rightArrowSVGString,
    closeSVG: closeIconSVGString,
    gallery: config.gallery,
    children: config.children,
    pswpModule: () => import('photoswipe'),
  });

  const videoPlugin = new PhotoSwipeVideoPlugin(lightSlider, {});

  lightSlider.init();

  lightSlider.on('close', () => {
    const currentIndex = lightSlider.pswp?.currIndex || 0;
    if (!isNaN(currentIndex) && config.onImageChange) {
      config.onImageChange(currentIndex);
    }
  });

  return lightSlider;
};
