import { useRef } from 'react';
import { Cropper, CropperRef, CircleStencil } from 'react-advanced-cropper';
import { roundEdges } from './utils';

import 'react-advanced-cropper/dist/style.css';
import './styles.scss';

interface ICircleCropperProps {
  image: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCrop: (blob: Blob) => any;
}

export const CircleCropper: React.FC<ICircleCropperProps> = ({
  image,
  label = 'Save result',
  onCrop,
}) => {
  const cropperRef = useRef<CropperRef>(null);

  const onImageCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      if (canvas) {
        roundEdges(canvas).toBlob((blob) => {
          if (blob) {
            onCrop(blob);
          }
        });
      }
    }
  };

  return (
    <div className='circleCropper'>
      <div className='circleCropper__cropper-wrapper'>
        <Cropper
          ref={cropperRef}
          className='circleCropper__cropper'
          stencilComponent={CircleStencil}
          backgroundClassName='circleCropper__cropper-background'
          src={image}
        />
      </div>
      <div className='circleCropper__buttons-wrapper'>
        <button className='circleCropper__button' onClick={onImageCrop}>
          {label}
        </button>
      </div>
    </div>
  );
};
