import { FC } from 'react';
import { Stack } from '@mui/material';
import { LabelComponent } from '@/components';
import SelectComponent from './SelectComponent';
import { IMySelectProps } from '../types';

export const MySelect: FC<IMySelectProps> = ({
  data,
  control,
  errors,
  onClick,
  disabled,
  selectedSelect,
  sx,
}) => {
  const { name, options, placeholder, label, isRequired, iconInfo, tooltip, dataQa } = data;

  return (
    <Stack sx={{ width: '100%' }}>
      <LabelComponent label={label} isRequired={isRequired} iconInfo={iconInfo} tooltip={tooltip} />
      <SelectComponent
        dataQa={dataQa}
        options={options}
        placeholder={placeholder}
        control={control}
        name={name}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        onClick={onClick}
        disabled={disabled}
        selectedSelect={selectedSelect}
        sx={sx}
      />
    </Stack>
  );
};
