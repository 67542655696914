import { useState } from 'react';
import { Avatar, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  State,
  AdModel,
  AdFieldLandKindEnum,
  FileKindEnum,
  ActivityKindEnum,
  UserModel,
  RentPeriodEnum,
  AdsFilteringType,
  TransactionTypeEnum,
  AdFieldOccupancyEnum,
  AdFieldMultilistingEnum,
  PropertyKindEnum,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
} from '@/types';
import { priceFormat, stringAvatar } from '@/utils';

import styles from './AdsListItem.module.scss';
import ItemFee from './svg/itemFee.svg';
import ItemGps from './svg/itemGps.svg';
import ItemSqft from './svg/itemSqft.svg';
import ItemBed from './svg/itemBed.svg';
import ItemBaths from './svg/itemBaths.svg';
import ItemIconUpdated from './svg/itemIconUpdated.svg';
import ItemIconImages from './svg/itemIconImages.svg';
import ItemIconVideo from './svg/itemIconVideo.svg';
import ItemIconPlan from './svg/itemIconPlan.svg';
import ImgArrayLeftBtn from './svg/imgArrayLeftBtn.svg';
import ImgArrayRightBtn from './svg/imgArrayRightBtn.svg';
import { Favorite } from '@mui/icons-material';
import { favoritesProvider } from '@/providers/favoritesProvider';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

interface AdsListItemProps {
  item: AdModel;
  user?: UserModel;
  filteringObj?: AdsFilteringType;
  userId?: string;
  tariffPlan?: UserTariffPlanEnum;
}

type PreferredPropertyKinds =
  | 'shop'
  | 'office'
  | 'warehouse'
  | 'residentialLand'
  | 'industrialLand'
  | 'commercialLand';

export const AdsListItem = ({
  item: {
    activityKind,
    transactionType,
    occupancy,
    nameEn,
    addressEn,
    baths,
    beds,
    landKind,
    listingAgencyFeePercent,
    id,
    propertyAdCreator,
    propertyAdCreatorEmployer,
    propertyAdMedia,
    rentFrequency,
    title,
    updatedAt,
    convertedAreaUnits,
    convertedArea,
    convertedCurrency,
    convertedPrice,
    convertedListingAgencyFee,
    plotArea,
    isFavorite,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    defaultRentFrequency,
    rentedTill,
    price,
    sellingPrice,
    multilisting,
    propertyKind,
    convertedSellingPrice,
  },
  user,
  userId,
  tariffPlan,
  filteringObj,
}: AdsListItemProps) => {
  // const { id: userId } = useSelector(({ auth }: State) => auth.user);
  const [imageIndex, setImageIndex] = useState(0);
  const { openDialog, setOpenDialog } = useDialogs();
  const images = propertyAdMedia
    .filter((item) => item.kind === FileKindEnum.Image)
    .sort((a, b) => a.order - b.order);
  const hasVideo = !!propertyAdMedia.find((item) => item.kind === FileKindEnum.Video);
  const isNotComercial = landKind !== AdFieldLandKindEnum.Commercial;
  const [favorite, setFavorite] = useState(isFavorite);

  const transactionTypeMapping = {
    [TransactionTypeEnum.Ready]: 'Ready',
    [TransactionTypeEnum.Resale]: 'Resale',
    [TransactionTypeEnum.Distress]: 'Distress',
  };
  const transactionTypeText = transactionType && transactionTypeMapping[transactionType];
  const availabilityTypeMapping = {
    [AdFieldOccupancyEnum.Vacant]: 'Vacant',
    [AdFieldOccupancyEnum.Tenanted]: 'Rented',
  };
  const isOccupancy =
    occupancy === AdFieldOccupancyEnum.Vacant || occupancy === AdFieldOccupancyEnum.Tenanted;
  const availabilityTypeText =
    occupancy && isOccupancy ? ` | ${availabilityTypeMapping[occupancy]}` : '';
  const rentedTillText = rentedTill ? ` | ${moment(rentedTill).format('MMM YYYY')}` : '';
  const distressPercentFee =
    price && sellingPrice
      ? ` | ${(((Number(price) - Number(sellingPrice)) / Number(price)) * 100).toFixed(1)}%`
      : '';

  const isUpdatedToday = moment().isSame(updatedAt, 'day');
  const parsedUpdateDate = moment(updatedAt).format(
    `${isUpdatedToday ? '[today]' : 'DD.MM.YYYY'} hh:MM a`
  );

  const isTariffFree = tariffPlan === UserTariffPlanEnum.Free;
  const userName = isTariffFree ? 'Creator of property' : user ? user?.name : propertyAdCreator?.name;
  const agencyName = isTariffFree ? 'Creator Employer of Ad' : propertyAdCreatorEmployer?.name;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isSale = activityKind === ActivityKindEnum.Sale;
  const filterRentFrequency = filteringObj?.rentFrequency;
  const transactionDate = `${transactionTypeText}${availabilityTypeText}${rentedTillText}${distressPercentFee}`;

  const rentFrequencyLabels = {
    [RentPeriodEnum.Yearly]: 'Yearly',
    [RentPeriodEnum.Monthly]: 'Monthly',
    [RentPeriodEnum.Weekly]: 'Weekly',
    [RentPeriodEnum.Daily]: 'Daily',
  };

  const multilistingLabel = {
    [AdFieldMultilistingEnum.Covered]: 'Covered',
    [AdFieldMultilistingEnum.NotCovered]: 'Not Covered',
  };
  const multilistingText = multilisting && multilistingLabel[multilisting];

  const preferredPropertyKinds = [
    PropertyKindEnum.Shop,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ];
  const propertyKindLabel: Record<PreferredPropertyKinds, string> = {
    shop: 'Shop',
    office: 'Office',
    warehouse: 'Warehouse',
    residentialLand: 'Residential Land',
    industrialLand: 'Industrial Land',
    commercialLand: 'Commercial Land',
  };
  const isPrefTitle = preferredPropertyKinds.includes(propertyKind);
  const titlePref = propertyKind && propertyKindLabel[propertyKind as PreferredPropertyKinds];
  const titleText = isPrefTitle ? `${titlePref} at ${nameEn}` : nameEn;

  const handleChangeImageIndex = (e: React.MouseEvent<HTMLElement>, newIndex: number) => {
    e.preventDefault();
    setImageIndex(newIndex < 0 ? images.length - 1 : newIndex >= images.length ? 0 : newIndex);
  };

  const adCreatorHref =
    userId == propertyAdCreator?.id ? '/profile/view' : `/users/${propertyAdCreator?.id}`;
  const adCreatorEmployerHref =
    userId == propertyAdCreatorEmployer?.id
      ? '/profile/view'
      : `/users/${propertyAdCreatorEmployer?.id}`;

  const textBeds = beds === 'Studio' ? '' : Number(beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  const convertedPlotArea = plotArea
    ? convertedAreaUnits === 'SQFT'
      ? plotArea
      : Math.round(Number(plotArea) / 10.764)
    : 0;
  const aria = convertedArea ? convertedArea : plotArea ? convertedPlotArea : 0;
  const handleFavoriteClick = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    favorite
      ? await favoritesProvider.removeFromFavorites(id)
      : await favoritesProvider.addToFavorites(id);
    setFavorite((s) => !s);
  };

  let newPrice = 0;
  if (isSale) {
    if (transactionType === TransactionTypeEnum.Distress) {
      newPrice = convertedSellingPrice || 0;
    } else {
      newPrice = convertedPrice || 0;
    }
  } else {
    const rentFrequency = filterRentFrequency || defaultRentFrequency;
    switch (rentFrequency) {
      case RentPeriodEnum.Yearly:
        newPrice = convertedPriceYearly || 0;
        break;
      case RentPeriodEnum.Monthly:
        newPrice = convertedPriceMonthly || 0;
        break;
      case RentPeriodEnum.Weekly:
        newPrice = convertedPriceWeekly || 0;
        break;
      case RentPeriodEnum.Daily:
        newPrice = convertedPriceDaily || 0;
        break;
    }
  }

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const imgUrl = isMobile
    ? `${images[imageIndex]?.fileUrl}_370x238`
    : `${images[imageIndex]?.fileUrl}_482x238`;

  return (
    <div className={styles.listItem}>
      <Link href={`/ads/${id}`} title={title} className={styles.itemImg}>
        {images.length > 0 && <img src={imgUrl} alt={title} />}
        {images.length > 1 && (
          <div className={styles.sliderControls}>
            <img
              src={ImgArrayLeftBtn}
              alt='Prev image'
              onClick={(e) => handleChangeImageIndex(e, imageIndex - 1)}
            />
            <img
              src={ImgArrayRightBtn}
              alt='Next image'
              onClick={(e) => handleChangeImageIndex(e, imageIndex + 1)}
            />
          </div>
        )}
        <div className={styles.itemImgInfo}>
          <div className={styles.itemUpdatedDate}>
            <img src={ItemIconUpdated} alt='' />
            {parsedUpdateDate}
          </div>
          {isSale && transactionType && (
            <div className={styles.itemTransactionDate}>
              <p>{transactionDate}</p>
            </div>
          )}
          <div className={styles.itemImgIcons}>
            <div className={styles.itemImgIconsImages}>
              <img src={ItemIconImages} alt='' />
              {images.length}
            </div>
            {hasVideo && <img src={ItemIconVideo} alt='' />}
            <div className={styles.itemImgIconsImages}>
              <Favorite
                onClick={handleFavoriteClick}
                color='secondary'
                style={{
                  fill: favorite ? '#1650FF' : 'none',
                  stroke: favorite ? '#1650FF' : 'white',
                  strokeWidth: 2,
                }}
              />
            </div>
          </div>
        </div>
      </Link>
      <div className={styles.itemContent}>
        <Link href={`/ads/${id}`} title={title} className={styles.itemName}>
          {titleText}
        </Link>
        <div className={styles.itemPriceContainer}>
          <div className={styles.itemPrice}>
            Price <span>{priceFormat(newPrice)}</span> {convertedCurrency}
            {!isSale && defaultRentFrequency && (
              <div className={styles.itemLabel}>
                {
                  rentFrequencyLabels[
                    filterRentFrequency ? filterRentFrequency : defaultRentFrequency
                  ]
                }
              </div>
            )}
          </div>
          <div className={styles.itemFee}>
            <img src={ItemFee} alt='' />
            {multilistingText}
          </div>
        </div>
        <div className={styles.itemAddress}>
          <img src={ItemGps} alt='' />
          {addressEn || nameEn}
        </div>
        <div className={styles.itemParams}>
          {aria > 0 && (
            <div>
              <img src={ItemSqft} alt='' />
              {aria} {convertedAreaUnits.toLowerCase()}
            </div>
          )}
          {isNotComercial && (
            <>
              {beds && (
                <>
                  <div className={styles.itemParamsSeparator} />
                  <div>
                    <img src={ItemBed} alt='' />
                    {`${beds} ${textBeds}`}
                  </div>
                </>
              )}
              {baths && (
                <>
                  <div className={styles.itemParamsSeparator} />
                  <div>
                    <img src={ItemBaths} alt='' />
                    {`${baths} ${textBaths}`}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className={`${styles.itemLinks} ${isTariffFree ? styles.blurText : styles.text}`}>
          {user ? (
            <Link title={userName}>
              <Avatar
                src={user?.media?.[0]?.fileUrl || undefined}
                alt={userName}
                {...stringAvatar(userName)}
              />
              <span>{userName}</span>
            </Link>
          ) : (
            <Link 
              href={isTariffFree ? undefined : adCreatorHref } 
              title={userName} 
              onClick={() => isTariffFree && handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
            >
              <Avatar
                src={propertyAdCreator?.avatar || undefined}
                alt={userName}
                {...stringAvatar(userName)}
              />
              <span>{userName}</span>
            </Link>
          )}
          {(propertyAdCreatorEmployer || isTariffFree) && (
            <Link 
              href={isTariffFree ? undefined : adCreatorEmployerHref} 
              title={agencyName}
              onClick={() => isTariffFree && handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
            >
              <Avatar
                src={propertyAdCreatorEmployer?.avatar || undefined}
                alt={agencyName}
                {...stringAvatar(agencyName)}
              />
              <span>{agencyName}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
