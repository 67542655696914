import { Box, Container, Link } from '@mui/material';
import { LogoIcon } from '@/assets/svg';
import { ContactSection } from '@/components/Footer/ContactSection';
import { InfoDialog } from '@/components/Footer/InfoDialog';
import { SocialSection } from '@/components/Footer/SocialSection';

export const Footer = () => {
  return (
    <footer
      data-qa='footer'
      style={{
        backgroundColor: '#0A1329',
        color: '#FFF',
        width: '100%',
        paddingTop: '12px',
        paddingBottom: '12px',
        fontSize: '14px',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            justifyContent: {
              md: 'space-between',
            },
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginTop: '14px',
            }}
          >
            <Link style={{ display: 'flex' }} href='/'>
              <LogoIcon style={{ width: '66px', height: '69px' }} fill='#FFF' />
            </Link>
            <InfoDialog />
          </Box>
          <ContactSection />
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              flexGrow: {
                xs: 1,
                md: 0,
              },
              justifyContent: {
                xs: 'center',
                md: 'end',
              },
            }}
          >
            <SocialSection />
          </Box>
        </Box>
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '57px',
            borderTop: '1px solid #1E293B',
            margin: '20px 0 25px 0',
            textAlign: 'center',
          }}
        >
          &copy;&nbsp;Copyright 2024, All Rights Reserved by&nbsp;OnlineProperty.io
        </p>
      </Container>
    </footer>
  );
};
