import React from 'react';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import HistoryIcon from '@mui/icons-material/History';
import ChatIcon from '@mui/icons-material/Chat';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
interface UserMenuProps {
  anchorEl?: Element;
  anchorPosition: { top: number; left: number } | undefined;
  chatId?: string;
  isBlocked?: boolean;
  isVerificationEnabled?: boolean;
  handleMenuClose: () => void;
  handleBlockUserDialog: () => void;
  handleUnBlockUserDialog: () => void;
  handleBlockHistoryDialog: () => void;
  handleImpersonateUserDialog: () => void;
  handleEmploymentRequestDialog: () => void;
  handleVerifyUserDialog: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  anchorEl,
  anchorPosition,
  isBlocked,
  isVerificationEnabled,
  chatId,
  handleMenuClose,
  handleBlockUserDialog,
  handleUnBlockUserDialog,
  handleBlockHistoryDialog,
  handleImpersonateUserDialog,
  handleEmploymentRequestDialog,
  handleVerifyUserDialog,
}) => {
  const handleBlockUser = () => {
    handleBlockUserDialog();
    handleMenuClose();
  };

  const handleUnBlockUser = () => {
    handleUnBlockUserDialog();
    handleMenuClose();
  };

  const handleBlockHistory = () => {
    handleBlockHistoryDialog();
    handleMenuClose();
  };

  const handleChatWithUser = () => {
    handleMenuClose();
    location.href = `/chat/group_${chatId}`;
  };

  const handleVerifyUser = () => {
    handleVerifyUserDialog();
    handleMenuClose();
  };

  const handleEditUserProfile = () => {
    handleMenuClose();
  };

  const handleLoginAsUser = () => {
    handleMenuClose();
    handleImpersonateUserDialog();
  };

  const handleEmploymentRequest = () => {
    handleMenuClose();
    handleEmploymentRequestDialog();
  };

  return (
    <Menu
      id='user-menu'
      anchorEl={anchorEl}
      anchorReference='anchorPosition'
      anchorPosition={anchorPosition}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {isBlocked && (
        <MenuItem onClick={handleUnBlockUser}>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          UnBlock user
        </MenuItem>
      )}
      {!isBlocked && (
        <MenuItem onClick={handleBlockUser}>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          Block user
        </MenuItem>
      )}
      <MenuItem onClick={handleBlockHistory}>
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        Block history
      </MenuItem>
      <MenuItem onClick={handleChatWithUser}>
        <ListItemIcon>
          <ChatIcon />
        </ListItemIcon>
        Chat
      </MenuItem>
      <MenuItem onClick={handleVerifyUser} disabled={!isVerificationEnabled}>
        <ListItemIcon>
          <VerifiedUserIcon />
        </ListItemIcon>
        Verification
      </MenuItem>
      <MenuItem onClick={handleEditUserProfile}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Edit user profile
      </MenuItem>
      <MenuItem onClick={handleLoginAsUser}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        Login as user
      </MenuItem>
      <MenuItem onClick={handleEmploymentRequest}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        Employment request
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
