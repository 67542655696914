import { FC, useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { StyledPhoneInput, ErrorText } from '../styled';
import { LabelComponent } from '@/components';
import { IMyPhoneInputProps } from '../types';

export const MyPhoneInput: FC<IMyPhoneInputProps> = ({
  data,
  control,
  setValue,
  errors,
  disabled,
  onChange,
  emailOrPhoneInput,
  setLogInPhoneValue,
}) => {
  const { name, placeholder, label, isRequired, dataQa } = data;
  const [phoneValue, setPhoneValue] = useState<string>(emailOrPhoneInput || '');
  const [prevPhoneValue, setPrevPhoneValue] = useState<string>(emailOrPhoneInput || '');
  const handleInputKeyPress = (e: any): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return;
    }

    if (!/\d/.test(e.key)) {
      e.preventDefault();
      onChange?.(formatPhoneNumber(e.target.value) + e.key, name);
    }
  };

  const handleInputChange = (e: any): void => {
    if (!e) {
      onChange?.('', name);
      setValue?.(name, '');
    } else {
      // let newPhoneValue;
      // if (prevPhoneValue && formatPhoneNumber(e).length < 3 && formatPhoneNumber(e).length !== 0) {
      //   newPhoneValue = e;
      //   onChange?.(formatPhoneNumber(e), name);
      // } else {
      //   if (e.length < 5) {
      //     newPhoneValue = e + formatPhoneNumber(prevPhoneValue);
      //   } else {
      //     newPhoneValue = e;
      //   }
      // }
      setPhoneValue(e);
      setPrevPhoneValue(e || '');
      setLogInPhoneValue?.(e);
      setValue?.(name, e);
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <LabelComponent label={label} isRequired={isRequired} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          let phoneNumberFormatted = field.value || phoneValue;
          return (
            <>
              <StyledPhoneInput
                {...field}
                data-qa={dataQa}
                // defaultCountry='AE'
                placeholder={placeholder}
                value={phoneNumberFormatted}
                autoFocus={name === 'phoneNumber'}
                international
                // countryCallingCodeEditable={false}
                disabled={disabled}
                onKeyPress={(e: any) => handleInputKeyPress(e)}
                onChange={(e: any) => handleInputChange(e)}
              />
              {!!errors[name] && <ErrorText>{errors[name]?.message}</ErrorText>}
            </>
          );
        }}
      />
    </Stack>
  );
};