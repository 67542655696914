import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import { User } from '../types/types';

const StyledTableCell = styled(TableCell)({
    
  border: 0,
  borderRight: '1px solid #E9EEF1',
  '&:last-child': {
    borderRight: 'none',
  },
  verticalAlign: 'middle',
  '&.header-cell': {
    fontWeight: 600
  },
  '&.user-name-cell,': {
    maxWidth: '250px'
  },
  '&.user-type-cell, &.registration-cell': {
    minWidth: '150px'
  },
  '&.social-cell': {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
});

const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 230px;
`;

const UserName = styled('div')`
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.foreign-item {
    color: #fff;
  }
`;

const UserRole = styled('div')`
  color: gray;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserAgency = styled('span')`
  color: #01439b;
`;

const UserDate = styled('span')`
  font-size: 14px;
`;

const UserLink = styled('a')`
  color: #1650ff;
  &:hover {
    text-decoration: none;
  }
  &:visited,
  &:focus {
    color: #1650ff;
  }
`;

const StyledTableContainer = styled(TableContainer)({
  background: 'transparent',
  boxShadow: 'none',
  maxWidth: '1003px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    borderRadius: '9px',
    height: '6px',
    backgroundColor: 'rgba(233, 238, 241, 1)',
    width: '300px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(42, 56, 66, 1)',
    borderRadius: '9px',
    height: '6px'
  },
});

const StyledTableRow = styled(TableRow)({
  border: '1px solid #E9EEF1',
  background: 'inherit',
});

const StyledTableHead = styled(TableHead)({
  background: '#fff',
});

const formatDate = (value: string | null) =>
  value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

interface UserTableProps {
  users: User[];
  handleMenuClick: (event: React.MouseEvent, userId: any) => void;
}

const UnregisteredTable: React.FC<UserTableProps> = ({ users, handleMenuClick }) => {
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell className={`header-cell user-name-cell`}>User name</StyledTableCell>
            <StyledTableCell className={`header-cell user-type-cell`}>User type</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Phone number</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Email</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Registration Date</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Social networks</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {users.map((user, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <UserInfo>
                  <UserName className={`${user.kind}-item`}>
                    {user.name}
                  </UserName>
                  <UserRole>
                    {user.employer?.name && <UserAgency>{user.employer.name}</UserAgency>}
                  </UserRole>
                </UserInfo>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.kind}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.phone}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.email}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{formatDate(user.createdAt)}</UserDate>
              </StyledTableCell>
              <StyledTableCell className={`social-cell`}>
                <UserDate>
                  {user.links.map((link, index) => (
                    <React.Fragment key={index}>
                      <UserLink target='_blank' href={link}>
                        {link}
                      </UserLink>
                      {index < user.links.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </UserDate>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default UnregisteredTable;
