import { FC, ReactElement } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TooltipComponent } from '@/components';
import { StyledButton } from './styled';

type ButtonType = 'button' | 'submit' | 'reset' | undefined;

export interface IMyButtonData {
  label?: string;
  buttonName?: string;
  iconInfo?: boolean;
  tooltip?: string;
  customWidth?: string | number;
  customMobileWidth?: string | number;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  buttonType?: ButtonType;
  styleType?:
    | 'cancel'
    | 'iconButton'
    | 'onlyIcon'
    | 'withoutIcon'
    | 'withIcon'
    | 'OPchat'
    | 'OP_CHAT'
    | 'unread'
    | 'dialog'
    | string;
  icon?: ReactElement;
  colorIcons?: string;
  buttonsIcon?: boolean;
  iconPosition?: 'start' | 'end';
  component?: React.ReactNode;
  dataQa?: string;
}

export interface IMyButtonProps {
  data: IMyButtonData;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SocialButton: FC<IMyButtonProps> = ({ data, disabled, onClick }) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customWidth,
    variant,
    size = 'medium',
    buttonType,
    styleType,
    icon,
    colorIcons,
    buttonsIcon,
    iconPosition = 'start',
    dataQa,
  } = data;

  return (
    <Stack direction='row' justifyContent='flex-start' alignItems='center' display='flex'>
      <StyledButton
        data-qa={dataQa}
        type={buttonType}
        styleType={styleType}
        colorIcons={colorIcons}
        variant={variant}
        size={size}
        customWidth={customWidth}
        onClick={onClick}
        disabled={disabled}
        startIcon={buttonsIcon && iconPosition === 'start' ? icon : null}
        endIcon={buttonsIcon && iconPosition === 'end' ? icon : null}
      >
        {icon}
      </StyledButton>
    </Stack>
  );
};
