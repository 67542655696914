import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserModel, NotificationTypeEnum } from '@/types';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';

export const useUserData = (userId?: string) => {
  const [userData, setUserData] = useState<UserModel>();
  const dispatch = useDispatch();

  const fetchData = async (id: string) => {
    const { ok, data, status, message } = await usersProvider.getUserById(id);
    if (ok && data) {
      setUserData(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId]);

  return { userData };
};
