import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  NotificationTypeEnum,
  State,
  TeamGetAgencyInfoByLinkResponseType,
  UserKindEnum,
} from '@/types';
import { teamProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { Loader, MyButton, MyDialog, PageNotFound } from '@/components';
import styles from './InviteLink.module.scss';
import { useUserProfile } from '@/hooks/useUserProfile';

export const InviteLink = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code: inviteLinkCode } = useParams<{ code: string }>();
  const { kind: userKind, employer } = useSelector(({ auth }: State) => auth.user);
  const { refetchUserProfile } = useUserProfile();
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [employers, setEmployers] = useState<TeamGetAgencyInfoByLinkResponseType>();
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

  const getAgency = async () => {
    const { ok, data, status, message } = await teamProvider.getAgencyInfoByLink(inviteLinkCode!);
    if (ok) {
      const { newEmployer, oldEmployer } = data as TeamGetAgencyInfoByLinkResponseType;
      setEmployers({ newEmployer, oldEmployer });
      setIsDialogOpened(true);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    localStorage.removeItem('inviteCode');
    if (inviteLinkCode) {
      getAgency();
    }
  }, []);

  const handleCloseDialog = () => {
    setIsDialogOpened(false);
    navigate(employer === null ? '/' : '/profile/view');
  };

  const handleConfirmInvite = async () => {
    setIsLoading(true);
    const { ok, status, message } = await teamProvider.inviteToAgencyByLink(inviteLinkCode!);
    if (ok) {
      setIsDialogOpened(false);
      navigate('/profile/view');
      await refetchUserProfile();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (
    (userKind !== UserKindEnum.Broker && userKind !== UserKindEnum.Manager) ||
    employers?.newEmployer?.id === employer?.id ||
    !employers?.newEmployer
  ) {
    return <PageNotFound />;
  }

  return (
    <MyDialog
      open={isDialogOpened}
      onClose={handleCloseDialog}
      dialogTitle='Accept Collaboration Invitation?'
      width='750'
    >
      <div className={styles.dialogDescription}>
        {employers?.oldEmployer?.id ? (
          <>
            Are you considering ending your employment with&nbsp;
            <strong>{employers.oldEmployer.name}</strong>&nbsp; to join&nbsp;
            <strong>{employers?.newEmployer.name}</strong>?&nbsp; Remember, once confirmed, this
            action is irreversible.
          </>
        ) : (
          <>
            <strong>{employers?.newEmployer.name}</strong>&nbsp; has invited you to join as a
            full-time employee (Agent). Once you confirm this invitation, you&apos;ll gain access to
            organization-specific features within the system.
          </>
        )}
      </div>
      <div className={styles.dialogButtons}>
        <MyButton
          data={{
            buttonName: 'Confirm',
            customWidth: '226px',
            variant: 'contained',
            buttonType: 'button',
          }}
          onClick={handleConfirmInvite}
        />
        <MyButton
          data={{
            buttonName: 'Cancel',
            customWidth: '226px',
            variant: 'outlined',
            styleType: 'cancel',
            buttonType: 'button',
          }}
          onClick={handleCloseDialog}
        />
      </div>
    </MyDialog>
  );
};
