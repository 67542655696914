import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  Typography,
  Link,
  InputBase,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  State,
  RequestMyModel,
  UserRequestsModel,
  UserRequestResponseType,
  NotificationTypeEnum,
} from '@/types';
import { appSetNotification } from '@/store';
import { propertyAdRequestProvider } from '@/providers';
import { useChangeQueryParams } from '@/hooks';
import { Loader, MyButton } from '@/components';
import IconArrow from './iconArrow.svg';
import { ReactComponent as IconSearch } from './iconSearch.svg';

import { COUNT_REQUESTS_PER_PAGE } from '../constants';
import { RequestsListItem } from './RequestsListItem';
import { Ad } from '../Ad';
import styles from './RequestsForMe.module.scss';

export const RequestsForMe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { id } = useParams<{ id: string }>();
  const _changeQueryParams = useChangeQueryParams('requests');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<UserRequestsModel[]>([]);
  const [page, setPage] = useState<RequestMyModel>();
  const [totalRequests, setTotalRequests] = useState(0);
  const [offset, setOffset] = useState(COUNT_REQUESTS_PER_PAGE);
  const [searchValue, setSearchValue] = useState('');

  const fetchRequests = async (isInitialLoad = true) => {
    const RequestsFilteringObj = [
      {
        field: 'name',
        value: searchValue.length > 0 ? searchValue : undefined,
      },
    ].filter((item) => item.value !== undefined);

    const newOffset = isInitialLoad
      ? queryParams.page
        ? (parseInt(queryParams.page as string) - 1) * COUNT_REQUESTS_PER_PAGE
        : 0
      : offset;

    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      limit: COUNT_REQUESTS_PER_PAGE,
      offset: newOffset,
      total: 0,
    };

    const { ok, data, status, message } = await propertyAdRequestProvider.getRequestsForMeById(
      id!,
      RequestsFilteringObj,
      newPaginationObj
    );

    if (ok && data) {
      const { items, total } = data as UserRequestResponseType;
      if (isInitialLoad) {
        setRequests(items);
        setTotalRequests(total);
      } else {
        setRequests((prevItems) => [...prevItems, ...items]);
        setOffset((prevOffset) => prevOffset + COUNT_REQUESTS_PER_PAGE);
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const getRequestData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await propertyAdRequestProvider.getAdForMeById(id!);
    if (ok && data) {
      setPage(data as RequestMyModel);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    await fetchRequests(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && id) {
      getRequestData();
    }
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSearchSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    fetchRequests(true);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.requestsContainer}>
        <div className={styles.pageName}>
          <Link onClick={handleBackClick} sx={{ textDecoration: 'none', color: '#2A3842' }}>
            <img src={IconArrow} alt='' style={{ cursor: 'pointer' }} />
          </Link>
          <Typography sx={{ fontSize: '28px', fontWeight: '500' }}>
            My Inbox <span style={{ fontWeight: 800, color: '#1650FF' }}>{totalRequests}</span>
          </Typography>
        </div>
        <form onSubmit={handleSearchSubmit} className={styles.search}>
          <Paper
            elevation={0}
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              maxWidth: 460,
              border: '1px solid #E9EEF1',
            }}
          >
            <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
              <IconSearch />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Search by name'
              inputProps={{ 'aria-label': 'search by name' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Paper>
          <MyButton
            data={{
              buttonName: 'Search',
              buttonType: 'submit',
              variant: 'contained',
              size: 'medium',
              customWidth: '138px',
            }}
          />
        </form>
        {requests &&
          (requests as UserRequestsModel[]).map((item, index) => (
            <RequestsListItem key={item.chatId + item.createdAt + index} item={item} page={page} />
          ))}
        {offset < totalRequests && (
          <MyButton
            data={{
              styleType: 'cancel',
              buttonName: 'Show more',
              variant: 'contained',
              customWidth: '610px',
            }}
            onClick={() => fetchRequests(false)}
          />
        )}
      </div>

      {!isMobile && (
        <div className={styles.adContainer}>
          <Ad page={page} />
        </div>
      )}
    </div>
  );
};
