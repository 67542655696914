import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useChangeQueryParams } from '@/hooks';
import { UserKindEnum, UserAgencyStateLicense } from '@/types';
import { CheckDetails, ContactDataForeign, AccountUpdate } from '../ThirdStep';
import { PersonalDateOfForeign, ContactDateOfForeign, ContactDateOfManager } from '../Utils/types';

export const ThirdStep = ({
  user,
  kind,
  contactDateOfForeign,
  setContactDateOfForeign,
  errorFields,
  setErrorFields,
  helperText,
  setError,
  error,
  personalDateOfForeign,
  setUser,
  newPhone,
  setNewPhone,
  newEmail,
  setNewEmail,
  contactDateOfManager,
  setContactDateOfManager,
  isChangeBroker,
  setIsChangeBroker,
}: {
  user?: UserAgencyStateLicense | null;
  kind: UserKindEnum;
  setContactDateOfForeign: (value: ContactDateOfForeign) => void;
  contactDateOfForeign: ContactDateOfForeign;
  errorFields: string[];
  setErrorFields: (fields: string[]) => void;
  error: boolean;
  helperText: string;
  setError: (error: boolean) => void;
  personalDateOfForeign: PersonalDateOfForeign;
  setUser: (user: UserAgencyStateLicense) => void;
  newPhone: string;
  setNewPhone: (value: string) => void;
  newEmail: string;
  setNewEmail: (value: string) => void;
  setContactDateOfManager: (value: ContactDateOfManager) => void;
  contactDateOfManager: ContactDateOfManager;
  isChangeBroker: boolean;
  setIsChangeBroker: (value: boolean) => void;
}) => {
  const changeQueryParams = useChangeQueryParams('signUp');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  useEffect(() => {
    changeQueryParams({ ...queryParams, step: 'three' });
  }, []);

  const isForeign = kind === UserKindEnum.Foreign;
  const isManager = kind === UserKindEnum.Manager;
  const isBroker = !isForeign && !isManager;
  return (
    <>
      {isChangeBroker ? (
        user && <AccountUpdate user={user} />
      ) : isBroker ? (
        user ? (
          <CheckDetails
            user={user}
            kind={kind}
            errorFields={errorFields}
            setErrorFields={setErrorFields}
            setUser={setUser}
            newPhone={newPhone}
            setNewPhone={setNewPhone}
            newEmail={newEmail}
            setNewEmail={setNewEmail}
            error={error}
          />
        ) : null
      ) : (
        <ContactDataForeign
          contactDateOfForeign={contactDateOfForeign}
          setContactDateOfForeign={setContactDateOfForeign}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          personalDateOfForeign={personalDateOfForeign}
          kind={kind}
          contactDateOfManager={contactDateOfManager}
          setContactDateOfManager={setContactDateOfManager}
          error={error}
          setError={setError}
        />
      )}
    </>
  );
};
