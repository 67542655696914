import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { CheckoutForm } from './CheckoutForm';

import { State, NotificationTypeEnum } from '@/types';
import { appSetNotification } from '@/store';
import { usersProvider } from '@/providers';
import { Loader } from '@/components';

export const Payment = () => {
  const { tariffPriceId } = useSelector(({ auth }: State) => auth);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const fetchClientSecret = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.getSubscription(tariffPriceId!);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setClientSecret(data.clientSecret);
      localStorage.setItem('orderId', data.orderId);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchStripe = async () => {
      const stripeInstance = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
          'pk_test_51PNtZzP0ssolsyviXqbAQLiZpb0rgYpg0f6Y8sw7VznLR7oBQVoSTnGlqXWuKaG6HFqS1OCDhbCCnafZ0SO3ufIM000mrd1B44'
      );
      setStripe(stripeInstance);
    };

    fetchStripe();
    fetchClientSecret();
  }, []);

  if (isLoading || !stripe || !clientSecret) {
    return <Loader />;
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret }}>
      <CheckoutForm />
    </Elements>
  );
};
