import { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, Stack, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  AdFieldStatusEnum,
  AdsFilteringType,
  AdsSortingType,
  AdsViewModeEnum,
  SortingOrderEnum,
  State,
  UserKindEnum,
} from '@/types';
import styles from './AdsListSelect.module.scss';
import ViewModeList from './viewModeList.svg';
import ViewModeMap from './viewModeMap.svg';
import { ReactComponent as CaretDown } from './caretDown.svg';
import { MyDialog, MyButton } from '@/components';
import UnpublishIcon from './svg/unpublishIcon.svg';
import ArchivehIcon from './svg/archiveIcon.svg';
import UpdateIcon from './svg/updateIcon.svg';
import AssignIcon from './svg/assignIcon.svg';
import { ResponsibleUser } from '../../AdForm/ResponsibleUser';

interface AdsListControlsProps {
  selectAll: boolean;
  handleSelectAllChange: () => void;
  handleUpdateList: () => void;
  selectedAds: string[];
  handleChangeStatusList: (value: AdFieldStatusEnum) => void;
  filteringObj?: AdsFilteringType;
  countSelected: number | null;
  handleAssign: (value: string) => void;
}

export const AdsListSelect = ({
  selectAll,
  handleUpdateList,
  handleSelectAllChange,
  selectedAds,
  handleChangeStatusList,
  filteringObj,
  countSelected,
  handleAssign,
}: AdsListControlsProps) => {
  const { id: userId, kind: userKind } = useSelector(({ auth }: State) => auth.user);
  const formMethods = useForm();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [creatorId, setCreatorId] = useState<string>(userId);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      justifyContent='space-between'
      sx={{ margin: '10px 0 0 13px' }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Checkbox
          icon={<span />}
          checkedIcon={<span className={styles.checked} />}
          onChange={handleSelectAllChange}
          checked={selectAll}
        />
        {!!selectedAds.length && (
          <Stack direction={'row'} alignItems={'center'} spacing={3} className={styles.listActions}>
            {userKind !== UserKindEnum.Agency
              ? (filteringObj?.status === AdFieldStatusEnum.Unpublished ||
                  filteringObj?.status === AdFieldStatusEnum.Archived) && (
                  <div
                    className={styles.buttonWrap}
                    onClick={() => handleChangeStatusList(AdFieldStatusEnum.Published)}
                  >
                    <img src={ArchivehIcon} alt='Archive' className={styles.buttonIcon} />
                    Publish
                  </div>
                )
              : (filteringObj?.status === AdFieldStatusEnum.Unpublished ||
                  filteringObj?.status === AdFieldStatusEnum.Archived) && (
                  <div className={styles.buttonWrap} onClick={() => setOpenDialog(true)}>
                    <img src={AssignIcon} alt='Assign' className={styles.buttonIcon} />
                    Assign
                  </div>
                )}
            {(filteringObj?.status === AdFieldStatusEnum.Published ||
              filteringObj?.status === AdFieldStatusEnum.Archived) && (
              <div
                className={styles.buttonWrap}
                onClick={() => handleChangeStatusList(AdFieldStatusEnum.Unpublished)}
              >
                <img src={UnpublishIcon} alt='Unpublish' className={styles.buttonIcon} />
                Unpublish
              </div>
            )}
            {(filteringObj?.status === AdFieldStatusEnum.Published ||
              filteringObj?.status === AdFieldStatusEnum.Unpublished) && (
              <div
                className={styles.buttonWrap}
                onClick={() => handleChangeStatusList(AdFieldStatusEnum.Archived)}
              >
                <img src={ArchivehIcon} alt='Archive' className={styles.buttonIcon} />
                Archive
              </div>
            )}
            {filteringObj?.status === AdFieldStatusEnum.Published && (
              <div className={styles.buttonWrap} onClick={handleUpdateList}>
                <img src={UpdateIcon} alt='Update' className={styles.buttonIcon} />
                Update
              </div>
            )}
          </Stack>
        )}
      </Stack>
      {countSelected && (
        <Typography sx={{ marginRight: '15px', color: '#1650FF', fontSize: '18px' }}>
          {'Selected '}
          <strong>{countSelected}</strong>
          {' ads'}
        </Typography>
      )}
      <MyDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        dialogTitle='Responsible for announcement'
        width='750'
      >
        <FormProvider {...formMethods}>
          <ResponsibleUser withTitle={false} setCreatorId={setCreatorId} />
        </FormProvider>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '351px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => {
              handleAssign(creatorId);
              setOpenDialog(false);
            }}
          />
        </div>
      </MyDialog>
    </Stack>
  );
};
