export const messageContainerStyle = () => {
  return {
    alignSelf: 'flex-start',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '65%',
    minWidth: '340px',
    clear: 'both',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
  };
};

export const messageWrapperStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    margin: '0 5px 10px',
    float: 'left',
    flexShrink: '0',
  };
};

export const messageDetailStyle = () => {
  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  };
};

export const messageFileContainerStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    display: 'flex',
    alignSelf: 'flex-start',
  };
};

export const messageFileWrapperStyle = (context) => {
  return {
    display: 'inline-block',
    borderRadius: '4px 14px 14px 14px',
    backgroundColor: `${context.theme.backgroundColor.lightBlue3}`,
    color: `${context.theme.color.primary}`,
    padding: '10px 16px 9px',
    alignSelf: 'flex-start',
    maxWidth: '100%',
    '.message__file': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '0 0',
      textDecoration: 'none',
      color: `${context.theme.color.primary}`,
      maxWidth: '100%',
      fontSize: '14px',
      '&:visited, &:active, &:hover': {
        color: `${context.theme.color.primary}`,
        textDecoration: 'none',
      },
      '> p': {
        margin: '0',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        textAlign: 'left',
        width: '100%',
        fontSize: '14px',
        marginLeft: '8px',
      },
    },
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '25px',
    gap: '2px',
  };
};

export const messageReactionsWrapperStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '5px',
    paddingTop: '6px',
  };
};

export const iconStyle = (img, context) => {
  return {
    width: '24px',
    height: '24px',
    display: 'inline-block',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.color.primary}`,
    flexShrink: '0',
  };
};
