import { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { AdModel, NewBuildingModel } from '@/types';
import { Picture } from './Picture';
import { Section } from './Section';
import {
  BalconyIcon,
  MaidsRoomIcon,
  SharedPoolIcon,
  BuiltInWardrobesIcon,
  ChildrenPlayAreaIcon,
  SharedSpaIcon,
  SharedGymIcon,
  StudyIcon,
  CentralAcIcon,
  CoveredParkingIcon,
  BarbecueAreaIcon,
  ViewOfWaterIcon,
  ViewOfLandmarkIcon,
  PetsAllowedIcon,
  ConciergeIcon,
  PublicParkingIcon,
  KitchenAppliancesIcon,
  LobbyInBuildingIcon,
  MaidServiceIcon,
  PetsNotAllowedIcon,
  PrivateGardenIcon,
  PrivateGymIcon,
  PrivatePoolIcon,
  SecurityIcon,
  TennisCourtsIcon,
  ViewOfParkIcon,
  ViewOfCityIcon,
  PrivateSpaIcon,
  LaundryFacilityIcon,
} from '@/assets/pdf';

export function FeaturesSection({ data }: { data?: AdModel | NewBuildingModel }) {
  const feature = useMemo(() => {
    return Object.entries(propertyFeatures)
      .filter(([key]) => (data as any)[key])
      .map(([key, value]) => {
        return (
          <View
            key={key} 
            wrap={false}
            style={{
              width: '450px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: '140px',
            }}
          >
            <Picture src={value.src} style={{ height: 50, marginRight: '16px' }} />
            <Text style={{ fontSize: 40 }}>{value.name}</Text>
          </View>
        );
      });
  }, [data]);

  if (!feature || feature.length === 0) return null;

  return (
    <Section title='Amenities'>
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'column',
          height: '350px',
          gap: '24px'
        }}
      >
        {feature}
      </View>
    </Section>
  );
}

interface Feature {
  name: string;
  src: string;
}

type PropertyFeaturesBase = {
  [K in keyof AdModel]: Feature;
};

const propertyFeatures: Partial<PropertyFeaturesBase> = {
  balcony: { name: 'Balcony', src: BalconyIcon },
  maidsRoom: { name: 'Maids room', src: MaidsRoomIcon },
  sharedPool: { name: 'Shared pool', src: SharedPoolIcon },
  builtInWardrobes: { name: 'Built in wardrobes', src: BuiltInWardrobesIcon },
  childrenPlayArea: { name: `Children's play area`, src: ChildrenPlayAreaIcon },
  sharedSpa: { name: 'Shared spa', src: SharedSpaIcon },
  sharedGym: { name: 'Shared gym', src: SharedGymIcon },
  study: { name: 'Study', src: StudyIcon },
  centralAc: { name: 'Central A/C', src: CentralAcIcon },
  coveredParking: { name: 'Covered parking', src: CoveredParkingIcon },
  barbecueArea: { name: 'Barbecue area', src: BarbecueAreaIcon },
  viewOfWater: { name: 'Water view', src: ViewOfWaterIcon },
  viewOfLandmark: { name: 'Landmark view', src: ViewOfLandmarkIcon },
  petsAllowed: { name: 'Pets allowed', src: PetsAllowedIcon },
  concierge: { name: 'Concierge', src: ConciergeIcon },
  publicParking: { name: 'Public parking', src: PublicParkingIcon },
  kitchenAppliances: { name: 'Kitchen tools', src: KitchenAppliancesIcon },
  lobbyInBuilding: { name: 'Lobby in building', src: LobbyInBuildingIcon },
  maidService: { name: 'Maid service', src: MaidServiceIcon },
  petsNotAllowed: { name: 'Pets not allowed', src: PetsNotAllowedIcon },
  privateGarden: { name: 'Private garden', src: PrivateGardenIcon },
  privateGym: { name: 'Private gym', src: PrivateGymIcon },
  privatePool: { name: 'Private pool', src: PrivatePoolIcon },
  security: { name: 'Security', src: SecurityIcon },
  tennisCourts: { name: 'Tennis courts', src: TennisCourtsIcon },
  viewOfPark: { name: 'Park view', src: ViewOfParkIcon },
  viewOfCity: { name: 'City view', src: ViewOfCityIcon },
  privateSpa: { name: 'Private spa', src: PrivateSpaIcon },
  laundryFacility: { name: 'Laundry facility', src: LaundryFacilityIcon },
};
