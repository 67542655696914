import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import { User } from '../types/types';

interface UserTableProps {
  users: User[];
  total: number;
  handleMenuClick: (event: React.MouseEvent, userId: any) => void;
}

const StyledTableCell = styled(TableCell)({
  border: 0,
  borderRight: '1px solid #E9EEF1',
  '&:last-child': {
    borderRight: 'none',
  },
  verticalAlign: 'middle',
  '&.header-cell': {
    fontWeight: 600,
    fontSize: '15px'
  },
  '&.all-cell': {
    minWidth: '270px'
  },
  '&.registration-cell': {
    maxWidth: '150px'
  },
  '&.social-cell': {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
});

const TableCellWithMenu = styled(StyledTableCell)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: 2px solid #c5dfff;
  border-radius: 14px;
`;

const UserCell = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 10px;
  & svg {
    margin-left: 5px;
    vertical-align: text-top;
  }
`;

const Avatar = styled('img')`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

const UserName = styled('div')`
  font-weight: bold;
  font-size: 18px;
`;

const UserRole = styled('div')`
  color: rgba(42, 56, 66, 1);
  font-size: 16px;
`;

const UserAgency = styled('span')`
  color: #01439b;
`;

const UserDate = styled('span')`
  font-size: 15px;
`;

const UserLink = styled('a')`
  color: #1650ff;
  &:hover {
    text-decoration: none;
  }
  &:visited,
  &:focus {
    color: #1650ff;
  }
`;

const StyledTableContainer = styled(TableContainer)({
  background: 'transparent',
  boxShadow: 'none',
  maxWidth: '1003px',
  overflowX: 'auto',
});

const StyledTableRow = styled(TableRow)({
  border: '1px solid #E9EEF1',
  background: 'inherit',
});

const StyledTableHead = styled(TableHead)({
  background: '#fff',
});

const formatDate = (value: string | null) =>
  value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

const VerificationRequestsTable: React.FC<UserTableProps> = ({ users, total, handleMenuClick }) => {
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell className={`header-cell all-cell`}>All requests({total})</StyledTableCell>
            <StyledTableCell className={`header-cell registration-cell`}>Date of registration</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Email or Phone num.</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Social networks</StyledTableCell>
            <StyledTableCell className={`header-cell`}></StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {users.map((user, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <UserCell>
                  {user.avatar && <Avatar src={user.avatar} />}
                  <UserInfo>
                    <UserName>
                      {user.name}
                      {user.status === 'blocked' && <BlockIcon fontSize='small' />}
                    </UserName>
                    <UserRole>
                      {user.kind}
                      {user.employer.name && (
                        <>
                          {' '}
                          | <UserAgency>{user.employer.name}</UserAgency>
                        </>
                      )}
                    </UserRole>
                  </UserInfo>
                </UserCell>
              </StyledTableCell>
              <StyledTableCell className={`registration-cell`}>
                <UserDate>{formatDate(user.createdAt)}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate>{user.email || user.phone}</UserDate>
              </StyledTableCell>
              <StyledTableCell className={`social-cell`}>
                <UserDate>
                  {user.links.map((link, index) => (
                    <React.Fragment key={index}>
                      <UserLink target='_blank' href={link}>
                        {link}
                      </UserLink>
                      {index < user.links.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </UserDate>
              </StyledTableCell>
              <TableCellWithMenu>
                <StyledIconButton
                  aria-label='more'
                  aria-controls='user-menu'
                  aria-haspopup='true'
                  onClick={(event) => handleMenuClick(event, user.id)}
                  style={{ float: 'right' }}
                >
                  <MoreVertIcon />
                </StyledIconButton>
              </TableCellWithMenu>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default VerificationRequestsTable;
