import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import {
  NewBuildingModel,
  BuildingsDataType,
  CreateNewPlanType,
  AdFieldBedsEnum,
  NotificationTypeEnum,
} from '@/types';
import { Loader } from '@/components';
import { Plans } from './Plans';
import { Title } from '../styled';
import styles from '../Ad.module.scss';

export const PlansBlock = ({
  page,
  isAddBuilding,
  setIsAddBuilding,
  plan,
}: {
  page: NewBuildingModel;
  plan: CreateNewPlanType;
  isAddBuilding: boolean;
  setIsAddBuilding: (value: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalUnits, setTotalUnits] = useState<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (plan?.units) {
      setTotalUnits(plan?.units?.length);
    }
  }, []);

  return (
    <>
      <Title>{totalUnits > 0 ? `${totalUnits} Units available` : 'Available units'}</Title>
      <Plans page={page} plan={plan} />
    </>
  );
};
