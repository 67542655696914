import { Box } from '@mui/material';
import { AdModel, AdMyModel, PaginationType, State } from '@/types';
import styles from './Favorites.module.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Loader, Pagination } from '@/components';
import { EmptyList } from './EmptyList';
import { AdsListItem } from '@/components/Ads/AdsListItem';
import { favoritesProvider } from '@/providers/favoritesProvider';
import { COUNT_ADS_PER_PAGE } from '@/components/Ads/constants';
import { useChangeQueryParams } from '@/hooks';
import queryString from 'query-string';
import { AdsGetFavoritesResponseType } from '@/types/responses';

export const Favorites = () => {
  const { user } = useSelector(({ auth }: State) => auth);
  const [ads, setAds] = useState<AdModel[] | AdMyModel[]>([]);
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const changeQueryParams = useChangeQueryParams('');
  const [isLoadingGetAds, setIsLoadingGetAds] = useState(false);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  const getAds = useCallback(async () => {
    setIsLoadingGetAds(true);
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };
    try {
      const { data } = await favoritesProvider.getFavorites(newPaginationObj);
      const { items, total } = data as AdsGetFavoritesResponseType;
      setAds(items ?? []);
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } catch (err) {
      console.log(err);
    }
    setIsLoadingGetAds(false);
  }, []);

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  useEffect(() => {
    void getAds();
  }, []);

  if (isLoadingGetAds) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Box className={styles.title}>
        <span className={styles.text}>Favorites</span>
      </Box>
      {!ads?.length ? (
        <EmptyList />
      ) : (
        <div className={styles.adsList}>
          {(ads as AdModel[]).map((item) => (
            <AdsListItem key={item.id} item={item} tariffPlan={user.tariffPlan?.name} />
          ))}
        </div>
      )}
      {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE ? (
        <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
      ) : null}
    </div>
  );
};
