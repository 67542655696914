import { AdFormModel, AdFieldMultilistingEnum, CompletionStatusEnum, AdFieldCurrencyEnum } from '@/types';


export const getInitAdFormData = (data?: AdFormModel) => {
  return {
    stateData: {
      activityKind: data?.activityKind || undefined,
      transactionType: data?.transactionType || undefined,
      propertyKind: data?.propertyKind || undefined,
      addressEn: data?.addressEn || undefined,
      nameEn: data?.nameEn || undefined,
      placeId: data?.placeId || undefined,
      coordinatesLat: data?.coordinatesLat || undefined,
      coordinatesLong: data?.coordinatesLong || undefined,
      locationId: data?.locationId || undefined,

      currency: data?.currency || undefined,
      price: data?.price || undefined,
      multilisting: data?.multilisting || AdFieldMultilistingEnum.Covered,
      topUp: data?.topUp || false,
      topUpConditions: data?.topUpConditions || undefined,
      beds: data?.beds || undefined,
      builtUpArea: data?.builtUpArea || undefined,
      view: data?.view || undefined,
      floor: data?.floor || undefined,
      occupancy: data?.occupancy || undefined,
      furniture: data?.furniture || undefined,
      renovation: data?.renovation || undefined,
      baths: data?.baths || undefined,

      privateGarden: data?.privateGarden || undefined,
      privateGym: data?.privateGym || undefined,
      privatePool: data?.privatePool || undefined,
      privateSpa: data?.privateSpa || undefined,
      sharedGym: data?.sharedGym || undefined,
      sharedPool: data?.sharedPool || undefined,
      sharedSpa: data?.sharedSpa || undefined,
      balcony: data?.balcony || undefined,
      barbecueArea: data?.barbecueArea || undefined,
      childrenPlayArea: data?.childrenPlayArea || undefined,
      coveredParking: data?.coveredParking || undefined,
      lobbyInBuilding: data?.lobbyInBuilding || undefined,
      maidsRoom: data?.maidsRoom || undefined,
      publicParking: data?.publicParking || undefined,
      tennisCourts: data?.tennisCourts || undefined,
      study: data?.study || undefined,
      kitchenAppliances: data?.kitchenAppliances || undefined,
      builtInWardrobes: data?.builtInWardrobes || undefined,
      centralAc: data?.centralAc || undefined,
      concierge: data?.concierge || undefined,
      laundryFacility: data?.laundryFacility || undefined,
      maidService: data?.maidService || undefined,
      petsAllowed: data?.petsAllowed || undefined,
      petsNotAllowed: data?.petsNotAllowed || undefined,
      security: data?.security || undefined,
      description: data?.description || undefined,
      propertyAdMedia: data?.propertyAdMedia || [],
    }
  }
}

export const clearSecondPageForm = (setValue: any) => {
  setValue('completionStatus', null);
  setValue('currency', AdFieldCurrencyEnum.AED);
  setValue('price', null);
  setValue('sellingPrice', null);
  setValue('handover', null);
  setValue('postHandover', null);
  setValue('toSeller', null);
  setValue('toDeveloper', null);
  setValue('multilisting', undefined);
  setValue('topUp', false);
  setValue('topUpConditions', '');
  setValue('beds', null);
  setValue('builtUpArea', null);
  setValue('view', []);
  setValue('floor', null);
  setValue('occupancy', null);
  setValue('rentedTill', null);
  setValue('furniture', null);
  setValue('renovation', null);
  setValue('baths', null);

  setValue('privateGarden', false);
  setValue('privateGym', false);
  setValue('privatePool', false);
  setValue('privateSpa', false);
  setValue('sharedGym', false);
  setValue('sharedPool', false);
  setValue('sharedSpa', false);
  setValue('balcony', false);
  setValue('barbecueArea', false);
  setValue('childrenPlayArea', false);
  setValue('coveredParking', false);
  setValue('lobbyInBuilding', false);
  setValue('maidsRoom', false);
  setValue('publicParking', false);
  setValue('tennisCourts', false);
  setValue('study', false);
  setValue('kitchenAppliances', false);
  setValue('builtInWardrobes', false);
  setValue('centralAc', false);
  setValue('concierge', false);
  setValue('laundryFacility', false);
  setValue('maidService', false);
  setValue('petsAllowed', false);
  setValue('petsNotAllowed', false);
  setValue('security', false);
  setValue('description', undefined);
};

export const clearAllInformation = (setValue: any) => {
  setValue('activityKind', null);
  setValue('transactionType', null);
  setValue('defaultRentFrequency', null);
  setValue('priceYearly', null);
  setValue('priceMonthly', null);
  setValue('priceWeekly', null);
  setValue('priceDaily', null);
  setValue('propertyKind', null);
  setValue('addressEn', null);
  setValue('nameEn', null);
  setValue('coordinatesLat', null);
  setValue('coordinatesLong', null);
  setValue('locationId', null);
  clearSecondPageForm(setValue);
};