import { Select, MenuItem } from '@mui/material';
import classNames from 'classnames';

import { AdsSortingType, AdsViewModeEnum, SortingOrderEnum } from '@/types';
import styles from './AdsListControls.module.scss';
import ViewModeList from "./viewModeList.svg";
import ViewModeMap from "./viewModeMap.svg";
import { ReactComponent as CaretDown } from './caretDown.svg';

interface AdsListControlsProps {
  viewMode: AdsViewModeEnum;
  setViewMode: (value: AdsViewModeEnum) => void;
  sortingFields: string[];
  sortingObj: AdsSortingType;
  onChangeSort: (value: AdsSortingType) => void;
}

export const AdsListControls = ({
  viewMode,
  setViewMode,
  sortingFields,
  sortingObj: { field: fieldValue, order: orderValue },
  onChangeSort,
}: AdsListControlsProps) => {
  const handleChangeSorting = (field: string) => {
    const order =
      fieldValue !== field
        ? SortingOrderEnum.Asc
        : orderValue === SortingOrderEnum.Asc
          ? SortingOrderEnum.Desc
          : SortingOrderEnum.Asc;
    onChangeSort({ field, order });
  };

  const handleChangeViewMode = () => setViewMode(viewMode === AdsViewModeEnum.Map ? AdsViewModeEnum.List : AdsViewModeEnum.Map);

  const viewModeLabel = viewMode === AdsViewModeEnum.Map ? AdsViewModeEnum.List : AdsViewModeEnum.Map;
  const viewModeImg = viewMode === AdsViewModeEnum.Map ? ViewModeList : ViewModeMap;

  return (
    <div className={styles.listControls}>
      <div className={styles.sortControl}>
        <div>Sort by:</div>
        <Select
          value={fieldValue}
          className={classNames(styles.sortSelect, {
            [styles.sortOrderAsc]: orderValue === SortingOrderEnum.Asc,
            [styles.sortOrderDesc]: orderValue === SortingOrderEnum.Desc,
          })}
          IconComponent={({ className }) => (
            <CaretDown
              className={classNames(styles.sortSelectCaret, {
                [styles.sortCaretUp]: className.toString().includes('iconOpen'),
                [styles.sortCaretDown]: !className.toString().includes('iconOpen'),
              })}
            />
          )}
        >
          {sortingFields.map((field, index) => (
            <MenuItem
              key={field}
              value={field}
              divider={index < sortingFields.length - 1 && true}
              className={classNames(styles.sortSelectItem, {
                [styles.sortItemOrderAsc]:
                  fieldValue !== field ||
                  (fieldValue === field && orderValue === SortingOrderEnum.Desc),
                [styles.sortItemOrderDesc]:
                  fieldValue === field && orderValue === SortingOrderEnum.Asc,
              })}
              onClick={() => handleChangeSorting(field)}
            >
              {field}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.viewModeControl} onClick={handleChangeViewMode}>
        <img src={viewModeImg} alt={`Go to ${viewModeLabel}`} />
        {viewModeLabel}
      </div>
    </div>
  );
};
