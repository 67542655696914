/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, KeyboardEvent, useState, useEffect } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Libraries, useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';

import {
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  OffPlanPropertyKind,
  FilterAddressType,
  DevelopmentStatusEnum,
  NewBuildingsFilteringType,
  FeaturesType,
} from '@/types';
import { classifyPrice } from '@/utils';
import { MyButton, FormSelectCustom } from '@/components';

import { SelectItemButton } from './SelectItemButton';
import { DoubleButtonBlock } from './DoubleButtonBlock';
import styles from './OffPlanFilters.module.scss';
import resetFilters from './resetFilters.svg';
import moreFilters from './moreFilters.svg';
import lessFilters from './lessFilters.svg';
import { ReactComponent as CaretDown } from './caretDown.svg';
import { useUserSettings } from '@/hooks/useUserSettings';
import { ReactComponent as IconSearch } from './iconSearch.svg';
import { language } from '../../AccountManagement/utils/fakeData';

const googleLibraries: Libraries = ['places'];

const generateHandoverUsingQuartersAndYears = ({
  handoverValue,
  quarters,
  years,
}: {
  handoverValue?: string | null;
  quarters?: string | null;
  years?: string | null;
}) => {
  return `${quarters?.trim() || ''}/${years?.trim() || ''}`;
};

const ITEM_HEIGHT = 72;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const OffPlanFilters = ({
  filteringObj,
  onChangeFilters,
  handleReqForMeBtnClick,
  handleMyReqBtnClick,
  btnOffPlan,
  isForeign,
}: {
  filteringObj: NewBuildingsFilteringType;
  onChangeFilters: (value: NewBuildingsFilteringType, shouldSendRequest?: boolean) => void;
  btnOffPlan: boolean;
  isForeign: boolean;
  handleReqForMeBtnClick: () => void;
  handleMyReqBtnClick: () => void;
}) => {
  const { userSettings } = useUserSettings();
  const emptyFilteringObj: NewBuildingsFilteringType = {
    status: 'published',
    address: [],
    constructionType: undefined,
    priceMin: undefined,
    priceMax: undefined,
    beds: undefined,
    buildUpAreaFrom: undefined,
    buildUpAreaTo: undefined,
    developmentStatus: undefined,
    numberOfFloors: undefined,
    features: undefined,
    handover: undefined,
    availableUnits: undefined,
    offPlanStatus: undefined,
    quarters: undefined,
    years: undefined,
    paymentPlan: false,
    amenitiesFeatures: undefined,
    showFilters: undefined,
  };
  const {
    address,
    constructionType,
    priceMin = 0,
    priceMax = 0,
    beds,
    buildUpAreaFrom = 0,
    buildUpAreaTo = 0,
    developmentStatus,
    numberOfFloors = '',
    features,
    handover,
    availableUnits,
    offPlanStatus,
    quarters,
    years,
    paymentPlan,
    amenitiesFeatures,
    showFilters,
  } = filteringObj;

  const developmentStatusValues = [
    { label: 'In progress', value: DevelopmentStatusEnum.IN_PROGRESS },
    { label: 'Completed', value: DevelopmentStatusEnum.COMPLETED },
    { label: 'Stopped', value: DevelopmentStatusEnum.STOPPED },
    { label: 'Planned', value: DevelopmentStatusEnum.PLANNED },
  ];
  const completionStatusValues = [
    { label: 'Ready', value: CompletionStatusEnum.Ready },
    { label: 'Off-Plan', value: CompletionStatusEnum.OffPlan },
  ];
  const constructionTypeValues = [
    { label: 'Apartment', value: OffPlanPropertyKind.Apartments },
    { label: 'Villa', value: OffPlanPropertyKind.Villa },
    { label: 'Townhouse', value: OffPlanPropertyKind.Townhouse },
    { label: 'Penthouse', value: OffPlanPropertyKind.Penthouse },
    { label: 'Hotel Apartment', value: OffPlanPropertyKind.HotelApartment },
    { label: 'Residential Building', value: OffPlanPropertyKind.ResidentialBuilding },
  ];
  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];
  const quarterValues = [
    { label: '1Q', value: '1Q' },
    { label: '2Q', value: '2Q' },
    { label: '3Q', value: '3Q' },
    { label: '4Q', value: '4Q' },
  ];
  const unitsForSaleValues = [
    { label: 'All', value: false },
    { label: 'Available units', value: true },
  ];

  const amenities = [
    { key: 'cityView', name: 'City view' },
    { key: 'landmarkView', name: 'Landmark view' },
    { key: 'parkView', name: 'Park view' },
    { key: 'waterView', name: 'Water view' },
    { key: 'privateGarden', name: 'Private garden' },
    { key: 'privateGym', name: 'Private gym' },
    { key: 'privatePool', name: 'Private pool' },
    { key: 'privateSpa', name: 'Private spa' },
    { key: 'sharedGym', name: 'Shared gym' },
    { key: 'sharedPool', name: 'Shared pool' },
    { key: 'sharedSpa', name: 'Shared spa' },
    { key: 'balcony', name: 'Balcony' },
    { key: 'barbecueArea', name: 'Barbecue area' },
    { key: 'childrenPlayArea', name: "Children's play area" },
    { key: 'coveredParking', name: 'Covered parking' },
    { key: 'lobbyInBuilding', name: 'Lobby in building' },
    { key: 'maidsRoom', name: 'Maids room' },
    { key: 'publicParking', name: 'Public parking' },
    { key: 'tennisCourts', name: 'Tennis courts' },
    { key: 'study', name: 'Study' },
    { key: 'kitchenAppliances', name: 'Kitchen appliances' },
    { key: 'buildInWardrobes', name: 'Built in wardrobes' },
    { key: 'centralAC', name: 'Central A/C' },
    { key: 'concierge', name: 'Concierge' },
    { key: 'laundryFacility', name: 'Laundry facility' },
    { key: 'maidService', name: 'Maid service' },
    { key: 'security', name: 'Security' },
  ];

  const [addressAutocomplete, setAddressAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [inputAddressValue, setInputAddressValue] = useState<string>(address?.[0]?.addressEn || '');
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);
  const [handoverValue, setHandoverValue] = useState<string>('');
  const [yearsValues, setYearsValues] = useState<string[]>([]);
  const [featuresName, setFeaturesName] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 8 }, (_, i) => (currentYear + i).toString());
    setYearsValues(years);

    const value =
      features &&
      Object.keys(features as any).reduce((names: string[], key: string) => {
        if (features[key as keyof FeaturesType]) {
          const amenity = amenities.find((a) => a.key === key);
          if (amenity) {
            names.push(amenity.name);
          }
        }
        return names;
      }, []);

    const featuresObj: Partial<FeaturesType> = {};
    const selectedFeatures: string[] = value || [];

    selectedFeatures.forEach((featureName) => {
      const feature = amenities.find((a) => a.name === featureName);
      if (feature) {
        (featuresObj as any)[feature.key] = true;
      }
    });
    setFeaturesName(selectedFeatures);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    libraries: googleLibraries,
  });

  const bedsLabel = beds === AdFieldBedsEnum.Studio ? 'Studio' : beds ? `${beds} Beds` : '';
  const bedsBathsText = !bedsLabel ? 'Beds' : bedsLabel ? `${bedsLabel}` : bedsLabel;
  const handoverLabel =
    !quarters && !years
      ? 'Handover'
      : quarters && years
        ? `${quarters}/${years}`
        : quarters
          ? `${quarters}/`
          : years
            ? years
            : '';
  const areaLabel =
    !buildUpAreaFrom && !buildUpAreaTo
      ? `Area (${userSettings?.areaUnits.toLowerCase()})`
      : `${buildUpAreaFrom} - ${buildUpAreaTo}`;

  const priceLabel =
    !priceMin && !priceMax
      ? `Price (${userSettings?.currency})`
      : `${classifyPrice(priceMin)} - ${classifyPrice(priceMax)}`;

  const handleChangeAreaMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      buildUpAreaFrom: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangeAreaMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      buildUpAreaTo: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMin: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMax: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangeNumberOfFloors = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters(
      {
        ...filteringObj,
        numberOfFloors: isNaN(parsedInt) || parsedInt < 0 ? undefined : parsedInt,
      },
      true
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocomplete.setOptions({
      componentRestrictions: { country: ['ae'] },
    });
    setAddressAutocomplete(autocomplete);
  };

  const handlePlaceChanged = () => {
    if (addressAutocomplete) {
      const place = addressAutocomplete.getPlace();
      const coordinatesLat = place?.geometry?.location?.lat();
      const coordinatesLong = place?.geometry?.location?.lng();
      if (!coordinatesLat || !coordinatesLong) {
        return;
      }

      const addressObject: FilterAddressType = {
        coordinatesLat: String(coordinatesLat),
        coordinatesLong: String(coordinatesLong),
        placeId: place.place_id,
        nameEn: place.name,
        addressEn: place.formatted_address,
      };
      setInputAddressValue(place?.formatted_address || '');
      onChangeFilters({ ...filteringObj, address: [{ ...addressObject }] }, true);
    }
  };

  const handleAddressPressEnter = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (key !== 'Enter') {
      return;
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: inputAddressValue }).then(
      (response) => {
        const place = response.results[0];
        const coordinatesLat = place?.geometry?.location?.lat();
        const coordinatesLong = place?.geometry?.location?.lng();
        if (!coordinatesLat || !coordinatesLong) {
          return;
        }

        onChangeFilters(
          {
            ...filteringObj,
            address: [
              {
                coordinatesLat: String(coordinatesLat),
                coordinatesLong: String(coordinatesLong),
                placeId: place.place_id,
                nameEn: undefined,
                addressEn: place.formatted_address || '',
              },
            ],
          },
          true
        );
      },
      (error) => {
        onChangeFilters(
          {
            ...filteringObj,
            address: [
              {
                coordinatesLat: '',
                coordinatesLong: '',
                addressEn: inputAddressValue || '',
              },
            ],
          },
          true
        );
        console.error('Error GoogleMap (Geocoder response)', error);
      }
    );
  };

  const handleInputAddressClear = () => {
    setInputAddressValue('');
    onChangeFilters({ ...filteringObj, address: [] }, true);
  };

  const handleResetFilters = () => {
    setInputAddressValue('');
    onChangeFilters({ ...emptyFilteringObj }, true);
  };

  const handleChangeQuarters = (value: string) => {
    const newValue = quarters !== value ? value : undefined;
    const newHandoverValue = generateHandoverUsingQuartersAndYears({
      handoverValue,
      quarters: newValue,
      years,
    });
    onChangeFilters(
      {
        ...filteringObj,
        quarters: newValue,
        handover: newHandoverValue,
      },
      newHandoverValue.length > 6
    );
  };

  const handleChangeYears = (value: string) => {
    const newValue = years !== value ? value : undefined;
    const newHandoverValue = generateHandoverUsingQuartersAndYears({
      handoverValue,
      quarters,
      years: newValue,
    });
    onChangeFilters(
      {
        ...filteringObj,
        years: newValue,
        handover: newHandoverValue,
      },
      newHandoverValue.length > 6
    );
  };

  const handleSearchSubmit = () => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: inputAddressValue }).then(
      (response) => {
        const place = response.results[0];
        const coordinatesLat = place?.geometry?.location?.lat();
        const coordinatesLong = place?.geometry?.location?.lng();
        if (!coordinatesLat || !coordinatesLong) {
          return;
        }

        onChangeFilters(
          {
            ...filteringObj,
            address: [
              {
                coordinatesLat: String(coordinatesLat),
                coordinatesLong: String(coordinatesLong),
                placeId: place.place_id,
                nameEn: undefined,
                addressEn: place.formatted_address || '',
              },
            ],
          },
          true
        );
      },
      (error) => {
        onChangeFilters(
          {
            ...filteringObj,
            address: [
              {
                coordinatesLat: '',
                coordinatesLong: '',
                addressEn: inputAddressValue || '',
              },
            ],
          },
          true
        );
        console.error('Error GoogleMap (Geocoder response)', error);
      }
    );
  };

  const handleChangeFeatures = (event: SelectChangeEvent<typeof featuresName>) => {
    const value = event.target.value;
    const featuresObj: Partial<FeaturesType> = {};
    const selectedFeatures: string[] = typeof value === 'string' ? value.split(',') : value;

    selectedFeatures.forEach((featureName) => {
      const feature = amenities.find((a) => a.name === featureName);
      if (feature) {
        (featuresObj as any)[feature.key] = true;
      }
    });
    setFeaturesName(selectedFeatures);
    const string = selectedFeatures.join(', ');

    onChangeFilters(
      {
        ...filteringObj,
        features: value.length === 0 ? undefined : (featuresObj as FeaturesType),
        amenitiesFeatures: value.length === 0 ? undefined : string,
      },
      true
    );
  };

  const handleChangePaymentPlan = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onChangeFilters(
      {
        ...filteringObj,
        paymentPlan: value,
      },
      true
    );
  };

  const handleShowFilters = () => {
    onChangeFilters(
      {
        ...filteringObj,
        showFilters: filteringObj.showFilters === undefined ? true : undefined,
      },
      true
    );
  };

  const handleDevelopersSearchSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // console.log(e.target.value)
  };

  const shouldBeShownResetFilters =
    !!address?.length ||
    constructionType ||
    priceMin ||
    priceMax ||
    beds ||
    buildUpAreaFrom ||
    buildUpAreaTo ||
    developmentStatus ||
    numberOfFloors ||
    features ||
    handover ||
    availableUnits ||
    paymentPlan ||
    offPlanStatus;

  const constructionTypeValueItem = constructionTypeValues.find(
    (item) => item.value === constructionType
  );
  const propertyKindValueLabel = constructionTypeValueItem?.label || 'Unit type';

  const developmentStatusItem = developmentStatusValues.find(
    (item) => item.value === developmentStatus
  );
  const developmentStatusLabel = developmentStatusItem?.label || 'Dev. status';

  const featuresLabel = featuresName.length > 0 ? featuresName : ['Features'];

  const unitsForSaleItem = unitsForSaleValues.find((item) => item.value === availableUnits);
  const unitsForSaleLabel = unitsForSaleItem?.label || 'Units for Sale';

  const sortLanguage = language.map((item) => item.label).sort((a, b) => a.localeCompare(b));

  return (
    <div className={styles.filtersOffPlanContainer}>
      {/* <DoubleButtonBlock
        handleReqForMeBtnClick={handleReqForMeBtnClick}
        handleMyReqBtnClick={handleMyReqBtnClick}
        btnOffPlan={btnOffPlan}
      /> */}
      {btnOffPlan ? (
        <div>
          <div className={styles.row}>
            {isLoaded && (
              <Autocomplete
                className={styles.addressAutocompleteStyle}
                onLoad={handleAutocompleteLoad}
                onPlaceChanged={handlePlaceChanged}
              >
                <TextField
                  className={styles.addressStyle}
                  placeholder='Enter the address'
                  value={inputAddressValue}
                  onChange={({ target }) => setInputAddressValue(target.value)}
                  onKeyUp={handleAddressPressEnter}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: address?.[0]?.addressEn ? 'visible' : 'hidden',
                        }}
                        onClick={handleInputAddressClear}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Autocomplete>
            )}
            <Box
              sx={{
                width: {
                  sm: '166px',
                },
              }}
            >
              <MyButton
                data={{
                  buttonName: 'Search',
                  styleType: 'button',
                  variant: 'contained',
                }}
                onClick={handleSearchSubmit}
              />
            </Box>
          </div>
          <div className={styles.row} style={{ flexWrap: 'wrap' }}>
            <div className={styles.propertyKindContainer}>
              <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
                <div className={styles.floor}>
                  {propertyTypeTab === 0 && (
                    <div
                      role='tabpanel'
                      hidden={propertyTypeTab !== 0}
                      id='property-type-tabpanel-0'
                      aria-labelledby='property-type-tab-0'
                      className={styles.buttonsContainer}
                    >
                      <div>
                        {constructionTypeValues.map((item) => (
                          <Button
                            key={item.value}
                            className={classNames({
                              [styles.selectButtons]: true,
                              [styles.propertyKindButton]: true,
                              [styles.selectButtonsActive]: constructionType === item.value,
                            })}
                            variant='contained'
                            onClick={() =>
                              onChangeFilters(
                                {
                                  ...filteringObj,
                                  constructionType:
                                    filteringObj.constructionType === item.value
                                      ? undefined
                                      : item.value,
                                },
                                true
                              )
                            }
                          >
                            {item.label}
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </FormSelectCustom>
            </div>
            <div className={styles.priceContainer}>
              <Select
                value={'-1'}
                className={styles.filterSelect}
                IconComponent={({ className }) => (
                  <CaretDown
                    className={classNames(styles.filterSelectCaret, {
                      [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                      [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                    })}
                  />
                )}
              >
                <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                  {priceLabel}
                </MenuItem>
                <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
                  <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                    <div>
                      <FormHelperText>
                        <span className={styles.selectInnerLabel}>Minimum</span>
                      </FormHelperText>
                      <TextField
                        value={priceMin}
                        onChange={handleChangePriceMin}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </div>
                    <div>
                      <FormHelperText>
                        <span className={styles.selectInnerLabel}>Maximum</span>
                      </FormHelperText>
                      <TextField
                        value={priceMax}
                        onChange={handleChangePriceMax}
                        onKeyDown={(e) => e.stopPropagation()}
                        error={priceMin > priceMax && priceMax !== 0}
                        helperText={
                          priceMin > priceMax && priceMax !== 0
                            ? 'max value should be more or equal than min value'
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </Select>
            </div>
            <div className={styles.bedsContainer}>
              <Select
                value={'-1'}
                className={styles.filterSelect}
                IconComponent={({ className }) => (
                  <CaretDown
                    className={classNames(styles.filterSelectCaret, {
                      [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                      [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                    })}
                  />
                )}
              >
                <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                  {bedsBathsText}
                </MenuItem>
                <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
                  <div className={styles.selectInnerContent}>
                    {bedsValues.map(({ label, value }) => (
                      <SelectItemButton
                        key={value}
                        label={label}
                        value={value}
                        currentValue={beds}
                        onChange={(newValue) =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              beds:
                                filteringObj.beds === newValue
                                  ? undefined
                                  : (newValue as AdFieldBedsEnum),
                            },
                            true
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              </Select>
            </div>
            <div className={styles.areaContainer}>
              <Select
                value={'-1'}
                className={styles.filterSelect}
                IconComponent={({ className }) => (
                  <CaretDown
                    className={classNames(styles.filterSelectCaret, {
                      [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                      [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                    })}
                  />
                )}
              >
                <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                  {areaLabel}
                </MenuItem>
                <div className={classNames(styles.selectInnerContainer, styles.filterArea)}>
                  <div className={classNames(styles.selectInnerContent, styles.filterArea)}>
                    <div>
                      <FormHelperText>
                        <span className={styles.selectInnerLabel}>
                          Minimum {userSettings?.areaUnits.toLowerCase()}
                        </span>
                      </FormHelperText>
                      <TextField
                        value={buildUpAreaFrom}
                        onChange={handleChangeAreaMin}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </div>
                    <div>
                      <FormHelperText>
                        <span className={styles.selectInnerLabel}>
                          Maximum {userSettings?.areaUnits.toLowerCase()}
                        </span>
                      </FormHelperText>
                      <TextField
                        value={buildUpAreaTo}
                        onChange={handleChangeAreaMax}
                        onKeyDown={(e) => e.stopPropagation()}
                        error={buildUpAreaFrom > buildUpAreaTo && buildUpAreaTo !== 0}
                        helperText={
                          buildUpAreaFrom > buildUpAreaTo && buildUpAreaTo !== 0
                            ? 'max value should be more or equal than min value'
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </Select>
            </div>
            <div className={styles.devStatusContainer}>
              <FormSelectCustom label='' value={developmentStatusLabel} textCapitalize={false}>
                <div className={styles.floor}>
                  {propertyTypeTab === 0 && (
                    <div
                      role='tabpanel'
                      hidden={propertyTypeTab !== 0}
                      id='property-type-tabpanel-0'
                      aria-labelledby='property-type-tab-0'
                      className={styles.buttonsContainer}
                    >
                      <div>
                        {developmentStatusValues.map((item) => (
                          <Button
                            key={item.value}
                            className={classNames({
                              [styles.selectButtons]: true,
                              [styles.propertyKindButton]: true,
                              [styles.selectButtonsActive]: developmentStatus === item.value,
                            })}
                            variant='contained'
                            onClick={() =>
                              onChangeFilters(
                                {
                                  ...filteringObj,
                                  developmentStatus:
                                    filteringObj.developmentStatus === item.value
                                      ? undefined
                                      : item.value,
                                },
                                true
                              )
                            }
                          >
                            {item.label}
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </FormSelectCustom>
            </div>
            {showFilters && (
              <div className={styles.floorsContainer}>
                <TextField
                  value={numberOfFloors}
                  placeholder='Num.of floors'
                  onChange={handleChangeNumberOfFloors}
                  onKeyDown={handleKeyDown}
                />
              </div>
            )}
            {showFilters && (
              <div className={styles.featuresContainer}>
                <FormControl sx={{ m: 1, width: '100%', margin: '0 20px 0 0' }}>
                  <Select
                    id='multiple-checkbox'
                    multiple
                    value={featuresLabel}
                    onChange={handleChangeFeatures}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    className={styles.fieldset}
                    IconComponent={(_props) => {
                      const rotate = _props.className.toString().includes('iconOpen');
                      return (
                        <div
                          className={styles.caretDown}
                          style={{
                            transform: rotate ? 'rotate(180deg)' : 'none',
                          }}
                        >
                          <CaretDown />
                        </div>
                      );
                    }}
                  >
                    {amenities.map((feature) => (
                      <MenuItem key={feature.key} value={feature.name}>
                        <Checkbox
                          icon={<span />}
                          checkedIcon={<span className={styles.checked} />}
                          style={{ transform: 'scale(0.65)' }}
                          checked={featuresName.indexOf(feature.name) > -1}
                        />
                        <ListItemText primary={feature.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {showFilters && (
              <div className={styles.handoverContainer}>
                <Select
                  value={'-1'}
                  className={styles.filterSelect}
                  IconComponent={({ className }) => (
                    <CaretDown
                      className={classNames(styles.filterSelectCaret, {
                        [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                        [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                      })}
                    />
                  )}
                >
                  <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                    {handoverLabel}
                  </MenuItem>
                  <div className={classNames(styles.selectInnerContainer, styles.filterHandover)}>
                    <FormHelperText>
                      <span className={styles.selectInnerTitle}>Quarters</span>
                    </FormHelperText>
                    <div className={classNames(styles.selectInnerContent, styles.filterHandover)}>
                      {quarterValues.map(({ label, value }) => (
                        <SelectItemButton
                          key={value}
                          label={label}
                          value={value}
                          currentValue={quarters}
                          onChange={() => handleChangeQuarters(value)}
                        />
                      ))}
                    </div>
                    <FormHelperText>
                      <span className={styles.selectInnerTitle}>Years</span>
                    </FormHelperText>
                    <div className={classNames(styles.selectInnerContent, styles.filterHandover)}>
                      {yearsValues.length > 0 &&
                        yearsValues.map((year, index) => (
                          <SelectItemButton
                            key={index}
                            label={year}
                            value={year}
                            currentValue={years}
                            onChange={() => handleChangeYears(year)}
                          />
                        ))}
                    </div>
                  </div>
                </Select>
              </div>
            )}
            {showFilters && (
              <div className={styles.unitsForSaleContainer}>
                <FormSelectCustom label='' value={unitsForSaleLabel} textCapitalize={false}>
                  <div className={styles.floor}>
                    {propertyTypeTab === 0 && (
                      <div
                        role='tabpanel'
                        hidden={propertyTypeTab !== 0}
                        id='property-type-tabpanel-0'
                        aria-labelledby='property-type-tab-0'
                        className={styles.buttonsContainer}
                      >
                        <div>
                          {unitsForSaleValues.map((item) => (
                            <Button
                              key={item.label}
                              className={classNames({
                                [styles.selectButtons]: true,
                                [styles.propertyKindButton]: true,
                                [styles.selectButtonsActive]: availableUnits === item.value,
                              })}
                              variant='contained'
                              onClick={() =>
                                onChangeFilters(
                                  {
                                    ...filteringObj,
                                    availableUnits:
                                      filteringObj.availableUnits === item.value
                                        ? undefined
                                        : item.value,
                                  },
                                  true
                                )
                              }
                            >
                              {item.label}
                            </Button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormSelectCustom>
              </div>
            )}
          </div>

          <div className={styles.rowControls}>
            <div className={styles.planControl}>
              <div className={styles.topUpLabel}>
                <Checkbox
                  checkedIcon={<span className={styles.checked} />}
                  name='paymentPlan'
                  style={{ transform: 'scale(0.65)' }}
                  checked={paymentPlan}
                  icon={<span />}
                  onChange={handleChangePaymentPlan}
                />
                <span>Payment plan</span>
              </div>
              {shouldBeShownResetFilters && (
                <div className={styles.buttonLink} onClick={handleResetFilters}>
                  <img src={resetFilters} alt='Reset filters' />
                  Reset filters
                </div>
              )}
            </div>
            <div className={styles.buttonLink} onClick={handleShowFilters}>
              {showFilters ? `Less filters` : `More filters`}
              <img
                src={showFilters ? lessFilters : moreFilters}
                alt={showFilters ? `Less filters` : `More filters`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.filtersDeveloperContainer}>
          <form onSubmit={handleDevelopersSearchSubmit} className={styles.search}>
            <div className={styles.row}>
              {isLoaded && (
                <Autocomplete
                  className={styles.addressAutocompleteStyle}
                  onLoad={handleAutocompleteLoad}
                  onPlaceChanged={handlePlaceChanged}
                >
                  <TextField
                    className={styles.addressStyle}
                    placeholder='Enter the address'
                    value={inputAddressValue}
                    onChange={({ target }) => setInputAddressValue(target.value)}
                    onKeyUp={handleAddressPressEnter}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            visibility: address?.[0]?.addressEn ? 'visible' : 'hidden',
                          }}
                          onClick={handleInputAddressClear}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Autocomplete>
              )}
              <Paper
                elevation={0}
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 330,
                  border: '1px solid #E9EEF1',
                }}
              >
                <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
                  <IconSearch />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder='Search by name'
                  inputProps={{ 'aria-label': 'search by name' }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Paper>
            </div>
            <div className={styles.row}>
              <MuiAutocomplete
                multiple
                id='checkboxes-tags-demo'
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                options={sortLanguage}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                onChange={(event, value) => setSelectedOptions(value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<span />}
                      checkedIcon={<span className={styles.checked} />}
                      style={{ transform: 'scale(0.65)' }}
                      checked={selected}
                    />
                    {option ? option : 'Languages'}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder={selectedOptions.length > 0 ? '' : 'Languages'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <div
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            pointerEvents: 'none',
                            right: 15,
                            height: '20px',
                            width: '20px',
                            transform: open ? 'rotate(180deg)' : 'none',
                          }}
                        >
                          <CaretDown />
                        </div>
                      ),
                    }}
                  />
                )}
              />
              <div className={styles.propertyKindContainer}>
                <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
                  <div className={styles.floor}>
                    {propertyTypeTab === 0 && (
                      <div
                        role='tabpanel'
                        hidden={propertyTypeTab !== 0}
                        id='property-type-tabpanel-0'
                        aria-labelledby='property-type-tab-0'
                        className={styles.buttonsContainer}
                      >
                        <div>
                          {constructionTypeValues.map((item) => (
                            <Button
                              key={item.value}
                              className={classNames({
                                [styles.selectButtons]: true,
                                [styles.propertyKindButton]: true,
                                [styles.selectButtonsActive]: constructionType === item.value,
                              })}
                              variant='contained'
                              onClick={() =>
                                onChangeFilters(
                                  {
                                    ...filteringObj,
                                    constructionType:
                                      filteringObj.constructionType === item.value
                                        ? undefined
                                        : item.value,
                                  },
                                  true
                                )
                              }
                            >
                              {item.label}
                            </Button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormSelectCustom>
              </div>
              <MyButton
                data={{
                  buttonName: 'Search',
                  buttonType: 'submit',
                  variant: 'contained',
                  size: 'medium',
                  customWidth: '138px',
                }}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
