import { useState } from 'react';
import { Box, Checkbox, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { State, UserKindEnum } from '@/types';
import { SectionHeader, SectionTitle } from '@/components';
import { ResponsibleUser } from './ResponsibleUser';
import { FileUpload } from './FileUpload';
import { XMLTable } from './XMLTable';
import styles from './XMLUpload.module.scss';

export const XMLUpload = () => {
  const { id: userId, kind: userKind } = useSelector(({ auth }: State) => auth.user);
  const [propertyAdAnnouncerId, setPropertyAdAnnouncerId] = useState<string>(userId);
  const [isXmlUpload, setIsXmlUpload] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);

  const isUserSelected = userId !== propertyAdAnnouncerId;

  return (
    <div className={styles.container}>
      <Box component='form' autoComplete='off'>
        <SectionHeader title='XML Upload' />
        <SectionTitle
          number={1}
          title='Select the user to upload lists without an author'
          required
        />
        {userKind !== UserKindEnum.Developer && (
          <ResponsibleUser
            propertyAdAnnouncerId={propertyAdAnnouncerId}
            setPropertyAdAnnouncerId={setPropertyAdAnnouncerId}
          />
        )}
        <SectionTitle number={2} title='Choose XML file' required />
        <Stack direction='row' spacing={1} alignItems='center'>
          <Checkbox
            icon={<span />}
            checkedIcon={<span className={styles.checked} />}
            style={{ transform: 'scale(0.75)', marginLeft: '-11px' }}
            checked={isRemove}
            onChange={(e) => setIsRemove(e.target.checked)}
          />
          <span>Remove watermarks from an image</span>
        </Stack>
        <FileUpload
          defaultUserId={propertyAdAnnouncerId}
          isUserSelected={isUserSelected}
          setIsXmlUpload={setIsXmlUpload}
          isXmlUpload={isXmlUpload}
          isRemove={isRemove}
        />
        <SectionTitle number={3} title='Upload information' />
        <XMLTable isXmlUpload={isXmlUpload} />
      </Box>
    </div>
  );
};
