import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdModel, NotificationTypeEnum } from '@/types';
import { adsProvider } from '@/providers';
import { appSetNotification } from '@/store';

export const useAdData = (id: string, currency = 'AED', pdf: boolean) => {
  const [adsData, setAdsData] = useState<AdModel>();
  const dispatch = useDispatch();

  const fetchData = async () => {
    const { ok, data, status, message } = await adsProvider.getAdById(id, currency, pdf);
    if (ok && data) {
      setAdsData(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, currency]);

  return { adsData };
};