export const blockedMessageWrapperStyle = () => {
  return {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    order: '3',
    position: 'relative',
    flex: 'none',
    minHeight: '105px',
    width: '100%',
    margin: 'auto',
  };
};

export const blockedMessageContainerStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
};

export const blockedTitleStyle = ({ theme }) => {
  return {
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
    color: `${theme.color.primary}`,
    lineHeight: '28px',
  };
};

export const bockedSubTitleStyle = ({ theme }) => {
  return {
    margin: '12px 0 26px',
    textAlign: 'center',
    color: theme.color.primary,
    maxWidth: '188px',
  };
};

export const unblockButtonStyle = ({ theme }) => {
  return {
    width: '303px',
    height: '56px',
    padding: '8px 16px',
    margin: '0',
    borderRadius: '14px',
    background: `${theme.backgroundColor.blueGradient}`,
    color: `${theme.color.lightGrey}`,
    fontSize: '16px',
  };
};
