import { useState } from 'react';
import { Link } from '@mui/material';
import Agreement from '@/components/AccountManagement/Dialogs/Agreement';
import PrivacyPolicy from '@/components/AccountManagement/Dialogs/PrivacyPolicy';

export const InfoDialog = () => {
  const [openedDialog, setOpenedDialog] = useState<DialogType>(null);

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '110px',
      }}
    >
      <Link underline='none' color='inherit' href='#' onClick={() => setOpenedDialog('agreement')}>
        Terms of usage
      </Link>
      <Agreement open={openedDialog === 'agreement'} onClose={() => setOpenedDialog(null)} />
      <Link
        underline='none'
        color='inherit'
        href='#'
        onClick={() => {
          setOpenedDialog('privacyPolicy');
        }}
      >
        Privacy policy
      </Link>
      <PrivacyPolicy
        open={openedDialog === 'privacyPolicy'}
        onClose={() => setOpenedDialog(null)}
      />
    </section>
  );
};

type DialogType = 'agreement' | 'privacyPolicy' | null;
