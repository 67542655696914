import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import {
  INewBuildingsModel,
  FileKindEnum,
  DevelopmentStatusEnum,
  NotificationTypeEnum,
  State,
  UserKindEnum,
  CreateNewPlanType,
  AdFieldBedsEnum,
} from '@/types';
import { Loader } from '@/components';
import { IUnitSearchQueryParams } from '@/types/requests';
import { IUnitSearchItems } from '@/types/responses';
import { appSetNotification } from '@/store';
import { newBuildingsProvider } from '@/providers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './NewBuildingListItem.module.scss';
import ItemGps from './svg/itemGps.svg';
import Developer from './svg/developer.svg';
import { BuildingMenu } from './BuildingMenu';

interface NewBuildingListProps {
  setChangeStatus?: (value: string) => void;
  item: INewBuildingsModel;
}

interface Unit {
  beds: string;
  price: string;
}

interface MinPrices {
  [key: string]: number;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  color: '#ffffff',
  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .3)',
    backgroundColor: '#1579FF33',
  },
}));

export const NewBuildingListItem = ({
  setChangeStatus,
  item: { id, developerName, developmentStatus, addressEn, nameEn, title, media },
}: NewBuildingListProps) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [units, setUnits] = useState<IUnitSearchItems[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: State) => auth);
  const isAdmin = user.kind === UserKindEnum.Admin;
  const isConstructionWithUnits = units && units?.length > 0;

  const searchPlans = async () => {
    try {
      const queryParams: IUnitSearchQueryParams = {
        limit: 50,
        offset: 0,
        newConstructionId: id,
        filter: [{ beds: '' }],
        sort: [{ field: 'price', order: 'ASC' }],
      };
      const { ok, data, status, message } = await newBuildingsProvider.searchPlans(queryParams);
      if (ok && data) {
        setUnits(data.items);
      } else {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, message || 'Error fetching data', status)
        );
      }
    } catch (error) {
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          error instanceof Error ? error.message : 'An unknown error occurred',
          500
        )
      );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    searchPlans();
    setIsLoading(false);
  }, []);

  const developmentStatusData = [
    { label: 'Planned', value: DevelopmentStatusEnum.PLANNED },
    { label: 'In progress', value: DevelopmentStatusEnum.IN_PROGRESS },
    { label: 'Completed', value: DevelopmentStatusEnum.COMPLETED },
    { label: 'Stopped', value: DevelopmentStatusEnum.STOPPED },
  ];

  const developStatus = developmentStatusData.find((status) => status.value === developmentStatus)?.label;

  const images =
    media &&
    media.filter((item) => item.mediaType === FileKindEnum.Image).sort((a, b) => a.order - b.order);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClickMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const formatPrice = (price: string) => {
    const numPrice = parseInt(price);
    if (numPrice >= 1000000) {
      return (numPrice / 1000000).toFixed(1) + 'M';
    } else if (numPrice >= 1000) {
      return (numPrice / 1000).toFixed(0) + 'K';
    }
    return price;
  };

  const minPriceByBeds: MinPrices = units
    ? units.reduce<MinPrices>((acc, unit) => {
        if (!acc[unit.beds] || unit.price < acc[unit.beds]) {
          acc[unit.beds] = unit.price;
        }
        return acc;
      }, {})
    : {};

  const getFilteredUnits = (units: Unit[]): Unit[] => {
    const filtered = units.filter((unit) => unit.beds === 'Studio' || parseInt(unit.beds) < 3);
    const hasThreeOrMore = units.some((unit) => parseInt(unit.beds) >= 3);
    if (hasThreeOrMore) {
      const threeOrMoreUnit = units.find((unit) => parseInt(unit.beds) >= 3) ?? units[0];
      filtered.push({ beds: '3+', price: threeOrMoreUnit.price });
    }
    return filtered;
  };

  const unitsOld = Object.keys(minPriceByBeds).map((beds) => ({
    beds,
    price: minPriceByBeds[beds].toString(),
  }));

  const sortOrder: { [key: string]: number } = {
    Studio: 1,
    '1': 2,
    '2': 3,
    '3+': 4,
  };

  const filteredUnits = getFilteredUnits(unitsOld).sort((a, b) => {
    return sortOrder[a.beds] - sortOrder[b.beds];
  });


  if (!media) return null;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.listItem}>
      <div className={styles.itemImg}>
        <Link href={`/offplan/${id}`} title={title} className={styles.imageWrapper}>
          {images && images.length > 0 && <img src={images[imageIndex].fileUrl} alt={title} />}
        </Link>

        {isAdmin ? (
          <div className={styles.itemImgInfo}>
            <Link href={`/offplan/${id}`} title={title} className={styles.itemUpdatedDateLink}>
              {developStatus}
            </Link>
            <div className={styles.itemImgIcons}>
              <StyledIconButton size='small' onClick={handleClickMenu}>
                <MoreVertIcon />
              </StyledIconButton>
              <BuildingMenu
                id={id}
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
                setChangeStatus={setChangeStatus}
              />
            </div>
          </div>
        ) : (
          <Link href={`/offplan/${id}`} title={title} className={styles.itemImgInfoLink}>
            <div className={styles.itemUpdatedDate}>{developStatus}</div>
          </Link>
        )}

        <Link href={`/offplan/${id}`} title={title} className={styles.itemInfo}>
          <div className={styles.itemContent}>
            <div className={styles.itemName}>{title}</div>
            <div className={styles.itemAddress}>
              <img src={Developer} alt='' />
              {developerName}
            </div>
            <div className={styles.itemAddress}>
              <img src={ItemGps} alt='' />
              {nameEn || addressEn}
            </div>
          </div>
        </Link>
      </div>

      <div
        className={`${styles.unitContent} ${!isConstructionWithUnits && styles.unitContentCenter}`}
      >
        {isConstructionWithUnits ? (
          filteredUnits.map((unit, index) => (
            <React.Fragment key={index}>
              <div className={styles.itemParams}>
                {unit.beds === 'Studio'
                  ? `Studio from ${formatPrice(unit.price)}`
                  : `${unit.beds} room${unit.beds === '1' ? '' : 's'} from ${formatPrice(unit.price)}`}
              </div>
              {index < filteredUnits.length - 1 && <div className={styles.itemParamsSeparator} />}
            </React.Fragment>
          ))
        ) : (
          <div className={styles.itemParamsWithoutUnits}>Contact the manager for information about the availability of units</div>
        )}
      </div>
    </div>
  );
};
