import { useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import {
  LicensesGetAgenciesResponseType,
  NotificationTypeEnum,
  TeamGetMyRequestResponseType,
  UserModel,
} from '@/types';
import { stateLicenseProvider, teamProvider, usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { MyButton, MyDialog } from '@/components';

import styles from './AgencyField.module.scss';
import { useUserProfile } from '@/hooks/useUserProfile';

interface AgencyOptionType {
  id: string;
  label: string;
}

export const AgencyField = ({ isUserBrokerOrManager }: { isUserBrokerOrManager: boolean }) => {
  const dispatch = useDispatch();
  const { refetchUserProfile } = useUserProfile();
  const [agencyInputValue, setAgencyInputValue] = useState<string>('');
  const [agencyOptions, setAgencyOptions] = useState<AgencyOptionType[]>([]);
  const [agencyDefaultValue, setAgencyDefaultValue] = useState<AgencyOptionType | undefined>();
  const [agencyValue, setAgencyValue] = useState<AgencyOptionType | undefined>();
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isDialogTerminateOpened, setIsDialogTerminateOpened] = useState(false);

  useEffect(() => {
    const getAgencyValue = async () => {
      const {
        ok: okRequest,
        data: dataRequest,
        status: statusRequest,
        message: messageRequest,
      } = await teamProvider.getMyRequest();
      if (!okRequest) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageRequest, statusRequest));
        return;
      }

      const request = (dataRequest as TeamGetMyRequestResponseType)?.items?.[0]?.employer;
      setIsRequestPending(!!request);
      if (request) {
        setAgencyInputValue(request.name);
        setAgencyValue({ id: request.id, label: request.name });
        setAgencyDefaultValue({ id: request.id, label: request.name });
        return;
      }

      const {
        ok: okAgency,
        data: dataAgency,
        status: statusAgency,
        message: messageAgency,
      } = await usersProvider.getMyUser();
      if (!okAgency) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageAgency, statusAgency));

        return;
      }

      const id = (dataAgency as UserModel).employer?.id || '';
      const label = (dataAgency as UserModel).employer?.name || '';
      setAgencyInputValue(label);
      setAgencyValue(label ? { id, label } : undefined);
      setAgencyDefaultValue(label ? { id, label } : undefined);
    };

    if (isUserBrokerOrManager) {
      getAgencyValue();
    }
  }, [isUserBrokerOrManager]);

  useEffect(() => {
    const getAllAgencies = async () => {
      const {
        ok,
        data: responseData,
        status,
        message,
      } = await stateLicenseProvider.searchAgency({ query: agencyInputValue });
      if (ok) {
        const { items } = responseData as LicensesGetAgenciesResponseType;
        setAgencyOptions(items.map(({ id, nameEn }) => ({ id, label: nameEn })));
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };

    if (agencyInputValue && !agencyDefaultValue) {
      getAllAgencies();
    } else {
      setAgencyOptions([]);
    }
  }, [agencyInputValue]);

  const handleApplyRequest = async () => {
    const { ok, status, message } = await teamProvider.sendMyRequest(agencyValue?.id || '');
    if (ok) {
      const { id = '', label = '' } = agencyValue || {};
      setAgencyDefaultValue({ id, label });
      setIsRequestPending(true);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const handleCancelRequest = async () => {
    const { ok, status, message } = await teamProvider.cancelMyRequest();
    if (ok) {
      setAgencyInputValue('');
      setAgencyValue(undefined);
      setAgencyDefaultValue(undefined);
      setIsRequestPending(false);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const handleCloseDialogTerminate = () => setIsDialogTerminateOpened(false);

  const handleConfirmTerminate = async () => {
    const { ok, status, message } = await teamProvider.removeMyInvite();
    if (ok) {
      setAgencyInputValue('');
      setAgencyValue(undefined);
      setAgencyDefaultValue(undefined);
      handleCloseDialogTerminate();
      await refetchUserProfile();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  return !isUserBrokerOrManager ? null : (
    <div className={styles.agencyFieldContaier}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={2}
      >
        <div className={styles.sectionName}>Agency</div>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={{ sm: 3, xs: 2 }}
        sx={{ width: '100%' }}
      >
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              className={styles.agenciesInput}
              placeholder='Search for Agency or Developer'
              helperText='Please choose from the dropdown list'
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                  }
                },
              }}
            />
          )}
          inputValue={agencyInputValue}
          onInputChange={(e, newValue) =>
            e.type === 'click' && !newValue
              ? setIsDialogTerminateOpened(true)
              : setAgencyInputValue(newValue)
          }
          options={agencyOptions}
          onChange={(_, newValue) => {
            if (newValue === null) {
              return;
            }
            setAgencyInputValue((newValue as AgencyOptionType).label);
            setAgencyValue(newValue as AgencyOptionType);
          }}
          className={classnames(styles.agenciesAutocomplete, {
            [styles.agenciesAutocompletePending]: isRequestPending,
          })}
          disabled={isRequestPending}
          disableClearable={!agencyDefaultValue || isRequestPending}
          onBlur={() => setAgencyInputValue(agencyValue?.label || '')}
        />
        {!isRequestPending && (
          <MyButton
            disabled={!!agencyDefaultValue || !agencyValue}
            data={{
              buttonName: 'Apply',
              customWidth: '120px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleApplyRequest}
          />
        )}
        {isRequestPending && (
          <MyButton
            data={{
              buttonName: 'Cancel Request',
              customWidth: '188px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleCancelRequest}
          />
        )}
      </Stack>
      <MyDialog
        open={isDialogTerminateOpened}
        onClose={handleCloseDialogTerminate}
        dialogTitle='Terminate Employment?'
        width='750'
      >
        <div className={styles.dialogDescription}>
          Are you certain about ending your employment with{' '}
          <strong>{agencyDefaultValue?.label}</strong>? Please note that this action is irreversible
          once confirmed.
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleConfirmTerminate}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              styleType: 'cancel',
              buttonType: 'button',
            }}
            onClick={handleCloseDialogTerminate}
          />
        </div>
      </MyDialog>
    </div>
  );
};
