import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

export interface AddressIconTextProps {
  iconSrc?: string;
  children?: ReactNode;
  style?: Style;
}

export const UnitsAddressIconText = ({ iconSrc, children, style }: AddressIconTextProps) => {
  if (!children) return null;


  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
      maxWidth: '35%',
      ...style,
    },
    addressText: {
      color: '#1650FF',
      fontSize: 40,
      fontWeight: 'bold',
    },
    icon: {
      width: 40,
      height: 40,
    },
    iconContainer: {
      display: 'flex',
      backgroundColor: '#1650FF',
      width: 90,
      height: 90,
      minWidth: 90,
      borderRadius: '12px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });


  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        {iconSrc && <Image src={iconSrc} style={styles.icon} />}
      </View>
      <Text style={styles.addressText}>{children}</Text>
    </View>
  );
};
