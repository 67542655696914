import { TextField, FormControl, FormHelperText, SxProps, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './FormInputNumeric.module.scss';

export const NumberInput = ({
  name,
  uiVariant,
  ...otherProps
}: { name: string; uiVariant?: 'blank' } & TextFieldProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const defaultStyles: SxProps =
    uiVariant === 'blank' ? { width: '100%' } : { m: 1, width: '100%', margin: '0 20px 14px 0' };

  return (
    <FormControl sx={defaultStyles}>
      {otherProps.label && (
        <FormHelperText className={styles.label}>
          {otherProps.label}
          {otherProps.required && <span>*</span>}
        </FormHelperText>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <TextField
            type='number'
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => setValue(name, Number(e.target.value))}
            InputProps={{
              inputProps: {
                min: 1,
                max: 10,
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};
