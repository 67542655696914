export const theme = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '14px',
  secondaryTextColor: '#808080',
  primaryColor: '#39f',

  color: {
    white: '#fff',
    primary: '#2A3842',
    grey: '#838383',
    lightGrey: '#F6FBFF',
    red: '#FF0000',
    active: '#006AF8',
    secondary: '#cccccc',
    helpText: 'rgba(20, 20, 20, 0.6)',
    blue: '#39f',
    blueActive: '#1650FF',
  },
  borderColor: {
    white: '#fff',
    primary: '#E9EEF1',
    secondary: '#2A3842',
    lightBlue1: '#006AF880',
  },
  backgroundColor: {
    white: '#fff',
    lightBlue1: '#F9FCFF',
    lightBlue2: '#F1F7FF',
    lightBlue3: '#CBE3FF',
    lightBlue4: '#CBE3FF80',
    online: '#24FF00',
    offline: '#FF0000',
    lightGrey1: '#E9EEF1',
    lightGrey2: '#D9D9D9',
    blueGradient: 'linear-gradient(90deg, #01439B -114.29%, #006AF8 71.43%)',
    primary: '#E6E6E6',
    secondary: '#f6f6f6',
    blue: '#39f',
    red: '#ff3b30',
    grey: 'rgba(20,20,20,0.04)',
    lightGrey: 'rgba(20, 20, 20, 0.08)',
    darkGrey: 'rgba(20,20,20,0.9)',
    callScreenGrey: '#444444',
    silver: '#c4c4c4',
  },
  breakPoints: [
    '(min-width: 320px) and (max-width: 767px)',
    '(min-width: 320px) and (max-width: 480px)',
    '(min-width: 481px) and (max-width: 768px)',
    '(min-width: 769px) and (max-width: 1024px)',
    '(min-width: 1025px) and (max-width: 1200px)',
  ],
};
