import { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tabs,
  Tab,
  Button,
  Grid,
} from '@mui/material';
import classNames from 'classnames';

import {
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  AdsFilteringType,
  RentPeriodEnum,
  TransactionTypeEnum,
} from '@/types';
import {
  predefinedVars,
  getLabelsAndTexts,
  getMoreFiltersControlsVisibility,
} from './AdsFilters.init';
import { MyButton, FormSelectCustom } from '@/components';

import { SelectItemButton } from './SelectItemButton';
import styles from './AdsFilters.module.scss';

import { ReactComponent as CaretDown } from './caretDown.svg';
import { useUserSettings } from '@/hooks/useUserSettings';
import { LocationModel } from '@/types/location';
import { LocationAutocomplete } from '@/components/LocationAutocomplete/LocationAutocomplete';
import { MoreFiltersControl } from './MoreFiltersControl';
import { MoreFilters } from './MoreFilters';
import { DumbFormSelect } from '@/components/uiComponents/FormSelect';
import { handoverChoices, rentSalesExtraChoices } from './MoreFilters.init';
interface Activity {
  completionStatus?: CompletionStatusEnum;
  rentFrequency?: RentPeriodEnum;
}
export const MyPropertyFilters = ({
  filteringObj,
  onChangeFilters,
  locations,
  inputAddressValue,
  setInputAddressValue,
  setSelectedLocation,
  selectedLocation,
  setChangeFilter,
  userSettings,
}: {
  filteringObj: AdsFilteringType;
  onChangeFilters: (value: AdsFilteringType, shouldSendRequest?: boolean) => void;
  locations: LocationModel[];
  setLocations: Dispatch<SetStateAction<LocationModel[]>>;
  isMyAds?: boolean;
  selectedLocation: LocationModel | null;
  setSelectedLocation: (value: LocationModel | null) => void;
  inputAddressValue: string;
  setInputAddressValue: (value: string) => void;
  setChangeFilter: (value: boolean) => void;
  userSettings: any;
}) => {
  const {
    activityKind,
    propertyKind,
    areaMin = 0,
    areaMax = 0,
    beds,
    baths,
    priceMin = 0,
    priceMax = 0,
  } = filteringObj;
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);
  const [isMoreFiltersVisible, setIsMoreFiltersVisible] = useState(false);
  const [isResetVisible, setIsResetVisible] = useState(false);

  useEffect(() => {
    const { isResetFiltersVisible } = getMoreFiltersControlsVisibility(filteringObj);
    setIsResetVisible(isResetFiltersVisible);
  }, [filteringObj]);

  const { bedsBathsText, areaLabel, priceLabel } = getLabelsAndTexts(filteringObj, userSettings);

  const handleShowFilters = () => setIsMoreFiltersVisible((prevState) => !prevState);

  const handleChangeAreaMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaMin: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangeAreaMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaMax: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMin: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMax: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleInputAddressClear = () => {
    setInputAddressValue('');
    setSelectedLocation(null);
    onChangeFilters({ ...filteringObj, address: [], locationId: undefined }, true);
  };

  const handleResetFilters = () => {
    setInputAddressValue('');
    setSelectedLocation(null);
    setIsResetVisible(true);
    onChangeFilters({ ...predefinedVars.emptyFilteringObj }, true);
    setChangeFilter(false);
  };

  const handleSearchSubmit = () => {
    onChangeFilters(
      {
        ...filteringObj,
        address: [
          {
            addressEn: inputAddressValue || '',
          },
        ],
        locationId: selectedLocation?.id ?? locations?.[0]?.id ?? undefined,
      },
      true
    );
  };

  const propertyKindValueItem = predefinedVars.propertyKindValues.find(
    (item) => item.value === propertyKind?.[0]
  );
  const propertyKindValueLabel = propertyKindValueItem?.label || 'Property type';

  const activityKindValueItem = predefinedVars.activityKindValues.find(
    (item) => item.value === activityKind
  );
  const activityKindValueLabel = activityKindValueItem?.label || 'Type of activity';

  const onSelectLocation = (value: LocationModel) => {
    setSelectedLocation(value);
    onChangeFilters(
      {
        ...filteringObj,
        address: [
          {
            addressEn: inputAddressValue || '',
          },
        ],
        locationId: value.id,
      },
      true
    );
  };

  const onChangeMoreFilters = (data: AdsFilteringType) => {
    const newFilteringObj = {
      ...filteringObj,
      ...data,
      'creator.name': data.creator ? data['creator.name'] : undefined,
      'creator.kind': data.creator ? data['creator.kind'] : undefined,
      'creator.id': data.creator ? data['creator.id'] : undefined,
    };

    onChangeFilters(newFilteringObj, true);
  };

  const rentData = [
    { label: 'Yearly', value: 'yearly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Daily', value: 'daily' },
  ];

  const saleData = [
    { label: 'Ready', value: TransactionTypeEnum.Ready },
    { label: 'Resale', value: TransactionTypeEnum.Resale },
    { label: 'Distress', value: TransactionTypeEnum.Distress },
  ];

  const rentSalesData = rentSalesExtraChoices(activityKindValueLabel.toLowerCase());
  return (
    <div className={styles.filtersContainer}>
      <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <FormSelectCustom label='' value={activityKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <div className={classNames(styles.selectInnerContainer, styles.filterActivityKind)}>
                <div className={styles.selectInnerContent}>
                  {predefinedVars.activityKindValues.map(({ label, value }) => (
                    <Button
                      key={value}
                      className={classNames({
                        [styles.selectButtons]: true,
                        [styles.activityKindButton]: true,
                        [styles.selectButtonsActive]: activityKind === value,
                      })}
                      variant='contained'
                      onClick={() =>
                        onChangeFilters(
                          {
                            ...filteringObj,
                            activityKind: filteringObj.activityKind === value ? undefined : value,
                          },
                          true
                        )
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </FormSelectCustom>
        </Grid>
        <Grid item xs={9.6} sm={7.2} lg={7.2} md={7.2}>
          <LocationAutocomplete
            setInputAddressValue={setInputAddressValue}
            locations={locations}
            inputAddressValue={inputAddressValue}
            handleClear={handleInputAddressClear}
            onSelect={onSelectLocation}
          />
        </Grid>
        <Grid item xs={2.4} sm={2.4} lg={2.4} md={2.4}>
          <Box
            sx={{
              width: {
                sm: '166px',
              },
            }}
          >
            <MyButton
              data={{
                buttonName: 'Search',
                styleType: 'button',
                variant: 'contained',
              }}
              onClick={handleSearchSubmit}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <DumbFormSelect
            activityKindValueLabel={activityKindValueLabel}
            defaultSelectedValue={
              activityKindValueLabel === 'Sale'
                ? 'qqqqqqq' // filteringObj.transactionType
                : filteringObj.rentFrequency
            }
            placeholder={rentSalesData?.label}
            name='constructionType'
            disabled={
              !(rentSalesData?.choices && rentSalesData.choices.length > 0) ||
              activityKindValueLabel === 'Sale'
            }
            data={activityKindValueLabel === 'Sale' ? saleData : rentData}
            handleChange={(value) => {
              const activity: Activity = {};
              if (activityKindValueLabel.toLowerCase() === 'sale') {
                activity.completionStatus = value ? value : undefined;
                activity.rentFrequency = undefined;
              } else {
                activity.rentFrequency = value ? value : undefined;
                activity.completionStatus = undefined;
              }
              onChangeFilters(
                {
                  ...filteringObj,
                  ...activity,
                },
                true
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <Tabs
                value={propertyTypeTab}
                onChange={(_, val) => setPropertyTypeTab(val)}
                aria-label='property type tabs'
                className={styles.tabsContainer}
              >
                <Tab
                  label='Residential'
                  id='property-type-tab-0'
                  aria-controls='property-type-tabpanel-0'
                  className={styles.tabItem}
                />
                <Tab
                  label='Commercial'
                  id='property-type-tab-1'
                  aria-controls='property-type-tabpanel-1'
                  className={styles.tabItem}
                />
              </Tabs>
              {propertyTypeTab === 0 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 0}
                  id='property-type-tabpanel-0'
                  aria-labelledby='property-type-tab-0'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[0, 1, 2, 3].map((item) => (
                      <Button
                        key={predefinedVars.propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === predefinedVars.propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] ===
                                predefinedVars.propertyKindValues[item].value
                                  ? undefined
                                  : [predefinedVars.propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {predefinedVars.propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[4, 5, 6, 7].map((item) => (
                      <Button
                        key={predefinedVars.propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === predefinedVars.propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] ===
                                predefinedVars.propertyKindValues[item].value
                                  ? undefined
                                  : [predefinedVars.propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {predefinedVars.propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              {propertyTypeTab === 1 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 1}
                  id='property-type-tabpanel-1'
                  aria-labelledby='property-type-tab-1'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[8, 9, 10].map((item) => (
                      <Button
                        key={predefinedVars.propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === predefinedVars.propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] ===
                                predefinedVars.propertyKindValues[item].value
                                  ? undefined
                                  : [predefinedVars.propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {predefinedVars.propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[11, 12, 13].map((item) => (
                      <Button
                        key={predefinedVars.propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === predefinedVars.propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] ===
                                predefinedVars.propertyKindValues[item].value
                                  ? undefined
                                  : [predefinedVars.propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {predefinedVars.propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </FormSelectCustom>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {areaLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterArea)}>
              <div className={classNames(styles.selectInnerContent, styles.filterArea)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Minimum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaMin}
                    onChange={handleChangeAreaMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Maximum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaMax}
                    onChange={handleChangeAreaMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={areaMin > areaMax && areaMax !== 0}
                    helperText={
                      areaMin > areaMax && areaMax !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {bedsBathsText}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Beds</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {predefinedVars.bedsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={beds as AdFieldBedsEnum}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          beds:
                            filteringObj.beds === newValue
                              ? undefined
                              : (newValue as AdFieldBedsEnum),
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Baths</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {predefinedVars.bathsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={baths as AdFieldBathsEnum}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          baths:
                            filteringObj.baths === newValue
                              ? undefined
                              : (newValue as AdFieldBathsEnum),
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </Select>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {priceLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
              <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Minimum</span>
                  </FormHelperText>
                  <TextField
                    value={priceMin}
                    onChange={handleChangePriceMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Maximum</span>
                  </FormHelperText>
                  <TextField
                    value={priceMax}
                    onChange={handleChangePriceMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={priceMin > priceMax && priceMax !== 0}
                    helperText={
                      priceMin > priceMax && priceMax !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Grid>
      </Grid>

      <MoreFiltersControl
        isResetVisible={isResetVisible}
        isMoreFiltersVisible={isMoreFiltersVisible}
        handleShowFilters={handleShowFilters}
        handleResetFilters={handleResetFilters}
      >
        <MoreFilters
          isMoreFiltersVisible={isMoreFiltersVisible}
          activityType={activityKindValueLabel}
          cbUpdateFilters={onChangeMoreFilters}
          defaultFilters={filteringObj}
        />
      </MoreFiltersControl>
    </div>
  );
};
