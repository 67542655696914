import { useEffect, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Box, Tabs, Tab, Link, Stack } from '@mui/material';
import {
  NotificationTypeEnum,
  AdsGetAllResponseType,
  AdModel,
  AdMyModel,
  PaginationType,
  ProfileViewStatusEnum,
  MemberModel,
  TeamGetAllMembersResponseType,
  UserModel,
  State,
  ClientRequestModel,
  ClientRequestGetAllResponseType,
  ClientRequestsButtonStatusEnum,
} from '@/types';
import { getKindLabel } from '@/utils';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { EmptyList, Loader, Pagination } from '@/components';
import { EmptyList as EmptyListTeam } from '@/components/Team/EmptyList';
import { EmptyListRequest } from './EmptyListRequest';
import { AdsListItem } from '@/components/Ads/AdsListItem';
import { RequestsMyListItem } from '@/components/PropertyRequests/RequestsMyListItem';
import styles from './ListingsAndAgencyStaff.module.scss';
const COUNT_ADS_PER_PAGE = 10;
const COUNT_REQUESTS_PER_PAGE = 10;
const COUNT_MEMBERS_PER_PAGE = 12;

interface TotalCountsType {
  properties: number;
  team: number;
  request: number;
}

export const ListingsAndAgencyStaff = ({
  id,
  user,
  isAgency,
  isForeign,
  dataQa,
}: {
  id: string;
  user: UserModel;
  isAgency: boolean;
  isForeign: boolean;
  dataQa: string;
}) => {
  const dispatch = useDispatch();
  const userMe = useSelector(({ auth }: State) => auth.user);
  const changeQueryParams = useChangeQueryParams(`users/${id!}`);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [activeTab, setActiveTab] = useState(ProfileViewStatusEnum.Listings);
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const [requestsPaginationObj, setRequestsPaginationObj] = useState<PaginationType>();
  const [isLoadingGetUserTotals, setIsLoadingGetUserTotals] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ads, setAds] = useState<AdModel[] | AdMyModel[]>([]);
  const [members, setMembers] = useState<MemberModel[]>();
  const [requests, setRequests] = useState<ClientRequestModel[]>();
  const [totals, setTotals] = useState<TotalCountsType>({
    properties: 0,
    team: 0,
    request: 0,
  });

  const [lastPages, setLastPages] = useState({
    listings: '1',
    staff: '1',
    request: '1',
  });
  const [membersPaginationObj, setMembersPaginationObj] = useState<PaginationType>({
    page: 1,
    limit: COUNT_MEMBERS_PER_PAGE,
    offset: 0,
    total: 0,
  });

  const getCounter = async () => {
    setIsLoadingGetUserTotals(true);
    const { ok, data: responseData, status, message } = await usersProvider.getCounter(id!);
    if (ok) {
      const data = responseData as TotalCountsType;
      setTotals(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetUserTotals(false);
  };

  const getAds = async () => {
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };

    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.getUserAds(id!, newPaginationObj);
    if (ok) {
      const { items, total } = data as AdsGetAllResponseType;
      // const publishAds = items.filter((item) => item.status === 'published');
      setAds(items ?? []);
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getMembers = async (newPage?: number, newOffset?: number) => {
    const offset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_MEMBERS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: offset || 0,
      limit: COUNT_MEMBERS_PER_PAGE,
      total: 0,
    };
    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.getUserMembers(id!, newPaginationObj);
    if (ok) {
      const { items, total } = data as TeamGetAllMembersResponseType;
      setMembers(items);
      setMembersPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getRequests = async () => {
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_REQUESTS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_REQUESTS_PER_PAGE,
      total: 0,
    };

    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.getUserRequests(
      id!,
      newPaginationObj
    );
    if (ok) {
      const { items, total } = data as ClientRequestGetAllResponseType;
      setRequests(items ?? []);
      setRequestsPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const isListings = activeTab === ProfileViewStatusEnum.Listings;
  const isStaff = activeTab === ProfileViewStatusEnum.Staff;
  const isRequests = activeTab === ProfileViewStatusEnum.Requests;

  useEffect(() => {
     if (isAgency) {
      setActiveTab(ProfileViewStatusEnum.Staff);
    }
  }, []);

  useEffect(() => {
    getCounter();
    if (isListings) {
      getAds();
    }
    if (isStaff) {
      getMembers();
    }
    if (isRequests) {
      getRequests();
    }
  }, [activeTab, queryParamsStr]);

  const handleChangeTab = (_: SyntheticEvent, newStatus: ProfileViewStatusEnum) => {
    setActiveTab(newStatus);
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as ProfileViewStatusEnum]: page });
    return changeQueryParams({
      ...queryParams,
      page: '1',
      status: newStatus,
    });
  };

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleChangeMembersPage = (page: number) =>
    changeQueryParams({
      ...queryParams,
      page,
    });

  if (isLoading || isLoadingGetUserTotals) {
    return (
      <div className={styles.templateMyAdsContainer} data-qa={`${dataQa}_listing_staff_request_block`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.templateMyAdsContainer} data-qa={`${dataQa}_listing_staff_request_block`}>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label='agency view tabs'
          className={styles.tabsInner}
          data-qa={`${dataQa}_tabs`}
        >
          {!isForeign && !isAgency && (
            <Tab
              id='tab-listings'
              aria-controls='tabpanel-published'
              label={`Listings${totals.properties > 0 ? ` (${totals.properties})` : ''}`}
              value={ProfileViewStatusEnum.Listings}
              className={styles.tabItem}
              data-qa={`${dataQa}_tab_listings`}
            />
          )}
          {isAgency && (
            <Tab
              id='tab-agency-staff'
              aria-controls='tabpanel-unpublished'
              label={`Agency staff${totals.team > 0 ? ` (${totals.team})` : ''}`}
              value={ProfileViewStatusEnum.Staff}
              className={styles.tabItem}
              data-qa={`${dataQa}_tab_agency_staff`}
            />
          )}
          <Tab
            id='tab-requests'
            aria-controls='tabpanel-published'
            label={`Client requests${totals.request > 0 ? ` (${totals.request})` : ''}`}
            value={ProfileViewStatusEnum.Requests}
            className={styles.tabItem}
            data-qa={`${dataQa}_tab_requests`}
          />
        </Tabs>
      </Box>
      <Stack sx={{minHeight: '450px'}}>
        {isListings && !isAgency ? (
          <div data-qa={`${dataQa}_listings_block`}>
            {!ads?.length ? (
              <EmptyList description='The user does not have any ads created yet!'/>
            ) : (
              <div className={styles.adsList}>
                {(ads as AdModel[]).map(
                  (item) => item && <AdsListItem key={item.id} item={item} user={user} />
                )}
              </div>
            )}
            {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE ? (
              <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
            ) : null}
          </div>
        ) : null}
        {isStaff ? (
          <div data-qa={`${dataQa}_agency_staff_block`}>
            {!members?.length ? (
              <EmptyListTeam description='No search results' />
            ) : (
              <div className={styles.usersContainer}>
                {members &&
                  members?.map((item, index) => {
                    const isMe = item.id === userMe.id;
                    return (
                      <Link
                        href={!isMe ? `/users/${item?.id}` : '/profile/view'}
                        title={user?.name}
                        style={{ textDecoration: 'none' }}
                        key={index}
                        className={styles.container}
                      >
                        <div className={styles.image}>
                          {item.avatar ? (
                            <img src={item.avatar} alt={item.name} />
                          ) : (
                            <span>{item.name[0]}</span>
                          )}
                        </div>
                        <div className={styles.name} title={item.name}>
                          {item.name}
                        </div>
                        <div className={styles.title}>{getKindLabel(item.kind)}</div>
                      </Link>
                    );
                  })}
              </div>
            )}
            {!!membersPaginationObj?.total && membersPaginationObj.total > COUNT_MEMBERS_PER_PAGE && (
              <Pagination
                paginationObj={membersPaginationObj}
                onChangePage={handleChangeMembersPage}
              />
            )}
          </div>
        ) : null}
        {isRequests ? (
          <div data-qa={`${dataQa}_requests_block`}>
            {!requests?.length ? (
              <EmptyListRequest />
            ) : (
              <div className={styles.adsList}>
                {(requests as ClientRequestModel[]).map(
                  (item) =>
                    item && <RequestsMyListItem key={item.id} item={item} btnClientRequest={true} />
                )}
              </div>
            )}
            {!!requestsPaginationObj?.total && requestsPaginationObj?.total > COUNT_ADS_PER_PAGE ? (
              <Pagination paginationObj={requestsPaginationObj} onChangePage={handleChangePage} />
            ) : null}
          </div>
        ) : null}
      </Stack>
    </div>
  );
};
