import { useSortable } from '@dnd-kit/sortable';
import { Link } from '@mui/material';

import styles from './MediaItem.module.scss';
import { ReactComponent as DeleteIcon } from './trash.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Image {
  url: string;
  position: number;
  id: string;
  remove?: (index: number) => void;
  openPdf?: (pdfUrl: string) => void;
  fileOriginalName?: string;
}

export const MediaApplication = ({
  url,
  position,
  id,
  remove,
  fileOriginalName,
  openPdf,
}: Image) => {
  const { attributes, listeners, setNodeRef } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={styles.container}
      style={{
        backgroundImage: true ? `url(${'/Img/bgBrochure.png'})` : '',
        width: remove ? '315px' : '285px',
        height: remove ? '190px' : '170px',
        margin: remove ? '0 16px 16px 0' : '0 0 20px 0',
      }}
      id={id}
    >
      <div className={styles.position}>{position}</div>
      <div className={styles.fileName}>
        <Link className={styles.fileLinkHover} href={url}>
          {fileOriginalName || `Brochure ${id}`}
        </Link>
      </div>
      <div className={styles.buttons}>
        {remove ? (
          <div className={styles.delete} onMouseDown={() => remove && remove(position)}>
            <DeleteIcon />
          </div>
        ) : (
          <div className={styles.open} onClick={() => openPdf && openPdf(url)}>
            <OpenInNewIcon />
          </div>
        )}
      </div>
    </div>
  );
};
