import axios, { AxiosRequestHeaders, AxiosResponse, isAxiosError } from 'axios';

import { GPT_API_URL } from '@/constants';
import { gptRequestType, ResponseDataType, ResponseType } from '@/types';

export const gptClient = async (
  uri: string,
  method: string,
  { description, keywords }: gptRequestType
): Promise<ResponseType> => {
  const url = `${GPT_API_URL}${uri}`;

  const headers = {
    ['Authorization']: `Bearer ${process.env.REACT_APP_OPEN_AI_TOKEN}`,
    ['Content-Type']: 'application/json',
  } as AxiosRequestHeaders;

  const data = JSON.stringify({
    messages: [
      { role: 'system', content: description },
      { role: 'user', content: keywords.toString() },
    ],
    max_tokens: 1000,
    model: 'gpt-3.5-turbo',
  });

  try {
    const response: AxiosResponse<ResponseDataType> = await axios({
      url,
      method,
      headers,
      data,
    });

    return {
      data: response.data,
      ok: true,
      status: response.status,
      message: response.statusText,
    };
  } catch (error) {
    let status, message;
    if (isAxiosError(error)) {
      status = error?.response?.data.statusCode || error?.response?.status || error?.code;
      message = error?.response?.data.message || error?.response?.statusText || error?.message;
    } else {
      message = `Not Axios error was received from the server (gptClient request). Error: ${JSON.stringify(
        error
      )}`;
    }

    return {
      data: null,
      ok: false,
      status,
      message,
    };
  }
};
