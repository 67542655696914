import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { StyleLabel } from '../styled';
import { MyButton, MyDialog , DialogTariffPlan} from '@/components';
import { HeaderDialogsEnum, State, NotificationTypeEnum } from '@/types';
import { IMyCommunicateProps, IMyButtonData, DialogsType } from '../types';
import { IDialogContent } from '../../AccountManagement/utils/types';
import {
  socialButtonsData,
  dialogWarningData,
} from '../../AccountManagement/utils/dialogDescription';
import Request from './Request';
import { ReactComponent as FriendIcon } from './friend.svg';
import { friendsProvider } from '@/providers';
import { appSetNotification } from '@/store';

export const MyCommunicate: FC<IMyCommunicateProps> = ({
  user,
  adInfo,
  dataQa,
  isTariffFree,
}) => {
  const [openDialog, setOpenDialog] = useState<DialogsType>({
    signUp: false,
    logIn: false,
    privacyPolicy: false,
    agreement: false,
    confirmation: false,
    resetPassword: false,
    createPassword: false,
    changePassword: false,
    operator: false,
    selectUserType: false,
    success: false,
    error: false,
    warning: false,
    info: false,
    request: false,
    tariff: false,
  });
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState('');
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>()
  const navigate = useNavigate();
  const additionalEmails = user?.additionalEmails;
  const userName = user?.name;
  const additionalPhones = user?.additionalPhones;
  const whatsapp = user?.whatsapp;
  const telegram = user?.telegram;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user: userMe } = useSelector(({ auth }: State) => auth);
  const dispatch = useDispatch();

  const isFriend = async () => {
    const { ok, data, status, message } = await friendsProvider.isFriend(user?.id!);
    if (ok) {
      setIsButtonVisible(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  }

  useEffect(() => {
    isFriend();
  }, [])
  

  const OPChat: IMyButtonData = {
    styleType: 'withoutIcon',
    buttonName: 'OP Chat',
    variant: 'contained',
    customWidth: isButtonVisible ? '348px' : '258px',
    label: 'chat',
    dataQa: `op_chat_btn`,
  };

  const Friends: IMyButtonData = {
    icon: <FriendIcon />,
    colorIcons: '#2A3842',
    styleType: 'onlyIcon',
    variant: 'contained',
    customWidth: '78px',
    label: 'friend',
    dataQa: `friend_btn`,
  };

  const handleOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const createFriendsRequest = async () => {
    const requestData = {
      senderId: userMe.id,
      receiverId: user?.id!,
    }
    const { ok, data, status, message } = await friendsProvider.createRequest(requestData);
    if (ok) {
      setIsButtonVisible(true);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  }

  const switchActionKey = (actionKey: string, name: string, message: string) => {
    switch (actionKey) {
      case 'phoneAction':
        if (additionalPhones) {
          window.location.href = `tel:${additionalPhones?.[0]}`;
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'whatsappAction':
        if (whatsapp) {
          window.open(`https://wa.me/${whatsapp}?text=${message}`, '_blank');
        } else {
          handleOpenDialog(HeaderDialogsEnum.Error);
        }
        break;
      case 'telegramAction':
        if (telegram) {
          window.open(`https://t.me/${telegram}?start=${message}`, '_blank');
        } else {
          handleOpenDialog(HeaderDialogsEnum.Error);
        }
        break;
      case 'opChatAction':
        if (user?.chatId) {
          navigate(`/chat/${user.chatId}/${message}`);
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'emailAction':
        if (additionalEmails && name) {
          const subject = encodeURIComponent('question');
          window.location.href = `mailto:${additionalEmails?.[0]}?subject=${subject}&body=${message}`;
        } else {
          console.error('Email address not available!');
        }
        break;
      case 'friendAction':
        createFriendsRequest();
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const handleButtonClick = (actionKey: string, name: string) => {
    if (isTariffFree) {
      return handleOpenDialog(HeaderDialogsEnum.Tariff);
    }
    if (adInfo) {
      setActionKey(actionKey);
      setActionName(name);
      return handleOpenDialog(HeaderDialogsEnum.Request);
    }
    const message = encodeURIComponent(
      `Hi, ${userName}, I'm considering making a purchase and would like additional information about the property listed on Online Property.`
    );
    switchActionKey(actionKey, name, message);
  };

  return (
    <>
      <Stack
        justifyContent='space-between'
        alignItems={isMobile ? 'center' : 'flex-end'}
        spacing={1}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1.5}>
          <MyButton
            data={OPChat}
            onClick={() => OPChat?.label && handleButtonClick('opChatAction', OPChat?.label)}
          />
          {!isButtonVisible && (
            <MyButton
              data={Friends}
              onClick={() => Friends?.label && handleButtonClick('friendAction', Friends?.label)}
            />
          )}
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1.5}>
          {socialButtonsData?.map((content: IDialogContent, index: number) => (
            <MyButton
              key={index}
              data={content}
              onClick={() => handleButtonClick(content?.actionKey || '', content?.name)}
            />
          ))}
        </Stack>
      </Stack>
      <MyDialog
        open={openDialog.error}
        dialogTitle={dialogWarningData.dialogTitle.label}
        width={dialogWarningData.width}
      >
        <StyleLabel style={{ textAlign: 'center', marginBottom: '15px' }}>
          {dialogWarningData.dialogTitle.description}
        </StyleLabel>
        <MyButton
          data={dialogWarningData.dialogContent[0]}
          onClick={() => handleOpenDialog(HeaderDialogsEnum.Error)}
        />
      </MyDialog>
      {adInfo && openDialog.request && (
        <Request
          open={openDialog.request}
          adInfo={adInfo}
          onClose={() => handleOpenDialog(HeaderDialogsEnum.Request)}
          actionKey={actionKey}
          actionName={actionName}
          switchActionKey={switchActionKey}
        />
      )}
      <DialogTariffPlan
        open={openDialog.tariff}
        onClose={() => handleOpenDialog(HeaderDialogsEnum.Tariff)}
      />
    </>
  );
};
