export const pinnedMessageContainerStyle = ({ theme }) => {
  return {
    background: `${theme.backgroundColor.white}`,
    boxShadow: '0px 4px 4px 0px #0000001C',
    borderLeft: `8px solid ${theme.borderColor.secondary}`,
    borderRadius: '4px',
    zIndex: 2,
    maxWidth: '570px',
  };
};

export const pinnedMessageWrapperStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  };
};

export const pinnedMessageContentStyle = () => {
  return {
    overflow: 'hidden',
    paddingRight: '20px',
  };
};

export const pinnedMessageTitleStyle = ({ theme }) => {
  return {
    fontWeight: '500',
    lineHeight: '20px',
    color: `${theme.color.primary}`,
    opacity: '50%',
  };
};

export const pinnedMessageTextStyle = ({ theme }) => {
  return {
    fontWeight: '400',
    lineHeight: '20px',
    color: `${theme.color.primary}`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };
};

export const pinnedMessageButtonStyle = (img, { theme }) => {
  return {
    outline: '0',
    border: '0',
    height: '24px',
    width: '24px',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    right: '12px',
    backgroundColor: `${theme.color.primary}!important`,
    mask: `url(${img}) center center no-repeat`,
    cursor: 'pointer',
  };
};
