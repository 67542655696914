import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { appSetNotification, appSetLoading } from '@/store';
import { Grid } from '@mui/material';
import { NotificationTypeEnum, State, UserModel, UserKindEnum } from '@/types';
import { usersProvider } from '@/providers';
import PersonalInfo from '../ViewProfile/PersonalInfo';
import MainInfo from '../ViewProfile/MainInfo';
import {
  brokerPersonalInfo,
  managerPersonalInfo,
  foreignBrokerPersonalInfo,
  companyPersonalInfo,
} from '../../utils/ViewProfileDescription';
import { dialogUserTypeData } from '../../utils/dialogDescription';
import { getKindLabel } from '@/utils';
import { Loader } from '@/components';
import { ListingsAndAgencyStaff } from '../ViewProfile/ListingsAndAgencyStaff';
import styles from '../ViewProfile/MainInfo.module.scss';

const UsersProfile = () => {
  const [user, setUser] = useState<UserModel>();
  const [message, setMessage] = useState('');

  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { isLoading } = useSelector(({ app }: State) => app);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getUserData = async () => {
      dispatch(appSetLoading(true));
      if (id) {
        const { ok, data, status, message } = await usersProvider.getUserById(id);
        if (ok && data) {
          setUser(data as UserModel);
        } else {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
          setMessage(message || '');
        }
      }
      dispatch(appSetLoading(false));
    };
    if (isLoggedIn) {
      getUserData();
    }
  }, []);

  const isAgency = user?.kind === UserKindEnum.Agency;
  const isForeign = user?.kind === UserKindEnum.Foreign;

  const userType = user?.kind ? getKindLabel(user?.kind) : '';

  const personalInfo = () => {
    switch (user?.kind) {
      case 'broker':
        return brokerPersonalInfo;
      case 'agency':
        return companyPersonalInfo;
      case 'developer':
        return companyPersonalInfo;
      case 'manager':
        return managerPersonalInfo;
      case 'foreign':
        return foreignBrokerPersonalInfo;
      default:
        console.log(`Unknown action: ${user?.kind}`);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {user && (
        <Grid className={styles.container}>
          <MainInfo user={user} userType={userType} type='user' dataQa='users_profile'/>
          <PersonalInfo user={user} personalInfo={personalInfo()} type='user' dataQa='users_profile'/>
          {user && id && <ListingsAndAgencyStaff id={id} user={user} isAgency={isAgency} isForeign={isForeign} dataQa='users_profile'/>}
        </Grid>
      )}
    </>
  );
};

export default UsersProfile;
