import { useState, useEffect, useRef } from 'react';
import { useMediaQuery, useTheme, Typography, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormContext } from 'react-hook-form';

import {
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  AdFieldFurnitureEnum,
  AdFieldLandKindEnum,
  PropertyKindEnum,
  AdFieldRenovationEnum,
  AdFormModel,
  AdFieldFloorEnum,
  AdFieldOccupancyEnum,
  TransactionTypeEnum,
  AdFieldMultilistingEnum,
  ActivityKindEnum,
} from '@/types';
import {
  FormInput,
  FormSelect,
  FormSelectCustom,
  SectionTitle,
  FormButtons,
  FormMultiSelect,
} from '@/components';
import { PropertyPriceReady } from './PropertyPriceReady';
import { PropertyMultilisting } from './PropertyMultilisting';
import { PropertyPriceResale } from './PropertyPriceResale';
import { PropertyPriceDistress } from './PropertyPriceDistress';
import { MyButton, LabelComponent, Loader } from '@/components';
import IconArrowRight from './arrowRight.svg';

import styles from './PropertyInformation.module.scss';

export const PropertyInformation = () => {
  const [bedsValue, setBedsValue] = useState<AdFieldBedsEnum | null>();
  const [floorValue, setFloorValue] = useState<AdFieldFloorEnum | null>();
  const [occupancyValue, setOccupancyValue] = useState<AdFieldOccupancyEnum | null>();
  const [furnitureValue, setFurnitureValue] = useState<AdFieldFurnitureEnum | null>();
  const [renovationValue, setRenovationValue] = useState<AdFieldRenovationEnum | null>();
  const [bathsValue, setBathsValue] = useState<AdFieldBathsEnum | null>();
  const [completionStatusValue, setCompletionStatusValue] = useState<CompletionStatusEnum>();
  const [dataPickerValue, setDataPickerValue] = useState<Dayjs | null>();
  const [isDataPickerVisible, setIsDataPickerVisible] = useState<boolean>(false);
  const [isDistressError, setIsDistressError] = useState<boolean>(false);
  const [originalPrice, setOriginalPrice] = useState<number>();
  const [sellingPrice, setSellingPrice] = useState<number>();

  const {
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();
  const formValues = getValues();
  const {
    propertyKind,
    beds,
    baths,
    floor,
    furniture,
    renovation,
    activityKind,
    transactionType,
    occupancy,
    completionStatus,
    rentedTill,
    price,
    sellingPrice: formSellingPrice,
  } = formValues;

  const isSale = activityKind === ActivityKindEnum.Sale;
  const isRent = activityKind === ActivityKindEnum.Rent;
  const isReady = transactionType === TransactionTypeEnum.Ready;
  const isResale = transactionType === TransactionTypeEnum.Resale;
  const isDistress = transactionType === TransactionTypeEnum.Distress;

  const convertToDayjs = (isoString: string | null): Dayjs | null => {
    return isoString ? dayjs(isoString) : null;
  };

  useEffect(() => {
    const dayjsValue = convertToDayjs(rentedTill);
    setBedsValue(beds);
    setFloorValue(floor);
    setOccupancyValue(occupancy);
    setFurnitureValue(furniture);
    setRenovationValue(renovation);
    setBathsValue(baths);
    setCompletionStatusValue(completionStatus || CompletionStatusEnum.OffPlan);
    if (occupancy === AdFieldOccupancyEnum.Tenanted) {
      setIsDataPickerVisible(true);
    }
    setDataPickerValue(dayjsValue);
    setOriginalPrice(price);
    setSellingPrice(formSellingPrice);
  }, []);

  const bedsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (errors['beds'] && bedsRef.current) {
      bedsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      const offset = 100;
      const y = bedsRef.current.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    const perPrice = 100 - (100 * Number(sellingPrice)) / Number(originalPrice);
    if (perPrice < 5 && isDistress) {
      setIsDistressError(true);
    } else {
      setIsDistressError(false);
    }
  }, [errors, originalPrice, sellingPrice]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewValues = ['Water', 'Community', 'Burj Khalifa', 'Road', 'Park'];

  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];
  const bathsValues = [
    { label: '1', value: AdFieldBathsEnum.One },
    { label: '2', value: AdFieldBathsEnum.Two },
    { label: '3', value: AdFieldBathsEnum.Three },
    { label: '4', value: AdFieldBathsEnum.Four },
    { label: '5', value: AdFieldBathsEnum.Five },
    { label: '6', value: AdFieldBathsEnum.Six },
    { label: '7', value: AdFieldBathsEnum.Seven },
    { label: '8+', value: AdFieldBathsEnum.EightPlus },
  ];
  const floorValues = [
    { label: 'Low', value: AdFieldFloorEnum.Low },
    { label: 'Middle', value: AdFieldFloorEnum.Middle },
    { label: 'High', value: AdFieldFloorEnum.High },
  ];
  const furnitureValues = [
    { label: 'Furnished', value: AdFieldFurnitureEnum.Furnished },
    { label: 'Partly furnished', value: AdFieldFurnitureEnum.PartlyFurnished },
    { label: 'Unfurnished', value: AdFieldFurnitureEnum.Unfurnished },
  ];
  const renovationValues = [
    { label: 'Renovated', value: AdFieldRenovationEnum.Renovation },
    { label: 'Non renovated', value: AdFieldRenovationEnum.NonRenovation },
  ];
  const occupancyValues = [
    { label: 'Vacant', value: AdFieldOccupancyEnum.Vacant },
    { label: 'Rented', value: AdFieldOccupancyEnum.Tenanted },
  ];
  const completionStatusValues = [
    { label: 'Ready', value: CompletionStatusEnum.Ready },
    { label: 'OffPlan', value: CompletionStatusEnum.OffPlan },
  ];

  // const handleChangePropertyKind = (value: PropertyKindEnum | null) => {
  //   clearErrors([
  //     'propertyKind',
  //     'builtUpArea',
  //     'beds',
  //     'baths',
  //     'floor',
  //     'floorsNum',
  //     'occupancy',
  //     'plotArea',
  //   ]);
  // };

  const handleChangeBeds = (value: AdFieldBedsEnum) => {
    const newValue = bedsValue !== value ? value : null;
    setBedsValue(newValue);
    setValue('beds', newValue);
    clearErrors(['beds']);
  };

  const handleChangeFloor = (value: AdFieldFloorEnum) => {
    const newValue = floorValue !== value ? value : null;
    setFloorValue(newValue);
    setValue('floor', newValue);
  };

  const handleChangeOccupancy = (value: AdFieldOccupancyEnum) => {
    const newValue = occupancyValue !== value ? value : null;
    setOccupancyValue(newValue);
    setValue('occupancy', newValue);
    if (newValue === AdFieldOccupancyEnum.Tenanted) {
      setIsDataPickerVisible(true);
    } else {
      setIsDataPickerVisible(false);
      setDataPickerValue(null);
      setValue('rentedTill', null);
    }
  };

  const handleChangeBaths = (value: AdFieldBathsEnum) => {
    const newValue = bathsValue !== value ? value : null;
    setBathsValue(newValue);
    setValue('baths', newValue);
  };

  const handleChangeFurniture = (value: AdFieldFurnitureEnum) => {
    const newValue = furnitureValue !== value ? value : null;
    setFurnitureValue(newValue);
    setValue('furniture', newValue);
  };

  const handleChangeRenovation = (value: AdFieldRenovationEnum) => {
    const newValue = renovationValue !== value ? value : null;
    setRenovationValue(newValue);
    setValue('renovation', newValue);
  };

  const handleChangeCompletionStatus = (value: CompletionStatusEnum) => {
    setCompletionStatusValue(value);
    setValue('completionStatus', value);
    setOccupancyValue(null);
    setValue('occupancy', null);
    setRenovationValue(null);
    setValue('renovation', null);
  };

  const handleChangeDataPicker = (value: Dayjs | null) => {
    setDataPickerValue(value);
    const dateValue = value ? value.toDate().toISOString() : null;
    setValue('rentedTill', dateValue);
  };

  const bedsAndBathsIsVisible = [
    PropertyKindEnum.Apartment,
    PropertyKindEnum.Villa,
    PropertyKindEnum.CommercialVilla,
    PropertyKindEnum.Townhouse,
    PropertyKindEnum.Penthouse,
    PropertyKindEnum.HotelApartment,
  ].includes(propertyKind);

  const viewIsVisible = propertyKind !== PropertyKindEnum.Warehouse;

  const floorIsVisible = [
    PropertyKindEnum.Apartment,
    PropertyKindEnum.HotelApartment,
    PropertyKindEnum.Floor,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
  ].includes(propertyKind);

  const isAvailability = ![
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ].includes(propertyKind);

  const availabilityIsVisible =
    isAvailability &&
    !isRent &&
    (isReady || completionStatusValue === CompletionStatusEnum.Ready);

  const furnishingIsVisible = ![
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ].includes(propertyKind);

  const renovatingIsVisible = ![
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
    PropertyKindEnum.HotelApartment,
    PropertyKindEnum.Shop,
  ].includes(propertyKind);

  const statusIsVisible = (availabilityIsVisible || isRent) && renovatingIsVisible;

  const changeToResale = () => {
    setValue('transactionType', TransactionTypeEnum.Resale);
    setValue('price', sellingPrice);
    setValue('sellingPrice', null);
    setValue('completionStatus', null);
    setIsDistressError(false);
  };

  return (
    <div>
      <SectionTitle number={5} title='Information about the property' />
      {isDistress && (
        <div>
          <FormButtons
            options={completionStatusValues}
            onClick={handleChangeCompletionStatus}
            activeButton={completionStatusValue}
            size='big'
          />
        </div>
      )}
      {isReady && <PropertyPriceReady />}
      {isResale && <PropertyPriceResale />}
      {isDistress && (
        <PropertyPriceDistress
          setSellingPrice={setSellingPrice}
          setOriginalPrice={setOriginalPrice}
        />
      )}
      {isDistressError && (
        <div className={styles.distressError}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 800,
              textAlign: isMobile ? 'center' : 'left',
              color: 'red',
              margin: '10px 0',
            }}
          >
            Selling price must be at least 5% less than the Original price
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 800,
              textAlign: isMobile ? 'center' : 'left',
              color: 'red',
            }}
          >
            Our platform calculates Distress deals only with a discount of at least 5%.
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 800,
              textAlign: isMobile ? 'center' : 'left',
              color: 'red',
            }}
          >
            Therefore, you need to change the transaction type to Resale. You can do this in two
            ways: go back to the first page (note that all previously entered data will be lost) or
            you can convert it automatically by clicking the button below.
          </Typography>
          <Stack direction='row' className={isMobile ? styles.buttonMobile : styles.button} onClick={changeToResale}>
            <span>To Resale</span>
            <img src={IconArrowRight} alt='' style={{ cursor: 'pointer' }} />
          </Stack>
        </div>
      )}
      <PropertyMultilisting />
      {bedsAndBathsIsVisible && (
        <div ref={bedsRef}>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Bedrooms <span style={{ color: 'red' }}>*</span>{' '}
            {errors['beds'] && (
              <span
                style={{ fontSize: '16px', fontWeight: 400, color: 'red' }}
              >{`${errors['beds'].message}`}</span>
            )}
          </Typography>
          <FormButtons
            name='beds'
            options={bedsValues}
            onClick={handleChangeBeds}
            activeButton={bedsValue}
            size='small'
          />
        </div>
      )}
      <div className={viewIsVisible ? styles.row : styles.rowOnlySize}>
        <div className={viewIsVisible ? styles.size : styles.onlySize}>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Size (sqft) <span style={{ color: 'red' }}>*</span>
          </Typography>
          <FormInput
            uiVariant='blank'
            name='builtUpArea'
            required
            fullWidth
            digitsOnly
            placeholder='Enter size (sqft)'
          />
        </div>
        {viewIsVisible && (
          <div className={styles.view}>
            <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
              View
            </Typography>
            <FormMultiSelect data={viewValues} name='view' />
          </div>
        )}
      </div>
      {floorIsVisible && (
        <div>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Floor
          </Typography>
          <FormButtons
            options={floorValues}
            onClick={handleChangeFloor}
            activeButton={floorValue}
            size='big'
          />
        </div>
      )}
      {availabilityIsVisible && (
        <div className={styles.availRow}>
          <div>
            <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
              Availability
            </Typography>
            <FormButtons
              options={occupancyValues}
              onClick={handleChangeOccupancy}
              activeButton={occupancyValue}
              size='big'
            />
          </div>
          {isDataPickerVisible && (
            <div>
              <Typography sx={{ fontSize: '15px', fontWeight: 400, margin: '20px 0 1px' }}>
                Rented till
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    name='rentedTill'
                    value={dataPickerValue}
                    onChange={(newValue) => handleChangeDataPicker(newValue)}
                    minDate={dayjs()}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
        </div>
      )}
      {furnishingIsVisible && (
        <div>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Furniture
          </Typography>
          <FormButtons
            options={furnitureValues}
            onClick={handleChangeFurniture}
            activeButton={furnitureValue}
            size='big'
          />
        </div>
      )}
      {statusIsVisible && (
        <div>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Status
          </Typography>
          <FormButtons
            options={renovationValues}
            onClick={handleChangeRenovation}
            activeButton={renovationValue}
            size='big'
          />
        </div>
      )}
      {bedsAndBathsIsVisible && (
        <div>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Baths
          </Typography>
          <FormButtons
            options={bathsValues}
            onClick={handleChangeBaths}
            activeButton={bathsValue}
            size='small'
          />
        </div>
      )}
    </div>
  );
};
