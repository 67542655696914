import { useState, MouseEvent, useEffect, useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import {
  AdModel,
  UserModel,
  FileKindEnum,
  RentPeriodEnum,
  ActivityKindEnum,
  TransactionTypeEnum,
  PropertyKindEnum,
  AdFieldMultilistingEnum,
  HeaderDialogsEnum,
} from '@/types';
import {
  StyledPriceBlock,
  Title,
  NumOfAd,
  StyledIconButton,
  StyledPriceSpan,
  StyledOriginalPriceSpan,
} from './styled';
import ImgArrayLeftBtn from '../Ads/AdsListItem/svg/imgArrayLeftBtn.svg';
import ImgArrayRightBtn from '../Ads/AdsListItem/svg/imgArrayRightBtn.svg';
import ItemIconImages from '../Ads/AdsListItem/svg/itemIconImages.svg';
import ItemIconVideo from '../Ads/AdsListItem/svg/itemIconVideo.svg';
import ItemFee from '../Ads/AdsListItem/svg/itemFee.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { priceFormat } from '@/utils';
import { AdMenu } from './AdMenu';
import { icons } from './Icons/icons';
import styles from './Ad.module.scss';
import { Section } from '@/components/Ad/Section';
import { LightSlider, createLightSlider } from './LightSlider';

type PreferredPropertyKinds =
  | 'shop'
  | 'office'
  | 'warehouse'
  | 'residentialLand'
  | 'industrialLand'
  | 'commercialLand';

export const BasicDataBlock = ({
  user,
  page,
  isMobile,
  price,
  setPrice,
  defaultRentFrequency,
  setDefaultRentFrequency,
  handleClickOpenDialog,
}: {
  user: UserModel;
  page: AdModel;
  isMobile: boolean;
  price?: number;
  setPrice: (price: number) => void;
  defaultRentFrequency: RentPeriodEnum | null | undefined;
  setDefaultRentFrequency: (period: RentPeriodEnum | null | undefined) => void;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [lightboxPhotos, setLightboxPhotos] = useState<any>(null);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClickMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const isRent = page.activityKind === ActivityKindEnum.Rent;
  const isDistress = page.transactionType === TransactionTypeEnum.Distress;

  useEffect(() => {
    const lbPhotos = createLightSlider({
      gallery: '#pswp-gallery-photos',
      children: 'a',
      onImageChange: setImageIndex,
    });
    setLightboxPhotos(lbPhotos);
    return () => lbPhotos?.destroy();
  }, []);

  useEffect(() => {
    if (isRent) {
      if (defaultRentFrequency === RentPeriodEnum.Yearly && page.convertedPriceYearly) {
        setPrice(page.convertedPriceYearly);
      }
      if (defaultRentFrequency === RentPeriodEnum.Monthly && page.convertedPriceMonthly) {
        setPrice(page.convertedPriceMonthly);
      }
      if (defaultRentFrequency === RentPeriodEnum.Weekly && page.convertedPriceWeekly) {
        setPrice(page.convertedPriceWeekly);
      }
      if (defaultRentFrequency === RentPeriodEnum.Daily && page.convertedPriceDaily) {
        setPrice(page.convertedPriceDaily);
      }
    } else {
      if (isDistress && page.convertedSellingPrice) {
        setPrice(page.convertedSellingPrice);
      } else {
        setPrice(page.convertedPrice);
      }
    }
  }, [defaultRentFrequency]);

  const { images, pswpPhotos, hasVideo } = useMemo(() => {
    const media = page.propertyAdMedia || [];
    const images = media
      .filter((item) => item.kind === FileKindEnum.Image && item.fileUrl)
      .sort((a, b) => a.order - b.order);

    const imagesPhotos = media
      .filter((item) => item.kind === FileKindEnum.Image && item.fileUrl)
      .sort((a, b) => a.order - b.order);

    const pswpPhotos = images.map((item) => ({
      fileUrl: `${item.fileUrl}_800x600` as string,
      order: item.order,
      id: item.id || `fallback-${item.order}`,
    }));

    const hasVideo = media.some((item) => item.kind === FileKindEnum.Video);

    return { images, pswpPhotos, hasVideo };
  }, [page.propertyAdMedia]);

  const handleChangeImageIndex = (newIndex: number) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (images.length === 0) return;
    setImageIndex((newIndex + images.length) % images.length);
  };

  const handleAdMediaClick =
    (index: number = 0) =>
    () =>
      lightboxPhotos?.loadAndOpen(index);

  const rentPeriodLabel = [
    { label: 'Yearly', value: RentPeriodEnum.Yearly, convertedPrice: page.convertedPriceYearly },
    { label: 'Monthly', value: RentPeriodEnum.Monthly, convertedPrice: page.convertedPriceMonthly },
    { label: 'Weekly', value: RentPeriodEnum.Weekly, convertedPrice: page.convertedPriceWeekly },
    { label: 'Daily', value: RentPeriodEnum.Daily, convertedPrice: page.convertedPriceDaily },
  ];

  const distressPercentFee =
    page.convertedPrice && page.convertedSellingPrice
      ? `${(((Number(page.convertedPrice) - Number(page.convertedSellingPrice)) / Number(page.convertedPrice)) * 100).toFixed(1)}%`
      : '';

  const preferredPropertyKinds = [
    PropertyKindEnum.Shop,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ];
  const propertyKindLabel: Record<PreferredPropertyKinds, string> = {
    shop: 'Shop',
    office: 'Office',
    warehouse: 'Warehouse',
    residentialLand: 'Residential Land',
    industrialLand: 'Industrial Land',
    commercialLand: 'Commercial Land',
  };
  const isPrefTitle = preferredPropertyKinds.includes(page.propertyKind);
  const titlePref =
    page.propertyKind && propertyKindLabel[page.propertyKind as PreferredPropertyKinds];
  const titleText = isPrefTitle ? `${titlePref} at ${page.nameEn}` : page.nameEn;

  const multilistingLabel = {
    [AdFieldMultilistingEnum.Covered]: 'Covered',
    [AdFieldMultilistingEnum.NotCovered]: 'Not Covered',
  };
  const multilistingText = page.multilisting && multilistingLabel[page.multilisting];

  const imgUrl = isMobile
    ? `${images[imageIndex]?.fileUrl}_414x200`
    : `${images[imageIndex]?.fileUrl}_580x350`;

  return (
    <Stack className={styles.list}>
      <Stack className={styles.listItem}>
        {images && images?.length > 0 ? (
          <div
            className={styles.itemImg}
            onClick={handleAdMediaClick(imageIndex)}
            style={{ position: 'relative' }}
          >
            {images && images?.length > 0 && (
              <img data-id={imageIndex} src={imgUrl} alt={page.title} />
            )}
            {images && images.length > 1 && (
              <div className={styles.sliderControls}>
                <img
                  src={ImgArrayLeftBtn}
                  alt='Prev image'
                  onClick={handleChangeImageIndex(imageIndex - 1)}
                />
                <img
                  src={ImgArrayRightBtn}
                  alt='Next image'
                  onClick={handleChangeImageIndex(imageIndex + 1)}
                />
              </div>
            )}
            <div className={styles.itemImgInfo}>
              <div className={styles.itemImgIcons}>
                <div className={styles.itemImgIconsImages} style={{ position: 'relative' }}>
                  <div className={styles.imgTotal}></div>
                  <img src={ItemIconImages} alt='' style={{ position: 'relative', zIndex: 2 }} />
                  <div className={styles.imgTotalNumber}>{images?.length}</div>
                  {hasVideo && (
                    <img
                      src={ItemIconVideo}
                      style={{ position: 'relative', zIndex: 2 }}
                      className={styles.imgTotal}
                      alt=''
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.imgBottomCenterInfo}>
              {imageIndex + 1} of {images?.length}
            </div>
          </div>
        ) : null}
        <Section>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <NumOfAd style={{ lineHeight: '0.7' }}>#{page.fakeId}</NumOfAd>
            {isMobile && (
              <StyledIconButton size='small' onClick={handleClickMenu}>
                <MoreVertIcon />
              </StyledIconButton>
            )}
          </Stack>
          <Stack direction='row' alignItems='start' justifyContent='space-between' spacing={2}>
            <Title>{titleText}</Title>
            {!isMobile && (
              <StyledIconButton
                style={{ color: '#2A3842', fontSize: '22px' }}
                size='small'
                onClick={handleClickMenu}
              >
                <MoreVertIcon />
              </StyledIconButton>
            )}
            <AdMenu
              page={page}
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
              user={user}
              handleClickOpenDialog={handleClickOpenDialog}
            />
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            {price ? (
              <StyledPriceBlock>
                {isDistress ? 'Selling price' : `Price`}
                <StyledPriceSpan>{priceFormat(price ? price : 0)}</StyledPriceSpan>
                <div>{page.convertedCurrency}</div>
              </StyledPriceBlock>
            ) : null}
            <div className={styles.itemFee}>
              <img src={ItemFee} alt='' />
              {multilistingText}
            </div>
          </Stack>
          {isDistress && (
            <StyledPriceBlock>
              Original price
              <StyledOriginalPriceSpan>
                {`${priceFormat(page.convertedPrice ? page.convertedPrice : 0)} ${page.convertedCurrency}`}
              </StyledOriginalPriceSpan>
              <div>{distressPercentFee}</div>
            </StyledPriceBlock>
          )}
          <Stack direction='row' className={styles.rentContainer}>
            {isRent &&
              rentPeriodLabel.map((period) =>
                period.convertedPrice && period.convertedPrice > 0 ? (
                  <Stack
                    key={period.value}
                    className={`${styles.rent} ${period.value === defaultRentFrequency ? styles.rentActive : styles.rentPassive}`}
                    onClick={() => setDefaultRentFrequency(period.value)}
                  >
                    <span>{period.label}</span>
                  </Stack>
                ) : null
              )}
          </Stack>

          {page.addressEn && (
            <Link
              className={styles.linkOnMap}
              href={`https://www.google.com/maps/place/${page.coordinatesLat},${page.coordinatesLong}/@${page.coordinatesLat},${page.coordinatesLong},17z`}
              target='_blank'
            >
              <icons.Location className={styles.locationIcon} />
              <span>{page.addressEn}</span>
            </Link>
          )}
        </Section>
      </Stack>
      <LightSlider images={pswpPhotos} id='pswp-gallery-photos' />
    </Stack>
  );
};
