import { useEffect, useState, SyntheticEvent } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Box, Tabs, Tab, Link, Stack, Paper } from '@mui/material';
import {
  AdminMetricsEnum,
  MetricsChurnRateModel,
  MetricsNotLoggedInModel,
} from '@/types';
import { PeriodAndFrequency } from './PeriodAndFrequency';
import { GroupedBarChart } from './GroupedBarChart';
import { MetricsTable } from './MetricsTable';
import { useChangeQueryParams } from '@/hooks';
import styles from './Metrics.module.scss';


export const Metrics = () => {
  const changeQueryParams = useChangeQueryParams(`admin/metrics/`);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [activeTab, setActiveTab] = useState(AdminMetricsEnum.ChurnRate);

  const [churnRateData, setChurnRateData] = useState<MetricsChurnRateModel>();
  const [lastAdData, setLastAdData] = useState<MetricsChurnRateModel>();
  const [lastRequestData, setLastRequestData] = useState<MetricsChurnRateModel>();
  const [userNotLoggedInData, setUserNotLoggedInData] = useState<MetricsNotLoggedInModel>();

  const [isPeriodChurnRateVisible, setIsPeriodChurnRateVisible] = useState<boolean>(true);
  const [isPeriodAdVisible, setIsPeriodAdVisible] = useState<boolean>(true);
  const [isPeriodRequestVisible, setIsPeriodRequestVisible] = useState<boolean>(true);
  const [isUserTableVisible, setIsUserTableVisible] = useState<boolean>(true);

  const [lastPages, setLastPages] = useState({
    listings: '1',
    staff: '1',
    request: '1',
  });

  const isChurnRate = activeTab === AdminMetricsEnum.ChurnRate;
  const isNotLoggedIn = activeTab === AdminMetricsEnum.NotLoggedIn;
  const isLastPropertyAdPublished = activeTab === AdminMetricsEnum.LastPropertyAdPublished;
  const isLastClientRequestPublished = activeTab === AdminMetricsEnum.LastClientRequestPublished;

  // useEffect(() => {
  //   console.log(activeTab, queryParamsStr);
  // }, [activeTab, queryParamsStr]);

  const handleChangeTab = (_: SyntheticEvent, newStatus: AdminMetricsEnum) => {
    setActiveTab(newStatus);
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as AdminMetricsEnum]: page });
    return changeQueryParams({
      ...queryParams,
      page: '1',
      status: newStatus,
    });
  };

  return (
    <div>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label='view tabs'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-churn-rate'
            aria-controls='tabpanel-churnRate'
            label='Churn Rate'
            value={AdminMetricsEnum.ChurnRate}
            className={styles.tabItem}
          />
          <Tab
            id='tab-not-logged-in'
            aria-controls='tabpanel-notLoggedIn'
            label='Not LoggedIn'
            value={AdminMetricsEnum.NotLoggedIn}
            className={styles.tabItem}
          />
          <Tab
            id='tab-last-property-ad-published'
            aria-controls='tabpanel-lastPropertyAdPublished'
            label='Last Property Ad Published'
            value={AdminMetricsEnum.LastPropertyAdPublished}
            className={styles.tabItem}
          />
          <Tab
            id='tab-last-client-request-published'
            aria-controls='tabpanel-lastClientRequestPublished'
            label='Last Client Request Published'
            value={AdminMetricsEnum.LastClientRequestPublished}
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      {isChurnRate ? (
        <div>
          {isPeriodChurnRateVisible && (
            <PeriodAndFrequency
              onSetValue={setChurnRateData}
              setIsPeriodVisible={setIsPeriodChurnRateVisible}
              type='churnRate'
            />
          )}
          {!isPeriodChurnRateVisible && (
            <Stack sx={{ margin: 5 }}>
              {churnRateData && (
                <GroupedBarChart
                  metricsName='Churn Rate'
                  color='#8884d8'
                  metricsData={churnRateData}
                  setIsPeriodVisible={setIsPeriodChurnRateVisible}
                />
              )}
            </Stack>
          )}
        </div>
      ) : null}
      {isNotLoggedIn ? (
        <div>
          {isUserTableVisible && (
            <PeriodAndFrequency
              onSetValue={setUserNotLoggedInData}
              setIsPeriodVisible={setIsUserTableVisible}
              type='notLoggedIn'
            />
          )}
          {!isUserTableVisible && (
            <Stack sx={{ margin: 5 }}>
              {userNotLoggedInData && <MetricsTable users={userNotLoggedInData} setIsPeriodVisible={setIsUserTableVisible}/>}
            </Stack>
          )}
        </div>
      ) : null}
      {isLastPropertyAdPublished ? (
        <div>
          {isPeriodAdVisible && (
            <PeriodAndFrequency
              onSetValue={setLastAdData}
              setIsPeriodVisible={setIsPeriodAdVisible}
              type='lastPropertyAdPublished'
            />
          )}
          {!isPeriodAdVisible && (
            <Stack sx={{ margin: 5 }}>
              {lastAdData && (
                <GroupedBarChart
                  color='green'
                  metricsName='Last Property Ad Published'
                  metricsData={lastAdData}
                  setIsPeriodVisible={setIsPeriodAdVisible}
                />
              )}
            </Stack>
          )}
        </div>
      ) : null}
      {isLastClientRequestPublished ? (
        <div>
          {' '}
          {isPeriodRequestVisible && (
            <PeriodAndFrequency
              onSetValue={setLastRequestData}
              setIsPeriodVisible={setIsPeriodRequestVisible}
              type='lastClientRequestPublished'
            />
          )}
          {!isPeriodRequestVisible && (
            <Stack sx={{ margin: 5 }}>
              {lastRequestData && (
                <GroupedBarChart
                  color='orange'
                  metricsName='Last Client Request Published'
                  metricsData={lastRequestData}
                  setIsPeriodVisible={setIsPeriodRequestVisible}
                />
              )}
            </Stack>
          )}
        </div>
      ) : null}
    </div>
  );
};
