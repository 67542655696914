import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Stack, Typography, TextField, useMediaQuery, useTheme, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { usersProvider } from '@/providers';
import { MyAutocomplete, MyButton, MyGooglMapsAutocompete, MyPhoneInput, MySelect, MyTextField, CircleNumber, LabelComponent, MyCheckbox } from '@/components';
const FONT_FAMILY = '"Poppins", sans-serif';
const FONT_COLOR = '#2A3842';


const SectionName = styled(Typography)(({ theme }) => ({
  fontFamily: FONT_FAMILY,
  fontSize: '24px',
  fontWeight: '600',
  color: FONT_COLOR,
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const TextFieldStyle = styled(TextField)({
  width: '800px',
  pointerEvents: 'none',
  '& .MuiInputBase-input': {
    fontFamily: FONT_FAMILY,
    fontSize:  '16px',
    color: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    '& fieldset': {
      borderColor: 'transparent',
    },
  },
});

const FormData = ({
  control,
  formData,
  formErrors,
  setValue,
  openConfirmationDialog,
  handleCancelTariff,
  user,
  type,
}) => {
  const [visibility, setVisibility] = useState(() => formData.data.map(item => item.initialVisibility !== false));
  const [inputAutocompleteValue, setAutocompleteValue] = useState("");
  const [updateFormData, setUpdateFormData] = useState(formData);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updatedFieldVisibility = (currentData, name) => {
    return currentData.map((content) => {
      if (content.name === name) {
        return {
          ...content,
          initialVisibility: !content.initialVisibility,
        };
      }
      return content;
    });
};
  
  useEffect(() => {
    if (formData && formData.sectionName && formData.sectionName.name === 'contactData') {
      let updatedData = [...formData.data];
  
      if (user.additionalPhones.length > 1) {
        updatedData = updatedFieldVisibility(updatedData, 'phoneNumberSecond');
        updatedData = updatedFieldVisibility(updatedData, 'addPhone');
      }
      if (user.links.length > 1) {
        updatedData = updatedFieldVisibility(updatedData, 'additionalLinksSecond');
      }
      if (user.links.length === 3) {
        updatedData = updatedFieldVisibility(updatedData, 'additionalLinksThird');
        updatedData = updatedFieldVisibility(updatedData, 'addLink');
      }
  
      setUpdateFormData({
        ...formData,
        data: updatedData
      });
    }
  }, []);
  
  

  useEffect(() => {
    setVisibility(() => updateFormData.data.map(item => item.initialVisibility !== false));
  }, [updateFormData]);




  const handleOnChangeAutocomplete = async (param) => {
    if (param) {
      const { ok, data, status, message } = await usersProvider.getOperationRegion({query: param});
      let options;
      if (ok && data) {
        options = data?.items?.map((item) => item.name);
      }
      else {
        dispatch(appSetNotification('error', message, status));
      }
      setUpdateFormData((prevState) => {
        const updateRegionOptions = prevState.data.map((content) => {
          if (content.name === 'regionOfOperation') {
            return {
              ...content,
              options: options,
            };
          }
          return content;
        })
        return {
          ...prevState,
          data: updateRegionOptions,
        };
      });
    }
  };

  useEffect(() => {
    handleOnChangeAutocomplete(inputAutocompleteValue);
  }, [inputAutocompleteValue]);


  const navigate = useNavigate();
  const allControls = updateFormData.data || [];
  const formGroups = updateFormData.groupSizes.reduce((acc, size, index) => {
    const start = updateFormData.groupSizes.slice(0, index).reduce((sum, value) => sum + value, 0);
    const end = start + size;
    acc.push(allControls.slice(start, end));
    return acc;
  }, []);

  const handleAddLinkInput = (actionKey) => {
    const startIndex = updateFormData.data.findIndex(item => item.actionKey === actionKey);
    if (startIndex === -1) return;
    const nextInvisibleIndex = getNextInvisibleIndex(startIndex);
    if (nextInvisibleIndex !== -1) {
      setVisibility(prev => {
        const updated = [...prev];
        updated[nextInvisibleIndex] = true;
        if (getNextInvisibleIndex(nextInvisibleIndex) === -1) {
          updated[startIndex] = false;
        }
        return updated;
      });
    }
  };

  const handleAddPhoneInput = () => {    
    const phoneIndex = updateFormData.data.findIndex(item => item.name === 'addPhone');
    if (phoneIndex === -1) return;
    if (phoneIndex + 1 < visibility.length && !visibility[phoneIndex + 1]) {
      setVisibility(prev => {
        const updated = [...prev];
        updated[phoneIndex + 1] = true;
        if (phoneIndex + 2 >= visibility.length || visibility[phoneIndex + 2]) {
          updated[phoneIndex] = false;
        }
        const textIndex = updateFormData.data.findIndex(item => item.name === 'text');
        if (textIndex !== -1) {
          updated[textIndex] = false;
        }
        return updated;
      });
    }
  };


  const getNextInvisibleIndex = (fromIndex) => {
    return visibility.findIndex((visible, index) => !visible && index > fromIndex);
  };

  const handleButtonClick = (actionKey) => {
    switch (actionKey) {
      case 'saveAction':
        break;
      case 'cancelAction':
        navigate('/profile/view');
        break;
      case 'addLinkAction':
        handleAddLinkInput(actionKey);
        break;
      case 'addPhoneAction':
        handleAddPhoneInput();
        break;
      case 'supportAction':
        navigate('/chat');
        break;
      case 'passwordAction':
        openConfirmationDialog();
        break;
      case 'authorizationAction':
        // console.log('111111');
        openConfirmationDialog();
        break;
      case 'cancelTariff':
        handleCancelTariff?.();
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };
  
  return (
    <Grid container sx={{ width: isMobile ? '370px' : type === 'settings' ? '700px' : type === 'license' ? '700px' : '986px' }}>
      <Stack alignItems='flex-start' spacing={2} sx={{ marginLeft: '2px', width: '100%' }}>
        {updateFormData.sectionName &&
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <CircleNumber number={updateFormData.sectionName.number} size='40px' colorText='#1650FF' borderColor='#E9EEF1' />
            <SectionName>{updateFormData.sectionName.label}</SectionName>
          </Stack>
        }
        {formGroups.map((groups, groupIndex) => {
          return (
            <Stack
              key={groupIndex}
              direction={{ xs: 'column', sm: 'row' }} 
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              spacing={1}
              sx={{
                width: isMobile || !(groupIndex === 2 && groups.length === 4) ? '100%' : 'auto'
              }}
            >
              {groups.map((group, groupInnerIndex) => {
                const commonProps = { data: group, control, errors: formErrors, disabled: group.disabled };
                const globalIndex = updateFormData.groupSizes.slice(0, groupIndex).reduce((sum, value) => sum + value, 0) + groupInnerIndex;
                if (!visibility[globalIndex]) {
                  return null;
                }
                switch (group.typeOfComponent) {
                  case 'text':
                    return !isMobile && <TextFieldStyle key={group.name} defaultValue={group.name} fullWidth inputProps={{ readOnly: true }}/>
                  case 'input':
                    return <MyTextField key={group.name}  {...commonProps} />
                  case 'phoneInput':
                    return <MyPhoneInput key={group.name} data={group} control={control} errors={formErrors} setValue={setValue} disabled={group.disabled}/>
                  case 'select':
                    return <MySelect key={group.name} data={group} control={control} errors={formErrors} />
                  case 'autocomplete':
                    return <MyAutocomplete key={group.name} data={group} control={control} errors={formErrors} changeAutocompleteValue={setAutocompleteValue}/>
                  case 'googleMapsAutocomplete':
                    return <MyGooglMapsAutocompete key={group.name} data={group} control={control} errors={formErrors} setValue={setValue}/>
                  case 'button':
                    return (
                      <Stack key={group.name} justifyContent='flex-start' sx={{ width: '100%' }}>
                        <Box sx={{ marginTop: { xs: '4px', sm: '0' }, display: { xs: 'none', sm: 'block' } }}>
                          <LabelComponent label='1' opacity='0' />
                        </Box>
                        <MyButton data={group} onClick={() => handleButtonClick(group.actionKey)} />
                      </Stack>
                    );
                  case 'checkbox':
                    return <MyCheckbox key={group.name} {...commonProps} onChange={setValue} />
                  default:
                    return null;
                }
              })}
              {groupIndex === 2 && groups.length === 3 && <Stack sx={{ width: '100%', flexGrow: 1 }} />}
            </Stack>
          )
        })}
      </Stack>
    </Grid>
  );
};

export default FormData;