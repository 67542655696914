import { AdFieldBedsEnum } from '@/types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const bedsValues = [
  { label: 'Studio', value: AdFieldBedsEnum.Studio },
  { label: '1', value: AdFieldBedsEnum.One },
  { label: '2', value: AdFieldBedsEnum.Two },
  { label: '3', value: AdFieldBedsEnum.Three },
  { label: '4', value: AdFieldBedsEnum.Four },
  { label: '5', value: AdFieldBedsEnum.Five },
  { label: '6', value: AdFieldBedsEnum.Six },
  { label: '7', value: AdFieldBedsEnum.Seven },
  { label: '8+', value: AdFieldBedsEnum.EightPlus },
];

export const sortOptions = [
  {
    value: 'Unit price up',
    label: 'Unit price up',
    Icon: ArrowUpwardIcon,
    field: 'price',
    order: 'ASC',
  },
  {
    value: 'Unit price down',
    label: 'Unit price down',
    Icon: ArrowDownwardIcon,
    field: 'price',
    order: 'DESC',
  },
  {
    value: 'Unit area up',
    label: 'Unit area up',
    Icon: ArrowUpwardIcon,
    field: 'areaSQFT',
    order: 'ASC',
  },
  {
    value: 'Unit area down',
    label: 'Unit area down',
    Icon: ArrowDownwardIcon,
    field: 'areaSQFT',
    order: 'DESC',
  },
  {
    value: 'Unit floor up',
    label: 'Unit floor up',
    Icon: ArrowUpwardIcon,
    field: 'floor',
    order: 'ASC',
  },
  {
    value: 'Unit floor down',
    label: 'Unit floor down',
    Icon: ArrowDownwardIcon,
    field: 'floor',
    order: 'DESC',
  },
];

export const UNITS_MODAL_TYPE = {
  deleteOne: 'deleteOne',
  editOne: 'editOne',
  saveAsPdfOne: 'saveAsPdfOne',
  selectAll: 'selectAll',
  unSelectAll: 'unSelectAll',
  deleteAll: 'deleteAll',
  saveAsPdfAll: 'saveAsPdfAll',
};

export const formDefaultValue = {
  defaultValues: {
    buildingId: [],
    beds: 'any',
    areaSQFT: '1',
    floor: '1',
    planCode: [],
    price: 'Option 1',
  },
};
