import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dashboard } from '@/components';
import View from '@/components/AccountManagement/Profile/ViewProfile/ViewProfile';

export const ViewProfilePage = () => {
  const { pathname, search } = useLocation();
  const { type } = useParams();
  const newPathName = pathname+search;
  if (type && newPathName) {
    localStorage.setItem('unsubscribe', type);
    localStorage.setItem('pathName', newPathName);
  }
  return(
    <Dashboard openLogIn isChecked={true}>
      <View />
    </Dashboard>
  )
};
