import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatBackdrop } from '../../Shared';

import { CometChatContext } from '../../../util/CometChatContext';
import Translator from '../../../resources/localization/translator';

import {
  modalWrapperStyle,
  modalCloseStyle,
  modalBodyStyle,
  modalTableStyle,
  captionStyle,
  tableBodyStyle,
} from './style';

import closeIcon from './resources/close.svg';

class CometChatForwardMessage extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    this.state = {
      conversations: [],
    };
  }

  componentDidMount() {
    let conversationRequest = new CometChat.ConversationsRequestBuilder().setLimit(50).build();
    conversationRequest
      .fetchNext()
      .then((response) => {
        this.setState({ conversations: response });
      })
      .catch((error) => this.errorHandler('SOMETHING_WRONG'));
  }

  selectChat = (chat) => {
    this.props.actionGenerated(chat);
  };

  render() {
    return (
      <React.Fragment>
        <CometChatBackdrop show={true} clicked={this.props.close} />
        <div css={modalWrapperStyle(this.context)} className='modal__creategroup'>
          <span
            css={modalCloseStyle(closeIcon, this.context)}
            className='modal__close'
            onClick={this.props.close}
            title={Translator.translate('CLOSE', this.context.language)}
          />
          <div css={modalBodyStyle()} className='modal__body'>
            <div css={captionStyle(this.context)} className='modal__title'>
              {' '}
              {Translator.translate('FORWARD_TO', this.context.language)}{' '}
            </div>
            <table css={modalTableStyle()}>
              <tbody css={tableBodyStyle(this.context)} className='modal__search'>
                {this.state.conversations.map((chat) => (
                  <tr key={chat.conversationId}>
                    <td onClick={() => this.selectChat(chat)}>{chat.conversationWith.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { CometChatForwardMessage };
