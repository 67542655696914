import { AdFieldCurrencyEnum } from '@/types';
import { FormInputNumeric, FormSelect, SectionTitle } from '@/components';

import styles from './PropertyPrice.module.scss';

export const PropertyPrice = () => {
  const currencyValues = [
    { label: 'AED', value: AdFieldCurrencyEnum.AED },
    // { label: 'USD', value: AdFieldCurrencyEnum.USD },
  ];

  return (
    <div className={styles.container}>
      <SectionTitle number={2} title='Price' />

      <div className={styles.row}>
        <div className={styles.currency}>
          <FormSelect
            uiVariant='blank'
            name='currency'
            data={currencyValues}
            defaultValue={currencyValues[0].value}
            label='Currency'
            required
          />
        </div>

        <div className={styles.price}>
          <FormInputNumeric
            uiVariant='blank'
            name='priceFrom'
            fullWidth
            label='Price from'
            placeholder='Price from'
            required
          />
        </div>
        <div className={styles.price}>
          <FormInputNumeric
            uiVariant='blank'
            name='priceTo'
            fullWidth
            label='Price to'
            placeholder='Price to'
            required
          />
        </div>
      </div>
    </div>
  );
};
