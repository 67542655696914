export const presenceStyle = (props, { theme }) => {
  let presenceStatus = {
    backgroundColor: `${theme.backgroundColor.offline}`,
  };

  if (props.status === 'online' || props.status === 'available') {
    presenceStatus = {
      backgroundColor: `${theme.backgroundColor.online}`,
    };
  }

  return {
    width: '12px',
    height: '12px',
    top: '-12px',
    float: 'right',
    position: 'relative',
    ...presenceStatus,
  };
};
