import { Image, StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';

export interface IconTextProps {
  iconSrc?: string;
  children?: ReactNode;
  style?: Style;
  wh?: number;
  size?: string;
  fontWeight?: number;
}

export const Badge = ({ iconSrc, children, style, wh }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      padding: '15px 25px',
      borderRadius: 22,
      color: '#fff',
      backgroundColor: '#2A3842',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'row',
      ...style,
    },
  });

  return (
    <IconText iconSrc={iconSrc} style={styles.container} wh={wh}>
      {children}
    </IconText>
  );
};

export const IconText = ({ iconSrc, children, style, wh = 50, size = '40px', fontWeight = 400 }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
      color: '#2A3842',
      ...style,
    },
    icon: {
      width: wh,
      height: wh,
    },
    text: {
      fontSize: size,
      fontWeight: fontWeight
    }
  });

  return (
    <View style={styles.container}>
      {iconSrc && <Image src={iconSrc} style={styles.icon} />}
      <Text style={styles.text}>{children}</Text>
    </View>
  );
};
