import React, { useState, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, IconButton, TextField } from '@mui/material';
import { MyButton } from '@/components/uiComponents';
import ClearIcon from '@mui/icons-material/Clear';

interface LocalizationsSearchProps {
  languages: string;
  namespaces: string;
  keys: string;
  addLocalization: () => void;
  setKeys: (value: string) => void;
  setNamespaces: (value: string) => void;
  setLanguages: (value: string) => void;
  handleSearch: (languages: string, namespaces: string, keys: string) => void;
}

const LocalizationsSearch: React.FC<LocalizationsSearchProps> = ({
  languages,
  namespaces,
  keys,
  setLanguages,
  setNamespaces,
  setKeys,
  addLocalization,
  handleSearch,
}) => {
  const StyledRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    gap: '12px',
  });

  const StyledTextField = styled(TextField)({
    maxWidth: '676px',
    width: '100%',
  });

  const inputLanguageRef = useRef<HTMLInputElement | null>(null);
  const inputNamespaceRef = useRef<HTMLInputElement | null>(null);
  const inputKeyRef = useRef<HTMLInputElement | null>(null);

  const [inputLanguageValue, setInputLanguageValue] = useState<string>(languages);
  const [inputNamespaceVales, setInputNamespaceVales] = useState<string>(namespaces);
  const [inputKeyVales, setInputKeyVales] = useState<string>(keys);

  const setFocus = (inputRef: any) => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      });
    }
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLanguageValue(event.target.value);
    setFocus(inputLanguageRef);
    setLanguages(event.target.value);
  };

  const handleNamespaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputNamespaceVales(event.target.value);
    setFocus(inputNamespaceRef);
    setNamespaces(event.target.value);
  };

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputKeyVales(event.target.value);
    setFocus(inputKeyRef);
    setKeys(event.target.value);
  };

  return (
    <StyledRow>
      <StyledTextField
        placeholder='Languages'
        inputProps={{ ref: inputLanguageRef }}
        value={inputLanguageValue}
        onChange={handleLanguageChange}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: inputLanguageValue ? 'visible' : 'hidden',
              }}
              onClick={() => {
                setLanguages('');
                setInputLanguageValue('');
                handleSearch('', inputNamespaceVales, inputKeyVales);
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      <StyledTextField
        placeholder='Namespaces'
        inputProps={{ ref: inputNamespaceRef }}
        value={inputNamespaceVales}
        onChange={handleNamespaceChange}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: inputNamespaceVales ? 'visible' : 'hidden',
              }}
              onClick={() => {
                setNamespaces('');
                setInputNamespaceVales('');
                handleSearch(inputLanguageValue, '', inputKeyVales);
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      <StyledTextField
        placeholder='Alias'
        inputProps={{ ref: inputKeyRef }}
        value={inputKeyVales}
        onChange={handleKeyChange}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: inputKeyVales ? 'visible' : 'hidden',
              }}
              onClick={() => {
                setKeys('');
                setInputKeyVales('');
                handleSearch(inputLanguageValue, inputNamespaceVales, '');
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <MyButton
          data={{
            buttonName: 'Search',
            styleType: 'button',
            variant: 'contained',
          }}
          onClick={() => handleSearch(inputLanguageValue, inputNamespaceVales, inputKeyVales)}
        />
        <MyButton
          data={{
            buttonName: 'Add',
            styleType: 'button',
            variant: 'contained',
          }}
          onClick={addLocalization}
        />
      </Box>
    </StyledRow>
  );
};

export default LocalizationsSearch;
