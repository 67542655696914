import { Container, DialogActions, DialogContent } from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import styles from './../../AdForm/AdForm.module.scss';

import FormData from '@/components/AccountManagement/Profile/EditProfile/FormData';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  agencyData,
  brokerData,
  developerData,
  LicenseType,
} from '@/components/Admin/License/types/types';

const LicenseEdit = ({
  open,
  license,
  actions,
  onClose,
  type,
}: {
  open: boolean;
  type: string;
  actions: {
    onDelete: (id: string) => void;
    onEdit: (data: any) => void;
    onCreate: (data: any) => void;
  };
  license?: LicenseType;
  onClose: () => void;
}) => {
  if (!open && !license) {
    return null;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...license,
    },
  });

  useEffect(() => {
    reset(license ? license : {});
  }, [open, license]);

  const onSubmit = async (data: any) => {
    if (license) {
      await actions.onEdit(data);
    } else {
      await actions.onCreate(data);
    }
    onClose();
  };

  const licenseData =
    type === 'agency'
      ? { ...agencyData }
      : type === 'broker'
        ? { ...brokerData }
        : { ...developerData };
  if (!license) {
    licenseData.data = licenseData.data.filter((l) => !['manual', 'id'].includes(l.name));
  }

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle={license ? 'edit license data' : 'add license data'}
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          <FormData
            control={control}
            formData={licenseData}
            formErrors={errors}
            setValue={setValue}
            user={{}}
            openConfirmationDialog={() => {}}
            handleCancelTariff={() => {}}
            type='license'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: license ? 'Save information' : 'Create',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={handleSubmit((data) => {
                onSubmit(data);
              })}
            />
            <MyButton
              data={{
                buttonName: license ? 'Delete license' : 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={license ? () => actions.onDelete(license.id) : onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default LicenseEdit;
