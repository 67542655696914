import { Container, DialogContent } from '@mui/material';
import { MyDialog } from '@/components';
import { useEffect, useState } from 'react';
import { Loader } from '@/components';
import { adminProvider } from '@/providers';

import { useDispatch } from 'react-redux';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum } from '@/types';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';

const BlockHistory = ({
  open,
  userId,
  onClose,
}: {
  open: boolean;
  userId: number;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [blockHistory, setBlockHistory] = useState([]);

  useEffect(() => {
    if (open && userId) {
      adminProvider.getBlockHistory(userId).then(({ ok, data }) => {
        if (ok) {
          setBlockHistory((data as any).items);
        } else {
          dispatch(appSetNotification(NotificationTypeEnum.Error, 'Error loading block history'));
        }
        setIsLoading(false);
      });
    }
  }, [userId, open]);

  return (
    <MyDialog open={open} onClose={onClose} dialogTitle='Block history' width='900'>
      <Container component='main'>
        <DialogContent
          dividers
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            textAlign: 'center',
            padding: 0,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && !blockHistory.length && <div>No block history</div>}
          {!isLoading && !!blockHistory.length && (
            <Table>
              <TableBody>
                {blockHistory.map((history: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{history.createdAt}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      <b>
                        {history.kind === 'block'
                          ? `Block user by ${history.adminName} (${history.reason})`
                          : `Unblock user by ${history.adminName}`}
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Container>
    </MyDialog>
  );
};

export default BlockHistory;
