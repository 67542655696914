import { Box } from '@mui/material';
import UnitCard from './UnitsViewCard';
import { IUnitSearchItems } from '@/types/responses';

export const UnitsViewCards = ({
  units,
  selectedUnits,
  onClickUnit,
  onSelectUnit,
}: {
  units?: IUnitSearchItems[];
  selectedUnits: { [id: string]: boolean | undefined };
  onClickUnit: (modalType: string, options: IUnitSearchItems[]) => () => void;
  onSelectUnit: (unitId: string, isChecked: boolean) => () => void;
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {units?.map((unit) => (
        <UnitCard
          key={unit.id}
          isSelected={selectedUnits[unit.id] || false}
          unit={unit}
          onClickUnit={onClickUnit}
          onSelectUnit={onSelectUnit}
        />
      ))}
    </Box>
  );
};
