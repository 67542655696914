import { FormControl, MenuItem, Typography } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';

import styles from './FormSelectCustom.module.scss';
import { ReactComponent as CaretDown } from './CaretDown.svg';

type FormSelectCustomProps = {
  label?: string;
  value: any;
  required?: boolean;
  errorText?: string;
  textCapitalize?: boolean;
  endAdornment?: React.ReactNode;
} & SelectProps;

export const FormSelectCustom = ({
  label,
  value,
  required,
  children,
  errorText,
  textCapitalize = true,
  endAdornment,
  renderValue
}: FormSelectCustomProps): JSX.Element => (
  <FormControl sx={{ m: 1, width: '100%', margin: '0 20px 0 0' }} error={!!errorText}>
    {label && (
      <p className={styles.label}>
        {label}
        {required && <span>*</span>}
      </p>
    )}
    <Select
      value={'-1'}
      sx={{
        borderRadius: '14px',
        border: errorText ? '1px solid red' : '1px solid #E9EEF1',
        textTransform: textCapitalize ? 'capitalize' : 'none',
      }}
      renderValue={renderValue}
      className={styles.fieldset}
      IconComponent={(_props) => {
        const rotate = _props.className.toString().includes('iconOpen');
        return (
          <div
            className={styles.caretDown}
            style={{
              transform: rotate ? 'rotate(180deg)' : 'none',
            }}
          >
            <CaretDown />
          </div>
        );
      }}
      
    >
      <MenuItem style={{ display: 'none', alignItems: 'center' }} hidden key={1} value={'-1'}>
        {<span style={{ color: '#FFF', fontSize: '0px' }}>*</span>}
        <span style={{ alignItems: 'center', gap: '8px' }}>
          {value}
          {endAdornment}
        </span>
      </MenuItem>
      {children}
    </Select>
    {errorText && (
      <Typography sx={{ fontSize: '12px', color: 'red', textAlign: 'right' }}>
        {errorText}
      </Typography>
    )}
  </FormControl>
);
