import {
  ResponseType,
  AdsSortingType,
  PaginationType,
  ClientRequestModel,
  ClientRequestFilteringType,
  ClientRequestGetAllResponseType,
} from '@/types';
import { apiClient } from '@/providers';

export const clientRequestProvider = {
  getClientRequest: async (
    filteringObj: ClientRequestFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient('/clientRequest/search', 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      sort: [{ ...sortingObj }],
      filter: [{ ...filteringObj }],
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as ClientRequestGetAllResponseType;
    return { ...response, data };
  },

  getClientRequestById: async (id: string) => {
    const response = await apiClient<ClientRequestModel>(`/clientRequest/${id}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  updateClientRequestById: async (id: string, payload: ClientRequestModel): Promise<ResponseType> => {
    const response = await apiClient(`/clientRequest/${id}`, 'PATCH', payload);
    return { ...response };
  },

  createClientRequest: async (payload: ClientRequestModel): Promise<ResponseType> => {
    const response = await apiClient('/clientRequest', 'POST', payload);

    return { ...response, data: null };
  },

  deleteClientRequest: async (id: string) => {
    const response = await apiClient(`/clientRequest/${id}`, 'DELETE');
    return response;
  },
};
