import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, FormHelperText, Tabs, Tab, useMediaQuery, useTheme } from '@mui/material';
import { PropertyKindEnum } from '@/types';
import { SectionTitle, FormButtons } from '@/components';

import styles from './PropertyType.module.scss';

export const PropertyType = ({
  setIsLocationVisible,
  setIsPageChanged,
}: {
  setIsLocationVisible: (value: boolean) => void;
  setIsPageChanged: (value: boolean) => void;
}) => {
  const { getValues, setValue } = useFormContext();
  const formValues = getValues();
  const { propertyKind } = formValues;

  const [propertyKindValue, setPropertyKindValue] = useState<PropertyKindEnum | undefined>();
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);

  useEffect(() => {
    setPropertyKindValue(propertyKind);
    const isSecondTab = propertyKind &&
    [
      PropertyKindEnum.Shop,
      PropertyKindEnum.Office,
      PropertyKindEnum.Warehouse,
      PropertyKindEnum.CommercialVilla,
      PropertyKindEnum.CommercialLand,
      PropertyKindEnum.IndustrialLand,
    ].includes(propertyKind);
    if (isSecondTab) {
      setPropertyTypeTab(1);
    } else {
      setPropertyTypeTab(0);
    }
  }, [propertyKind]);

  const propertyTypeValuesFirstTab = [
    { label: 'Apartment', value: PropertyKindEnum.Apartment },
    { label: 'Villa', value: PropertyKindEnum.Villa },
    { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
    { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
    { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
    { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
    { label: 'Floor', value: PropertyKindEnum.Floor },
    { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
  ];

  const propertyTypeValuesSecondTab = [
    { label: 'Shop', value: PropertyKindEnum.Shop },
    { label: 'Office', value: PropertyKindEnum.Office },
    { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
    { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
    { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
    { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
  ];

  const onHandlerClick = (value: PropertyKindEnum) => {
    if (value !== propertyKind) {
      setValue('propertyKind', value);
      setValue('coordinatesLat', null);
      setValue('coordinatesLong', null);
      setValue('addressEn', null);
      setPropertyKindValue(value);
      setIsLocationVisible(true);
      setIsPageChanged(false);
    }
  };

  return (
    <div>
      <SectionTitle number={3} title='Select property type' />
      <div className={styles.floor}>
        <Tabs
          value={propertyTypeTab}
          onChange={(_, val) => setPropertyTypeTab(val)}
          aria-label='property type tabs'
          className={styles.tabsContainer}
        >
          <Tab
            label='Residential'
            id='property-type-tab-0'
            aria-controls='property-type-tabpanel-0'
            className={styles.tabItem}
          />
          <Tab
            label='Commercial'
            id='property-type-tab-1'
            aria-controls='property-type-tabpanel-1'
            className={styles.tabItem}
          />
        </Tabs>
        {propertyTypeTab === 0 && (
          <FormButtons
            options={propertyTypeValuesFirstTab}
            onClick={onHandlerClick}
            activeButton={propertyKindValue}
            size='large'
          />
        )}
        {propertyTypeTab === 1 && (
          <FormButtons
            options={propertyTypeValuesSecondTab}
            onClick={onHandlerClick}
            activeButton={propertyKindValue}
            size='large'
          />
        )}
      </div>
    </div>
  );
};
