import { CircularProgress, Stack } from '@mui/material';
import { styled } from '@mui/system';

const Wrapper = styled(Stack)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

export const Loader = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);
