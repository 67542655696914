export const chatComposerStyle = () => {
  return {
    padding: '16px',
    zIndex: '1',
    order: '3',
    position: 'relative',
    minHeight: '120px',
    maxWidth: '570px',
  };
};

export const composerInputStyle = ({ emojiViewer, stickerViewer }, { theme }) => {
  return {
    border: `1px solid ${theme.borderColor.primary}`,
    borderRadius: emojiViewer || stickerViewer ? '0 0 8px 8px' : '8px',
  };
};

export const messageInputStyle = (disabled, { emojiViewer, stickerViewer }, { theme }) => {
  const disabledState = disabled
    ? {
        pointerEvents: 'none',
        opacity: '0.4',
      }
    : {};

  return {
    backgroundColor: `${theme.backgroundColor.white}`,
    borderRadius: emojiViewer || stickerViewer ? '0' : '8px 8px 0px 0px',
    minHeight: '48px',
    maxHeight: '100px',
    padding: '14px 16px',
    outline: 'none',
    fontSize: '16px',
    color: `${theme.color.primary}`,
    lineHeight: '20px',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    zIndex: '1',
    userSelect: 'text',
    ...disabledState,
    '&:empty:before': {
      content: 'attr(placeholder)',
      opacity: '0.5',
      pointerEvents: 'none',
      display: 'block' /* For Firefox */,
    },
  };
};

export const inputStickyStyle = (disabled, { theme }) => {
  const disabledState = disabled ? { pointerEvents: 'none' } : {};

  return {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: `${theme.backgroundColor.lightBlue2}`,
    borderRadius: '0px 0px 8px 8px',
    padding: '8px 16px',
    height: '40px',
    ...disabledState,
  };
};

export const stickyAttachmentStyle = () => {
  return {
    display: 'flex',
    width: 'auto',
  };
};

export const stickyAttachButtonStyle = (img, { theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '24px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.primary}`,
    },
  };
};

export const filePickerStyle = ({ showFilePicker }) => {
  const active = showFilePicker
    ? {
        width: 'calc(100% - 20px)',
        opacity: '1',
      }
    : {};

  return {
    width: '0',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: '1',
    opacity: '0',
    transition: 'width 0.5s linear',
    ...active,
  };
};

export const fileListStyle = () => {
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0 0 16px',
  };
};

export const fileItemStyle = (img, { theme }) => {
  return {
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 16px 0 0',
    ' > i': {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.primary}`,
    },
    ' > input': {
      display: 'none',
    },
  };
};

export const stickyButtonStyle = ({ showFilePicker }) => {
  return {
    display: `${showFilePicker ? 'none' : 'flex'}`,
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
  };
};

export const stickerBtnStyle = (img, { theme }) => {
  return {
    cursor: 'pointer',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.primary}`,
    },
  };
};

export const emojiButtonStyle = (img, { theme }) => {
  return {
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.primary}`,
    },
  };
};

export const sendButtonStyle = (img, { theme }) => {
  return {
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.active}`,
    },
  };
};

export const editPreviewContainerStyle = ({ theme }, keyframes) => {
  const slideAnimation = keyframes`
    from {
        bottom: -60px
    }
    to {
        bottom: 0px
    }`;

  return {
    backgroundColor: `${theme.backgroundColor.white}`,
    borderRadius: '4px',
    borderLeft: `8px solid ${theme.backgroundColor.lightBlue3}`,
    animation: `${slideAnimation} 0.5s ease-out`,
    padding: '5px 12px 10px',
    marginBottom: '3px',
    minHeight: '50px',
    maxHeight: '150px',
    height: 'calc(100% - 90px)',
    overflowY: 'auto',
  };
};

export const previewHeadingStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
};

export const previewTextStyle = ({ theme }) => {
  return {
    padding: '3px 0 7px',
    fontSize: '14px',
    color: `${theme.color.primary}`,
    opacity: '0.5',
  };
};

export const previewCloseStyle = (img, { theme }) => {
  return {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
  };
};

export const previewMessageStyle = ({ theme }) => {
  return {
    fontSize: '14px',
    lineHeight: '18px',
    color: `${theme.color.primary}`,
  };
};
