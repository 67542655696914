import moment from 'moment';

import {
  AUTH_ACCESSTOKEN,
  AUTH_REFRESHTOKEN,
  AUTH_EXPIRESFROM,
  AUTH_EXPIRESIN,
  REFRESH_TOKEN_TIME_GAP,
} from '@/constants';
import { AuthGetTokenResponseType } from '@/types';
import { authProvider } from './authProvider';

export const sessionProvider = {
  initAuthSession: ({ accessToken, expiresIn, refreshToken }: AuthGetTokenResponseType) => {
    const expiresFrom = moment().format('X');
    localStorage.setItem(AUTH_ACCESSTOKEN, accessToken);
    localStorage.setItem(AUTH_REFRESHTOKEN, refreshToken);
    localStorage.setItem(AUTH_EXPIRESFROM, expiresFrom);
    localStorage.setItem(AUTH_EXPIRESIN, String(expiresIn));
  },

  removeAuthSession: () => {
    localStorage.removeItem(AUTH_ACCESSTOKEN);
    localStorage.removeItem(AUTH_REFRESHTOKEN);
    localStorage.removeItem(AUTH_EXPIRESFROM);
    localStorage.removeItem(AUTH_EXPIRESIN);
  },

  initRefreshToken: async () => {
    const accessToken = localStorage.getItem(AUTH_ACCESSTOKEN);
    const refreshToken = localStorage.getItem(AUTH_REFRESHTOKEN);
    const expiresFrom = localStorage.getItem(AUTH_EXPIRESFROM);
    const expiresIn = localStorage.getItem(AUTH_EXPIRESIN);
    if (accessToken && refreshToken && expiresFrom && expiresIn) {
      const parsedTime = moment.unix(parseInt(expiresFrom));
      const diffTime = moment().diff(parsedTime, 'seconds');
      const refreshAfter = parseInt(expiresIn) - diffTime - REFRESH_TOKEN_TIME_GAP;
      setTimeout(() => {
        authProvider.refreshToken(refreshToken);
      }, refreshAfter * 1000); // seconds  to milliseconds
    }
  },
};
