import React, { useState } from 'react';
import {
  Avatar,
  Stack,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Link,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdModel, AdCreatorType, UserModel, UserTariffPlanEnum, HeaderDialogsEnum } from '@/types';
import { stringAvatar } from '@/utils';
import { icons } from './Icons/icons';
import { MyCommunicate } from '@/components';
import { UserName, StyledAccordion, UserType } from './styled';
import { Section } from '@/components/Ad/Section';

interface IContactBlockProps {
  page: AdModel;
  userType: string;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}

interface IContactAccordionProps {
  contact: AdCreatorType;
  expanded: boolean;
  onExpandClick: () => void;
  user: UserModel;
}

export const ContactBlock: React.FC<IContactBlockProps> = ({
  page,
  userType,
  user,
  handleClickOpenDialog,
}) => {
  // const [expanded, setExpanded] = useState<string | false>(false);
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;
  const name = !page?.propertyAdCreator?.name ? 'Creator of property' : page?.propertyAdCreator?.name;
  // const handleExpandClick = (id: string) => setExpanded((prev) => (prev === id ? false : id));
  // const announcerContact = page ? page.propertyAdAnnouncers : null;
  const adInfo = {
    name: name,
    activityKind: page.activityKind,
    title: page.title,
    currency: page.currency,
    price: page.price,
    location: page.nameEn,
    id: page.id,
  };
  const isCheckedMy =
    user && page && page.propertyAdCreator && user.id === page.propertyAdCreator.id;

  return (
    <Stack alignItems='flex-start' gap='20px' sx={{ width: '100%' }}>
      <Section>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          style={{ position: 'relative' }}
          sx={{ filter: page?.propertyAdCreator?.name ? 'blur(0px)' : 'blur(5px)' }}
        >
          <Avatar
            src={page?.propertyAdCreator?.avatar ? page?.propertyAdCreator?.avatar : ''}
            {...stringAvatar(page.propertyAdCreator?.name)}
          />
          <Stack>
            <Link
              href={
                !page?.propertyAdCreator?.name
                  ? undefined
                  : isCheckedMy
                    ? '/profile/view'
                    : `/users/${page.propertyAdCreator?.id}`
              }
              title={name}
              style={{ textDecoration: 'none', color: '#2A3842' }}
              onClick={() => !page?.propertyAdCreator?.name && handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)}
            >
              <UserName>{name}</UserName>
              <UserType>{!page?.propertyAdCreator?.name ? 'User type' : userType}</UserType>
            </Link>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={27}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <icons.ActiveAds />
            <Typography>Active ads</Typography>
          </Stack>
          <Stack alignItems='center'>
            <Typography>{page?.propertyAdCreator?.name ? page?.propertyAdCreator?.propertyAdsCount : '' }</Typography>
          </Stack>
        </Stack>
        {!isCheckedMy && (
          <Stack>
            <MyCommunicate
              user={page?.propertyAdCreator}
              adInfo={adInfo}
              isTariffFree={isTariffFree}
            />
          </Stack>
        )}
      </Section>

      {/* {announcerContact && announcerContact.length > 0 && (
        <Section>
          <Stack direction='row' alignItems='center' spacing={1}>
            <icons.Users />
            <Typography>Another contacts</Typography>
          </Stack>
          {announcerContact.map((contact) => (
            <ContactAccordion
              key={contact.id}
              contact={contact}
              expanded={expanded === contact.id}
              onExpandClick={() => handleExpandClick(contact.id)}
              user={user}
            />
          ))}
        </Section>
      )} */}
    </Stack>
  );
};

const ContactAccordion: React.FC<IContactAccordionProps> = ({
  contact,
  expanded,
  onExpandClick,
  user,
}) => {
  const isCheckedMy = user && contact && user.id === contact.id;
  return (
    <StyledAccordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={onExpandClick} />}
        aria-controls={`panel-${contact.id}`}
        id={`panel-${contact.id}`}
        sx={{ padding: 0 }}
      >
        <Link
          href={isCheckedMy ? '/profile/view' : `/users/${contact.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar src={contact?.avatar ? contact?.avatar : ''} {...stringAvatar(contact.name)} />
            <UserName sx={{ color: '#2A3842' }}>{contact.name}</UserName>
          </Stack>
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '4px' }}>
        <Stack alignItems='flex-start' justifyContent='center' spacing={2}>
          <Stack direction='row' spacing={23}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <icons.ActiveAds />
              <Typography>Active ads</Typography>
            </Stack>
            <Typography>{contact?.propertyAdsCount}</Typography>
          </Stack>
          <Stack sx={{ width: '100%' }}>{!isCheckedMy && <MyCommunicate user={contact} />}</Stack>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
};
