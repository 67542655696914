import { useState } from 'react';
import { Typography } from '@mui/material';
import { Title, TextWrapper, StyledButton } from './styled';
import { Section } from '@/components/Ad/Section';

export const DescriptionBlock = ({ description }: { description: string }) => {
  const [isExpanded, setIsExpanded] = useState(description.length < 400);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Section>
      <Title>Description</Title>
      <TextWrapper isExpanded={isExpanded}>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
      </TextWrapper>
      {description.length > 400 && (
        <StyledButton onClick={handleToggle}>{isExpanded ? 'Read less' : 'Read more'}</StyledButton>
      )}
    </Section>
  );
};
