import { ActionsTypes, NotificationTypeEnum, RequestGetCountOfUnreadResponseType } from '@/types';
import { APP_SET_LOADING, APP_SET_NOTIFICATION, APP_GET_REQUEST_COUNT_OF_UNREAD } from '@/store';

export const appSetLoading = (payload: boolean): ActionsTypes => ({
  type: APP_SET_LOADING,
  payload,
});

export const appSetNotification = (
  type?: NotificationTypeEnum,
  message?: string,
  status?: number
): ActionsTypes => ({
  type: APP_SET_NOTIFICATION,
  payload: { type, message, status },
});

export const appGetRequestCount = (payload: number | null) => ({
  type: APP_GET_REQUEST_COUNT_OF_UNREAD,
  payload,
});
