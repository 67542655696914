import { FormControl, FormHelperText, SxProps, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './FormInput.module.scss';

export const FormInput = ({
  name,
  withoutForm,
  floor,
  uiVariant,
  minWidth,
  digitsOnly,
  isLocation,
  isDisable,
  ...otherProps
}: {
  name: string;
  withoutForm?: boolean;
  floor?: string;
  minWidth?: string;
  uiVariant?: 'blank';
  digitsOnly?: boolean;
  isLocation?: boolean;
  isDisable?: boolean;
} & TextFieldProps) => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const defaultStyles: SxProps =
    uiVariant === 'blank' ? { width: '100%' } : { width: '100%', margin: '0 20px 14px 0' };

  if (minWidth) {
    defaultStyles.minWidth = minWidth;
  }

  if (withoutForm) {
    return (
      <FormControl sx={defaultStyles}>
        {otherProps.label && (
          <FormHelperText className={styles.label}>{otherProps.label}</FormHelperText>
        )}
        <TextField {...otherProps} label='' />
      </FormControl>
    );
  }

  const onlyNumbers = (value: string) => {
    return value.replace(/[^0-9]/g, '');
  };

  const errorText = (name: string) => {
    if (errors[name]?.message === 'areaTo must be a `number` type, but the final value was: `NaN` (cast from the value `""`).') return '"Area to" is a required field';
    if (errors[name]?.message === 'areaFrom must be a `number` type, but the final value was: `NaN` (cast from the value `""`).') return '"Area from" is a required field';
    if (!isLocation && errors['coordinatesLong'] && name === 'title') {
      return 'You need to put a marker on the map (after you enter the title)';
    }
    return errors[name]?.message?.toString()
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field }) => (
        <FormControl sx={defaultStyles}>
          {otherProps.label && (
            <FormHelperText className={styles.label}>
              {otherProps.label}
              {otherProps.required && <span>*</span>}
            </FormHelperText>
          )}
          <TextField
            disabled={isDisable}
            minRows='10'
            {...otherProps}
            {...field}
            error={!!errors[name] || (name === 'title' && !isLocation && !!errors['coordinatesLong'])}
            label=''
            helperText={errors[name] || (name === 'title' && !isLocation && errors['coordinatesLong']) ? errorText(name) : ''}
            onChange={(e) => {
              const { value } = e.target;
              const numericValue = onlyNumbers(value);
              if (digitsOnly) {
                field.onChange(numericValue);
              } else {
                field.onChange(e);
              }
              if (otherProps.onChange) {
                otherProps.onChange(e);
              }
            }}
          />
        </FormControl>
      )}
    />
  );
};
