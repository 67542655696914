import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  PropertyKindEnum,
  NotificationTypeEnum,
  State,
  UserKindEnum,
  ClientRequestModel,
  AdFieldMultilistingEnum,
  CompletionStatusEnum,
  RentPeriodEnum,
  UserTariffPlanEnum,
} from '@/types';
import { adsProvider, clientRequestProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { clientRequestSchemasForm } from '@/schemas';
import { Loader, MyButton, MyDialog, PageNotFound, SectionHeader } from '@/components';

import { PropertyInformation } from './PropertyInformation';
import { PropertyPrice } from './PropertyPrice';
import { PropertyDescription } from './PropertyDescription';
import styles from './ClientRequestForm.module.scss';
import { PropertyLocation } from './PropertyLocation';
import { PropertyType } from './PropertyType';
import { ActivityType } from './ActivityType';
import { CompletionStatus } from './CompletionStatus';

export const ClientRequestForm = () => {
  const dispatch = useDispatch();
  const {
    id: userId,
    kind: userKind,
    employer,
    verified,
    tariffPlan,
  } = useSelector(({ auth }: State) => auth.user);
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isEditForm = !!id;

  const [isLoading, setIsLoading] = useState<boolean>(isEditForm);
  const [isShownPublishedDialog, setIsShownPublishedDialog] = useState<boolean>(false);
  const [creatorId, setCreatorId] = useState<string>();

  const [typeOfActivity, setTypeOfActivity] = useState<ActivityKindEnum>(ActivityKindEnum.Sale);
  const [completionStatus, setCompletionStatus] = useState<CompletionStatusEnum | undefined>(
    CompletionStatusEnum.All
  );
  const [propertyKind, setPropertyKind] = useState<PropertyKindEnum | null>();
  const [period, setPeriod] = useState<RentPeriodEnum | null>();
  const [bedsArr, setBedsArr] = useState<AdFieldBedsEnum | AdFieldBedsEnum[] | null>();
  const [baths, setBaths] = useState<AdFieldBathsEnum | null>();

  const [priceFrom, setPriceFrom] = useState<number>();
  const [priceTo, setPriceTo] = useState<number>();
  const [multilisting, setMultilisting] = useState<AdFieldMultilistingEnum>(
    AdFieldMultilistingEnum.Covered
  );

  const [addressEn, setAddressEn] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [coordinatesLat, setCoordinatesLat] = useState<number>();
  const [coordinatesLong, setCoordinatesLong] = useState<number>();
  const [title, setTitle] = useState<string>();

  const formMethods = useForm<ClientRequestModel>({
    resolver: yupResolver(clientRequestSchemasForm) as any,
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    setValue('typeOfActivity', ActivityKindEnum.Sale);
    setValue('completionStatus', CompletionStatusEnum.All);
  }, []);

  useEffect(() => {
    const getClientRequestById = async (id: string) => {
      setIsLoading(true);
      const {
        ok,
        data: responseData,
        status,
        message,
      } = await clientRequestProvider.getClientRequestById(id);
      if (ok) {
        const data = responseData as ClientRequestModel;
        setCreatorId(data.userId);
        setValue('typeOfActivity', data.typeOfActivity);
        setTypeOfActivity(data.typeOfActivity);
        setValue('completionStatus', data.completionStatus);
        setCompletionStatus(data.completionStatus);
        setValue('propertyKind', data.propertyKind);
        setPropertyKind(data.propertyKind);
        setValue('areaFrom', data.areaFrom);
        setValue('areaTo', data.areaTo);
        setValue('bedsArr', data.bedsArr);
        setBedsArr(data.bedsArr);
        setValue('baths', data.baths);
        setBaths(data.baths);
        setValue('currency', data.currency);
        setValue('period', data.period);
        setPeriod(data.period);
        setValue('priceFrom', data.priceFrom);
        setValue('priceTo', data.priceTo);
        setPriceFrom(data.priceFrom);
        setPriceTo(data.priceTo);
        setValue('multilisting', data.multilisting || AdFieldMultilistingEnum.Covered);
        setMultilisting(data.multilisting || AdFieldMultilistingEnum.Covered);
        setValue('addressEn', data.addressEn);
        setAddressEn(data.addressEn);
        setValue('placeId', data.placeId);
        setValue('coordinatesLong', data.coordinatesLong);
        setCoordinatesLong(parseFloat(data.coordinatesLong.replace(',', '.')));
        setValue('coordinatesLat', data.coordinatesLat);
        setCoordinatesLat(parseFloat(data.coordinatesLat.replace(',', '.')));
        setValue('title', data.title);
        setValue('description', data.description);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    };

    if (id) {
      getClientRequestById(id);
    }
  }, []);

  useEffect(() => {
    const firstErrorElement = Object.keys(errors)[0];
    if (firstErrorElement) {
      console.error('Form errors:', errors);
      const elementForFocus = document.getElementsByClassName('Mui-error')[0];
      if (elementForFocus) {
        const offset =
          elementForFocus.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          150;
        window.scrollTo({
          behavior: 'smooth',
          top: offset,
        });
      }
    }
  }, [errors]);

  const handleCancel = () => navigate('/client-requests?page=1&status=my-requests');

  const changeOldParams = (propertyKind: string, values: ClientRequestModel) => {
    const newValues = { ...values };
    if (typeOfActivity === ActivityKindEnum.Sale) {
      newValues.period = null;
    }
    if (
      propertyKind === PropertyKindEnum.IndustrialLand ||
      propertyKind === PropertyKindEnum.CommercialLand ||
      propertyKind === PropertyKindEnum.ResidentialLand ||
      propertyKind === PropertyKindEnum.Warehouse
    ) {
      newValues.bedsArr = null;
      newValues.baths = null;
    }
    if (
      propertyKind === PropertyKindEnum.Office ||
      propertyKind === PropertyKindEnum.Shop ||
      propertyKind === PropertyKindEnum.CommercialBuilding
    ) {
      newValues.bedsArr = null;
    }
    if (
      propertyKind === PropertyKindEnum.CommercialVilla ||
      propertyKind === PropertyKindEnum.LabourCamp
    ) {
      newValues.baths = null;
    }
    return newValues;
  };

  const submitHandler: SubmitHandler<ClientRequestModel> = async (values) => {
    const newValues = changeOldParams(values.propertyKind, values);
    setTitle(values.title);
    const data: ClientRequestModel = {
      ...newValues,
      userId: userId,
      createdAt: !isEditForm ? new Date().toISOString() : undefined,
      updatedAt: new Date().toISOString(),
    };
    setIsLoading(true);
    let response;
    if (isEditForm) {
      response = await clientRequestProvider.updateClientRequestById(
        id,
        data as ClientRequestModel
      );
    } else {
      response = await clientRequestProvider.createClientRequest(data as ClientRequestModel);
    }

    const { ok, status, message } = response;
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setIsShownPublishedDialog(true);
    }
    setIsLoading(false);
  };

  const handleClosePublishedDialog = () => setIsShownPublishedDialog(false);

  const handleDonePublishedDialog = () => navigate('/client-requests?page=1&status=my-requests');

  if (isLoading) {
    return <Loader />;
  }

  if (
    (userKind === UserKindEnum.Foreign && !verified) ||
    userKind === UserKindEnum.Admin ||
    (userKind === UserKindEnum.Broker && employer === null) ||
    (userKind === UserKindEnum.Manager && employer === null) ||
    (isEditForm && String(creatorId) !== userId) ||
    tariffPlan?.name === UserTariffPlanEnum.Free
  ) {
    return <PageNotFound />;
  }

  return (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <Box component='form' autoComplete='off'>
          <SectionHeader
            title={isEditForm ? 'Edit property request' : 'Create a new property request'}
          />
          <ActivityType
            typeOfActivity={typeOfActivity}
            setTypeOfActivity={setTypeOfActivity}
            setCompletionStatus={setCompletionStatus}
          />
          <CompletionStatus
            period={period}
            setPeriod={setPeriod}
            typeOfActivity={typeOfActivity}
            completionStatus={completionStatus}
            setCompletionStatus={setCompletionStatus}
          />
          <PropertyType setBedsArr={setBedsArr} />
          <PropertyLocation
            addressEn={addressEn}
            setAddressEn={setAddressEn}
            address={address}
            setAddress={setAddress}
            coordinatesLat={coordinatesLat}
            setCoordinatesLat={setCoordinatesLat}
            coordinatesLong={coordinatesLong}
            setCoordinatesLong={setCoordinatesLong}
          />
          <PropertyPrice
            priceFrom={priceFrom}
            setPriceFrom={setPriceFrom}
            priceTo={priceTo}
            setPriceTo={setPriceTo}
            multilisting={multilisting}
            setMultilisting={setMultilisting}
            bedsArr={bedsArr}
            setBedsArr={setBedsArr}
          />
          <PropertyDescription />
          <div className={styles.submitButtons}>
            <MyButton
              data={{
                buttonId: 'id_button_publish_client_request',
                className: 'className_button_publish_client_request',
                buttonName: 'Publish',
                customWidth: '155px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={handleSubmit(submitHandler)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '157px',
                styleType: 'cancel',
                variant: 'outlined',
                buttonType: 'button',
              }}
              onClick={handleCancel}
            />
          </div>
          <MyDialog
            open={isShownPublishedDialog}
            onClose={handleClosePublishedDialog}
            dialogTitle={'Your request has been successfully published'}
            width='750'
          >
            <div className={styles.dialogDescription}>
              Your request <strong style={{ color: '#1650FF' }}>&quot;{title}&quot;</strong> has
              been successfully posted in the system and is available for other users to view. Your
              search will be published for a{' '}
              <strong style={{ color: '#1650FF' }}>period of 14 days. </strong>
              You can update it through the{' '}
              <strong style={{ color: '#1650FF' }}>
                &quot;Client requests - My requests&quot;
              </strong>{' '}
              section by clicking the{' '}
              <strong style={{ color: '#1650FF' }}>&quot;Update&quot;</strong> button.
            </div>
            <div className={styles.dialogButtons}>
              <MyButton
                data={{
                  buttonName: 'Confirm',
                  customWidth: '351px',
                  variant: 'contained',
                  buttonType: 'button',
                }}
                onClick={handleDonePublishedDialog}
              />
            </div>
          </MyDialog>
        </Box>
      </FormProvider>
    </div>
  );
};
