import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  FormInputNumeric,
  LabelComponent,
  SectionTitle,
} from '@/components/uiComponents';
import { Box, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { IOffPlanModel } from '@/types';
import { ValidationError } from '@/components/OffPlanView/styled';

interface IForecast {
  currentProgress: number;
  lastUpdate: string;
  estimatedCompletion: string;
  plannedCompletion: string;
};

interface IForecastAndActualDataProps {
  forecast: IForecast | undefined;
  errors: FieldErrors<IOffPlanModel>;
}

export const ForecastAndActualData = ({ forecast, errors }: IForecastAndActualDataProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setValue, getValues } = useFormContext();
  const estimatedCompletion = forecast?.estimatedCompletion ? dayjs(forecast.estimatedCompletion) : dayjs();
  const plannedCompletion = forecast?.plannedCompletion ? dayjs(forecast.plannedCompletion) : dayjs().add(1, 'year');

  const [estDate, setEstDate] = useState<Dayjs | null>(estimatedCompletion);
  const [planDate, setPlanDate] = useState<Dayjs | null>(plannedCompletion);

  useEffect(() => {
    const values = getValues();
    if (values.estimatedCompletion) {
      setEstDate(dayjs(values.estimatedCompletion));
    }
    if (values.plannedCompletion) {
      setPlanDate(dayjs(values.plannedCompletion));
    }
  }, []);

  const handleChangeDates = (type: any) => (v: dayjs.Dayjs | null) => {
    if (v) {
      const isoString = v || dayjs();

      if (type === 'planned') {
        setValue('forecast.plannedCompletion', isoString);
        setPlanDate(v);
      } else {
        setValue('forecast.estimatedCompletion', isoString);
        setEstDate(v);
      }
      setValue('forecast.lastUpdate', dayjs());
    }
  };

  return (
    <Box>
      <SectionTitle number={7} title='Forecast and actual data' />
      <Box sx={{ display: 'flex', gap: '20px', flexDirection: isMobile ? 'column' : 'row' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DatePicker']}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <LabelComponent isRequired label='Planned Completion Date' sx={{marginBottom: '5px'}} />
              <DatePicker 
                label='' 
                value={planDate} 
                onChange={handleChangeDates('planned')} 
                slots={{
                  openPickerIcon: KeyboardArrowDownIcon
                }}
              />
              <ValidationError>
                {errors?.forecast?.plannedCompletion?.message}
              </ValidationError>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <LabelComponent isRequired label='Actual Completion Date' sx={{marginBottom: '5px'}} />
              <DatePicker 
                label='' 
                value={estDate} 
                onChange={handleChangeDates('estimated')} 
                slots={{
                  openPickerIcon: KeyboardArrowDownIcon
                }}
              />
              <ValidationError>
                {errors?.forecast?.estimatedCompletion?.message}
              </ValidationError>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormInputNumeric
                uiVariant='blank'
                name='forecast.currentProgress'
                fullWidth
                suffix='%'
                label='% of completion'
                placeholder='Enter %'
                sx={{ width: '250px' }}            
              />
              <FormHelperText error>{errors?.forecast?.currentProgress?.message}</FormHelperText>
            </Box>
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};
