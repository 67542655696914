import { Control } from 'react-hook-form';
import { SectionTitle, MyTextField, MySelect, MyAutocomplete } from '@/components';
import { IErrors } from '../../../../uiComponents/types';
import styles from './PersonalData.module.scss';
import { nationality, language, gender } from '../../../utils/fakeData';

export const PersonalData = ({
  errors,
  control,
  isCheckedBroker,
  isCheckedPerson,
  isCheckedForeign,
}: {
  errors: IErrors;
  control: Control;
  isCheckedBroker: boolean;
  isCheckedPerson: boolean;
  isCheckedForeign: boolean;
}) => {
  const sortLanguage = language.map((item) => item.label).sort((a, b) => a.localeCompare(b));
  return (
    <div>
      <SectionTitle number={isCheckedBroker ? 2 : 1} title='Personal Data' />
      <div className={styles.container}>
        <div className={isCheckedPerson ? styles.row : styles.name}>
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'userName',
              label: isCheckedPerson ? 'Your name' : 'Company name',
              isRequired: true,
              placeholder: 'Enter your name',
              maxLength: 80,
            }}
          />
          {isCheckedPerson && (
            <div className={styles.personalDataSelect}>
              {isCheckedForeign && (
                <MyTextField
                  errors={errors}
                  control={control}
                  disabled={true}
                  data={{
                    name: 'formOfOrganization',
                    label: 'Form of organization',
                    placeholder: 'Your form of organization',
                    maxLength: 80,
                  }}
                />
              )}
              <MySelect
                errors={errors}
                control={control}
                data={{
                  name: 'gender',
                  label: 'Gender',
                  options: gender,
                  placeholder: 'Your gender',
                }}
              />
              <MySelect
                errors={errors}
                control={control}
                data={{
                  name: 'nationality',
                  label: 'Nationality',
                  options: nationality,
                  placeholder: 'Your nationality',
                }}
              />
            </div>
          )}
        </div>
        <MyAutocomplete
          errors={errors}
          control={control}
          data={{
            name: 'language',
            label: 'Language',
            options: sortLanguage,
            placeholder: 'Choose language',
            numOfSelected: isCheckedPerson ? 5 : 10,
            multiple: true,
          }}
        />
        <MyTextField
          errors={errors}
          control={control}
          data={{
            name: 'description',
            label: 'Description',
            multiline: true,
            placeholder: 'Add information about yourself',
            maxLength: 1600,
          }}
        />
      </div>
    </div>
  );
};
