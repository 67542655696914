import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MyButton, SectionHeader, SectionTitle } from '@/components';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Link
} from '@mui/material';
import { FormCheckboxWithWatch } from '@/components/uiComponents/FormCheckbox/FormCheckboxWithWatch';
import { AdMediaType } from '@/types';
import { Gallery } from './Gallery';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfPreviewCard } from './PdfSettingsComponents/PdfPreviewCard/PdfPreviewCard';
import { DumbFormSelect } from '@/components/uiComponents/FormSelect';

const currencyData = [
    {
      value: "AED",
      label: "AED",
    },
    {
      value: "USD",
      label: "USD",
    },
  ];

export const PdfSettings = ({
  showMyContactsCheckbox,
  images = [],
  onSubmit,
  pdfFile,
  isViewPdfVisible,
  hideViewPdf,
  setCurrency,
  currency
}: {
  showMyContactsCheckbox?: boolean;
  images?: AdMediaType[];
  onSubmit: (data: Record<string, boolean>) => void;
  pdfFile: Blob | null;
  isViewPdfVisible: boolean;
  hideViewPdf: () => void;
  setCurrency: (val: string) => void;
  currency: string;
}) => {
  const navigate = useNavigate();
  const [selectionState, setSelectionState] = useState({ selectAll: false });
  const [countSelectedImages, setCountSelectedImages] = useState(0);
  const { id } = useParams();

  const pdfPreviewVariants = [
    { name: 'horizontal-view', label: `Horizontal format` },
    { name: 'vertical-view', label: `Vertical format` },
  ];

  const form = useForm({
    defaultValues: {
      radioOption: 'horizontal-view',
      currency: 'AED', 
    },
  });

  const checkboxOptions = [
    {
      label: 'Show my contacts',
      name: 'brokerDetailsShow',
      showCheckbox: showMyContactsCheckbox,
      checkedByDefault: true,
    },
    {
      label: 'Show agency',
      name: 'agencyDetailsShow',
      showCheckbox: true,
      checkedByDefault: false,
    },
    {
      label: 'Show price',
      name: 'showPrice',
      showCheckbox: true,
      checkedByDefault: true,
    },
  ];

  if (images?.length <= 2) {
    return (
      <>
        <SectionHeader title={'Save as PDF'} />
        <Stack sx={{ marginTop: '40px', alignItems: 'center', color: 'red' }}>
          <h2>
            The&nbsp;
            <Link href={`/ads/${id}`} title={`/ads/${id}`}>
              ad contains {images?.length || 0} images
            </Link>
            &nbsp;, and at least three are required to generate a PDF
          </h2>
        </Stack>
      </>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        {/* @ts-ignore */}
        <form onSubmit={form.handleSubmit(onSubmit)} onChange={() => isViewPdfVisible && hideViewPdf()}>
          <SectionHeader title={'Save as PDF'} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '56px' }} />
          <Stack sx={{ marginTop: '40px', gap: '20px' }}>
            <SectionTitle variant='blank' number={1} title='PDF settings' />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', gap: '0 28px' }}>
              <Stack sx={{ flexGrow: 1, maxWidth: { xs: '100%', sm: '50%', md: '25%', lg: '20%' } }}>
                <Typography component="label" sx={{ mb: 1 }}>Currency:</Typography>
                <Controller
                  control={form.control}
                  name="currency"
                  render={({ field }) => (
                    <DumbFormSelect
                      {...field}
                      defaultSelectedValue={currency}
                      placeholder="Currency"
                      disabled={false}
                      data={currencyData}
                      handleChange={(val) => {
                        if (['AED', 'USD'].includes(val)) {
                          setCurrency(val); 
                          field.onChange(val);
                          hideViewPdf();
                        }
                      }}
                    />
                  )}
                />
              </Stack>
              {checkboxOptions.map((option, i) =>
                option.showCheckbox ? (
                  <FormCheckboxWithWatch
                    checked={option.checkedByDefault}
                    label={option.label}
                    name={option.name}
                    key={option.name + i}
                  />
                ) : null
              )}
            </Box>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              marginTop: '56px',
            }}
          >
            <SectionTitle variant='blank' number={2} title='PDF Preview' />
            <Controller
              name='radioOption'
              control={form.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Grid container spacing={2}>
                    {pdfPreviewVariants.map((variant) => (
                      <Grid item key={variant.name}>
                        <Grid container direction='column'>
                          <PdfPreviewCard cardName={variant.name} />
                          <FormControlLabel
                            value={variant.name}
                            control={<Radio disableRipple />}
                            label={<Typography variant='body1'>{variant.label}</Typography>}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            alignItems='flex-start'
            spacing={2}
            sx={{
              backgroundColor: '#F1F7FF',
              borderRadius: '20px',
              marginTop: '56px',
              padding: '20px',
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection={{ xs: 'column' }}
              justifyContent='space-between'
              gap={2}
            >
              <SectionTitle variant='blank' number={3} title='Select image' />
              <Typography component="label">There are {images.length >= 4 ? '4' : '3'} images available for selection that you can display in your collection</Typography>
              {images && 
                <Gallery 
                  images={images} 
                  selectionState={selectionState} 
                  countSelectedImages={countSelectedImages} 
                  setCountSelectedImages={setCountSelectedImages}
                />
              }
              {/* <Box
                display='flex'
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap={2}
                width={{ xs: '100%', sm: 'auto' }}
              >
                <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='129px'>
                  <MyButton
                    data={{
                      buttonName: 'Select all',
                      variant: 'outlined',
                      styleType: 'cancel',
                      sx: { height: '40px' },
                    }}
                    onClick={() => {
                      setSelectionState({
                        selectAll: true,
                      });
                    }}
                  />
                </Box>
                <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='129px'>
                  <MyButton
                    data={{
                      buttonName: 'Unselect all',
                      variant: 'outlined',
                      styleType: 'cancel',
                      sx: { height: '40px' },
                    }}
                    onClick={() => {
                      setSelectionState({
                        selectAll: false,
                      });
                    }}
                  />
                </Box>
              </Box> */}
            </Box>
          </Stack>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
            style={{ marginTop: '20px' }}
          >
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              {isViewPdfVisible ? <MyButton
                onClick={() => pdfFile && window.open(URL.createObjectURL(pdfFile))}
                data={{
                  buttonName: 'View PDF',
                  variant: 'contained',
                  sx: { height: '40px' },
                }}
              /> : <MyButton
                disabled={
                  images.length === 3 && countSelectedImages != images.length || 
                  images.length >= 4 && countSelectedImages !== 4
                }
                data={{
                  buttonName: 'Save',
                  variant: 'contained',
                  buttonType: 'submit',
                  sx: { height: '40px' },
                }}
              />}


            </Box>
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              <MyButton
                data={{
                  buttonName: 'Cancel',
                  variant: 'outlined',
                  styleType: 'cancel',
                  sx: { height: '40px' },
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};