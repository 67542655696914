import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  State,
  AdModel,
  PriceType,
  FileKindEnum,
  NotificationTypeEnum,
  RentPeriodEnum,
  ActivityKindEnum,
  CompletionStatusEnum,
  TransactionTypeEnum,
  HeaderDialogsEnum,
} from '@/types';
import { appSetNotification } from '@/store';
import { adsProvider } from '@/providers';
import { getKindLabel } from '@/utils';
import { Loader } from '@/components';

import { NumOfAd } from './styled';
import { icons } from './Icons/icons';
import {
  BasicDataBlock,
  ChangesPriceBlock,
  CommissionBlock,
  ContactBlock,
  DescriptionBlock,
  FeatureBlock,
  MediaBlock,
  ResaleBlock,
  SpecificationsBlock,
} from '../Ad';
import { Comments } from './Comments/Comments';
import { MapUi } from '@/components/Map/Map';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

export const Ad = () => {
  const dispatch = useDispatch();
  const { openDialog, setOpenDialog } = useDialogs();
  const { id } = useParams<{ id: string }>();
  const { isLoggedIn, user } = useSelector(({ auth }: State) => auth);

  const [page, setPage] = useState<AdModel>();
  const [price, setPrice] = useState<PriceType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [priceRent, setPriceRent] = useState<number>();
  const [rentFrequency, setRentFrequency] = useState<RentPeriodEnum | null | undefined>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getAdData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await adsProvider.getAdById(id!);
    if (ok && data) {
      setPage(data as AdModel);
      if (data.activityKind === ActivityKindEnum.Rent) {
        setRentFrequency(data.defaultRentFrequency);
        if (data.defaultRentFrequency === RentPeriodEnum.Yearly && data.convertedPriceYearly) {
          setPriceRent(data.convertedPriceYearly);
        }
        if (data.defaultRentFrequency === RentPeriodEnum.Monthly && data.convertedPriceMonthly) {
          setPriceRent(data.convertedPriceMonthly);
        }
        if (data.defaultRentFrequency === RentPeriodEnum.Weekly && data.convertedPriceWeekly) {
          setPriceRent(data.convertedPriceWeekly);
        }
        if (data.defaultRentFrequency === RentPeriodEnum.Daily && data.convertedPriceDaily) {
          setPriceRent(data.convertedPriceDaily);
        }
      } else {
        setPriceRent(data.convertedPrice);
      }
      const {
        ok: okPrice,
        data: dataPrice,
        status: statusPrice,
        message: messagePice,
      } = await adsProvider.getPriceById(id!);
      if (okPrice && dataPrice) {
        setPrice(dataPrice as PriceType);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, messagePice, statusPrice));
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && id) {
      getAdData();
    }
  }, []);

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const userType = page ? getKindLabel(page?.propertyAdCreator?.kind || '') : '';
  const videos = page && page.propertyAdMedia.filter((item) => item.kind === FileKindEnum.Video);

  const formateDate = (value: string | null) =>
    value !== null ? moment(value).format('D, MMMM, YYYY') : null;

  const adDate = page && [
    {
      name: 'create',
      value: formateDate(page.createdAt),
      icon: <icons.CirclePlus />,
    },
    {
      name: 'published',
      value: formateDate(page.publishedAt),
      icon: <icons.CalendarPlus />,
    },
    {
      name: 'updated',
      value: formateDate(page.updatedAt),
      icon: <icons.ArrowClockwise />,
    },
  ];

  const description = (page && page.description.trim()) || '';
  const isSale = page && page.activityKind === ActivityKindEnum.Sale;
  const isResale =
    (page?.transactionType === TransactionTypeEnum.Resale ||
      (page?.transactionType === TransactionTypeEnum.Distress &&
        page?.completionStatus === CompletionStatusEnum.OffPlan)) &&
    page?.toSeller;

  return isLoading ? (
    <Loader />
  ) : !page ? null : (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='flex-start'
      justifyContent='center'
      spacing={2}
      sx={{ '@media (max-width: 600px)': { width: 'inherit' } }}
    >
      <Stack
        alignItems='flex-start'
        spacing={2}
        sx={{ flexGrow: '1', width: '100%', minWidth: { sm: '580px' } }}
      >
        <BasicDataBlock
          user={user}
          page={page}
          isMobile={isMobile}
          price={priceRent}
          setPrice={setPriceRent}
          defaultRentFrequency={rentFrequency}
          setDefaultRentFrequency={setRentFrequency}
          handleClickOpenDialog={handleClickOpenDialog}
        />
        {isResale && <ResaleBlock page={page} />}
        {page.topUp && <CommissionBlock page={page} />}
        <SpecificationsBlock page={page} />
        {description && description?.length > 0 && <DescriptionBlock description={description} />}
        {videos && videos?.length > 0 && <MediaBlock videos={videos} isMobile={isMobile} />}
        <Stack flex={1} width={'100%'}>
          <MapUi
            selectedLocation={{
              latitude: Number(page?.coordinatesLat ?? 0),
              longitude: Number(page?.coordinatesLong ?? 0),
            }}
          />
        </Stack>
        <FeatureBlock page={page} />
        {isSale && price && <ChangesPriceBlock price={price} isMobile={isMobile} />}
        <Stack
          direction='row'
          alignItems='flex-start'
          spacing={2}
          sx={{ width: '100%', padding: '5px' }}
        >
          {adDate &&
            adDate.map(
              (date) =>
                date.value !== null && (
                  <Stack
                    key={date.name}
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems='center'
                    spacing={0.5}
                  >
                    {date.icon}
                    <NumOfAd>{date.value}</NumOfAd>
                  </Stack>
                )
            )}
        </Stack>
        <Comments page={page} user={user} handleClickOpenDialog={handleClickOpenDialog}/>
      </Stack>
      <ContactBlock
        page={page}
        userType={userType}
        user={user}
        handleClickOpenDialog={handleClickOpenDialog}
      />
    </Stack>
  );
};
