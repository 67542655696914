import { Stack, Typography } from '@mui/material';
import { NewBuildingModel } from '@/types';
import { Title } from './styled';
import { icons } from '../Ad/Icons/icons';
import { Section } from '@/components/Ad/Section';
import { ReactNode } from 'react';

export const AmenitiesBlock = ({ page }: { page: NewBuildingModel }) => {
  const feature = page && {
    groupSizes: [5, 5, 5, 5],
    data: [
      {
        name: 'balcony',
        value: 'Balcony',
        isVisible: !!page.balcony,
        icon: <icons.Balcony />,
      },
      {
        name: 'maidsRoom',
        value: 'Maids room',
        isVisible: !!page.maidsRoom,
        icon: <icons.MaidsRoom />,
      },
      {
        name: 'sharedPool',
        value: 'Shared pool',
        isVisible: !!page.sharedPool,
        icon: <icons.SharedPool />,
      },
      {
        name: 'builtInWardrobes',
        value: 'Built in wardrobes',
        isVisible: !!page.buildInWardrobes,
        icon: <icons.BuiltInWardrobes />,
      },
      {
        name: 'childrenPlayArea',
        value: `Children's play area`,
        isVisible: !!page.childrenPlayArea,
        icon: <icons.ChildrenPlayArea />,
      },
      {
        name: 'sharedSpa',
        value: 'Shared spa',
        isVisible: !!page.sharedSpa,
        icon: <icons.SharedSpa />,
      },
      {
        name: 'sharedGym',
        value: 'Shared gym',
        isVisible: !!page.sharedGym,
        icon: <icons.SharedGym />,
      },
      {
        name: 'study',
        value: 'Study',
        isVisible: !!page.study,
        icon: <icons.Study />,
      },
      {
        name: 'centralAc',
        value: 'Central A/C',
        isVisible: !!page.centralAC,
        icon: <icons.CentralAc />,
      },
      {
        name: 'coveredParking',
        value: 'Covered parking',
        isVisible: !!page.coveredParking,
        icon: <icons.CoveredParking />,
      },
      {
        name: 'barbecueArea',
        value: 'Barbecue area',
        isVisible: !!page.barbecueArea,
        icon: <icons.BarbecueArea />,
      },
      {
        name: 'viewOfWater',
        value: 'Water view',
        isVisible: !!page.waterView,
        icon: <icons.ViewOfWater />,
      },
      {
        name: 'viewOfLandmark',
        value: 'Landmark view',
        isVisible: !!page.landmarkView,
        icon: <icons.ViewOfLandmark />,
      },
      {
        name: 'concierge',
        value: 'Concierge',
        isVisible: !!page.concierge,
        icon: <icons.Concierge />,
      },
      {
        name: 'publicParking',
        value: 'Public parking',
        isVisible: !!page.publicParking,
        icon: <icons.PublicParking />,
      },
      {
        name: 'kitchenAppliances',
        value: 'Kitchen appliances',
        isVisible: !!page.kitchenAppliances,
        icon: <icons.KitchenAppliances />,
      },
      {
        name: 'lobbyInBuilding',
        value: 'Lobby in building',
        isVisible: !!page.lobbyInBuilding,
        icon: <icons.LobbyInBuilding />,
      },
      {
        name: 'maidService',
        value: 'Maid service',
        isVisible: !!page.maidService,
        icon: <icons.MaidService />,
      },
      {
        name: 'privateGarden',
        value: 'Private garden',
        isVisible: !!page.privateGarden,
        icon: <icons.PrivateGarden />,
      },
      {
        name: 'privateGym',
        value: 'Private gym',
        isVisible: !!page.privateGym,
        icon: <icons.PrivateGym />,
      },
      {
        name: 'privatePool',
        value: 'Private pool',
        isVisible: !!page.privatePool,
        icon: <icons.PrivatePool />,
      },
      {
        name: 'security',
        value: 'Security',
        isVisible: !!page.security,
        icon: <icons.Security />,
      },
      {
        name: 'tennisCourts',
        value: 'Tennis courts',
        isVisible: !!page.tennisCourts,
        icon: <icons.TennisCourts />,
      },
      {
        name: 'viewOfPark',
        value: 'Park view',
        isVisible: !!page.parkView,
        icon: <icons.ViewOfPark />,
      },
      {
        name: 'viewOfCity',
        value: 'City view',
        isVisible: !!page.cityView,
        icon: <icons.ViewOfCity />,
      },
      {
        name: 'privateSpa',
        value: 'Private spa',
        isVisible: !!page.privateSpa,
        icon: <icons.PrivateSpa />,
      },
      {
        name: 'laundryFacility',
        value: 'Laundry facility',
        isVisible: !!page.laundryFacility,
        icon: <icons.LaundryFacility />,
      },
    ],
  };

  const featureData: {
    name: string;
    value: string;
    isVisible: boolean;
    icon: ReactNode;
  }[] = feature ? feature.data : [];

  const isVisible = featureData.find((data) => data.isVisible);

  if (!isVisible) {
    return null;
  }

  return (
    <Section>
      <Title>Amenities</Title>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent='flex-start'
        sx={{ flexWrap: 'wrap' }}
      >
        {featureData.map((data) => {
          return (
            data.isVisible && (
              <Stack
                key={data.name}
                alignItems='center'
                justifyContent='center'
                spacing={1}
                sx={{ width: '95px', margin: '7px 6px' }}
              >
                {data.icon}
                <Typography sx={{ textAlign: 'center' }}>{data.value}</Typography>
              </Stack>
            )
          );
        })}
      </Stack>
    </Section>
  );
};
