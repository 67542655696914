import { AdCreatorType, UserKindEnum, UserModel } from '@/types';
import { Section, Title } from '@/components/PdfManager/PdfDocument/ClassicView/Section';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { IconText } from '@/components/PdfManager/PdfDocument/ClassicView/Badge';
import { EnvelopeIcon, PhoneIcon, TelegramIcon, WhatsappIcon } from '@/assets/pdf';
import { Picture } from '@/components/PdfManager/PdfDocument/ClassicView/Picture';
import { getInitials } from '@/utils/stringAvatar';

export const ContactSection = ({ contactInfo }: { contactInfo?: AdCreatorType | UserModel }) => {
  if (!contactInfo) return null;

  const { name, additionalPhones, additionalEmails, whatsapp, telegram, kind } = contactInfo;

  let avatarSrc;
  if ('avatar' in contactInfo) {
    avatarSrc = contactInfo.avatar;
  } else if (Array.isArray(contactInfo.media) && contactInfo.media[0]) {
    avatarSrc = contactInfo.media[0].fileUrl;
  }

  return (
    <Section>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 32,
        }}
      >
        <View
          style={{
            fontSize: '64px',
            fontWeight: 'bold',
            width: 156,
            height: 156,
            borderRadius: 104,
            backgroundColor: '#1650FF',
            overflow: 'hidden',
            color: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* {avatarSrc ? (
            <Picture src={avatarSrc} style={{ width: 160, height: 160, objectFit: 'cover' }} />
          ) : ( */}
            <Text>{getInitials(name)}</Text>
          {/* )} */}
        </View>
        <Text
          style={{
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: 96,
            color: '#2A3842',
          }}
        >
          {name}
        </Text>
      </View>

      <View style={{ flexDirection: 'row', gap: 100 }}>
        {additionalPhones && (
          <IconText style={styles.contact} iconSrc={PhoneIcon}>
            {additionalPhones[0]}
          </IconText>
        )}
        {additionalEmails && (
          <IconText style={styles.contact} iconSrc={EnvelopeIcon}>
            {additionalEmails[0]}
          </IconText>
        )}
      </View>
      <View style={{ flexDirection: 'row', gap: 100 }}>
        {kind === UserKindEnum.Agency && telegram && (
          <IconText style={styles.contact} iconSrc={TelegramIcon}>
            {telegram}
          </IconText>
        )}
        {kind === UserKindEnum.Agency && whatsapp && (
          <IconText style={styles.contact} iconSrc={WhatsappIcon}>
            {whatsapp}
          </IconText>
        )}
      </View>
    </Section>
  );
};

const styles = StyleSheet.create({
  contact: {
    minWidth: 900,
    color: '#1650FF',
    fontSize: 80,
  },
});
