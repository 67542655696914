import { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Stack } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { AuthSignupRequestType, UserModel, State, NotificationTypeEnum } from '@/types';
import { authLogin, appSetNotification, appSetLoading } from '@/store';
import { MyDialog, Loader } from '@/components';

import {
  StyledSquareTextField,
  StyleLabel,
  StyledButtonSubmit,
  CenterAlignStyleText,
  StyledButtonText,
} from '../../uiComponents/styled';
import { authProvider, usersProvider } from '../../../providers';
import { IDialogProps, IData, IState } from './types';

const fields: Array<'firstNum' | 'secondNum' | 'thirdNum' | 'fourthNum'> = [
  'firstNum',
  'secondNum',
  'thirdNum',
  'fourthNum',
];

const defaultState: IState = {
  attemptCounter: 3,
  codeCounter: 3,
  error: false,
  formInformation: {},
  countdown: null,
  canResend: false,
  flag: false,
  time: false,
  timeoutId: null,
  dialogTitleDescription: '',
  messageError: '',
  countdownTimer: null,
};

const TIME_DELAY = 600000;
const COUNT_DOWN = 120;

const Confirmation: FC<IDialogProps> = (props) => {
  const {
    open,
    onClose,
    handleOpenDialog,
    dialogData,
    emailOrPhoneInput,
    userDataSubmit = {},
    setUserChecked,
    type,
    setOpenChangePassword,
    setCode,
  } = props;
  const [state, setState] = useState<IState>(defaultState);
  const [openError, setOpenError] = useState(false);

  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const fourthRef = useRef<HTMLInputElement>(null);

  const refs = [firstRef, secondRef, thirdRef, fourthRef];
  const { isLoading } = useSelector(({ app }: State) => app);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<IData>({
    defaultValues: fields.reduce((acc, field) => ({ ...acc, [field]: '' }), {}),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogData && emailOrPhoneInput && identifyEmailInputType(emailOrPhoneInput)) {
      setState((prev) => ({
        ...prev,
        dialogTitleDescription: dialogData?.dialogTitle?.descriptionEmail,
      }));
    } else {
      dialogData &&
        setState((prev) => ({
          ...prev,
          dialogTitleDescription: dialogData?.dialogTitle?.descriptionEmail,
        })); //TODO need to change
    }

    if (open) {
      setTimeout(() => {
        if (firstRef.current) {
          firstRef.current.focus();
        }
      }, 0);

      const id = setTimeout(() => {
        setState((prev) => ({ ...prev, time: true }));
      }, TIME_DELAY);
      setState((prev) => ({
        ...prev,
        timeoutId: id,
        countdown: COUNT_DOWN,
        canResend: false,
      }));

      const interval = setInterval(() => {
        setState((prev) => {
          if (prev.countdown !== null) {
            if (prev.countdown <= 1) {
              clearInterval(interval);
              return { ...prev, canResend: true, countdown: null };
            } else {
              return { ...prev, countdown: prev.countdown - 1 };
            }
          } else {
            return prev;
          }
        });
      }, 1000);
      setState((prev) => ({
        ...prev,
        countdownTimer: interval,
      }));
    } else {
      if (state.countdownTimer) clearInterval(state.countdownTimer);
    }
    return () => {
      if (state.countdownTimer) clearInterval(state.countdownTimer);
    };
  }, [open, state.flag]);

  const identifyEmailInputType = (input: string) => {
    const emailRegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegEx.test(input);
  };

  const handleClose = () => {
    reset();
    if (state.countdownTimer) clearInterval(state.countdownTimer);
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
      setState((prev) => ({
        ...prev,
        time: false,
      }));
    }
    setState(defaultState);
    onClose();
  };

  const handleResendClick = async () => {
    setState((prev) => ({
      ...prev,
      countdown: COUNT_DOWN,
      flag: !prev.flag,
      attemptCounter: 3,
      error: false,
      codeCounter: prev.codeCounter - 1,
    }));
    const dataSubmit = { ...userDataSubmit } as AuthSignupRequestType;
    if (userDataSubmit && type === 'signup') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await authProvider.signupStart(dataSubmit);
      if (!ok && setUserChecked) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setUserChecked(true);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'userResetPassword') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await usersProvider.postChangePasswordRequest();
      if (!ok && setUserChecked) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setUserChecked(true);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'loginResetPassword') {
      dispatch(appSetLoading(true));
      const { ok, data, status, message } =
        await authProvider.postRecoverPasswordRequest(dataSubmit);
      if (!ok && setUserChecked) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setUserChecked(true);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'userChangeLogin') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await usersProvider.postChangeLoginRequest(dataSubmit);
      if (!ok && setUserChecked) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setUserChecked(true);
      }
      dispatch(appSetLoading(false));
    }
  };

  const handleFailure = (code: string) => {
    if (state.attemptCounter === 1) {
      setState((prev) => ({
        ...prev,
        codeCounter: 0,
        error: true,
        messageError: (
          <span style={{ fontWeight: 600 }}>
            Attention! You entered the wrong code three times. Wait 20 minutes before attempting
            again.
          </span>
        ),
      }));
      if (state.countdownTimer) clearInterval(state.countdownTimer);
    } else {
      setState((prev) => ({
        ...prev,
        attemptCounter: prev.attemptCounter - 1,
        error: true,
        messageError: (
          <span>
            The entered verification code is incorrect. Please double-check and try again. You have{' '}
            <strong>{prev.attemptCounter - 1}</strong> attempts left.
          </span>
        ),
      }));
    }
  };

  const onSubmit: SubmitHandler<IData> = async (data) => {
    if (!userDataSubmit && type === 'signup') {
      console.error('userDataSubmit is undefined');
      return;
    }
    const code = data.firstNum + data.secondNum + data.thirdNum + data.fourthNum;

    const dataSubmit = { ...userDataSubmit, code } as AuthSignupRequestType;
    if (dataSubmit && type === 'signup') {
      dispatch(appSetLoading(true));
      const { ok, data, status, message } = await authProvider.signupFinish(dataSubmit);
      if (ok) {
        dispatch(authLogin(data as UserModel));
        handleClose();
      } else {
        const messageErr = status === 400 ? 'Code should not be empty' : message;
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageErr, status));
        handleFailure(code);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'userResetPassword') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await usersProvider.postChangePasswordCheck({ code });
      if (ok && handleOpenDialog && setCode) {
        handleClose();
        handleOpenDialog('changePassword');
        setCode(code);
      } else {
        const messageErr = status === 400 ? 'Code should not be empty' : message;
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageErr, status));
        handleFailure(code);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'loginResetPassword') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await authProvider.postRecoverPasswordCheck(dataSubmit);
      if (ok && handleOpenDialog && setCode) {
        handleClose();
        handleOpenDialog('createPassword');
        setCode(code);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        handleFailure(code);
      }
      dispatch(appSetLoading(false));
    }
    if (type === 'userChangeLogin') {
      dispatch(appSetLoading(true));
      const { ok, message, status } = await usersProvider.postChangeLoginChange(dataSubmit);
      if (ok) {
        handleClose();
      } else {
        const messageErr = status === 400 ? 'Code should not be empty' : message;
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageErr, status));
        handleFailure(code);
      }
      dispatch(appSetLoading(false));
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (e.target.value.length === 1 && index < refs.length - 1) {
      const nextInput = refs[index + 1].current;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
      const prevInput = refs[index - 1].current;
      if (prevInput) {
        prevInput.focus();
        prevInput.value = '';
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    if (text.length === 4 && /^[0-9]{4}$/.test(text)) {
      const chars = text.split('');
      chars.forEach((char, index) => {
        const fieldName = fields[index];
        if (fieldName) {
          setValue(fieldName, char, { shouldValidate: true });
        }
      });
    }
  };

  const onErrorClose = () => {
    setOpenError(false);
    handleClose();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      const allFieldsFilled = fields.every((fieldName) => watch(fieldName));
      if (allFieldsFilled) {
        handleSubmit(onSubmit)();
      }
    }
  };

  if (!open) return null;
  return (
    <>
      <MyDialog
        open={open}
        onClose={handleClose}
        dialogTitle={dialogData?.dialogTitle.label || ''}
        width={dialogData?.width}
      >
        <Box display='flex' justifyContent='center' width='100%'>
          <Stack spacing={2} sx={{ width: '75%' }}>
            <StyleLabel style={{ textAlign: 'center' }}>{state.dialogTitleDescription}</StyleLabel>
            <form className='form' onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Stack
                  direction='row'
                  spacing={1}
                  justifyContent='space-evenly'
                  alignItems='center'
                  display='flex'
                >
                  {fields.map((fieldName, index) => (
                    <Stack key={index} justifyContent='center'>
                      <Controller
                        control={control}
                        name={fieldName}
                        render={({ field }) => (
                          <StyledSquareTextField
                            {...field}
                            inputRef={refs[index]}
                            autoComplete={fieldName}
                            name={fieldName}
                            variant='outlined'
                            fullWidth
                            inputProps={{
                              maxLength: 1,
                              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
                                handleKeyDown(e, index),
                            }}
                            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) =>
                              handleKeyPress(event, index)
                            }
                            onPaste={handlePaste}
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange(e, index);
                            }}
                          />
                        )}
                      />
                    </Stack>
                  ))}
                </Stack>

                {state.time ? (
                  <Grid>
                    <Grid item xs={12}>
                      <StyleLabel
                        style={{
                          textAlign: 'center',
                          color: 'red',
                          marginBottom: '15px',
                        }}
                      >
                        10 minutes have passed since the code was sent. Your code is not valid.
                      </StyleLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledButtonSubmit
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={handleClose}
                        disabled={isLoading}
                      >
                        Back
                      </StyledButtonSubmit>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>
                    {state.error && state.attemptCounter !== 0 && (
                      <Grid item xs={12}>
                        <StyleLabel
                          style={{
                            textAlign: 'center',
                            color: 'red',
                            marginBottom: '15px',
                          }}
                        >
                          {' '}
                          {state.messageError}
                        </StyleLabel>
                      </Grid>
                    )}
                    {state.codeCounter !== 0 ? (
                      <Grid item xs={12}>
                        <CenterAlignStyleText>
                          {state.canResend ? (
                            <StyledButtonText
                              variant='text'
                              disableRipple
                              onClick={handleResendClick}
                            >
                              You can now resend the code
                            </StyledButtonText>
                          ) : (
                            state.countdown !== null && (
                              <span>Resend after {state.countdown} seconds</span>
                            )
                          )}
                        </CenterAlignStyleText>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <StyleLabel
                          style={{
                            textAlign: 'center',
                            color: 'red',
                            marginBottom: '15px',
                            fontWeight: '600',
                          }}
                        >
                          {' '}
                          Attention! Maximum limit of verification code requests exceeded. Wait 20
                          minutes before attempting again.
                        </StyleLabel>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <StyledButtonSubmit
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isLoading || state.codeCounter === 0}
                      >
                        Confirm
                      </StyledButtonSubmit>
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </form>
          </Stack>
        </Box>
      </MyDialog>
      <MyDialog open={openError} onClose={onErrorClose} dialogTitle='Error' width='500'>
        <StyleLabel style={{ textAlign: 'center', color: 'red' }}>
          You entered the wrong code three times, try again in 2 minutes
        </StyleLabel>
      </MyDialog>
    </>
  );
};

export default Confirmation;
