import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { checkMessageForExtensionsData } from '../../../util/common';

import { CometChatContext } from '../../../util/CometChatContext';

import { theme } from '../../../resources/theme';

import { messagePreviewContainerStyle } from './style';

class CometChatRichMediaPreview extends React.PureComponent {
  static contextType = CometChatContext;

  render() {
    const richMediaPreviewData = checkMessageForExtensionsData(this.props.message, 'rich-media');
    const html = richMediaPreviewData.html;

    return (
      <div
        css={messagePreviewContainerStyle()}
        className='message__preview'
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

// Specifies the default values for props:
CometChatRichMediaPreview.defaultProps = {
  theme: theme,
};

CometChatRichMediaPreview.propTypes = {
  theme: PropTypes.object,
  message: PropTypes.object.isRequired,
};

export { CometChatRichMediaPreview };
