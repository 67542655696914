import { useState } from 'react';
import moment from 'moment';
import { Stack, Typography, Divider } from '@mui/material';
import { PriceType } from '@/types';
import { Title, StyledButton } from './styled';
import { priceFormat } from '@/utils';

const NUM = 3;

export const ChangesPriceBlock = ({ price, isMobile }: { price: PriceType; isMobile: boolean }) => {
  const [showAll, setShowAll] = useState(false);

  const formateDate = (value: string | null) =>
    value !== null ? moment(value).format('DD.MM.YYYY') : null;
  const updatedPrice =
    price &&
    price?.items?.map((item, index, array) => {
      const nextItem = array[index + 1];
      const difference = nextItem ? item.convertedPrice - nextItem.convertedPrice : 0;
      return {
        ...item,
        priceDifference: difference <= 0 ? difference : `+${difference}`,
        color: difference < 0 ? '#FF1607' : '#19B100',
      };
    });

  return (
    <Stack
      alignItems='flex-start'
      spacing={2}
      sx={{
        width: '100%',
        backgroundColor: '#F1F7FF',
        borderRadius: isMobile ? '10px' : '20px',
        padding: '15px',
      }}
    >
      <Title>History of price changes</Title>
      {(showAll ? updatedPrice || [] : updatedPrice?.slice(0, NUM) || []).map((el) => {
        if (!el) return null;
        return (
          <div key={el.createdAt}>
            <Stack
              direction='row'
              alignItems='flex-end'
              justifyContent='space-between'
              spacing={2}
              sx={{ width: isMobile ? '350px' : '550px' }}
            >
              <Typography sx={{ fontSize: isMobile ? '14px' : '18px' }}>
                {formateDate(el.createdAt)}
              </Typography>
              <Stack key={el.createdAt} direction='row' alignItems='center' spacing={5}>
                {el.priceDifference !== 0 && (
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: `${el.color}`,
                      fontSize: isMobile ? '14px' : '18px',
                    }}
                  >
                    {priceFormat(el.priceDifference).replace('.', '')} {el.convertedCurrency}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: isMobile ? '14px' : '18px',
                  }}
                >
                  {priceFormat(el.convertedPrice)} {el.convertedCurrency}
                </Typography>
              </Stack>
            </Stack>
            <Divider flexItem />
          </div>
        );
      })}
      {updatedPrice && updatedPrice?.length > NUM && (
        <StyledButton onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Hide' : 'Show more'}
        </StyledButton>
      )}
    </Stack>
  );
};
