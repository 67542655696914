import { Autocomplete as MuiAutocomplete, IconButton, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LocationModel } from '@/types/location';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useMemo, useState, useEffect } from 'react';
import styles from './LocationAutocomplete.module.scss';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { ActivityKindEnum, PropertyKindEnum, TransactionTypeEnum } from '@/types';

interface LocationAutocompleteProps {
  onSelect?: (value: LocationModel) => void;
  locations: LocationModel[];
  inputAddressValue: string;
  setInputAddressValue: (value: string) => void;
  handleClear?: () => void;
  activityKind?: ActivityKindEnum;
  propertyKind?: PropertyKindEnum;
  transactionType?: TransactionTypeEnum;
  isPageChanged?: boolean;
  isAddNewLocationVisible?: boolean;
  isDisable?: boolean;
}

export const getDepthLabel = (option: LocationModel) =>
  ['city', 'level1', 'level2', 'level3'].reduce((acc, item, index, arr) => {
    if (item === option.kind) {
      arr.slice(1);
      return acc;
    }
    if (option[item as keyof LocationModel]) {
      return acc + `${index !== 0 ? ', ' : ''}${option[item as keyof LocationModel]}`;
    }
    return acc;
  }, '');

export const LocationAutocomplete = ({
  locations = [],
  onSelect,
  inputAddressValue,
  setInputAddressValue,
  handleClear,
  activityKind,
  propertyKind,
  transactionType,
  isPageChanged,
  isAddNewLocationVisible,
  isDisable,
}: LocationAutocompleteProps) => {
  const formContext = useFormContext();
  const clearErrors = formContext?.clearErrors;
  const errors = formContext?.formState.errors || {};

  const [value, setValue] = useState<LocationModel | undefined>(undefined);
  const handleSelectValue = (value: LocationModel) => {
    setValue(value);
    setInputAddressValue(value[value.kind] ?? '');
    onSelect?.(value);
    if (clearErrors) {
      clearErrors(['addressEn']);
    }
  };

  const onClear = () => {
    setInputAddressValue('');
    setValue(undefined);
    handleClear?.();
  };

  useEffect(() => {
    if (activityKind || propertyKind || transactionType || isAddNewLocationVisible) {
      if (!isPageChanged) {
        onClear();
      }
    }
  }, [activityKind, propertyKind, transactionType, isAddNewLocationVisible]);

  const handlePressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') return;
    event.preventDefault();
    if (value) {
      onSelect?.(value);
    } else if (locations[0] && inputAddressValue) {
      setValue(locations[0]);
      setInputAddressValue(locations[0]?.[locations[0].kind] ?? '');
      onSelect?.(locations[0]);
    }
  };

  const options = useMemo(
    () => (inputAddressValue ? locations : []),
    [inputAddressValue, locations]
  );

  return (
    <MuiAutocomplete
      onChange={(e, value) => value && handleSelectValue(value)}
      disabled={isDisable}
      fullWidth={true}
      value={value || undefined}
      inputValue={inputAddressValue}
      disableClearable={true}
      autoComplete={false}
      onKeyDown={handlePressEnter}
      noOptionsText={''}
      autoSelect={false}
      filterSelectedOptions={false}
      filterOptions={(option) => option}
      classes={{ inputRoot: styles.inputRoot, root: styles.root, noOptions: styles.noOptions }}
      options={options}
      getOptionLabel={(option) => option[option.kind] ?? ''}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option[option.kind] ?? '', inputValue, { insideWords: true });
        const parts = parse(option[option.kind] ?? '', matches);
        const depthLabel = getDepthLabel(option);
        const kindLabel =
          option.kind === 'city' ? `(City)` : option.kind === 'region' ? `(Region)` : null;

        return (
          <li key={option.id} {...props}>
            <div>
              {parts?.map((part, index) => (
                <span
                  key={`${option.id}-${index}`}
                  style={{
                    fontWeight: part.highlight ? 600 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
              <span className={styles.gray}>
                {kindLabel ? kindLabel : depthLabel && ' ' + `(${depthLabel})`}
              </span>
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name='addressEn'
          error={!!errors['addressEn']}
          helperText={errors['addressEn'] ? errors['addressEn']?.message?.toString() : ''}
          fullWidth={true}
          onChange={(event) => {
            const val = event.target.value;
            if (val) {
              setInputAddressValue(val);
            } else {
              onClear();
            }
          }}
          placeholder='Enter the address'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton
                sx={{
                  visibility: !isDisable && inputAddressValue ? 'visible' : 'hidden',
                }}
                onClick={onClear}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};
