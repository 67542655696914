import {
  ResponseType,
  PaginationType,
} from '@/types';
import { apiClient } from '@/providers';
import {AdsGetFavoritesResponseType} from "@/types/responses";

export const favoritesProvider = {
  getFavorites: async (
    paginationObj: PaginationType
  ): Promise<ResponseType<AdsGetFavoritesResponseType>> => {
    const response = await apiClient(`/propertyAd/favorites`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetFavoritesResponseType;
    return { ...response, data };
  },

  addToFavorites: async (id: string) => {
    const response = await apiClient(`/propertyAd/${id}/favorite/add`, 'POST', {
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    return { ...response };
  },

  removeFromFavorites: async (
    id: string
  ) => {
    const response = await apiClient(`/propertyAd/${id}/favorite/remove`, 'POST', {
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    return { ...response };
  },
};
