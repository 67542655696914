export const unifiedStyle = (props) => {
  return {
    display: 'flex',
    height: 'calc(100vh - 72px - 199px - 24px - 56px)',
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: `${props.theme.fontFamily}`,
    backgroundColor: `${props.theme.backgroundColor.lightBlue1}`,
    position: 'relative',
    minHeight: '750px',
    [`@media (max-height: 830px)`]: {
      minHeight: '550px',
    },
    '*': {
      boxSizing: 'border-box',
      fontFamily: `${props.theme.fontFamily}`,
      '::-webkit-scrollbar': {
        width: '8px',
        height: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#ffffff00',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#ccc',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  };
};

export const unifiedSidebarStyle = (state, props) => {
  const sidebarView = state.sidebarview
    ? {
        left: '0',
      }
    : {};

  const mq = [...props.theme.breakPoints];

  return {
    width: '423px',
    height: '100%',
    minHeight: '750px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [`@media ${mq[0]}`]: {
      position: 'absolute!important',
      left: '-100%',
      top: '0',
      bottom: '0',
      width: '100%!important',
      zIndex: '2',
      backgroundColor: `${props.theme.backgroundColor.lightBlue1}`,
      transition: 'all .3s ease-out',
      ...sidebarView,
    },
    [`@media (max-height: 830px)`]: {
      minHeight: '550px',
    },
  };
};

export const unifiedMainStyle = (state, props) => {
  const mq = [...props.theme.breakPoints];

  const emptyTitle = state.isChatSelected
    ? {}
    : {
        justifyContent: 'center',
        alignItems: 'center',
      };

  return {
    position: 'relative',
    backgroundColor: `${props.theme.backgroundColor.lightBlue2}`,
    border: `1px solid ${props.theme.borderColor.primary}`,
    borderRadius: '24px',
    width: 'calc(100% - 423px)',
    height: '100%',
    minHeight: '750px',
    order: '2',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    ...emptyTitle,
    [`@media ${mq[1]}, ${mq[2]}`]: {
      width: '100%',
    },
    [`@media (max-height: 830px)`]: {
      minHeight: '550px',
    },
  };
};

export const unifiedSelectChatMessageStyle = (props) => {
  return {
    fontSize: '16px',
    lineHeight: '22px',
    color: `${props.theme.color.primary}`,
  };
};
