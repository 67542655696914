/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties, ReactElement } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Stack,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  ClickAwayListener,
  Button,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/system';
import { clientRequestProvider } from '@/providers';
import { appSetNotification } from '@/store';
import {
  ActivityKindEnum,
  RentPeriodEnum,
  ClientRequestModel,
  State,
  PropertyKindEnum,
  NotificationTypeEnum,
  gptResponseType,
  CompletionStatusEnum,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
} from '@/types';
import moment from 'moment';
import { MyButton, MyDialog, DialogTariffPlan } from '@/components';
import {
  smallSocialButtonsData,
  dialogWarningData,
} from '../../AccountManagement/utils/dialogDescription';
import { IDialogContent } from '../../AccountManagement/utils/types';
import { priceFormat, stringAvatar } from '@/utils';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

import styles from './RequestsMyListItem.module.scss';
import { ReactComponent as Marker } from './svg/iconMarker.svg';
import { ReactComponent as Fee } from './svg/fee.svg';
import { ReactComponent as Shower } from './svg/iconShower.svg';
import { ReactComponent as Dollar } from './svg/iconDollar.svg';
import { ReactComponent as Buildings } from './svg/iconBuildings.svg';
import { ReactComponent as House } from './svg/iconHouse.svg';
import { ReactComponent as Beds } from './svg/iconBeds.svg';
import { ReactComponent as Ruler } from './svg/iconRuler.svg';
import { ReactComponent as Calendar } from './svg/calendarPlus.svg';
import { ReactComponent as Search } from './svg/iconSearch.svg';
import IconTimer from './svg/iconTimer.svg';
import IconTimerRed from './svg/iconTimerRed.svg';
import IconUpdate from './svg/iconUpdate.svg';
import IconEdit from './svg/iconEdit.svg';
import IconDelete from './svg/iconDelete.svg';
import { Term, SmallShell } from '../../Requests/Ad/styled';

import { ContactBlock } from './ContactBlock';
import { DescriptionBlock } from './DescriptionBlock';
import { SocialButton } from './SocialButton';
import { Padding } from '@mui/icons-material';

interface RequestsMyListItemProps {
  item: ClientRequestModel;
  btnClientRequest: boolean;
  deleteRequest?: (id: string) => void;
  setRequests?: React.Dispatch<React.SetStateAction<ClientRequestModel[]>>;
}

interface IStyleLabelProps {
  opacity?: number;
}

const SpecificationsContainer = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'left',

  '& .MuiDivider-root': {
    backgroundColor: '#2A3842',
    opacity: 0.3,
    width: '1px',
  },
});

const StyleLabel = styled(Typography)<IStyleLabelProps>(({ opacity }) => ({
  color: '#2A3842',
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '3px',
  opacity: opacity ? 0 : 1,
}));

export const RequestsMyListItem = ({
  item,
  item: {
    id,
    userId,
    areaFrom,
    areaTo,
    baths,
    bedsArr,
    createdAt,
    updatedAt,
    completionStatus,
    title,
    description,
    priceFrom,
    priceTo,
    propertyKind,
    addressEn,
    placeId,
    coordinatesLat,
    coordinatesLong,
    typeOfActivity,
    multilisting,
    period,
    creator,
    conversion,
    endOfPublishDayLeft,
    ttl,
  },
  setRequests,
  btnClientRequest,
  deleteRequest,
}: RequestsMyListItemProps) => {
  // const addressEn = [
  //   'Dubai, Business Bay, Bays Edge',
  //   'Dubai, Dubai Media City, Avani Palm View Hotel & Suites',
  //   'Test adress',
  //   'More test address 2',
  // ];
  const { openDialog, setOpenDialog } = useDialogs();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openMyDialog, setOpenMyDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [ttlValue, setTtlValue] = useState(ttl);

  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);
  const navigate = useNavigate();
  const tariffPlan = user.tariffPlan?.name;
  const isTariffFree = tariffPlan === UserTariffPlanEnum.Free;

  const currentUrl = window.location.href;

  const clientRequestCreator = {
    name: creator?.name,
    id: creator?.id,
    avatar: creator?.fileUrl,
  };

  const completionStatusLabels = {
    [CompletionStatusEnum.All]: 'Ready/OffPlan',
    [CompletionStatusEnum.Ready]: 'Ready',
    [CompletionStatusEnum.OffPlan]: 'OffPlan',
  };
  const activityKindLabels = {
    [ActivityKindEnum.Sale]: 'Buy',
    [ActivityKindEnum.Rent]: 'Rent',
  };
  const rentFrequencyLabels = {
    [RentPeriodEnum.Monthly]: 'Monthly',
    [RentPeriodEnum.Yearly]: 'Yearly',
    [RentPeriodEnum.Weekly]: 'Weekly',
    [RentPeriodEnum.Daily]: 'Daily',
  };
  const propertyKindLabels = {
    [PropertyKindEnum.Apartment]: 'Apartment',
    [PropertyKindEnum.Townhouse]: 'Townhouse',
    [PropertyKindEnum.Villa]: 'Villa',
    [PropertyKindEnum.Floor]: 'Floor',
    [PropertyKindEnum.Penthouse]: 'Penthouse',
    [PropertyKindEnum.ResidentialLand]: 'Residential Land',
    [PropertyKindEnum.VillaCompound]: 'Villa Compound',
    [PropertyKindEnum.HotelApartment]: 'Hotel Apartment',
    [PropertyKindEnum.CommercialVilla]: 'Commercial Villa',
    [PropertyKindEnum.Warehouse]: 'Warehouse',
    [PropertyKindEnum.IndustrialLand]: 'Industrial Land',
    [PropertyKindEnum.CommercialLand]: 'Commercial Land',
    [PropertyKindEnum.Office]: 'Office',
    [PropertyKindEnum.LabourCamp]: 'Labour Camp',
    [PropertyKindEnum.CommercialBuilding]: 'Commercial Building',
    [PropertyKindEnum.Shop]: 'Shop',
  };

  const completionStatusLabel = completionStatus && completionStatusLabels[completionStatus];
  const activityLabel = activityKindLabels[typeOfActivity];
  const periodLabel = period && rentFrequencyLabels[period];
  const propertyKindLabel = propertyKindLabels[propertyKind];

  const {
    currency: convertedCurrency,
    convertedPriceFrom,
    convertedPriceTo,
    areaMetric,
    convertedAreaFrom,
    convertedAreaTo,
  } = conversion;

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const formatNumber = (num: number) => {
    if (!num) return null;
    let result;
    if (num >= 1000000) {
      result = (num / 1000000).toFixed(1);
    } else if (num >= 1000) {
      result = (num / 1000).toFixed(1);
    } else {
      return num.toString();
    }
    if (result.endsWith('.0')) {
      result = result.substring(0, result.length - 2);
    }
    return result + (num >= 1000000 ? 'M' : 'K');
  };

  const isPrice = convertedPriceFrom || convertedPriceTo;
  const priceLabel =
    convertedPriceFrom && convertedPriceTo
      ? 'Price '
      : convertedPriceFrom
        ? 'Price from '
        : 'Price to ';
  const priceValue =
    convertedPriceFrom && convertedPriceTo
      ? `${formatNumber(convertedPriceFrom)} - ${formatNumber(convertedPriceTo)} `
      : convertedPriceFrom
        ? `${formatNumber(convertedPriceFrom)} `
        : `${formatNumber(convertedPriceTo)} `;

  const isArea = convertedAreaFrom || convertedAreaTo;

  const areaValue =
    convertedAreaFrom && convertedAreaTo
      ? `${formatNumber(Number(convertedAreaFrom))} - ${formatNumber(Number(convertedAreaTo))} ${areaMetric.toLowerCase()}`
      : convertedAreaFrom
        ? `from ${formatNumber(Number(convertedAreaFrom))} ${areaMetric.toLowerCase()}`
        : `to ${formatNumber(Number(convertedAreaTo))} ${areaMetric.toLowerCase()}`;

  // const feeFrom = Math.round(
  //   ((convertedPriceFrom! * (1 + Number(agencyFee)! / 100) - convertedPriceFrom!) * 10) / 10
  // );
  // const feeTo = Math.round(
  //   ((convertedPriceTo! * (1 + Number(agencyFee)! / 100) - convertedPriceTo!) * 10) / 10
  // );

  const textBeds =
    bedsArr && bedsArr?.length === 1 && bedsArr[0] === 'Studio'
      ? ''
      : (bedsArr && bedsArr?.length > 1) || (bedsArr && Number(bedsArr[0]) > 1)
        ? 'beds'
        : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  const onHandlerClickDelete = async () => {
    const { ok, message, status } = await clientRequestProvider.deleteClientRequest(id!);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setOpen(false);
    deleteRequest && deleteRequest(id!);
  };

  const onHandlerClickUpdate = async () => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    const { ok, message, status, data } = await clientRequestProvider.updateClientRequestById(id!, {
      ...item,
      multilisting: multilisting,
      ttl: ttlValue,
    });
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    if (setRequests) {
      setRequests((prevRequests: ClientRequestModel[]) =>
        prevRequests.map((request: ClientRequestModel) =>
          request.id === id
            ? { ...request, endOfPublishDayLeft: (data as ClientRequestModel).endOfPublishDayLeft }
            : request
        )
      );
    }
    setOpenUpdate(false);
  };

  const switchActionKey = (actionKey: string, name: string, message: string) => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    switch (actionKey) {
      case 'phoneAction':
        if (creator?.phone) {
          window.location.href = `tel:${creator?.phone}`;
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'whatsappAction':
        if (creator?.whatsapp) {
          window.open(`https://wa.me/${creator?.whatsapp}?text=${message}`, '_blank');
        } else {
          setOpenMyDialog(true);
        }
        break;
      case 'telegramAction':
        if (creator?.telegram) {
          window.open(`https://t.me/${creator?.telegram}?start=${message}`, '_blank');
        } else {
          setOpenMyDialog(true);
        }
        break;
      case 'opChatAction':
        if (creator?.chatId) {
          navigate(`/chat/${creator?.chatId}/${message}`);
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'emailAction':
        if (creator?.email && name) {
          const subject = encodeURIComponent('question');
          window.location.href = `mailto:${creator?.email}?subject=${subject}&body=${message}`;
        } else {
          console.error('Email address not available!');
        }
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const handleButtonClick = (actionKey: string, name: string) => {
    const message = encodeURIComponent(
      `Hello, ${creator?.name}!
      I’m thrilled to inform you that I have a property listing on Online 
      Property that perfectly matches your client’s request: ${currentUrl}
      Please feel free to reach out if you need further details.`
    );
    switchActionKey(actionKey, name, message);
  };

  const onHandlerSearch = () => {
    const queryParams = {
      activityKind: typeOfActivity,
      addressParamAddress: addressEn,
      addressParamLat: coordinatesLat,
      addressParamLong: coordinatesLong,
      addressParamName: addressEn,
      addressParamPlaceId: placeId,
      areaMax: convertedAreaTo,
      areaMin: convertedAreaFrom,
      baths: baths,
      bedsArr: bedsArr,
      priceMax: convertedPriceTo,
      priceMin: convertedPriceFrom,
      propertyKind: propertyKind,
      rentFrequency: period,
    };
    const queryString = Object.entries(queryParams)
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
      .join('&');
    const url = `/?${queryString}`;

    navigate(url);
  };

  const handleChangeTtl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTtlValue(event.target.value);
    // setError(false);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '8px 16px',
      fontSize: '14px',
      maxWidth: 300,
      backgroundColor: 'white',
      color: 'rgba(42, 56, 66, 1)',
      boxShadow: '0px 0px 21px 2px rgba(0,0,0,0.35)',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
    },
  });

  return (
    <Stack className={styles.listItem}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={1}
        sx={{ width: '100%', mb: '13px' }}
      >
        <ContactBlock
          user={clientRequestCreator}
          userMe={user}
          isTariffFree={isTariffFree}
          handleClickOpenDialog={handleClickOpenDialog}
        />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent={{ xs: 'left', sm: 'right' }}
          spacing={1}
          sx={{ width: '100%' }}
        >
          <Calendar />
          <Typography sx={{ fontSize: '15px', opacity: 0.5 }}>
            {moment(updatedAt).format('D MMMM, YYYY')}
          </Typography>
        </Stack>
      </Stack>
      <Typography sx={{ fontSize: '22px', lineHeight: '26px', fontWeight: 600 }}>
        {title}
      </Typography>
      {description && <DescriptionBlock description={description} />}
      <Stack direction='row' alignItems='flex-start' spacing={3} sx={{ width: '100%' }}>
        {isPrice ? (
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            {priceLabel}
            <span style={{ color: '#1650FF' }}>{priceValue}</span>
            {convertedCurrency}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            {'Price  '}
            <span style={{ color: '#1650FF' }}>Negotiable</span>
          </Typography>
        )}
        {period && (
          <SmallShell alignItems='center' justifyContent='center'>
            <Term>{periodLabel}</Term>
          </SmallShell>
        )}
      </Stack>
      <Stack direction='row' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
        {multilisting && (
          <Stack
            sx={{
              paddingX: '15px',
              height: '30px',
              borderRadius: '14px',
              marginLeft: '5px',
              border: `1px solid #1650FF`,
            }}
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='center'
          >
            <Fee />
            <Term>{multilisting}</Term>
          </Stack>
        )}
        {completionStatusLabel && (
          <Stack
            sx={{
              paddingX: '15px',
              height: '30px',
              borderRadius: '14px',
              marginLeft: '5px',
              border: `1px solid #1650FF`,
            }}
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='center'
          >
            <House />
            <Term>{completionStatusLabel}</Term>
          </Stack>
        )}
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
        sx={{ width: '95%' }}
      >
        <Stack sx={{ width: '24px' }}>
          <Marker />
        </Stack>
        <Stack sx={{ width: '95%' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#2A3842',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {addressEn}
          </Typography>
        </Stack>
      </Stack>
      {!btnClientRequest && (
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={1}
          sx={{ width: '95%' }}
        >
          <div className={`${styles.timer} ${endOfPublishDayLeft > 2 ? '' : styles.endingTimer}`}>
            <img
              src={endOfPublishDayLeft > 2 ? IconTimer : IconTimerRed}
              alt=''
              className={styles.menuIcon}
            />
            Active until:
            <span>
              {endOfPublishDayLeft} {endOfPublishDayLeft === 1 ? 'day' : 'days'}
            </span>
          </div>
        </Stack>
      )}
      <Divider sx={{ border: `1px solid #E9EEF1`, width: '100%', margin: '5px 0' }} />
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='wrap'
        spacing={1.5}
        sx={{ width: '95%' }}
      >
        <IconText icon={<Dollar />}>{activityLabel}</IconText>
        <Divider orientation='vertical' flexItem />
        <IconText icon={<Buildings />}>{propertyKindLabel}</IconText>
        {bedsArr?.length && (
          <>
            <Divider orientation='vertical' flexItem />
            <IconText
              style={{ margin: bedsArr?.length > 7 ? '10px 0 0' : '0 0 0 12px' }}
              icon={<Beds />}
            >{`${bedsArr} ${textBeds}`}</IconText>
          </>
        )}
      </Stack>
      {!btnClientRequest || user.id === userId ? (
        <Stack
          direction='row'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ width: '100%', marginTop: '10px' }}
        >
          <MyButton
            data={{
              buttonsIcon: true,
              icon: <img src={IconUpdate} alt='update' />,
              buttonName: 'Update',
              customWidth: '214px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() =>
              isTariffFree ? handleClickOpenDialog(HeaderDialogsEnum.Tariff) : setOpenUpdate(true)
            }
          />
          <MyButton
            data={{
              styleType: 'onlyIcon',
              icon: <img src={IconEdit} alt='edit' />,
              customWidth: '103px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() =>
              isTariffFree
                ? handleClickOpenDialog(HeaderDialogsEnum.Tariff)
                : navigate(`/client-request/edit/${id}`)
            }
          />
          <MyButton
            data={{
              styleType: 'onlyIcon',
              icon: <img src={IconDelete} alt='delete' />,
              customWidth: '103px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() =>
              isTariffFree ? handleClickOpenDialog(HeaderDialogsEnum.Tariff) : setOpen(true)
            }
          />
        </Stack>
      ) : (
        <Stack
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          sx={{ marginTop: '10px', width: '100%' }}
        >
          <SocialButton
            data={{
              styleType: 'OPchat',
              icon: <Search />,
              variant: 'contained',
            }}
            onClick={() => onHandlerSearch()}
          />
          {smallSocialButtonsData?.map((content: IDialogContent, index: number) => (
            <SocialButton
              key={index}
              data={content}
              onClick={() => handleButtonClick(content?.actionKey || '', content?.name)}
            />
          ))}
        </Stack>
      )}
      <MyDialog
        open={openMyDialog}
        dialogTitle={dialogWarningData.dialogTitle.label}
        width={dialogWarningData.width}
      >
        <StyleLabel style={{ textAlign: 'center', marginBottom: '15px' }}>
          {dialogWarningData.dialogTitle.description}
        </StyleLabel>
        <MyButton
          data={dialogWarningData.dialogContent[0]}
          onClick={() => setOpenMyDialog(false)}
        />
      </MyDialog>
      <MyDialog
        open={open}
        onClose={() => setOpen(false)}
        dialogTitle='Confirm deletion of your request'
        width='730'
      >
        <div className={styles.dialogDescription}>
          Are you sure you want to delete the request you created? After deletion, it will be
          inaccessible and will disappear from the system.
        </div>

        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: `Delete`,
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={onHandlerClickDelete}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              buttonType: 'button',
              styleType: 'cancel',
            }}
            onClick={() => setOpen(false)}
          />
        </div>
      </MyDialog>
      <MyDialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        dialogTitle='Select update period'
        width='730'
      >
        <div className={styles.dialogDescription}>
          Please select the period for which your request will be in the system and will be visible
          to other users.
        </div>
        <FormControl
          sx={{
            width: '100%',
            marginTop: '16px',
          }}
        >
          <RadioGroup value={ttlValue} onChange={handleChangeTtl} sx={{ width: '100%' }}>
            <Stack spacing={2} direction={'row'} justifyContent={'center'}>
              <FormControlLabel
                value={'one_week'}
                control={<Radio sx={{ padding: '0', alignSelf: 'center' }} />}
                label={'1 week'}
                sx={{
                  justifyContent: 'space-between',
                  alignItem: 'center',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              />
              <FormControlLabel
                value={'two_weeks'}
                control={<Radio sx={{ padding: '0', alignSelf: 'center' }} />}
                label={'2 week'}
                sx={{
                  justifyContent: 'space-between',
                  alignItem: 'center',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>

        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: `Update`,
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={onHandlerClickUpdate}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              buttonType: 'button',
              styleType: 'cancel',
            }}
            onClick={() => setOpenUpdate(false)}
          />
        </div>
      </MyDialog>
    </Stack>
  );
};

const IconText = ({
  icon,
  children,
  style,
}: {
  icon: ReactElement;
  children?: string;
  style?: CSSProperties;
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='left' spacing={0.5} style={style}>
      {icon}
      <Typography sx={{ fontSize: { xs: '14px', sm: '15px' } }}>{children}</Typography>
    </Stack>
  );
};
