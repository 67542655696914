import { UNITS_MODAL_TYPE } from '../UnitsView.init';
import { IModalDialogWordings } from './ModalDialogs.types';

const deaultButtonWordings = {
  leftBtnText: 'Confirm',
  rightBtnText: 'Cancel',
};

const defaultWordings: IModalDialogWordings = {
  title: 'Please confirm',
  typography: undefined,
  ...deaultButtonWordings,
};

export const getWordings = (modalType: string, totalSelected = 0): IModalDialogWordings => {
  const wordingsTemplate = {
    [UNITS_MODAL_TYPE.deleteOne]: {
      title: 'Are you going to delete this unit?',
      typography: `You are about to remove a unit from the system. When confirmed, information about the unit will not be available; to restore it, you will need to download the units again.`,
      ...deaultButtonWordings,
    },
    [UNITS_MODAL_TYPE.deleteAll]: {
      title: 'Are you going to delete all the selected units?',
      typography: `You are about to remove all the selected units from the system. When confirmed,
                information about all the selected
                (${totalSelected ? totalSelected : 0})${' '}
                units will not be available. To restore it, you will need to download the units
                again.`,
      ...deaultButtonWordings,
    },
    [UNITS_MODAL_TYPE.editOne]: {
      title: 'Edit unit information',
      leftBtnText: 'Save',
      rightBtnText: 'Cancel',
    },
    [UNITS_MODAL_TYPE.saveAsPdfOne]: {
      title: 'Generate PDF for unit',
      typography: 'Are you going to create PDF for this unit?',
      ...deaultButtonWordings,
    },
    [UNITS_MODAL_TYPE.saveAsPdfAll]: {
      title: 'Generate PDF for selected unit',
      typography: `Are you going to create PDF for all the selected(${totalSelected ? totalSelected : 0})${' '} units?`,
      ...deaultButtonWordings,
    },
  };

  const wordings = wordingsTemplate[modalType];
  if (!wordings?.title) return defaultWordings; // Fixed typo here
  return wordings;
};
