import queryString from 'query-string';
import {
  ResponseType,
  AdsFilteringType,
  AdsSortingType,
  PaginationType,
  AdsGetAllResponseType,
  AdModel,
  AdModelStatus,
  AdsGetTotalsResponseType,
  PriceType,
  CreateAdType,
} from '@/types';
import { apiClient } from '@/providers';

export const adsProvider = {
  getAds: async (
    isMyAds: boolean,
    filteringObj: AdsFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    let furniture;
    let renovation;
    let floor;

    if (isMyAds) {
      const oldFurniture = filteringObj?.furniture as string;
      const oldRenovation = filteringObj?.renovation as string;
      const oldFloor = filteringObj?.floor as string;
      furniture = oldFurniture?.toLowerCase() === "any" ? undefined : filteringObj.furniture;
      renovation = oldRenovation?.toLowerCase() === "any" ? undefined : filteringObj.renovation;
      floor = oldFloor?.toLowerCase() === "any" ? undefined : filteringObj.floor;
    } else {
      furniture = filteringObj.furniture;
      renovation = filteringObj.renovation;
      floor = filteringObj.floor;
    }

    const response = await apiClient(`/propertyAd${isMyAds ? '/me' : ''}/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      sort: [{ ...sortingObj }],
      filter: [{ ...filteringObj, furniture, renovation, floor }],
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetAllResponseType;
    return { ...response, data };
  },

  getAdById: async (id: string, currency: string = 'AED', pdf: boolean = false) => {
    const url = `/propertyAd/${id}${pdf ? `?currency=${currency}` : (currency !== 'AED' ? `?currency=${currency}` : '')}`;

    const response = await apiClient<AdModel>(url, 'GET');

    return response.ok ? response : { ...response, data: null };
  },

  updateAdById: async (id: string, payload: AdModel | AdModelStatus): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}`, 'PATCH', payload);

    return { ...response, data: null };
  },

  updateAdStatus: async (payload: any): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/status`, 'PATCH', payload);

    return response;
  },

  createAd: async (payload: AdModel): Promise<ResponseType> => {
    const response = await apiClient('/propertyAd', 'POST', payload);

    const responseData = response.data as CreateAdType;
    return { ...response, data: responseData };
  },

  getMyTotals: async (): Promise<ResponseType> => {
    const response = await apiClient('/propertyAd/me/count', 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetTotalsResponseType;
    return { ...response, data };
  },

  getPriceById: async (id: string): Promise<ResponseType> => {
    const limit = 50;
    const offset = 0;
    const paramsStr = queryString.stringify({ limit, offset });

    const response = await apiClient(`/propertyAd/${id}/prices?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as PriceType;
    return { ...response, data };
  },

  massUpdateAdById: async (ids: string[], payload: Partial<AdModel> | AdModel | AdModelStatus): Promise<ResponseType> => {
    const requestData = {...payload, ids: ids};
    const response = await apiClient(`/propertyAd/mass-update`, 'PATCH', requestData as any);

    return { ...response, data: null };
  },
};
