import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { newBuildingsProvider, offPlanProvider } from '@/providers';

import { appSetNotification } from '@/store';
import { Box } from '@mui/material';
import { Loader, Pagination } from '@/components';
import { UnitsViewCards } from './UnitsViewCards';
import { UnitsViewFilters } from './UnitsViewFilters';
import { UnitsViewMiddleSection } from './UnitsViewMiddleSection';
import { NotificationTypeEnum } from '@/types';
import {
  IUnitSearchQueryParams,
  IPagination,
  IUnitViewBulding,
  IUnitViewPlans,
} from '@/types/requests';
import { IUnitSearchItems } from '@/types/responses';

import { parseUrlToQueryParams, buildUrWithBaseURLFromParams } from './queryParamsParse';
import { UNITS_MODAL_TYPE, formDefaultValue } from './UnitsView.init';
import { checkUnitOperationType, getBuildingList, getPlansList } from './UnitsView.utils';
import { ModalDialogs } from './ModalDialogs';
import { IPatchUnitProps, IUnitState } from './ModalDialogs/ModalDialogs.types';

export const UnitsView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [curConstractionId, setCurConstractionId] = useState<string | undefined>();
  const [allUnits, setAllUnits] = useState<IUnitSearchItems[] | undefined>();
  const [curUnits, setCurUnits] = useState<IUnitSearchItems[] | undefined>();
  const [selectedUnits, setSelectedUnits] = useState<{ [id: string]: boolean | undefined }>({});
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [paginationScheme, setPaginationScheme] = useState<IPagination | undefined>();
  const [buildingsList, setBuildingsList] = useState<IUnitViewBulding[] | undefined>();
  const [plansList, setPlansList] = useState<IUnitViewPlans[] | undefined>();

  const dispatch = useDispatch();
  const formMethods = useForm(formDefaultValue);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = parseUrlToQueryParams(window.location.href);
    searchPlans(queryParams);
  }, []);

  useEffect(() => {
    const newConstructionId = paginationScheme?.queryParams?.newConstructionId;

    if (newConstructionId && newConstructionId !== curConstractionId) {
      setCurConstractionId(newConstructionId);
      updateBuidingsAndPlans(newConstructionId);
    }
  }, [paginationScheme, curConstractionId]);

  const updateBuidingsAndPlans = async (id: string) => {
    const buildingsList = await getBuildingList(id);
    if (buildingsList) setBuildingsList(buildingsList);
    const plans = await getPlansList(id);
    if (plans) setPlansList(plans);
  };

  const reloadPage = () => {
    const queryParams = parseUrlToQueryParams(window.location.href);
    return searchPlans(queryParams);
  };

  const searchPlans = async (queryParams: IUnitSearchQueryParams) => {
    setIsLoading(true);

    try {
      const { ok, data, status, message } = await newBuildingsProvider.searchPlans(queryParams);

      if (ok && data) {
        setAllUnits(data.items);
        setPaginationScheme({
          page: Math.floor(queryParams.offset / queryParams.limit) + 1,
          limit: queryParams.limit || 10,
          offset: queryParams.offset || 0,
          total: data.total,
          visible: data.total > queryParams.limit,
          queryParams,
        });
      } else {
        const errorMessage = message || 'Error fetching data';
        dispatch(appSetNotification(NotificationTypeEnum.Error, errorMessage, status));
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      dispatch(appSetNotification(NotificationTypeEnum.Error, errorMessage, 500));
    } finally {
      setIsLoading(false);
    }
  };

  const updatePaginationScheme = useCallback(
    (pScheme: IPagination) => {
      setPaginationScheme(pScheme);

      const { urlPathname, urlSearch } = buildUrWithBaseURLFromParams(
        `${window.location.origin}${window.location.pathname}`,
        pScheme.queryParams
      );

      navigate(`${urlPathname}${urlSearch}`);
      searchPlans(pScheme.queryParams);
    },
    [navigate]
  );

  const handlePagination = useCallback(
    (page: number) => {
      if (!paginationScheme) return;

      const newOffset = (page - 1) * paginationScheme.limit;
      updatePaginationScheme({
        ...paginationScheme,
        page,
        offset: newOffset,
        queryParams: { ...paginationScheme.queryParams, offset: newOffset },
      });
    },
    [paginationScheme, updatePaginationScheme]
  );

  const handleQueryChange = (update: Partial<IUnitSearchQueryParams>) => {
    if (!paginationScheme) return;

    updatePaginationScheme({
      ...paginationScheme,
      queryParams: { ...paginationScheme.queryParams, ...update },
    });
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleClickUnitMenu = (modalType: string, units?: IUnitSearchItems[]) => () => {
    if (modalType === UNITS_MODAL_TYPE.selectAll) {
      const data = allUnits?.reduce(
        (acc, el) => {
          acc[el.id] = true;
          return acc;
        },
        {} as { [id: string]: boolean }
      );

      return setSelectedUnits(data || {});
    }
    if (modalType === UNITS_MODAL_TYPE.unSelectAll) {
      return setSelectedUnits({});
    }

    const isSingle = checkUnitOperationType(modalType);

    if (isSingle && units && units.length === 1) {
      setCurUnits(units);
    } else if (isSingle === false && Object.keys(selectedUnits).length > 0) {
      //
    } else {
      return;
    }
    setOpenModal(true);
    setModalType(modalType);
  };

  const handleSelectUnit = (unitId: string, isChecked: boolean) => () => {
    setSelectedUnits((prev) => ({ ...prev, [unitId]: isChecked }));
  };

  const fetchUnitEdit = async (unit: IUnitState) => {
    const patchedUnit: IPatchUnitProps = {
      id: unit.id,
      areaSQFT: unit.areas_sqft,
      price: unit.price_aed,
      apartmentNumber: unit.apartment_num,
      floor: unit.floor_num,
    };
    await offPlanProvider.patchUnit(patchedUnit);
    reloadPage();

    setOpenModal(false);
  };

  const fetchUnitsDelete = async (ids: string[]) => {
    await offPlanProvider.deleteUnitsBatch(ids);
    reloadPage();

    setOpenModal(false);
  };

  const generateUnitsPDF = (ids: string[]) => {
    const idsQueryString = ids.map((id) => `ids[]=${id}`).join('&');
    // const buildingIdQuery = `buildingId=${allUnits?.[0]?.buildingId}`;
    setOpenModal(false);
    navigate(`/admin/units/create-pdf/${curConstractionId}?${idsQueryString}`);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%' }}>
          <FormProvider {...formMethods}>
            <Box
              component='form'
              noValidate
              sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <UnitsViewFilters
                plansList={plansList}
                buildingsList={buildingsList}
                onChangeFilters={handleQueryChange}
                defaultFilterOptions={paginationScheme?.queryParams?.filter?.[0] || {}}
              />
              <UnitsViewMiddleSection
                itemsOnPage={allUnits?.length}
                itemsTotal={paginationScheme?.total || 0}
                onChangeSort={handleQueryChange}
                defaultSortOptions={
                  paginationScheme?.queryParams?.sort?.[0] || {
                    field: 'price',
                    order: 'ASC',
                  }
                }
                onClickForAllSelectedUnits={handleClickUnitMenu}
              />
              <UnitsViewCards
                units={allUnits}
                selectedUnits={selectedUnits}
                onClickUnit={handleClickUnitMenu}
                onSelectUnit={handleSelectUnit}
              />
              {paginationScheme?.visible && (
                <Pagination paginationObj={paginationScheme} onChangePage={handlePagination} />
              )}
            </Box>
          </FormProvider>
          <ModalDialogs
            modalType={modalType}
            curUnits={curUnits}
            selectedUnits={selectedUnits}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            fetchUnitEdit={fetchUnitEdit}
            fetchUnitsDelete={fetchUnitsDelete}
            generateUnitsPDF={generateUnitsPDF}
          />
        </Box>
      )}
    </>
  );
};
