import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MyButton, SectionHeader, SectionTitle } from '@/components';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Link
} from '@mui/material';
import { FormCheckboxWithWatch } from '@/components/uiComponents/FormCheckbox/FormCheckboxWithWatch';
import { Gallery } from './Gallery';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfPreviewCard } from './PdfSettingsComponents/PdfPreviewCard/PdfPreviewCard';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export const OffplanPdfSettings = ({
  showMyContactsCheckbox,
  images = [],
  onSubmit,
  pdfFile,
  isViewPdfVisible,
  hideViewPdf
}: {
  showMyContactsCheckbox?: boolean;
  images?: NewBuildingsMediaType[];
  onSubmit: (data: Record<string, boolean>) => void;
  pdfFile: Blob | null
  isViewPdfVisible: boolean
  hideViewPdf: () => void
}) => {
  const navigate = useNavigate();

  const [selectionState, setSelectionState] = useState({ selectAll: true });
  const { id } = useParams();

  const pdfPreviewVariants = [
    { name: 'classic-view', label: `Classic View (include 5 photo's)` },
    {
      name: 'all-in-one-view',
      label: `All-in-One View (include 3 photo's)`,
    },
    { name: 'lux-view', label: `Lux View (include 3 photo's)` },
    { name: 'one-page', label: `Onepage View (include 3 photo's)` },
    { name: 'portable-view', label: 'Mobile Version (not for print)' },
  ];

  const form = useForm({
    defaultValues: {
      radioOption: 'classic-view',
    },
  });


  const checkboxOptions = [
    {
      label: 'Show my contacts',
      name: 'brokerDetailsShow',
      showCheckbox: showMyContactsCheckbox,
      checkedByDefault: true,
    },
    {
      label: 'Show agency contacts',
      name: 'agencyDetailsShow',
      showCheckbox: true,
      checkedByDefault: false,
    },
    {
      label: 'Show price',
      name: 'showPrice',
      showCheckbox: true,
      checkedByDefault: true,
    },
  ];

  if (images?.length <= 2) {
    return (
      <>
        <SectionHeader title={'Save as PDF'} />
        <Stack sx={{ marginTop: '40px', alignItems: 'center', color: 'red' }}>
          <h2>
            The&nbsp;
            <Link href={`/ads/${id}`} title={`/ads/${id}`}>
              ad contains {images?.length || 0} images
            </Link>
            &nbsp;, and at least three are required to generate a PDF
          </h2>
        </Stack>
      </>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        {/* @ts-ignore */}
        <form onSubmit={form.handleSubmit(onSubmit)} onChange={() => isViewPdfVisible && hideViewPdf()}>
          <SectionHeader title={'Save as PDF'} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '56px' }} />
          <Stack sx={{ marginTop: '40px', gap: '20px' }}>
            <SectionTitle variant='blank' number={1} title='PDF settings' />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0 58px' }}>
              {checkboxOptions.map((option, i) =>
                option.showCheckbox ? (
                  <FormCheckboxWithWatch
                    checked={option.checkedByDefault}
                    label={option.label}
                    name={option.name}
                    key={option.name + i}
                  />
                ) : null
              )}
            </Box>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              marginTop: '56px',
            }}
          >
            <SectionTitle variant='blank' number={2} title='PDF Preview' />
            <Controller
              name='radioOption'
              control={form.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Grid container spacing={2}>
                    {pdfPreviewVariants.map((variant) => (
                      <Grid item key={variant.name}>
                        <Grid container direction='column'>
                          <PdfPreviewCard cardName={variant.name} />
                          <FormControlLabel
                            value={variant.name}
                            control={<Radio disableRipple />}
                            label={<Typography variant='body1'>{variant.label}</Typography>}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              )}
            />
            {/* <RadioGroup value={selectedValue} onChange={handleChange}>
            <Grid container spacing={2}>
                {pdfPreviewVariants.map((variant) => (
                    <Grid item key={variant.name}>
                        <Grid container direction="column" alignItems="center">
                        <PdfPreviewCard
                key={variant.name}
                name={variant.name}
                label={variant.label}
                checked={variant.checkedByDefault}
              />
                            <FormControlLabel 
                                value={value}
                                control={<Radio />}
                                label={<Typography variant="body1">{variant.label}</Typography>}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </RadioGroup> */}
            {/* {pdfPreviewVariants.map((variant) => (
              <PdfPreviewCard
                key={variant.name}
                name={variant.name}
                label={variant.label}
                checked={variant.checkedByDefault}
              />
            ))} */}
          </Stack>
          <Stack
            alignItems='flex-start'
            spacing={2}
            sx={{
              backgroundColor: '#F1F7FF',
              borderRadius: '20px',
              marginTop: '56px',
              padding: '20px',
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent='space-between'
              gap={2}
            >
              <SectionTitle variant='blank' number={3} title='Select image' />
              <Box
                display='flex'
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap={2}
                width={{ xs: '100%', sm: 'auto' }}
              >
                <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='129px'>
                  <MyButton
                    data={{
                      buttonName: 'Select all',
                      variant: 'outlined',
                      styleType: 'cancel',
                      sx: { height: '40px' },
                    }}
                    onClick={() => {
                      setSelectionState({
                        selectAll: true,
                      });
                    }}
                  />
                </Box>
                <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='129px'>
                  <MyButton
                    data={{
                      buttonName: 'Unselect all',
                      variant: 'outlined',
                      styleType: 'cancel',
                      sx: { height: '40px' },
                    }}
                    onClick={() => {
                      setSelectionState({
                        selectAll: false,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Stack>
          {images && <Gallery images={images} selectionState={selectionState} />}
          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
            style={{ marginTop: '20px' }}
          >
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              {isViewPdfVisible ? <MyButton
                onClick={() => pdfFile && window.open(URL.createObjectURL(pdfFile))}
                data={{
                  buttonName: 'View PDF',
                  variant: 'contained',
                  sx: { height: '40px' },
                }}
              /> : <MyButton
                data={{
                  buttonName: 'Save',
                  variant: 'contained',
                  buttonType: 'submit',
                  sx: { height: '40px' },
                }}
              />}


            </Box>
            <Box flexGrow={{ xs: 1, sm: 0 }} minWidth='156px'>
              <MyButton
                data={{
                  buttonName: 'Cancel',
                  variant: 'outlined',
                  styleType: 'cancel',
                  sx: { height: '40px' },
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Box>
          </Box>
        </form>
        {/* <Box sx={{ display: 'flex', width: '100%', gap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '35%',
              height: '100%',
              gap: '10px',
            }}
          >
            <img
              src={images[0].fileUrl}
              alt={`image-${images[0].id}`}
              style={{
                width: '100%',
                height: 'auto',
                aspectRatio: '16 / 9',
                objectFit: 'cover',
              }}
              loading='lazy'
            />
            <img
              src={images[1].fileUrl}
              alt={`image-${images[1].id}`}
              style={{
                width: '100%',
                height: 'auto',
                aspectRatio: '16 / 9',
                objectFit: 'cover',
              }}
              loading='lazy'
            />
          </Box>
          <Box sx={{ display: 'flex', width: '65%' }}>
            <img
              src={images[2].fileUrl}
              alt={`image-${images[2].id}`}
              style={{
                width: '100%',
                height: 'auto',
                aspectRatio: '16 / 9',
                objectFit: 'cover',
              }}
              loading='lazy'
            />
          </Box>
        </Box> */}
      </FormProvider>
    </>
  );
};

const isReferrerFromCurrentDomain = () => {
  const referrerHostname = document.referrer.split('/')[2];
  const currentHostname = window.location.host;

  return referrerHostname === currentHostname;
};

const useReferrerDomainCheck = () => {
  const [fromSameDomain, setFromSameDomain] = useState(false);

  useEffect(() => {
    setFromSameDomain(isReferrerFromCurrentDomain());
  }, []);

  return fromSameDomain;
};
