import { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { gptResponseType, NotificationTypeEnum } from '@/types';
import { gptProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { FormInput, SectionTitle, MyButton } from '@/components';
import styles from './PropertyDescription.module.scss';
import ChatGPTLogo from './ChatGPT.png';

export const PropertyDescription = () => {
  const dispatch = useDispatch();
  const [isDescriptionGptContainerShown, setIsDescriptionGptContainerShown] =
    useState<boolean>(false);
  const [descriptionText, setDescriptionText] = useState<string>('');
  const [isDescriptionFirstAttempt, setIsDescriptionFirstAttempt] = useState<boolean>(true);
  const [isDescriptionLoading, setIsDescriptionLoading] = useState<boolean>(false);

  const { getValues, setValue } = useFormContext();

  const generateKeywords = () => {
    const values = getValues();
    
    const keywords: string[] = [
      values.activityKind,
      values.propertyKind,
      `${values.builtUpArea ? values.builtUpArea : 'nice'} sq.feet`,
      values.transactionType,
      values.view,
    ];
    const features = [
      { key: 'balcony', value: 'Balcony' },
      { key: 'maidsRoom', value: 'Maids room' },
      { key: 'sharedPool', value: 'Shared pool' },
      { key: 'builtInWardrobes', value: 'Built in wardrobes' },
      { key: 'childrenPlayArea', value: "Children's play area" },
      { key: 'sharedSpa', value: 'Shared spa' },
      { key: 'sharedGym', value: 'Shared gym' },
      { key: 'study', value: 'Study' },
      { key: 'centralAc', value: 'Central A/C' },
      { key: 'coveredParking', value: 'Covered parking' },
      { key: 'barbecueArea', value: 'Barbecue area' },
      { key: 'petsAllowed', value: 'Pets allowed' },
      { key: 'concierge', value: 'Concierge' },
      { key: 'publicParking', value: 'Public parking' },
      { key: 'kitchenAppliances', value: 'Kitchen appliances' },
      { key: 'lobbyInBuilding', value: 'Lobby in building' },
      { key: 'maidService', value: 'Maid service' },
      { key: 'petsNotAllowed', value: 'Pets not allowed' },
      { key: 'privateGarden', value: 'Private garden' },
      { key: 'privateGym', value: 'Private gym' },
      { key: 'privatePool', value: 'Private pool' },
      { key: 'security', value: 'Security' },
      { key: 'tennisCourts', value: 'Tennis courts' },
      { key: 'privateSpa', value: 'Private spa' },
      { key: 'laundryFacility', value: 'Laundry facility' },
    ];
    features.forEach((item) => {
      if (values[item.key] === true) {
        keywords.push(item.value);
      }
    });

    return keywords;
  };

  const handleGetDescription = async () => {
    setIsDescriptionFirstAttempt(false);
    setIsDescriptionLoading(true);
    const request = !descriptionText ? generateKeywords() : descriptionText;
    const { ok, data, status, message } = await gptProvider.getDescription(request);
    if (ok) {
      const { choices } = data as gptResponseType;
      setValue('description', choices[0]?.message?.content);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsDescriptionLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.rowDescription}>
        <div className={styles.labelContainer}>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Description
          </Typography>
          <div className={styles.gptButtonContainer}>
            <MyButton
              data={{
                buttonName: 'Generate with AI',
                customWidth: '150px',
                size: 'small',
                variant: 'contained',
                buttonType: 'button',
              }}
              disabled={isDescriptionLoading || isDescriptionGptContainerShown}
              onClick={handleGetDescription}
            />
            <MyButton
              data={{
                buttonName: !isDescriptionGptContainerShown ? 'Edit' : 'Save',
                customWidth: '79px',
                size: 'small',
                variant: 'outlined',
                styleType: 'cancel',
                buttonType: 'button',
                icon: <img src={ChatGPTLogo} alt='Chat GPT' />,
                buttonsIcon: true,
                iconPosition: 'end',
              }}
              disabled={isDescriptionLoading || isDescriptionFirstAttempt}
              onClick={() =>
                setIsDescriptionGptContainerShown(!isDescriptionGptContainerShown ? true : false)
              }
            />
          </div>
        </div>
        <FormInput
          uiVariant='blank'
          name='description'
          multiline
          placeholder='Add a description of your property'
          minRows={undefined}
          rows={10}
          inputProps={{ maxLength: 8000 }}
        />
        {isDescriptionGptContainerShown && (
          <div className={styles.gptContainer}>
            <TextField
              multiline
              placeholder='Create a 160-word maximum description for a sale/rent real estate ad using the following...'
              value={descriptionText}
              onChange={({ target }) => setDescriptionText(target.value)}
              minRows={undefined}
              rows={2}
              inputProps={{ maxLength: 8000 }}
              sx={{ width: '100%' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
