import { Stack, Typography, Divider } from '@mui/material';
import { PaymentPlanType } from '@/types';
import { Section } from '@/components/Ad/Section';
import { SpecificationsContainer, Title, Circle } from './styled';

export const PaymentPlanBlock = ({ paymentPlan }: { paymentPlan: PaymentPlanType }) => {
  const { items: plan } = paymentPlan;

  return (
    <Section>
      <Title>{paymentPlan.name}</Title>
      <div>
        {plan &&
          plan.map((notation, index) => (
            <div key={notation.name + index}>
              <IconText
                number={index + 1}
                name={notation.name}
                description={notation.description}
                value={notation.value}
              />
              {plan.length > index + 1 && <Divider />}
            </div>
          ))}
      </div>
    </Section>
  );
};

const IconText = ({
  number,
  name,
  description,
  value,
}: {
  number: number;
  name: string;
  description: string;
  value: string;
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <SpecificationsContainer direction='row' spacing={2}>
        <Circle>{number}</Circle>
        <Stack alignItems='flex-start' justifyContent='center'>
          <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>{name}</Typography>
          <Typography sx={{ fontSize: { xs: '10px', sm: '12px' }, opacity: 0.5 }}>
            {description}
          </Typography>
        </Stack>
      </SpecificationsContainer>
      <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>{value}</Typography>
    </Stack>
  );
};
