import React, { KeyboardEvent, useState, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import { IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { MyButton } from '@/components/uiComponents';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as CaretDown } from 'src/components/uiComponents/MySelect/CaretDown.svg';

interface IconComponentProps {
  className: string;
}

interface TypesUserProps {
  value: string;
  label: string;
}

const typesUsers = [
  { value: 'all', label: 'All users' },
  { value: 'broker', label: 'Licensed Broker' },
  { value: 'manager', label: 'Agency Manager' },
  { value: 'agency', label: 'Real estate agency' },
  { value: 'developer', label: 'Developer' },
  { value: 'foreign', label: 'Foreign Broker' },
]

interface UserSearchProps {
  search: string;
  tabValue: number,
  typeUsers: string,
  setTypeUsers: React.Dispatch<React.SetStateAction<string>>,
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onAddNewUser: () => void;
}

const UserSearch: React.FC<UserSearchProps> = ({ search, tabValue, typeUsers, setTypeUsers, setSearch, onAddNewUser }) => {
  const StyledRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '12px',
    gap: '12px',
  });

  const SearchFieldStyle = styled(TextField)({
    width: '100%',
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputAddressValue, setInputAddressValue] = useState<string>(search);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputAddressValue]);

  const handleAddressPressEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearch(inputAddressValue);
    }
  };

  const handleChangeTypeUser = (event: SelectChangeEvent) => {
    setTypeUsers(event.target.value as string);
  }

  return (
    <StyledRow>
      {tabValue === 0 &&
        <Select
          sx={{
            maxWidth: '216px',
            width: '100%',
          }}
          MenuProps={{
            sx: {
              marginTop: '4px',
            },
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={typeUsers}
          onChange={(event) => handleChangeTypeUser(event)}
          IconComponent={(_props: IconComponentProps) => {
            const rotate = _props.className.toString().includes('iconOpen');
            return (
              <div
                data-qa='dropdown_menu_btn'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  pointerEvents: 'none',
                  right: 15,
                  height: '15px',
                  width: '15px',
                  transform: rotate ? 'rotate(180deg)' : 'none',
                }}
              >
                <CaretDown />
              </div>
            );
          }}
        >
          {typesUsers.map((typeUser: TypesUserProps, index) =>
            <MenuItem
              key={typeUser.value}
              sx={{
                borderBottom: index !== typesUsers.length - 1 ? '1px solid #e0e0e0' : '',
                padding: '11px 17px'
              }}
              value={typeUser.value}
            >
              {typeUser.label}
            </MenuItem>
          )}
        </Select>
      }
      <SearchFieldStyle
        placeholder='Search by name, phone or email'
        inputProps={{ ref: inputRef }}
        value={inputAddressValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInputAddressValue(event.target.value);
        }}
        onClick={event => event.stopPropagation()}
        onKeyDown={handleAddressPressEnter}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{
                visibility: inputAddressValue ? 'visible' : 'hidden',
              }}
              onClick={() => {
                setSearch('');
                setInputAddressValue('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />

      <MyButton
        data={{
          buttonName: 'Search',
          styleType: 'button',
          variant: 'contained',
          customWidth: '166px',
        }}
        onClick={() => setSearch(inputAddressValue)}
      />
      {tabValue === 0 && <MyButton
        data={{
          buttonName: 'Add new user',
          styleType: 'cancel',
          variant: 'contained',
          customWidth: '160px',
        }}
        onClick={onAddNewUser}
      />}
    </StyledRow>
  );
};

export default UserSearch;
