import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import { NewBuildingModel, UserModel, AdFieldStatusEnum, NotificationTypeEnum } from '@/types';
import { offPlanProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { icons } from '../../Ad/Icons/icons';
import { Loader } from '@/components';

const StyledMenuItem = styled(MenuItem)({
  fontWeight: '400',
});

export const OffPlanBuildingMenu = ({
  page,
  open,
  typeMenu,
  handleClose,
  anchorEl,
  user,
  planId,
  setIsShownConfirmDialog,
  setTypeOfDialog,
}: {
  page: NewBuildingModel;
  open: boolean;
  typeMenu?: string;
  planId?: string;
  handleClose: () => void;
  anchorEl: Element | null;
  user?: UserModel;
  setIsShownConfirmDialog: (value: boolean) => void;
  setTypeOfDialog?: (type: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusAd, setStatusAd] = useState<AdFieldStatusEnum>(page.status);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (type: string) => {
    if (typeMenu === 'plan') {
      if (type === 'edit') {
        navigate(`/admin/plan/${planId}/edit/${page.id}`);
      } else {
        setIsShownConfirmDialog(true);
        setTypeOfDialog && setTypeOfDialog(type);
      }
    }
    setIsShownConfirmDialog(true);
    setTypeOfDialog && setTypeOfDialog(type);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuItem onClick={() => handleClick('edit')}>
          <ListItemIcon>
            <icons.EditComment />
          </ListItemIcon>
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClick('delete')}>
          <ListItemIcon>
            <icons.Delete />
          </ListItemIcon>
          Delete
        </StyledMenuItem>
      </Menu>
    </>
  );
};
