import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActivityKindEnum, CompletionStatusEnum, AdFieldCurrencyEnum } from '@/types';
import { SectionTitle, FormButtons } from '@/components';
import { Typography } from '@mui/material';

const typeOfActivityValues = [
  { label: 'Buy', value: ActivityKindEnum.Sale },
  { label: 'Rent', value: ActivityKindEnum.Rent },
];

export const ActivityType = ({
  typeOfActivity,
  setTypeOfActivity,
  setCompletionStatus,
}: {
  typeOfActivity?: ActivityKindEnum;
  setTypeOfActivity: (value: ActivityKindEnum) => void;
  setCompletionStatus: (value: CompletionStatusEnum | undefined) => void;
}) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const onHandlerClick = (value: ActivityKindEnum) => {
    setValue('typeOfActivity', value);
    setTypeOfActivity(value);
    clearErrors(['typeOfActivity']);
    if (value === ActivityKindEnum.Rent) {
      setCompletionStatus(undefined);
      setValue('completionStatus', undefined);
    }
  };

  return (
    <div>
      <SectionTitle
        required
        errorTitle={
          (typeof errors?.typeOfActivity?.message === 'string' &&
            errors?.typeOfActivity?.message) ||
          ''
        }
        number={1}
        title='Select type of activity'
      />
      <FormButtons
        options={typeOfActivityValues}
        onClick={onHandlerClick}
        activeButton={typeOfActivity}
        size='middle'
        name={'typeOfActivity'}
      />
    </div>
  );
};
