import { useParams } from 'react-router-dom';
import { Dashboard, InviteLink } from '@/components';

export const InviteLinkPage = () => {
  const { code } = useParams();
  if (code) {
    localStorage.setItem('inviteCode', code);
  }
  return (
    <Dashboard openSignupPopup isChecked>
      <InviteLink />
    </Dashboard>
  );
};
