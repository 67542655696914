import { useEffect } from 'react';
import {
  AdFieldMultilistingEnum,
  AdFieldBedsEnum,
  AdFieldCurrencyEnum,
  PropertyKindEnum,
} from '@/types';
import { FormSelect, SectionTitle, FormButtons } from '@/components';
import { FormInputNumeric } from './FormInputNumeric';
import { useFormContext } from 'react-hook-form';

import styles from './PropertyPrice.module.scss';
import { Typography } from '@mui/material';

const multilistingValues = [
  { label: 'Covered', value: AdFieldMultilistingEnum.Covered },
  { label: 'Not covered', value: AdFieldMultilistingEnum.NotCovered },
];

const currencyValues = [
  { label: 'AED', value: AdFieldCurrencyEnum.AED },
  { label: 'USD', value: AdFieldCurrencyEnum.USD },
];

const bedsValues = [
  { label: 'Studio', value: AdFieldBedsEnum.Studio },
  { label: '1', value: AdFieldBedsEnum.One },
  { label: '2', value: AdFieldBedsEnum.Two },
  { label: '3', value: AdFieldBedsEnum.Three },
  { label: '4', value: AdFieldBedsEnum.Four },
  { label: '5', value: AdFieldBedsEnum.Five },
  { label: '6', value: AdFieldBedsEnum.Six },
  { label: '7', value: AdFieldBedsEnum.Seven },
  { label: '8+', value: AdFieldBedsEnum.EightPlus },
];

interface PropertyPriceProps {
  priceTo?: number;
  setPriceTo: (value: number) => void;
  priceFrom?: number;
  setPriceFrom: (value: number) => void;
  multilisting?: AdFieldMultilistingEnum;
  setMultilisting: (value: AdFieldMultilistingEnum) => void;
  bedsArr?: AdFieldBedsEnum | AdFieldBedsEnum[] | null;
  setBedsArr: (value: AdFieldBedsEnum | AdFieldBedsEnum[] | null) => void;
}

export const PropertyPrice = ({
  priceTo,
  setPriceTo,
  priceFrom,
  setPriceFrom,
  multilisting,
  setMultilisting,
  bedsArr,
  setBedsArr,
}: PropertyPriceProps) => {
  const { setValue, getValues } = useFormContext();
  const { propertyKind } = getValues();

  useEffect(() => {
    setValue('multilisting', AdFieldMultilistingEnum.Covered);
  }, []);

  const handleChangePriceFrom = (newValue: number) => {
    // console.log('newValue', newValue);
    const value = newValue || 0;
    setPriceFrom(value);
  };

  const handleChangePriceTo = (newValue: number) => {
    const value = newValue || 0;
    setPriceTo(value);
  };

  const handleChangeMultilisting = (newValue: AdFieldMultilistingEnum) => {
    setValue('multilisting', newValue);
    setMultilisting(newValue);
  };

  const handleChangeBeds = (value: AdFieldBedsEnum) => {
    let newSelection: AdFieldBedsEnum[] = [];

    if (Array.isArray(bedsArr)) {
      // Toggle the clicked value in the array
      if (bedsArr.includes(value)) {
        newSelection = bedsArr.filter((item) => item !== value);
      } else {
        newSelection = [...bedsArr, value];
      }
    } else {
      // Treat bedsArr as an array with a single item or empty array
      newSelection = bedsArr === value ? [] : [value];
    }

    const customSort = (a: AdFieldBedsEnum, b: AdFieldBedsEnum) => {
      if (a === AdFieldBedsEnum.Studio) return -1;
      if (b === AdFieldBedsEnum.Studio) return 1;

      const numA = parseInt(a);
      const numB = parseInt(b);

      if (isNaN(numA) || isNaN(numB)) {
        return a.localeCompare(b);
      }
      return numA - numB;
    };

    newSelection.sort(customSort);

    setBedsArr(newSelection);
    setValue('bedsArr', newSelection);
  };

  const bedsIsVisible = [
    PropertyKindEnum.Apartment,
    PropertyKindEnum.Villa,
    PropertyKindEnum.CommercialVilla,
    PropertyKindEnum.Townhouse,
    PropertyKindEnum.Penthouse,
    PropertyKindEnum.HotelApartment,
  ].includes(propertyKind);

  return (
    <div className={styles.container}>
      <SectionTitle number={5} title='Price' />
      <div className={styles.row}>
        <div className={styles.currency}>
          <FormSelect
            uiVariant='blank'
            name='currency'
            data={currencyValues}
            defaultValue={currencyValues[0].value}
            label='Price'
          />
        </div>
        <div className={styles.price}>
          <FormInputNumeric
            value={priceFrom}
            uiVariant='blank'
            name='priceFrom'
            fullWidth
            label='Price from'
            placeholder='Enter price from'
            handleChange={handleChangePriceFrom}
          />
        </div>
        <div className={styles.price}>
          <FormInputNumeric
            value={priceTo}
            uiVariant='blank'
            name='priceTo'
            fullWidth
            label='Price to'
            placeholder='Enter price to'
            handleChange={handleChangePriceTo}
          />
        </div>
      </div>
      <div>
        <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 15px' }}>
          Do you receive a fee from your client?
        </Typography>
        <FormButtons
          options={multilistingValues}
          onClick={handleChangeMultilisting}
          activeButton={multilisting}
          size='big'
        />
      </div>
      {bedsIsVisible && (
        <div>
          <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
            Bedrooms
          </Typography>
          <FormButtons
            options={bedsValues}
            onClick={handleChangeBeds}
            activeButton={bedsArr}
            size='small'
          />
        </div>
      )}
    </div>
  );
};
