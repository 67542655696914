import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

export interface AddressIconTextProps {
  iconSrc?: string;
  children?: ReactNode;
  style?: Style;
}

export const AddressIconText = ({ iconSrc, children, style }: AddressIconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 40,
      ...style,
    },
    addressText: {
      fontSize: 64,
      lineHeight: 1.3,
      fontWeight: 'bold',
    },
    icon: {
      width: 60,
      height: 60,
    },
    iconContainer: {
      display: 'flex',
      width: 100,
      height: 100,
      border: '2px solid #2A3842',
      borderRadius: '14px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        {iconSrc && <Image src={iconSrc} style={styles.icon} />}
      </View>
      <Text style={styles.addressText}>{children}</Text>
    </View>
  );
};
