import axios, { AxiosRequestHeaders, isAxiosError } from 'axios';

import { AUTH_ACCESSTOKEN } from '@/constants';
import { RequestDataType, ResponseDataType, ResponseType } from '@/types';

export const apiClient = async <T = ResponseDataType>(
  uri: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  data: RequestDataType = null
): Promise<ResponseType<T | any>> => {
  const url = `${process.env.REACT_APP_API_URL}${uri}`;
  const token = localStorage.getItem(AUTH_ACCESSTOKEN);

  const headers = {} as AxiosRequestHeaders;
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (data !== null) {
    headers['Content-Type'] = 'application/json';
  }

  try {
    const response = await axios<T | any>({
      url,
      method,
      headers,
      data,
    });

    return {
      data: response.data,
      ok: true,
      status: response.status,
      message: response.statusText,
    };
  } catch (error) {
    let status, message;
    if (isAxiosError(error)) {
      status = error?.response?.data?.statusCode || error?.response?.status || error?.code;
      if (Array.isArray(error?.response?.data?.message)) {
        message = error?.response?.data?.message.join(';;; ');
      } else if (typeof error?.response?.data === 'string') {
        message = error?.response?.data;
      } else {
        message = error?.response?.data?.message || error?.response?.statusText || error?.message;
      }
    } else {
      message = `Not Axios error was received from the server (apiClient request). Error: ${JSON.stringify(
        error
      )}`;
    }

    return {
      data: null,
      ok: false,
      status,
      message,
    };
  }
};
