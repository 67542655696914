import { useSelector } from 'react-redux';
import { State } from '@/types';
import { Dashboard, NewSignUp, Ads } from '@/components';

export const SignUpPage = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  return (
    <Dashboard>
      {isLoggedIn ? <Ads /> : <NewSignUp />}
    </Dashboard>
  );
};





