export enum AdsViewModeEnum {
  Map = 'map',
  List = 'list',
}

export enum SortingOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum AdFieldStatusEnum {
  Published = 'published',
  Unpublished = 'unpublished',
  Archived = 'archived',
}

export enum ProfileViewStatusEnum {
  Listings = 'listings',
  Staff = 'staff',
  Requests = 'requests',
}

export enum OffPlanPropertyKind {
  Apartments = 'apartments',
  Villa = 'villa',
  Townhouse = 'townhouse',
  Penthouse = 'penthouse',
  HotelApartment = 'hotel apartment',
  ResidentialBuilding = 'residential building',
}

export enum RequestsButtonStatusEnum {
  RequestsForMe = 'requestsforme',
  RequestsByMe = 'myrequests',
}

export enum ClientRequestsButtonStatusEnum {
  ClientRequests = 'client-requests',
  MyRequests = 'my-requests',
}

export enum NewBuildingsButtonStatusEnum {
  OffPlan = 'off-plans',
  Developers = 'developers',
}

export enum AdFieldVisibilityEnum {
  Public = 'public',
  Private = 'private',
}

export enum ActivityKindEnum {
  Rent = 'rent',
  Sale = 'sale',
}

export enum AdFieldLandKindEnum {
  Commercial = 'Commercial',
  Residential = 'Residential',
  Industrial = 'Industrial',
  Agricultural = 'Agricultural',
  GovAuthorities = 'Gov. Authorities',
  PublicFacilities = 'Public Facilities',
  Utility = 'Utility',
  OpenSpace = 'Open Space',
  PublicFacility = 'Public Facility',
}

export enum DevelopmentStatusEnum {
  PLANNED = 'planned',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  STOPPED = 'stopped',
}

export enum AdFieldFurnitureEnum {
  Furnished = 'furnished',
  PartlyFurnished = 'partly furnished',
  Unfurnished = 'unfurnished',
}
export enum OffPlanFurnitureEnum {
  Furnished = 'furnished',
  PartlyFurnished = 'partly furnished',
  Unfurnished = 'unfurnished',
}

export enum OffPlanStatusEnum {
  Yes = 'true',
  No = 'false'
}

export enum AdFieldRenovationEnum {
  Renovation = 'Renovation',
  NonRenovation = 'Non renovation',
}

export enum AdFieldBedsEnum {
  Studio = 'Studio',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  EightPlus = '8+',
}
export enum QuartersEnum {
  FirstQuarter = '1Q',
  SecondQuarter = '2Q',
  ThirdQuarter = '3Q',
  FourthQuarter = '4Q',
}
export enum AdFieldBathsEnum {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  EightPlus = '8+',
}

export enum AdFieldFloorEnum {
  Low = 'low',
  Middle = 'middle',
  High = 'high',
}

export enum AdFieldCurrencyEnum {
  USD = 'USD',
  AED = 'AED',
}

export enum PropertyKindEnum {
  Apartment = 'apartment',
  Townhouse = 'townhouse',
  Villa = 'villa',
  Floor = 'floor',
  Penthouse = 'penthouse',
  ResidentialLand = 'residentialLand',
  VillaCompound = 'villaCompound',
  HotelApartment = 'hotelApartment',
  CommercialVilla = 'commercialVilla',
  Warehouse = 'warehouse',
  IndustrialLand = 'industrialLand',
  CommercialLand = 'commercialLand',
  Office = 'office',
  LabourCamp = 'labourCamp',
  CommercialBuilding = 'commercialBuilding',
  Shop = 'shop',
}

export enum ProfilePropertyKindOldEnum {
  Apartment = 'apartment',
  Building = 'building',
  Commercial = 'commercial',
  Villa = 'house',
  Land = 'land',
}

export enum ProfilePropertyKindNewEnum {
  Apartment = 'apartment',
  Building = 'commercialBuilding',
  Commercial = 'commercialVilla',
  Villa = 'villa',
  Land = 'commercialLand',
}

export enum AdFieldOccupancyEnum {
  // OwnerOccupied = 'owner occupied',
  Tenanted = 'tenanted',
  Vacant = 'vacant',
}

export enum CompletionStatusEnum {
  All = 'all',
  Ready = 'ready',
  OffPlan = 'off-plan',
}

export enum TransactionTypeEnum {
  Ready = 'ready',
  Resale = 'resale',
  Distress = 'distress',
}

export enum AdFieldMultilistingEnum {
  Covered = 'Covered',
  NotCovered = 'NotCovered',
}

export enum AdFieldRentFrequencyEnum {
  Monthly = 'monthly',
  Annual = 'annual',
}

export enum RentPeriodEnum {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Weekly = 'weekly',
  Daily = 'daily',
}

export enum AdminPeriodEnum {
  Quarter = '90',
  Month = '30',
  TwoWeek = '15',
  Week = '7',
  Day = '1',
}

export enum FileKindEnum {
  Image = 'image',
  Video = 'video',
  Plan = 'plan',
  Link = 'link',
}

export enum GenderEnum {
  Male = 'male',
  Female = 'female',
}

export enum PersonKindEnum {
  Juridicial = 'juridicial',
  Physical = 'physical',
}

export enum UserKindEnum {
  Admin = 'admin',
  Agency = 'agency',
  Broker = 'broker',
  Developer = 'developer',
  Manager = 'manager',
  Foreign = 'foreign',
}

export enum HeaderDialogsEnum {
  SignUp = 'signUp',
  LogIn = 'logIn',
  PrivacyPolicy = 'privacyPolicy',
  Agreement = 'agreement',
  Confirmation = 'confirmation',
  ResetPassword = 'resetPassword',
  CreatePassword = 'createPassword',
  Operator = 'operator',
  SelectUserType = 'selectUserType',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  ChangePassword = 'changePassword',
  Request = 'request',
  Tariff = 'tariff',
}

export enum NotificationTypeEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum SocialButtonEnum {
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  Email = 'email',
  OpChat = 'chat',
  Phone = 'phone',
}

export enum RequestSortingFieldsEnum {
  DATE = 'date',
  TOTAL = 'total',
  UNREAD = 'unread',
  FEE = 'fee',
}

export enum NewBuildingsSortingFieldsEnum {
  Date = 'date',
  PriceFrom = 'priceFrom',
  PriceTo = 'priceTo',
}

export enum RequestsStatusEnum {
  Read = 'read',
  UnRead = 'unread',
}

export enum AdminMetricsEnum {
  ChurnRate = 'churnRate',
  NotLoggedIn = 'notLoggedIn',
  LastPropertyAdPublished = 'lastPropertyAdPublished',
  LastClientRequestPublished = 'lastClientRequestPublished',
}

export enum AdminPaymentEnum {
  Subscriptions = 'subscriptions',
  Transactions = 'transactions',
  Tariffs = 'tariffs',
  Coupons = 'coupons',
}

export enum AdminLocationListEnum {
  VerifiedLocations = 'verifiedLocations',
  VerificationRequests = 'verificationRequests',
}

export enum PaymentStatusEnum {
  Pending = 'pending', 
  Payed = 'payed', 
  Closed = 'closed', 
  Expired = 'expired', 
  Canceled = 'canceled',
}

export enum UserTariffPlanEnum {
  Free = 'Free',
  Pro = 'Pro',
  Team = 'Team',
  Trial = 'Trial',
  Foreign = 'Foreign',
  TeamFive = 'Team (5 users)',
  TeamTwenty = 'Team (20 users)',
  TeamFifty = 'Team (50 users)',
}

export enum IntervalTariffPlan {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths',
  Custom = 'Custom',
}
