import { useEffect, useState, SyntheticEvent } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Tabs, Tab, Paper, IconButton, InputBase } from '@mui/material';
import {
  PaginationType,
  AdFieldStatusEnum,
  NotificationTypeEnum,
  NewBuildingsAdminFilteringType,
  INewBuildingsModel,
  NewBuildingsResponseType,
} from '@/types';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination, MyButton, NewBuildingListItem } from '@/components';
import { ReactComponent as IconSearch } from './iconSearch.svg';
import { COUNT_ADS_PER_PAGE } from './constants';
import { EmptyList } from '../../Ads/EmptyList';
import styles from './AdminNewBuildings.module.scss';


interface TotalCountsType {
  published: number;
  unpublished: number;
  archived: number;
}

interface AdsProps {
  isMyAds?: boolean;
}

export const AdminNewBuildings = ({ isMyAds }: AdsProps) => {
  const dispatch = useDispatch();
  const changeQueryParams = useChangeQueryParams('admin/offplans');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [isLoadingGetMyTotals, setIsLoadingGetMyTotals] = useState<boolean>(false);
  const [isLoadingGetAds, setIsLoadingGetAds] = useState<boolean>(true);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  const [lastPages, setLastPages] = useState({
    published: '1',
    unpublished: '1',
    archived: '1',
  });
  const [searchValue, setSearchValue] = useState('');
  const [newConstructions, setNewConstructions] = useState<INewBuildingsModel[]>();
  const [query, setQuery] = useState('');
  const [changeStatus, setChangeStatus] = useState('');

  const [filteringObj, setFilteringObj] = useState<NewBuildingsAdminFilteringType>();
  const [paginationObj, setPaginationObj] = useState<PaginationType>();

  const [totals, setTotals] = useState<TotalCountsType>({
    published: 0,
    unpublished: 0,
    archived: 0,
  });

  const getNewConstructions = async () => {
    const newFilteringObj: NewBuildingsAdminFilteringType = {
      status: (queryParams.status as AdFieldStatusEnum) || AdFieldStatusEnum.Published,
    };
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };

    setIsLoadingGetAds(true);
    const { ok, data, status, message } = await newBuildingsProvider.getAdminOffPlans(
      newFilteringObj,
      newPaginationObj,
      query
    );
    if (ok) {
      const { items, total } = data as NewBuildingsResponseType;
      setNewConstructions(items);
      setFilteringObj({ ...newFilteringObj });
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetAds(false);
  };

  useEffect(() => {
    getNewConstructions();
  }, []);

  useEffect(() => {
    if (queryParamsStr || query) {
      getNewConstructions();
    }
  }, [queryParamsStr, query, changeStatus]);

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleChangeTab = (_: SyntheticEvent, newStatus: AdFieldStatusEnum) => {
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as AdFieldStatusEnum]: page });
    return changeQueryParams({
      ...queryParams,
      page: lastPages[newStatus],
      status: newStatus,
    });
  };

  // const handleChangeStatus = async (id: string, newStatus: AdFieldStatusEnum) => {
  //   setIsLoadingChangeStatus(true);
  //   const { ok, status, message } = await adsProvider.updateAdById(id, {
  //     status: newStatus,
  //   });
  //   if (ok) {
  //     getMyTotals();
  //     const newAds = ads?.filter((item) => item.id !== id);
  //     setAds(newAds);
  //   } else {
  //     dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
  //   }
  //   setIsLoadingChangeStatus(false);
  // };

  const handleSearchSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setQuery(searchValue);
  };

  if (isLoadingGetMyTotals || isLoadingGetAds || isLoadingChangeStatus) {
    return <Loader />;
  }

  return (
    <div className={styles.adsContainer}>
      <div className={styles.templateMyAdsContainer}>
        <Box className={styles.tabsContainer}>
          <Tabs
            value={filteringObj?.status || AdFieldStatusEnum.Published}
            onChange={handleChangeTab}
            aria-label='my ads tabs'
            className={styles.tabsInner}
          >
            <Tab
              id='tab-published'
              aria-controls='tabpanel-published'
              label={`Active${totals.published > 0 ? ` (${totals.published})` : ''}`}
              value={AdFieldStatusEnum.Published}
              className={styles.tabItem}
            />
            <Tab
              id='tab-unpublished'
              aria-controls='tabpanel-unpublished'
              label={`Draft${totals.unpublished > 0 ? ` (${totals.unpublished})` : ''}`}
              value={AdFieldStatusEnum.Unpublished}
              className={styles.tabItem}
            />
            <Tab
              id='tab-archived'
              aria-controls='tabpanel-archived'
              label={`Archived${totals.archived > 0 ? ` (${totals.archived})` : ''}`}
              value={AdFieldStatusEnum.Archived}
              className={styles.tabItem}
            />
          </Tabs>
        </Box>

        <form onSubmit={handleSearchSubmit} className={styles.search}>
          <Paper
            component='div'
            elevation={0}
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              border: '1px solid #E9EEF1',
            }}
          >
            <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
              <IconSearch />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Search by name, location, developer'
              inputProps={{ 'aria-label': 'search by name' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Paper>
          <MyButton
            data={{
              buttonName: 'Search',
              buttonType: 'submit',
              variant: 'contained',
              size: 'medium',
              customWidth: '138px',
            }}
          />
        </form>

        {!newConstructions?.length ? (
          <EmptyList />
        ) : (
          <div className={styles.adsList}>
            {(newConstructions as INewBuildingsModel[]).map((item) => (
              <NewBuildingListItem key={item.id} item={item} setChangeStatus={setChangeStatus}/>
            ))}
          </div>
        )}
        {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE ? (
            <Pagination
              paginationObj={paginationObj}
              onChangePage={handleChangePage}
            />
          ) : null}
      </div>
    </div>
  );
};
