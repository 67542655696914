import { Select, MenuItem } from '@mui/material';
import classNames from 'classnames';

import { AdsSortingType, SortingOrderEnum, SortingFieldsType } from '@/types';
import styles from './RequestsListControls.module.scss';
import { ReactComponent as CaretDown } from './caretDown.svg';

interface RequestsListControlsProps {
  sortingFields: string[];
  sortingObj: AdsSortingType;
  onChangeSort: (value: AdsSortingType) => void;
}

export const RequestsListControls = ({
  sortingFields,
  sortingObj: { field: fieldValue, order: orderValue },
  onChangeSort,
}: RequestsListControlsProps) => {
  const handleChangeSorting = (field: string) => {
    const order =
      fieldValue !== field
        ? SortingOrderEnum.Asc
        : orderValue === SortingOrderEnum.Asc
          ? SortingOrderEnum.Desc
          : SortingOrderEnum.Asc;
    onChangeSort({ field, order });
  };
  return (
    <div className={styles.listControls}>
      <div className={styles.sortControl}>
        <div>Sort by:</div>
        <Select
          value={fieldValue}
          className={classNames(styles.sortSelect, {
            [styles.sortOrderAsc]: orderValue === SortingOrderEnum.Asc,
            [styles.sortOrderDesc]: orderValue === SortingOrderEnum.Desc,
          })}
          IconComponent={({ className }) => (
            <CaretDown
              className={classNames(styles.sortSelectCaret, {
                [styles.sortCaretUp]: className.toString().includes('iconOpen'),
                [styles.sortCaretDown]: !className.toString().includes('iconOpen'),
              })}
            />
          )}
        >
          {sortingFields.map((field, index) => (
            <MenuItem
              key={field}
              value={field}
              divider={index < sortingFields.length - 1 && true}
              className={classNames(styles.sortSelectItem, {
                [styles.sortItemOrderAsc]:
                  fieldValue !== field ||
                  (fieldValue === field && orderValue === SortingOrderEnum.Desc),
                [styles.sortItemOrderDesc]:
                  fieldValue === field && orderValue === SortingOrderEnum.Asc,
              })}
              onClick={() => handleChangeSorting(field)}
            >
              {'Date'}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
