/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Link, Avatar, Typography, Stack, Divider, IconButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { UserModel, PropertyKindEnum, HeaderDialogsEnum } from '@/types';
import { styled, shouldForwardProp } from '@mui/system';
import { MyDialog } from '@/components';
import { MyButton } from './MyButton';
import { priceFormat, stringAvatar } from '@/utils';
import styles from './DeveloperItem.module.scss';
import {
  smallSocialButtonsData,
  dialogWarningData,
  socialButtonsData,
} from '../../AccountManagement/utils/dialogDescription';
import { IDialogContent } from '../../AccountManagement/utils/types';

import { ReactComponent as IconMarker } from './svg/location.svg';
import { ReactComponent as IconBuildings } from './svg/buildings.svg';
import { ReactComponent as IconProjects } from './svg/projects.svg';
import { ReactComponent as IconUnits } from './svg/units.svg';
import { ReactComponent as IconChat } from './svg/ChatText.svg';
const FONT_COLOR = '#2A3842';

interface IStyleLabelProps {
  opacity?: number;
}

const InfoNameStyle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  color: FONT_COLOR,
});

const InfoStyle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  color: FONT_COLOR,
  whiteSpace: 'pre-wrap',
});

const StyleLabel = styled(Typography)<IStyleLabelProps>(({ opacity }) => ({
  color: '#2A3842',
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '3px',
  opacity: opacity ? 0 : 1,
}));

export const DeveloperItem = ({ developer }: { developer: UserModel }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const propertyKindLabels = {
    [PropertyKindEnum.Apartment]: 'Apartment',
    [PropertyKindEnum.Townhouse]: 'Townhouse',
    [PropertyKindEnum.Villa]: 'Villa',
    [PropertyKindEnum.Floor]: 'Floor',
    [PropertyKindEnum.Penthouse]: 'Penthouse',
    [PropertyKindEnum.ResidentialLand]: 'Residential Land',
    [PropertyKindEnum.VillaCompound]: 'Villa Compound',
    [PropertyKindEnum.HotelApartment]: 'Hotel Apartment',
    [PropertyKindEnum.CommercialVilla]: 'Commercial Villa',
    [PropertyKindEnum.Warehouse]: 'Warehouse',
    [PropertyKindEnum.IndustrialLand]: 'Industrial Land',
    [PropertyKindEnum.CommercialLand]: 'Commercial Land',
    [PropertyKindEnum.Office]: 'Office',
    [PropertyKindEnum.LabourCamp]: 'Labour Camp',
    [PropertyKindEnum.CommercialBuilding]: 'Commercial Building',
    [PropertyKindEnum.Shop]: 'Shop',
  };

  const socialButton = smallSocialButtonsData?.filter((button) => button.name !== 'chat');

  const switchActionKey = (actionKey: string, name: string, message: string) => {
    switch (actionKey) {
      case 'phoneAction':
        if (developer.additionalPhones) {
          window.location.href = `tel:${developer.additionalPhones?.[0]}`;
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'whatsappAction':
        if (developer.whatsapp) {
          window.open(`https://wa.me/${developer.whatsapp}?text=${message}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'telegramAction':
        if (developer.telegram) {
          window.open(`https://t.me/${developer.telegram}?start=${message}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'opChatAction':
        if (developer.chatId) {
          navigate(`/chat/${developer.chatId}/${message}`);
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'emailAction':
        if (developer.additionalEmails && name) {
          const subject = encodeURIComponent('question');
          window.location.href = `mailto:${developer.additionalEmails?.[0]}?subject=${subject}&body=${message}`;
        } else {
          console.error('Email address not available!');
        }
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const handleButtonClick = (actionKey: string, name: string) => {
    const message = encodeURIComponent(
      `Hi, ${developer.name}, I'm considering making a purchase and would like additional information about the property listed on Online Property.`
    );
    switchActionKey(actionKey, name, message);
  };

  const areas = developer
    ? developer?.operationRegions?.map((region) => region.name).join(', ')
    : undefined;

  const propertyType = developer
    ? developer?.propertyKinds?.map((type) => propertyKindLabels[type]).join(', ')
    : undefined;

  return (
    <>
      <div className={styles.listItem}>
        <Stack direction='row' alignItems='center' spacing={1} style={{ position: 'relative' }}>
          <Link
            href={`/users/${developer.id}`}
            title={developer.name}
            sx={{ textDecoration: 'none', color: '#2A3842' }}
          >
            <Avatar
              src={developer?.media?.[0]?.fileUrl || undefined}
              alt={developer.name}
              {...stringAvatar(developer.name)}
              sx={{ width: 64, height: 64 }}
            />
          </Link>
          <Stack>
            <Link
              href={`/users/${developer.id}`}
              title={developer.name}
              sx={{ textDecoration: 'none', fontSize: '18px', fontWeight: '600', color: '#2A3842' }}
            >
              {developer.name}
            </Link>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Link
                href={`/users/${developer.id}`}
                title={developer.name}
                sx={{ textDecoration: 'none', fontSize: '14px', opacity: 0.5, color: '#2A3842' }}
              >
                Developer
              </Link>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          sx={{ fontSize: '12px', color: '#1650FF', marginLeft: '74px', fontWeight: 600 }}
        >
          from 800K AED per unit
        </Typography>
        <Divider sx={{ border: `1px solid #E9EEF1`, width: '100%', margin: '5px 0' }} />
        <Stack spacing={2}>
          {areas && (
            <Stack spacing={1}>
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={0.5}>
                <IconMarker />
                <InfoNameStyle>Areas:</InfoNameStyle>
              </Stack>
              <InfoStyle>{areas}</InfoStyle>
            </Stack>
          )}
          <Stack spacing={1}>
            <Stack direction='row' justifyContent='flex-start' spacing={0.5}>
              <IconProjects />
              <InfoNameStyle>
                Projects: <span style={{ color: '#1650FF' }}>{`8`}</span>
              </InfoNameStyle>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Stack direction='row' justifyContent='flex-start' spacing={0.5}>
              <IconUnits />
              <InfoNameStyle>
                Available units: <span style={{ fontWeight: 400 }}> {`40`}</span>
              </InfoNameStyle>
            </Stack>
          </Stack>
          {propertyType && (
            <Stack spacing={1}>
              <Stack direction='row' justifyContent='flex-start' spacing={0.5}>
                <IconBuildings />
                <InfoNameStyle>Property types:</InfoNameStyle>
              </Stack>
              <InfoStyle>{propertyType}</InfoStyle>
            </Stack>
          )}
        </Stack>

        <Stack
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          sx={{ marginTop: '10px' }}
        >
          <MyButton
            data={{
              styleType: 'OPchat',
              icon: <IconChat />,
              variant: 'contained',
            }}
            onClick={() => handleButtonClick('opChatAction', 'OPchat')}
          />
          {socialButton?.map((content: IDialogContent, index: number) => (
            <MyButton
              key={index}
              data={content}
              onClick={() => handleButtonClick(content?.actionKey || '', content?.name)}
            />
          ))}
        </Stack>
      </div>
      <MyDialog
        open={openDialog}
        dialogTitle={dialogWarningData.dialogTitle.label}
        width={dialogWarningData.width}
      >
        <StyleLabel style={{ textAlign: 'center', marginBottom: '15px' }}>
          {dialogWarningData.dialogTitle.description}
        </StyleLabel>
        <MyButton data={dialogWarningData.dialogContent[0]} onClick={() => setOpenDialog(false)} />
      </MyDialog>
    </>
  );
};
