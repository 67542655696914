import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// import { State } from '@/types';   // JSX >>> TSX
import {
  authProvider,
  stateLicenseProvider,
  supportProvider,
} from '@/providers';
import { appSetNotification, appSetLoading } from '@/store';
import { useOperatorForm, useSignUpForm } from '../utils/useFormLogic';
import DialogContentRenderer from '../Dialogs/DialogContentRenderer';
import SelectUserType from '../Dialogs/SelectUserType';
import PrivacyPolicy from '../Dialogs/PrivacyPolicy';
import Agreement from '../Dialogs/Agreement';
import Confirmation from '../Dialogs/Confirmation';

import {
  dialogConfirmationData,
  dialogOperatorData,
  dialogOperatorSuccessData,
  dialogSignUpData,
  dialogSignUpDataBroker,
  dialogSignUpDataAgency,
  dialogSignUpDataDeveloper,
  dialogSignUpDataManager,
  dialogSignUpDataForeign,
  dialogSignUpDataForeignPerson,
  dialogSignUpDataForeignOrganization,
  dialogUserTypeData,
  dialogUserTypeDataAdmin,
} from '../utils/dialogDescription';
import { formOfOrganization } from '../utils/fakeData';

const SignUp = ({ openDialog, handleOpenDialog }) => {
  // const { user } = useSelector(({ auth }: State) => auth);   // JSX >>> TSX
  const { name } = useSelector(({ auth }) => auth.user);
  const { message } = useSelector(({ app }) => app.notification);
  const { isLoggedIn } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code: inviteLinkCode } = useParams();

  const [userChecked, setUserChecked] = useState(false);
  const [valueUserType, setValueUserType] = useState('');
  const [dialogData, setDialogData] = useState(dialogSignUpData);
  const [userDLD, setUserDLD] = useState(null);
  const [userDataSubmit, setUserDataSubmit] = useState(null);
  const [newAutocompleteValue, setNewAutocompleteValue] = useState('');

  const [selectedType, setSelectedType] = useState('');
  const [inputAutocompleteValue, setAutocompleteValue] = useState('');
  const [userTypeDialogData, setUserTypeDialogData] = useState(isLoggedIn ? dialogUserTypeDataAdmin : dialogUserTypeData);
  const [kind, setKind] = useState('broker');
  const [selectedFormOfOrganization, setSelectedFormOfOrganization] = useState('');
  const [valueFormOfOrganization, setValueFormOfOrganization] = useState('');
  const [emailOrPhoneInput, setEmailOrPhoneInput] = useState('');
  const [logInPhoneValue, setLogInPhoneValue] = useState('');
  const [errorStatus, setErrorStatus] = useState('');

 
  const {
    handleSubmit: handleSubmitSignUp,
    errors: errorsSignUp,
    control: controlSignUp,
    resetForm: resetSignUpForm,
    setValue: setValueSignUp,
  } = useSignUpForm(userDLD, kind, valueFormOfOrganization, dialogData);

  const {
    handleSubmit: handleSubmitOperator,
    errors: errorsOperator,
    control: controlOperator,
    resetForm: resetOperatorForm,
    setValue: setValueOperatorForm,
  } = useOperatorForm({ name });

  useEffect(() => {
    setUserTypeDialogData(isLoggedIn ? dialogUserTypeDataAdmin : dialogUserTypeData);
  }, [isLoggedIn]);

  useEffect(() => {
    setEmailOrPhoneInput(null);
  }, [logInPhoneValue]);

  useEffect(() => {
    setLogInPhoneValue(null);
  }, [emailOrPhoneInput]);

  useEffect(() => {
    setKind(
      userTypeDialogData.dialogContent[0].radioGroup.find(
        (type) => type.label === selectedType,
      )?.name,
    );
    resetSignUpForm(null);
  }, [selectedType]);

  useEffect(() => {
    const dialogDataMap = {
      broker: dialogSignUpDataBroker,
      agency: dialogSignUpDataAgency,
      developer: dialogSignUpDataDeveloper,
      manager: dialogSignUpDataManager,
      foreign: dialogSignUpDataForeign,
    };
    setDialogData(kind ? dialogDataMap[kind] : dialogSignUpData);
    setNewAutocompleteValue('');
  }, [kind]);

  useEffect(() => {
    const dialogDataMap = {
      juridicial: dialogSignUpDataForeignOrganization,
      physical: dialogSignUpDataForeignPerson,
    };
    const value = formOfOrganization.find(
      (el) => el.label === selectedFormOfOrganization,
    )?.name;
    if (value !== undefined) {
      setValueFormOfOrganization(value);
      setDialogData(
        selectedFormOfOrganization ? dialogDataMap[value] : dialogSignUpData,
      );
    }
  }, [selectedFormOfOrganization]);

  useEffect(() => {
    if (kind !== 'manager' && inputAutocompleteValue.length < 2) {
      setUserDLD(null);
      resetSignUpForm();
    }
    handleOnChangeAutocomplete(inputAutocompleteValue);
  }, [inputAutocompleteValue]);

  useEffect(() => {
    if (userChecked) {
      if (errorStatus === 409) {
        updateFieldVisibility('error409', true);
        updateFieldVisibility('error', false);
      } else {
        updateFieldVisibility('error409', false);
        setDialogData((prevState) => {
          const updatedDialogContent = prevState.dialogContent.map(
            (content) => {
              if (content.name === 'error') {
                return {
                  ...content,
                  initialVisibility: true,
                  passiveLabel: errorStatus === 406 ? 'Try again later. OTP request limit: 1/min' : message,
                };
              }
              return content;
            },
          );
          return {
            ...prevState,
            dialogContent: updatedDialogContent,
          };
        });
      }
    }
  }, [errorStatus]);

  useEffect(() => {
    const fetchRes = async () => {
      if (newAutocompleteValue.length === 0) {
        resetSignUpForm();
        return;
      }
      try {
        const { data } = await switchUserType(kind, newAutocompleteValue);
        if (data?.items.length > 0) {
          const [el] = data?.items;
          if (el.phone === null) {
            updateFieldVisibility('phone', false);
          } else {
            updateFieldVisibility('phone', true);
          }
          if (el.email === null) {
            updateFieldVisibility('email', false);
          } else {
            updateFieldVisibility('email', true);
          }
          let user = {};
          if (kind === 'broker') {
            const { id, nameEn: name, agency: { nameEn: company } = {} } = el;
            const email = el.email;
            const phone = el.phone;
            user = {
              id,
              name,
              email,
              phone,
              company,
              profileVerificationOptions: newAutocompleteValue,
            };
          }
          if (kind === 'agency' || kind === 'developer') {
            const { id, nameEn: name, stateId: officeNumber } = el;
            const email = el.email;
            const phone = el.phone;
            user = {
              id,
              name,
              email,
              phone,
              profileVerificationOptions: newAutocompleteValue,
              officeNumber,
            };
          }
          if (kind === 'manager') {
            const { id, nameEn: name } = el;
            user = { id, company: newAutocompleteValue };
            setDialogData((prevState) => {
              const updatedDialogContent = prevState.dialogContent.map(
                (content) => {
                  if (content.name === 'emailOrPhone') {
                    return {
                      ...content,
                      disabled: false,
                    };
                  }
                  return content;
                },
              );
              return {
                ...prevState,
                dialogContent: updatedDialogContent,
              };
            });
          }
          setUserDLD(user);
        }
      } catch (error) {
        console.error('Error request:', error);
      }
    };
    fetchRes();
  }, [newAutocompleteValue]);

  useEffect(() => {
    kind !== 'manager' && resetSignUpForm(userDLD);
  }, [userDLD]);

  const updateFieldVisibility = (fieldName, visibility) => {
    setDialogData((prevState) => {
      const updatedDialogContent = prevState.dialogContent.map((content) => {
        if (content.name === fieldName) {
          return {
            ...content,
            initialVisibility: visibility,
          };
        }
        return content;
      });

      return {
        ...prevState,
        dialogContent: updatedDialogContent,
      };
    });
  };

  const switchUserType = (kind, param) => {
    let result;
    switch (kind) {
      case 'broker':
        result = stateLicenseProvider.searchBroker({ query: param });
        break;
      case 'agency':
        result = stateLicenseProvider.searchAgency({ query: param });
        break;
      case 'developer':
        result = stateLicenseProvider.searchDeveloper({ query: param });
        break;
      case 'manager':
        result = stateLicenseProvider.searchAgency({ query: param });
        break;
      case 'foreign':
        break;
      default:
        console.log(`Unknown action: ${kind}`);
    }
    return result;
  };

  const selectUserType = dialogSignUpData.dialogContent.find(
    (el) => el.name === 'selectUserType',
  )?.placeholder;
  const selectUserTypeName =
    dialogUserTypeData.dialogContent[0].radioGroup?.find(
      (el) => el.label === valueUserType,
    )?.name;

  const updateUserType = (value) => {
    const updatedDialogContent = dialogData.dialogContent.map((content) => {
      if (content.name === 'selectUserType') {
        return {
          ...content,
          placeholder: value || content.placeholder,
        };
      }
      return content;
    });
    setSelectedType(value);
    setDialogData((prevData) => ({
      ...prevData,
      dialogContent: updatedDialogContent,
    }));
  };

  const handleOnChangeAutocomplete = async (param) => {
    if (param) {
      const { ok, data, status, message } = await switchUserType(kind, param);
      const optionsBrokerAndAgency = [
        ...new Set(
          data?.items
            ?.flatMap((el) => [
              el.stateId?.includes(param) ? el.stateId : null,
              el.nameEn?.toLowerCase().includes(param.toLowerCase())
                ? el.nameEn
                : null,
              el.phone?.includes(param) ? el.phone : null,
              el.email?.toLowerCase().includes(param.toLowerCase())
                ? el.email
                : null,
            ])
            .filter(Boolean),
        ),
      ];

      const optionsManager = [
        ...new Set(
          data?.items
            ?.flatMap((el) => [
              el.nameEn?.toLowerCase().includes(param.toLowerCase())
                ? el.nameEn
                : null,
            ])
            .filter(Boolean),
        ),
      ];

      setDialogData((prevState) => {
        const updatedDialogContent = prevState.dialogContent.map((content) => {
          if (content.name === 'profileVerificationOptions') {
            return {
              ...content,
              options: optionsBrokerAndAgency,
            };
          }
          if (kind === 'manager' && content.name === 'company') {
            return {
              ...content,
              options: optionsManager,
            };
          }
          return content;
        });

        return {
          ...prevState,
          dialogContent: updatedDialogContent,
        };
      });
    }
  };

  const handleInputChange = (value, name) => {
    let newValue = value.replace(/\s/g, '');
    const isNumber = /^\d{3,}$/.test(newValue);
    const isContainsLetter = /[a-zA-Z]/.test(newValue);
    setDialogData((prevState) => {
      const newState = { ...prevState };
      if (name === 'emailOrPhone') {
        newState.dialogContent.find(
          (item) => item.name === 'emailOrPhone',
        ).initialVisibility = !isNumber;
        newState.dialogContent.find(
          (item) => item.name === 'phoneNumber',
        ).initialVisibility = isNumber;
        setEmailOrPhoneInput(isNumber ? '+971' + newValue : newValue);
      } else if (name === 'phoneNumber') {
        if (!isNumber || isContainsLetter) {
          newState.dialogContent.find(
            (item) => item.name === 'emailOrPhone',
          ).initialVisibility = true;
          newState.dialogContent.find(
            (item) => item.name === 'phoneNumber',
          ).initialVisibility = false;
          setEmailOrPhoneInput(newValue);
        }
      }
      return newState;
    });
  };

  const initialVisibilityErrorText = (visibility) => {
    const errorTextIndex = userTypeDialogData.dialogContent.findIndex(
      (content) => content.name === 'errorText',
    );
    if (errorTextIndex !== -1) {
      const updatedDialogContent = [...userTypeDialogData.dialogContent];
      updatedDialogContent[errorTextIndex].initialVisibility = visibility;
      setUserTypeDialogData((prevData) => ({
        ...prevData,
        dialogContent: updatedDialogContent,
      }));
    }
  };

  const onSubmitSelectUserType = () => {
    if (valueUserType.length === 0) {
      initialVisibilityErrorText(true);
    } else {
      updateUserType(valueUserType);
      handleOpenDialog('selectUserType');
      initialVisibilityErrorText(false);
    }
  };

  const closeUserTypeDialog = () => {
    initialVisibilityErrorText(false);
    handleOpenDialog('selectUserType');
  };

  const onSubmitSignUp = async (data) => {
    if (Object.keys(errorsSignUp).length) {
      console.log('Validation errors:', errorsSignUp);
      return;
    }
    const isCheckedEmail = dialogData?.dialogContent?.find(
      (item) => item.name === 'emailOrPhone',
    )?.initialVisibility;
    const phoneOrEmail =
      (kind === 'manager' || kind === 'foreign')
        ? isCheckedEmail
          ? data?.emailOrPhone
          : data?.phoneNumber
        : data?.emailOrPhone || data?.email || data?.phoneNumber || data.managerEmailOrPhone || '' ;
    const links = kind === 'foreign' ? (data.other ? [data.social, data.other] : [data.social]) : [];
    const dataSubmit = {
      kind: kind || '',
      password: data?.password || '',
      registryId: userDLD?.id || null,
      name: data?.name || data?.companyName || '',
      phoneOrEmail: phoneOrEmail,
      city: data?.city || null,
      country: data?.country || null,
      personKind: valueFormOfOrganization || '',
      links: links || [],
    };
    setUserDataSubmit(dataSubmit);
    
    const handleSignupResponse = (response, isInitialSignup) => {
      const { ok, message, status } = response;
      if (ok) {
        closeSignUpDialog();
        if (!isInitialSignup) {
          handleOpenDialog('confirmation');
          navigate(inviteLinkCode ? `/invitelink/${inviteLinkCode}` : '/');
        }
      } else {
        dispatch(appSetNotification('error', message, status));
        setErrorStatus(status);
        setUserChecked(true);
      }
    };
    
    if (dataSubmit) {
      dispatch(appSetLoading(true));
      const response = isLoggedIn 
        ? await authProvider.signup(dataSubmit)
        : await authProvider.signupStart(dataSubmit);
      handleSignupResponse(response, isLoggedIn);
      dispatch(appSetLoading(false));
    }
  };

  const onSubmitOperatorForm = async (data) => {
    if (Object.keys(errorsOperator).length) {
      console.log('Validation errors:', errorsOperator);
      return;
    }
    const dataSubmit = {
      name: data.name || '',
      email: data.emailOrPhone || null,
      phone: data.phoneNumber || null,
      text: data.description || 'Help me',
    };
    if (dataSubmit) {
      closeOperatorForm();
      const { ok, message, status } = await supportProvider.support(dataSubmit);
      if (ok) {
        handleOpenDialog('operatorSuccess');
      } else {
        dispatch(appSetNotification('error', message, status));
      }
    }
  };

  const closeOperatorForm = () => {
    handleOpenDialog('operator');
    resetOperatorForm();
  };

  const closeSignUpDialog = () => {
    setEmailOrPhoneInput(null);
    setValueUserType('');
    setDialogData(dialogSignUpData);
    setUserChecked(false);
    setUserDLD(null);
    setNewAutocompleteValue('');
    setAutocompleteValue('');
    resetSignUpForm();
    updateUserType(selectUserType);
    handleOpenDialog('signUp');
    setDialogData((prevState) => {
      const newState = { ...prevState };
      if (kind === 'manager') {
        newState.dialogContent.find(
          (item) => item.name === 'emailOrPhone',
        ).initialVisibility = true;
        newState.dialogContent.find(
          (item) => item.name === 'phoneNumber',
        ).initialVisibility = false;
      }
      return newState;
    });
  };

  const handleCloseConfirm = () => {
    setEmailOrPhoneInput(null);
    resetSignUpForm();
    handleOpenDialog('confirmation');
  };

  return (
    <>
      <DialogContentRenderer
        open={openDialog.signUp}
        onClose={closeSignUpDialog}
        control={controlSignUp}
        dialogData={dialogData}
        onSubmit={handleSubmitSignUp(onSubmitSignUp)}
        errors={errorsSignUp}
        handleOpenDialog={handleOpenDialog}
        kind={kind}
        valueUserType={valueUserType}
        changeAutocompleteValue={setAutocompleteValue}
        changeNewAutocompleteValue={setNewAutocompleteValue}
        selectedSelect={setSelectedFormOfOrganization}
        onChange={handleInputChange}
        emailOrPhoneInput={emailOrPhoneInput}
        setLogInPhoneValue={setLogInPhoneValue}
        setValue={setValueSignUp}
      />
      <DialogContentRenderer
        open={openDialog.operator}
        onClose={closeOperatorForm}
        control={controlOperator}
        setValue={setValueOperatorForm}
        dialogData={dialogOperatorData}
        errors={errorsOperator}
        onSubmit={handleSubmitOperator(onSubmitOperatorForm)}
      />
      <DialogContentRenderer
        open={openDialog.operatorSuccess}
        onClose={() => handleOpenDialog('operatorSuccess')}
        dialogData={dialogOperatorSuccessData}
      />
      <SelectUserType
        open={openDialog.selectUserType}
        onClose={closeUserTypeDialog}
        dialogData={userTypeDialogData}
        value={valueUserType}
        changeValue={setValueUserType}
        onSubmit={() => onSubmitSelectUserType()}
      />
      <Confirmation
        open={openDialog.confirmation}
        onClose={handleCloseConfirm}
        handleOpenDialog={handleOpenDialog}
        dialogData={dialogConfirmationData}
        emailOrPhoneInput={emailOrPhoneInput}
        userDataSubmit={userDataSubmit}
        setUserChecked={setUserChecked}
        type='signup'
      />
      <Agreement
        open={openDialog.agreement}
        onClose={() => handleOpenDialog('agreement')}
      />
      <PrivacyPolicy
        open={openDialog.privacyPolicy}
        onClose={() => {
          handleOpenDialog('privacyPolicy');
        }}
      />
    </>
  );
};

export default SignUp;
