import Settings from '@/components/AccountManagement/Profile/Settings/Settings';
import { Dashboard } from '@/components';

export const UserSettings = () => {
  return (
    <Dashboard isChecked={true} openLogIn>
      <Settings />
    </Dashboard>
  );
};
