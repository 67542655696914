/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  PaginationType,
  AdsSortingType,
  SortingOrderEnum,
  OffPlanPropertyKind,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  RequestMyModel,
  SortingFieldsType,
  NewBuildingsButtonStatusEnum,
  State,
  UserKindEnum,
  NewBuildingsSortingFieldsEnum,
  NewBuildingsFilteringType,
  DevelopmentStatusEnum,
  FeaturesType,
  NotificationTypeEnum,
  NewBuildingsResponseType,
  INewBuildingsModel,
  AdFieldStatusEnum,
  IDeveloperModel,
  UserModel,
} from '@/types';
import { setAddressParamsInFilteringObj } from '@/utils';
import { newBuildingsProvider, usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination, EmptyList, NewBuildingListItem } from '@/components';

import { COUNT_ADS_PER_PAGE } from './constants';
import { OffPlanFilters } from './OffPlanFilters';
import { OffPlanControls } from './OffPlanControls';
import { DeveloperItem } from './DeveloperItem';

import styles from './OffPlan.module.scss';

interface DataItem {
  unreadCount?: number;
}

export const OffPlan = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);
  const changeQueryParams = useChangeQueryParams('offplans');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [isLoadingGetNewBuilding, setIsLoadingGetNewBuilding] = useState<boolean>(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(false);

  const [newBuilding, setNewBuilding] = useState<INewBuildingsModel[]>();
  const [developers, setDevelopers] = useState<UserModel[]>();

  const [filteringObj, setFilteringObj] = useState<NewBuildingsFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const [_countRequests, setCountRequests] = useState(0);

  const isForeign = user.kind === UserKindEnum.Foreign;

  const [totalNewBuilds, setTotalNewBuilds] = useState(0);

  const queryParam = new URLSearchParams(window.location.search);
  const btnStatus = queryParam.get('btnStatus');
  const statusBtn = btnStatus
    ? btnStatus === NewBuildingsButtonStatusEnum.OffPlan
      ? true
      : false
    : true;

  const sortingFields: SortingFieldsType = [
    { label: 'Update date', value: NewBuildingsSortingFieldsEnum.Date },
    { label: 'Unit price', value: NewBuildingsSortingFieldsEnum.PriceFrom },
  ];

  const getOffPlans = async () => {
    const address =
      queryParams.addressParamLat && queryParams.addressParamLong
        ? [
            {
              addressEn: queryParams.addressParamAddress as string,
              coordinatesLat: queryParams.addressParamLat as string,
              coordinatesLong: queryParams.addressParamLong as string,
              nameEn: queryParams.addressParamName as string,
              placeId: queryParams.addressParamPlaceId as string,
            },
          ]
        : [];

    const features =
      queryParams.cityView ||
      queryParams.landmarkView ||
      queryParams.parkView ||
      queryParams.waterView ||
      queryParams.privateGarden ||
      queryParams.privateGym ||
      queryParams.privatePool ||
      queryParams.privateSpa ||
      queryParams.sharedGym ||
      queryParams.sharedPool ||
      queryParams.sharedSp ||
      queryParams.balcony ||
      queryParams.barbecueArea ||
      queryParams.childrenPlayArea ||
      queryParams.coveredParking ||
      queryParams.lobbyInBuilding ||
      queryParams.maidsRoom ||
      queryParams.publicParking ||
      queryParams.tennisCourts ||
      queryParams.study ||
      queryParams.kitchenAppliances ||
      queryParams.buildInWardrobes ||
      queryParams.centralAC ||
      queryParams.concierge ||
      queryParams.laundryFacility ||
      queryParams.maidService ||
      queryParams.security
        ? {
            cityView:
              queryParams.cityView !== undefined ? queryParams.cityView === 'true' : undefined,
            landmarkView:
              queryParams.landmarkView !== undefined
                ? queryParams.landmarkView === 'true'
                : undefined,
            parkView:
              queryParams.parkView !== undefined ? queryParams.parkView === 'true' : undefined,
            waterView:
              queryParams.waterView !== undefined ? queryParams.waterView === 'true' : undefined,
            privateGarden:
              queryParams.privateGarden !== undefined
                ? queryParams.privateGarden === 'true'
                : undefined,
            privateGym:
              queryParams.privateGym !== undefined ? queryParams.privateGym === 'true' : undefined,
            privatePool:
              queryParams.privatePool !== undefined
                ? queryParams.privatePool === 'true'
                : undefined,
            privateSpa:
              queryParams.privateSpa !== undefined ? queryParams.privateSpa === 'true' : undefined,
            sharedGym:
              queryParams.sharedGym !== undefined ? queryParams.sharedGym === 'true' : undefined,
            sharedPool:
              queryParams.sharedPool !== undefined ? queryParams.sharedPool === 'true' : undefined,
            sharedSpa:
              queryParams.sharedSpa !== undefined ? queryParams.sharedSpa === 'true' : undefined,
            balcony: queryParams.balcony !== undefined ? queryParams.balcony === 'true' : undefined,
            barbecueArea:
              queryParams.barbecueArea !== undefined
                ? queryParams.barbecueArea === 'true'
                : undefined,
            childrenPlayArea:
              queryParams.childrenPlayArea !== undefined
                ? queryParams.childrenPlayArea === 'true'
                : undefined,
            coveredParking:
              queryParams.coveredParking !== undefined
                ? queryParams.coveredParking === 'true'
                : undefined,
            lobbyInBuilding:
              queryParams.lobbyInBuilding !== undefined
                ? queryParams.lobbyInBuilding === 'true'
                : undefined,
            maidsRoom:
              queryParams.maidsRoom !== undefined ? queryParams.maidsRoom === 'true' : undefined,
            publicParking:
              queryParams.publicParking !== undefined
                ? queryParams.publicParking === 'true'
                : undefined,
            tennisCourts:
              queryParams.tennisCourts !== undefined
                ? queryParams.tennisCourts === 'true'
                : undefined,
            study: queryParams.study !== undefined ? queryParams.study === 'true' : undefined,
            kitchenAppliances:
              queryParams.kitchenAppliances !== undefined
                ? queryParams.kitchenAppliances === 'true'
                : undefined,
            buildInWardrobes:
              queryParams.buildInWardrobes !== undefined
                ? queryParams.buildInWardrobes === 'true'
                : undefined,
            centralAC:
              queryParams.centralAC !== undefined ? queryParams.centralAC === 'true' : undefined,
            concierge:
              queryParams.concierge !== undefined ? queryParams.concierge === 'true' : undefined,
            laundryFacility:
              queryParams.laundryFacility !== undefined
                ? queryParams.laundryFacility === 'true'
                : undefined,
            maidService:
              queryParams.maidService !== undefined
                ? queryParams.maidService === 'true'
                : undefined,
            security:
              queryParams.security !== undefined ? queryParams.security === 'true' : undefined,
          }
        : undefined;

    const newFilteringObj: NewBuildingsFilteringType = {
      address,
      features,
      status: AdFieldStatusEnum.Published,
      constructionType: queryParams.constructionType as OffPlanPropertyKind | undefined,
      priceMin: parseInt(queryParams.priceMin as string) || undefined,
      priceMax: parseInt(queryParams.priceMax as string) || undefined,
      beds: queryParams.beds as AdFieldBedsEnum,
      buildUpAreaFrom: parseInt(queryParams.buildUpAreaFrom as string) || undefined,
      buildUpAreaTo: parseInt(queryParams.buildUpAreaTo as string) || undefined,
      developmentStatus: queryParams.developmentStatus as DevelopmentStatusEnum,
      numberOfFloors: parseInt(queryParams.numberOfFloors as string) || undefined,
      // features: (queryParams.features as FeaturesType) || undefined,
      handover: queryParams.handover as string | undefined,
      availableUnits:
        queryParams.availableUnits !== undefined
          ? queryParams.availableUnits === 'true'
          : undefined,
      offPlanStatus: queryParams.offPlanStatus as string | undefined,
      quarters: queryParams.quarters as string | undefined,
      years: queryParams.years as string | undefined,
      paymentPlan:
        queryParams.paymentPlan !== undefined ? queryParams.paymentPlan === 'true' : false,
      amenitiesFeatures: (queryParams.amenitiesFeatures as string) || undefined,
      showFilters:
        queryParams.showFilters !== undefined ? queryParams.showFilters === 'true' : undefined,
      btnStatus: queryParams.status as NewBuildingsButtonStatusEnum,
    };

    const newSortingObj = {
      field:
        sortingFields.find((sort) => sort.label === (queryParams.field as string))?.value ||
        sortingFields[0].value,
      order: (queryParams.order as SortingOrderEnum) || SortingOrderEnum.Desc,
    };
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };

    setIsLoadingGetNewBuilding(true);
    const filterObj = filteringObj ? filteringObj : newFilteringObj;
    const { ok, data, status, message } = await newBuildingsProvider.getOffPlans(
      filterObj,
      newSortingObj,
      newPaginationObj
    );
    if (ok) {
      const { items, total } = data as NewBuildingsResponseType;
      setNewBuilding(items);
      setFilteringObj({ ...filterObj });
      setSortingObj({ ...newSortingObj });
      setTotalNewBuilds(total);
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      setNewBuilding([]);
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetNewBuilding(false);
  };

  const getUserData = async (id: string) => {
    setIsLoadingUserData(true);
    if (id) {
      const { ok, data, status, message } = await usersProvider.getUserById(id);
      if (ok && data) {
        setDevelopers([data]);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    }
    setIsLoadingUserData(false);
  };

  useEffect(() => {
    getOffPlans();
    const fakeDeveloperId = '82';
    getUserData(fakeDeveloperId);
  }, [queryParamsStr]);

  useEffect(() => {
    if (statusBtn) {
      getOffPlans();
    } else {
      const fakeDeveloperId = '82';
      getUserData(fakeDeveloperId);
    }
  }, [statusBtn]);

  const handleChangeFilter = (
    newFilteringObj: NewBuildingsFilteringType,
    shouldSendRequest?: boolean
  ) => {
    if (!newFilteringObj.constructionType?.length) {
      newFilteringObj.constructionType = undefined;
    }
    setFilteringObj(newFilteringObj);
    if (shouldSendRequest) {
      changeQueryParams({
        ...queryParams,
        ...setAddressParamsInFilteringObj(newFilteringObj),
        page: 1,
      });
    }
    // console.log(newFilteringObj, shouldSendRequest);
  };

  const handleChangeSort = (newSortingObj: AdsSortingType) =>
    changeQueryParams({
      ...queryParams,
      ...newSortingObj,
    });

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleReqForMeBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      btnStatus: NewBuildingsButtonStatusEnum.OffPlan,
      page: 1,
    });
  };

  const handleMyReqBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      btnStatus: NewBuildingsButtonStatusEnum.Developers,
      page: 1,
    });
  };

  if (isLoadingGetNewBuilding || isLoadingUserData) {
    return <Loader />;
  }

  return (
    <div className={styles.adsContainer}>
      {filteringObj && (
        <OffPlanFilters
          filteringObj={filteringObj}
          onChangeFilters={handleChangeFilter}
          handleReqForMeBtnClick={handleReqForMeBtnClick}
          handleMyReqBtnClick={handleMyReqBtnClick}
          btnOffPlan={statusBtn}
          isForeign={isForeign}
        />
      )}
      <div className={styles.templateOffPlanContainer}>
        {statusBtn ? (
          <Typography sx={{ fontSize: '28px', fontWeight: '500' }}>
            Total
            <span style={{ fontWeight: 800, color: '#1650FF' }}> {totalNewBuilds} </span>
            projects
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '28px', fontWeight: '500' }}>
            Property developers
            <span style={{ fontWeight: 800, color: '#1650FF' }}> {`1`}</span>
          </Typography>
        )}
        {statusBtn && sortingObj && (
          <OffPlanControls
            sortingFields={sortingFields}
            sortingObj={sortingObj}
            onChangeSort={handleChangeSort}
          />
        )}
      </div>
      {!newBuilding?.length ? (
        <EmptyList />
      ) : statusBtn ? (
        <div className={styles.adsList}>
          {(newBuilding as INewBuildingsModel[]).map((item) => (
            <NewBuildingListItem key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <div className={styles.developersList}>
          {(developers as UserModel[]).map((developer) => (
            <DeveloperItem key={developer.id} developer={developer} />
          ))}
        </div>
      )}
      {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE && (
        <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
      )}
    </div>
  );
};
