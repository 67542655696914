import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, MenuItem, FormControl, ListItemText, Checkbox, Chip } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import styles from './FormMultiSelect.module.scss';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 344,
      width: 250,
    },
  },
};

export const FormMultiSelect = ({
  name,
  data,
  onChange,
}: {
  name: string;
  data: string[];
  onChange?: (value: string[]) => void;
}) => {
  const [personName, setPersonName] = useState<string[]>([]);

  const { getValues, setValue } = useFormContext();
  const values = getValues();
  const value = values[name];

  useEffect(() => {
    if (value) {
      setPersonName(value);
    }
  }, [value]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setPersonName(newValue);
    // onChange(newValue);
    setValue(name, newValue);
  };

  const handleDelete = (nameToDelete: string) => () => {
    const newValue = personName.filter((name) => name !== nameToDelete);
    setPersonName(newValue);
    // onChange(newValue);
    setValue(name, newValue);
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <Select
          multiple
          sx={{
            borderRadius: '14px',
            border: '1px solid #E9EEF1',
          }}
          className={styles.fieldset}
          IconComponent={(_props) => {
            const rotate = _props.className.toString().includes('iconOpen');
            return (
              <div
                className={styles.caretDown}
                style={{
                  transform: rotate ? 'rotate(180deg)' : 'none',
                }}
              >
                <CaretDown />
              </div>
            );
          }}
          value={personName}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                marginTop: '-4px',
              }}
            >
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  clickable
                  onDelete={handleDelete(value)}
                  deleteIcon={<span className={styles.closeIcon} />}
                  onMouseDown={(e) => e.stopPropagation()}
                  className={styles.label}
                  sx={{
                    marginRight: '32px',
                    borderRadius: '2px',
                    background: '#F1F7FF',
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {data.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox
                checkedIcon={<span className={styles.checkedIcon} />}
                icon={<span />}
                checked={personName.indexOf(name) > -1}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
