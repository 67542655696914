import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography, TextField, Divider, Checkbox } from '@mui/material';
import { styled } from '@mui/system';

import { NotificationTypeEnum, State } from '@/types';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { SectionHeader, SectionTitle, MyButton, Loader } from '@/components';

import { InvitationLink } from './InvitationLink';
import { EmptyList } from './EmptyList';
import { MemberItem, MyInfo } from './MemberItem';

import { ReactComponent as IconArrowRight } from './iconArrowRight.svg';
import { ReactComponent as RateIcon } from './rate.svg';
import { ReactComponent as UserIcon } from './user.svg';
import styles from './Referrals.module.scss';

const StyledSquareTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    width: '64px',
    height: '64px',
    backgroundColor: '#FFFFFF',
    borderColor: '#E9EEF1',
  },
  '& .MuiInputBase-input': {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '24px',
    fontWeight: '800',
    textAlign: 'center',
  },
});

interface MemberModel {
  id: string;
  name: string;
  avatar: string;
  isRegistration: boolean;
  isPayment: boolean;
}

export const Referrals = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);

  const [referralsLink, setReferralsLink] = useState<string>();
  const [inviteUsers, setInviteUsers] = useState<MemberModel[]>();
  const [leads, setLeads] = useState<number>(0);
  const [conversions, setConversions] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fields = ['2', 'U', 'A', 'V', 'G', 'N'];
  const coast = 15;
  const inviteCoast = conversions * coast;

  const getListReferrals = async (id: string) => {
    const { ok, data, status, message } = await usersProvider.getListReferrals(id);
    if (ok) {
      const usersData =
        data.data.length > 0 &&
        data.data.map((el: any) => {
          return {
            id: el.id,
            name: el.name,
            isRegistration: true,
            isPayment: el.conversion_state === 'conversion' ? true : false,
            avatar: el.media[0] ? el.media[0].fileUrl : undefined,
          };
        });
      setInviteUsers(usersData);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const getReferralsLink = async () => {
    const { ok, data, status, message } = await usersProvider.getReferralsLink();
    if (ok) {
      console.log(data.links[0].url);
      setReferralsLink(data.links[0].url);
      setLeads(data.leads || 0);
      setConversions(data.conversions || 0);
      const affiliateId = data.id;
      getListReferrals(affiliateId);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      getReferralsLink();
      setIsLoading(false);
    };
    loadData();
  }, []);

  return !referralsLink || isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <Box className={styles.title}>
        <span className={styles.text}>Referral program</span>
      </Box>
      <SectionTitle number={1} title='Referral link' />
      {referralsLink && <InvitationLink linkValue={referralsLink} />}
      <Stack direction='column' className={styles.rateContainer} spacing={2}>
        <Stack className={styles.rate} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#1650FF' }}>
            Exchange rate:
          </Typography>
          <Stack direction='row' spacing={2}>
            <Stack sx={{ borderRadius: '8px', border: '1px solid #C5DFFF', padding: '5px 13px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
                {inviteCoast}$ / 100$
              </Typography>
            </Stack>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                borderRadius: '8px',
                backgroundColor: '#C5DFFF',
                border: '1px solid #C5DFFF',
                padding: '5px 13px',
              }}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>1</Typography>
              <Stack direction='row' spacing={1} alignItems='center'>
                <UserIcon />
                <IconArrowRight />
              </Stack>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>15$</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Stack>
            <RateIcon />
          </Stack>
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            When you reach <strong style={{ color: '#1650FF' }}>$100,</strong> a promo code will
            become available, and the money can be cashed out
          </Typography>
        </Stack>
      </Stack>
      {inviteCoast > 100 && (
        <Stack direction={{ xs: 'column', sm: 'row' }} className={styles.codeContainer} spacing={2}>
          <Stack direction={{ xs: 'row', sm: 'column' }}>
            <Typography sx={{ fontSize: '28px', fontWeight: 600, color: '#1650FF' }}>
              Use promo code
            </Typography>
            <Typography sx={{ fontSize: '28px', fontWeight: 600, color: '#1650FF' }}>
              to cash out
            </Typography>
          </Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            {fields.map((fieldName, index) => (
              <Stack key={index} justifyContent='center'>
                <StyledSquareTextField variant='outlined' fullWidth value={fieldName} />
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}

      <SectionTitle number={2} title='My invites' />
      <Stack className={styles.inviteContainer} spacing={2}>
        <MyInfo user={user} registrationUserCount={leads} paymentUserCount={conversions} />
      </Stack>

      {!inviteUsers?.length ? (
        <EmptyList description='You currently have no referrals. Invite your friends and earn rewards!' />
      ) : (
        <div className={styles.list}>
          {inviteUsers.map((item) => (
            <MemberItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};
