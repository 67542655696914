import { useState, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Divider,
  Stack,
  Typography,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import { MyDialog, MyButton, Loader } from '@/components';
import { usersProvider } from '@/providers';
import { CommunicationMethod, NotificationTypeEnum } from '@/types';
import { appSetNotification } from '@/store';
import styles from './Settings.module.scss';
import { StyleLabel } from '../../../uiComponents/styled';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const CommunicationMethodDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>('');
  const [contact, setContact] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  const formMethods = useForm<CommunicationMethod>();
  const {handleSubmit} = formMethods;

  const onHandleClose = () => {
    onClose();
    setError(false);
  };

  const submitHandler: SubmitHandler<CommunicationMethod> = async (values) => {
    if(!contact) {
      return setError(true);
    }
    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.saveContactMethod(contact);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
    onHandleClose();
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
    setError(false);
  };

  const radioValues = ['Email', 'Telegram', 'Whatsapp', 'Phone'];

  if (isLoading) return <Loader />;

  return (
    <MyDialog
      open={open}
      dialogTitle='Choose your preferred communication method'
      width='725'
    >
      <StyleLabel style={{ textAlign: 'center' }}>
        <span style={{ color: error ? 'red' : '#2A3842' }}>
          Please indicate your preferred methods of contacting you.
        </span>
        <br /> 
        Users of the platform will see and understand that you want to conduct a 
        dialogue preferably in these channels.
      </StyleLabel>
      <FormProvider {...formMethods}>
        <Stack spacing={3} sx={{ width: '100%', margin: '30px 0' }}>
          <RadioGroup value={radioValue} onChange={handleChangeRadio}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', sm: 'row' }}
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: { xs: 'flex-start', sm: 'center' },
                marginLeft: { xs: '30px', sm: 0 },
              }}
            >
              {radioValues?.map((radio) => (
                <FormControlLabel
                  key={radio}
                  value={radio}
                  control={
                    <Radio
                      sx={{
                        padding: '0',
                        alignSelf: 'flex-start',
                        color: '#2A384280',
                        '&.Mui-checked': {
                          color: '#1650FF',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{radio}</Typography>
                  }
                  sx={{ justifyContent: 'center' }}
                  onClick={() => setContact(radio)}
                />
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
        {/* <PropertyLocation /> */}
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Save',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={handleSubmit(submitHandler)}
          />
        </div>
      </FormProvider>
    </MyDialog>
  );
};
