import { apiClient } from '@/providers';
import {
  LocalizationRequestType,
  LocalizationPatchRequestType,
  LocalizationPostRequestType,
} from '@/types/requests';
import { LocalizationResponseType } from '@/components/Admin/Localizations/types/types';
import { CreateLocalizationResponseType } from '@/types/responses';

export const localizationsProvider = {
  getLocalizations: async (
    language: string,
    namespace: string,
    key: string,
    limit: number,
    offset: number
  ): Promise<LocalizationResponseType> => {
    const params: LocalizationRequestType = {
      limit,
      offset,
    };
    if (language) {
      params.language = language;
    }
    if (namespace) {
      params.namespace = namespace;
    }
    if (key) {
      params.key = key;
    }
    const response = await apiClient(`/admin/localizations`, 'POST', params);
    if (!response.ok || !response.data) {
      return { ok: false, data: { items: [], total: 0 } };
    }

    return { ok: true, data: response.data } as LocalizationResponseType;
  },

  updateLocalizations: async (id: number, params: LocalizationPatchRequestType) => {
    const response = await apiClient(`/admin/localization/${id}`, 'PATCH', params);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  createLocalizations: async (params: LocalizationPostRequestType): Promise<number> => {
    const response = await apiClient(`/admin/localization`, 'POST', params);
    // if (!response.ok) {
    //   return { id: undefined };
    // }

    return response.data as CreateLocalizationResponseType;
  },

  deleteLocalizations: async (id: number) => {
    const response = await apiClient(`/admin/localization/${id}`, 'DELETE');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },
};
