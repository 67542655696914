import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import { Loader, LoaderOverlay } from '@/components';
import { FileKindEnum, State, NewBuildingModel } from '@/types';
import { useSelector } from 'react-redux';
import { GeneratePDF } from './PdfDocument/OffplanGeneratePDF';
import { pdf } from '@react-pdf/renderer';
import { useUserData } from '@/components/PdfManager/useUserData';
import { IUnit } from '@/types/offplans';
import { OffplanPdfSettings } from './OffplanPdfSettings';
import { NewBuildingsMediaType } from '@/types/newBuildings';

type IPdfTemplateImagesRules = {
  name: string;
  minHeaderPicsCount: number;
  bestHeaderPicsCount: number;
};

const pdfTemplateImagesRules: IPdfTemplateImagesRules[] = [
  { name: 'classic-view', minHeaderPicsCount: 3, bestHeaderPicsCount: 5 },
  { name: 'vertical-view', minHeaderPicsCount: 3, bestHeaderPicsCount: 5 },
  {
    name: 'all-in-one-view',
    minHeaderPicsCount: 3,
    bestHeaderPicsCount: 3,
  },
  { name: 'lux-view', minHeaderPicsCount: 3, bestHeaderPicsCount: 3 },
  { name: 'one-page', minHeaderPicsCount: 3, bestHeaderPicsCount: 3 },
  { name: 'portable-view', minHeaderPicsCount: 1, bestHeaderPicsCount: 3 },
];

const selectHeaderImages = (
  minHeaderPicsCount: number,
  bestHeaderPicsCount: number,
  images: NewBuildingsMediaType[],
  selectedImages: NewBuildingsMediaType[]
): NewBuildingsMediaType[] => {
  let newSelectedImages = [...selectedImages];
  if (newSelectedImages.length < minHeaderPicsCount) {
    const imagesToAddCount =
      images.length >= bestHeaderPicsCount
        ? bestHeaderPicsCount - newSelectedImages.length
        : minHeaderPicsCount - newSelectedImages.length;

    const filteredImages = images.filter(
      (image) => !newSelectedImages.some((selectedImage) => selectedImage.id === image.id)
    );

    newSelectedImages.push(...filteredImages.slice(0, imagesToAddCount));
  }

  return newSelectedImages;
};

export const OffplanPdfManager = ({
  offplanId,
  units,
  totalUnits = 0,
  plans,
  totalPlans = 0,
  offplanData,
}: {
  offplanId: string;
  units: IUnit[];
  totalUnits: number;
  plans: any[];
  totalPlans: number;
  offplanData?: NewBuildingModel;
}) => {
  const user = useSelector(({ auth }: State) => auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showMyContactsCheckbox, setShowMyContactsCheckbox] = useState(true);

  const { userData: currentAgencyInfo } = useUserData(user.employer?.id);

  const [isViewPdfVisible, setIsViewPdfVisible] = useState(false)
  const [pdfFile, setPdfFile] = useState<Blob | null>(null)
  const images = useMemo(() => {
    if(offplanData){
    return offplanData.media?.filter((item) => item.mediaType === FileKindEnum.Image) || [];
    }
    return []
  }, [offplanData?.media]);



  if (!offplanData || !images.length) return <Loader />;
  return (
    <div style={{ width: '100%' }}>
      {isLoading && <LoaderOverlay />}
      <OffplanPdfSettings
        showMyContactsCheckbox={showMyContactsCheckbox}
        images={images}
        pdfFile={pdfFile}
        isViewPdfVisible={isViewPdfVisible}
        hideViewPdf={() => setIsViewPdfVisible(false)}
        onSubmit={async (data) => {
          setIsLoading(true);
          const selectedImages = images.filter((item) => data[`image-${item.id}`]);

          const templateName = data.radioOption.toString();
          let templateRule = pdfTemplateImagesRules.filter((el) => el.name === templateName)[0];

          if (!templateRule) {
            templateRule = {
              name: 'stub',
              minHeaderPicsCount: 3,
              bestHeaderPicsCount: 3,
            };
            return;
          }

          const { minHeaderPicsCount, bestHeaderPicsCount } = templateRule;
          const tImages = selectHeaderImages(
            minHeaderPicsCount,
            bestHeaderPicsCount,
            images,
            selectedImages
          );

          const blob = await pdf(
            <GeneratePDF
              templateName={templateName}
              offplanData={offplanData}
              personalInfo={user}
              units={units}
              currentAgencyInfo={currentAgencyInfo}
              showContent={{
                images: tImages,
                isAgencyInformationVisible: data.agencyDetailsShow,
                isBrokersInformationVisible: showMyContactsCheckbox
                  ? data.brokerDetailsShow
                  : data.agencyDetailsShow,
                showPrice: data.showPrice,
              }}
            />
          ).toBlob();
          const res = await documentSave(blob, `${offplanId}`, setPdfFile);
          setIsViewPdfVisible(true)
          if (res) setIsLoading(false);
        }}
      />
    </div>
  );
};

export async function documentSave(blob: Blob, documentName: string, setPdfFile: Dispatch<SetStateAction<Blob | null>>): Promise<string> {
  try {
    const objectUrl = URL.createObjectURL(blob);
    setPdfFile(blob)
    const fileExtension = blob.type.split('/')[1];

    const fileName = `${documentName}.${fileExtension}`
      .replace(/\s/g, '_')
      .replace(/[^a-zA-Z0-9_.]/g, '');

    const link = window.document.createElement('a');
    link.href = objectUrl;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();
    link.remove();

    URL.revokeObjectURL(objectUrl);

    return 'ok';
  } catch (error) {
    console.error('Error in documentSave:', error);
    return 'error';
  }
}

export type OffplanSelectedDataType = {
  images?: NewBuildingsMediaType[];
  isAgencyInformationVisible?: boolean;
  isBrokersInformationVisible?: boolean;
  showPrice?: boolean;
};
