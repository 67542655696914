import { FC, useState, ChangeEvent } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Stack,
  Container,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUserSettings } from '@/hooks/useUserSettings';

import {
  predefinedVars,
  getLabelsAndTexts,
  getMoreFiltersControlsVisibility,
} from '../AdsFilters.init';

import { styled, shouldForwardProp } from '@mui/system';
import classNames from 'classnames';

import {
  AdFieldStatusEnum,
  AdsFilteringType,
  AdsSortingType,
  AdsViewModeEnum,
  SortingOrderEnum,
  RentPeriodEnum,
  TransactionTypeEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  AdFieldFurnitureEnum,
  AdFieldRenovationEnum,
  AdFieldFloorEnum,
  AdFieldOccupancyEnum,
} from '@/types';
import { MyButton, LocationAutocomplete, Loader } from '@/components';
import { LocationModel } from '@/types/location';
import { FiltersBlock } from './FiltersBlock';

import { icons } from './Icons';
import { isScrollable } from '@dnd-kit/core/dist/utilities/scroll';

interface StyledDialogProps {
  width?: string;
}

interface IMyDialogTitleProps {
  dataQa?: string;
  dialogTitle: string;
  onClose?: () => void;
}

interface FilterState {
  locationId: string[];
  propertyKind: PropertyKindEnum[];
  transactionType: TransactionTypeEnum[];
  rentFrequency: RentPeriodEnum | undefined;
  completionStatus: CompletionStatusEnum[];
  beds: AdFieldBedsEnum[];
  baths: AdFieldBathsEnum[];
  furniture: AdFieldFurnitureEnum[];
  renovation: AdFieldRenovationEnum[];
  floor: AdFieldFloorEnum[];
  occupancy: AdFieldOccupancyEnum[];
  postHandover: boolean | undefined;
  handover: HandoverKeys;
  price: PriceKeys;
  size: SizeKeys;
  downPayment: number | undefined;
  keywords: string | undefined;
  creator: CreatorType;
  isMyAgency: boolean | undefined;
  myFriends: boolean | undefined;
}
interface HandoverKeys {
  quarter: string | undefined;
  year: string | undefined;
}
interface PriceKeys {
  priceMin: string | undefined;
  priceMax: string | undefined;
}
interface SizeKeys {
  areaMin: string | undefined;
  areaMax: string | undefined;
}
interface CreatorType {
  id: string | undefined;
  name: string | undefined;
  kind: string | undefined;
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: StyledDialogProps) => ({
  '& .MuiDialog-paper': {
    width: width ? `${width}px` : '488px',
    maxWidth: '100%',
    borderRadius: '25px',
  },
}));

const StyleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  color: '#2A3842',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

const MyDialogTitle: FC<IMyDialogTitleProps> = ({ dialogTitle, onClose, dataQa }) => {
  return (
    <StyleDialogTitle data-qa={dataQa}>
      {dialogTitle}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyleDialogTitle>
  );
};

const boxStyle = {
  display: 'flex',
  gap: '20px',
  marginTop: '12px',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

export const MoreFiltersDialog = ({
  open,
  onClose,
  setOpenDialog,
  isRent,
  locations = [],
  inputAddressValue,
  setInputAddressValue,
  onSelect,
  handleClear,
  filterState,
  handleChangeTransactionType,
  handleChangeRentFrequency,
  handleChangeCompletionStatus,
  handleChangePropertyKind,
  handleChangeBeds,
  handleChangeBaths,
  handleChangeFurnished,
  handleChangeRenovation,
  handleChangeFloor,
  handleChangeOccupancy,
  handleChangePostHandover,
  handleChangeHandover,
  handleChangePrice,
  handleChangeSize,
  handleChangeDownPayment,
  handleChangeKeywords,
  handleChangeCreator,
  handleShowResult,
  handleResetFilters,
  handleChangeMyAgency,
  handleChangeMyFriend,
  isTariffFree,
}: {
  open: boolean;
  onClose: () => void;
  setOpenDialog: (value: boolean) => void;
  isRent?: boolean;
  locations: LocationModel[];
  inputAddressValue: string;
  setInputAddressValue: (value: string) => void;
  onSelect?: (value: LocationModel, isQueryParams?: boolean) => void;
  handleClear?: () => void;
  filterState: FilterState;
  handleChangeTransactionType: (value: TransactionTypeEnum, name?: string) => void;
  handleChangeRentFrequency: (value: RentPeriodEnum, name?: string) => void;
  handleChangeCompletionStatus: (value: CompletionStatusEnum, name?: string) => void;
  handleChangePropertyKind: (value: PropertyKindEnum, name?: string) => void;
  handleChangeBeds: (value: AdFieldBedsEnum, name?: string) => void;
  handleChangeBaths: (value: AdFieldBathsEnum, name?: string) => void;
  handleChangeFurnished: (value: AdFieldFurnitureEnum | string, name?: string) => void;
  handleChangeRenovation: (value: AdFieldRenovationEnum | string, name?: string) => void;
  handleChangeFloor: (valie: AdFieldFloorEnum | string, name?: string) => void;
  handleChangeOccupancy: (value: AdFieldOccupancyEnum, name?: string) => void;
  handleChangePostHandover: (value: boolean, name?: string) => void;
  handleChangeHandover: (value: string, name?: string) => void;
  handleChangePrice?: (
    e: ChangeEvent<HTMLInputElement>,
    name?: string,
    isQueryParams?: boolean
  ) => void;
  handleChangeSize: (
    e: ChangeEvent<HTMLInputElement>,
    name?: string,
    isQueryParams?: boolean
  ) => void;
  handleChangeDownPayment: (value: number) => void;
  handleChangeKeywords: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeCreator: (value: CreatorType, name?: string) => void;
  handleShowResult: () => void;
  handleResetFilters: () => void;
  handleChangeMyAgency: (value: boolean) => void;
  handleChangeMyFriend: (value: boolean) => void;
  isTariffFree: boolean;
}) => {
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);

  const rentSalesData = isRent
    ? predefinedVars.rentFrequencyValues
    : predefinedVars.transactionTypeValues;
  const rentSaleName = isRent ? 'rentFrequency' : 'transactionType';
  const rentSaleTitle = isRent ? 'Rent period' : 'Transaction type';
  const rentSaleDataQa = isRent ? 'rent_frequency' : 'transaction_type';

  const isDistress = filterState.transactionType.includes(TransactionTypeEnum.Distress);
  const isResale = filterState?.transactionType?.includes(TransactionTypeEnum.Resale);
  const isReady = filterState?.transactionType?.includes(TransactionTypeEnum.Ready);
  const isOffPlan = isDistress && filterState.completionStatus.includes(CompletionStatusEnum.Ready);

  const handoverVisible = isDistress || isResale;
  const quarterActive = filterState.handover.year ? false : true;

  const bedsAndBathsIsVisible =
    filterState?.propertyKind.length === 0 ||
    [
      PropertyKindEnum.Apartment,
      PropertyKindEnum.Villa,
      PropertyKindEnum.CommercialVilla,
      PropertyKindEnum.Townhouse,
      PropertyKindEnum.Penthouse,
      PropertyKindEnum.HotelApartment,
    ].includes(filterState?.propertyKind?.[0]);

  const furnishedVisible =
    filterState?.propertyKind.length === 0 ||
    ![
      PropertyKindEnum.Warehouse,
      PropertyKindEnum.ResidentialLand,
      PropertyKindEnum.IndustrialLand,
      PropertyKindEnum.CommercialVilla,
    ].includes(filterState?.propertyKind?.[0]);

  const floorVisible =
    filterState?.propertyKind.length === 0 ||
    [
      PropertyKindEnum.Apartment,
      PropertyKindEnum.HotelApartment,
      PropertyKindEnum.Floor,
      PropertyKindEnum.Office,
      PropertyKindEnum.Warehouse,
    ].includes(filterState?.propertyKind?.[0]);

  const renovationValid = isRent || isReady || (isDistress && isOffPlan);
  const renovationVisible =
    renovationValid &&
    (filterState?.propertyKind.length === 0 ||
      ![
        PropertyKindEnum.HotelApartment,
        PropertyKindEnum.Warehouse,
        PropertyKindEnum.ResidentialLand,
        PropertyKindEnum.IndustrialLand,
        PropertyKindEnum.CommercialLand,
        PropertyKindEnum.Shop,
      ].includes(filterState?.propertyKind?.[0]));

  const availabilityValid = isReady || (isDistress && isOffPlan);
  const availabilityVisible =
    availabilityValid &&
    (filterState?.propertyKind.length === 0 ||
      ![
        PropertyKindEnum.ResidentialLand,
        PropertyKindEnum.IndustrialLand,
        PropertyKindEnum.CommercialLand,
      ].includes(filterState?.propertyKind?.[0]));

  const { userSettings, isLoadingUserSettings } = useUserSettings();

  if (isLoadingUserSettings) {
    return <Loader />;
  }

  return (
    <StyledDialog open={open} width='900' data-qa={'more_filters_dialog'}>
      <MyDialogTitle onClose={onClose} dialogTitle='More filters' dataQa='more_filters_title' />

      <DialogContent
        sx={{
          overflowY: 'auto',
          textAlign: 'center',
          padding: '20px 30px',
        }}
        dividers
        data-qa={'more_filters_content'}
      >
        <Grid container alignItems='flex-start' justifyContent='space-between' spacing={3}>
          <Grid item xs={12}>
            <LocationAutocomplete
              setInputAddressValue={setInputAddressValue}
              locations={locations}
              inputAddressValue={inputAddressValue}
              handleClear={handleClear}
              onSelect={onSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.TransactionType />}
              name={rentSaleName}
              type='button'
              size='middle'
              title={rentSaleTitle}
              options={rentSalesData}
              activeButton={isRent ? filterState.rentFrequency : filterState.transactionType}
              onClick={isRent ? handleChangeRentFrequency : handleChangeTransactionType}
              dataQa={rentSaleDataQa}
            />
          </Grid>
          {isDistress && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.CompletionStatus />}
                name='completionStatus'
                type='button'
                size='middle'
                title='Completion status'
                options={predefinedVars.completionStatusValues}
                activeButton={filterState.completionStatus}
                onClick={handleChangeCompletionStatus}
                dataQa='completion_status'
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.PropertyType />}
              name='propertyKind'
              type='button'
              size='large'
              title='Property type'
              options={
                propertyTypeTab === 0
                  ? predefinedVars.propertyKindValuesResidentialTab
                  : predefinedVars.propertyKindValuesCommercialTab
              }
              activeButton={filterState.propertyKind}
              onClick={handleChangePropertyKind}
              dataQa='property_type'
              propertyTypeTab={propertyTypeTab}
              setPropertyTypeTab={setPropertyTypeTab}
            />
          </Grid>
          <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.Size />}
              name='size'
              secondName={['areaMin', 'areaMax']}
              type='numberInput'
              size='little'
              title={`Size (${userSettings?.areaUnits.toLowerCase()})`}
              activeButton={filterState.size}
              onChange={handleChangeSize}
              subTitle={[
                `Minimum size (${userSettings?.areaUnits.toLowerCase()})`,
                `Maximum size (${userSettings?.areaUnits.toLowerCase()})`,
              ]}
              dataQa='size'
            />
          </Grid>
          {bedsAndBathsIsVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Beds />}
                name='beds'
                type='button'
                size='little'
                title='Bedrooms'
                options={predefinedVars.bedsValues}
                activeButton={filterState.beds}
                onClick={handleChangeBeds}
                dataQa='beds'
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.Price />}
              name='price'
              secondName={['priceMin', 'priceMax']}
              type='numberInput'
              title={`Price (${userSettings?.currency})`}
              activeButton={filterState.price}
              onChange={handleChangePrice}
              subTitle={['Minimum', 'Maximum']}
              dataQa='price'
            />
          </Grid>
          {handoverVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Price />}
                name='downPayment'
                type='input'
                title='Down payment'
                activeButton={filterState.downPayment}
                onClick={handleChangeDownPayment}
                dataQa='downPayment'
              />
            </Grid>
          )}
          {bedsAndBathsIsVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Baths />}
                name='baths'
                type='button'
                size='little'
                title='Baths'
                options={predefinedVars.bathsValues}
                activeButton={filterState.baths}
                onClick={handleChangeBaths}
                dataQa='baths'
              />
            </Grid>
          )}
          {furnishedVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Furniture />}
                name='furniture'
                type='button'
                size='big'
                title='Furnished'
                options={predefinedVars.furnitureValues}
                activeButton={filterState.furniture}
                onClick={handleChangeFurnished}
                dataQa='furniture'
              />
            </Grid>
          )}
          {renovationVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Status />}
                name='renovation'
                type='button'
                size='big'
                title='Status'
                options={predefinedVars.renovationValues}
                activeButton={filterState.renovation}
                onClick={handleChangeRenovation}
                dataQa='renovation_status'
              />
            </Grid>
          )}
          {handoverVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Handover />}
                name='postHandover'
                type='button'
                size='small'
                title='Post handover'
                options={predefinedVars.postHandoverValues}
                activeButton={filterState.postHandover}
                onClick={handleChangePostHandover}
                dataQa='post_handover'
              />
            </Grid>
          )}
          {handoverVisible && (
            <Grid item xs={12} sm={9} lg={9} md={9}>
              <FiltersBlock
                icon={<icons.Handover />}
                name='handover'
                type='doubleButton'
                size='circle'
                title='Handover'
                subTitle={['Quarters', 'Years']}
                options={predefinedVars.handover}
                activeButton={filterState.handover}
                onClick={handleChangeHandover}
                dataQa='handover'
                disabled={quarterActive}
              />
            </Grid>
          )}
          {floorVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Floor />}
                name='floor'
                type='button'
                size='big'
                title='Floor'
                options={predefinedVars.floorValues}
                activeButton={filterState.floor}
                onClick={handleChangeFloor}
                dataQa='floor'
              />
            </Grid>
          )}
          {availabilityVisible && (
            <Grid item xs={12}>
              <FiltersBlock
                icon={<icons.Availability />}
                name='occupancy'
                type='button'
                size='big'
                title='Availability'
                options={predefinedVars.availabilityValues}
                activeButton={filterState.occupancy}
                onClick={handleChangeOccupancy}
                dataQa='availability'
              />
            </Grid>
          )}
          {!isTariffFree && <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.FindListings />}
              name='findListings'
              type='checkbox'
              title='Find Listings'
              activeButton={filterState.creator}
              onClick={handleChangeCreator}
              dataQa='find_listings'
              isMyAgency={filterState.isMyAgency}
              changeMyAgency={handleChangeMyAgency}
              myFriends={filterState.myFriends}
              changeMyFriends={handleChangeMyFriend}
            />
          </Grid>}
          <Grid item xs={12}>
            <FiltersBlock
              icon={<icons.Keywords />}
              name='keywords'
              type='input'
              title='Keywords'
              activeButton={filterState.keywords}
              onChange={handleChangeKeywords}
              dataQa='keywords'
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{ justifyContent: 'left', margin: '0 0 15px 15px' }}
        data-qa={'more_filters_action'}
      >
        <Box sx={boxStyle}>
          <MyButton
            data={{
              customWidth: '226px',
              buttonName: 'Show results',
              variant: 'contained',
              buttonType: 'submit',
              dataQa: 'show_results_btn',
            }}
            onClick={() => {
              handleShowResult();
              setOpenDialog(false);
            }}
          />
          <MyButton
            data={{
              customWidth: '226px',
              buttonName: 'Reset',
              variant: 'outlined',
              styleType: 'cancel',
              dataQa: 'reset_btn',
            }}
            onClick={() => {
              handleResetFilters();
              setOpenDialog(false);
            }}
          />
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};
