import React, { ComponentProps, ReactNode } from 'react';
import { Stack } from '@mui/material';

export const Section = ({
  title,
  children,
  ...props
}: ComponentProps<typeof Stack> & {
  title?: string;
  children: ReactNode;
}) => {
  return (
    <Stack
      sx={{
        gap: '8px',
        borderRadius: { xs: '10px', sm: '20px' },
        padding: { xs: '14px 16px', sm: '22px 20px' },
        backgroundColor: '#F1F7FF',
        width: '100%',
        fontSize: {
          xs: '15px',
          sm: '16px',
        },
        color: '#2A3842',
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
