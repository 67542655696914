import { Grid } from '@mui/material';
import { DumbFormSelect, FormSelectWithFetch } from '@/components/uiComponents/FormSelect';
import {
  DoubleDeckerListsSelect,
  DoubleDeckerListWithBottomInputs,
} from '@/components/uiComponents/DoubleDeckerSelect';
import {
  furnitureRenovationChoices,
  floorNumberOfFloorChoices,
  findListingsChoices,
  handoverChoices,
  postHandoverChoices,
} from './MoreFilters.init';
import { useEffect, useState } from 'react';
import { KeywordSearch } from '@/components/uiComponents/KeywordSearch';
import { usersProvider } from '@/providers';
import { useSelector } from 'react-redux';
import {
  CompletionStatusEnum,
  RentPeriodEnum,
  State,
  TransactionTypeEnum,
  AdsFilteringType,
} from '@/types';
import { DownPaymentSearch } from '@/components/uiComponents/DownPaymentSearch';

interface IMoreFiltersProps {
  activityType: string;
  defaultFilters?: IMoreFiltersValues | AdsFilteringType;
  cbUpdateFilters: (filters: IMoreFiltersValues | AdsFilteringType) => void;
  isMoreFiltersVisible?: boolean;
}

interface IMoreFiltersValues {
  rentFrequency?: RentPeriodEnum;
  transactionType?: TransactionTypeEnum;
  completionStatus?: CompletionStatusEnum;
  furniture?: string;
  renovation?: string;
  floor?: string;
  floorsNumFrom?: number;
  floorsNumTo?: number;
  postHandover?: boolean;
  // "handover.quarter"?: string;
  // "handover.year"?: string;
  handover?: {
    quarter?: string;
    year?: string;
  };
  downPayment?: number;
  creator?: {
    id?: string;
    name?: string;
    kind?: string;
    myAgency?: string;
  };
  keywords?: string;
}

export const MoreFilters = ({
  defaultFilters,
  cbUpdateFilters,
  isMoreFiltersVisible,
}: IMoreFiltersProps) => {
  const { user } = useSelector(({ auth }: State) => auth);
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters || {});
  const [isOffPlan, setIsOffPlan] = useState<boolean>(false);

  useEffect(() => {
    if (defaultFilters) {
      setSelectedFilters(defaultFilters);
      if (defaultFilters.completionStatus === CompletionStatusEnum.OffPlan) {
        setIsOffPlan(true);
      } else {
        setIsOffPlan(false);
      }
    }
  }, [defaultFilters]);

  const handleFiltersChange = (selectType: string) => (value: any, extraParam?: any) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      let isChanged = false;

      const setValue = (key: keyof typeof updatedFilters, newValue: any) => {
        if (JSON.stringify(prevFilters[key]) !== JSON.stringify(newValue)) {
          updatedFilters[key] = newValue;
          isChanged = true;
        }
      };

      switch (selectType) {
        case 'furnitureRenovation':
          setValue('furniture', value.firstSelectedValue);
          setValue('renovation', value.secondSelectedValue);
          break;
        case 'floorNumberOfFloor':
          setValue('floor', value && value.firstStage ? value.firstStage.toString() : undefined);
          value?.secondStage?.forEach((item: any) => {
            if ('Floor from' in item) {
              setValue('floorsNumFrom', Number(item['Floor from']));
            } else if ('Floor to' in item) {
              setValue('floorsNumTo', Number(item['Floor to']));
            }
          });
          break;
        case 'findListings':
          // const creatorUpdate = { ...value, myAgency: extraParam ? 'true' : 'false' };
          setValue('creator', value);
          break;
        case 'keywords':
          setValue('keywords', value);
          break;
        case 'handover':
          setValue('handover', {
            quarter: value?.firstSelectedValue || '',
            year: value?.secondSelectedValue || '',
          });
          break;
        case 'postHandover':
          setValue('postHandover', value);
          break;
        case 'downPayment':
          setValue('downPayment', Number(value));
          break;
        default:
          console.warn(`Unknown filter type: ${selectType}`);
      }

      if (isChanged) {
        setTimeout(() => cbUpdateFilters(updatedFilters), 0);
      }

      return updatedFilters;
    });
  };

  const {
    posthandoverData,
    handoverData,
    furnitureRenovationData,
    floorNumberOfFloorData,
    findListingsData,
  } = getFilterData();

  return (
    <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
      <Grid item xs={12} sm={6} lg={2.4} md={3}>
        <DoubleDeckerListWithBottomInputs
          defaultFirstValue={(selectedFilters.floor as string) || ''}
          defaultSecondArray={[
            {
              'Floor from': selectedFilters.floorsNumFrom?.toString() ?? '',
            },
            {
              'Floor to': selectedFilters.floorsNumTo?.toString() ?? '',
            },
          ]}
          {...floorNumberOfFloorData}
          handleChange={handleFiltersChange('floorNumberOfFloor')}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={2.4} md={3}>
        <DumbFormSelect
          isMoreFiltersVisible={isMoreFiltersVisible}
          completionStatus={defaultFilters?.completionStatus as CompletionStatusEnum}
          defaultSelectedValue={selectedFilters.postHandover}
          placeholder={posthandoverData?.label}
          name='constructionType'
          disabled={
            !(posthandoverData?.choices && posthandoverData.choices.length > 0 && isOffPlan)
          }
          data={posthandoverData?.choices || []}
          handleChange={handleFiltersChange('postHandover')}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={2.4} md={3}>
        <DoubleDeckerListsSelect
          disabled={!isOffPlan}
          defaultFirstValue={selectedFilters.handover?.quarter || ''}
          defaultSecondValue={selectedFilters.handover?.year || ''}
          {...handoverData}
          handleChange={handleFiltersChange('handover')}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4.8} md={3}>
        <DownPaymentSearch
          placeholder={'Down Payment'}
          defaultDownPayment={selectedFilters.downPayment}
          handleChange={handleFiltersChange('downPayment')}
          disabled={!isOffPlan}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4.8} md={4}>
        <DoubleDeckerListsSelect
          defaultFirstValue={(selectedFilters.furniture as string) || ''}
          defaultSecondValue={(selectedFilters.renovation as string) || ''}
          {...furnitureRenovationData}
          handleChange={handleFiltersChange('furnitureRenovation')}
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} lg={2.4} md={4}>
        <FormSelectWithFetch
          defaultData={selectedFilters?.creator?.name ? { ...selectedFilters.creator } : undefined}
          // disabled={window.location.pathname === '/ads/my'}
          cbSearchBy={searchCreators}
          topButtonChoice={{
            name: user.employer ? user.employer.name : user.name,
            kind: user.employer ? 'agency' : user.kind,
            id: user.employer ? user.employer.id : user.id,
          }}
          label={findListingsData.label}
          topButtonText={findListingsData.topButtonText}
          inputPlaceholder={findListingsData.inputPlaceholder}
          handleChange={handleFiltersChange('findListings')}
        />
      </Grid> */}
      <Grid item xs={12} sm={4} lg={4.8} md={4}>
        <KeywordSearch
          defaultKeywords={selectedFilters.keywords}
          handleChange={handleFiltersChange('keywords')}
        />
      </Grid>
    </Grid>
  );
};

const getFilterData = () => {
  const _frData = furnitureRenovationChoices();
  const furnitureRenovationData = {
    placeholder: 'Equipment',
    firstStageLabel: _frData.furniture.label,
    firstStageData: _frData.furniture.data,
    secondStageLabel: _frData.renovation.label,
    secondStageData: _frData.renovation.data,
  };
  const _fnfData = floorNumberOfFloorChoices();
  const floorNumberOfFloorData = {
    placeholder: 'Floors',
    firstStageLabel: _fnfData.floor.label,
    firstStageData: _fnfData.floor.data,
    secondStageLabel: _fnfData.numberOfFloor.label,
    secondStageData: _fnfData.numberOfFloor.data,
  };

  const findListingsData = findListingsChoices();

  const _hndData = handoverChoices();
  const handoverData = {
    placeholder: 'Handover',
    firstStageLabel: _hndData.quarters.label,
    firstStageData: _hndData.quarters.data,
    secondStageLabel: _hndData.years.label,
    secondStageData: _hndData.years.data,
  };

  const posthandoverData = postHandoverChoices();

  return {
    posthandoverData,
    handoverData,
    furnitureRenovationData,
    floorNumberOfFloorData,
    findListingsData,
  };
};

const searchCreators = async (searchTerm: string) => {
  if (searchTerm?.length > 2) {
    const response = await usersProvider.getCreatorsByName({ name: searchTerm });
    if (response?.data?.items) {
      return response?.data?.items;
    }
  } else if (searchTerm?.length === 0) {
    return [];
  }
};
