import React, { useEffect, useState } from 'react';
import { Autocomplete, useJsApiLoader, Libraries } from '@react-google-maps/api';
import { TextField, Stack } from '@mui/material';
import { useFormContext, Control, Controller, FieldValues } from 'react-hook-form';
import { LabelComponent } from '@/components';

import styles from './GoogleMap.module.scss';

const libraries = ['places'];

const MyGooglMapsAutocompete = (props: {
  setLat?: (value: string) => void;
  setLng?: (value: string) => void;
  address?: string;
  coordinatesLat?: number;
  coordinatesLong?: number;
  control: Control;
  data: any;
  setValue: (name: string, value: any, options?: Partial<FieldValues>) => void;
}) => {
  const { setValue } = props;

  const [searchResult, setSearchResult] = useState(null);
  const [markerAddress, setMarkerAddress] = useState(props.address || '');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    libraries: libraries as Libraries,
  });

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = (searchResult as any).getPlace();
      const formattedAddress = place.formatted_address;
      setMarkerAddress(formattedAddress);
      setValue?.(props.data.name, formattedAddress);
    } else {
      // alert('Please enter text');
    }
  }
  return isLoaded ? (
    <Stack sx={{ width: '100%' }}>
      <LabelComponent label={props.data.label} />
      <Controller
        name={props.data.name}
        control={props.control}
        render={({ field }) => (
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <TextField
              name={props.data.name}
              className={styles.inputStyle}
              placeholder={props.data.placeholder}
              value={field.value}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMarkerAddress(event.target.value);
                setValue?.(props.data.name, event.target.value);
              }}
              sx={{ color: '#2A3842' }}
            />
          </Autocomplete>
        )}
      />
    </Stack>
  ) : (
    <></>
  );
};

export default React.memo(MyGooglMapsAutocompete);
