import classnames from 'classnames';
import { FormSelect } from '@/components';
import { PaginationType } from '@/types';
import { FormProvider, useForm } from 'react-hook-form';
import {
  COUNT_PAGES_AROUND_ACTIVEPAGE,
  COUNT_PAGES_AROUND_ENDS,
  COUNT_ADS_PER_PAGE_ARRAY,
} from './constants';
import styles from './Pagination.module.scss';
import ChevronLeft from './chevronLeft.svg';
import ChevronRight from './chevronRight.svg';

interface PaginationProps {
  paginationObj: PaginationType;
  onChangePage: (value: number) => void;
  rowsPerPage?: number;
  onChangeRowsPerPage?: (value: number) => void;
}

export const Pagination = ({
  paginationObj: { page, limit, total },
  onChangePage,
  rowsPerPage,
  onChangeRowsPerPage,
}: PaginationProps) => {
  const countPages = Math.trunc(total / limit) + (total % limit > 0 ? 1 : 0);
  const pages = new Array(countPages).fill(null).map((_, index) => index + 1);

  let beginPages: number[] = [];
  let endPages: number[] = [];
  let middlePages: number[] = [];
  let shouldLeftDotsShow = false;
  let shouldRightDotsShow = false;

  if (countPages > COUNT_PAGES_AROUND_ENDS * 2 + COUNT_PAGES_AROUND_ACTIVEPAGE * 2 + 1) {
    beginPages = pages.slice(0, COUNT_PAGES_AROUND_ENDS);
    endPages = pages.slice(-COUNT_PAGES_AROUND_ENDS);
    let middlePagesStartIndex = page - 1 - COUNT_PAGES_AROUND_ACTIVEPAGE;
    middlePagesStartIndex =
      middlePagesStartIndex >= COUNT_PAGES_AROUND_ENDS
        ? middlePagesStartIndex
        : COUNT_PAGES_AROUND_ENDS;
    middlePagesStartIndex =
      middlePagesStartIndex <=
      pages.length - COUNT_PAGES_AROUND_ENDS - COUNT_PAGES_AROUND_ACTIVEPAGE
        ? middlePagesStartIndex
        : page - COUNT_PAGES_AROUND_ENDS - COUNT_PAGES_AROUND_ACTIVEPAGE;
    let middlePagesEndIndex = page + COUNT_PAGES_AROUND_ACTIVEPAGE;
    middlePagesEndIndex =
      middlePagesEndIndex <= pages.length - COUNT_PAGES_AROUND_ENDS
        ? middlePagesEndIndex
        : pages.length - COUNT_PAGES_AROUND_ENDS;
    middlePagesEndIndex =
      middlePagesEndIndex >= COUNT_PAGES_AROUND_ENDS + COUNT_PAGES_AROUND_ACTIVEPAGE
        ? middlePagesEndIndex
        : COUNT_PAGES_AROUND_ENDS + COUNT_PAGES_AROUND_ACTIVEPAGE;
    middlePages = pages.slice(middlePagesStartIndex, middlePagesEndIndex);
    shouldLeftDotsShow = middlePages[0] > beginPages[beginPages.length - 1] + 1;
    shouldRightDotsShow = middlePages[middlePages.length - 1] < endPages[0] - 1;
  } else {
    middlePages = pages;
  }

  const PageItem = ({ item }: { item: number }) => (
    <div
      className={classnames(styles.pageItem, page === item && styles.pageItemActive)}
      onClick={() => setPage(item)}
    >
      {item}
    </div>
  );

  const setPage = (newPage: number) => onChangePage(newPage);

  const goToPrevPage = () => onChangePage(page - 1);

  const goToNextPage = () => onChangePage(page + 1);

  const currencyValues = COUNT_ADS_PER_PAGE_ARRAY.map((row: number) => {
    return { label: row.toString(), value: row.toString() };
  });

  const formMethods = useForm();

  return (
    <div className={styles.listPagination}>
      {rowsPerPage && (
        <div className={styles.rowsPerPage}>
          <div>Rows per page:</div>
          <div>
            <FormProvider {...formMethods}>
              <FormSelect
                uiVariant='blank'
                name='perPage'
                data={currencyValues}
                defaultValue={limit}
                handleChange={onChangeRowsPerPage}
              />
            </FormProvider>
          </div>
        </div>
      )}
      <div
        className={classnames(styles.pageItem, page === 1 && styles.pageItemDisabled)}
        onClick={goToPrevPage}
      >
        <img src={ChevronLeft} alt='Previous page' />
      </div>
      {beginPages.map((item) => (
        <PageItem key={item} item={item} />
      ))}

      {shouldLeftDotsShow && <div className={styles.pageItemDots}>...</div>}
      {middlePages.map((item) => (
        <PageItem key={item} item={item} />
      ))}
      {shouldRightDotsShow && <div className={styles.pageItemDots}>...</div>}

      {endPages.map((item) => (
        <PageItem key={item} item={item} />
      ))}
      <div
        className={classnames(styles.pageItem, page === countPages && styles.pageItemDisabled)}
        onClick={goToNextPage}
      >
        <img src={ChevronRight} alt='Next page' />
      </div>
    </div>
  );
};
