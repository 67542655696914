import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActivityKindEnum, AdFieldCurrencyEnum } from '@/types';
import { SectionTitle, FormButtons } from '@/components';

export const ActivityType = ({
  setIsTransactionVisible,
  setIsPropertyTypeVisible,
  setIsLocationVisible,
  setIsBtnVisible,
  isPageChanged,
  setIsPageChanged,
  setIsPriceRentVisible,
  setErrorRentPrice,
}: {
  setIsTransactionVisible: (value: boolean) => void;
  setIsPriceRentVisible: (value: boolean) => void;
  setIsPropertyTypeVisible: (value: boolean) => void;
  setIsLocationVisible: (value: boolean) => void;
  setIsBtnVisible: (value: boolean) => void;
  isPageChanged: boolean;
  setIsPageChanged: (value: boolean) => void;
  setErrorRentPrice: (value: boolean) => void;
}) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const { getValues, setValue } = useFormContext();
  const formValues = getValues();
  const { activityKind } = formValues;

  useEffect(() => {
    if (!isPageChanged) {
      setValue('propertyKind', null);
      setValue('transactionType', null);
      setValue('defaultRentFrequency', undefined);
      setValue('currency', AdFieldCurrencyEnum.AED);
      setValue('priceYearly', null);
      setValue('priceMonthly', null);
      setValue('priceWeekly', null);
      setValue('priceDaily', null);
      setIsPropertyTypeVisible(false);
      setIsLocationVisible(false);
      setIsBtnVisible(false);
      setErrorRentPrice(false);
    }
  }, [isChanged]);

  const typeOfActivityValues = [
    { label: 'Sale', value: ActivityKindEnum.Sale },
    { label: 'Rent', value: ActivityKindEnum.Rent },
  ];

  const onHandlerClick = (value: ActivityKindEnum) => {
    if (value !== activityKind) {
      setIsChanged(!isChanged);
      setValue('activityKind', value);
      setIsTransactionVisible(value === ActivityKindEnum.Sale);
      setIsPriceRentVisible(value === ActivityKindEnum.Rent);
      setIsPageChanged(false);
    }
  };

  return (
    <div>
      <SectionTitle number={1} title='Select type of activity' />
      <FormButtons 
        options={typeOfActivityValues}
        onClick={onHandlerClick}
        activeButton={activityKind}
        size='middle'
      />
    </div>
  );
};
