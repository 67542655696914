import { ChangeEvent, useState } from 'react';
import { Box, FormHelperText, MenuItem, Select, TextField, Tabs, Tab, Button } from '@mui/material';
import classNames from 'classnames';

import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  AdFieldRentFrequencyEnum,
  AdsFilteringType,
  FilterAddressType,
  RentPeriodEnum,
} from '@/types';
import { classifyPrice } from '@/utils';
import { MyButton, FormSelectCustom } from '@/components';

import { SelectItemButton } from './SelectItemButton';
import { DoubleButtonBlock } from './DoubleButtonBlock';
import styles from './RequestsFilters.module.scss';
import resetFilters from './resetFilters.svg';
import { ReactComponent as CaretDown } from './caretDown.svg';
import { useUserSettings } from '@/hooks/useUserSettings';
import { LocationAutocomplete } from '@/components/LocationAutocomplete/LocationAutocomplete';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';

export const RequestsFilters = ({
  filteringObj,
  onChangeFilters,
  handleReqForMeBtnClick,
  handleMyReqBtnClick,
  btnReqForMe,
  isForeign,
}: {
  filteringObj: AdsFilteringType;
  onChangeFilters: (value: AdsFilteringType, shouldSendRequest?: boolean) => void;
  btnReqForMe: boolean;
  isForeign: boolean;
  handleReqForMeBtnClick: () => void;
  handleMyReqBtnClick: () => void;
}) => {
  const { userSettings } = useUserSettings();
  const emptyFilteringObj: AdsFilteringType = {
    address: [],
    activityKind: undefined,
    completionStatus: undefined,
    rentFrequency: undefined,
    propertyKind: undefined,
    areaMin: undefined,
    areaMax: undefined,
    beds: undefined,
    baths: undefined,
    priceMin: undefined,
    priceMax: undefined,
  };
  const {
    address,
    activityKind,
    completionStatus,
    rentFrequency,
    propertyKind,
    areaMin = 0,
    areaMax = 0,
    beds,
    baths,
    priceMin = 0,
    priceMax = 0,
  } = filteringObj;

  const activityKindValues = [
    { label: 'Sale', value: ActivityKindEnum.Sale },
    { label: 'Rent', value: ActivityKindEnum.Rent },
  ];
  const completionStatusValues = [
    { label: 'Ready', value: CompletionStatusEnum.Ready },
    { label: 'Off-Plan', value: CompletionStatusEnum.OffPlan },
  ];
  const rentFrequencyValues = [
    { label: 'Yearly', value: RentPeriodEnum.Yearly },
    { label: 'Monthly', value: RentPeriodEnum.Monthly },
    { label: 'Weekly', value: RentPeriodEnum.Weekly },
    { label: 'Daily', value: RentPeriodEnum.Daily },
  ];
  const propertyKindValues = [
    { label: 'Apartment', value: PropertyKindEnum.Apartment },
    { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
    { label: 'Villa', value: PropertyKindEnum.Villa },
    { label: 'Floor', value: PropertyKindEnum.Floor },
    { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
    { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
    { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
    { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
    { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
    { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
    { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
    { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
    { label: 'Office', value: PropertyKindEnum.Office },
    { label: 'Shop', value: PropertyKindEnum.Shop },
  ];
  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];
  const bathsValues = [
    { label: '1', value: AdFieldBathsEnum.One },
    { label: '2', value: AdFieldBathsEnum.Two },
    { label: '3', value: AdFieldBathsEnum.Three },
    { label: '4', value: AdFieldBathsEnum.Four },
    { label: '5', value: AdFieldBathsEnum.Five },
    { label: '6', value: AdFieldBathsEnum.Six },
    { label: '7', value: AdFieldBathsEnum.Seven },
    { label: '8+', value: AdFieldBathsEnum.EightPlus },
  ];
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);
  const {
    inputAddressValue,
    setInputAddressValue,
    selectedLocation,
    locations,
    setSelectedLocation,
  } = useLocationAutocomplete({
    defaultValue: address?.[0]?.nameEn || '',
  });

  const bedsLabel = beds === AdFieldBedsEnum.Studio ? 'Studio' : beds ? `${beds} Beds` : '';
  const bathsLabel = baths ? `${baths} Baths` : '';
  const bedsBathsText =
    !bedsLabel && !bathsLabel
      ? 'Beds & Baths'
      : bedsLabel && bathsLabel
        ? `${bedsLabel} / ${bathsLabel}`
        : bedsLabel || bathsLabel;
  const areaLabel =
    !areaMin && !areaMax
      ? `Area (${userSettings?.areaUnits.toLowerCase()})`
      : `${areaMin} - ${areaMax}`;

  const priceLabel =
    !priceMin && !priceMax
      ? `Price (${userSettings?.currency})`
      : `${classifyPrice(priceMin)} - ${classifyPrice(priceMax)}`;

  const handleChangeAreaMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaMin: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangeAreaMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaMax: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMin: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceMax: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handlePlaceChanged = (location: LocationModel | null) => {
    const addressObject: FilterAddressType = {
      coordinatesLat: String(location?.latitude),
      coordinatesLong: String(location?.longitude),
      nameEn: location?.[location?.kind] ?? '',
    };
    setSelectedLocation(location);
    onChangeFilters(
      { ...filteringObj, locationId: location?.id, address: [{ ...addressObject }] },
      true
    );
  };

  const handleInputAddressClear = () => {
    setInputAddressValue('');
    setSelectedLocation(null);
    onChangeFilters({ ...filteringObj, address: [], locationId: undefined }, true);
  };

  const handleResetFilters = () => {
    setInputAddressValue('');
    onChangeFilters({ ...emptyFilteringObj }, true);
  };

  const handleSearchSubmit = () => {
    onChangeFilters(
      {
        ...filteringObj,
        address: [
          {
            addressEn: inputAddressValue || '',
          },
        ],
        locationId: selectedLocation?.id || locations?.[0]?.id || undefined,
      },
      true
    );
  };

  const shouldBeShownResetFilters =
    !!address?.length ||
    activityKind ||
    completionStatus ||
    rentFrequency ||
    propertyKind?.length ||
    !!areaMin ||
    !!areaMax ||
    !!beds ||
    !!baths ||
    !!priceMin ||
    !!priceMax;

  const propertyKindValueItem = propertyKindValues.find((item) => item.value === propertyKind?.[0]);
  const propertyKindValueLabel = propertyKindValueItem?.label || 'Property type';

  const activityKindValueItem = activityKindValues.find((item) => item.value === activityKind);
  const activityKindValueLabel = activityKindValueItem?.label || 'Type of activity';

  return (
    <div className={styles.filtersContainer}>
      {!isForeign && (
        <DoubleButtonBlock
          handleReqForMeBtnClick={handleReqForMeBtnClick}
          handleMyReqBtnClick={handleMyReqBtnClick}
          btnReqForMe={btnReqForMe}
        />
      )}
      <div className={styles.row}>
        <LocationAutocomplete
          inputAddressValue={inputAddressValue}
          setInputAddressValue={setInputAddressValue}
          locations={locations}
          onSelect={handlePlaceChanged}
          handleClear={handleInputAddressClear}
        />
        <Box
          sx={{
            width: {
              sm: '166px',
            },
          }}
        >
          <MyButton
            data={{
              buttonName: 'Search',
              styleType: 'button',
              variant: 'contained',
            }}
            onClick={handleSearchSubmit}
          />
        </Box>
      </div>
      <div className={styles.row} style={{ flexWrap: 'wrap' }}>
        <div className={styles.activityKindContainer}>
          <FormSelectCustom label='' value={activityKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <div className={classNames(styles.selectInnerContainer, styles.filterActivityKind)}>
                <div className={styles.selectInnerContent}>
                  {activityKindValues.map(({ label, value }) => (
                    <Button
                      key={value}
                      className={classNames({
                        [styles.selectButtons]: true,
                        [styles.activityKindButton]: true,
                        [styles.selectButtonsActive]: activityKind === value,
                      })}
                      variant='contained'
                      onClick={() =>
                        onChangeFilters(
                          {
                            ...filteringObj,
                            activityKind: filteringObj.activityKind === value ? undefined : value,
                          },
                          true
                        )
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </div>
                {(activityKind === ActivityKindEnum.Rent ||
                  activityKind === ActivityKindEnum.Sale) && (
                  <FormHelperText>
                    <span className={styles.selectInnerTitle}>
                      {activityKind === ActivityKindEnum.Rent
                        ? 'Rent Frequency'
                        : 'Completion Status'}
                    </span>
                  </FormHelperText>
                )}
                {activityKind === ActivityKindEnum.Rent && (
                  <div className={styles.selectInnerContent}>
                    {rentFrequencyValues.map(({ label, value }) => (
                      <Button
                        key={value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.activityKindButton]: true,
                          [styles.selectButtonsActive]: rentFrequency === value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              rentFrequency:
                                filteringObj.rentFrequency === value ? undefined : value,
                            },
                            true
                          )
                        }
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                )}
                {activityKind === ActivityKindEnum.Sale && (
                  <div className={styles.selectInnerContent}>
                    {completionStatusValues.map(({ label, value }) => (
                      <Button
                        key={value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.activityKindButton]: true,
                          [styles.selectButtonsActive]: completionStatus === value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              completionStatus:
                                filteringObj.completionStatus === value ? undefined : value,
                            },
                            true
                          )
                        }
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </FormSelectCustom>
        </div>
        <div className={styles.propertyKindContainer}>
          <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <Tabs
                value={propertyTypeTab}
                onChange={(_, val) => setPropertyTypeTab(val)}
                aria-label='property type tabs'
                className={styles.tabsContainer}
              >
                <Tab
                  label='Residential'
                  id='property-type-tab-0'
                  aria-controls='property-type-tabpanel-0'
                  className={styles.tabItem}
                />
                <Tab
                  label='Commercial'
                  id='property-type-tab-1'
                  aria-controls='property-type-tabpanel-1'
                  className={styles.tabItem}
                />
              </Tabs>
              {propertyTypeTab === 0 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 0}
                  id='property-type-tabpanel-0'
                  aria-labelledby='property-type-tab-0'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[0, 1, 2, 3].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] === propertyKindValues[item].value
                                  ? undefined
                                  : [propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[4, 5, 6, 7].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] === propertyKindValues[item].value
                                  ? undefined
                                  : [propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              {propertyTypeTab === 1 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 1}
                  id='property-type-tabpanel-1'
                  aria-labelledby='property-type-tab-1'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[8, 9, 10].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] === propertyKindValues[item].value
                                  ? undefined
                                  : [propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[11, 12, 13].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind?.[0] === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind?.[0] === propertyKindValues[item].value
                                  ? undefined
                                  : [propertyKindValues[item].value],
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </FormSelectCustom>
        </div>
        <div className={styles.areaContainer}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {areaLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterArea)}>
              <div className={classNames(styles.selectInnerContent, styles.filterArea)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Minimum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaMin}
                    onChange={handleChangeAreaMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Maximum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaMax}
                    onChange={handleChangeAreaMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={areaMin > areaMax && areaMax !== 0}
                    helperText={
                      areaMin > areaMax && areaMax !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </div>
        <div className={styles.bedsBathsContainer}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {bedsBathsText}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Beds</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {bedsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={beds as AdFieldBedsEnum}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          beds: newValue as AdFieldBedsEnum,
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Baths</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {bathsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={baths as AdFieldBathsEnum}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          baths: newValue as AdFieldBathsEnum,
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </Select>
        </div>
        <div className={styles.priceContainer}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {priceLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
              <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Minimum</span>
                  </FormHelperText>
                  <TextField
                    value={priceMin}
                    onChange={handleChangePriceMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Maximum</span>
                  </FormHelperText>
                  <TextField
                    value={priceMax}
                    onChange={handleChangePriceMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={priceMin > priceMax && priceMax !== 0}
                    helperText={
                      priceMin > priceMax && priceMax !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </div>
      </div>
      {shouldBeShownResetFilters && (
        <div className={styles.rowControls}>
          <div className={styles.buttonLink} onClick={handleResetFilters}>
            <img src={resetFilters} alt='Reset filters' />
            Reset filters
          </div>
        </div>
      )}
    </div>
  );
};
