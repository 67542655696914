import { useState, FC } from 'react';
import { Stack } from '@mui/material';
import { LabelComponent } from '@/components';
import TextFieldComponent from './TextFieldComponent';
import { IMyTextFieldProps } from '../types';

export const MyTextField: FC<IMyTextFieldProps> = ({
  data,
  control,
  disabled,
  errors,
  onChange,
  onClick,
  emailOrPhoneInput,
}) => {
  const {
    name,
    placeholder,
    fillColor,
    customWidth,
    multiline,
    endAdornment,
    label,
    isRequired,
    iconInfo,
    tooltip,
    maxLength,
    dataQa,
  } = data;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const typePassword = (name: string) => {
    if (name === 'password') {
      return showPassword ? 'text' : 'password';
    }
    if (name === 'confirmPassword') {
      return showConfirmPassword ? 'text' : 'password';
    }
    return 'text';
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <LabelComponent label={label} isRequired={isRequired} iconInfo={iconInfo} tooltip={tooltip} />
      <TextFieldComponent
        dataQa={dataQa}
        control={control}
        disabled={disabled}
        showPassword={name === 'password' ? showPassword : showConfirmPassword}
        setShowPassword={name === 'password' ? setShowPassword : setShowConfirmPassword}
        name={name}
        placeholder={placeholder}
        fillColor={fillColor}
        customWidth={customWidth}
        multiline={multiline}
        type={typePassword(name)}
        endAdornment={endAdornment ? true : false}
        error={errors && !!errors[name]}
        helperText={errors && errors[name]?.message}
        onChange={onChange}
        onClick={onClick}
        emailOrPhoneInput={emailOrPhoneInput}
        maxLength={maxLength}
      />
    </Stack>
  );
};
