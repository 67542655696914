import { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { FilesUploadUrlResponseType, NotificationTypeEnum } from '@/types';
import { filesProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { Loader, MyButton } from '@/components';
import { convertToPNG, convertToWEBP } from './FileUploader.utils';

import { IMAGE_FILE_TYPES, VIDEO_FILE_TYPES, PDF_FILE_TUPES } from './constants';
import styles from './FileUploader.module.scss';
import getRandomHex from '@/utils/getRandomHex';

interface FileUploaderProps {
  accept: { [MIMEType: string]: string[] };
  disabled?: boolean;
  fileTypes?: string[];
  fileTypeInfo?: string;
  hasAnError?: boolean,  
  labelButton?: string;
  labelFormat?: string;
  labelSize?: string;
  limit: number;
  type: string;
  onUploaded: (id: string, url: string, fileOriginalName?: string) => void;
}

interface IProcessFileProps {
  file: File;
  newFileName?: string;
  prefixForAutoNaming?: string;
}

export const FileUploader = ({
  accept,
  disabled,
  fileTypes = ['image/jpeg', 'image/png'],
  fileTypeInfo = "file",
  type,
  labelButton,
  labelFormat,
  labelSize,
  limit,
  onUploaded,
  hasAnError = false,
}: FileUploaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const uploadAcceptedFile = async ({
    name,
    type,
  }: File): Promise<FilesUploadUrlResponseType | null> => {
    const { ok, data, status, message } = await filesProvider.uploadUrl({
      fileName: name,
      contentType: type,
    });

    if (ok) {
      return { ...data as FilesUploadUrlResponseType, fileOriginalName: name} ;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return null;
  };

  const putFile = async (selectedFile: File, fileUploadUrl: string): Promise<boolean> => {
    const { ok, status, message } = await filesProvider.putFile(fileUploadUrl, selectedFile);
    if (ok) {
      return true;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return false;
  };

  const markFileReady = async (id: string, { name, type }: File): Promise<boolean> => {
    const { ok, status, message } = await filesProvider.markReady(id, {
      fileName: name,
      contentType: type,
    });
    if (ok) {
      return true;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return false;
  };

  const downloadFile = async (id: string, { name, type }: File): Promise<string | null> => {
    const {
      ok,
      data: responseData,
      status,
      message,
    } = await filesProvider.downloadUrl(id, {
      fileName: name,
      contentType: type,
    });
    if (ok) {
      const data = responseData as FilesUploadUrlResponseType;
      return data.fileUploadUrl;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return null;
  };

  const isValidFilename = (fileName: string) => /^[A-Za-z0-9 .-]+$/.test(fileName);

  const generateNewFileName = ({
    fileName,
    newFileName,
    prefixForAutoNaming
  }: {
    fileName: string;
    newFileName?: string;
    prefixForAutoNaming?: string;
  }): string => {
    const dotIndex = fileName.lastIndexOf('.');
    const ext = dotIndex !== -1 ? fileName.substring(dotIndex) : '';
    const timestamp = moment().format('YYYY-MM-DD-hh-mm-ss');
    let baseFileName;
    if (newFileName) {
      baseFileName = newFileName.includes('.') ? newFileName.substring(0, newFileName.lastIndexOf('.')) : newFileName;
    } else {
      const defaultName = prefixForAutoNaming || (ext ? ext.substring(1) : 'file');
      baseFileName = `${defaultName}-${timestamp}`;
    }
    const finalFileName = `${baseFileName}${ext}`;

    return finalFileName;
  }

  const getRenamedFileCopy = (file: File, newFileName: string): File => {
    return new File([file], newFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });
  }

  const processFile = async ({ file, newFileName, prefixForAutoNaming = fileTypeInfo }: IProcessFileProps ): Promise<void> => {
    let processingFile = file;

    if (!isValidFilename(processingFile.name)) {
      const nFileName = generateNewFileName({ fileName: file.name, newFileName, prefixForAutoNaming });
      processingFile = getRenamedFileCopy(file, nFileName);
    };
   
    const uploadedFile = await uploadAcceptedFile(processingFile);    

    if (!uploadedFile) return;

    const puttedFile = await putFile(processingFile, uploadedFile.fileUploadUrl);
    if (!puttedFile) return;

    const markedFileReady = await markFileReady(uploadedFile.id, processingFile);
    if (!markedFileReady) return;

    const downloadedFileUrl = await downloadFile(uploadedFile.id, processingFile);
    if (!downloadedFileUrl) return;

    onUploaded(uploadedFile.id, downloadedFileUrl, uploadedFile.fileOriginalName);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const filteredFiles = acceptedFiles.filter(file => fileTypes.includes(file.type));
      const limitedFiles = limit > 0 ? filteredFiles.slice(0, limit) : filteredFiles;
      setIsLoading(true);

      for (const file of limitedFiles) {
        try {
          let processedFile = file;

          if (type === 'image') {
            const pngFile = await convertToPNG(file);
            if (!pngFile) throw new Error('Error in converting to PNG');
            processedFile = pngFile;
          }

          await processFile({ file: processedFile });
        } catch (error) {
          console.error(error);
          dispatch(appSetNotification(NotificationTypeEnum.Error, 'Error processing image file'));
          break;
        }
      }

      setIsLoading(false);
    },
    [limit, type, processFile, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    disabled: disabled || isLoading,
  });

  const styleWithError = hasAnError ? styles.customError : ''

  return (
    <div {...getRootProps()} className={`${styles.uploader} ${styleWithError}`}>
      <input {...getInputProps()} />
      {isLoading && <Loader />}
      <MyButton
        disabled={disabled}
        data={{
          buttonName: labelButton || 'Upload file',
          customWidth: '217px',
          variant: 'contained',
          buttonType: 'button',
        }}
      />
      <p className={styles.text}>or drag them here</p>
      {labelFormat && (
        <p style={{ textAlign: 'center' }}>
          <span>{labelFormat}</span>
        </p>
      )}
      {labelSize && (
        <p>
          <span>{labelSize}</span>
        </p>
      )}
    </div>
  );
};
