import styles from './SectionHeader.module.scss';

interface Props {
  title: string;
  dataQa?: string;
}

export const SectionHeader = ({ title, dataQa }: Props) => (
  <div className={styles.header} data-qa={`${dataQa}_header`}>
    <div className={styles.title} data-qa={`${dataQa}_title`}>
      {title}
    </div>
  </div>
);
