import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import { State, AdModel } from '@/types';
import { icons } from '../Icons/icons';

const StyledMenuItem = styled(MenuItem)({
  fontWeight: '400',
});

export const CommentMenu = ({
  open,
  handleClose,
  anchorEl,
  isCheckedMyComment,
  onHandlerClickDelete,
  onHandlerClickMessage,
  setOpenCommentReportDialog,
}: {
  open: boolean;
  handleClose: () => void;
  anchorEl: Element | null;
  isCheckedMyComment: boolean;
  onHandlerClickDelete: () => void;
  onHandlerClickMessage: (typeMessage: string) => void;
  setOpenCommentReportDialog: (open: boolean) => void;
}) => {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isCheckedMyComment && (
          <StyledMenuItem onClick={onHandlerClickDelete}>
            <ListItemIcon>
              <icons.Delete />
            </ListItemIcon>
            Delete
          </StyledMenuItem>
        )}
        {isCheckedMyComment && (
          <StyledMenuItem onClick={() => onHandlerClickMessage('edit')}>
            <ListItemIcon>
              <icons.EditComment />
            </ListItemIcon>
            Edit
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={() => setOpenCommentReportDialog(true)}>
          <ListItemIcon>
            <icons.Report />
          </ListItemIcon>
          Report
        </StyledMenuItem>
      </Menu>
    </>
  );
};
