import { StyledButton } from '@/components/Header/stylesHeader';
import styles from './EmptyList.module.scss';
import Image from './emptyList.png';
import AddAd from '../../Header/addAd.svg';
import { useNavigate } from 'react-router-dom';

interface EmptyListProps {
  statusBtn: boolean;
}

export const EmptyList = ({ statusBtn }: EmptyListProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img className={styles.containerImg} src={Image} alt='Empty list' />
      {statusBtn && (
        <>
          <div>
            No requests for your listings? Act now! Add new listings or update existing ones to
            boost visibility!
          </div>
          <StyledButton
            style={{ maxWidth: '220px', width: 'auto', marginTop: '20px' }}
            type='submit'
            variant='contained'
            color='primary'
            onClick={() => navigate('/ads/create')}
          >
            <img src={AddAd} alt='Add Ad' />
            Add more listing
          </StyledButton>
        </>
      )}
      {!statusBtn && (
        <div>
          You haven’t sent any requests yet. Browse properties, showcase your listings, and express
          interest in properties your clients dream of. Start now!
        </div>
      )}
    </div>
  );
};
