import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  AdsFilteringType,
  RequestsFilteringType,
  PaginationType,
  AdsSortingType,
  SortingOrderEnum,
  ActivityKindEnum,
  CompletionStatusEnum,
  AdFieldRentFrequencyEnum,
  PropertyKindEnum,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  RequestsGetAllResponseType,
  RequestMyModel,
  NotificationTypeEnum,
  RequestSortingFieldsEnum,
  SortingFieldsType,
  RequestsButtonStatusEnum,
  State,
  UserKindEnum,
  RentPeriodEnum,
  UserTariffPlanEnum,
} from '@/types';
import { setAddressParamsInFilteringObj } from '@/utils';
import { propertyAdRequestProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination, PageNotFound } from '@/components';

import { COUNT_ADS_PER_PAGE } from './constants';
import { RequestsFilters } from './RequestsFilters';
import { RequestsListControls } from './RequestsListControls';
import { EmptyList } from './EmptyList';
import { RequestsMyListItem } from './RequestsMyListItem';
import styles from './Requests.module.scss';

interface DataItem {
  unreadCount?: number;
}

export const Requests = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;
  const changeQueryParams = useChangeQueryParams('notifications');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [isLoadingGetRequests, setIsLoadingGetRequests] = useState<boolean>(true);

  const [requests, setRequests] = useState<RequestMyModel[]>();

  const [filteringObj, setFilteringObj] = useState<AdsFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const [_countRequests, setCountRequests] = useState(0);

  const isForeign = user.kind === UserKindEnum.Foreign;
  const isForeignPermission = isForeign && user.verified;

  const [totalRequests, setTotalRequests] = useState(0);

  const queryParam = new URLSearchParams(window.location.search);
  const status = queryParam.get('status');

  const statusBtn = isForeignPermission
    ? false
    : status
      ? status === RequestsButtonStatusEnum.RequestsForMe
        ? true
        : false
      : true;

  const sortingFields: SortingFieldsType = [
    { label: 'New request', value: RequestSortingFieldsEnum.UNREAD },
    { label: 'Number of all request', value: RequestSortingFieldsEnum.TOTAL },
    { label: 'Date of last request', value: RequestSortingFieldsEnum.DATE },
    { label: 'Agent commission', value: RequestSortingFieldsEnum.FEE },
  ];

  const getAdRequest = async () => {
    const address =
      queryParams.addressParamLat && queryParams.addressParamLong
        ? [
            {
              addressEn: queryParams.addressParamAddress as string,
              coordinatesLat: queryParams.addressParamLat as string,
              coordinatesLong: queryParams.addressParamLong as string,
              nameEn: queryParams.addressParamName as string,
              placeId: queryParams.addressParamPlaceId as string,
            },
          ]
        : [];

    const newFilteringObj: AdsFilteringType = {
      address,
      locationId: (queryParams.locationId as string) || undefined,
      activityKind: queryParams.activityKind as ActivityKindEnum,
      completionStatus: queryParams.completionStatus as CompletionStatusEnum,
      rentFrequency: queryParams.rentFrequency as RentPeriodEnum,
      propertyKind: queryParams.propertyKind
        ? [queryParams.propertyKind as PropertyKindEnum]
        : undefined,
      areaMin: parseInt(queryParams.areaMin as string) || undefined,
      areaMax: parseInt(queryParams.areaMax as string) || undefined,
      beds: queryParams.beds as AdFieldBedsEnum,
      baths: queryParams.baths as AdFieldBathsEnum,
      priceMin: parseInt(queryParams.priceMin as string) || undefined,
      priceMax: parseInt(queryParams.priceMax as string) || undefined,
    };

    const newSortingObj = {
      field:
        sortingFields.find((sort) => sort.label === (queryParams.field as string))?.value ||
        sortingFields[0].value,
      order: (queryParams.order as SortingOrderEnum) || SortingOrderEnum.Desc,
    };
    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * COUNT_ADS_PER_PAGE
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: COUNT_ADS_PER_PAGE,
      total: 0,
    };

    setIsLoadingGetRequests(true);
    let ok, data, status, message;
    if (statusBtn) {
      ({ ok, data, status, message } = await propertyAdRequestProvider.getRequestsForMe(
        newFilteringObj,
        newSortingObj,
        newPaginationObj
      ));
    } else {
      ({ ok, data, status, message } = await propertyAdRequestProvider.getMyRequests(
        newFilteringObj,
        newSortingObj,
        newPaginationObj
      ));
    }
    if (ok) {
      const { items, total } = data as RequestsGetAllResponseType;
      const sumUnreadCounts = (array: DataItem[]) => {
        return array.reduce((sum: number, item: DataItem) => sum + (item.unreadCount || 0), 0);
      };
      setCountRequests(sumUnreadCounts(items));
      setRequests(items);
      setFilteringObj({ ...newFilteringObj });
      setSortingObj({ ...newSortingObj });
      setTotalRequests(total);
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      setRequests([]);
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetRequests(false);
  };

  useEffect(() => {
    getAdRequest();
  }, [queryParamsStr]);

  useEffect(() => {
    getAdRequest();
  }, [statusBtn]);

  const handleChangeFilter = (newFilteringObj: AdsFilteringType, shouldSendRequest?: boolean) => {
    if (newFilteringObj.activityKind === ActivityKindEnum.Rent) {
      newFilteringObj.completionStatus = undefined;
    } else if (newFilteringObj.activityKind === ActivityKindEnum.Sale) {
      newFilteringObj.rentFrequency = undefined;
    } else {
      newFilteringObj.completionStatus = undefined;
      newFilteringObj.rentFrequency = undefined;
      newFilteringObj.activityKind = undefined;
    }

    if (!newFilteringObj.propertyKind?.length) {
      newFilteringObj.propertyKind = undefined;
    }

    setFilteringObj(newFilteringObj);
    if (shouldSendRequest) {
      changeQueryParams({
        ...queryParams,
        ...setAddressParamsInFilteringObj(newFilteringObj),
        page: 1,
      });
    }
  };

  const handleChangeSort = (newSortingObj: AdsSortingType) =>
    changeQueryParams({
      ...queryParams,
      ...newSortingObj,
    });

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleReqForMeBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      status: RequestsButtonStatusEnum.RequestsForMe,
      page: 1,
    });
  };

  const handleMyReqBtnClick = () => {
    changeQueryParams({
      ...queryParams,
      status: RequestsButtonStatusEnum.RequestsByMe,
      page: 1,
    });
  };

  const text = statusBtn ? 'My Inbox' : 'My Outbox';

  if (isLoadingGetRequests) {
    return <Loader />;
  }

  if (isTariffFree) {
    return <PageNotFound />;
  }

  return (
    <div className={styles.requestsContainer}>
      {filteringObj && (
        <RequestsFilters
          filteringObj={filteringObj}
          onChangeFilters={handleChangeFilter}
          handleReqForMeBtnClick={handleReqForMeBtnClick}
          handleMyReqBtnClick={handleMyReqBtnClick}
          btnReqForMe={statusBtn}
          isForeign={isForeign}
        />
      )}
      <div className={styles.templateRequestsContainer}>
        <Typography sx={{ fontSize: '28px', fontWeight: '500' }}>
          {text} <span style={{ fontWeight: 800, color: '#1650FF' }}>{totalRequests}</span>
        </Typography>
        {sortingObj && (
          <RequestsListControls
            sortingFields={sortingFields}
            sortingObj={sortingObj}
            onChangeSort={handleChangeSort}
          />
        )}
      </div>
      {!requests?.length ? (
        <EmptyList statusBtn={statusBtn} />
      ) : (
        <div className={styles.myAdsList}>
          {(requests as RequestMyModel[]).map((item) => (
            <RequestsMyListItem
              key={item.id}
              item={item}
              btnReqForMe={statusBtn}
              filteringObj={filteringObj}
            />
          ))}
        </div>
      )}
      {!!paginationObj?.total && paginationObj?.total > COUNT_ADS_PER_PAGE && (
        <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
      )}
    </div>
  );
};
