import { ISectionData } from '@/components/AccountManagement/utils/types';

export type AgencyType = {
  email: string;
  id: string;
  licenseEnd: string;
  licenseId: string;
  licenseStart: string;
  manual: boolean;
  nameEn: string;
  phone: string;
  stateId: string;
};

export type BrokerType = {
  id: string;
  stateId: string;
  email: string;
  agencyStateLicenseId: string;
  licenseStart: string;
  licenseEnd: string;
  manual: boolean;
  nameEn: string;
  phone?: string;
  agency: AgencyType;
};

export type DeveloperType = {
  email: string;
  id: string;
  licenseEnd: string;
  licenseId: string;
  licenseStart: string;
  manual: boolean;
  nameEn: string;
  phone: string;
  stateId: string;
};

export type LicenseType = AgencyType | BrokerType | DeveloperType;

export const agencyData: ISectionData = {
  groupSizes: [3, 3, 2, 1],
  data: [
    {
      name: 'id',
      label: 'Agency State Lic. ID',
      typeOfComponent: 'input',
    },
    {
      name: 'stateId',
      label: 'State ID',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseId',
      label: 'License id',
      typeOfComponent: 'input',
    },
    {
      name: 'nameEn',
      label: 'Name Eng',
      typeOfComponent: 'input',
    },
    {
      name: 'phone',
      label: 'Phone',
      typeOfComponent: 'input',
    },
    {
      name: 'email',
      label: 'Email',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseStart',
      label: 'License Start',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseEnd',
      label: 'License End',
      typeOfComponent: 'input',
    },
    {
      name: 'manual',
      label: 'Manual update',
      typeOfComponent: 'checkbox',
    },
  ],
};

export const brokerData: ISectionData = {
  groupSizes: [3, 3, 2, 1],
  data: [
    {
      name: 'id',
      label: 'Lic. ID',
      typeOfComponent: 'input',
    },
    {
      name: 'stateId',
      label: 'State ID',
      typeOfComponent: 'input',
    },
    {
      name: 'agencyStateLicenseId',
      label: 'Agency State Lic. ID',
      typeOfComponent: 'input',
    },
    {
      name: 'nameEn',
      label: 'Name Eng',
      typeOfComponent: 'input',
    },
    {
      name: 'phone',
      label: 'Phone',
      typeOfComponent: 'input',
    },
    {
      name: 'email',
      label: 'Email',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseStart',
      label: 'License Start',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseEnd',
      label: 'License End',
      typeOfComponent: 'input',
    },
    {
      name: 'manual',
      label: 'Manual update',
      typeOfComponent: 'checkbox',
    },
  ],
};

export const developerData: ISectionData = {
  groupSizes: [3, 3, 2, 1],
  data: [
    {
      name: 'id',
      label: 'Lic. ID',
      typeOfComponent: 'input',
    },
    {
      name: 'stateId',
      label: 'State ID',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseId',
      label: 'License id',
      typeOfComponent: 'input',
    },
    {
      name: 'nameEn',
      label: 'Name Eng',
      typeOfComponent: 'input',
    },
    {
      name: 'phone',
      label: 'Phone',
      typeOfComponent: 'input',
    },
    {
      name: 'email',
      label: 'Email',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseStart',
      label: 'License Start',
      typeOfComponent: 'input',
    },
    {
      name: 'licenseEnd',
      label: 'License End',
      typeOfComponent: 'input',
    },
    {
      name: 'manual',
      label: 'Manual update',
      typeOfComponent: 'checkbox',
    },
  ],
};

export const licenseData: ISectionData = {
  groupSizes: [1, 1, 1, 1, 1],
  data: [
    {
      name: 'alias',
      label: 'Alias',
      typeOfComponent: 'input',
    },
    {
      name: 'namespace',
      label: 'Namespace',
      typeOfComponent: 'input',
    },
  ],
};
