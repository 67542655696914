import { FC, useState, ChangeEvent } from 'react';
import { Box, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, SubmitHandler, UseFormRegister } from 'react-hook-form';

import { MyDialog, MyButton } from '@/components';
import { StyleLabel } from '../../uiComponents/styled';
import styles from './FormCheckbox.module.scss';

interface CommentReportFormData {
  'Fake information': boolean;
  Discrimination: boolean;
  Spam: boolean;
  Fraud: boolean;
  Insults: boolean;
}

interface FormCheckboxProps {
  name: keyof CommentReportFormData;
  register: UseFormRegister<CommentReportFormData>;
  checked: boolean;
  setValue: (name: keyof CommentReportFormData, value: boolean) => void;
}

export const CommentReport = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { register, handleSubmit, setValue, reset } = useForm<CommentReportFormData>();

  const onSubmit: SubmitHandler<CommentReportFormData> = (data) => {
    //console.log(data);
    onHandleClose();
  };

  const onHandleClose = () => {
    handleClose();
    reset();
  };

  if (!open) return null;
  return (
    <MyDialog open={open} onClose={onHandleClose} dialogTitle='Report a comment' width='590'>
      <Box display='flex' justifyContent='center' width='100%'>
        <Stack direction='column' spacing={2} justifyContent='center'>
          <Stack direction='row' spacing={2} justifyContent='center'>
            <Stack sx={{ width: '100%' }}>
              <StyleLabel style={{ textAlign: 'center' }}>
                You are about to report a comment. Please indicate the reason why you think this
                comment violates the rules of use of the platform.
              </StyleLabel>
            </Stack>
          </Stack>
          <form className='form' onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 4 }}>
                <Stack>
                  <FormCheckbox
                    name='Fake information'
                    register={register}
                    checked={false}
                    setValue={setValue}
                  />
                  <FormCheckbox
                    name='Discrimination'
                    register={register}
                    checked={false}
                    setValue={setValue}
                  />
                </Stack>
                <Stack>
                  <FormCheckbox
                    name='Spam'
                    register={register}
                    checked={false}
                    setValue={setValue}
                  />
                  <FormCheckbox
                    name='Insults'
                    register={register}
                    checked={false}
                    setValue={setValue}
                  />
                </Stack>
                <Stack>
                  <FormCheckbox
                    name='Fraud'
                    register={register}
                    checked={false}
                    setValue={setValue}
                  />
                </Stack>
              </Stack>
              <Box
                justifyContent='center'
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: '16px',
                  marginTop: '50px',
                }}
              >
                <MyButton
                  data={{
                    buttonName: 'Report',
                    variant: 'contained',
                    customWidth: '226px',
                    buttonType: 'submit',
                  }}
                />
                <MyButton
                  data={{
                    buttonName: 'Cancel',
                    variant: 'outlined',
                    customWidth: '226px',
                    styleType: 'cancel',
                  }}
                  onClick={onHandleClose}
                />
              </Box>
            </Stack>
          </form>
        </Stack>
      </Box>
    </MyDialog>
  );
};

const FormCheckbox: FC<FormCheckboxProps> = ({ name, register, checked, setValue }) => {
  const [val, setVal] = useState<boolean>(checked);
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(name, target.checked);
    setVal(target.checked);
  };
  return (
    <Stack justifyContent='center'>
      <FormControlLabel
        className={styles.label}
        label={name}
        control={
          <Checkbox
            {...register(name)}
            checkedIcon={<span className={styles.checked} />}
            icon={<span />}
            checked={val}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
      />
    </Stack>
  );
};
