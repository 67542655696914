import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ActivityKindEnum,
  CompletionStatusEnum,
  AdFieldCurrencyEnum,
  RentPeriodEnum,
} from '@/types';
import { SectionTitle, FormButtons } from '@/components';

const completionStatusValues = [
  { label: 'All', value: CompletionStatusEnum.All },
  { label: 'Ready', value: CompletionStatusEnum.Ready },
  { label: 'OffPlan', value: CompletionStatusEnum.OffPlan },
];

const rentFrequencyValues = [
  { label: 'Daily', value: RentPeriodEnum.Daily },
  { label: 'Weekly', value: RentPeriodEnum.Weekly },
  { label: 'Monthly', value: RentPeriodEnum.Monthly },
  { label: 'Yearly', value: RentPeriodEnum.Yearly },
];

export const CompletionStatus = ({
  period,
  setPeriod,
  typeOfActivity,
  completionStatus,
  setCompletionStatus,
}: {
  period?: RentPeriodEnum | null;
  setPeriod: (value: RentPeriodEnum | null) => void;
  typeOfActivity: ActivityKindEnum;
  completionStatus?: CompletionStatusEnum;
  setCompletionStatus: (value: CompletionStatusEnum | undefined) => void;
}) => {
  const { setValue } = useFormContext();

  const onHandlerClick = (value: CompletionStatusEnum | RentPeriodEnum) => {
    if (typeOfActivity === ActivityKindEnum.Sale) {
      if (Object.values(CompletionStatusEnum).includes(value as CompletionStatusEnum)) {
        setValue('completionStatus', value as CompletionStatusEnum);
        setCompletionStatus(value as CompletionStatusEnum);
        setValue('period', null);
      }
    } else {
      if (Object.values(RentPeriodEnum).includes(value as RentPeriodEnum)) {
        setValue('period', value as RentPeriodEnum);
        setPeriod(value as RentPeriodEnum);
        setValue('completionStatus', null);
      }
    }
  };

  return (
    <div>
      <SectionTitle
        number={2}
        title={
          typeOfActivity === ActivityKindEnum.Sale
            ? 'Completion status'
            : 'Information about the rental period'
        }
      />
      <FormButtons
        options={
          typeOfActivity === ActivityKindEnum.Sale ? completionStatusValues : rentFrequencyValues
        }
        onClick={onHandlerClick}
        activeButton={typeOfActivity === ActivityKindEnum.Sale ? completionStatus : period}
        size='middle'
      />
    </div>
  );
};
