import { FC, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { appSetNotification, authGetMyUser } from '@/store';
import { MyDialog, MyButton } from '@/components';
import { filesProvider, usersProvider } from '@/providers';
import { CircleCropper } from '../../../PhotoCropper/CircleCropper';
import { UserAvatarView } from './UserAvatarView';
import { getInitials } from '@/utils/stringAvatar';
import { buttons } from './ProfilePicWithCropper.init';
import { fetchImageAsBlob } from './ProfilePicWithCropper.utils';
import dayjs from 'dayjs';

import { FilesUploadUrlResponseType, NotificationTypeEnum, UserModel } from '@/types';
import { IDialogProps } from './types';


export const ProfilePicWithCropper: FC<IDialogProps> = (props) => {
  const { open, onClose, user } = props;
  const [src, setSrc] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState('');
  const [cropMode, setCropMode] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const loadImage = async () => {
      if (user?.media?.[0]?.fileUrl) {
        const imgBlob = await fetchImageAsBlob(user.media[0].fileUrl);
        if (imgBlob) {
          getInitialPreview(imgBlob, 'jpg');
        }
      }
    };

    loadImage();
  }, [user?.media])


  const uploadAcceptedFile = async ({
    name,
    type,
  }: File): Promise<FilesUploadUrlResponseType | null> => {
    const { ok, data, status, message } = await filesProvider.uploadUrl({
      fileName: name,
      contentType: type,
    });
    if (ok) {
      return data as FilesUploadUrlResponseType;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return null;
  };

  const putFile = async (selectedFile: File, fileUploadUrl: string): Promise<boolean> => {
    const { ok, status, message } = await filesProvider.putFile(fileUploadUrl, selectedFile);
    if (ok) {
      return true;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return false;
  };

  const markFileReady = async (id: string, { name, type }: File): Promise<boolean> => {
    const { ok, status, message } = await filesProvider.markReady(id, {
      fileName: name,
      contentType: type,
    });
    if (ok) {
      return true;
    }
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    return false;
  };

  const handleInputClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const processImageFile = async (fileOrBlob: File | Blob, fileNameExtension: string) => {
    const baseFileName = `avatar-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
    const extension = fileNameExtension || (fileOrBlob instanceof File ? fileOrBlob.name.split('.').pop() : 'png');
    const newFileName = `${baseFileName}.${extension}`;
    const newFile = new File([fileOrBlob], newFileName, { type: fileOrBlob.type, lastModified: Date.now() });

    setFileName(newFile.name);
    setSrc(URL.createObjectURL(newFile));
    setPreview(URL.createObjectURL(newFile));

    const uploadedFile = await uploadAcceptedFile(newFile);
    if (!uploadedFile) return false;

    const putSuccess = await putFile(newFile, uploadedFile.fileUploadUrl);
    if (!putSuccess) return false;

    const marked = await markFileReady(uploadedFile.id, newFile);
    if (!marked) return false;

    const dataSubmit = {
      id: user?.id || '',
      name: user?.name || '',
      media: [{ fileId: uploadedFile.id, fileName: newFile.name, order: 0, fileUrl: uploadedFile.fileUploadUrl }],
    };

    const userUpdateResponse = await usersProvider.patchMyUser(dataSubmit);
    if (!userUpdateResponse.ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, userUpdateResponse.message, userUpdateResponse.status));
      return false;
    }

    const { ok: getUserOk, data: getUserData } = await usersProvider.getMyUser();
    if (!getUserOk) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'Failed to get user data', 500));
      return false;
    }

    dispatch(authGetMyUser(getUserData as UserModel));
    return true;
  };

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const extension = file.name.split('.').pop() || 'png';
      const success = await processImageFile(file, extension);
      if (success && (preview || user?.media?.[0]?.fileUrl)) {
         setCropMode(true);
      }
    }
  };

  const handleBlobImage = async (blob: Blob) => {
    const success = await processImageFile(blob, 'png');
    if (success) setCropMode(false);
  };

  const getInitialPreview = async (fileOrBlob: File | Blob, fileNameExtension: string) => {
    const baseFileName = `avatar-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
    const extension = fileNameExtension || (fileOrBlob instanceof File ? fileOrBlob.name.split('.').pop() : 'png');
    const newFileName = `${baseFileName}.${extension}`;
    const newFile = new File([fileOrBlob], newFileName, { type: fileOrBlob.type, lastModified: Date.now() });

    setFileName(newFile.name);
    setSrc(URL.createObjectURL(newFile));
    setPreview(URL.createObjectURL(newFile));
  }

  const deleteAvatar = async () => {
    const dataSubmit = {
      id: user?.id || '',
      name: user?.name || '',
      media: [],
    };
    if (dataSubmit) {
      const { ok, message, status } = await usersProvider.patchMyUser(dataSubmit);
      if (ok) {
        const {
          ok: okGetMyUser,
          data: dataGetMyUser,
          status: statusGetMyUser,
          message: messageGetMyUser,
        } = await usersProvider.getMyUser();
        if (okGetMyUser && dataGetMyUser) {
          dispatch(authGetMyUser(dataGetMyUser as UserModel));
        } else {
          dispatch(
            appSetNotification(NotificationTypeEnum.Error, messageGetMyUser, statusGetMyUser)
          );
        }
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    }
  };

  const handleClose = () => {
    setCropMode(false);
    onClose();
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, actionKey: string) => {
    switch (actionKey) {
      case 'uploadPhotoAction':
        handleInputClick(e);
        break;
      case 'editPhotoAction':
        if ((preview || user?.media?.[0]?.fileUrl)) {
          setCropMode(true);
        }
        break;
      case 'deletePhotoAction':
        setPreview('');
        deleteAvatar();
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  if (!open) return null;

  return (
    <MyDialog open={open} onClose={handleClose} dialogTitle={'Profile picture'} width={'887'}>
      <Box display='flex' justifyContent='center' width='100%'>
        <input
          type='file'
          accept='image/*'
          ref={inputRef}
          onChange={handleImgChange}
          style={{ display: 'none' }}
        />
        <Stack alignItems='center' spacing={5} sx={{ width: '100%' }}>
          {cropMode ? (
            <CircleCropper
              onCrop={handleBlobImage}
              label='Save photo'
              image={preview || user?.media?.[0]?.fileUrl || ''}
            />
          ) : (
            <UserAvatarView
              theme={theme}
              userName={user?.name || 'user'}
              alt={user?.name}
              src={preview || user?.media?.[0]?.fileUrl}
            >
              {user && user?.name && getInitials(user?.name)}
            </UserAvatarView>
          )}

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='center'
            spacing={1}
            sx={{ width: '100%' }}
          >
            {buttons.map((data, index) => (
              <MyButton
                key={index}
                data={data}
                {...(data.actionKey === 'editPhotoAction'
                  ? { disabled: cropMode || (!preview || !user?.media?.[0]?.fileUrl) }
                  : { disabled: cropMode }
                )}
                onClick={(e: any) => handleButtonClick(e, data?.actionKey || '')}
              />
            ))}
          </Stack>
        </Stack>
      </Box>
    </MyDialog>
  );
};
