import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { AdFieldMultilistingEnum } from '@/types';
import { FormInput, FormCheckbox, FormButtons } from '@/components';

import styles from './PropertyPrice.module.scss';

export const PropertyMultilisting = () => {
  const [multilistingValue, setMultilistingValue] = useState<AdFieldMultilistingEnum>();
  const { setValue, getValues } = useFormContext();
  const formValues = getValues();
  const { topUp, multilisting } = formValues;

  useEffect(() => {
    setMultilistingValue(multilisting || AdFieldMultilistingEnum.Covered);
    setValue('multilisting', multilisting || AdFieldMultilistingEnum.Covered);
  }, [])
  

  const multilistingValues = [
    { label: 'Covered', value: AdFieldMultilistingEnum.Covered },
    { label: 'Not covered', value: AdFieldMultilistingEnum.NotCovered },
  ];

  const onHandlerAgencyFeeClick = (value: AdFieldMultilistingEnum) => {
    setValue('multilisting', value);
    setMultilistingValue(value);
  };

  return (
    <div className={styles.container}>
      <div>
        <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 15px' }}>
          Do you receive a fee from your client?
        </Typography>
        <FormButtons
          options={multilistingValues}
          onClick={onHandlerAgencyFeeClick}
          activeButton={multilistingValue}
          size='big'
        />
      </div>
      <div>
        <div className={styles.topUpLabel}>
          <FormCheckbox name='topUp' label='' checked={topUp} />
          <span>Top up</span>&nbsp;(Price boost opportunity)
        </div>
        <FormInput
          uiVariant='blank'
          name='topUpConditions'
          multiline
          placeholder='Enter additional conditions'
          minRows={undefined}
          rows={3}
        />
      </div>
    </div>
  );
};
