import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import {
  NewBuildingModel,
  UserModel,
  AdFieldStatusEnum,
  NotificationTypeEnum,
  CreateNewPlanType,
} from '@/types';
import { offPlanProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { icons } from '../Ad/Icons/icons';
import { Loader } from '@/components';

const StyledMenuItem = styled(MenuItem)({
  fontWeight: '400',
});

export const PlanViewMenu = ({
  page,
  plan,
  open,
  handleClose,
  anchorEl,
  user,
  setIsShownConfirmDialog,
}: {
  page: NewBuildingModel;
  plan: CreateNewPlanType;
  open: boolean;
  handleClose: () => void;
  anchorEl: Element | null;
  user: UserModel;
  setIsShownConfirmDialog: (value: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusAd, setStatusAd] = useState<AdFieldStatusEnum>(page.status);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickEdit = () => {
    // if (page && user.id === page.propertyAdCreator.id.toString()) {
    //   navigate(`/ads/edit/${page.id}`);
    // } else {
    //   handleClose();
    // }

    navigate(`/admin/plan/${plan.id}/edit/${page.id}`);
  };

  const onHandlerClick = async (fieldStatus: AdFieldStatusEnum) => {
    setIsLoading(true);
    const { ok, status, message } = await offPlanProvider.updateById(page.id!, {
      status: fieldStatus,
    });
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setStatusAd(AdFieldStatusEnum.Archived);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuItem onClick={() => handleClickEdit()}>
          <ListItemIcon>
            <icons.EditComment />
          </ListItemIcon>
          Edit
        </StyledMenuItem>
        {/* <StyledMenuItem
          onClick={() => {
            navigate(`/ads/create-pdf/${page.id}`);
          }}
        >
          <ListItemIcon>
            <icons.Pdf />
          </ListItemIcon>
          Save as PDF
        </StyledMenuItem> */}
        <StyledMenuItem onClick={() => console.log('Create link')}>
          <ListItemIcon>
            <icons.Delete />
          </ListItemIcon>
          Delete
        </StyledMenuItem>
        <StyledMenuItem onClick={() => console.log('Favorite')}>
          <ListItemIcon>
            <icons.Unlike />
          </ListItemIcon>
          Favorite
        </StyledMenuItem>
      </Menu>
    </>
  );
};
