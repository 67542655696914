import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, Stack, Typography, Divider, Checkbox } from '@mui/material';

import {
  PaginationType,
  TeamGetAllMembersResponseType,
  TeamGetAllRequestsResponseType,
  MemberModel,
  RequestModel,
  NotificationTypeEnum,
  State,
  UserTariffPlanEnum,
} from '@/types';
import { teamProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { SectionHeader, SectionTitle, Pagination, MyDialog, MyButton, Loader } from '@/components';

import { COUNT_MEMBERS_PER_PAGE, COUNT_REQUESTS_PER_PAGE, SEPARATOR } from './constants';
import { InvitationLink } from './InvitationLink';
import { TabPage } from './TabPage';
import { EmptyList } from './EmptyList';
import { MemberItem } from './MemberItem';
import { RequestItem } from './RequestItem';
import { PaymentItem } from './PaymentItem';
import { ReactComponent as IconArrowRight } from './iconArrowRight.svg';
import { ReactComponent as IconArrowLeft } from './iconArrowLeft.svg';
import styles from './Team.module.scss';

export const Team = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: State) => auth.user);
  const tariffPlan = user.tariffPlan?.name;
  const isTariffFree = tariffPlan === UserTariffPlanEnum.Free;
  const isTariffTrial = tariffPlan === UserTariffPlanEnum.Trial;
  const isFreeTariffs = isTariffFree || isTariffTrial;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [members, setMembers] = useState<MemberModel[]>();
  const [membersWithTariff, setMembersWithTariff] = useState<MemberModel[]>();
  const [membersPaginationObj, setMembersPaginationObj] = useState<PaginationType>({
    page: 1,
    limit: COUNT_MEMBERS_PER_PAGE,
    offset: 0,
    total: 0,
  });
  const [requests, setRequests] = useState<RequestModel[]>();
  const [requestsPaginationObj, setRequestsPaginationObj] = useState<PaginationType>({
    page: 1,
    limit: COUNT_REQUESTS_PER_PAGE,
    offset: 0,
    total: 0,
  });
  const [isDialogTerminateOpened, setIsDialogTerminateOpened] = useState<string>('');
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectedMembersWithTariff, setSelectedMembersWithTariff] = useState<string[]>([]);

  const getMembers = async (newPage?: number, newOffset?: number) => {
    const newPaginationObj = {
      ...membersPaginationObj,
      page: newPage || membersPaginationObj.page,
      offset: newPage && newOffset !== undefined ? newOffset : membersPaginationObj.offset,
    };
    const { ok, data, status, message } = await teamProvider.getMembers(newPaginationObj);
    if (ok) {
      const { items, total } = data as TeamGetAllMembersResponseType;
      setMembers(items);
      setMembersWithTariff(
        items.filter(
          (item) =>
            item.tariffPlan.name === UserTariffPlanEnum.TeamFifty ||
            item.tariffPlan.name === UserTariffPlanEnum.TeamFive ||
            item.tariffPlan.name === UserTariffPlanEnum.TeamTwenty
        )
      );
      setTotalMembers(total);
      setMembersPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  const getRequests = async (newPage?: number, newOffset?: number) => {
    const newPaginationObj = {
      ...requestsPaginationObj,
      page: newPage || requestsPaginationObj.page,
      offset: newPage && newOffset !== undefined ? newOffset : requestsPaginationObj.offset,
    };
    const { ok, data, status, message } = await teamProvider.getRequests(newPaginationObj);
    if (ok) {
      const { items, total } = data as TeamGetAllRequestsResponseType;
      setRequests(items);
      setTotalRequests(total);
      setRequestsPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  useEffect(() => {
    const loadData = async () => {
      getMembers();
      getRequests();
      setIsLoading(false);
    };
    loadData();
  }, []);

  const handleChangeMembersPage = (newPage: number) => {
    const newOffset = (newPage - 1) * COUNT_MEMBERS_PER_PAGE;
    getMembers(newPage, newOffset);
  };

  const handleChangeRequestsPage = (newPage: number) => {
    const newOffset = (newPage - 1) * COUNT_REQUESTS_PER_PAGE;
    getRequests(newPage, newOffset);
  };

  const handleAcceptRequest = async (requestId: string) => {
    if (!requests?.length) {
      return;
    }

    setIsLoading(true);
    const { ok, status, message } = await teamProvider.applyMemberRequest(requestId);
    if (ok) {
      await getRequests();
      await getMembers();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleCancelRequest = async (requestId: string) => {
    if (!requests?.length) {
      return;
    }

    setIsLoading(true);
    const { ok, status, message } = await teamProvider.cancelMemberRequest(requestId);
    if (ok) {
      await getRequests();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!members?.length) {
      return;
    }

    setIsLoading(true);
    const { ok, status, message } = await teamProvider.removeMember(memberId);
    if (ok) {
      await getMembers();
      handleCloseDialogTerminate();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleCloseDialogTerminate = () => setIsDialogTerminateOpened('');

  const countTeamTariffPlan = !isFreeTariffs && tariffPlan?.match(/\d+/);
  const countMembersWithTariff = !isFreeTariffs && membersWithTariff?.length;

  const handleCheckboxChange = (id: string) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleCheckboxChangeWithTariff = (id: string) => {
    setSelectedMembersWithTariff((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const addMemberToTeamTariff = async () => {
    const { ok, data, status, message } = await teamProvider.addMemberToTeamTariff(selectedMembers);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      getMembers();
      setSelectedMembers([]);
    }
  };

  const removeMemberFromTeamTariff = async () => {
    const { ok, data, status, message } =
      await teamProvider.removeMemberFromTeamTariff(selectedMembersWithTariff);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      getMembers();
      setSelectedMembersWithTariff([]);
    }
  };

  return isLoading || !members || !requests ? (
    <Loader />
  ) : (
    <div className={styles.teamContainer}>
      <SectionHeader title='Employee management' />
      <SectionTitle number={1} title='Build Your Team' />
      <InvitationLink />

      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue: number) => setActiveTab(newValue)}
          aria-label='members requests tabs'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-members'
            aria-controls='tabpanel-members'
            label={`My Team${totalMembers > 0 ? ` (${totalMembers})` : ''}`}
            className={styles.tabItem}
          />
          <Tab
            id='tab-requests'
            aria-controls='tabpanel-requests'
            label={`Incoming requests${totalRequests > 0 ? ` (${totalRequests})` : ''}`}
            className={styles.tabItem}
          />
          {!isFreeTariffs && (
            <Tab
              id='tab-payment'
              aria-controls='tabpanel-payment'
              label={`Payment Management (${countMembersWithTariff} / ${countTeamTariffPlan})`}
              className={styles.tabItem}
            />
          )}
        </Tabs>
      </Box>

      <TabPage value={activeTab} index={0}>
        {!members.length ? (
          <EmptyList description='Ready to grow your team? Invite members now and watch it flourish! Start sending invites today.' />
        ) : (
          <div className={styles.list}>
            {members.map((item) => (
              <MemberItem
                key={item.id}
                item={item}
                onRemoveMember={() =>
                  setIsDialogTerminateOpened(`${item.id}${SEPARATOR}${item.name}`)
                }
              />
            ))}
          </div>
        )}
        {!!membersPaginationObj?.total && membersPaginationObj.total > COUNT_MEMBERS_PER_PAGE && (
          <Pagination paginationObj={membersPaginationObj} onChangePage={handleChangeMembersPage} />
        )}
      </TabPage>

      <TabPage value={activeTab} index={1}>
        {!requests.length ? (
          <EmptyList description='No incoming requests yet? Share your invitation link to grow your team!' />
        ) : (
          <div className={styles.list}>
            {requests.map((item) => (
              <RequestItem
                key={item.id}
                item={item}
                onAcceptRequest={() => handleAcceptRequest(item.id)}
                onCancelRequest={() => handleCancelRequest(item.id)}
              />
            ))}
          </div>
        )}
        {!!requestsPaginationObj?.total &&
          requestsPaginationObj.total > COUNT_REQUESTS_PER_PAGE && (
            <Pagination
              paginationObj={requestsPaginationObj}
              onChangePage={handleChangeRequestsPage}
            />
          )}
      </TabPage>

      {!isFreeTariffs && (
        <TabPage value={activeTab} index={2}>
          {!members.length ? (
            <EmptyList description='Ready to grow your team? Invite members now and watch it flourish! Start sending invites today.' />
          ) : (
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems='flex-start'
              sx={{ marginTop: '20px' }}
            >
              <Stack alignItems='flex-start'>
                <Stack direction='column' spacing={2} alignItems='center'>
                  <Stack
                    sx={{
                      marginTop: '20px',
                      border: '1px solid #E9EEF1',
                      borderRadius: '14px',
                      width: '360px',
                      height: '490px',
                      padding: '20px 0',
                      overflow: 'auto',
                    }}
                  >
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, margin: '0 15px' }}>
                      {`All users${totalMembers > 0 ? ` (${totalMembers})` : ''}`}
                    </Typography>
                    <Divider sx={{ border: `1px solid #E9EEF1`, margin: '15px 0' }} />
                    <Stack direction='column' spacing={2}>
                      {members.map((item) => {
                        const isProTariff = item.tariffPlan.name === UserTariffPlanEnum.Pro;
                        return (
                          <Stack direction='row' alignItems='center' key={item.id}>
                            <Checkbox
                              disabled={isProTariff}
                              className={styles.itemCheckbox}
                              icon={<span />}
                              checked={selectedMembers.includes(item.id)}
                              checkedIcon={<span className={styles.checked} />}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  opacity: isProTariff ? '0.5' : '1',
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography sx={{ fontSize: '12px', opacity: '0.5' }}>
                                {item.tariffPlan.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack>
                    <MyButton
                      disabled={selectedMembers.length === 0}
                      data={{
                        buttonName: 'Move to Team',
                        customWidth: '180px',
                        variant: 'contained',
                        buttonType: 'button',
                        buttonsIcon: true,
                        iconPosition: 'end',
                        icon: <IconArrowRight />,
                      }}
                      onClick={() => addMemberToTeamTariff()}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack alignItems='flex-start'>
                <Stack direction='column' spacing={2} alignItems='center'>
                  <Stack
                    sx={{
                      marginTop: '20px',
                      border: '1px solid #E9EEF1',
                      borderRadius: '14px',
                      width: '360px',
                      height: '490px',
                      padding: '20px 0',
                    }}
                  >
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, margin: '0 15px' }}>
                      {`User with tariff (${countMembersWithTariff} / ${countTeamTariffPlan})`}
                    </Typography>
                    <Divider sx={{ border: `1px solid #E9EEF1`, margin: '15px 0' }} />
                    <Stack direction='column' spacing={2}>
                      {membersWithTariff?.map((item) => {
                        return (
                          <Stack direction='row' alignItems='center' key={item.id}>
                            <Checkbox
                              className={styles.itemCheckbox}
                              icon={<span />}
                              checked={selectedMembersWithTariff.includes(item.id)}
                              checkedIcon={<span className={styles.checked} />}
                              onChange={() => handleCheckboxChangeWithTariff(item.id)}
                            />
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography sx={{ fontSize: '12px', opacity: '0.5' }}>
                                {item.tariffPlan.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack>
                    <MyButton
                      disabled={selectedMembersWithTariff.length === 0}
                      data={{
                        buttonName: 'Remove',
                        customWidth: '180px',
                        variant: 'contained',
                        buttonType: 'button',
                        buttonsIcon: true,
                        icon: <IconArrowLeft />,
                      }}
                      onClick={() => removeMemberFromTeamTariff()}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </TabPage>
      )}

      <MyDialog
        open={!!isDialogTerminateOpened}
        onClose={handleCloseDialogTerminate}
        dialogTitle='You are about to remove an employee from your organization'
        width='750'
      >
        <div className={styles.dialogDescription}>
          Are you sure you want to remove{' '}
          <strong>{isDialogTerminateOpened.split(SEPARATOR)[1]}</strong> from your organization?
          Once confirmed, this action cannot be undone.
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => handleRemoveMember(isDialogTerminateOpened.split(SEPARATOR)[0])}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              styleType: 'cancel',
              buttonType: 'button',
            }}
            onClick={handleCloseDialogTerminate}
          />
        </div>
      </MyDialog>
    </div>
  );
};
