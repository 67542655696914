import { Box, TextField } from '@mui/material';
import { MyButton } from '@/components';

import styles from './InvitationLink.module.scss';

export const InvitationLink = ({ linkValue }: { linkValue: string }) => {
  const handleCopyLink = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(linkValue);
    } else {
      document.execCommand('copy', true, linkValue);
    }
  };

  return (
    <div>
      <div className={styles.linkInputBlock}>
        <TextField className={styles.linkInput} placeholder='Your link' value={linkValue} />
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '145px',
            },
          }}
        >
          <MyButton
            data={{
              buttonName: 'Copy',
              styleType: 'button',
              variant: 'contained',
            }}
            onClick={handleCopyLink}
          />
        </Box>
      </div>
    </div>
  );
};
