import { SyntheticEvent, useEffect, useState } from 'react';
import { Tabs, Box, Stack } from '@mui/material';
import ReactPlayer from 'react-player';
import { StyledTab, Title } from './styled';
import {
  createLightSlider,
  LightSlider,
  createLightVideoSlider,
  LightVideoSlider,
} from './LightSlider';

interface TabPanelProps {
  children: any;
  index?: number;
  value?: number;
  isMobile?: boolean;
  onImageClick?: (i: number) => void;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, isMobile, onImageClick, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children && children.length > 0 && (
        <Box sx={{ p: isMobile ? 1 : 3 }}>
          {children?.map((el: any, index: number) => (
            <ReactPlayer
              key={index}
              url={el.fileUrl}
              controls
              width='100%'
              height='auto'
            />
          ))}
        </Box>
      )}
    </div>
  );
};

export const MediaBlock = (props: any) => {
  const { videos, isMobile } = props;
  const [lightboxVideos, setLightboxVideos] = useState<any>(null);

  const handleVideoClick = (index: number) => lightboxVideos?.loadAndOpen(index);

  return (
    <Stack
      alignItems='flex-start'
      spacing={2}
      sx={{
        width: '100%',
        backgroundColor: '#F1F7FF',
        borderRadius: isMobile ? '10px' : '20px',
      }}
    >
      {videos?.length > 0 && (
        <Stack justifyContent='left'>
          <Stack justifyContent='left' sx={{ padding: '15px 0 0 15px', color: '#1650FF' }}>
            <Title>Video</Title>
          </Stack>
          <CustomTabPanel onImageClick={handleVideoClick}>{videos}</CustomTabPanel>
        </Stack>
      )}
    </Stack>
  );
};
