import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Avatar,
  Stack,
  Divider,
  Typography,
  Rating,
  Link,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled, shouldForwardProp } from '@mui/system';
import moment from 'moment';
import { icons } from '../Icons/icons';
import { UserModel } from '@/types';
import { ProfilePicWithCropper } from '../ProfilePicWithCropper';
import { getInitials } from '@/utils/stringAvatar';

interface UserAvatarInfoProps {
  user: UserModel;
  avatarMarginTop?: string;
  type?: 'view' | 'settings' | 'edit' | 'user';
  userType?: string;
  dataQa?: string;
}

interface BaseTextStyleProps {
  editMode: boolean;
  color: string;
  opacity?: number;
}

const baseTextStyle = ({ editMode, color, opacity = 1 }: BaseTextStyleProps) => ({
  fontFamily: '"Poppins", sans-serif',
  fontSize: '16px',
  fontWeight: '400',
  color: editMode ? '#FFFFFF' : color,
  opacity: opacity,
});

const UserName = styled(Typography, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'editMode',
})(({ theme, editMode }: { theme: Theme; editMode: boolean }) => ({
  ...baseTextStyle({ editMode, color: '#2A3842' }),
  fontSize: '18px',
  fontWeight: '600',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    textAlign: 'center',
  },
}));

const UserType = styled(Typography, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'editMode',
})(({ theme, editMode }: { theme: Theme; editMode: boolean }) => ({
  ...baseTextStyle({ editMode, color: '#2A3842', opacity: editMode ? 1 : 0.5 }),
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    textAlign: 'center',
  },
}));

const UserCompany = styled(Typography, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'editMode',
})(({ theme, editMode }: { theme: Theme; editMode: boolean }) => ({
  ...baseTextStyle({ editMode, color: '#1650FF' }),
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    textAlign: 'center',
  },
  ...(!editMode && {
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}));

const UserAvatar = styled(Avatar, {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) && prop !== 'userName' && prop !== 'isEditMode',
})(({ theme, isEditMode, userName }: { theme: Theme; isEditMode: boolean; userName: string }) => ({
  width: 92,
  height: 92,
  fontSize: '30px',
  backgroundColor: stringToColor(userName),
  '& svg': {
    fill: '#ffffff',
    width: '24px',
    height: '24px',
  },
  ...(isEditMode && {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
  }),
  [theme.breakpoints.down('sm')]: {
    width: 64,
    height: 64,
    fontSize: '24px',
  },
}));

const StyledIconButton = styled(IconButton)`
  & svg {
    transition: fill 0.1s;
  }

  &:hover {
    & svg {
      fill: '#000000' !important;
    }
  }
`;

const DIVIDER_STYLE = { backgroundColor: '#FFFFFF', marginTop: 0 };

const stringToColor = (string: string): string => {
  const hash = string.split('').reduce((acc, char) => {
    let h = char.charCodeAt(0) + ((acc << 5) - acc);
    return h & h;
  }, 0);
  const color = [0, 1, 2]
    .map((i) => {
      const value = (hash >> (i * 8)) & 0xff;
      return `00${value.toString(16)}`.slice(-2);
    })
    .join('');
  return `#${color}`;
};

const UserAvatarInfo: FC<UserAvatarInfoProps> = ({
  user,
  avatarMarginTop,
  type,
  userType,
  dataQa,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const isEditMode = type === 'edit';
  const isCommunicate = type === 'user';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const date = user?.lastLoginAt
    ? moment(user?.lastLoginAt).format('MMMM D, YYYY, h:mm A')
    : moment(user?.updatedAt).format('MMMM D, YYYY, h:mm A');
  const employer = user.employer;

  return (
    <>
      <Grid
        sx={{
          marginTop: avatarMarginTop,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: isCommunicate ? '10px' : '25px',
          },
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems='center'
          spacing={2}
          sx={{
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            spacing={2}
            style={{ position: 'relative' }}
            data-qa={`${dataQa}_avatar`}
          >
            <UserAvatar
              theme={theme}
              userName={user?.name}
              isEditMode={isEditMode}
              alt={user?.name}
              src={user?.media?.[0]?.fileUrl}
            >
              {user && user?.name && getInitials(user?.name)}
            </UserAvatar>
            {isEditMode && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '33%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 100,
                }}
              >
                <StyledIconButton aria-label='edit' onClick={() => setOpenDialog(true)}>
                  <icons.EditProfile style={{ width: '24', height: '24', color: '#FFF' }} />
                </StyledIconButton>
              </div>
            )}
          </Stack>
          <Stack spacing={0} data-qa={`${dataQa}_credential_block`}>
            <UserName
              theme={theme}
              editMode={isEditMode}
              data-qa={`${dataQa}_credential_block_name`}
            >
              {user?.name}
            </UserName>
            {user?.kind === 'broker' || user?.kind === 'manager' ? (
              <Stack
                direction='column'
                divider={
                  employer && (
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={isEditMode ? DIVIDER_STYLE : undefined}
                    />
                  )
                }
                spacing={0}
              >
                <UserType
                  theme={theme}
                  editMode={isEditMode}
                  data-qa={`${dataQa}_credential_block_kind`}
                >
                  {userType}
                </UserType>
                {isEditMode ? (
                  <UserCompany
                    theme={theme}
                    editMode={isEditMode}
                    data-qa={`${dataQa}_credential_block_employer`}
                  >
                    {user?.employer?.name}
                  </UserCompany>
                ) : (
                  <Link
                    href={`/users/${user?.employer?.id}`}
                    title={user?.employer?.name}
                    style={{ textDecoration: 'none' }}
                  >
                    <UserCompany
                      theme={theme}
                      editMode={isEditMode}
                      data-qa={`${dataQa}_credential_block_employer`}
                    >
                      {user?.employer?.name}
                    </UserCompany>
                  </Link>
                )}
              </Stack>
            ) : (
              <Stack>
                <UserType
                  theme={theme}
                  editMode={isEditMode}
                  data-qa={`${dataQa}_credential_block_kind`}
                >
                  {userType}
                </UserType>
              </Stack>
            )}
            {isCommunicate && (
              <Stack
                direction='row'
                spacing={1}
                sx={{ ...(isMobile ? { margin: 'auto' } : {}) }}
                data-qa={`${dataQa}_credential_block_last_seen`}
              >
                <Typography sx={{ fontSize: isMobile ? '14px' : '16px' }}>Last seen:</Typography>

                <UserType theme={theme} editMode={isEditMode}>
                  {date}
                </UserType>
              </Stack>
            ) 
            // : (
            //   <Stack
            //     direction='row'
            //     spacing={1}
            //     sx={{ ...(isMobile ? { margin: 'auto' } : {}) }}
            //     data-qa={`${dataQa}_credential_block_tariff`}
            //     divider={
            //       <Divider
            //         orientation='vertical'
            //         flexItem
            //         style={isEditMode ? DIVIDER_STYLE : undefined}
            //       />
            //     }
            //   >
            //     <Typography sx={{ fontSize: isMobile ? '12px' : '14px', color: '#1650ff' }}>Team</Typography>

            //     <Typography sx={{ fontSize: isMobile ? '12px' : '14px', color: '#2a3842', opacity: 0.5 }}>
            //       {`Active: 365 days`}
            //     </Typography>
            //   </Stack>
            // )
            }
          </Stack>
        </Stack>
      </Grid>

      <ProfilePicWithCropper open={openDialog} onClose={() => setOpenDialog(false)} user={user} />
    </>
  );
};

export default UserAvatarInfo;
