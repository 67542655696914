const stringToColor = (string: string) => {
  let hash = 0;

  string.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  const color = [0, 1, 2]
    .map((i) => {
      const value = (hash >> (i * 8)) & 0xff;
      return `00${value.toString(16)}`.slice(-2);
    })
    .join('');

  return `#${color}`;
};

export const stringAvatar = (name: string = ' ') => {
  const names = name.split(' ');
  const initials = `${names[0][0]}${names[1] ? names[1][0] : ''}`;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
};

export const getInitials = (name: string = '', numLetters: number = 2) => {
  return name
    .split(' ')
    .map((item) => item[0])
    .join('')
    .substring(0, numLetters)
    .toUpperCase();
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
