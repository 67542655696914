import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PdfSettings } from './PdfSettings';
import { useAdData } from './useAdsData';
import { Loader, LoaderOverlay } from '@/components';
import { AdMediaType, FileKindEnum, State, UserKindEnum, PropertyKindEnum } from '@/types';
import { useSelector } from 'react-redux';
import { GeneratePDF } from './PdfDocument/GeneratePDF';
import { pdf } from '@react-pdf/renderer';
import { useUserData } from '@/components/PdfManager/useUserData';
import { convertToPNG } from '@/components/uiComponents/FileUploader/FileUploader.utils';

type IPdfTemplateImagesRules = {
  name: string;
  minHeaderPicsCount: number;
  bestHeaderPicsCount: number;
};

const pdfTemplateImagesRules: IPdfTemplateImagesRules[] = [
  { name: 'horizontal-view', minHeaderPicsCount: 1, bestHeaderPicsCount: 1 },
  { name: 'vertical-view', minHeaderPicsCount: 1, bestHeaderPicsCount: 1 },
];

const selectHeaderImages = (
  minHeaderPicsCount: number,
  bestHeaderPicsCount: number,
  images: AdMediaType[],
  selectedImages: AdMediaType[]
): AdMediaType[] => {
  let newSelectedImages = [...selectedImages];
  if (newSelectedImages.length < minHeaderPicsCount) {
    const imagesToAddCount =
      images.length >= bestHeaderPicsCount
        ? bestHeaderPicsCount - newSelectedImages.length
        : minHeaderPicsCount - newSelectedImages.length;

    const filteredImages = images.filter(
      (image) => !newSelectedImages.some((selectedImage) => selectedImage.id === image.id)
    );

    newSelectedImages.push(...filteredImages.slice(0, imagesToAddCount));
  }

  return newSelectedImages;
};

export const AdsPdfManager = ({ id }: { id: string }) => {
  const user = useSelector(({ auth }: State) => auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showMyContactsCheckbox, setShowMyContactsCheckbox] = useState(true);
  const [currency, setCurrency] = useState('AED');
  const [images, setImages] = useState<AdMediaType[]>([]);
  const [convertedImages, setConvertedImages] = useState<string[]>([]);
  const [isViewPdfVisible, setIsViewPdfVisible] = useState(false);
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);

  const { adsData } = useAdData(id, currency, true);
  const { userData: currentAgencyInfo } = useUserData(user.employer?.id);
  const propertyKind = adsData?.propertyKind;
  const propertyKindsToShowFloors = [
    PropertyKindEnum.Villa,
    PropertyKindEnum.VillaCompound,
    PropertyKindEnum.CommercialVilla,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.CommercialBuilding,
  ];

  const isNumberOfFloorsShown =
    propertyKind !== undefined && propertyKindsToShowFloors.includes(propertyKind);

  useEffect(() => {
    if (images?.length) return;
    const photo = adsData?.propertyAdMedia.filter((item) => item.kind === FileKindEnum.Image);
    setImages(photo || []);
  }, [adsData?.propertyAdMedia]);

  const convertImages = async (images: AdMediaType[]) => {
    const promises = images.map(async (image) => {
      if (image.fileUrl) {
        try {
          const response = await fetch(image.fileUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
          const pngFile = await convertToPNG(file);
          if (pngFile) {
            return URL.createObjectURL(pngFile);
          }
        } catch (error) {
          console.error('Error converting image to PNG:', error);
        }
      }
      return null;
    });

    const results = await Promise.all(promises);
    const convertedPhoto = results.filter((url) => url !== null) as string[]
    setConvertedImages(convertedPhoto);
    return convertedPhoto;
  };

  useEffect(() => {
    if (images.length) {
      convertImages(images);
    }
  }, [images]);

  useEffect(() => {
    if (!user || !adsData) return;

    if (user.kind === UserKindEnum.Agency) {
      setShowMyContactsCheckbox(false);
    }
  }, [user, adsData]);

  if (!adsData || !convertedImages.length) return <Loader />;
  return (
    <div style={{ width: '100%' }}>
      {isLoading && <LoaderOverlay />}
      <PdfSettings
        setCurrency={setCurrency}
        currency={currency}
        showMyContactsCheckbox={showMyContactsCheckbox}
        images={images} // передаем сконвертированные изображения
        pdfFile={pdfFile}
        isViewPdfVisible={isViewPdfVisible}
        hideViewPdf={() => setIsViewPdfVisible(false)}
        onSubmit={async (data: any) => {
          if (['AED', 'USD'].includes(data?.currency)) {
            setCurrency(data?.currency);
          }
          setIsLoading(true);
          const selectedImages = images.filter((item) => data[`image-${item.id}`]);
          const selectedImagesUrl = await convertImages(selectedImages);
          const templateName = data.radioOption.toString();
          let templateRule = pdfTemplateImagesRules.find((el) => el.name === templateName);

          if (!templateRule) {
            templateRule = {
              name: 'stub',
              minHeaderPicsCount: 1,
              bestHeaderPicsCount: 1,
            };
            return;
          }

          const { minHeaderPicsCount, bestHeaderPicsCount } = templateRule;
          const tImages = selectHeaderImages(
            minHeaderPicsCount,
            bestHeaderPicsCount,
            images,
            selectedImages
          );

          const blob = await pdf(
            <GeneratePDF
              templateName={templateName}
              adsData={adsData}
              images={selectedImagesUrl}
              personalInfo={user}
              currentAgencyInfo={currentAgencyInfo}
              isNumberOfFloorsShown={isNumberOfFloorsShown}
              showContent={{
                images: tImages,
                isAgencyInformationVisible: data.agencyDetailsShow,
                isBrokersInformationVisible: showMyContactsCheckbox
                  ? data.brokerDetailsShow
                  : data.agencyDetailsShow,
                showPrice: data.showPrice,
              }}
            />
          ).toBlob();
          const res = await documentSave(blob, `${adsData.fakeId}`, setPdfFile);
          setIsViewPdfVisible(true);
          if (res) setIsLoading(false);
        }}
      />
    </div>
  );
};

export async function documentSave(blob: Blob, documentName: string, setPdfFile: Dispatch<SetStateAction<Blob | null>>): Promise<string> {
  try {
    const objectUrl = URL.createObjectURL(blob);
    setPdfFile(blob);
    const fileExtension = blob.type.split('/')[1];

    const fileName = `${documentName}.${fileExtension}`
      .replace(/\s/g, '_')
      .replace(/[^a-zA-Z0-9_.]/g, '');

    const link = window.document.createElement('a');
    link.href = objectUrl;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();
    link.remove();

    URL.revokeObjectURL(objectUrl);

    return 'ok';
  } catch (error) {
    console.error('Error in documentSave:', error);
    return 'error';
  }
}

export type SelectedDataType = {
  images?: AdMediaType[];
  isAgencyInformationVisible?: boolean;
  isBrokersInformationVisible?: boolean;
  showPrice?: boolean;
};
