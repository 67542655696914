/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { SelectItemButton } from './SelectItemButton';
import classNames from 'classnames';
import styles from './DoubleDeckerListsSelect.module.scss';
import { ReactComponent as CaretDown } from './CaretDown.svg';

interface DataList {
  label: string;
  value: any;
}

interface IDoubleDeckerListsSelectProps {
  placeholder: string;
  firstStageLabel: string;
  firstStageData: DataList[];
  secondStageLabel: string;
  secondStageData: DataList[];
  defaultFirstValue?: string;
  defaultSecondValue?: string;
  disabled?: boolean;
  handleChange?: (value: any) => void;
}

export const DoubleDeckerListsSelect = ({
  placeholder,
  firstStageLabel,
  firstStageData,
  secondStageLabel,
  secondStageData,
  defaultFirstValue,
  defaultSecondValue,
  disabled = false,
  handleChange,
}: IDoubleDeckerListsSelectProps) => {
  const [stageValues, setStageValues] = useState<{
    firstSelectedValue?: string;
    secondSelectedValue?: string;
  }>({
    firstSelectedValue: defaultFirstValue,
    secondSelectedValue: defaultSecondValue,
  });
  const [newPlaceholder, setNewPlaceholder] = useState<string | undefined>(placeholder);

  useEffect(() => {
    setStageValues({
      firstSelectedValue: defaultFirstValue,
      secondSelectedValue: defaultSecondValue,
    });
  }, [defaultFirstValue, defaultSecondValue]);

  useEffect(() => {
    let tPlaceholder = '';
    const firstStageChoice = firstStageData.find(
      (el) => el.value === stageValues.firstSelectedValue
    )?.label;

    const secondStageChoice = secondStageData.find(
      (el) => el.value === stageValues.secondSelectedValue
    )?.label;

    if (stageValues.firstSelectedValue && stageValues.secondSelectedValue) {
      tPlaceholder = `${firstStageChoice} / ${secondStageChoice}`;
    } else if (stageValues.firstSelectedValue) {
      tPlaceholder = `${firstStageChoice}`;
    } else if (stageValues.secondSelectedValue) {
      tPlaceholder = `${secondStageChoice}`;
    }

    setNewPlaceholder(tPlaceholder);
  }, [stageValues]);

  const handleFirstStageChange = (newValue: string) => {
    setStageValues((prev) => ({
      ...prev,
      firstSelectedValue: prev.firstSelectedValue === newValue ? undefined : newValue,
    }));
  };

  const handleSecondStageChange = (newValue: string) => {
    setStageValues((prev) => ({
      ...prev,
      secondSelectedValue: prev.secondSelectedValue === newValue ? undefined : newValue,
    }));
  };

  return (
    <Select
      value={'-1'}
      disabled={disabled}
      IconComponent={CaretDownComponent}
      onClose={() =>
        handleChange &&
        handleChange({
          firstSelectedValue: stageValues.firstSelectedValue,
          secondSelectedValue: stageValues.secondSelectedValue,
        })
      }
      sx={{
        width: '100%',
        '& .Mui-focused': {
          border: '1px solid rgb(233, 238, 241)',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(233, 238, 241) !important',
        },
      }}
    >
      <MenuItem
        sx={{
          display: 'none',
          '&&.Mui-focusVisible': {
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
          },
        }}
        hidden
        key={1}
        value={'-1'}
      >
        {newPlaceholder || placeholder}
      </MenuItem>
      <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
        <FormHelperText>
          <span className={styles.selectInnerTitle}>{firstStageLabel}</span>
        </FormHelperText>
        <div className={styles.selectInnerContent}>
          {firstStageData.map(({ label, value }) => (
            <SelectItemButton
              key={value}
              label={label}
              value={value}
              currentValue={stageValues.firstSelectedValue}
              onChange={handleFirstStageChange}
            />
          ))}
        </div>
        <FormHelperText>
          <span className={styles.selectInnerTitle}>{secondStageLabel}</span>
        </FormHelperText>
        <div className={styles.selectInnerContent}>
          {secondStageData.map(({ label, value }) => (
            <SelectItemButton
              key={value}
              label={label}
              value={value}
              currentValue={stageValues.secondSelectedValue}
              onChange={handleSecondStageChange}
            />
          ))}
        </div>
      </div>
    </Select>
  );
};

const CaretDownComponent = (props: any) => {
  const rotate = props.className.includes('iconOpen');
  return (
    <div
      className={styles.caretDown}
      style={{
        transform: rotate ? 'rotate(180deg)' : 'none',
      }}
    >
      <CaretDown />
    </div>
  );
};
