import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, TextField } from '@mui/material';
import { commentsProvider } from '@/providers';
import { MyButton } from '@/components';
import { NotificationTypeEnum, UserModel, HeaderDialogsEnum, UserTariffPlanEnum } from '@/types';
import { appSetNotification } from '@/store';

export const WriteComment = ({
  id,
  setNewComment,
  type,
  setIsVisibleAnswer,
  message,
  idComment,
  user,
  handleClickOpenDialog,
}: {
  id: string;
  setNewComment: (comment: string) => void;
  type: string;
  setIsVisibleAnswer?: (isVisible: boolean) => void;
  message?: string;
  idComment?: string;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const [commentValue, setCommentValue] = useState(type === 'edit' ? (message ? message : '') : '');
  const dispatch = useDispatch();
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const onHandlerCreateComment = async (buttonName: string) => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    if (commentValue.length > 0) {
      if (buttonName === 'create') {
        const { ok, message, status } = await commentsProvider.createComment(id, commentValue);
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        } else {
          setNewComment(commentValue);
        }
      }
      if (buttonName === 'edit' && idComment) {
        const { ok, message, status } = await commentsProvider.editComment(
          id,
          idComment,
          commentValue
        );
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        } else {
          setIsVisibleAnswer && setIsVisibleAnswer(false);
          setNewComment(commentValue);
        }
      }
      if (buttonName === 'answer' && idComment) {
        const { ok, message, status } = await commentsProvider.replyComment(
          id,
          idComment,
          commentValue
        );
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        } else {
          setIsVisibleAnswer && setIsVisibleAnswer(false);
          setNewComment(commentValue);
        }
      }
    }
  };

  const onHandlerCancel = () => {
    setNewComment('');
    setIsVisibleAnswer && setIsVisibleAnswer(false);
  };

  const buttonName = type === 'create' ? 'Comment' : type === 'answer' ? 'Answer' : 'Edit';

  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='flex-start'
      spacing={2}
      sx={{ flexWrap: 'wrap' }}
    >
      <TextField
        name='comment'
        multiline
        fullWidth
        variant='outlined'
        placeholder='Enter text'
        inputProps={{ maxLength: 1600 }}
        minRows='4'
        rows={4}
        sx={{ width: '100%' }}
        onChange={(e) => setCommentValue(e.target.value)}
        value={commentValue}
      />
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={5}
        sx={{ flexWrap: 'wrap' }}
      >
        <MyButton
          data={{
            buttonName: buttonName,
            variant: 'contained',
            customWidth: '156px',
            styleType: 'OP_CHAT',
          }}
          onClick={() => onHandlerCreateComment(type)}
        />
        {type !== 'create' && (
          <MyButton
            data={{
              buttonName: 'Cancel',
              variant: 'contained',
              customWidth: '156px',
              styleType: 'unread',
            }}
            onClick={() => onHandlerCancel()}
          />
        )}
      </Stack>
    </Stack>
  );
};
