import {
  XmlHistoryGetAllResponseType,
} from '@/types';
import { apiClient } from '@/providers';

export const xmlUploadProvider = {
  getHistory: async ( {limit, offset}: {limit: number, offset: number} ) => {
    const response = await apiClient(`/import/history/search`, 'POST', {
      limit,
      offset,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as XmlHistoryGetAllResponseType;
    return { ...response, data };
  },
};
