import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, NotificationTypeEnum } from '@/types';
import { propertyAdRequestProvider } from '@/providers';
import { appSetNotification, appGetRequestCount } from '@/store';

export const useUnreadRequestCount = (shouldRun: boolean) => {
  const dispatch = useDispatch();
  const { app: appState } = useSelector((state: State) => state);

  useEffect(() => {
    const getCountOfUnread = async () => {
      const { ok, data, status, message } = await propertyAdRequestProvider.getAllUnreadCount();
      if (ok) {
        dispatch(appGetRequestCount(data as number));
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };

    if (appState.countOfUnread === null && shouldRun) {
      getCountOfUnread();
    }
  }, [dispatch, appState.countOfUnread]);

  return { appState };
};
