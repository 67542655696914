import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Divider } from '@mui/material';
import { commentsProvider } from '@/providers';
import { NotificationTypeEnum, CommentModel, CommentsResponseType, UserModel, HeaderDialogsEnum } from '@/types';
import { appSetNotification } from '@/store';
import { WriteComment } from './WriteComment';
import { CommentBlock } from './CommentBlock';
import { Loader } from '@/components';

export const CommentItem = ({
  comment,
  setLike,
  setNewComment,
  setOpenCommentReportDialog,
  id,
  user,
  handleClickOpenDialog,
}: {
  comment: CommentModel;
  setLike: (like: string) => void;
  setNewComment: (comment: string) => void;
  setOpenCommentReportDialog: (open: boolean) => void;
  id: string;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const [isVisibleAnswer, setIsVisibleAnswer] = useState(false);
  const [buttonType, setButtonType] = useState('');
  const [isReplay, setIsReplay] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [repliesComments, setRepliesComments] = useState<CommentModel[]>([]);
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  const getRepliesCommentsData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await commentsProvider.getRepliesComments(comment.id!);
    if (ok && data) {
      const { items, total } = data as CommentsResponseType;
      if (items && total) {
        setRepliesComments(items);
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      getRepliesCommentsData();
    } else {
      isMounted.current = true;
    }
  }, [isReplay]);

  return (
    <>
      <CommentBlock
        comment={comment}
        setLike={setLike}
        setNewComment={setNewComment}
        id={id}
        setOpenCommentReportDialog={setOpenCommentReportDialog}
        setIsVisibleAnswer={setIsVisibleAnswer}
        setButtonType={setButtonType}
        isReplay={isReplay}
        setIsReplay={setIsReplay}
        type='comment'
        user={user}
        handleClickOpenDialog={handleClickOpenDialog}
      />
      {isReplay &&
        (isLoading ? (
          <Loader />
        ) : (
          <Stack direction='row' spacing={2}>
            <Divider orientation='vertical' flexItem sx={{ border: `1px solid #E9EEF1` }} />
            <Stack spacing={0.5} sx={{ width: '100%' }}>
              {repliesComments.map((repliesComment) => {
                return (
                  <CommentBlock
                    key={repliesComment.id}
                    comment={repliesComment}
                    setLike={setLike}
                    setNewComment={setNewComment}
                    id={id}
                    setOpenCommentReportDialog={setOpenCommentReportDialog}
                    setIsVisibleAnswer={setIsVisibleAnswer}
                    setButtonType={setButtonType}
                    isReplay={isReplay}
                    setIsReplay={setIsReplay}
                    type='commentReplies'
                    buttonType={buttonType}
                    parentCommentId={comment.id}
                    user={user}
                    handleClickOpenDialog={handleClickOpenDialog}
                  />
                );
              })}
            </Stack>
          </Stack>
        ))}
      {isVisibleAnswer && (
        <WriteComment
          id={id}
          setNewComment={setNewComment}
          type={buttonType}
          setIsVisibleAnswer={setIsVisibleAnswer}
          message={comment.message}
          idComment={comment.id}
          user={user}
          handleClickOpenDialog={handleClickOpenDialog}
        />
      )}
      {/* {description.length > 400 && (
          <StyledButton onClick={handleToggle}>
            {isExpanded ? 'Read less' : 'Read more'}
          </StyledButton>
        )} */}
    </>
  );
};
