import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum, State } from '@/types';
import { Grid } from '@mui/material';
import { usersProvider } from '@/providers';
import MainInfo from '../ViewProfile/MainInfo';
import SettingsInfo from './SettingsInfo';
import { dialogUserTypeData } from '../../utils/dialogDescription';
import { getKindLabel } from '@/utils';
import { CommunicationMethodDialog } from './CommunicationMethodDialog';
import styles from './Settings.module.scss';

const Settings = () => {
  const [userSettings, setUserSettings] = useState({
    email: '',
    phone: '',
    currency: '',
    areaUnits: '',
    sendCommentEmails: true,
    sendMessageEmails: true,
    sendClientRequestEmails: true,
    sendTimersEmails: true,
    sendCometchatEmails: true,
    sendFriendRequestEmails: true,
    chosenContactMethod: false,
  });
  const [isLoginChanged, setIsLoginChanged] = useState<boolean>(false);
  const [openCommunicationDialog, setOpenCommunicationDialog] = useState<boolean>(false);

  const user = useSelector(({ auth }: State) => auth.user);
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserSettingsData = async () => {
      const { ok, data, status, message } = await usersProvider.getUserSettings();
      if (ok && data) {
        setUserSettings((prevState) => ({
          ...prevState,
          email: data.email ?? '',
          phone: data.phone ?? '',
          currency: data.currency ?? '',
          areaUnits: data.areaUnits ?? '',
          sendCommentEmails: data?.sendCommentEmails ?? true,
          sendMessageEmails: data?.sendMessageEmails ?? true,
          sendClientRequestEmails: data?.sendClientRequestEmails ?? true,
          sendTimersEmails: data?.sendTimersEmails ?? true,
          sendCometchatEmails: data?.sendCometchatEmails ?? true,
          sendFriendRequestEmails: data?.sendFriendRequestEmails ?? true,
          chosenContactMethod: data?.chosenContactMethod ?? false,
        }));
        const isPopupVisible = !data.chosenContactMethod;
        setOpenCommunicationDialog(isPopupVisible);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };
    if (isLoggedIn) {
      getUserSettingsData();
    }
  }, [isLoginChanged]);

  const userType = user.kind ? getKindLabel(user.kind) : '';

  return (
    <>
      <Grid className={styles.container}>
        <MainInfo user={user} userType={userType} type='settings' />
        <SettingsInfo
          userSettings={userSettings}
          user={user}
          isLoginChanged={isLoginChanged}
          setIsLoginChanged={setIsLoginChanged}
        />
      </Grid>
      <CommunicationMethodDialog
        open={openCommunicationDialog}
        onClose={() => setOpenCommunicationDialog(false)}
      />
    </>
  );
};

export default Settings;
