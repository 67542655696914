import ReactPDF, { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { PaymentPlanSection } from './PaymentPlanSection';

import {
  LocationWhiteIcon,
  ParkingBlue,
  FurnishingBlue,
  CalendarBlue,
  FloorBlue,
  BuildingsBlue,
  PaintRollerBlue,
} from '@/assets/pdf';

import { ContactSection } from './ContactSection';
import { TopGallery } from './TopGallery';
import { UnitsAddressIconText } from './UnitsAdressIconText';
import { UnitCard } from './UnitCard';
import { IUnitPdfDocument } from '@/types/pdf';
import { IUnit } from '@/types/offplans';
type GroupedUnits = {
  [key: string]: IUnit[];
};

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#1650FF',
    marginBottom: 64,
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2A3842',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 56,
  },
  pageTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 50,
  },
  characteristicsContainer: {
    backgroundColor: '#F1F7FF',
    padding: '24px',
    borderRadius: '8px',
    width: '48vw',
  },
  characteristicsMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  characteristicsInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  characteristicsText: {
    color: '#2A3842',
    opacity: 0.5,
  },
});

export function UnitsPdfDocument({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  units,
}: IUnitPdfDocument) {
  const groupAndSortUnits = (units: IUnit[] | undefined) => {
    if (!units) return {};

    const groupedUnits = units.reduce<GroupedUnits>((acc, unit) => {
      const beds = unit.beds;
      if (!acc[beds]) {
        acc[beds] = [];
      }
      acc[beds].push(unit);
      return acc;
    }, {});

    Object.keys(groupedUnits).forEach((beds) => {
      groupedUnits[beds].sort((a, b) => a.price - b.price);
    });

    return groupedUnits;
  };

  const characteristics = [
    {
      name: 'Developent status',
      value: offplanData?.developmentStatus ? splitAndReformat(offplanData?.developmentStatus) : null,
      icon: PaintRollerBlue,
    },
    {
      name: 'Property type',
      value: offplanData?.constructionType ? splitAndReformat(offplanData?.constructionType) : null,
      icon: BuildingsBlue,
    },
    {
      name: 'Floor',
      value: offplanData?.numberOfFloors,
      icon: FloorBlue,
    },
    {
      name: 'Handover',
      value: offplanData?.handover,
      icon: CalendarBlue,
    },
    {
      name: 'Furnishing',
      value: offplanData?.furnishing ? 'Yes' : 'No',
      icon: FurnishingBlue,
    },
    {
      name: 'Parking',
      value: offplanData?.coveredParking ? 'Yes' : 'No',
      icon: ParkingBlue,
    },
  ];

  const paymentPlan = offplanData?.paymentPlan;
  const splitPoint = Math.ceil(characteristics.length / 2);

  const firstHalf = characteristics.slice(0, splitPoint);
  const secondHalf = characteristics.slice(splitPoint);

  const renderCharacteristics = (chars: any[]) =>
    chars.map((character, index, arr) => (
      <View key={character.name} style={styles.characteristicsContainer}>
        <View style={styles.characteristicsInnerContainer}>
          <IconText iconSrc={character.icon}>{character.name}</IconText>
          <Text style={styles.characteristicsText}> {character.value}</Text>
        </View>
        {arr.length > index + 1 && <Viewider />}
      </View>
    ));

  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
            <TopGallery wrap={true} images={showContent.images} />
            <View>
              <UnitsAddressIconText iconSrc={LocationWhiteIcon}>
                {offplanData?.nameEn}
              </UnitsAddressIconText>
              <View style={styles.pageTitleWrapper}>
                <Text style={styles.pageTitle}>{offplanData?.title}</Text>
              </View>
            </View>

            <View style={styles.characteristicsMainContainer}>
              <View>{renderCharacteristics(firstHalf)}</View>
              <View>{renderCharacteristics(secondHalf)}</View>
            </View>
            {Object.entries(groupAndSortUnits(units)).map(([beds, units]) => (
              <View key={beds}>
                <Text
                  style={{
                    fontSize: 68,
                    fontWeight: 'bold',
                    color: '#1650FF',
                    marginVertical: 10,
                  }}
                >
                  {beds} Room unit
                </Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
                  {units.map((unit) => (
                    <UnitCard key={unit.id} unit={unit} offplanData={offplanData} showPrice={showContent.showPrice} />
                  ))}
                </View>
              </View>
            ))}
            <Section wrap={true}>
              <Text>{offplanData?.description}</Text>
            </Section>
          </View>
          <FeaturesSection data={offplanData} />
          {paymentPlan ? <PaymentPlanSection paymentPlan={paymentPlan} /> : null}

          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
                      
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
}

const Viewider = () => (
  <View
    style={{
      width: '100%',
      height: 2,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);

export function splitAndReformat(str: string) {
  const separatedString = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  const words = separatedString.trim().split(/\s+/);
  const reformedWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word.charAt(0).toLowerCase() + word.slice(1);
  });
  return reformedWords.join(' ');
}
