/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Link, Avatar, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  ActivityKindEnum,
  RentPeriodEnum,
  RequestMyModel,
  AdsFilteringType,
  PropertyKindEnum,
  TransactionTypeEnum,
} from '@/types';
import { MyButton } from '@/components';
import { priceFormat, stringAvatar } from '@/utils';

import styles from './RequestsMyListItem.module.scss';
import IconMarker from './svg/iconMarker.svg';
import IconRequest from './svg/iconRequest.svg';
import IconRuler from './svg/iconRuler.svg';
import IconBed from './svg/iconBed.svg';
import IconShower from './svg/iconShower.svg';
import { ReactComponent as IconPhone } from './svg/iconPhone.svg';
import { ReactComponent as IconTelegram } from './svg/iconTelegram.svg';
import { ReactComponent as IconWhatsapp } from './svg/iconWhatsapp.svg';
import { ReactComponent as IconChat } from './svg/ChatText.svg';
import { Term, SmallShell } from '../Ad/styled';
import IconTimer from './svg/iconTimer.svg';
import IconTimerRed from './svg/iconTimerRed.svg';

interface RequestsMyListItemProps {
  item: RequestMyModel;
  btnReqForMe: boolean;
  filteringObj?: AdsFilteringType;
}

type PreferredPropertyKinds =
  | 'shop'
  | 'office'
  | 'warehouse'
  | 'residentialLand'
  | 'industrialLand'
  | 'commercialLand';

export const RequestsMyListItem = ({
  item: {
    activityKind,
    addressEn,
    areaUnits,
    baths,
    beds,
    convertedArea,
    convertedCurrency,
    convertedPrice,
    coordinatesLat,
    coordinatesLong,
    currency,
    id,
    lastRequestDate,
    nameEn,
    price,
    propertyAdCreator,
    propertyAdCreatorEmployer,
    propertyAdMedia,
    rentFrequency,
    readCount,
    title,
    unreadCount,
    plotArea,
    convertedAreaUnits,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    defaultRentFrequency,
    endOfPublishDayLeft,
    sellingPrice,
    multilisting,
    propertyKind,
    convertedSellingPrice,
    rentedTill,
    transactionType,
  },
  btnReqForMe,
  filteringObj,
}: RequestsMyListItemProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const activityKindLabels = {
    [ActivityKindEnum.Sale]: 'Sale',
    [ActivityKindEnum.Rent]: 'Rent',
  };
  const rentFrequencyLabels = {
    [RentPeriodEnum.Yearly]: 'Yearly',
    [RentPeriodEnum.Monthly]: 'Monthly',
    [RentPeriodEnum.Weekly]: 'Weekly',
    [RentPeriodEnum.Daily]: 'Daily',
  };

  const preferredPropertyKinds = [
    PropertyKindEnum.Shop,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ];
  const propertyKindLabel: Record<PreferredPropertyKinds, string> = {
    shop: 'Shop',
    office: 'Office',
    warehouse: 'Warehouse',
    residentialLand: 'Residential Land',
    industrialLand: 'Industrial Land',
    commercialLand: 'Commercial Land',
  };
  const isPrefTitle = preferredPropertyKinds.includes(propertyKind);
  const titlePref = propertyKind && propertyKindLabel[propertyKind as PreferredPropertyKinds];
  const titleText = isPrefTitle ? `${titlePref} at ${nameEn}` : nameEn;

  const activityLabel = activityKindLabels[activityKind];

  const requestsCount = `${unreadCount + readCount} / ${unreadCount}`;

  const capitalizeFirstLetter = (string: string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleOpChatBtnClick = () => {
    navigate(`/chat/${propertyAdCreator?.chatId}`);
  };
  const handlePhoneBtnClick = () => {
    window.location.href = `tel:${propertyAdCreator?.additionalPhones?.[0]}`;
  };
  const handleTelegramBtnClick = () => {
    window.open(`https://t.me/${propertyAdCreator?.telegram}`, '_blank');
  };
  const handleWhatsappBtnClick = () => {
    window.open(`https://wa.me/${propertyAdCreator?.whatsapp}`, '_blank');
  };

  const textBeds = beds === 'Studio' ? '' : Number(beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  const convertedPlotArea = plotArea
    ? convertedAreaUnits === 'SQFT'
      ? plotArea
      : Math.round(Number(plotArea) / 10.764)
    : 0;
  const aria = convertedArea ? convertedArea : plotArea ? convertedPlotArea : 0;

  const isSale = activityKind !== ActivityKindEnum.Rent;
  const filterRentFrequency = filteringObj?.rentFrequency;

  let newPrice = 0;
  if (isSale) {
    if (transactionType === TransactionTypeEnum.Distress) {
      newPrice = convertedSellingPrice || 0;
    } else {
      newPrice = convertedPrice || 0;
    }
  } else {
    const rentFrequency = filterRentFrequency || defaultRentFrequency;
    switch (rentFrequency) {
      case RentPeriodEnum.Yearly:
        newPrice = convertedPriceYearly || 0;
        break;
      case RentPeriodEnum.Monthly:
        newPrice = convertedPriceMonthly || 0;
        break;
      case RentPeriodEnum.Weekly:
        newPrice = convertedPriceWeekly || 0;
        break;
      case RentPeriodEnum.Daily:
        newPrice = convertedPriceDaily || 0;
        break;
    }
  }

  const imgUrl = isMobile
    ? `url('${propertyAdMedia?.fileUrl}_382x133')`
    : btnReqForMe
      ? `url('${propertyAdMedia?.fileUrl}_208x130')`
      : `url('${propertyAdMedia?.fileUrl}_200x160')`;

  return (
    <div
      className={`${styles.listItem} ${unreadCount > 0 ? styles.listItemUnread : styles.listItemRead}`}
    >
      <div
        className={`${styles.listItemImage} ${btnReqForMe ? styles.listItemImageForMe : styles.listItemImageByMe}`}
        style={{
          backgroundImage: propertyAdMedia ? `${imgUrl}` : `url('/Img/newLogo.png')`,
        }}
      >
        {unreadCount > 0 && (
          <Link
            href={btnReqForMe ? `/notifications/forme/${id}` : `/notifications/byme/${id}`}
            title={title}
            className={styles.link}
          >
            <div className={styles.unreadCount}>{unreadCount > 10 ? '10+' : unreadCount}</div>
          </Link>
        )}
      </div>

      <div className={styles.addressBlock}>
        <div className={styles.titleBlock}>
          <Link
            href={btnReqForMe ? `/notifications/forme/${id}` : `/notifications/byme/${id}`}
            title={title}
            className={styles.title}
          >
            {titleText}
          </Link>
          {/* <div className={styles.adsId}>#{id}</div> */}
        </div>
        <div className={styles.itemAddress}>
          <Link
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'left',
              color: 'inherit',
              fontSize: '14px',
              textDecoration: 'none',
              gap: '4px',
            }}
            href={`https://www.google.com/maps/place/${coordinatesLat},${coordinatesLong}/@${coordinatesLat},${coordinatesLong},17z`}
            target='_blank'
            title={title}
          >
            <img src={IconMarker} alt='' className={styles.menuIcon} />
            <span className={styles.textAddress}>{addressEn || nameEn}</span>
          </Link>
        </div>
        {btnReqForMe && (
          <div className={`${styles.timer} ${endOfPublishDayLeft > 2 ? '' : styles.endingTimer}`}>
            <img
              src={endOfPublishDayLeft > 2 ? IconTimer : IconTimerRed}
              alt=''
              className={styles.menuIcon}
            />
            Active until:
            <span>
              {endOfPublishDayLeft} {endOfPublishDayLeft === 1 ? 'day' : 'days'}
            </span>
          </div>
        )}
      </div>
      <div className={styles.priceDateBlock}>
        <div className={styles.currency}>
          {activityLabel}: <span>{priceFormat(newPrice)}</span>
          {convertedCurrency}
          {!isSale && (
            <SmallShell alignItems='center' justifyContent='center'>
              <Term>
                {defaultRentFrequency &&
                  rentFrequencyLabels[
                    filterRentFrequency
                      ? (filterRentFrequency as RentPeriodEnum)
                      : defaultRentFrequency
                  ]}
              </Term>
            </SmallShell>
          )}
        </div>
        <div className={styles.itemParams}>
          {aria > 0 && (
            <div>
              <img src={IconRuler} alt='' className={styles.menuIcon} />
              {aria} {areaUnits.toLowerCase()}
            </div>
          )}
          {beds && (
            <>
              {aria > 0 && (beds || baths) && <div className={styles.itemParamsSeparator} />}
              <div>
                <img src={IconBed} alt='' className={styles.menuIcon} />
                {`${beds} ${textBeds}`}
              </div>
            </>
          )}
          {baths && (
            <>
              {beds && <div className={styles.itemParamsSeparator} />}
              <div>
                <img src={IconShower} alt='' className={styles.menuIcon} />
                {`${baths} ${textBaths}`}
              </div>
            </>
          )}
        </div>
        {btnReqForMe ? (
          <Link
            href={btnReqForMe ? `/notifications/forme/${id}` : `/notifications/byme/${id}`}
            title={title}
            className={styles.link}
          >
            <div className={styles.requests}>
              <img src={IconRequest} alt='' className={styles.menuIcon} />
              <div className={styles.request}>
                Requests: <span>{requestsCount}</span>
              </div>
            </div>
          </Link>
        ) : (
          <div
            className={`${styles.itemLinks} ${
              propertyAdCreatorEmployer
                ? styles.itemLinksWithEmployer
                : styles.itemLinksWithoutEmployer
            }`}
          >
            {propertyAdCreator && (
              <Link href={`/users/${propertyAdCreator?.id}`} title={propertyAdCreator?.name}>
                <Avatar
                  src={propertyAdCreator?.avatar || undefined}
                  alt={propertyAdCreator?.name}
                  {...stringAvatar(propertyAdCreator?.name)}
                />
                <span>{propertyAdCreator?.name}</span>
              </Link>
            )}
            {propertyAdCreatorEmployer && (
              <Link
                href={`/users/${propertyAdCreatorEmployer?.id}`}
                title={propertyAdCreatorEmployer.name}
              >
                <Avatar
                  src={propertyAdCreatorEmployer?.avatar || undefined}
                  alt={propertyAdCreatorEmployer?.name}
                  {...stringAvatar(propertyAdCreatorEmployer?.name)}
                />
                <span>{propertyAdCreatorEmployer.name}</span>
              </Link>
            )}
          </div>
        )}
      </div>
      {!btnReqForMe && (
        <div className={styles.connect}>
          <MyButton
            data={{
              styleType: 'OPchat',
              icon: <IconChat />,
              variant: 'contained',
            }}
            onClick={() => handleOpChatBtnClick()}
          />
          <IconButton aria-label='phone' onClick={() => handlePhoneBtnClick()}>
            <IconPhone />
          </IconButton>
          <IconButton aria-label='telegram' onClick={() => handleTelegramBtnClick()}>
            <IconTelegram />
          </IconButton>
          <IconButton aria-label='whatsapp' onClick={() => handleWhatsappBtnClick()}>
            <IconWhatsapp />
          </IconButton>
        </div>
      )}
    </div>
  );
};
