/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './DownPaymentSearch.module.scss';
import debounce from '@/utils/debounce';

interface IKeywordSearchProps {
  disabled?: boolean;
  defaultDownPayment?: string | number;
  placeholder?: string;
  handleChange: (value: any) => void;
  dataQa?: string;
}

export const DownPaymentSearch = ({
  placeholder,
  disabled = false,
  defaultDownPayment,
  handleChange,
  dataQa,
}: IKeywordSearchProps) => {
  const [downPayment, setDownPayment] = useState<string | number>(defaultDownPayment || '');

  useEffect(() => {
    if (defaultDownPayment !== downPayment) {
      setDownPayment(defaultDownPayment || '');
    }
  }, [defaultDownPayment]);

  const debouncedHandleChange = useCallback(
    debounce((value: string) => {
      if (value.length >= 4) {
        const parsedInt = parseInt(value);
        handleChange(isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt);
      }
    }, 500),
    [handleChange]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const parsedInt = parseInt(event.target.value);
      setDownPayment(isNaN(parsedInt) || parsedInt < 0 ? '' : parsedInt);
      debouncedHandleChange(newValue);
    },
    [debouncedHandleChange]
  );

  return (
    <Paper
      component='form'
      className={styles.keywordSearchContainer}
      elevation={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '13px',
        padding: '0 10px',
        boxShadow: 'none',
        border: '1px solid rgb(233, 238, 241)',
        background: disabled ? '#F8F8F8' : '',
        position: 'relative',
      }}
    >
      {downPayment === '' && (
        <SearchIcon
          sx={{ position: 'absolute', marginLeft: '10px', pointerEvents: 'none', opacity: 0.3 }}
        />
      )}
      <InputBase
        className={styles.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search down payment' }}
        value={downPayment}
        onChange={handleSearchChange}
        fullWidth={true}
        disabled={disabled}
        style={{
          paddingLeft: downPayment > 0 ? '10px' : '40px',
          paddingTop: '6px',
          paddingBottom: '6px',
        }}
        data-qa={dataQa}
      />
    </Paper>
  );
};
