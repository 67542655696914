import { NewBuildingModel, UserModel } from '@/types';
import { OffplanSelectedDataType } from '../OffplanPdfManager';
import { ClassicView, OffplanClassicView } from './ClassicView';
import { AllInOneView, OffplanAllInOneView } from './AllInOneView';
import { LuxView, OffplanLuxView } from './LuxView';
import { OffplanOnePageView, OnePageView } from './OnePageView';
import { OffplanPortableView, PortableView } from './PortableView';
import { IUnit } from '@/types/offplans';

export function GeneratePDF({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  units,
  templateName,
}: {
  showContent: OffplanSelectedDataType;
  offplanData: NewBuildingModel;
  personalInfo: UserModel;
  currentAgencyInfo?: UserModel;
  units?: IUnit[];
  templateName: string;
}) {
  const ViewComponents = {
    'classic-view': OffplanClassicView,
    'all-in-one-view': OffplanAllInOneView,
    'lux-view': OffplanLuxView,
    'one-page': OffplanOnePageView,
    'portable-view': OffplanPortableView,
  };

  const SelectedView = ViewComponents[templateName as keyof typeof ViewComponents];

  return SelectedView ? (
    <SelectedView
      offplanData={offplanData}
      personalInfo={personalInfo}
      currentAgencyInfo={currentAgencyInfo}
      showContent={showContent}
      units={units}
    />
  ) : null;
}
