import {
  IUnitSearchQueryParams,
  IUnitSearchQueryFilter,
  IUnitSearchQuerySort,
} from '@/types/requests';

//    * URL like:
//    * http://localhost:3000/admin/units?newConstructionId=60&limit=10&offset=0&filter[buildingId][]=1&filter[buildingId][]=2&filter[areaFrom]=50&filter[areaTo]=100&filter[floor][]=1&filter[floor][]=2&filter[planCode][]=2B-L&filter[priceFrom]=200000&filter[priceTo]=500000&filter[beds]=2

export const parseUrlToQueryParams = (url: string, deleteEmpty = false): IUnitSearchQueryParams => {
  const parsedUrl = new URL(url);
  const searchParams = new URLSearchParams(parsedUrl.search);

  const getNumber = (param: string | null): number | undefined =>
    param ? Number(param) : undefined;

  const filter: IUnitSearchQueryFilter = {
    buildingId: searchParams.getAll('filter[buildingId][]'),
    areaFrom: getNumber(searchParams.get('filter[areaFrom]')),
    areaTo: getNumber(searchParams.get('filter[areaTo]')),
    floor: searchParams.getAll('filter[floor][]'),
    planCode: searchParams.getAll('filter[planCode][]'),
    priceFrom: getNumber(searchParams.get('filter[priceFrom]')),
    priceTo: getNumber(searchParams.get('filter[priceTo]')),
    beds: searchParams.get('filter[beds]') || '',
  };

  filter.floor && filter.floor.length === 0 && delete filter.floor;
  filter.buildingId && filter.buildingId.length === 0 && delete filter.buildingId;
  filter.beds && filter.beds.length === 0 && delete filter.beds;
  filter.planCode && filter.planCode.length === 0 && delete filter.planCode;
  const filters = [filter];

  const sort: IUnitSearchQuerySort[] = Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith('sort['))
    .map(([key, value]) => ({
      field: key.slice(5, -1),
      order: value as string,
    }));

  const sortObject = sort?.length > 0 ? { sort } : { sort: [{ field: 'price', order: 'ASC' }] };

  const queryParams: IUnitSearchQueryParams = {
    limit: Number(searchParams.get('limit')),
    offset: Number(searchParams.get('offset')),
    newConstructionId: searchParams.get('newConstructionId')!,
    filter: filters,
    ...sortObject,
  };

  return queryParams;
};

export const buildUrWithBaseURLFromParams = (
  baseURL: string,
  params: IUnitSearchQueryParams
): { url: string; urlOrigin: string; urlPathname: string; urlSearch: string } => {
  const url = new URL(baseURL);

  url.searchParams.set('limit', params.limit.toString());
  url.searchParams.set('offset', params.offset.toString());
  url.searchParams.set('newConstructionId', params.newConstructionId);

  const addFilterParams = (filter: IUnitSearchQueryFilter, index: number) => {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => url.searchParams.append(`filter[${key}][]`, item.toString()));
      } else if (value !== undefined && value !== null) {
        url.searchParams.set(`filter[${key}]`, value.toString());
      }
    });
  };

  params.filter.forEach(addFilterParams);

  params.sort.forEach((sort) => {
    url.searchParams.set(`sort[${sort.field}]`, sort.order);
  });

  const newUrl = new URL(url.toString());

  return {
    url: url.toString(),
    urlOrigin: newUrl.origin || '',
    urlPathname: newUrl.pathname || '',
    urlSearch: newUrl.search || '',
  };
};

export const buildUrlFromParams = (params: IUnitSearchQueryParams): string => {
  let queryString = '?';

  queryString += `limit=${encodeURIComponent(params.limit.toString())}&`;
  queryString += `offset=${encodeURIComponent(params.offset.toString())}&`;
  queryString += `newConstructionId=${encodeURIComponent(params.newConstructionId)}`;

  const addFilterParams = (filter: IUnitSearchQueryFilter) => {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryString += `&filter[${key}][]=${encodeURIComponent(item.toString())}`;
        });
      } else if (value !== undefined && value !== null) {
        queryString += `&filter[${key}]=${encodeURIComponent(value.toString())}`;
      }
    });
  };

  params.filter.forEach(addFilterParams);

  params.sort.forEach((sort) => {
    queryString += `&sort[${sort.field}]=${encodeURIComponent(sort.order)}`;
  });

  return queryString;
};
