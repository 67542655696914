import { ComponentType, FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Container, useMediaQuery } from '@mui/material';

import { State, UserKindEnum } from '@/types';
import { useUserProfile } from '@/hooks/useUserProfile';
import {
  Footer,
  Header,
  Loader,
  ManagerWithoutEmployer,
  ForeignBrokerWithoutVerification,
  SidebarMenu,
} from '@/components';
import { ScrollTopButton } from '@/components/Dashboard/ScrollTopButton';
import { useNavigate } from 'react-router-dom';

import { DialogsProvider } from './DialogsContext';

const DashboardFn = ({
  openSignupPopup,
  openLogIn,
  children,
  isChecked,
  isSearch,
}: {
  openSignupPopup?: boolean;
  openLogIn?: boolean;
  children: ReactNode;
  isChecked?: boolean;
  isSearch?: boolean;
  isSignUp?: boolean;
}) => {
  const isAbove900 = useMediaQuery('(min-width:1200px)');
  const user = useSelector(({ auth }: State) => auth.user);
  const navigate = useNavigate();

  const { isLoading, isLoggedIn } = useUserProfile(() => {
    if (openSignupPopup) {
      navigate('/signup');
    }
    if (openLogIn) {
      navigate('/login');
    }
  });

  const isManager = user.kind === UserKindEnum.Manager;
  const isForeign = user.kind === UserKindEnum.Foreign;
  const isManagerPermission = isManager && user.employer !== null;
  const isForeignPermission = (isForeign && user.verified) || isChecked;
  const permission = () => !isManager || isManagerPermission || isChecked || isForeignPermission;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {(isLoggedIn || isAbove900) && <Header />}

      <Container
        component='main'
        sx={{
          display: 'flex',
          margin: '0 auto',
          flex: '1 0 auto',
          alignItems: 'flex-start',
          gap: '15px',
          padding: {
            xs: '24px 8px 56px',
            md: isLoggedIn ? '24px 16px 56px' : 0,
          },
          boxSizing: 'border-box',
        }}
        style={{ maxWidth: '1300px' }}
      >
        {!isLoading && (
          <>
            {isAbove900 && isLoggedIn && <SidebarMenu />}
            {isForeign && !isForeignPermission ? (
              <ForeignBrokerWithoutVerification />
            ) : permission() ? (
              children
            ) : (
              <ManagerWithoutEmployer />
            )}
          </>
        )}
      </Container>
      <ScrollTopButton isSearch={isSearch} />
      <Footer />
    </>
  );
};

export const withDialogProvider = <P extends {}>(Component: ComponentType<P>): FC<P> => {
  return (props) => (
    <DialogsProvider>
      <Component {...props} />
    </DialogsProvider>
  );
};

export const Dashboard = withDialogProvider(DashboardFn);
