/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme, shouldForwardProp } from '@mui/system';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu, 
  MenuItem
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum, ITariffPlan, ISubscriptionsTable } from '@/types';
import { Loader } from '@/components';
import { usersProvider } from '@/providers';
import moment from 'moment';

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'weight' && prop !== 'name',
})<{ color?: string; weight?: number; name?: string }>(({ color, weight, name }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: color,
    fontWeight: weight,
    maxWidth: '250px',
    width: name === 'name' ? '200px' : 'auto', 
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '10px 16px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FCFF',
  },
}));

export const TariffPlanTable = ({
  onClick,
  tariffPlanTable,
  setTotalTariffPlan,
}: {
  onClick?: (row: ITariffPlan, type: string) => void;
  tariffPlanTable: ISubscriptionsTable;
  setTotalTariffPlan: (value: number) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<ITariffPlan[]>();
  const [selectedRow, setSelectedRow] = useState<ITariffPlan | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch();

  const modifiedStr = (price: string) => {
    const str = price.toString();
    if (str?.length > 2) {
      return `${str.slice(0, -2)} AED`;
    } else {
      return '0';
    }
  };

  const getTariffPlan = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await usersProvider.getTariffPlan();
    if (ok && data) {
      const { list } = data;
      setTotalTariffPlan(list.length);
      const newItems = list.flatMap((item: any) => {
        if (item.isFree) {
          return {
            id: item.id,
            name: item.name,
            price: null,
            interval: item.interval,
            createdAt: item.createdAt ? moment(item.createdAt).format('DD.MM.YYYY') : null,
            updatedAt: item.updatedAt ? moment(item.updatedAt).format('DD.MM.YYYY') : null,
          };
        } else if (item.stripeProduct && item.stripeProduct.prices) {
          return item.stripeProduct.prices.map((price: any) => ({
            id: item.id,
            name: item.name,
            price: modifiedStr(price.unitAmount),
            interval: price.interval,
            createdAt: item.createdAt ? moment(item.createdAt).format('DD.MM.YYYY') : null,
            updatedAt: item.updatedAt ? moment(item.updatedAt).format('DD.MM.YYYY') : null,
          }));
        } else {
          return [];
        }
      });
      setRows(newItems as ITariffPlan[]);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTariffPlan();
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: ITariffPlan) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    onClick?.(selectedRow!, 'edit');
    handleMenuClose();
  };

  const handleDelete = () => {
    onClick?.(selectedRow!, 'delete');
    handleMenuClose();
  };

  const getCellProperties = (
    row: ITariffPlan,
    body: string
  ): { cell: string | number | null; color: string; weight: number } => {
    const cell = (row as ITariffPlan)[body as keyof ITariffPlan]
    const color = body === 'name' ? '#1650FF' : '#2A3842';
    const weight = body === 'name' ? 600 : 400;
    return { cell, color, weight };
  };

  if (isLoading) return <Loader />;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            {tariffPlanTable.header.map((header: string, index: number) => {
              return (
                <StyledTableCell key={header}>
                  {header}
                </StyledTableCell>
              );
            })}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: ITariffPlan, index) => (
            <StyledTableRow key={index} onClick={() => onClick?.(row, 'transaction')}>
              {tariffPlanTable.body.map((body: string, bodyIndex: number) => {
                const { cell, color, weight } = getCellProperties(row, body);
                return (
                  <StyledTableCell
                    key={bodyIndex}
                    component='th'
                    scope='row'
                    color={color}
                    weight={weight}
                    name={body}
                  >
                    {cell}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell>
                <IconButton 
                  sx={{
                    width: '32px',
                    height: '32px',
                    border: '2px solid #C5DFFF',
                    borderRadius: { xs: '14px', sm: '32px' },
                    ':hover': {
                      borderColor: '#1650FF',
                      color: '#1650FF',
                    },
                  }}
                  onClick={(event) => handleMenuClick(event, row)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id='account-menu'
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(1px 1px 4px rgba(0,0,0,0.1))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleEdit}>
                    <EditIcon sx={{ marginRight: '10px' }} /> Edit tariff
                  </MenuItem>
                  <MenuItem onClick={handleDelete} sx={{ color: 'red' }}>
                    <DeleteIcon sx={{ marginRight: '10px' }} /> Delete tariff
                  </MenuItem>
                </Menu>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
