import Map, { FillLayer, Layer, MapRef, Marker, NavigationControl, Source } from 'react-map-gl';
import { useFormContext } from 'react-hook-form';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LocationModel, MinimizedLocation } from '@/types/location';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Map.module.scss';
import { bbox, mask, polygon, polygons } from '@turf/turf';
import { Tooltip } from '@mui/material';
import { AdFieldCurrencyEnum } from '@/types';

export const dataLayer: FillLayer = {
  id: 'data',
  type: 'fill',
  interactive: true,
  paint: {
    'fill-outline-color': '#000000',
    'fill-color': '#000000',
    'fill-opacity': 0.4,
  },
};

interface MapProps {
  minimizedLocations?: MinimizedLocation[];
  selectedLocation?: Partial<LocationModel> | null;
  onLocationClick?: (locationId: string) => void;
  selectedMarker?: string | null;
  onMarkerAdd?: (longitude: number, latitude: number) => void; // добавлено
  source?: string;
  setLocations?: (selectedLocation: LocationModel[] | []) => void;
  isLocation?: boolean;
  saveUserMarker?: (coordinatesLat: string, coordinatesLong: string) => void;
  isAddNewLocationVisible?: boolean;
  locationName?: string;
}

const BOX = [
  [51.010780127357066, 21.479022143402176], // southwestern
  [56.2080876365967, 21.479022143402176], // southwestern
  [56.2080876365967, 26.19279751678829], // northeastern
  [51.010780127357066, 26.19279751678829], // northeastern
  [51.010780127357066, 21.479022143402176], // southwestern
];

const getArrayDepth = (
  arr: number | number[] | number[][] | number[][][] | number[][][][]
): number => {
  return Array.isArray(arr) && arr.length ? 1 + Math.max(...arr.map(getArrayDepth)) : 0;
};

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

type Price = { [AdFieldCurrencyEnum.AED]: number[]; [AdFieldCurrencyEnum.USD]: number[] };

type MinimizedLocationMarker = MinimizedLocation & {
  sale?: Price;
  rentDaily?: Price;
  rentWeekly?: Price;
  rentMountly?: Price;
  rentYearly?: Price;
};

export const MapUi = ({
  selectedLocation,
  minimizedLocations,
  onLocationClick,
  selectedMarker,
  onMarkerAdd,
  source,
  setLocations,
  isLocation,
  saveUserMarker,
  isAddNewLocationVisible,
  locationName,
}: MapProps) => {
  const formContext = useFormContext();
  const getValues = formContext?.getValues || (() => ({}));
  const values = getValues();
  const { title } = values;

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [zoomOnScroll, setZoomOnScroll] = useState(false);
  const [userMarker, setUserMarker] = useState<{ longitude: number; latitude: number } | null>(
    null
  ); // добавлено

  const markers = useMemo(
    () =>
      minimizedLocations?.reduce<MinimizedLocationMarker[]>((acc, field) => {
        const sameLocation = acc.findIndex(
          (item) =>
            item.coordinatesLong === field.coordinatesLong &&
            item.coordinatesLat === field.coordinatesLat
        );
        if (sameLocation !== -1) {
          acc[sameLocation] = {
            ...acc[sameLocation],
            ...(field.price
              ? {
                  sale: {
                    [AdFieldCurrencyEnum.USD]: [
                      ...(acc[sameLocation].sale?.[AdFieldCurrencyEnum.USD] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.USD ? [field.price] : []),
                    ],
                    [AdFieldCurrencyEnum.AED]: [
                      ...(acc[sameLocation].sale?.[AdFieldCurrencyEnum.AED] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.AED ? [field.price] : []),
                    ],
                  },
                }
              : {}),
            ...(field.priceDaily
              ? {
                  rentDaily: {
                    [AdFieldCurrencyEnum.USD]: [
                      ...(acc[sameLocation].rentDaily?.[AdFieldCurrencyEnum.USD] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.USD ? [field.priceDaily] : []),
                    ],
                    [AdFieldCurrencyEnum.AED]: [
                      ...(acc[sameLocation].rentDaily?.[AdFieldCurrencyEnum.AED] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.AED ? [field.priceDaily] : []),
                    ],
                  },
                }
              : {}),
            ...(field.priceWeekly
              ? {
                  rentWeekly: {
                    [AdFieldCurrencyEnum.USD]: [
                      ...(acc[sameLocation].rentWeekly?.[AdFieldCurrencyEnum.USD] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.USD ? [field.priceWeekly] : []),
                    ],
                    [AdFieldCurrencyEnum.AED]: [
                      ...(acc[sameLocation].rentWeekly?.[AdFieldCurrencyEnum.AED] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.AED ? [field.priceWeekly] : []),
                    ],
                  },
                }
              : {}),
            ...(field.priceMonthly
              ? {
                  rentMountly: {
                    [AdFieldCurrencyEnum.USD]: [
                      ...(acc[sameLocation].rentMountly?.[AdFieldCurrencyEnum.USD] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.USD ? [field.priceMonthly] : []),
                    ],
                    [AdFieldCurrencyEnum.AED]: [
                      ...(acc[sameLocation].rentMountly?.[AdFieldCurrencyEnum.AED] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.AED ? [field.priceMonthly] : []),
                    ],
                  },
                }
              : {}),
            ...(field.priceYearly
              ? {
                  rentYearly: {
                    [AdFieldCurrencyEnum.USD]: [
                      ...(acc[sameLocation].rentYearly?.[AdFieldCurrencyEnum.USD] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.USD ? [field.priceYearly] : []),
                    ],
                    [AdFieldCurrencyEnum.AED]: [
                      ...(acc[sameLocation].rentYearly?.[AdFieldCurrencyEnum.AED] ?? []),
                      ...(field.currency === AdFieldCurrencyEnum.AED ? [field.priceYearly] : []),
                    ],
                  },
                }
              : {}),
          };
          return acc;
        }
        return [
          ...acc,
          {
            ...field,
            ...(field.price
              ? {
                  sale: {
                    [AdFieldCurrencyEnum.AED]:
                      field.currency === AdFieldCurrencyEnum.AED ? [field.price] : [],
                    [AdFieldCurrencyEnum.USD]:
                      field.currency === AdFieldCurrencyEnum.USD ? [field.price] : [],
                  },
                }
              : {}),
            ...(field.priceDaily
              ? {
                  rentDaily: {
                    [AdFieldCurrencyEnum.AED]:
                      field.currency === AdFieldCurrencyEnum.AED ? [field.priceDaily] : [],
                    [AdFieldCurrencyEnum.USD]:
                      field.currency === AdFieldCurrencyEnum.USD ? [field.priceDaily] : [],
                  },
                }
              : {}),
            ...(field.priceWeekly
              ? {
                  rentWeekly: {
                    [AdFieldCurrencyEnum.AED]:
                      field.currency === AdFieldCurrencyEnum.AED ? [field.priceWeekly] : [],
                    [AdFieldCurrencyEnum.USD]:
                      field.currency === AdFieldCurrencyEnum.USD ? [field.priceWeekly] : [],
                  },
                }
              : {}),
            ...(field.priceMonthly
              ? {
                  rentMountly: {
                    [AdFieldCurrencyEnum.AED]:
                      field.currency === AdFieldCurrencyEnum.AED ? [field.priceMonthly] : [],
                    [AdFieldCurrencyEnum.USD]:
                      field.currency === AdFieldCurrencyEnum.USD ? [field.priceMonthly] : [],
                  },
                }
              : {}),
            ...(field.priceYearly
              ? {
                  rentYearly: {
                    [AdFieldCurrencyEnum.AED]:
                      field.currency === AdFieldCurrencyEnum.AED ? [field.priceYearly] : [],
                    [AdFieldCurrencyEnum.USD]:
                      field.currency === AdFieldCurrencyEnum.USD ? [field.priceYearly] : [],
                  },
                }
              : {}),
          },
        ];
      }, []),
    [minimizedLocations]
  );

  const mapRef = useRef<MapRef>(null);
  const isCorrectCoordinates = useMemo(() => {
    if (selectedLocation?.coordinates) {
      const coordinates = selectedLocation.coordinates;
      const depth = getArrayDepth(coordinates);
      switch (depth) {
        case 1:
          return false;
        case 2:
          return (
            coordinates[0] &&
            coordinates[0][0] &&
            coordinates[0][1] &&
            coordinates.length > 2 &&
            coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
            coordinates[0][1] === coordinates[coordinates.length - 1][1]
          );
        case 3:
        case 4:
          return true;
        default:
          return false;
      }
    }
    return false;
  }, [selectedLocation?.coordinates]);

  const selectedLocationPolygon = useMemo(() => {
    const coordinates = selectedLocation?.coordinates;
    if (coordinates && isCorrectCoordinates) {
      const depth = getArrayDepth(coordinates);
      switch (depth) {
        case 4:
          return polygons(coordinates as number[][][][]);
        case 3:
          return polygon(coordinates as number[][][]);
        case 2:
          return polygon([coordinates as number[][]]);
        default:
          return null;
      }
    }
    return null;
  }, [selectedLocation?.coordinates, isCorrectCoordinates]);
  const sourceData = useMemo(() => {
    if (
      isCorrectCoordinates &&
      selectedLocationPolygon &&
      !['level3'].includes(selectedLocation?.kind ?? '')
    ) {
      const overlay = polygon([BOX]);
      return mask(selectedLocationPolygon, overlay);
    }
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }, [selectedLocation, selectedLocationPolygon, isCorrectCoordinates]);

  useEffect(() => {
    if (!selectedLocation || isLocation) {
      if (userMarker) {
        mapRef.current?.flyTo({
          center: {
            lat: userMarker.latitude as number,
            lng: userMarker?.longitude as number,
          },
          zoom: 16,
        });
      } else {
        mapRef.current?.flyTo({
          center: {
            lng: 55.2690347,
            lat: 25.2057795,
          },
          zoom: 10,
        });
      }
    } else {
      setUserMarker(null);
    }
  }, [selectedLocation, userMarker]);

  useEffect(() => {
    if (title?.length < 3 || (locationName && locationName?.length < 3)) {
      setUserMarker(null);
    }
  }, [title, locationName]);

  useEffect(() => {
    if (isCorrectCoordinates && isMapLoaded) {
      const box = bbox(selectedLocationPolygon);
      mapRef.current?.fitBounds(box as [number, number, number, number]);
    } else if (selectedLocation?.longitude && selectedLocation.latitude && isMapLoaded) {
      mapRef.current?.flyTo({
        center: {
          lat: selectedLocation.latitude as number,
          lng: selectedLocation?.longitude as number,
        },
        zoom: 16,
      });
    }
  }, [
    selectedLocation?.longitude,
    selectedLocation?.latitude,
    selectedLocationPolygon,
    isMapLoaded,
  ]);

  const getGetFormattedPrice = (price: number) => {
    const priceLen = `${price}`.length;
    if (priceLen > 6) {
      return `${`${price}`.slice(0, priceLen - 6)}m`;
    } else if (priceLen > 3) {
      return `${`${price}`.slice(0, priceLen - 3)}k`;
    } else {
      return price;
    }
  };

  const getTooltipLabel = (item: MinimizedLocationMarker) => {
    const getPriceLabel = (array: Price, currency: AdFieldCurrencyEnum) => {
      if (!array[currency].length) return <></>;
      return (
        <>
          {array[currency].length >= 2
            ? `${getGetFormattedPrice(Math.min(...array[currency]))} - ${getGetFormattedPrice(Math.max(...array[currency]))}`
            : getGetFormattedPrice(array[currency][0])}{' '}
          {currency}
        </>
      );
    };
    const ArrayKeys = ['sale', 'rentDaily', 'rentWeekly', 'rentMountly', 'rentYearly'];
    const getRentLabel = (
      key: 'sale' | 'rentDaily' | 'rentWeekly' | 'rentMountly' | 'rentYearly'
    ) => {
      switch (key) {
        case 'sale':
          return '';
        case 'rentDaily':
          return '/day';
        case 'rentMountly':
          return '/mounth';
        case 'rentWeekly':
          return '/week';
        case 'rentYearly':
          return '/year';
      }
    };
    return (
      <div>
        {ArrayKeys.map((key, index) => {
          const value = item[key as keyof MinimizedLocationMarker] as Price;
          if (value) {
            return (
              <>
                {value[AdFieldCurrencyEnum.AED].length ? (
                  <div key={`${key}-${index}-AED}`}>
                    {key === 'sale' ? 'Sale' : 'Rent'}{' '}
                    {getPriceLabel(value as Price, AdFieldCurrencyEnum.AED)}
                    {getRentLabel(key as any)}
                  </div>
                ) : (
                  ''
                )}
                {value[AdFieldCurrencyEnum.USD].length ? (
                  <div key={`${key}-${index}-USD}`}>
                    {key === 'sale' ? 'Sale' : 'Rent'}{' '}
                    {getPriceLabel(value as Price, AdFieldCurrencyEnum.USD)}
                    {getRentLabel(key as any)}
                  </div>
                ) : (
                  ''
                )}
              </>
            );
          }
        })}
      </div>
    );
  };

  const handleMapClick = (event: any) => {
    const { lng, lat } = event.lngLat;
    if (source === 'offPlan' && (title || locationName) && saveUserMarker) {
      setUserMarker({ longitude: lng, latitude: lat });
      saveUserMarker(lng, lat);
    }
    if (title?.length < 3 || (locationName && locationName?.length < 3)) {
      setUserMarker(null);
      return;
    }
    if (onMarkerAdd) {
      onMarkerAdd(lng, lat);
    }
  };

  const handleMarkerDragEnd = (event: any) => {
    const { lng, lat } = event.lngLat;
    setUserMarker({ longitude: lng, latitude: lat });
    if (onMarkerAdd) {
      onMarkerAdd(lng, lat);
    }
  };

  return (
    <div style={{ flex: 1 }} onMouseLeave={() => setZoomOnScroll(false)}>
      <Map
        onLoad={() => setIsMapLoaded(true)}
        scrollZoom={zoomOnScroll}
        onClick={handleMapClick}
        mapboxAccessToken={mapboxToken}
        initialViewState={{
          longitude: 55.2690347,
          latitude: 25.2057795,
          zoom: 10,
        }}
        ref={mapRef}
        style={{ width: '100%', height: '70vh' }}
        mapStyle='mapbox://styles/mapbox/streets-v9'
        maxBounds={[
          [51.010780127357066, 21.479022143402176], // southwestern
          [56.2080876365967, 26.19279751678829], // northeastern
        ]}
      >
        <Source type='geojson' data={sourceData as any}>
          <Layer {...dataLayer} />
        </Source>
        {markers?.map((item) => (
          <Marker
            onClick={() => onLocationClick?.(item.locationId)}
            longitude={Number(item.coordinatesLong)}
            latitude={Number(item.coordinatesLat)}
            key={item.id}
          >
            <Tooltip title={getTooltipLabel(item)}>
              {selectedMarker && selectedMarker === item.locationId ? (
                <div className={styles.markerBg}>
                  <div className={styles.markerInner} />
                </div>
              ) : (
                <div className={`${styles.marker}`} />
              )}
            </Tooltip>
          </Marker>
        ))}
        {userMarker && isLocation && (
          <Marker
            longitude={userMarker.longitude}
            latitude={userMarker.latitude}
            draggable
            onDragEnd={handleMarkerDragEnd}
          >
            <div className={styles.userMarker} />
          </Marker>
        )}
        {selectedLocation && !minimizedLocations?.length && !isAddNewLocationVisible && (
          <Marker
            longitude={Number(selectedLocation.longitude)}
            latitude={Number(selectedLocation.latitude)}
            key={selectedLocation.id}
          >
            <Tooltip title={selectedLocation.kind && selectedLocation?.[selectedLocation.kind]}>
              <div className={styles.marker} />
            </Tooltip>
          </Marker>
        )}
        <NavigationControl />
      </Map>
    </div>
  );
};
