import { Container, DialogActions, DialogContent } from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import styles from './../../AdForm/AdForm.module.scss';

import FormData from '@/components/AccountManagement/Profile/EditProfile/FormData';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { licenseData } from '@/components/Admin/License/types/types';

const LocalizationEdit = ({
  open,
  languages,
  localization,
  actions,
  onClose,
}: {
  open: boolean;
  actions: {
    onEdit: (data: any) => void;
    onCreate: (data: any) => void;
    onDelete: (id: number) => void;
  };
  languages: string;
  localization?: any;
  onClose: () => void;
}) => {
  if (!open && !localization) {
    return null;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...localization,
    },
  });

  useEffect(() => {
    reset(localization ? localization : {});
  }, [open, localization]);

  const onSubmit = async (data: any) => {
    if (localization) {
      await actions.onEdit(data);
    } else {
      await actions.onCreate(data);
    }
    onClose();
  };

  const isNewLocalization = localization === undefined;
  const localizationData: any = { ...licenseData };
  languages.split(',').forEach((lang) => {
    if (!isNewLocalization) {
      localization[`translate_${lang}`] = localization?.translation
        ? localization?.translation[lang]
        : '';
    }
    localizationData.data.push({
      name: `translate_${lang}`,
      label: `translate_${lang}`,
      typeOfComponent: 'input',
    });
  });

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle={!isNewLocalization ? 'edit localization' : 'add localization'}
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          <FormData
            control={control}
            formData={localizationData}
            formErrors={errors}
            setValue={setValue}
            user={{}}
            openConfirmationDialog={() => {}}
            handleCancelTariff={() => {}}
            type='license'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: !isNewLocalization ? 'Save information' : 'Create',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={handleSubmit((data) => {
                onSubmit(data);
              })}
            />
            <MyButton
              data={{
                buttonName: !isNewLocalization ? 'Delete localization' : 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={!isNewLocalization ? () => actions.onDelete(localization.id) : onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default LocalizationEdit;
