import { Typography } from '@mui/material';
import { FormCheckbox, SectionTitle } from '@/components';

import styles from './FeaturesAmenities.module.scss';

interface FeaturesAmenitiesProps {
  balcony?: boolean;
  maidsRoom?: boolean;
  sharedPool?: boolean;
  builtInWardrobes?: boolean;
  childrenPlayArea?: boolean;
  sharedSpa?: boolean;
  sharedGym?: boolean;
  study?: boolean;
  centralAc?: boolean;
  coveredParking?: boolean;
  barbecueArea?: boolean;
  petsAllowed?: boolean;
  concierge?: boolean;
  publicParking?: boolean;
  kitchenAppliances?: boolean;
  lobbyInBuilding?: boolean;
  maidService?: boolean;
  petsNotAllowed?: boolean;
  privateGarden?: boolean;
  privateGym?: boolean;
  privatePool?: boolean;
  security?: boolean;
  tennisCourts?: boolean;
  privateSpa?: boolean;
  laundryFacility?: boolean;
}

export const FeaturesAmenities = ({
  balcony,
  maidsRoom,
  sharedPool,
  builtInWardrobes,
  childrenPlayArea,
  sharedSpa,
  sharedGym,
  study,
  centralAc,
  coveredParking,
  barbecueArea,
  petsAllowed,
  concierge,
  publicParking,
  kitchenAppliances,
  lobbyInBuilding,
  maidService,
  petsNotAllowed,
  privateGarden,
  privateGym,
  privatePool,
  security,
  tennisCourts,
  privateSpa,
  laundryFacility,
}: FeaturesAmenitiesProps) => {
  return (
    <div>
      <Typography sx={{ fontSize: '18px', fontWeight: 800, margin: '20px 0 10px' }}>
        Amenities
      </Typography>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.blockTitle}>Private amenities</div>
          <FormCheckbox name='privateGarden' label='Private garden' checked={privateGarden} />
          <FormCheckbox name='privateGym' label='Private gym' checked={privateGym} />
          <FormCheckbox name='privatePool' label='Private pool' checked={privatePool} />
          <FormCheckbox name='privateSpa' label='Private spa' checked={privateSpa} />
          <div className={styles.blockTitle}>Shared amenities</div>
          <FormCheckbox name='sharedGym' label='Shared gym' checked={sharedGym} />
          <FormCheckbox name='sharedPool' label='Shared pool' checked={sharedPool} />
          <FormCheckbox name='sharedSpa' label='Shared spa' checked={sharedSpa} />
        </div>
        <div className={styles.column}>
          <div className={styles.blockTitle}>Building and area</div>
          <FormCheckbox name='balcony' label='Balcony' checked={balcony} />
          <FormCheckbox name='barbecueArea' label='Barbecue area' checked={barbecueArea} />
          <FormCheckbox
            name='childrenPlayArea'
            label="Children's play area"
            checked={childrenPlayArea}
          />
          <FormCheckbox name='coveredParking' label='Covered parking' checked={coveredParking} />
          <FormCheckbox
            name='lobbyInBuilding'
            label='Lobby in building'
            checked={lobbyInBuilding}
          />
          <FormCheckbox name='maidsRoom' label='Maids room' checked={maidsRoom} />
          <FormCheckbox name='publicParking' label='Public parking' checked={publicParking} />
          <FormCheckbox name='tennisCourts' label='Tennis courts' checked={tennisCourts} />
          <FormCheckbox name='study' label='Study' checked={study} />
          <FormCheckbox
            name='kitchenAppliances'
            label='Kitchen appliances'
            checked={kitchenAppliances}
          />
        </div>
        <div className={styles.column}>
          <div className={styles.blockTitle}>Services and features</div>
          <FormCheckbox
            name='builtInWardrobes'
            label='Built in wardrobes'
            checked={builtInWardrobes}
          />
          <FormCheckbox name='centralAc' label='Central A/C' checked={centralAc} />
          <FormCheckbox name='concierge' label='Concierge' checked={concierge} />
          <FormCheckbox name='laundryFacility' label='Laundry facility' checked={laundryFacility} />
          <FormCheckbox name='maidService' label='Maid service' checked={maidService} />
          <FormCheckbox name='petsAllowed' label='Pets allowed' checked={petsAllowed} />
          <FormCheckbox name='petsNotAllowed' label='Pets not allowed' checked={petsNotAllowed} />
          <FormCheckbox name='security' label='Security' checked={security} />
        </div>
      </div>
    </div>
  );
};
