import { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';

import { googleMapsOptions } from '@/utils/googleMapsOptions';
import { SectionTitle, FormInput, MyButton } from '@/components';

import styles from './PropertyLocation.module.scss';
import { MapUi } from '@/components/Map/Map';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';
import { PropertyKindEnum } from '@/types';
import {
  getDepthLabel,
  LocationAutocomplete,
} from '@/components/LocationAutocomplete/LocationAutocomplete';
import { clearSecondPageForm } from '../AdForm.init';
import { ReactComponent as IconFindLocation } from './findLocation.svg';

const OPFormHelperText = styled(FormHelperText)`
  margin: 0 0 5px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  span {
    color: var(--secondary, #f00);
  }
`;

interface PropertyLocationProps {
  nameEn?: string;
  coordinatesLat?: number;
  setCoordinatesLat: (value: number) => void;
  coordinatesLong?: number;
  setCoordinatesLong: (value: number) => void;
  setIsBtnVisible?: (value: boolean) => void;
  isPageChanged?: boolean;
  setIsPageChanged?: (value: boolean) => void;
  setLocationId: (value: string | null) => void;
  isAddNewLocationVisible: boolean;
  setIsAddNewLocationVisible: (value: boolean) => void;
}

export const PropertyLocation = ({
  setIsBtnVisible,
  isPageChanged,
  setIsPageChanged,
  setLocationId,
  isAddNewLocationVisible,
  setIsAddNewLocationVisible,
}: PropertyLocationProps) => {
  const { isLoaded } = useJsApiLoader(googleMapsOptions);
  const { setValue, watch, getValues } = useFormContext();
  const propertyKind = watch('propertyKind');
  const activityKind = watch('activityKind');
  const transactionType = watch('transactionType');
  const buildingName = watch('buildingName');
  const formValues = getValues();

  const [selLocation, setSelLocation] = useState<LocationModel | null>(null);

  const { addressEn, coordinatesLat, coordinatesLong } = formValues;

  const isNewLocationButtonVisible = propertyKind &&
    [
      PropertyKindEnum.Apartment,
      PropertyKindEnum.Floor,
      PropertyKindEnum.HotelApartment,
      PropertyKindEnum.Office,
      PropertyKindEnum.CommercialBuilding,
      PropertyKindEnum.Shop,
    ].includes(propertyKind);

  const {
    selectedLocation,
    locations,
    setSelectedLocation,
    setInputAddressValue,
    inputAddressValue,
  } = useLocationAutocomplete({
    defaultValue: addressEn,
    searchByDistrict: isAddNewLocationVisible,
    searchWithProperty: true,
  });

  useEffect(() => {
    const locationData = {
      latitude: Number(coordinatesLat ?? selectedLocation?.latitude),
      longitude: Number(coordinatesLong ?? selectedLocation?.longitude),
    } as LocationModel;
    if (addressEn) {
      setSelLocation(locationData);
    } else {
      setSelLocation(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (buildingName) {
      if (buildingName?.length > 3 && addressEn) {
        setIsAddNewLocationVisible(true);
        setIsBtnVisible?.(true);
        setValue('nameEn', buildingName);
        setValue('title', buildingName);
      } else {
        setIsBtnVisible?.(false);
      }
    }
  }, [buildingName]);

  const updateLocationDetails = (location: LocationModel | null) => {
    // setAddressEn(location?.[location?.kind] || inputAddressValue);
    setSelectedLocation(location);
    if (isAddNewLocationVisible) {
      setValue('districtId', location?.id);
      setValue('locationId', null);
      setValue('nameEn', buildingName || null);
      setValue('title', buildingName || null);
      setLocationId(null);
      if (buildingName?.length > 3) {
        setIsBtnVisible?.(true);
      } else {
        setIsBtnVisible?.(false);
      }
    } else {
      setIsBtnVisible?.(true);
      setValue('nameEn', location?.[location?.kind] ?? null);
      setValue('title', location?.[location?.kind] ?? null);
      setValue('locationId', location?.id || null);
      setValue('coordinatesLat', String(location?.latitude) || null);
      setValue('coordinatesLong', String(location?.longitude) || null);
      setLocationId(location?.id || null);
    }
    setValue(
      'addressEn',
      location ? `${getDepthLabel(location)}, ${location[location.kind]}` : null
    );
    setIsPageChanged?.(false);
  };

  const handleClear = () => {
    setSelectedLocation(null);
    setValue('addressEn', null);
    setValue('nameEn', null);
    setValue('title', null);
    setValue('locationId', null);
    setValue('coordinatesLat', null);
    setValue('coordinatesLong', null);
    clearSecondPageForm(setValue);
    setIsBtnVisible?.(false);
    setLocationId(null);
  };

  const locationLabel = isAddNewLocationVisible ? 'Enter District name' : 'Enter location';

  return (
    <div>
      <SectionTitle number={4} title='Listing name' />
      {isLoaded && (
        <>
          <div className={styles.group}>
            <FormControl sx={{ width: '100%' }}>
              <div className={styles.locationGroup}>
                <div className={styles.location}>
                  <OPFormHelperText className={styles.label}>
                    {locationLabel}
                    <span>*</span>
                  </OPFormHelperText>
                  <LocationAutocomplete
                    inputAddressValue={inputAddressValue}
                    setInputAddressValue={setInputAddressValue}
                    locations={locations}
                    handleClear={handleClear}
                    onSelect={updateLocationDetails}
                    activityKind={activityKind}
                    isPageChanged={isPageChanged}
                    propertyKind={propertyKind}
                    transactionType={transactionType}
                    isAddNewLocationVisible={isAddNewLocationVisible}
                  />
                </div>
                {isAddNewLocationVisible && (
                  <FormInput
                    uiVariant='blank'
                    name='buildingName'
                    required
                    fullWidth
                    label='Enter building name'
                    placeholder='Enter building name'
                  />
                )}
                {isAddNewLocationVisible && (
                  <MyButton
                    data={{
                      buttonName: 'Cancel',
                      customWidth: '120px',
                      styleType: 'cancel',
                      variant: 'outlined',
                      buttonType: 'button',
                    }}
                    onClick={() => {
                      setIsAddNewLocationVisible(false);
                      setValue('buildingName', null);
                    }}
                  />
                )}
              </div>

              {!isAddNewLocationVisible && isNewLocationButtonVisible && (
                <div onClick={() => setIsAddNewLocationVisible(true)} className={styles.btnBack}>
                  <IconFindLocation style={{ cursor: 'pointer' }} />
                  <Typography sx={{ cursor: 'pointer', fontWeight: '800' }}>
                    Didn't find the location?
                  </Typography>
                </div>
              )}
            </FormControl>
          </div>
          <MapUi selectedLocation={selLocation} isAddNewLocationVisible={isAddNewLocationVisible} />
        </>
      )}
    </div>
  );
};
