import { ReactNode } from 'react';
import { Grid, Button, Stack, styled } from '@mui/material';

import {
  Add as AddIcon,
  Remove as RemoveIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';

const MoreFiltersButton = styled(Button)({
  // marginTop: '17px',
  padding: '5px 5px',
  border: 'none',
  color: '#1650ff',
  textTransform: 'none',
  fontSize: '0.775rem',
  '&:hover': {
    border: 'none',
    backgroundColor: 'rgba(197, 223, 255, 0.5)',
    cursor: 'pointer',
  },
});

const ColoredIcon = (IconComponent: typeof AddIcon) =>
  styled(IconComponent)({
    color: '#1650ff',
  });

const DeleteOutlineOutlinedColored = ColoredIcon(DeleteOutlineOutlinedIcon);
const AddIconColored = ColoredIcon(AddIcon);
const RemoveIconColored = ColoredIcon(RemoveIcon);

interface IMoreFiltersControlProps {
  handleShowFilters: () => void;
  handleResetFilters: () => void;
  isResetVisible?: boolean;
  isMoreFiltersVisible?: boolean;
  children?: ReactNode;
}

export const MoreFiltersControl = (props: IMoreFiltersControlProps) => {
  const { handleShowFilters, handleResetFilters, isResetVisible, isMoreFiltersVisible, children } =
    props;

  return (
    <>
      {isMoreFiltersVisible && children && <Stack>{children}</Stack>}
      <Stack direction='row' spacing={3} alignItems='center' justifyContent='space-between'>
        <Grid item xs style={{ flexGrow: 1 }}></Grid>
        {isResetVisible && (
          <Grid item xs={4} md={3} sm={3} lg={2} sx={{ textAlign: 'right' }}>
            <MoreFiltersButton
              variant='outlined'
              sx={{ fontSize: '1.1rem' }}
              endIcon={<DeleteOutlineOutlinedColored />}
              onClick={handleResetFilters}
            >
              Reset Filters
            </MoreFiltersButton>
          </Grid>
        )}
        {/* 
        <Grid item xs={4} md={3} sm={3} lg={2} sx={{ textAlign: 'right' }}>
          <MoreFiltersButton
            variant='outlined'
            onClick={handleShowFilters}
            sx={{ fontSize: '1.1rem' }}
            endIcon={isMoreFiltersVisible ? <RemoveIconColored /> : <AddIconColored />}
          >
            {isMoreFiltersVisible ? 'Less Filters' : 'More Filters'}
          </MoreFiltersButton>
        </Grid> */}
      </Stack>
    </>
  );
};
