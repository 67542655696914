import { FormInput, SectionTitle } from '@/components';
import styles from './TariffPlanInfo.module.scss';

export const TariffPlanInfo = () => {
  return (
    <div>
      <SectionTitle number={1} title='Information about tariff plan' />
      <div className={styles.rowTitle}>
        <FormInput
          uiVariant='blank'
          name='name'
          required
          placeholder='Enter name'
          label='Name'
        />
      </div>
      <div className={styles.rowDescription}>
        <FormInput
          label='Description'
          uiVariant='blank'
          name='description'
          multiline
          required
          placeholder='Add a description of your tariff'
          minRows={undefined}
          rows={3}
          inputProps={{ maxLength: 800 }}
        />
      </div>
    </div>
  );
};
