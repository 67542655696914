import * as yup from 'yup';

import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  AdFieldCurrencyEnum,
  AdFieldFurnitureEnum,
  AdFieldLandKindEnum,
  PropertyKindEnum,
  AdFieldRenovationEnum,
  AdFieldRentFrequencyEnum,
  AdFieldStatusEnum,
  AdFieldVisibilityEnum,
  FileKindEnum,
  AdFieldFloorEnum,
  AdFieldOccupancyEnum,
  RentPeriodEnum,
  AdFieldMultilistingEnum,
  TransactionTypeEnum,
} from '@/types';

export const schemaAdForm = yup.object().shape({
  activityKind: yup
    .string()
    .oneOf(
      [ActivityKindEnum.Rent, ActivityKindEnum.Sale],
      'Choose one value from the list'
    )
    .required('ActivityKind is a required field'),

  transactionType: yup.lazy((value, context) => {
      if (context.parent.activityKind === ActivityKindEnum.Sale) {
        return yup
          .string()
          .oneOf(
            [TransactionTypeEnum.Distress, TransactionTypeEnum.Ready, TransactionTypeEnum.Resale],
            'Choose one value from the list'
          )
          .required('Transaction Type is a required field');
      } else {
        return yup.string().notRequired();
      }
    }),

  multilisting: yup
    .string()
    .oneOf(
      [AdFieldMultilistingEnum.Covered, AdFieldMultilistingEnum.NotCovered],
      'Choose one value from the list'
    )
    .required('Multilisting is a required field'),

  builtUpArea: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required('Size is a required field')
    .min(50, 'Size of the selected property type cannot be less than 50 sqft'),

  beds: yup
    .string()
    .oneOf(
      [
        AdFieldBedsEnum.Studio,
        AdFieldBedsEnum.One,
        AdFieldBedsEnum.Two,
        AdFieldBedsEnum.Three,
        AdFieldBedsEnum.Four,
        AdFieldBedsEnum.Five,
        AdFieldBedsEnum.Six,
        AdFieldBedsEnum.Seven,
        AdFieldBedsEnum.EightPlus,
      ],
      'Choose one value from the list'
    )
    .when('propertyKind', {
      is: (value: PropertyKindEnum) =>
        [
          PropertyKindEnum.Apartment,
          PropertyKindEnum.CommercialVilla,
          PropertyKindEnum.Villa,
          PropertyKindEnum.Townhouse,
          PropertyKindEnum.Penthouse,
          PropertyKindEnum.HotelApartment,
          null,
          undefined,
        ].includes(value),
      then: (schema) => schema.required('Beds is a required field'),
    })
    .nullable(),

  baths: yup
    .string()
    .oneOf(
      [
        AdFieldBathsEnum.One,
        AdFieldBathsEnum.Two,
        AdFieldBathsEnum.Three,
        AdFieldBathsEnum.Four,
        AdFieldBathsEnum.Five,
        AdFieldBathsEnum.Six,
        AdFieldBathsEnum.Seven,
        AdFieldBathsEnum.EightPlus,
      ],
      'Choose one value from the list'
    )
    .nullable(),

  floor: yup
    .string()
    .oneOf(
      [AdFieldFloorEnum.Low, AdFieldFloorEnum.Middle, AdFieldFloorEnum.High],
      'Choose one value from the list'
    )
    .nullable(),

  furniture: yup
    .string()
    .oneOf(
      [
        AdFieldFurnitureEnum.Furnished,
        AdFieldFurnitureEnum.PartlyFurnished,
        AdFieldFurnitureEnum.Unfurnished,
      ],
      'Choose one value from the list'
    )
    .nullable(),

  renovation: yup
    .string()
    .oneOf(
      [AdFieldRenovationEnum.Renovation, AdFieldRenovationEnum.NonRenovation],
      'Choose one value from the list'
    )
    .nullable(),

  occupancy: yup
    .string()
    .oneOf(
      [
        AdFieldOccupancyEnum.Tenanted,
        AdFieldOccupancyEnum.Vacant,
      ],
      'Choose one value from the list'
    )
    .nullable(),

  completionStatus: yup
    .string()
    .oneOf(
      [CompletionStatusEnum.Ready, CompletionStatusEnum.OffPlan],
      'Choose one value from the list'
    )
    .nullable(),

  toSeller: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when(['transactionType', 'completionStatus'], ([transactionType, completionStatus], schema) =>
      (transactionType === TransactionTypeEnum.Resale || 
      (transactionType === TransactionTypeEnum.Distress && completionStatus === CompletionStatusEnum.OffPlan))
        ? schema.test(
            'is-zero',
            'Enter a numeric',
            value => value !== 0
          )
          .required('Enter a numeric')
        : schema.notRequired()
    ),

  defaultRentFrequency: yup
    .string()
    .nullable()
    .test(
      'is-required-if-rent',
      'Please fill in the rental period field',
      function (value) {
        const { activityKind } = this.parent;
        if (activityKind === ActivityKindEnum.Rent) {
          return yup
            .mixed()
            .oneOf(
              [RentPeriodEnum.Monthly, RentPeriodEnum.Yearly, RentPeriodEnum.Weekly, RentPeriodEnum.Daily],
              'Invalid rental period'
            )
            .isValidSync(value);
        }
        return true;
      }
    ),

  currency: yup
    .string()
    .oneOf([AdFieldCurrencyEnum.AED, AdFieldCurrencyEnum.USD], 'Choose one value from the list')
    .required('Currency is a required field'),

  price: yup
    .number()
    .nullable()
    .test(
      'min-price-based-on-currency',
      '10000AED',
      function (value) {
        if (value === null || value === undefined) return true; // Если значение null или undefined, пропускаем проверку
        const { currency } = this.parent;
        if (currency === AdFieldCurrencyEnum.AED) {
          return value >= 10000;
        } else if (currency === AdFieldCurrencyEnum.USD) {
          return value / 0.27 >= 10000;
        }
        return true;
      }
    )
    .test(
      'is-required-if-sale',
      'Enter a numeric property price to continue',
      function (value) {
        const { activityKind } = this.parent;
        if (activityKind === ActivityKindEnum.Sale) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      }
    ),

  sellingPrice: yup.lazy((value, context) => {
    if (context.parent.transactionType === TransactionTypeEnum.Distress) {
      return yup
        .number()
        .required('Enter a numeric Selling price to continue')
        .test(
          'is-less-than-price',
          '5%',
          function (sellingPrice) {
            const { price } = this.parent;
            if (price !== undefined && sellingPrice !== undefined) {
              return sellingPrice <= price * 0.95;
            }
            return true;
          }
        );
    } else {
      return yup.number().notRequired();
    }
  }),

  priceYearly: yup
    .number()
    .nullable()
    .test(
      'is-required-if-rent',
      'Enter a numeric property price to continue',
      function (value) {
        const { activityKind, defaultRentFrequency } = this.parent;
        if (activityKind === ActivityKindEnum.Rent && defaultRentFrequency === RentPeriodEnum.Yearly) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      }
    ),

  priceMonthly: yup
    .number()
    .nullable()
    .test(
      'is-required-if-rent',
      'Enter a numeric property price to continue',
      function (value) {
        const { activityKind, defaultRentFrequency } = this.parent;
        if (activityKind === ActivityKindEnum.Rent && defaultRentFrequency === RentPeriodEnum.Monthly) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      }
    ),

  priceWeekly: yup
    .number()
    .nullable()
    .test(
      'is-required-if-rent',
      'Enter a numeric property price to continue',
      function (value) {
        const { activityKind, defaultRentFrequency } = this.parent;
        if (activityKind === ActivityKindEnum.Rent && defaultRentFrequency === RentPeriodEnum.Weekly) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      }
    ),

  priceDaily: yup
    .number()
    .nullable()
    .test(
      'is-required-if-rent',
      'Enter a numeric property price to continue',
      function (value) {
        const { activityKind, defaultRentFrequency } = this.parent;
        if (activityKind === ActivityKindEnum.Rent && defaultRentFrequency === RentPeriodEnum.Daily) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      }
    ),

  topUp: yup.boolean(),

  topUpConditions: yup.string(),

  nameEn: yup.string().nullable(),

  placeId: yup.string().nullable(),

  coordinatesLong: yup.string().nullable(),

  coordinatesLat: yup.string().nullable(),

  locationId: yup.string().required('Location is a required field'),

  description: yup
    .string()
    .trim()
    .max(8000, 'Description must be shorter than or equal to 8000 characters'),

  balcony: yup.boolean(),
  maidsRoom: yup.boolean(),
  sharedPool: yup.boolean(),
  builtInWardrobes: yup.boolean(),
  childrenPlayArea: yup.boolean(),
  sharedSpa: yup.boolean(),
  sharedGym: yup.boolean(),
  study: yup.boolean(),
  childrenPool: yup.boolean(),
  centralAc: yup.boolean(),
  coveredParking: yup.boolean(),
  walkInCloset: yup.boolean(),
  barbecueArea: yup.boolean(),
  viewOfWater: yup.boolean(),
  viewOfLandmark: yup.boolean(),
  petsAllowed: yup.boolean(),
  privateJacuzzi: yup.boolean(),
  concierge: yup.boolean(),
  partlyFurnished: yup.boolean(),
  unfurnished: yup.boolean(),
  publicParking: yup.boolean(),
  undergroundParking: yup.boolean(),
  kitchenAppliances: yup.boolean(),
  lobbyInBuilding: yup.boolean(),
  maidService: yup.boolean(),
  petsNotAllowed: yup.boolean(),
  privateGarden: yup.boolean(),
  privateGym: yup.boolean(),
  privatePool: yup.boolean(),
  security: yup.boolean(),
  tennisCourts: yup.boolean(),
  privateSpa: yup.boolean(),
  laundryFacility: yup.boolean(),

  pushInInstallments: yup.boolean(),

  propertyAdMedia: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        fileId: yup.string(),
        kind: yup
          .string()
          .oneOf([FileKindEnum.Image, FileKindEnum.Video, FileKindEnum.Plan, FileKindEnum.Link])
          .required('PropertyAdMedia[Kind] is a required field'),
        order: yup.number().required('PropertyAdMedia[Order] is a required field'),
        fileUrl: yup.string(),
      })
    )
    .required('PropertyAdMedia is a required field')
    .test(
      'min-images',
      `A minimum of four photos is required for a comprehensive listing.`,
      function (propertyAdMedia) {
        const imageCount = propertyAdMedia.filter(
          (media) => media.kind === FileKindEnum.Image
        ).length;
        return imageCount >= 4;
      }
    ),

  // propertyAdAnnouncerIds: yup.array(),

  status: yup
    .string()
    .oneOf([
      AdFieldStatusEnum.Published,
      AdFieldStatusEnum.Unpublished,
      AdFieldStatusEnum.Archived,
    ]),

  visibility: yup.string().oneOf([AdFieldVisibilityEnum.Public, AdFieldVisibilityEnum.Private]),

  publishedAt: yup.string(),
});
