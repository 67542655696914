import { IMyButtonData } from '../../../../uiComponents/types';

const FILL_COLOR = '#F8F8F8';

const DEFAULT_INPUT_PROPS_LICENSE = {
  fillColor: FILL_COLOR,
  iconInfo: true,
};

const licenseData = {
  sectionName: { number: 1, name: 'licenseData', label: 'License Data' },
  data: [
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'licenseEmail',
      label: 'License email',
      tooltip: `The email address registered in the government agent registry and on your agent's license.`,
    },
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'nameInPublicRegister',
      label: 'Name in public register',
      tooltip: `The name listed in the public agent register. This should match your official records for verification.`,
    },
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'stateIdentityIdentifier',
      label: 'State identity identifier',
      tooltip: `State Identification Number in the Public Registry`,
    },
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'licensePhone',
      label: 'License phone',
      tooltip: `The phone registered in the government agent registry and on your agent's license.`,
    },
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'stateLicenseExpirationDate',
      label: 'State license expiration date',
      tooltip: `The date your state license expires. Keep this up to date to maintain your active agent status.`,
    },
    {
      ...DEFAULT_INPUT_PROPS_LICENSE,
      name: 'stateLicenseAgency',
      label: 'Num. of state license agency',
      tooltip: `The number assigned by your state's licensing agency. This unique identifier is essential for verification and credibility.`,
    },
  ],
};

const supportButton: IMyButtonData = {
  label: 'support',
  buttonName: 'Support',
  variant: 'contained',
  iconInfo: true,
  customWidth: '191px',
  tooltip: 'This button sends a request to our call center for further assistance.',
};

export { licenseData, supportButton };
