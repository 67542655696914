import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dashboard, UnitsPdfManager } from '@/components';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum, CreateNewPlanType, NewBuildingModel } from '@/types';
import { IShortPlan, IUnit } from '@/types/offplans';

interface IUnitData {
  units: IUnit[];
  total: number;
}
interface IPlanData {
  plans: any[];
  total: number;
}

const extractIdsFromString = (queryParams: URLSearchParams): number[] =>
  queryParams.getAll('ids[]').map((id) => parseInt(id, 10));

// const extractBuildingIdFromUrl = (queryParams: URLSearchParams): number[] =>
//   queryParams.getAll('buildingIds[]').map(id => parseInt(id, 10));

const getUnits = async (unitIds: number[], dispatch: any): Promise<IUnitData | undefined> => {
  try {
    const { ok, data, status, message } = await newBuildingsProvider.postGetUnitsBatch(unitIds);
    if (ok && data.items.length > 0) {
      return { units: data.items, total: data.items.length };
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  } catch (error) {
    console.error(error);
    dispatch(appSetNotification(NotificationTypeEnum.Error, 'An unexpected error occurred', 500));
  }
};

const getPlans = async (planIds: string[], dispatch: any): Promise<IPlanData | undefined> => {
  try {
    const { ok, data, status, message } = await newBuildingsProvider.postGetPlansBatch(planIds);
    if (ok && data.items.length > 0) {
      return { plans: data.items, total: data.items.length };
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  } catch (error) {
    console.error(error);
    dispatch(appSetNotification(NotificationTypeEnum.Error, 'An unexpected error occurred', 500));
  }
};

const getOffPlanData = async (offplanId: string, dispatch: any) => {
  const { ok, data, status, message } = await newBuildingsProvider.getOffPlanById(offplanId!);
  if (ok && data) {
    return data;
  } else {
    dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
  }
};

const filterUniquePlans = (units: IUnit[]): IShortPlan[] => {
  const uniqueCombinationMap = new Map<string, IShortPlan>();

  units.forEach(unit => {
    const combinationKey = `${unit.beds}-${unit.planCode}-${unit.planId}`;

    if (!uniqueCombinationMap.has(combinationKey)) {
      const shortPlan: IShortPlan = {
        beds: unit.beds,
        planCode: unit.planCode,
        planId: unit.planId,
        media: unit.media,
      };

      uniqueCombinationMap.set(combinationKey, shortPlan);
    }
  });

  return Array.from(uniqueCombinationMap.values());
};



export const UnitsPdfManagerPage = () => {
  const { id: offplanId } = useParams();
  const dispatch = useDispatch();
  const [unitsData, setUnitsData] = useState<IUnitData>({ units: [], total: 0 });
  const [plansData, setPlansData] = useState<IPlanData>({ plans: [], total: 0 });
  const [offplanData, setOffplanData] = useState<NewBuildingModel>();

  const queryParams = new URLSearchParams(window.location.search);
  const unitIds = extractIdsFromString(queryParams);

  useEffect(() => {
    const fetchData = async () => {
      let tUnits: IUnit[] = [];
      let uniquePlans: IShortPlan[]  =[];
      if (unitIds.length > 0) {
        const resUnits = await getUnits(unitIds, dispatch);

        if (resUnits) {
          const { units, total } = resUnits;
          tUnits = units;
          setUnitsData({ units, total });
        }
      }
      if (tUnits.length > 0) {
        uniquePlans = filterUniquePlans(tUnits);
        // const resPlans = await getPlans(uniquePlans.map(el => el.planId), dispatch);

        if (uniquePlans) {
          // const { plans, total } = resPlans;          
          setPlansData({ plans: uniquePlans, total: uniquePlans.length });
        }
      }
      if (offplanId) {
        const resOffplanData = await getOffPlanData(offplanId, dispatch);
        if (resOffplanData) {
          setOffplanData(resOffplanData);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <Dashboard openLogIn>
      {offplanId && (
        <UnitsPdfManager
          offplanId={offplanId}
          offplanData={offplanData}
          units={unitsData.units}
          totalUnits={unitsData.total}
          // plans={plansData.plans}
          totalPlans={plansData.total}
        />
      )}
    </Dashboard>
  );
};
