import {
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  FormGroup,
} from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import { FC } from 'react';
import styles from './../../AdForm/AdForm.module.scss';

const ForeignBrokerVerification = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Are you sure you want to save verification data?'
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          <div style={{ marginBottom: '20px' }}>
            Upon confirmation, this user will receive the status in the “Foreign Broker” system. He
            will have access to all the functionality that relates to this type of user.
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Confirm',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={onConfirm}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default ForeignBrokerVerification;
