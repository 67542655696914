import { createContext, useState, useContext, ReactNode } from 'react';
import { DialogsType } from '@/components/uiComponents/types';

import { useSelector } from 'react-redux';

import { State, UserKindEnum } from '@/types';

interface IDialogsContext {
  openDialog: DialogsType;
  setOpenDialog: (value: DialogsType | ((prevState: DialogsType) => DialogsType)) => void;
}

const defaultState: DialogsType = {
  signUp: false,
  logIn: false,
  privacyPolicy: false,
  agreement: false,
  confirmation: false,
  resetPassword: false,
  createPassword: false,
  changePassword: false,
  operator: false,
  selectUserType: false,
  success: false,
  error: false,
  warning: false,
  info: false,
  isAdmin: false,
  tariff: false,
};

export const DialogsContext = createContext<IDialogsContext>({
  openDialog: defaultState,
  setOpenDialog: () => {},
});

export const useDialogs = () => useContext(DialogsContext);

export const DialogsProvider = ({ children }: { children: ReactNode }) => {
  const { auth: authState } = useSelector((state: State) => state);
  const [openDialog, setOpenDialog] = useState<DialogsType>({
    ...defaultState,
    isAdmin: authState.user.kind === UserKindEnum.Admin,
  });

  return (
    <DialogsContext.Provider value={{ openDialog, setOpenDialog }}>
      {children}
    </DialogsContext.Provider>
  );
};
