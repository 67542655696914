import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import * as enums from '../../../util/enums.js';
import { theme } from '../../../resources/theme';
import Translator from '../../../resources/localization/translator';
import { CometChatContext } from '../../../util/CometChatContext';

import {
  pinnedMessageContainerStyle,
  pinnedMessageWrapperStyle,
  pinnedMessageContentStyle,
  pinnedMessageTitleStyle,
  pinnedMessageTextStyle,
  pinnedMessageButtonStyle,
} from './style';
import unpinIcon from './resources/unpin.svg';

class CometChatPinnedMessage extends React.Component {
  static contextType = CometChatContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      enablePinMessage: false,
    };
  }

  componentDidMount() {
    this.enablePinMessage();
  }

  enablePinMessage = () => {
    /**
     * If pin messages feature is disabled
     */
    this.context.FeatureRestriction.isPinMessagesEnabled()
      .then((response) => {
        if (response === true) {
          this.setState({ enablePinMessage: true });
        } else {
          this.setState({ enablePinMessage: false });
        }
      })
      .catch((error) => {
        this.setState({ enableMessageReaction: false });
      });
  };

  toggleTooltip = (event, flag) => {
    const elem = event.target;

    if (flag) {
      elem.setAttribute('title', elem.dataset.title);
    } else {
      elem.removeAttribute('title');
    }
  };

  unpinMessage = () => {
    this.props.actionGenerated(enums.ACTIONS['UNPIN_MESSAGE'], this.props.pinnedMessage);
  };

  render() {
    // If pin messages feature is disabled
    if (this.state.enablePinMessage === false) {
      return null;
    }

    const { pinnedMessage } = this.props;

    return (
      <div
        css={pinnedMessageContainerStyle(this.props)}
        className='pinned-message__container pinned-message'
      >
        <div css={pinnedMessageWrapperStyle()} className='pinned-message__wrapper'>
          <div css={pinnedMessageContentStyle()} className='pinned-message__content'>
            <div css={pinnedMessageTitleStyle(this.props)} className='pinned-message__title'>
              {Translator.translate('PINNED_MESSAGE', this.props.lang)}
            </div>
            <div css={pinnedMessageTextStyle(this.props)} className='pinned-message__text'>
              {pinnedMessage.data.text}
            </div>
          </div>
          <button
            type='button'
            onMouseEnter={(event) => this.toggleTooltip(event, true)}
            onMouseLeave={(event) => this.toggleTooltip(event, false)}
            css={pinnedMessageButtonStyle(unpinIcon, this.props)}
            className='pinned-message__button'
            data-title={Translator.translate('UNPIN_MESSAGE', this.props.lang)}
            onClick={this.unpinMessage}
          />
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatPinnedMessage.defaultProps = {
  theme: theme,
  actionGenerated: () => {},
};

CometChatPinnedMessage.propTypes = {
  theme: PropTypes.object,
  actionGenerated: PropTypes.func.isRequired,
  pinnedMessage: PropTypes.object.isRequired,
};

export { CometChatPinnedMessage };
