import queryString from 'query-string';
import {
  ResponseType,
  AdsSortingType,
  PaginationType,
  NewBuildingsFilteringType,
  NewBuildingsResponseType,
  NewBuildingsGetAllRequestType,
  NewBuildingsAdminFilteringType,
  NewBuildingsAdminGetAllRequestType,
  NewBuildingModel,
  BuildingsType,
  CreateNewPlanType,
  NewPlansGetAllRequestType,
  ITransactionsPayload,
  ITransactionsType,
} from '@/types';
import { IUnitSearchQueryParams } from '@/types/requests';
import { apiClient } from '@/providers';
import { IUnitSearchQueryResponse } from '@/types/responses';
import { IUnit, TMediaObject } from '@/types/offplans';

interface IApiResponse {
  data: {
    items: IUnit[];
  };
}

export const newBuildingsProvider = {
  getOffPlans: async (
    filteringObj: NewBuildingsFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const newFilteringObj = filteringObj.paymentPlan ? filteringObj : { ...filteringObj, paymentPlan: undefined }
    const requestObject: NewBuildingsGetAllRequestType = {
      ...paginationObj,
      sort: [sortingObj],
      filter: newFilteringObj,
    };
    const response = await apiClient('/newConstruction/search', 'POST', requestObject);
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as NewBuildingsResponseType;
    return { ...response, data };
  },

  getAdminOffPlans: async (
    filteringObj: NewBuildingsAdminFilteringType,
    paginationObj: PaginationType,
    query: string,
  ): Promise<ResponseType> => {
    const requestObject: NewBuildingsAdminGetAllRequestType = {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      filter: filteringObj,
      query: query,
    };
    const response = await apiClient('/newConstruction/admin/search', 'POST', requestObject);
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as NewBuildingsResponseType;
    return { ...response, data };
  },

  getOffPlanById: async (id: string) => {
    const response = await apiClient<NewBuildingModel>(`/newConstruction/${id}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  createNewBuilding: async (id: string, name: string) => {
    const response = await apiClient(`/newConstruction/${id}/building`, 'POST', { name: name });
    return response;
  },

  editNewBuilding: async (id: string, buildingId: string, name: string) => {
    const response = await apiClient(`/newConstruction/${id}/building/${buildingId}`, 'PATCH', { name: name });
    return response;
  },

  deleteNewBuilding: async (id: string, buildingId: string) => {
    const response = await apiClient(`/newConstruction/${id}/building/${buildingId}`, 'DELETE');
    return response;
  },

  getBuildings: async (id: string) => {
    const paramsStr = queryString.stringify({ limit: 50, offset: 0 });
    const response = await apiClient(`/newConstruction/${id}/buildings?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as BuildingsType;
    return { ...response, data };
  },

  createNewPlan: async (constructionId: string, payload: CreateNewPlanType) => {
    const response = await apiClient(`/newConstruction/${constructionId}/plan`, 'POST', payload);

    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  editPlan: async (constructionId: string, planId: string, payload: CreateNewPlanType) => {
    const response = await apiClient(`/newConstruction/${constructionId}/plan/${planId}`, 'PATCH', payload);

    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  deletePlan: async (constructionId: string, planId: string,) => {
    const response = await apiClient(`/newConstruction/${constructionId}/plan/${planId}`, 'DELETE');
    return response;
  },

  getPlans: async (constructionId: string, buildingId?: string, limit?: number) => {
    const requestObject = {
      limit: limit || 50,
      offset: 0,
      sort: [],
      ...(buildingId && { filter: { buildingId } }),
    };

    const response = await apiClient(`/newConstruction/${constructionId}/plan/search`, 'POST', requestObject);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as BuildingsType;
    return { ...response, data };
  },

  postGetUnitsBatch: async (ids: number[]) => {
    const requestObject = {
      ids,
      limit: 10,
      offset: 0,
      sort: [],
    };

    const response = await apiClient(`/newConstruction/unit/batch`, 'POST', requestObject);
    return { ...response, data: response?.data || null };
  },

  postGetPlansBatch: async (ids: string[]) => {
    const requestObject = {
      ids,
      limit: 10,
      offset: 0,
      sort: [],
    };

    const response = await apiClient(`/newConstruction/plan/batch`, 'POST', requestObject);
    return { ...response, data: response?.data || null };
  },

  searchPlans: async (payload: IUnitSearchQueryParams) => {
    const { newConstructionId, limit = 50, offset = 0, sort = [], filter = {} } = payload;

    const requestObject = {
      newConstructionId,
      limit,
      offset,
      sort,
      filter,
    };

    const response = await apiClient(`/newConstruction/unit/search`, 'POST', requestObject);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as IUnitSearchQueryResponse;
    return { ...response, data };
  },

  getAllUnitsByOffplanId: async (offplanId: string) => {
    const requestObject = {
      newConstructionId: offplanId,
      limit: 50,
      offset: 0,
      sort: [{
        "field": "price",
        "order": "ASC"
      }],
    };

    const response = await apiClient(`/newConstruction/unit/search`, 'POST', requestObject);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as IUnitSearchQueryResponse;
    return { ...response, data };
  },

  getPlanById: async (constructionId: string, planId?: string) => {
    const response = await apiClient(`/newConstruction/${constructionId}/plan/${planId}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  getTransactions: async (payload: ITransactionsPayload): Promise<ResponseType> => {
    const response = await apiClient('/transactions', 'POST', (payload));
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as ITransactionsType;
    return { ...response, data };
  },
};
