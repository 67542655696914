import { Control, FieldValues } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { MyTextField, MyPhoneInput, MyButton, MyDialog } from '@/components';
import { IErrors } from '../../../../uiComponents/types';
import styles from './AuthenticationData.module.scss';
import { icons } from '../../Icons/icons';

export const AuthenticationData = ({
  errors,
  control,
  setValue,
  open,
  onClose,
  onSubmit,
}: {
  errors: IErrors;
  control: Control;
  setValue: (name: string, value: any, options?: Partial<FieldValues>) => void;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  return (
    <MyDialog
      dataQa='settings_dialog'
      open={open}
      onClose={onClose}
      dialogTitle='Change Authentication data'
      width='635'
    >
      <Typography style={{ textAlign: 'center', marginBottom: '25px' }}>
        You can change your authentication data to log into the application. Change only to those
        email and phone numbers to which you have access.
      </Typography>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <div className={styles.container}>
          <Stack spacing={3} sx={{ width: '100%' }}>
            <MyTextField
              errors={errors}
              control={control}
              data={{
                name: 'email',
                label: 'Your new email',
                placeholder: 'Enter your new email',
                maxLength: 80,
              }}
            />
            <div className={styles.myPhoneInput}>
              <MyPhoneInput
                errors={errors}
                control={control}
                setValue={setValue}
                data={{
                  name: 'phone',
                  label: 'Your new phone',
                  placeholder: 'Enter your new phone number',
                }}
              />
            </div>
            <MyButton
              data={{
                buttonName: 'Change',
                buttonsIcon: true,
                icon: <icons.EditProfile />,
                colorIcons: '#ffffff',
                variant: 'contained',
              }}
              onClick={onSubmit}
            />
          </Stack>
        </div>
      </form>
    </MyDialog>
  );
};
