import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { CometChatContext } from '../../../util/CometChatContext';
import { theme } from '../../../resources/theme';
import { CometChatAvatar, CometChatUserPresence } from '../../Shared';
import { getTimeStampForLastMessage } from '../../../util/common';
import Translator from '../../../resources/localization/translator';

import {
  listItem,
  itemThumbnailStyle,
  itemDetailStyle,
  itemRowStyle,
  itemNameStyle,
  itemLastMsgTimeStyle,
  itemLastMsgStyle,
} from './style';

class CometChatMessagesListItem extends React.PureComponent {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  applySearchRequest = (text) => {
    const searchValueLength = this.props.searchValue.length;
    const startIndex = text.toUpperCase().indexOf(this.props.searchValue.toUpperCase());
    const cutValue = text.slice(startIndex, startIndex + searchValueLength);
    const startStr = text.slice(0, startIndex);
    const endStr = text.slice(startIndex + searchValueLength);
    return (
      <>
        {startStr}
        <span>{cutValue}</span>
        {endStr}
      </>
    );
  };

  render() {
    const { theme, message, handleClick } = this.props;

    const lastMessageTimeStamp = (
      <span css={itemLastMsgTimeStyle(theme)} className='item__details__timestamp'>
        {getTimeStampForLastMessage(message.sentAt, this.context.language)}
      </span>
    );

    return (
      <div css={listItem(theme)} className='list__item' onClick={() => handleClick(message)}>
        <div css={itemThumbnailStyle()} className='list__item__thumbnail'>
          <CometChatAvatar user={message.sender} />
          <CometChatUserPresence
            status={message.sender.status}
            borderColor={theme.borderColor.white}
            borderWidth='2px'
          />
        </div>
        <div
          css={itemDetailStyle()}
          className='list__item__details'
          dir={Translator.getDirection(this.context.language)}
        >
          <div css={itemRowStyle()} className='item__details_block_one'>
            <div css={itemNameStyle(theme)} className='item__details__name'>
              {message.sender.name}
            </div>
            {lastMessageTimeStamp}
          </div>
          <div css={itemRowStyle()} className='item__details_block_two'>
            <div css={itemLastMsgStyle(theme)} className='item__details__last-message'>
              {this.applySearchRequest(message.text)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CometChatMessagesListItem.defaultProps = {
  theme,
};

CometChatMessagesListItem.propTypes = {
  theme: PropTypes.object,
};

export { CometChatMessagesListItem };
