export const modalWrapperStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  return {
    minWidth: '350px',
    width: '100%',
    maxWidth: '500px',
    minHeight: '250px',
    height: '450px',
    maxHeight: '600px',
    overflow: 'hidden',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '4',
    margin: '0 auto',
    boxShadow: 'rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px',
    borderRadius: '14px',
    display: 'block',
    [mq[0]]: {
      width: '100%',
      height: '100%',
    },
    [mq[1]]: {
      width: '100%',
      height: '100%',
    },
    [mq[2]]: {
      width: '100%',
      height: '100%',
    },
  };
};

export const modalCloseStyle = (img, { theme }) => {
  return {
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '25px',
    right: '30px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
    cursor: 'pointer',
  };
};

export const modalBodyStyle = () => {
  return {
    padding: '24px 34px',
    height: '100%',
    width: '100%',
  };
};

export const captionStyle = ({ theme }) => {
  return {
    fontSize: '24px',
    marginBottom: '15px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: `${theme.color.primary}`,
    paddingBottom: '27px',
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      backgroundColor: `${theme.borderColor.primary}`,
      position: 'absolute',
      left: '0px',
      right: '0px',
      top: '85px',
    },
  };
};

export const modalTableStyle = () => {
  return {
    borderCollapse: 'collapse',
    margin: '0',
    padding: '0',
    width: '100%',
    height: 'calc(100% - 77px)',
    display: 'block',
    overflowY: 'auto',
    tr: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
  };
};

export const tableBodyStyle = ({ theme }) => {
  return {
    display: 'block',
    tr: {
      height: '40px',
      margin: '5px 0',
      td: {
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        '&:hover': {
          backgroundColor: `${theme.backgroundColor.lightBlue3}`,
        },
      },
    },
  };
};
