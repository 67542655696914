import { ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';
import { FormCheckboxWithWatch } from '@/components/uiComponents/FormCheckbox/FormCheckboxWithWatch';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AdMediaType } from '@/types';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export const Gallery = ({
  images,
  selectionState = {
    selectAll: false,
  },
  countSelectedImages,
  setCountSelectedImages,
}: {
  images: AdMediaType[] | NewBuildingsMediaType[];
  selectionState?: {
    selectAll: boolean;
  };
  countSelectedImages?: number;
  setCountSelectedImages?: (prevCount: number) => void;
}) => {
  const isAbove600 = useMediaQuery('(min-width:600px)');
  const isAbove900 = useMediaQuery('(min-width:900px)');
  let columns = 2;
  if (isAbove900) {
    columns = 4;
  } else if (isAbove600) {
    columns = 3;
  }

  const { setValue } = useFormContext();

  useEffect(() => {
    images.forEach((image) => {
      setValue(`image-${image.id}`, selectionState.selectAll);
    });
  }, [selectionState, images, setValue]);

  return (
    <ImageList sx={{ width: '100%', m: 0 }} cols={columns}>
      {images.map((image) => (
        <GalleryItem 
          key={image.id} 
          image={image} 
          countSelectedImages={countSelectedImages} 
          setCountSelectedImages={setCountSelectedImages}
        />
      ))}
    </ImageList>
  );
};

function GalleryItem({ 
  image, 
  setCountSelectedImages, 
  countSelectedImages = 0, 
}: { 
  image: AdMediaType | NewBuildingsMediaType, 
  countSelectedImages?: number; 
  setCountSelectedImages?: (prevCount: number) => void 
}) {
  const { setValue, getValues } = useFormContext();

  return (
    <ImageListItem
      onClick={() => {
        const fieldName = `image-${image.id}`;
        const currentValue = getValues(fieldName);
        if (setCountSelectedImages) {
          setCountSelectedImages(!currentValue ? countSelectedImages + 1 : countSelectedImages - 1);
        }
        setValue(fieldName, !currentValue);
      }}
    >
      <img
        src={image.fileUrl}
        alt={`image-${image.id}`}
        style={{
          borderRadius: '4px',
          width: '100%',
          height: 'auto',
          aspectRatio: '16 / 9',
          objectFit: 'cover',
        }}
        loading='lazy'
      />
      <ImageListItemBar
        sx={{ background: 'none' }}
        position='top'
        actionIcon={<FormCheckboxWithWatch name={`image-${image.id}`} label='' />}
      />
    </ImageListItem>
  );
}
