import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ActivityKindEnum,
  PropertyKindEnum,
  CompletionStatusEnum,
  AdFieldStatusEnum,
  AdFieldVisibilityEnum,
  AdFormModel,
  AdModel,
  FileKindEnum,
  NotificationTypeEnum,
  State,
  UserKindEnum,
  TransactionTypeEnum,
  AdFieldMultilistingEnum,
  RentPeriodEnum,
  CreateAdType,
} from '@/types';
import { adsProvider } from '@/providers';
import { locationProvider } from '@/providers/locationProvider';
import { appSetNotification } from '@/store';
import { schemaAdForm } from '@/schemas';
import { Loader, MyButton, MyDialog, PageNotFound, SectionHeader } from '@/components';
import { clearAllInformation } from './AdForm.init';

import { ActivityType } from './ActivityType';
import { TransactionType } from './TransactionType';
import { PropertyType } from './PropertyType';
import { PropertyInformation } from './PropertyInformation';
import { PropertyPriceRent } from './PropertyPrice';
import { PropertyLocation } from './PropertyLocation';
import { FeaturesAmenities } from './FeaturesAmenities';
import { PropertyDescription } from './PropertyDescription';
import { UploadPhotos } from './UploadPhotos';
import { UploadVideo } from './UploadVideo';
import { ResponsibleUser } from './ResponsibleUser';
import IconArrow from './iconArrow.svg';
import IconHouse from './houseLine.svg';
import styles from './AdForm.module.scss';

const MIN_PHOTOS = 3;
export interface Image {
  id: string;
  url: string;
}

export const AdForm = () => {
  const dispatch = useDispatch();
  const { id: userId, kind: userKind, employer } = useSelector(({ auth }: State) => auth.user);
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isEditForm = !!id;
  const [draftAttempt, setDraftAttempt] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(isEditForm);
  const [creatorId, setCreatorId] = useState<string>(userId);

  const [publishType, setPublishType] = useState<AdFieldStatusEnum>(AdFieldStatusEnum.Unpublished);
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [isShownPublishedDialog, setIsShownPublishedDialog] = useState<boolean>(false);
  const [isShowAttentionDialog, setIsShowAttentionDialog] = useState<boolean>(false);
  const [isWarningDialog, setIsWarningDialog] = useState<boolean>(false);

  const [activityKind, setActivityKind] = useState<ActivityKindEnum>();

  const [addressEn, setAddressEn] = useState<string>();
  const [nameEn, setNameEn] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [coordinatesLat, setCoordinatesLat] = useState<number>();
  const [coordinatesLong, setCoordinatesLong] = useState<number>();
  const [locationId, setLocationId] = useState<string | null>();

  const [balcony, setBalcony] = useState<boolean>();
  const [maidsRoom, setMaidsRoom] = useState<boolean>();
  const [sharedPool, setSharedPool] = useState<boolean>();
  const [builtInWardrobes, setBuiltInWardrobes] = useState<boolean>();
  const [childrenPlayArea, setChildrenPlayArea] = useState<boolean>();
  const [sharedSpa, setSharedSpa] = useState<boolean>();
  const [sharedGym, setSharedGym] = useState<boolean>();
  const [study, setStudy] = useState<boolean>();
  const [centralAc, setCentralAc] = useState<boolean>();
  const [coveredParking, setCoveredParking] = useState<boolean>();
  const [barbecueArea, setBarbecueArea] = useState<boolean>();
  const [petsAllowed, setPetsAllowed] = useState<boolean>();
  const [concierge, setConcierge] = useState<boolean>();
  const [publicParking, setPublicParking] = useState<boolean>();
  const [kitchenAppliances, setKitchenAppliances] = useState<boolean>();
  const [lobbyInBuilding, setLobbyInBuilding] = useState<boolean>();
  const [maidService, setMaidService] = useState<boolean>();
  const [petsNotAllowed, setPetsNotAllowed] = useState<boolean>();
  const [privateGarden, setPrivateGarden] = useState<boolean>();
  const [privateGym, setPrivateGym] = useState<boolean>();
  const [privatePool, setPrivatePool] = useState<boolean>();
  const [security, setSecurity] = useState<boolean>();
  const [tennisCourts, setTennisCourts] = useState<boolean>();
  const [privateSpa, setPrivateSpa] = useState<boolean>();
  const [laundryFacility, setLaundryFacility] = useState<boolean>();

  const [gallery, setGallery] = useState<Image[]>([]);
  const [video, setVideo] = useState<Image[]>([]);

  const [propertyAdAnnouncerIds, setPropertyAdAnnouncerIds] = useState([]);

  const [isTransactionVisible, setIsTransactionVisible] = useState<boolean>(false);
  const [isPriceRentVisible, setIsPriceRentVisible] = useState<boolean>(false);
  const [isPropertyTypeVisible, setIsPropertyTypeVisible] = useState<boolean>(false);
  const [isLocationVisible, setIsLocationVisible] = useState<boolean>(false);
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);
  const [isFirstPageVisible, setIsFirstPageVisible] = useState<boolean>(true);
  const [isSecondPageVisible, setIsSecondPageVisible] = useState<boolean>(false);
  const [isPageChanged, setIsPageChanged] = useState<boolean>(false);
  const [errorRentPrice, setErrorRentPrice] = useState<boolean>(false);
  const [propertyInformationText, setPropertyInformationText] = useState<string>('');
  const [isReviewLocation, setIsReviewLocation] = useState<boolean>(false);
  const [isCreatorError, setIsCreatorError] = useState<boolean>(false);
  const [isAddNewLocationVisible, setIsAddNewLocationVisible] = useState<boolean>(false);

  const formMethods = useForm<AdFormModel>({
    resolver: yupResolver(schemaAdForm) as any,
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    getValues,
    watch,
  } = formMethods;

  const isNotANumber = (value: any) => {
    return isNaN(value);
  };

  const formValues = getValues();
  const { propertyKind } = formValues;
  const transactionType = watch('transactionType');

  useEffect(() => {
    if (formValues.addressEn && !isAddNewLocationVisible) {
      setIsBtnVisible(true);
    }
  }, [formValues.addressEn]);

  useEffect(() => {
    const activityKindMapping = {
      [ActivityKindEnum.Rent]: 'Rent',
      [ActivityKindEnum.Sale]: 'Sale',
    };
    const transactionTypeMapping = {
      [TransactionTypeEnum.Ready]: 'Ready',
      [TransactionTypeEnum.Resale]: 'Resale',
      [TransactionTypeEnum.Distress]: 'Distress',
    };
    const propertyKindMapping = {
      [PropertyKindEnum.Apartment]: 'Apartment',
      [PropertyKindEnum.Townhouse]: 'Townhouse',
      [PropertyKindEnum.Villa]: 'Villa',
      [PropertyKindEnum.Floor]: 'Floor',
      [PropertyKindEnum.Penthouse]: 'Penthouse',
      [PropertyKindEnum.ResidentialLand]: 'Residential Land',
      [PropertyKindEnum.VillaCompound]: 'Villa Compound',
      [PropertyKindEnum.HotelApartment]: 'Hotel Apartment',
      [PropertyKindEnum.CommercialVilla]: 'Commercial Villa',
      [PropertyKindEnum.Warehouse]: 'Warehouse',
      [PropertyKindEnum.IndustrialLand]: 'Industrial Land',
      [PropertyKindEnum.CommercialLand]: 'Commercial Land',
      [PropertyKindEnum.Office]: 'Office',
      [PropertyKindEnum.LabourCamp]: 'Labour Camp',
      [PropertyKindEnum.CommercialBuilding]: 'Whole Building',
      [PropertyKindEnum.Shop]: 'Shop',
    };
    const priceRentMapping = {
      [RentPeriodEnum.Yearly]: 'Yearly',
      [RentPeriodEnum.Monthly]: 'Monthly',
      [RentPeriodEnum.Weekly]: 'Weekly',
      [RentPeriodEnum.Daily]: 'Daily',
    };
    const { activityKind, nameEn, defaultRentFrequency } = formValues;
    if (isSecondPageVisible && activityKind && propertyKind) {
      const activityKindText = activityKindMapping[activityKind];
      const transactionTypeText = transactionType ? transactionTypeMapping[transactionType] : '';
      const rentFrequencyText =
        defaultRentFrequency && activityKind === ActivityKindEnum.Rent
          ? priceRentMapping[defaultRentFrequency]
          : '';
      const secondStep =
        activityKind === ActivityKindEnum.Rent ? rentFrequencyText : transactionTypeText;
      const propertyKindText = propertyKindMapping[propertyKind];
      setPropertyInformationText(
        `${activityKindText}  /  ${secondStep}  /  ${propertyKindText}  /  ${nameEn}`
      );
    }
  }, [isSecondPageVisible, transactionType]);

  useEffect(() => {
    const getAdById = async (id: string) => {
      setIsLoading(true);
      const { ok, data: responseData, status, message } = await adsProvider.getAdById(id);
      if (ok) {
        const data = responseData as AdModel;
        setCreatorId(data.propertyAdCreator.id || data.creatorId);
        setValue('activityKind', data.activityKind);
        setActivityKind(data.activityKind);
        setValue('transactionType', data.transactionType);
        setValue('propertyKind', data.propertyKind);
        setValue('builtUpArea', data.builtUpArea);
        setValue('beds', data.beds);
        setValue('baths', data.baths);
        setValue('floor', isNotANumber(data.floor) ? data.floor : null);
        setValue('furniture', data.furniture);
        setValue('renovation', data.renovation);
        setValue('occupancy', data.occupancy);
        setValue('completionStatus', data.completionStatus);
        setValue('currency', data.currency);
        setValue('price', data.price);
        setValue('sellingPrice', data.sellingPrice);
        setValue('handover', data.handover);
        setValue('postHandover', data.postHandover);
        setValue('toSeller', data.toSeller);
        setValue('toDeveloper', data.toDeveloper);
        setValue('defaultRentFrequency', data.defaultRentFrequency);
        setValue('priceYearly', data.priceYearly);
        setValue('priceMonthly', data.priceMonthly);
        setValue('priceWeekly', data.priceWeekly);
        setValue('priceDaily', data.priceDaily);
        setValue('multilisting', data.multilisting || AdFieldMultilistingEnum.Covered);
        setValue('topUp', data.topUp);
        setValue('topUpConditions', data.topUpConditions || '');

        setValue('addressEn', data.addressEn ? data.addressEn : data.nameEn);
        setAddressEn(data.addressEn ? data.addressEn : data.nameEn);
        setValue('nameEn', data.nameEn);
        setNameEn(data.nameEn);
        setValue('placeId', data.placeId);
        setValue('locationId', data?.locationId);
        setLocationId(data?.locationId);
        setValue('coordinatesLong', data.coordinatesLong);
        setCoordinatesLong(
          parseFloat(data.coordinatesLong && data.coordinatesLong.replace(',', '.'))
        );
        setValue('coordinatesLat', data.coordinatesLat);
        setCoordinatesLat(parseFloat(data.coordinatesLat && data.coordinatesLat.replace(',', '.')));
        if (data.fromXml === false && data.locationId === null) {
          setValue('buildingName', data.nameEn);
        }

        setValue('title', data.title);
        setValue('description', data.description);

        setValue('balcony', data.balcony);
        setBalcony(data.balcony);
        setValue('maidsRoom', data.maidsRoom);
        setMaidsRoom(data.maidsRoom);
        setValue('sharedPool', data.sharedPool);
        setSharedPool(data.sharedPool);
        setValue('builtInWardrobes', data.builtInWardrobes);
        setBuiltInWardrobes(data.builtInWardrobes);
        setValue('childrenPlayArea', data.childrenPlayArea);
        setChildrenPlayArea(data.childrenPlayArea);
        setValue('sharedSpa', data.sharedSpa);
        setSharedSpa(data.sharedSpa);
        setValue('sharedGym', data.sharedGym);
        setSharedGym(data.sharedGym);
        setValue('study', data.study);
        setStudy(data.study);
        setValue('centralAc', data.centralAc);
        setCentralAc(data.centralAc);
        setValue('coveredParking', data.coveredParking);
        setCoveredParking(data.coveredParking);
        setValue('barbecueArea', data.barbecueArea);
        setBarbecueArea(data.barbecueArea);
        setValue('petsAllowed', data.petsAllowed);
        setPetsAllowed(data.petsAllowed);
        setValue('concierge', data.concierge);
        setConcierge(data.concierge);
        setValue('publicParking', data.publicParking);
        setPublicParking(data.publicParking);
        setValue('kitchenAppliances', data.kitchenAppliances);
        setKitchenAppliances(data.kitchenAppliances);
        setValue('lobbyInBuilding', data.lobbyInBuilding);
        setLobbyInBuilding(data.lobbyInBuilding);
        setValue('maidService', data.maidService);
        setMaidService(data.maidService);
        setValue('petsNotAllowed', data.petsNotAllowed);
        setPetsNotAllowed(data.petsNotAllowed);
        setValue('privateGarden', data.privateGarden);
        setPrivateGarden(data.privateGarden);
        setValue('privateGym', data.privateGym);
        setPrivateGym(data.privateGym);
        setValue('privatePool', data.privatePool);
        setPrivatePool(data.privatePool);
        setValue('security', data.security);
        setSecurity(data.security);
        setValue('tennisCourts', data.tennisCourts);
        setTennisCourts(data.tennisCourts);
        setValue('privateSpa', data.privateSpa);
        setPrivateSpa(data.privateSpa);
        setValue('laundryFacility', data.laundryFacility);
        setLaundryFacility(data.laundryFacility);

        setValue('view', data.view);
        setValue('rentedTill', data.rentedTill);
        setValue('fromXml', data.fromXml);

        const media = data.propertyAdMedia?.[0]?.order === null ? null : data.propertyAdMedia;
        setValue('propertyAdMedia', media);
        setGallery(
          data.propertyAdMedia
            .filter((item) => item.kind === FileKindEnum.Image)
            .sort((a, b) => a.order - b.order)
            .map((item) => ({ id: item.fileId!, url: item.fileUrl! }))
        );
        setVideo(
          data.propertyAdMedia
            .filter((item) => item.kind === FileKindEnum.Video)
            .map((item) => ({ id: item.fileId!, url: item.fileUrl! }))
        );

        // const ids = data?.propertyAdAnnouncers?.map((item) => item.id)[0] || '';
        setValue('propertyAdAnnouncerIds', []);
        setPropertyAdAnnouncerIds([]);

        setIsTransactionVisible(true);
        setIsPropertyTypeVisible(true);
        setIsLocationVisible(true);
        setIsBtnVisible(true);
        setIsFirstPageVisible(false);
        setIsSecondPageVisible(true);
        if (data.activityKind === ActivityKindEnum.Rent) {
          setIsPriceRentVisible(true);
        }

        if (!data.locationId && !data.fromXml) {
          setIsReviewLocation(true);
        }
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    };

    if (id) {
      getAdById(id);
    }
  }, []);

  useEffect(() => {
    const firstErrorElement = Object.keys(errors)[0];
    setIsShownConfirmDialog(false);
    if (firstErrorElement) {
      console.error('Form errors:', errors);
      const elementForFocus = document.getElementsByClassName('Mui-error')[0];
      if (elementForFocus) {
        const offset =
          elementForFocus.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          150;
        window.scrollTo({
          behavior: 'smooth',
          top: offset,
        });
      }
    }
  }, [errors, draftAttempt]);

  const handleCancel = () => (isEditForm ? navigate('/ads/my') : navigate('/'));

  const handleSave = () => {
    setIsShownConfirmDialog(true);
    setPublishType(AdFieldStatusEnum.Unpublished);
  };

  const handlePublish = () => {
    if (locationId) {
      setIsShownConfirmDialog(true);
      setPublishType(AdFieldStatusEnum.Published);
    } else {
      setIsShowAttentionDialog(true);
    }
  };

  const handleCloseConfirmDialog = () => setIsShownConfirmDialog(false);

  const handleClosePublishedDialog = () => setIsShownPublishedDialog(false);

  const handleDonePublishedDialog = () => navigate('/ads/my');

  const processAdSubmission = async (data: AdFormModel) => {
    setIsLoading(true);
    let newData;
    if (!isEditForm) {
      newData = { ...data, owner: userKind };
    }
    let response = isEditForm
      ? await adsProvider.updateAdById(id, data as unknown as AdModel)
      : await adsProvider.createAd(newData as unknown as AdModel);
    const { ok, status, message, data: responseData } = response;
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      setIsShownConfirmDialog(false);
    } else {
      if (data.status === AdFieldStatusEnum.Published) {
        setTitle(data.title);
        reset();
        setIsShownConfirmDialog(false);
        setIsShownPublishedDialog(true);
      } else {
        const newData = responseData as CreateAdType;
        const adId = id ? id : newData?.id;
        if (!locationId && data.districtId && data.buildingName) {
          const { ok, status, message } = await locationProvider.verificationRequest({
            userId: userId,
            propertyAdId: adId,
            districtId: data.districtId,
            propertyName: data.buildingName,
          });
          if (!ok) {
            dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
          }
        }
        navigate('/ads/my');
      }
    }
    setIsLoading(false);
  };

  const submitHandler: SubmitHandler<AdFormModel> = async (values) => {
    if (
      values.propertyAdMedia &&
      values.propertyAdMedia.filter((media) => media.kind === 'image').length < MIN_PHOTOS
    ) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'Upload at least 3 photos'));
      setIsShownConfirmDialog(false);
      return;
    }
    const data: AdFormModel = {
      ...values,
      creatorId: creatorId,
      toDeveloper: Number(values.toDeveloper),
      status: AdFieldStatusEnum.Published,
      visibility: AdFieldVisibilityEnum.Public,
      propertyAdAnnouncerIds: [],
      description: values.description || '',
      publishedAt:
        publishType === AdFieldStatusEnum.Published ? new Date().toISOString() : undefined,
    };
    if (userKind === UserKindEnum.Agency && creatorId === userId) {
      setIsCreatorError(true);
    } else {
      processAdSubmission(data);
    }
  };

  const handleSaveAsDraft: SubmitHandler<AdFormModel> = async (values) => {
    // setDraftAttempt((prev) => prev + 1);
    const data: AdFormModel = {
      ...values,
      creatorId: creatorId,
      builtUpArea: Number(values.builtUpArea) || null,
      price: Number(values.price) || null,
      sellingPrice: Number(values.sellingPrice) || null,
      toDeveloper: Number(values.toDeveloper),
      propertyAdMedia: values?.propertyAdMedia?.length === 0 ? [] : values.propertyAdMedia,
      status: AdFieldStatusEnum.Unpublished,
      visibility: AdFieldVisibilityEnum.Public,
      propertyAdAnnouncerIds: [],
      description: values.description || '',
    };
    processAdSubmission(data);
  };

  const handleClearInformation = () => {
    setIsTransactionVisible(false);
    setIsPropertyTypeVisible(false);
    setIsLocationVisible(false);
    setIsBtnVisible(false);
    setIsPriceRentVisible(false);
    clearAllInformation(setValue);
  };

  const handleNext = () => {
    if (!errorRentPrice) {
      window.scrollTo({ top: 0, behavior: 'auto' });
      setIsFirstPageVisible(false);
      setIsSecondPageVisible(true);
    }
    if (formValues.transactionType === TransactionTypeEnum.Distress) {
      setValue(
        'completionStatus',
        formValues.completionStatus || CompletionStatusEnum.OffPlan
      );
    }
  };

  const handleBack = () => {
    if (!isReviewLocation) {
      setIsWarningDialog(true);
    }
  };

  const handleOkNoticeDialog = () => {
    setIsFirstPageVisible(true);
    setIsSecondPageVisible(false);
    setIsPageChanged(true);
    setIsWarningDialog(false);
  };

  const amenitiesIsVisible =
    propertyKind &&
    ![
      PropertyKindEnum.Office,
      PropertyKindEnum.Warehouse,
      PropertyKindEnum.ResidentialLand,
      PropertyKindEnum.IndustrialLand,
      PropertyKindEnum.CommercialLand,
      PropertyKindEnum.Shop,
    ].includes(propertyKind);

  const isRent = activityKind === ActivityKindEnum.Rent;
  const buttonBackValue = 'Previous step';

  if (isLoading) {
    return <Loader />;
  }

  if (
    userKind === UserKindEnum.Foreign ||
    userKind === UserKindEnum.Admin ||
    (userKind === UserKindEnum.Broker && employer === null) ||
    (userKind === UserKindEnum.Manager && employer === null) ||
    (isEditForm && String(creatorId) !== userId && userKind !== UserKindEnum.Agency)
  ) {
    return <PageNotFound />;
  }

  return (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <Box component='form' autoComplete='off'>
          <SectionHeader title={isEditForm ? 'Edit listing' : 'Create a new listing'} />
          {isFirstPageVisible && (
            <div>
              <div onClick={() => navigate('/ads/my')} className={styles.btnBack}>
                <img src={IconArrow} alt='' style={{ cursor: 'pointer' }} />
                <Typography sx={{ cursor: 'pointer', fontWeight: '800' }}>
                  Back to My Property
                </Typography>
              </div>
              <ActivityType
                setIsTransactionVisible={setIsTransactionVisible}
                setIsPropertyTypeVisible={setIsPropertyTypeVisible}
                setIsLocationVisible={setIsLocationVisible}
                setIsBtnVisible={setIsBtnVisible}
                isPageChanged={isPageChanged}
                setIsPageChanged={setIsPageChanged}
                setIsPriceRentVisible={setIsPriceRentVisible}
                setErrorRentPrice={setErrorRentPrice}
              />
              {isTransactionVisible && !isRent && (
                <TransactionType
                  isPropertyTypeVisible={isPropertyTypeVisible}
                  setIsPropertyTypeVisible={setIsPropertyTypeVisible}
                  setIsLocationVisible={setIsLocationVisible}
                  setIsBtnVisible={setIsBtnVisible}
                  setIsPageChanged={setIsPageChanged}
                />
              )}
              {isPriceRentVisible && (
                <PropertyPriceRent
                  isPropertyTypeVisible={isPropertyTypeVisible}
                  setIsPropertyTypeVisible={setIsPropertyTypeVisible}
                  setIsLocationVisible={setIsLocationVisible}
                  setIsBtnVisible={setIsBtnVisible}
                  isPageChanged={isPageChanged}
                  setIsPageChanged={setIsPageChanged}
                  errorRentPrice={errorRentPrice}
                  setErrorRentPrice={setErrorRentPrice}
                />
              )}
              {isPropertyTypeVisible && (
                <PropertyType
                  setIsLocationVisible={setIsLocationVisible}
                  setIsPageChanged={setIsPageChanged}
                />
              )}
              {isLocationVisible && (
                <PropertyLocation
                  nameEn={nameEn}
                  coordinatesLat={coordinatesLat}
                  setCoordinatesLat={setCoordinatesLat}
                  coordinatesLong={coordinatesLong}
                  setCoordinatesLong={setCoordinatesLong}
                  setIsBtnVisible={setIsBtnVisible}
                  isPageChanged={isPageChanged}
                  setIsPageChanged={setIsPageChanged}
                  setLocationId={setLocationId}
                  isAddNewLocationVisible={isAddNewLocationVisible}
                  setIsAddNewLocationVisible={setIsAddNewLocationVisible}
                />
              )}
              {isBtnVisible && (
                <div className={styles.submitButtons}>
                  <MyButton
                    data={{
                      buttonName: 'Next',
                      customWidth: '155px',
                      variant: 'contained',
                      buttonType: 'button',
                    }}
                    onClick={handleNext}
                  />
                  <MyButton
                    data={{
                      buttonName: 'Clear information',
                      customWidth: '200px',
                      styleType: 'cancel',
                      variant: 'outlined',
                      buttonType: 'button',
                    }}
                    onClick={handleClearInformation}
                  />
                </div>
              )}
            </div>
          )}
          {isSecondPageVisible && (
            <div>
              <div className={styles.btnContainer}>
                <div className={styles.breadcrumbs}>
                  <img src={IconHouse} alt='' />
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '800',
                      opacity: '50%',
                      marginBottom: '-3px',
                    }}
                  >
                    {propertyInformationText}
                  </Typography>
                </div>
                {isReviewLocation ? (
                  <Typography sx={{ fontWeight: '800' }}>
                    Your location
                    <strong style={{ color: '#1650FF' }}>{` ${nameEn} `}</strong>
                    in
                    <strong style={{ color: '#1650FF' }}>{` ${addressEn} `}</strong>
                    is being clarified.
                  </Typography>
                ) : (
                  <div onClick={handleBack} className={styles.btnBack}>
                    <img src={IconArrow} alt='' style={{ cursor: 'pointer' }} />
                    <Typography sx={{ cursor: 'pointer', fontWeight: '800' }}>
                      Previous step
                    </Typography>
                  </div>
                )}
              </div>
              <PropertyInformation />
              {amenitiesIsVisible && (
                <FeaturesAmenities
                  balcony={balcony}
                  maidsRoom={maidsRoom}
                  sharedPool={sharedPool}
                  builtInWardrobes={builtInWardrobes}
                  childrenPlayArea={childrenPlayArea}
                  sharedSpa={sharedSpa}
                  sharedGym={sharedGym}
                  study={study}
                  centralAc={centralAc}
                  coveredParking={coveredParking}
                  barbecueArea={barbecueArea}
                  petsAllowed={petsAllowed}
                  concierge={concierge}
                  publicParking={publicParking}
                  kitchenAppliances={kitchenAppliances}
                  lobbyInBuilding={lobbyInBuilding}
                  maidService={maidService}
                  petsNotAllowed={petsNotAllowed}
                  privateGarden={privateGarden}
                  privateGym={privateGym}
                  privatePool={privatePool}
                  security={security}
                  tennisCourts={tennisCourts}
                  privateSpa={privateSpa}
                  laundryFacility={laundryFacility}
                />
              )}
              <PropertyDescription />
              <UploadPhotos gallery={gallery} />
              <UploadVideo gallery={video} />
              {userKind === UserKindEnum.Agency && (
                <ResponsibleUser
                  setCreatorId={setCreatorId}
                  isCreatorError={isCreatorError}
                  setIsCreatorError={setIsCreatorError}
                />
              )}
              <div className={styles.submitButtons}>
                <MyButton
                  data={{
                    buttonName: 'Publish',
                    customWidth: '155px',
                    variant: 'contained',
                    buttonType: 'button',
                  }}
                  onClick={handlePublish}
                />
                <MyButton
                  data={{
                    buttonName: 'Save as a draft',
                    customWidth: '242px',
                    styleType: 'cancel',
                    variant: 'outlined',
                    buttonType: 'button',
                  }}
                  onClick={() => {
                    const values = getValues();
                    handleSaveAsDraft(values);
                  }}
                />
                <MyButton
                  data={{
                    buttonName: 'Cancel',
                    customWidth: '157px',
                    styleType: 'cancel',
                    variant: 'outlined',
                    buttonType: 'button',
                  }}
                  onClick={handleCancel}
                />
              </div>
            </div>
          )}
          {/* <UploadPlan gallery={plan} /> */}
        </Box>
        <MyDialog
          open={isShownConfirmDialog}
          onClose={handleCloseConfirmDialog}
          dialogTitle='Save Changes:'
          width='750'
        >
          <div className={styles.dialogDescription}>Confirm your updated information.</div>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonId: 'id_button_confirm_create_edit_ad',
                className: 'className_button_confirm_create_edit_ad',
                buttonName: 'Confirm',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={handleSubmit(submitHandler)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={handleCloseConfirmDialog}
            />
          </div>
        </MyDialog>
        <MyDialog
          open={isShownPublishedDialog}
          onClose={handleClosePublishedDialog}
          dialogTitle={'Your list has been successfully published'}
          width='750'
        >
          <div className={styles.dialogDescription}>
            Your listing <strong style={{ color: '#1650FF' }}>&quot;{title}&quot;</strong> has been
            successfully posted in the system and is available for other users to view. Your listing
            will be published for a <strong style={{ color: '#1650FF' }}>period of 30 days.</strong>
            You can update it through the{' '}
            <strong style={{ color: '#1650FF' }}>"My properties"</strong> section by clicking the{' '}
            <strong style={{ color: '#1650FF' }}>"Update"</strong> button.
          </div>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Confirm',
                customWidth: '351px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={handleDonePublishedDialog}
            />
          </div>
        </MyDialog>
        <MyDialog
          open={isWarningDialog}
          onClose={() => setIsWarningDialog(false)}
          dialogTitle='Notice'
          width='750'
        >
          <div className={styles.dialogDescription}>
            Changing any parameter on the first page will result in{' '}
            <strong>the complete loss </strong>
            of previously entered data on the second page.
          </div>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Ok',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={handleOkNoticeDialog}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={() => setIsWarningDialog(false)}
            />
          </div>
        </MyDialog>
        <MyDialog
          open={isShowAttentionDialog}
          onClose={() => setIsShowAttentionDialog(false)}
          dialogTitle='Verification of location data'
          width='750'
        >
          <div className={styles.dialogDescription}>
            Your location is being clarified. You can save your listing as a draft. As soon as the
            administrator confirms the location, we will notify you by email, and you can publish
            your listing.
          </div>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Confirm',
                customWidth: '351px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={() => setIsShowAttentionDialog(false)}
            />
          </div>
        </MyDialog>
      </FormProvider>
    </div>
  );
};
