import { Box, Link } from '@mui/material';
import {
  EnvelopeIcon,
  LocationFooterIcon,
  PhoneIcon,
  UserGuideIcon,
} from '@/components/Footer/Icons';

export const ContactSection = () => (
  <Box component='section' style={{ alignSelf: 'center' }}>
    <h4 style={{ fontSize: '16px', margin: '8px 0' }}>Contacts</h4>
    <Box
      sx={{
        display: 'flex',
        gap: '10px 40px',
        flexWrap: 'wrap',
        fontSize: '12px',
        width: {
          xs: '215px',
          md: 'auto',
        },
      }}
    >
      <Link
        style={{ display: 'flex', width: '215px', gap: '10px' }}
        href='https://www.google.com/maps/search/?api=1&query=Dubai+Silicon+Oasis,+Building+A1,+Dubai,+United+Arab+Emirates'
        target='_blank'
        underline='none'
        color='inherit'
      >
        <Box>
          <LocationFooterIcon style={{ color: '#FFF', width: '18px', height: '18px' }} />
        </Box>
        <span style={{ lineHeight: '2' }}>
          Dubai Silicon Oasis, Building A1, Dubai, United Arab Emirates
        </span>
      </Link>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '175px',
        }}
      >
        <Link
          style={{ display: 'flex', gap: '10px', marginBottom: '2px' }}
          href='mailto:info@onlineproperty.io'
          target='_blank'
          underline='none'
          color='inherit'
        >
          <Box>
            <EnvelopeIcon style={{ color: '#FFF' }} />
          </Box>
          info@onlineproperty.io
        </Link>
        <Link
          style={{ display: 'flex', width: '220px', gap: '11px' }}
          href='tel:+971501355885'
          target='_blank'
          underline='none'
          color='inherit'
        >
          <Box>
            <PhoneIcon style={{ color: '#FFF' }} />
          </Box>
          +971 50 135 5885
        </Link>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '215px',
          marginTop: '-5px',
        }}
      >
        <Link
          style={{ display: 'flex', gap: '10px', fontSize: '16px', fontWeight: '800' }}
          href='https://onlineproperty.io/online-property-user-guide/'
          target='_blank'
          underline='none'
          color='inherit'
        >
          <Box>
            <UserGuideIcon />
          </Box>
          User Guide
        </Link>
        <Link
          style={{ display: 'flex', width: '220px', fontSize: '18px' }}
          href='https://onlineproperty.io/online-property-user-guide/'
          target='_blank'
          underline='none'
          color='inherit'
        >
          to help you get started
        </Link>
      </Box>
    </Box>
  </Box>
);
