import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import { offPlanProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { AdFieldStatusEnum, NotificationTypeEnum } from '@/types';
import { icons } from './Icons/icons';
import { Loader } from '@/components';


const StyledMenuItem = styled(MenuItem)({
  fontWeight: '400',
});

export const BuildingMenu = ({
  id,
  open,
  handleClose,
  anchorEl,
  setChangeStatus,
}: {
  id: string;
  open: boolean;
  handleClose: () => void;
  anchorEl: Element | null;
  setChangeStatus?: (value: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search: queryParamsStr } = useLocation();
  const queryParams = new URLSearchParams(queryParamsStr);
  const status = queryParams.get('status');
  const dispatch = useDispatch();

  const onHandlerClick = async (fieldStatus: AdFieldStatusEnum) => {
    setIsLoading(true);
    const { ok, status, message } = await offPlanProvider.updateById(id!, {status: fieldStatus});
    if(!ok){
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      if(setChangeStatus){
        setChangeStatus(fieldStatus);
      }
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuItem onClick={() => navigate(`/admin/offplan/${id}`)}>
          <ListItemIcon>
            <icons.Edit />
          </ListItemIcon>
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={() => navigate(`/admin/units/create/${id}`)}>
          <ListItemIcon>
            <icons.Upload />
          </ListItemIcon>
          Upload units
        </StyledMenuItem>
        {(!status || status !== AdFieldStatusEnum.Published) && (
          <StyledMenuItem onClick={() => onHandlerClick(AdFieldStatusEnum.Published)}>
            <ListItemIcon>
              <icons.Published />
            </ListItemIcon>
            Published
          </StyledMenuItem>
        )}
        {status !== AdFieldStatusEnum.Unpublished && (
          <StyledMenuItem onClick={() => onHandlerClick(AdFieldStatusEnum.Unpublished)}>
            <ListItemIcon>
              <icons.Unpublished />
            </ListItemIcon>
            Unpublished
          </StyledMenuItem>
        )}
        {status !== AdFieldStatusEnum.Archived && (
          <StyledMenuItem onClick={() => onHandlerClick(AdFieldStatusEnum.Archived)}>
            <ListItemIcon>
              <icons.Archive />
            </ListItemIcon>
            Archive
          </StyledMenuItem>
        )}
      </Menu>
    </>
  );
};
