import { AdModel, CreateNewPlanType, NewBuildingModel, UserModel } from '@/types';
import { SelectedDataType } from '../AdsPdfManager';
import { ClassicView } from './ClassicView';
import { UnitsPdfDocument } from './ClassicView/UnitsPdfDocument';
import { IUnit } from '@/types/offplans';

export function GenerateUnitPDF({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  isNumberOfFloorsShown,
  templateName,
  units,
}: {
  showContent: SelectedDataType;
  offplanData?: NewBuildingModel;
  personalInfo: UserModel;
  currentAgencyInfo?: UserModel;
  isNumberOfFloorsShown?: boolean;
  templateName: string;
  units: IUnit[];
}) {
  const ViewComponents = {
    'classic-view': ClassicView,
    // 'all-in-one-view': AllInOneView,
    // 'lux-view': LuxView,
    // 'one-page': OnePageView,
    // 'portable-view': PortableView,
  };

  const SelectedView = ViewComponents[templateName as keyof typeof ViewComponents];

  return (
    <UnitsPdfDocument
      offplanData={offplanData}
      personalInfo={personalInfo}
      currentAgencyInfo={currentAgencyInfo}
      showContent={showContent}
      isNumberOfFloorsShown={isNumberOfFloorsShown}
      units={units}
    />
  );
}
