import { AdCreatorType, UserKindEnum, UserModel } from '@/types';
import { Section, Title } from '@/components/PdfManager/PdfDocument/VerticalView/Section';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { IconText } from '@/components/PdfManager/PdfDocument/VerticalView/Badge';
import { EnvelopeIcon, PhoneIcon, TelegramIcon, WhatsappIcon, InstagramIcon, LinkedinIcon} from '@/assets/pdf';
import { Picture } from '@/components/PdfManager/PdfDocument/VerticalView/Picture';
import { getInitials } from '@/utils/stringAvatar';
import moment from 'moment';
import { SocialIcon } from './SocialIcon';

export const ContactSection = ({ contactInfo, showBroker, showAgency }: { contactInfo?: AdCreatorType | UserModel, showBroker?: boolean, showAgency?: boolean }) => {
  if (!contactInfo) return null;

  const { name, additionalPhones, additionalEmails, linkedin, whatsapp, telegram, instagram, kind, employer } = contactInfo;
  const createdTime = new Date();

  let avatarSrc;
  if ('avatar' in contactInfo) {
    avatarSrc = contactInfo.avatar;
  } else if (Array.isArray(contactInfo.media) && contactInfo.media[0]) {
    avatarSrc = contactInfo.media[0].fileUrl;
  }

  const formatDate = (value: string | null | Date) =>
    value !== null ? moment(value).format('D MMMM YYYY, H:mm') : null;

  const breakName = (name: string): string[] => [name];

  return (
    <Section>
      {(showBroker || showAgency) && <View 
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#E9EEF1',
          padding: '50px 40px',
        }}
      >
          {showBroker && <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '25%' }}>
            <Text
              style={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 40,
              }}
              hyphenationCallback={e => breakName(e)}
            >
              {name}
            </Text>
            <Text
              style={{
                fontSize: 24,
              }}
            >
              CREATED {formatDate(createdTime)}
            </Text>
          </View>}
          {showBroker && <View style={{ width: '25%' }}>

              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px', }}>
                {additionalPhones && (
                  <IconText iconSrc={PhoneIcon} wh={30} size={'24px'} fontWeight={600}>
                    {additionalPhones[0]}
                  </IconText>
                )}
                {additionalEmails && (
                  <IconText iconSrc={EnvelopeIcon} wh={30} size={'24px'} fontWeight={600}>
                    {additionalEmails[0]}
                  </IconText>
                )}
              </View>
            </View>
          }
        <View style={{ width: '25%' }}>
          {showBroker && 
            <View style={{display: 'flex', flexDirection: 'row', gap: '22px', justifyContent: 'center'}}>
              {linkedin && <SocialIcon iconSrc={LinkedinIcon} link={linkedin}/>}
              {instagram && <SocialIcon iconSrc={InstagramIcon} link={instagram}/>}
              {whatsapp && <SocialIcon iconSrc={WhatsappIcon} link={`https://wa.me/${whatsapp}`}/>}
              {telegram && <SocialIcon iconSrc={TelegramIcon} link={`https://t.me/${telegram}`}/>}
            </View>
          }
          
        </View>
        <View style={{ width: '25%' }}>
          {showAgency && 
            <View style={{display: 'flex', flexDirection: 'column'}}>
              <Text
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: 40,
                  textAlign: 'right',
                }}
                hyphenationCallback={e => breakName(e)}
              >
                {employer?.name}
              </Text>
              <Text
                style={{
                  fontSize: 24,
                  textAlign: 'right',
                }}
              >
                AGENCY
              </Text>
            </View>
          }
        </View>
      </View>}
    </Section>
  );
};
