import { Style } from '@react-pdf/types';
import { Image } from '@react-pdf/renderer';

export const Picture = ({
  id,
  src,
  style,
}: {
  id?: string;
  src?: string;
  style?: Style | Style[];
}) => {
  if (!src) return null;

  return (
    <Image
      id={id}
      style={style}
      src={{
        uri: src,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
        },
        body: '',
      }}
    />
  );
};
