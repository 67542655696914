import queryString from 'query-string';
import { ResponseType } from '@/types';
import { apiClient } from '@/providers';
import { CommentsResponseType } from '@/types';

export const commentsProvider = {
  createComment: async (id: string, message: string): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}/comment`, 'POST', {
      message,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  getComments: async (id: string) => {
    const limit = 50;
    const offset = 0;
    const paramsStr = queryString.stringify({ limit, offset });

    const response = await apiClient(`/propertyAd/${id}/comments?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as CommentsResponseType;
    return { ...response, data };
  },

  getRepliesComments: async (idComment: string) => {
    const limit = 50;
    const offset = 0;
    const paramsStr = queryString.stringify({ limit, offset });

    const response = await apiClient(
      `/propertyAd/comment/${idComment}/replies?${paramsStr}`,
      'GET'
    );
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as CommentsResponseType;
    return { ...response, data };
  },

  editComment: async (id: string, idComment: string, message: string): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}/comment/${idComment}`, 'PATCH', {
      message,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }
    return { ...response };
  },

  replyComment: async (id: string, idComment: string, message: string): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}/comment/${idComment}/reply`, 'POST', {
      message,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }
    return { ...response };
  },

  likeComment: async (id: string) => {
    const response = await apiClient(`/propertyAd/comment/${id}/like`, 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  unlikeComment: async (id: string) => {
    const response = await apiClient(`/propertyAd/comment/${id}/unlike`, 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  deleteComment: async (id: string, idComment: string) => {
    const response = await apiClient(`/propertyAd/${id}/comment/${idComment}`, 'DELETE');
    return response;
  },
};
