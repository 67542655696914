import { useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { googleMapsOptions } from '@/utils/googleMapsOptions';
import { SectionTitle, MyButton, FormInput } from '@/components';

import styles from './PropertyLocation.module.scss';
import { IOffPlanModel } from '@/types';

import { MapUi } from '@/components/Map/Map';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';
import {
  getDepthLabel,
  LocationAutocomplete,
} from '@/components/LocationAutocomplete/LocationAutocomplete';

const OPFormHelperText = styled(FormHelperText)`
  margin: 0 0 5px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  span {
    color: var(--secondary, #f00);
  }
`;

interface IPropertyLocationFields {
  addressEn?: string;
  nameEn?: string;
  coordinatesLat?: number;
  coordinatesLong?: number;
}

interface IPropertyLocationProps {
  fields: IPropertyLocationFields;
  setNewValue: (field: Partial<IOffPlanModel>) => void;
  errors: FieldErrors<IOffPlanModel>;
  source?: string;
  isLocation: boolean;
  setIsLocation: (value: boolean) => void;
}

export const PropertyLocation = ({
  fields,
  setNewValue,
  errors,
  source,
  isLocation,
  setIsLocation,
}: IPropertyLocationProps) => {
  const { isLoaded } = useJsApiLoader(googleMapsOptions);
  // const { addressEn, nameEn, coordinatesLat, coordinatesLong } = fields;
  const { setValue, getValues, clearErrors } = useFormContext();
  const formValues = getValues();
  const { addressEn } = formValues;

  const {
    selectedLocation,
    locations,
    setSelectedLocation,
    setInputAddressValue,
    inputAddressValue,
    setLocations,
  } = useLocationAutocomplete({ defaultValue: addressEn });

  const titleError = errors.title ? true : false;

  const errorMessage = errors?.coordinatesLong?.message || errors?.coordinatesLat?.message;

  const updateLocationDetails = (location: LocationModel | null) => {
    setNewValue({ addressEn: location?.[location?.kind] || inputAddressValue });
    setSelectedLocation(location);
    setValue(
      'addressEn',
      location ? `${getDepthLabel(location)}, ${location[location.kind]}` : null
    );
    setValue('nameEn', location?.[location?.kind] ?? null);
    setValue('locationId', location?.id || null);
    setValue('coordinatesLat', String(location?.latitude) || null);
    setValue('coordinatesLong', String(location?.longitude) || null);
  };

  const handleClear = () => {
    setSelectedLocation(null);
    setValue('addressEn', null);
    setValue('nameEn', null);
    setValue('locationId', null);
    setValue('coordinatesLat', null);
    setValue('coordinatesLong', null);
  };

  const onHandleClick = () => {
    handleClear();
    setLocations([]);
    setIsLocation(!isLocation);
    setInputAddressValue('');
    setValue('title', '');
  };

  const saveAddressEn = (e: any) => {
    const { value } = e.target;
    setValue('addressEn', value);
    if (value.length < 3) {
      handleClear();
    }
  };

  const saveUserMarker = (coordinatesLong: string, coordinatesLat: string) => {
    setValue('coordinatesLat', coordinatesLat);
    setValue('coordinatesLong', coordinatesLong);
    clearErrors(['coordinatesLong', 'coordinatesLat']);
  };

  return (
    <div>
      <SectionTitle number={3} title='Location' />
      {isLoaded && (
        <>
          <div className={styles.group}>
            <FormControl sx={{ width: '100%' }}>
              <OPFormHelperText className={styles.label}>
                {isLocation ? 'Enter location' : 'Title'}
                <span>*</span>
              </OPFormHelperText>
              <Stack direction='row' spacing={2}>
                {isLocation ? (
                  <LocationAutocomplete
                    inputAddressValue={inputAddressValue}
                    setInputAddressValue={setInputAddressValue}
                    locations={locations}
                    handleClear={handleClear}
                    onSelect={updateLocationDetails}
                  />
                ) : (
                  <FormInput
                    uiVariant='blank'
                    name='title'
                    required
                    placeholder='Enter title'
                    onChange={saveAddressEn}
                    isLocation={isLocation}
                  />
                )}
                <Stack sx={{ marginTop: '-10px' }}>
                  <MyButton
                    data={{
                      buttonName: isLocation ? 'Add new location' : 'Enter existing location',
                      customWidth: '220px',
                      variant: 'contained',
                      buttonType: 'button',
                    }}
                    onClick={onHandleClick}
                  />
                </Stack>
              </Stack>
            </FormControl>
          </div>
          <MapUi
            selectedLocation={selectedLocation}
            source={source}
            setLocations={setLocations}
            isLocation={isLocation}
            saveUserMarker={saveUserMarker}
          />
        </>
      )}
    </div>
  );
};
