import { ReactNode } from 'react';

export const TabPage = ({
  children,
  value,
  index,
}: {
  children?: ReactNode;
  index: number;
  value: number;
}) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {value === index && <>{children}</>}
  </div>
);
