import { ChangeEvent, useState } from 'react';
import {
  Box,
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import styles from './UnitsViewFilters.module.scss';
import { FormInput } from '../uiComponents';
import classNames from 'classnames';
import { SelectItemButton } from './SelectItemButton';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { classifyPrice } from '@/utils/priceFormatter';
import { bedsValues } from './UnitsView.init';
import {
  IUnitSearchQueryFilter,
  IUnitSearchQueryParams,
  IUnitViewBulding,
  IUnitViewPlans,
} from '@/types/requests';

interface IUnitsViewMiddleSectionProps {
  buildingsList?: IUnitViewBulding[];
  plansList?: IUnitViewPlans[];
  onChangeFilters: (args: Partial<IUnitSearchQueryParams>) => void;
  defaultFilterOptions: IUnitSearchQueryFilter;
}

export const UnitsViewFilters = ({
  buildingsList,
  plansList,
  onChangeFilters,
  defaultFilterOptions,
}: IUnitsViewMiddleSectionProps) => {
  const [filters, setFilters] = useState<IUnitSearchQueryFilter>(defaultFilterOptions);

  const priceLabel =
    !filters.priceFrom && !filters.priceTo
      ? `Price (AED)`
      : `${classifyPrice(filters.priceFrom || 0)} - ${classifyPrice(filters.priceTo || 0)}`;
  const areaLabel =
    !filters.areaFrom && !filters.areaTo
      ? `Area (sqft)`
      : `${filters.areaFrom} - ${filters.areaTo}`;

  const updateFilters = (name: keyof IUnitSearchQueryFilter, value: any) => {
    if (filters[name] !== value) {
      setFilters((currentFilters: IUnitSearchQueryFilter) => ({
        ...currentFilters,
        [name]: value,
      }));
      onChangeFilters({ filter: [{ ...filters, [name]: value }] });
    }
  };

  const handleChangeInput =
    (name: keyof IUnitSearchQueryFilter) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const parsedInt = parseInt(target.value, 10);
      const newValue = isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt;
      if (['buildingId', 'floor', 'planCode'].includes(name)) {
        const t: string[] = Array.isArray(filters[name]) ? (filters[name] as string[]) : [];
        if (!t.some((el: string) => el === newValue.toString())) {
          setFilters((currentFilters: IUnitSearchQueryFilter) => ({
            ...currentFilters,
            [name]: [...t, newValue],
          }));
        }
      } else {
        if (filters[name] !== newValue) {
          setFilters((currentFilters: IUnitSearchQueryFilter) => ({
            ...currentFilters,
            [name]: newValue,
          }));
        }
      }
    };

  const handleMultiSelectChange = (property: string) => (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setFilters({ ...filters, [property]: typeof value === 'string' ? value.split(',') : value });
  };

  const handleChangeFilters = () => {
    onChangeFilters({ filter: [filters] });
  };

  const getBuildingNamesByIds = (selectedIds: any[]) => {
    return selectedIds
      .map((id: string) => buildingsList?.find((building) => building.id === id)?.name || '')
      .filter((name) => name)
      .join(', ');
  };

  return (
    <div className={styles.filtersContainer}>
      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        <Box sx={{ width: '31%' }}>
          <Select
            name='buildingId'
            multiple
            displayEmpty
            onClose={handleChangeFilters}
            value={filters.buildingId}
            onChange={handleMultiSelectChange('buildingId')}
            renderValue={(selected) =>
              selected.length > 0 ? getBuildingNamesByIds(selected) : 'Select a building'
            }
            sx={{ width: '100%' }}
          >
            {buildingsList?.map((building) => (
              <MenuItem key={building.id} value={building.id}>
                <Checkbox
                  size='small'
                  checked={(filters.buildingId ?? []).indexOf(building.id) > -1}
                />
                <ListItemText primary={building.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ width: '31%' }}>
          <Select
            value={'-1'}
            name='beds'
            onClose={handleChangeFilters}
            sx={{ width: '100%' }}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              Beds
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
              <div className={styles.selectInnerContent}>
                {bedsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={filters.beds}
                    onChange={(e) => {
                      updateFilters('beds', value);
                    }}
                  />
                ))}
              </div>
            </div>
          </Select>
        </Box>

        <Box sx={{ width: '31%' }}>
          <Select
            name='areaSQFT'
            sx={{ width: '100%' }}
            value={'-1'}
            onClose={handleChangeFilters}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {areaLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterArea)}>
              <div className={classNames(styles.selectInnerContent, styles.filterArea)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Minimum</span>
                  </FormHelperText>
                  <TextField
                    value={filters.areaFrom}
                    onChange={handleChangeInput('areaFrom')}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Maximum</span>
                  </FormHelperText>
                  <TextField
                    value={filters.areaTo}
                    onChange={handleChangeInput('areaTo')}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={
                      (filters.areaFrom ?? 0) > (filters.areaTo ?? 0) && (filters.areaTo ?? 0) !== 0
                    }
                    helperText={
                      (filters.areaFrom ?? 0) > (filters.areaTo ?? 0) && (filters.areaTo ?? 0) !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Box>

        <Box sx={{ width: '31%' }}>
          <FormInput
            name='floor'
            onChange={handleChangeInput('floor')}
            type='number'
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ width: '31%' }}>
          <Select
            name='planCode'
            multiple
            value={filters.planCode}
            displayEmpty
            onChange={handleMultiSelectChange('planCode')}
            onClose={handleChangeFilters}
            sx={{ width: '100%' }}
            placeholder='Select plan'
            renderValue={(selected) => {
              if (selected.length === 0) {
                return 'Select plan';
              }
              return selected.join(', ');
            }}
          >
            <MenuItem disabled value=''>
              <em>Select plan</em>
            </MenuItem>
            {plansList?.map((plan) => (
              <MenuItem key={plan.id} value={plan.planCode}>
                <Checkbox checked={(filters.planCode ?? []).indexOf(plan.planCode) > -1} />
                <ListItemText primary={plan.planCode} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ width: '31%' }}>
          <Select
            sx={{ width: '100%' }}
            value={'-1'}
            onClose={handleChangeFilters}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {priceLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
              <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Minimum</span>
                  </FormHelperText>
                  <TextField
                    value={filters.priceFrom}
                    onChange={handleChangeInput('priceFrom')}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Maximum</span>
                  </FormHelperText>
                  <TextField
                    value={filters.priceTo}
                    onChange={handleChangeInput('priceTo')}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={
                      (filters.priceFrom ?? 0) > (filters.priceTo ?? 0) &&
                      (filters.priceTo ?? 0) !== 0
                    }
                    helperText={
                      (filters.priceFrom ?? 0) > (filters.priceTo ?? 0) &&
                      (filters.priceTo ?? 0) !== 0
                        ? 'Max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Box>
      </Box>
    </div>
  );
};
