import { Avatar, Stack, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { stringAvatar } from '@/utils';
import { DialogTariffPlan } from '@/components';
import { UserModel, UserTariffPlanEnum, HeaderDialogsEnum } from '@/types';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

const UserName = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  color: '#1650FF',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const UserType = styled(Typography)({
  opacity: 0.5,
});

interface IContactBlockProps {
  user: {
    name: string | undefined;
    id: string | undefined;
    avatar: string | null | undefined;
  };
  userMe?: UserModel;
  isTariffFree?: boolean;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}

export const ContactBlock: React.FC<IContactBlockProps> = ({
  user,
  userMe,
  isTariffFree,
  handleClickOpenDialog,
}) => {
  const isCheckedMy = userMe?.id === user.id;
  const userName = isTariffFree ? 'Creator of request' : user?.name;
  const requestCreatorHref = isCheckedMy ? '/profile/view' : `/users/${user.id}`;

  return (
    <Stack
      alignItems='flex-start'
      spacing={1}
      sx={{ width: '100%', filter: isTariffFree ? 'blur(5px)' : 'blur(0px)' }}
    >
      <Link
        href={isTariffFree ? undefined : requestCreatorHref}
        title={user.name}
        style={{ textDecoration: 'none', color: '#2A3842', overflow: 'hidden' }}
        onClick={() => isTariffFree && handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)}
      >
        <Stack direction='row' alignItems='center' spacing={1} style={{ position: 'relative' }}>
          <Avatar
            src={user.avatar ? user.avatar : ''}
            {...stringAvatar(user.name)}
            sx={{ width: 24, height: 24, fontSize: 12 }}
          />
          <Stack sx={{ maxWidth: '240px' }}>
            <UserName>{userName}</UserName>
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};
