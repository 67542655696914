import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { MyTextField, MyButton, MyPhoneInput, MyLink, MySelect, MyAutocomplete, MyDialog } from '@/components';
import { StyleLabel } from '../../uiComponents/styled';

const DialogContentRenderer = ({
  open,
  onClose,
  control,
  setValue,
  dialogData,
  onSubmit,
  errors,
  valueUserType,
  handleOpenDialog,
  onChange,
  onClick,
  changeAutocompleteValue,
  changeNewAutocompleteValue,
  kind,
  selectedSelect,
  emailOrPhoneInput,
  setLogInPhoneValue,
}) => {
  const { isLoading } = useSelector(({ app }) => app);

  const handleButtonClick = (actionKey) => {
    switch (actionKey) {
      case 'confirmAction':
        break;
      case 'createPassword':
        break;
      case 'resetPassword':
        break;
      case 'signUpAction':
        // onSubmit();
        break;
      case 'logInAction':
        break;
      case 'supportAction':
        break;
      case 'closeOperatorSuccessAction':
        onClose();
        break;
      case 'okAction':
        handleOpenDialog('success');
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const handleLinkClick = (actionKey) => {
    switch (actionKey) {
      case 'supportAction':
        handleOpenDialog('operator');
        break;
      case 'forgotPasswordAction':
        onClick();
        handleOpenDialog('resetPassword');
        onClose();
        break;
      case 'signUpAction':
        handleOpenDialog('logIn');
        handleOpenDialog('signUp');
        break;
      case 'logInAction':
        handleOpenDialog('logIn');
        handleOpenDialog('signUp');
        break;
      case 'agreementAction':
        handleOpenDialog('agreement');
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const onClickSecondLink = (action) => {
    if(action==='privacyPolicyAction'){
      handleOpenDialog('privacyPolicy')
    }
  }

  if (!open) return null;
  return (
    <MyDialog
      dataQa={dialogData.dataQa}
      open={open}
      onClose={onClose}
      dialogTitle={dialogData.dialogTitle.label}
      control={control}
      width={dialogData.width}
    >
      <StyleLabel style={{ textAlign: 'center', marginBottom: '15px'}}>
        {dialogData.dialogTitle.description}
      </StyleLabel>
      <form onSubmit={onSubmit} style={{ width: '100%', margin: '5px 0' }}>
        <Stack spacing={1.5} sx={{ width: '100%' }}>
          {dialogData.dialogContent.map((content, index) => {
            const commonProps = {
              data: content,
              control,
              errors: errors,
              disabled: content.disabled,
            };
            if (!content.initialVisibility) {
              return null;
            }
            switch (content.typeOfComponent) {
              case 'input':
                return (
                  <MyTextField
                    key={`${content.name}-${index}`}
                    {...commonProps}
                    emailOrPhoneInput={emailOrPhoneInput}
                    onChange={onChange}
                    onClick={onClick}
                  />
                );
              case 'phoneInput':
                return <MyPhoneInput 
                  key={content.name}
                  emailOrPhoneInput={emailOrPhoneInput}
                  setLogInPhoneValue={setLogInPhoneValue}
                  onChange={onChange}
                  setValue={setValue}
                  {...commonProps} 
                />;
              case 'select':
                return (
                  <MySelect
                    key={`${content.name}-${index}`}
                    {...commonProps}
                    onClick={() => handleOpenDialog('selectUserType')}
                    selectedSelect={selectedSelect}
                    sx={{backgroundColor: '#F1F7FF'}}
                  />
                );
              case 'autocomplete':
                return (
                  <MyAutocomplete
                    key={`${content.name}-${index}`}
                    {...commonProps}
                    disabled={ kind === 'manager' ? false : (content.name === 'profileVerificationOptions' ? (valueUserType ? false : true) : true )}
                    changeAutocompleteValue={changeAutocompleteValue}
                    changeNewAutocompleteValue={changeNewAutocompleteValue}
                  />
                );
              case 'button':
                return (
                  <MyButton
                    key={`${content.name}-${index}`}
                    data={content}
                    disabled={valueUserType?.length === 0 || isLoading}
                    onClick={() => handleButtonClick(content.actionKey)}
                  />
                );
              case 'link':
                return (
                  <MyLink
                    key={`${content.name}-${index}`}
                    data={content}
                    disabled={valueUserType?.length === 0}
                    onClickSecondLink={() => onClickSecondLink(content.actionKeySecond)}
                    onClick={() => handleLinkClick(content.actionKey)}
                  />
                );
              case 'text':
                return (
                  <Typography 
                    key={`${content.name}-${index}`} 
                    style={{textAlign: 'center', fontSize: '16px'}}
                  >
                    {content.label}
                  </Typography>
                );
              case 'picture':
                return (
                  <Stack
                    key={`${content.name}-${index}`}
                    direction='row'
                    justifyContent='center'
                    display='flex'
                  >
                    <img 
                      src='/Img/Pictures/operatorSuccess.svg'
                      style={{width: '${content.customWidth}'}}
                    />
                  </Stack>
                );
            }
          })}
        </Stack>
      </form>
    </MyDialog>
  );
};

export default DialogContentRenderer;
