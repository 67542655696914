import { ResponseType, FilesUploadUrlRequestType, FilesUploadUrlResponseType } from '@/types';
import { apiClient, awsClient } from '@/providers';

export const filesProvider = {
  uploadUrl: async (payload: FilesUploadUrlRequestType): Promise<ResponseType> => {
    const response = await apiClient('/file/uploadUrl', 'POST', payload);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as FilesUploadUrlResponseType;
    return { ...response, data };
  },

  putFile: async (url: string, file: File): Promise<ResponseType> => {
    const response = await awsClient(url, 'PUT', file);

    return { ...response, data: null };
  },

  markReady: async (id: string, payload: FilesUploadUrlRequestType): Promise<ResponseType> => {
    const response = await apiClient(`/file/${id}/markReady`, 'POST', payload);

    return { ...response, data: null };
  },

  downloadUrl: async (id: string, payload: FilesUploadUrlRequestType): Promise<ResponseType> => {
    const response = await apiClient(`/file/${id}/downloadUrl`, 'POST', payload);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data: FilesUploadUrlResponseType = {
      fileUploadUrl: response.data as unknown as string,
      id,
    };
    return { ...response, data };
  },
};
