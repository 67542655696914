import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { newBuildingsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { State, NewBuildingModel, PriceType, FileKindEnum, NotificationTypeEnum } from '@/types';
import { Loader, MyButton, MyDialog, LabelComponent } from '@/components';
import styles from './Ad.module.scss';

export const NewBuildingModal = ({
  id,
  typeOfDialog,
  open,
  onClose,
  typeMenu,
  buildingId,
  buildingName,
  isAddBuilding,
  setIsAddBuilding,
}: {
  id: string;
  typeOfDialog: string | undefined;
  open: boolean;
  typeMenu?: string;
  onClose: () => void;
  buildingId?: string | null;
  buildingName?: string | null;
  isAddBuilding?: boolean;
  setIsAddBuilding?: (value: boolean) => void;
}) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const createNewBuilding = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.createNewBuilding(id!, value);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setIsAddBuilding && setIsAddBuilding(!isAddBuilding);
    }
    setIsLoading(false);
  };

  const editNewBuilding = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.editNewBuilding(
      id!,
      buildingId!,
      value
    );
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setIsAddBuilding && setIsAddBuilding(!isAddBuilding);
    }
    setIsLoading(false);
  };

  const deleteEntity = async (typeMenu?: string) => {
    setIsLoading(true);
    let response;
    if (typeMenu === 'plan') {
      response = await newBuildingsProvider.deletePlan(id!, buildingId!);
    } else {
      response = await newBuildingsProvider.deleteNewBuilding(id!, buildingId!);
    }
    const { ok, data, status, message } = response;

    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setIsAddBuilding && setIsAddBuilding(!isAddBuilding);
    }
    setIsLoading(false);
  };

  const handleConfirm = () => {
    switch (typeOfDialog) {
      case 'create':
        createNewBuilding();
        break;
      case 'edit':
        editNewBuilding();
        break;
      case 'delete':
        deleteEntity(typeMenu);
        break;
    }
    onClose();
  };

  const name = typeMenu === 'plan' ? 'plan' : 'building';

  const dialogTitle =
    typeOfDialog === 'create'
      ? 'Add a new building'
      : typeOfDialog === 'edit'
        ? 'Edit building name'
        : `Are you sure you want to delete the ${name}?`;

  const label = 'Enter building name';
  const placeholder = 'Enter name';
  const buttonName =
    typeOfDialog === 'delete' ? 'Confirm' : typeOfDialog === 'edit' ? 'Edit' : 'Create';

  return (
    <MyDialog open={open} onClose={onClose} dialogTitle={dialogTitle} width='750'>
      {typeOfDialog === 'delete' ? (
        <div className={styles.dialogDescription}>
          {`After confirmation, the ${name} will be deleted from the system; if it contains layouts,
          they will also be deleted from the system. Are you sure you want to delete the ${name}?`}
        </div>
      ) : (
        <div>
          <LabelComponent label={label} />
          <TextField
            defaultValue={typeOfDialog ? buildingName : null}
            variant='outlined'
            placeholder={placeholder}
            fullWidth
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      )}
      <div className={styles.dialogButtons}>
        <MyButton
          data={{
            buttonName: `${buttonName}`,
            customWidth: '226px',
            variant: 'contained',
            buttonType: 'submit',
          }}
          onClick={handleConfirm}
        />
        <MyButton
          data={{
            buttonName: 'Cancel',
            customWidth: '226px',
            variant: 'outlined',
            buttonType: 'button',
            styleType: 'cancel',
          }}
          onClick={onClose}
        />
      </div>
    </MyDialog>
  );
};
