export const nationality = [
  'Afghan',
  'Albanian',
  'Algerian',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosniak',
  'Botswana',
  'Brazilian',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese (also known as Myanmar)',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comorian',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dominican (Dominica)',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Salvadoran',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Eswatini',
  'Ethiopian',
  'Fijian',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kittian and Nevisian',
  'Korean (North Korea)',
  'Korean (South Korea)',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Montenegrin',
  'Moroccan',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'Dutch',
  'New Zealander',
  'Nicaraguan',
  'Nigerien',
  'Nigerian',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Filipino',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Vincentian',
  'Samoan',
  'Sanmarinese',
  'Sao Tomean',
  'Saudi',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamese',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Emirati',
  'British',
  'American',
  'Uruguayan',
  'Uzbek',
  'Vanuatuan',
  'Vatican',
  'Venezuelan',
  'Vietnamese',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
];

export const propertyType = ['Apartment', 'Building', 'Commercial', 'Villa', 'Land'];

export const specialization = ['Sale', 'Rent'];

export const gender = ['Female', 'Male'];

export const formOfOrganization = [
  { name: 'physical', label: 'Private person' },
  { name: 'juridicial', label: 'Organization' },
];

export const country = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  "Eswatini (fmr. 'Swaziland')",
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia (formerly Macedonia)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const language = [
  { name: 'aa', label: 'Afar' },
  { name: 'ab', label: 'Abkhaz' },
  { name: 'ae', label: 'Avestan' },
  { name: 'af', label: 'Afrikaans' },
  { name: 'ak', label: 'Akan' },
  { name: 'am', label: 'Amharic' },
  { name: 'an', label: 'Aragonese' },
  { name: 'ar', label: 'Arabic' },
  { name: 'as', label: 'Assamese' },
  { name: 'av', label: 'Avaric' },
  { name: 'ay', label: 'Aymara' },
  { name: 'az', label: 'Azerbaijani' },
  { name: 'ba', label: 'Bashkir' },
  { name: 'be', label: 'Belarusian' },
  { name: 'bg', label: 'Bulgarian' },
  { name: 'bh', label: 'Bihari' },
  { name: 'bi', label: 'Bislama' },
  { name: 'bm', label: 'Bambara' },
  { name: 'bn', label: 'Bengali' },
  { name: 'bo', label: 'Tibetan' },
  { name: 'br', label: 'Breton' },
  { name: 'bs', label: 'Bosnian' },
  { name: 'ca', label: 'Catalan;' },
  { name: 'ce', label: 'Chechen' },
  { name: 'ch', label: 'Chamorro' },
  { name: 'co', label: 'Corsican' },
  { name: 'cr', label: 'Cree' },
  { name: 'cs', label: 'Czech' },
  // { name: "cu", label: "Old Church Slavonic" },
  { name: 'cv', label: 'Chuvash' },
  { name: 'cy', label: 'Welsh' },
  { name: 'da', label: 'Danish' },
  { name: 'de', label: 'German' },
  { name: 'dv', label: 'Divehi' },
  { name: 'dz', label: 'Dzongkha' },
  { name: 'ee', label: 'Ewe' },
  { name: 'el', label: 'Greek' },
  { name: 'en', label: 'English' },
  { name: 'eo', label: 'Esperanto' },
  { name: 'es', label: 'Spanish' },
  { name: 'et', label: 'Estonian' },
  { name: 'eu', label: 'Basque' },
  { name: 'fa', label: 'Persian' },
  { name: 'ff', label: 'Fula' },
  { name: 'fi', label: 'Finnish' },
  { name: 'fj', label: 'Fijian' },
  { name: 'fo', label: 'Faroese' },
  { name: 'fr', label: 'French' },
  { name: 'fy', label: 'Western Frisian' },
  { name: 'ga', label: 'Irish' },
  { name: 'gd', label: 'Scottish Gaelic' },
  { name: 'gl', label: 'Galician' },
  { name: 'gn', label: 'Guaraní' },
  { name: 'gu', label: 'Gujarati' },
  { name: 'gv', label: 'Manx' },
  { name: 'ha', label: 'Hausa' },
  { name: 'he', label: 'Hebrew' },
  { name: 'hi', label: 'Hindi' },
  { name: 'ho', label: 'Hiri Motu' },
  { name: 'hr', label: 'Croatian' },
  { name: 'ht', label: 'Haitian' },
  { name: 'hu', label: 'Hungarian' },
  { name: 'hy', label: 'Armenian' },
  { name: 'hz', label: 'Herero' },
  { name: 'ia', label: 'Interlingua' },
  { name: 'id', label: 'Indonesian' },
  { name: 'ie', label: 'Interlingue' },
  { name: 'ig', label: 'Igbo' },
  { name: 'ii', label: 'Nuosu' },
  { name: 'ik', label: 'Inupiaq' },
  { name: 'io', label: 'Ido' },
  { name: 'is', label: 'Icelandic' },
  { name: 'it', label: 'Italian' },
  { name: 'iu', label: 'Inuktitut' },
  { name: 'ja', label: 'Japanese' },
  { name: 'jv', label: 'Javanese' },
  { name: 'ka', label: 'Georgian' },
  { name: 'kg', label: 'Kongo' },
  { name: 'ki', label: 'Kikuyu' },
  { name: 'kj', label: 'Kwanyama' },
  { name: 'kk', label: 'Kazakh' },
  { name: 'kl', label: 'Kalaallisut' },
  { name: 'km', label: 'Khmer' },
  { name: 'kn', label: 'Kannada' },
  { name: 'ko', label: 'Korean' },
  { name: 'kr', label: 'Kanuri' },
  { name: 'ks', label: 'Kashmiri' },
  { name: 'ku', label: 'Kurdish' },
  { name: 'kv', label: 'Komi' },
  { name: 'kw', label: 'Cornish' },
  { name: 'ky', label: 'Kyrgyz' },
  { name: 'la', label: 'Latin' },
  { name: 'lb', label: 'Luxembourgish' },
  { name: 'lg', label: 'Ganda' },
  { name: 'li', label: 'Limburgish' },
  { name: 'ln', label: 'Lingala' },
  { name: 'lo', label: 'Lao' },
  { name: 'lt', label: 'Lithuanian' },
  { name: 'lu', label: 'Luba-Katanga' },
  { name: 'lv', label: 'Latvian' },
  { name: 'mg', label: 'Malagasy' },
  { name: 'mh', label: 'Marshallese' },
  { name: 'mi', label: 'Māori' },
  { name: 'mk', label: 'Macedonian' },
  { name: 'ml', label: 'Malayalam' },
  { name: 'mn', label: 'Mongolian' },
  { name: 'mr', label: 'Marathi' },
  { name: 'ms', label: 'Malay' },
  { name: 'mt', label: 'Maltese' },
  { name: 'my', label: 'Burmese' },
  { name: 'na', label: 'Nauruan' },
  { name: 'nb', label: 'Norwegian Bokmål' },
  { name: 'nd', label: 'Northern Ndebele' },
  { name: 'ne', label: 'Nepali' },
  { name: 'ng', label: 'Ndonga' },
  { name: 'nl', label: 'Dutch' },
  { name: 'nn', label: 'Norwegian Nynorsk' },
  { name: 'no', label: 'Norwegian' },
  { name: 'nr', label: 'Southern' },
  { name: 'nv', label: 'Navajo' },
  { name: 'ny', label: 'Chichewa' },
  { name: 'oc', label: 'Occitan' },
  { name: 'oj', label: 'Ojibwe' },
  { name: 'om', label: 'Oromo' },
  { name: 'or', label: 'Oriya' },
  { name: 'os', label: 'Ossetian' },
  { name: 'pa', label: 'Panjabi' },
  { name: 'pi', label: 'Pāli' },
  { name: 'pl', label: 'Polish' },
  { name: 'ps', label: 'Pashto,' },
  { name: 'pt', label: 'Portuguese' },
  { name: 'qu', label: 'Quechua' },
  { name: 'rm', label: 'Romansh' },
  { name: 'rn', label: 'Kirundi' },
  { name: 'ro', label: 'Romanian' },
  { name: 'ru', label: 'Russian' },
  { name: 'rw', label: 'Kinyarwanda' },
  { name: 'sa', label: 'Sanskrit' },
  { name: 'sc', label: 'Sardinian' },
  { name: 'sd', label: 'Sindhi' },
  { name: 'se', label: 'Northern Sami' },
  { name: 'sg', label: 'Sango' },
  { name: 'si', label: 'Sinhalese' },
  { name: 'sk', label: 'Slovak' },
  { name: 'sl', label: 'Slovene' },
  { name: 'sm', label: 'Samoan' },
  { name: 'sn', label: 'Shona' },
  { name: 'so', label: 'Somali' },
  { name: 'sq', label: 'Albanian' },
  { name: 'sr', label: 'Serbian' },
  { name: 'ss', label: 'Swati' },
  { name: 'st', label: 'Southern Sotho' },
  { name: 'su', label: 'Sundanese' },
  { name: 'sv', label: 'Swedish' },
  { name: 'sw', label: 'Swahili' },
  { name: 'ta', label: 'Tamil' },
  { name: 'te', label: 'Telugu' },
  { name: 'tg', label: 'Tajik' },
  { name: 'th', label: 'Thai' },
  { name: 'ti', label: 'Tigrinya' },
  { name: 'tk', label: 'Turkmen' },
  { name: 'tl', label: 'Tagalog' },
  { name: 'tn', label: 'Tswana' },
  { name: 'to', label: 'Tonga' },
  { name: 'tr', label: 'Turkish' },
  { name: 'ts', label: 'Tsonga' },
  { name: 'tt', label: 'Tatar' },
  { name: 'tw', label: 'Twi' },
  { name: 'ty', label: 'Tahitian' },
  { name: 'ug', label: 'Uighur' },
  { name: 'uk', label: 'Ukrainian' },
  { name: 'ur', label: 'Urdu' },
  { name: 'uz', label: 'Uzbek' },
  { name: 've', label: 'Venda' },
  { name: 'vi', label: 'Vietnamese' },
  { name: 'vo', label: 'Volapük' },
  { name: 'wa', label: 'Walloon' },
  { name: 'wo', label: 'Wolof' },
  { name: 'xh', label: 'Xhosa' },
  { name: 'yi', label: 'Yiddish' },
  { name: 'yo', label: 'Yoruba' },
  { name: 'za', label: 'Zhuang' },
  { name: 'zh', label: 'Chinese' },
  { name: 'zu', label: 'Zulu' },
];
