import queryString from 'query-string';
import { apiClient } from '@/providers';
import { AuthGetTokenResponseType, SortingOrderEnum, SubscriptionsGetAllResponse, TransactionsGetResponse } from '@/types';
import { AdminUserRespose, BlockHistory, User } from '@/components/Admin/Users/types/types';

export const adminProvider = {
  getUsers: async (search: string, limit: number, offset: number, type?: string, verified?: boolean) => {
    const params = { search, limit, offset, verified, type };
    if (verified === undefined) {
      delete params.verified;
    }
    const paramsStr = queryString.stringify(params);
    const response = await apiClient(`/admin/user?${paramsStr}`, 'GET');
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as AdminUserRespose;
    return { ...response, data };
  },

  impersonate: async (userId: number) => {
    const response = (await apiClient(`/admin/user/${userId}/impersonate`, 'GET')) as any;
    if (!response.ok) {
      return { ...response, data: null };
    }

    return response?.data as AuthGetTokenResponseType;
  },

  banUser: async (userId: number, reason: string) => {
    const response = await apiClient(`/admin/user/${userId}/block`, 'POST', {
      reason,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  unBanUser: async (userId: number) => {
    const response = await apiClient(`/admin/user/${userId}/unblock`, 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  getBlockHistory: async (userId: number) => {
    const response = await apiClient(
      `/admin/user/${userId}/block/history?limit=50&offset=0`,
      'GET'
    );
    if (!response.ok) {
      return { ...response, data: { items: [], total: 0 } };
    }

    return { ok: response.ok, data: response.data };
  },

  getEmploymentRequests: async (userId: number) => {
    const response = await apiClient(`/user/${userId}/employment/request`, 'GET');
    if (!response.ok) {
      return { ...response, data: { items: [], total: 0 } };
    }

    return response.data as any;
  },

  acсeptEmploymentRequest: async (userId: number, employerId: number) => {
    const response = await apiClient(
      `/user/${userId}/employment/application/${employerId}/accept`,
      'GET'
    );
    if (!response.ok) {
      return { ...response, data: null };
    }

    return response.data as any;
  },

  rejectEmploymentRequest: async (userId: number, employerId: number) => {
    const response = await apiClient(
      `/user/${userId}/employment/application/${employerId}/reject`,
      'GET'
    );
    if (!response.ok) {
      return { ...response, data: null };
    }

    return response.data as any;
  },

  verifyUser: async (userId: number) => {
    const response = await apiClient(`/admin/user/${userId}/verify`, 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    return response.data as any;
  },

  unregisteredUser: async (limit: number, offset: number) => {
    const params = { limit, offset };
    const paramsStr = queryString.stringify(params);
    const response = await apiClient(`/user/partiallyRegistered/all?${paramsStr}`, 'POST', { limit, offset });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  getAllSubscription: async ({
    limit, 
    offset, 
    // sort
  }: { 
    limit: number, 
    offset: number, 
    // sort: AdsSortingType 
  }) => {
    const sort = [{
      field: 'subId',
      order: SortingOrderEnum.Asc,
    }]
    const response = await apiClient(`/subscription/get-all`, 'POST', { limit, offset, sort });
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as SubscriptionsGetAllResponse;
    return { ...response, data };
  },

  getTransactions: async ({
    page, 
    limit, 
    userId,
  }: { 
    page?: string, 
    limit: number, 
    userId?: string,
  }) => {
    const requestBody = {
      ...(page && { page }),
      ...(userId && { userId }),
      limit,
    };
    const response = await apiClient(`/subscription/transactions`, 'POST', requestBody);
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as TransactionsGetResponse;
    return { ...response, data };
  },
};
