import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Avatar, Button, Link, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { FriendsModel } from '@/types';
import { stringAvatar, getKindLabel } from '@/utils';
import { ReactComponent as ThreeDotsVerticalIcon } from './iconThreeDotsVertical.svg';
import { ReactComponent as DisconnectIcon } from './disconnect.svg';
import styles from './FriendItem.module.scss';
import calendarPlus from './calendarPlus.svg';
import acceptFriendRequest from './acceptFriendRequest.svg';
import rejectFriendRequest from './rejectFriendRequest.svg';

interface FriendItemProps {
  friend: FriendsModel;
  onRemoveFriend: () => void;
  status: string;
  handleAcceptFriend?: (id: string) => void;
  userMeId: string;
}

export const FriendItem = ({
  friend,
  status,
  onRemoveFriend,
  handleAcceptFriend,
  userMeId,
}: FriendItemProps) => {
  const { id, senderId, receiverId, name, kind, employer, media, acceptedAt, createdAt, chatId } =
    friend;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const hrefId = userMeId !== senderId ? senderId : receiverId;
  const avatar =
    media.length > 0 ? `https://d1romkeaftoxzt.cloudfront.net/${media?.[0].externalId}` : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.blockAvatar}>
        <div className={styles.avatarImage}>
          <Link href={`/users/${hrefId}`} title={name}>
            {avatar ? (
              <img src={avatar} alt={name} />
            ) : (
              <Avatar {...stringAvatar(name)} sx={{ width: 72, height: 72 }} />
            )}
          </Link>
        </div>
        <div className={styles.nameLabel}>
          <Link href={`/users/${hrefId}`} title={name}>
            {name}
          </Link>
          <Typography sx={{ color: '#2a384250' }}>{getKindLabel(kind)}</Typography>
          <Typography sx={{ color: '#1650FF' }}>{employer.name}</Typography>
        </div>
      </div>

      <div className={styles.blockAdData}>
        <div className={styles.adData}>
          <img src={calendarPlus} alt='Active ads' />
          <span>{status === 'pending' ? 'Requests date: ' : 'Friend date:'}</span>
        </div>
        <div className={styles.adData}>
          {status === 'pending'
            ? `${moment(createdAt).format('D MMMM YYYY')}`
            : `${moment(acceptedAt).format('D MMMM YYYY')}`}
        </div>
      </div>

      <div className={styles.buttonSection}>
        {status === 'pending' ? (
          <>
            <Button
              type='button'
              variant='contained'
              onClick={() => handleAcceptFriend?.(id)}
              sx={{
                backgroundColor: '#1650FF',
                borderRadius: '14px',
                width: '88px',
              }}
            >
              <img src={acceptFriendRequest} alt='Accept' />
            </Button>
            <Button
              type='button'
              variant='outlined'
              onClick={onRemoveFriend}
              sx={{
                border: '1px solid #1650FF',
                borderRadius: '14px',
                width: '88px',
                flex: 1,
                ':hover': {
                  backgroundColor: '#e9eef1',
                },
              }}
            >
              <img src={rejectFriendRequest} alt='Reject' />
            </Button>
          </>
        ) : (
          <>
            <Button
              variant='contained'
              onClick={() => navigate(`/chat/${chatId}`)}
              sx={{
                color: '#FFF',
                backgroundColor: '#1650FF',
                textTransform: 'none',
                borderRadius: '14px',
                minWidth: '152px',
                flex: 1,
                ':hover': {
                  color: '#FFF',
                },
              }}
            >
              OP Chat
            </Button>
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                border: '2px solid #C5DFFF',
                borderRadius: { xs: '14px', sm: '32px' },
                ':hover': {
                  borderColor: '#1650FF',
                  color: '#1650FF',
                },
              }}
              onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            >
              <ThreeDotsVerticalIcon />
            </IconButton>

            <Menu
              sx={{
                marginTop: '10px',
                '.MuiMenuItem-root': {
                  display: 'flex',
                  gap: '8px',
                },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={onRemoveFriend}>
                <DisconnectIcon />
                Disconnect
              </MenuItem>
            </Menu>
          </>
        )}
      </div>

      {/* <div className={styles.blockButtons}>
        <Button type='button' variant='outlined' color='primary' onClick={onRemoveMember}>
          <img src={removeMember} alt='Remove' />
          Remove
        </Button>
      </div> */}
    </div>
  );
};
