import { Box } from '@mui/material';
import './LightSlider.css'; // CSS it is an only way to customize Photoswipe
import React from 'react';

interface ILightSliderProps {
  images: { fileUrl: string; order: number; id: string }[];
  id?: string;
}

interface ILightVideoSliderProps {
  videos: { fileUrl: string; order: number; id: string }[];
  id?: string;
}

export const LightSlider: React.FC<ILightSliderProps> = ({ images, id = 'pswp-gallery' }) => {
  return (
    <Box className='pswp-gallery' id={id} style={{ display: 'none' }}>
      {images?.map((image, index) => (
        <a
          href={image.fileUrl}
          data-pswp-width='800'
          data-pswp-height='600'
          target='_blank'
          rel='noreferrer'
          key={image.id}
        >
          <img src={image.fileUrl} alt='' />
        </a>
      ))}
    </Box>
  );
};

export const LightVideoSlider: React.FC<ILightVideoSliderProps> = ({
  videos,
  id = 'pswp-gallery-videos',
}) => {
  return (
    <Box className='pswp-gallery' id={id} style={{ display: 'none' }}>
      {videos?.map((video, _index) => (
        <a
          href={video.fileUrl}
          data-pswp-video-src={video.fileUrl}
          // data-pswp-video-sources='[{"src":"my-video.webm","type":"video/webm"},{"src":"my-video.mp4","type":"video/mp4"}]'
          data-pswp-width='800'
          data-pswp-height='600'
          data-pswp-type='video'
          // target='_blank'
          // rel='noreferrer'
          key={video.id}
        >
          {/* <img src={""} alt='' /> // Poster */}
        </a>
      ))}
    </Box>
  );
};
