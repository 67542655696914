import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { AUTH_ACCESSTOKEN } from '@/constants';
import { Loader, MyButton, MyDialog } from '@/components';
import styles from './XMLUpload.module.scss';

interface FileUploadProps {
  defaultUserId: string;
  isUserSelected: boolean;
  isXmlUpload: boolean;
  setIsXmlUpload: (value: boolean) => void;
  isRemove: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  defaultUserId,
  isUserSelected,
  setIsXmlUpload,
  isXmlUpload,
  isRemove,
}) => {
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // const [uploadMediaToStorage, setUploadMediaToStorage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem(AUTH_ACCESSTOKEN);
  const url = `${process.env.REACT_APP_API_URL}/import/propertyFinder/propertyAd`;

  const onDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
    if (!isUserSelected) {
      setIsShownConfirmDialog(true);
    } else {
      handleConfirm();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/xml': ['.xml'],
      'text/xml': ['.xml'],
    },
  });

  const handleConfirm = () => {
    if (selectedFile) {
      handleUpload(selectedFile);
    }
    setIsShownConfirmDialog(false);
  };

  const handleCloseConfirmDialog = () => setIsShownConfirmDialog(false);
  const uploadMediaToStorage = true;
  const handleUpload = async (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('xml', file, file.name);
      formData.append('defaultUserId', defaultUserId);
      formData.append('uploadMediaToStorage', String(uploadMediaToStorage));
      formData.append('removeWatermark', String(isRemove));
      setIsLoading(true);
      try {
        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
        setIsXmlUpload(!isXmlUpload);
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        {isLoading && <Loader />}
        <MyButton
          disabled={isLoading}
          data={{
            buttonName: 'Upload file',
            customWidth: '217px',
            variant: 'contained',
            buttonType: 'button',
          }}
        />
        <p className={styles.text}>or drag them here</p>
        <p style={{ textAlign: 'center' }}>
          <span>Only XML file. Maximum file size - 500MB</span>
        </p>
      </div>
      <MyDialog
        open={isShownConfirmDialog}
        onClose={handleCloseConfirmDialog}
        dialogTitle='XML Upload:'
        width='750'
      >
        <div className={styles.dialogDescription}>
          You have not selected a person responsible for the uploaded Ads, so if the uploaded ads do
          not have an author, we will put you as the author.
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={handleConfirm}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              buttonType: 'button',
              styleType: 'cancel',
            }}
            onClick={handleCloseConfirmDialog}
          />
        </div>
      </MyDialog>
    </>
  );
};
