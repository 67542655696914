import { useState } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import {
  SectionTitle,
  MyTextField,
  MyPhoneInput,
  MyButton,
  MyGooglMapsAutocompete,
} from '@/components';
import { IErrors } from '../../../../uiComponents/types';
import styles from './ContactData.module.scss';

export const ContactData = ({
  errors,
  control,
  isCheckedBroker,
  isCheckedSecondPhone,
  setValue,
}: {
  errors: IErrors;
  control: Control;
  isCheckedBroker: boolean;
  isCheckedSecondPhone: boolean;
  setValue: (name: string, value: any, options?: Partial<FieldValues>) => void;
}) => {
  const [showPhone, setShowPhone] = useState(isCheckedSecondPhone);

  return (
    <div>
      <SectionTitle number={isCheckedBroker ? 3 : 2} title='Contact Data' />
      <div className={styles.container}>
        <div className={styles.row}>
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'email',
              label: 'Your email',
              isRequired: true,
              placeholder: 'Enter your email',
              maxLength: 80,
            }}
          />
          <div className={!showPhone ? styles.inputWithButton : styles.inputWithoutButton}>
            <div className={styles.myPhoneInput}>
              <MyPhoneInput
                errors={errors}
                control={control}
                setValue={setValue}
                data={{
                  name: 'phone',
                  label: 'Your phone',
                  isRequired: true,
                  placeholder: 'Enter your phone number',
                }}
              />
            </div>
            {!showPhone && (
              <MyButton
                data={{
                  buttonName: 'addPhone',
                  styleType: 'iconButton',
                }}
                onClick={() => setShowPhone(true)}
              />
            )}
          </div>
          {showPhone && (
            <MyPhoneInput
              errors={errors}
              control={control}
              setValue={setValue}
              data={{
                name: 'phoneNumberSecond',
                label: 'Your phone',
                placeholder: 'Enter your phone number',
              }}
            />
          )}
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'whatsapp',
              label: 'Your Whatsapp',
              placeholder: 'Whatsapp number',
              maxLength: 80,
            }}
          />
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'telegram',
              label: 'Your Telegram',
              placeholder: 'Enter your Telegram',
              maxLength: 80,
            }}
          />
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'instagram',
              label: 'Your Instagram',
              placeholder: 'Enter your Instagram',
              maxLength: 80,
            }}
          />
          <MyTextField
            errors={errors}
            control={control}
            data={{
              name: 'linkedin',
              label: 'Your Linkedin',
              placeholder: 'Enter your Linkedin',
              maxLength: 80,
            }}
          />
          <MyGooglMapsAutocompete
            control={control}
            setValue={setValue}
            data={{
              name: 'address',
              label: 'Actual Address',
              placeholder: 'Enter your location',
            }}
          />
          <div className={styles.linkWithoutButton}>
            <div className={styles.myPhoneInput}>
              <MyTextField
                errors={errors}
                control={control}
                data={{
                  name: 'additionalLinks',
                  label: 'Additional Link',
                  placeholder: 'Enter your websites',
                  maxLength: 200,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
