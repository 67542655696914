import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SearchIcon from '@mui/icons-material/Search';

export const ScrollTopButton = ({ isSearch }: { isSearch?: boolean }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return isVisible ? (
    <IconButton
      size='large'
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '100px',
        right: '50px',
        backgroundColor: '#ffffff',
        color: '#1650FF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      {isSearch ? <SearchIcon /> : <ArrowUpwardIcon />}
    </IconButton>
  ) : null;
};
