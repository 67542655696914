import {
  ResponseType,
  AdsSortingType,
  PaginationType,
  RequestsGetAllResponseType,
  RequestsFilteringType,
  UserRequestResponseType,
  RequestsStatus,
  AdsFilteringType,
} from '@/types';
import { apiClient } from '@/providers';

interface ICommonParams {
  query: string;
  limit?: number;
  offset?: number;
}

export const propertyAdRequestProvider = {
  createAdRequest: async (userId: string, chanelKind: string) => {
    const response = await apiClient(`/propertyAd/${userId}/request`, 'POST', {
      chanelKind,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  getRequestsForMe: async (
    filteringObj: AdsFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/request/for-me/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      sort: [{ ...sortingObj }],
      filter: [{ ...filteringObj }],
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as RequestsGetAllResponseType;
    return { ...response, data };
  },

  getMyRequests: async (
    filteringObj: AdsFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/request/by-me/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      sort: [{ ...sortingObj }],
      filter: [{ ...filteringObj }],
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as RequestsGetAllResponseType;
    return { ...response, data };
  },

  getAdForMeById: async (id: string) => {
    const response = await apiClient(`/propertyAd/${id}/request/for-me`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data;
    return { ...response, data };
  },

  getRequestsForMeById: async (
    id: string,
    filteringObj: RequestsFilteringType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}/request/for-me/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      filters: filteringObj,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as UserRequestResponseType;
    return { ...response, data };
  },

  getAdByMeById: async (id: string) => {
    const response = await apiClient(`/propertyAd/${id}/request/by-me`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data;
    return { ...response, data };
  },

  getRequestsByMeById: async (
    id: string,
    filteringObj: RequestsFilteringType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/${id}/request/by-me/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      filters: filteringObj,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.data as UserRequestResponseType;
    return { ...response, data };
  },

  patchRequest: async (id: string, status: RequestsStatus): Promise<ResponseType> => {
    const response = await apiClient(`/propertyAd/request/${id}`, 'PATCH', status);
    if (!response.ok) {
      return { ...response, data: null };
    }
    return { ...response };
  },

  getAllUnreadCount: async () => {
    const response = await apiClient('/propertyAd/request/for-me/unreadCount', 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },
};
