import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Button,
  FormHelperText,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormContext, FieldErrors, UseFormClearErrors, Controller } from 'react-hook-form';
import classnames from 'classnames';

import { OffPlanFurnitureEnum, IOffPlanModel } from '@/types';
import {
  FormInput,
  FormSelect,
  FormSelectCustom,
  LabelComponent,
  SectionTitle,
  FormInputNumeric,
} from '@/components';
import {
  propertyKindValues,
  quarterValues,
  furnitureValues,
  offplanStatusValues,
  developentStatusValues,
} from './PropertyInformation.init';
import { licenseProvider } from '@/providers';
import debounce from '@/utils/debounce';

import styles from './PropertyInformation.module.scss';
import { OffPlanPropertyKind } from '@/types/enums';

interface IPropertyFields {
  constructionType?: OffPlanPropertyKind | null;
  quarters?: string | null;
  years?: string | null | null;
  furnishing?: OffPlanFurnitureEnum | null;
  offPlanStatus?: boolean | null;
}

interface IDeveloper {
  id: string;
  name: string;
}

interface IPropertyInformationProps {
  fields: IPropertyFields;
  setNewValue: (field: Partial<IOffPlanModel>) => void;
  errors: FieldErrors<IOffPlanModel>;
  clearErrors: UseFormClearErrors<IOffPlanModel>;
  control?: any;
  watch?: any;
}

const isPropertyTypeIncluded = (
  constructionType: OffPlanPropertyKind | null | undefined,
  types: OffPlanPropertyKind[]
) => {
  return !!constructionType && types.includes(constructionType);
};

const builtUpAreaLabel = (
  constructionType: OffPlanPropertyKind | null | undefined,
  label: string
) =>
  isPropertyTypeIncluded(constructionType, [
    OffPlanPropertyKind.Penthouse,
    OffPlanPropertyKind.Apartments,
    OffPlanPropertyKind.HotelApartment,
  ])
    ? `Area ${label}`
    : `Built-up Area ${label}`;

const generateHandoverUsingQuartersAndYears = ({
  handover,
  quarters,
  years,
}: {
  handover?: string | null;
  quarters?: string | null;
  years?: string | null;
}) => {
  if (!handover) {
    return '/';
  }

  const [q, y] = handover.split('/');
  return `${quarters?.trim() || q?.trim() || ''}/${years?.trim() || y?.trim() || ''}`;
};

export const PropertyInformation = ({
  fields,
  setNewValue,
  errors,
  clearErrors,
  control,
}: IPropertyInformationProps) => {
  const { constructionType, quarters, years, furnishing, offPlanStatus } = fields;

  const { setValue, getValues } = useFormContext();
  const [yearsValues, setYearsValues] = useState<string[]>([]);
  const [developers, setDevelopers] = useState<IDeveloper[]>([]);
  const [handover, setHandover] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const values = getValues();

    if (!values.occupancy) setValue('occupancy', undefined);
    if (!values.floorsNum) setValue('floorsNum', undefined);

    const yearStart = new Date('01 Jan 2010 00:00:00 GMT').getFullYear();
    const years = Array.from({ length: 24 }, (_, i) => (yearStart + i).toString());
    setYearsValues(years);

    const handoverValue = `${values.quarters || quarterValues[0].value}/${values.years || years[0]}`;
    setHandover(handoverValue);
  }, []);

  useEffect(() => {
    setValue('handover', handover);
  }, [handover]);

  const handleChangePropertyKind = (value: OffPlanPropertyKind | null) => {
    let newValue: OffPlanPropertyKind | undefined;

    if (value !== null) {
      newValue = value;
    } else {
      newValue = undefined;
    }

    setNewValue({ constructionType: newValue });
    setValue('constructionType', newValue);
    clearErrors(['constructionType', 'quarters', 'plotArea']);
  };

  const handleChangeOffPlanStatus = (value: any) => {
    const newValue = offPlanStatus !== value ? value : null;
    setNewValue({ offPlanStatus: newValue });
    setValue('offPlanStatus', newValue);
    clearErrors(['offPlanStatus']);
  };

  const handleChangeFurniture = (value: OffPlanFurnitureEnum) => {
    const newValue = furnishing !== value ? value : null;
    setNewValue({ furnishing: newValue });
    setValue('furnishing', newValue);
    clearErrors(['furnishing']);
  };

  const handleChangeQuarters = (value: string) => {
    const newValue = quarters !== value ? value : null;
    setNewValue({ quarters: newValue });
    setValue('quarters', newValue);
    setHandover(generateHandoverUsingQuartersAndYears({ handover, quarters: newValue }));
    clearErrors(['quarters', 'years']);
  };

  const handleChangeYears = (value?: string) => {
    const newValue = years !== value ? value : null;
    setNewValue({ years: newValue });
    setValue('years', newValue);
    setHandover(generateHandoverUsingQuartersAndYears({ handover, years: newValue }));
    clearErrors(['quarters', 'years']);
  };

  const handleChangeGoogleLink = (value: string) => {
    console.log('google link', value);
  }

  const handleSearchDeveloper = debounce(
    (_event: React.SyntheticEvent<Element, Event>, value: string) => {
      const fetchDevelopers = async () => {
        if (value.length < 2) {
          setDevelopers([]);
          return;
        }

        try {
          const response = await licenseProvider.searchOffPlanDeveloper(3, 0, value);
          const { items = [] }: { items: IDeveloper[] } = response?.data || {};

          if (Array.isArray(items)) {
            const found = items?.map(({ id, name }) => ({ id, name }));
            setDevelopers(found);
          } else {
            console.error('Data is not an array:', items);
            setDevelopers([]);
          }
        } catch (error) {
          console.error('Error during search:', error);
          setDevelopers([]);
        }
      };

      fetchDevelopers();
    },
    300
  );

  const builtUpAreaToLabel = builtUpAreaLabel(constructionType ?? null, 'to');

  const builtUpAreaFromLabel = builtUpAreaLabel(constructionType ?? null, 'from');

  const floorsNumIsVisible = isPropertyTypeIncluded(constructionType ?? null, [
    OffPlanPropertyKind.Apartments,
    OffPlanPropertyKind.Penthouse,
    OffPlanPropertyKind.HotelApartment,
  ]);

  const equipmentIsVisible = isPropertyTypeIncluded(constructionType ?? null, [
    OffPlanPropertyKind.Apartments,
    OffPlanPropertyKind.Villa,
    OffPlanPropertyKind.Penthouse,
    OffPlanPropertyKind.Townhouse,
    OffPlanPropertyKind.HotelApartment,
  ]);

  const plotAreaIsVisible = isPropertyTypeIncluded(constructionType ?? null, [
    OffPlanPropertyKind.Villa,
    OffPlanPropertyKind.Townhouse,
    OffPlanPropertyKind.ResidentialBuilding,
  ]);

  return (
    <div>
      <SectionTitle number={1} title='Information about the property' />
      <div className={styles.fieldsContainer}>
        <div
          style={{
            maxWidth: isMobile ? '100%' : `230px`,
          }}
        >
          <FormSelect
            label='Unit type'
            name='constructionType'
            data={propertyKindValues}
            required
            defaultValue={constructionType || propertyKindValues[0].value}
            handleChange={handleChangePropertyKind}
          />
        </div>
        <div
          style={{
            maxWidth: isMobile ? '100%' : `230px`,
          }}
        >
          <FormInput
            uiVariant='blank'
            name='buildUpAreaFrom'
            required
            fullWidth
            digitsOnly
            label={builtUpAreaFromLabel}
            placeholder={'Area (sqft)'}
          />
        </div>

        <FormInput
          uiVariant='blank'
          name='buildUpAreaTo'
          fullWidth
          digitsOnly
          required
          label={builtUpAreaToLabel}
          placeholder={'Area (sqft)'}
        />

        <FormSelectCustom label='Handover' value={handover} required>
          <div className={styles.floor}>
            <FormHelperText>
              <span className={styles.floorHeading}>Quarters</span>
            </FormHelperText>
            <div style={{ display: 'flex' }}>
              {quarterValues.length > 0 &&
                quarterValues?.map(({ value, label }, index) => (
                  <Button
                    key={index}
                    className={classnames({
                      [styles.selectButtons]: true,
                      [styles.bedsBathsButton]: true,
                      [styles.selectButtonsActive]: quarters === value,
                    })}
                    onClick={() => handleChangeQuarters(value)}
                    variant='contained'
                  >
                    {label}
                  </Button>
                ))}
            </div>
            <FormHelperText>
              <span className={styles.floorHeading}>Years</span>
            </FormHelperText>
            <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '15px' }}>
              {yearsValues.length > 0 &&
                yearsValues.map((year, index) => (
                  <Button
                    key={index}
                    className={classnames({
                      [styles.selectButtons]: true,
                      [styles.bedsBathsButton]: true,
                      [styles.selectButtonsActive]: years === year,
                    })}
                    style={{ flexBasis: 'auto', maxWidth: 'calc(100% / 6 - 10px)' }}
                    onClick={() => handleChangeYears(year)}
                    variant='contained'
                  >
                    {year}
                  </Button>
                ))}
            </div>
          </div>
        </FormSelectCustom>
        {plotAreaIsVisible && (
          <FormInput
            uiVariant='blank'
            name='plotArea'
            fullWidth
            digitsOnly
            label='Plot Area'
            placeholder='Plot area (sqft)'
          />
        )}
        {floorsNumIsVisible && (
          <FormInput
            uiVariant='blank'
            name='numberOfFloors'
            required
            fullWidth
            digitsOnly
            label='Number of Floors'
            placeholder='Enter number'
          />
        )}
        {equipmentIsVisible && (
          <div
            style={{
              maxWidth: isMobile ? '100%' : `230px`,
            }}
          >
            <FormSelect
              label='Furnishing'
              name='furnishing'
              value={furnishing}
              data={furnitureValues}
              defaultValue={furnitureValues[0].value}
              handleChange={(e) => handleChangeFurniture(e)}
            />
          </div>
        )}
        <div
          style={{
            maxWidth: isMobile ? '100%' : `230px`,
          }}
        >
          <FormSelect
            label='Off-Plan Status'
            name='offPlanStatus'
            value={offPlanStatus}
            data={offplanStatusValues}
            defaultValue={offplanStatusValues[0].value}
            handleChange={(e) => handleChangeOffPlanStatus(e)}
          />
        </div>
        <FormSelect
          name='developmentStatus'
          data={developentStatusValues}
          label='Development status'
          defaultValue={developentStatusValues[0].value}
        />
        <div style={{ maxWidth: isMobile ? '100%' : `480px` }}>
          <LabelComponent label='Search developer by name' isRequired={true} />
          <Controller
            name='developerId'
            control={control}
            render={() => (
              <Autocomplete
                id='combo-box-demo'
                options={developers}
                onInputChange={handleSearchDeveloper}
                onChange={(_, value) => {
                  clearErrors('developerId');
                  if (value?.id && value?.name) {
                    setValue('developerId', value.id);
                    setValue('developerName', value.name);
                  } else {
                    setValue('developerId', '');
                    setValue('developerName', '');
                  }
                }}
                getOptionLabel={(option) => {
                  return option.name || '';
                }}
                style={{
                  width: '100%',
                }}
                defaultValue={{
                  id: getValues('developerId') || '',
                  name: getValues('developerName') || '',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label=''
                    placeholder='Search developer by name'
                    error={!!errors?.developerId?.message}
                    helperText={errors?.developerId?.message}
                    sx={{
                      marginTop: '2px',
                      height: 48,
                      '.MuiInputBase-input': {
                        height: 'calc(100% - 20px)',
                        boxSizing: 'border-box',
                      },
                      '.MuiInputBase-root': {
                        height: '100%',
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </div>
        <FormInputNumeric
          uiVariant='blank'
          name='serviceCharge'
          fullWidth
          label='Service charge'
          placeholder='AED/sqft'
          suffix=' AED/sqft'
        />
        <FormInput
          uiVariant='blank'
          name='resalle'
          fullWidth
          label='Resale'
          placeholder='Enter resale info'
        />
        <div style={{ maxWidth: isMobile ? '100%' : `730px` }}>
          <FormInput
            uiVariant='blank'
            name='reviewLinkGoogle'
            fullWidth
            label='Link to reviews from Google'
            placeholder='Enter link'
          />
        </div>
        <FormInput
          uiVariant='blank'
          name='projectNumber'
          fullWidth
          label='Project number'
          placeholder='Enter project number'
        />
      </div>
    </div>
  );
};
