import { ReactElement } from 'react';
import { Stack, Typography, Divider, Link } from '@mui/material';

import { NewBuildingModel, OffPlanPropertyKind, DevelopmentStatusEnum } from '@/types';
import { Section } from '@/components/Ad/Section';

import { SpecificationsContainer, Title } from './styled';
import styles from './Ad.module.scss';
import { icons } from '../Ad/Icons/icons';

export const ObjectFeatureBlock = ({ page }: { page: NewBuildingModel }) => {
  const propertyKindLabels = {
    [OffPlanPropertyKind.Apartments]: 'Apartments',
    [OffPlanPropertyKind.Townhouse]: 'Townhouse',
    [OffPlanPropertyKind.Villa]: 'Villa',
    [OffPlanPropertyKind.Penthouse]: 'Penthouse',
    [OffPlanPropertyKind.HotelApartment]: 'Hotel Apartment',
    [OffPlanPropertyKind.ResidentialBuilding]: 'Residential Building',
  };

  const DevelopmentStatusLabels = {
    [DevelopmentStatusEnum.PLANNED]: 'Planned',
    [DevelopmentStatusEnum.IN_PROGRESS]: 'In Progress',
    [DevelopmentStatusEnum.COMPLETED]: 'Completed',
    [DevelopmentStatusEnum.STOPPED]: 'Stopped',
  };

  const serviceChargeValue = `${page?.serviceCharge} AED / sqft`;

  const characteristics = [
    {
      name: 'Offplan status',
      value: page?.offPlanStatus ? 'Yes' : 'No',
      icon: <icons.HouseLine />,
    },
    {
      name: 'Unit area',
      value: '235 sqft - 985 sqft',
      icon: <icons.Map />,
    },
    {
      name: 'Development status',
      value: DevelopmentStatusLabels[page?.developmentStatus],
      icon: <icons.DevelopStatus />,
    },
    {
      name: 'Property type',
      value: propertyKindLabels[page.constructionType],
      icon: <icons.Buildings />,
    },
    {
      name: 'Number of Floors',
      value: page?.numberOfFloors ? page.numberOfFloors : null,
      icon: <icons.Floor />,
    },
    {
      name: 'Handover',
      value: page.handover,
      icon: <icons.Calendar />,
    },
    {
      name: 'Furnishing',
      value: page?.furnishing ? 'Yes' : 'No',
      icon: <icons.Furnishing />,
    },
    // {
    //   name: 'Parking',
    //   value: page?.coveredParking
    //     ? 'Covered parking'
    //     : page?.publicParking
    //       ? 'Public parking'
    //       : 'No',
    //   icon: <icons.Parking />,
    // },
    {
      name: 'Payment plan',
      value: page?.paymentPlan ? 'Yes' : 'No',
      icon: <icons.PaymentPlan />,
    },
    {
      name: 'Service charge',
      value: page?.serviceCharge ? serviceChargeValue : null,
      icon: <icons.ServiceCharge />,
    },
    {
      name: 'Resale',
      value: page?.resalle ? page.resalle : null,
      icon: <icons.Resale />,
    },
    {
      name: 'Reviews from Google',
      value: page?.reviewLinkGoogle ? page?.reviewLinkGoogle : null,
      icon: <icons.ReviewGoogle />,
    },
  ];

  return (
    <Section>
      <Title>Object features</Title>
      <div>
        {characteristics &&
          characteristics.map((character, index) => (
            <div key={character.name}>
              <IconText icon={character.icon} name={character.name} value={character.value} />
              {characteristics.length > index + 1 && <Divider />}
            </div>
          ))}
      </div>
    </Section>
  );
};

const IconText = ({
  icon,
  name,
  value,
}: {
  icon: ReactElement;
  name: string;
  value: string | number | boolean | null | undefined;
}) => {
  if (!value) return null;
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <SpecificationsContainer direction='row' spacing={2}>
        <Stack sx={{ color: '#1650ff' }}>{icon}</Stack>
        <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>{name}</Typography>
      </SpecificationsContainer>
      {name !== 'Reviews from Google' ? (
        <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, opacity: 0.5 }}>{value}</Typography>
      ) : (
        <Link className={styles.linkOnMap} href={`${value}`} target='_blank'>
          <span>Open reviews</span>
        </Link>
      )}
    </Stack>
  );
};
