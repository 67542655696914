import {
  ResponseType,
} from '@/types';
import { apiClient } from '@/providers';
import {AdsGetFavoritesResponseType} from "@/types/responses";

export const emailNotificationProvider = {
  unsubscribe: async (
    type: string
  ): Promise<ResponseType<AdsGetFavoritesResponseType>> => {
    const response = await apiClient(`/user/me/unsubscribe/${type}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetFavoritesResponseType;
    return { ...response, data };
  },
};
