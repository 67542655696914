import { useNavigate } from 'react-router-dom';
import { Control } from 'react-hook-form';
import { MyButton, SectionTitle, MyTextField } from '@/components';
import { IErrors } from '../../../../uiComponents/types';
import styles from './LicenseData.module.scss';
import { licenseData, supportButton } from './licenseDataDescription';

export const LicenseData = ({ errors, control }: { errors: IErrors; control: Control }) => {
  const navigate = useNavigate();
  const adminChatId = process.env.REACT_APP_SUPPORT_CHAT_ID || 'd3c0cd5e-c3d8-4a1e-bafe-3a08dc7ef2ea';
  return (
    <div>
      <SectionTitle number={1} title={licenseData.sectionName?.label || ''} />
      <div className={styles.row}>
        {licenseData.data.map((group) => (
          <MyTextField
            key={group.name}
            errors={errors}
            control={control}
            disabled={true}
            data={group}
          />
        ))}
        <div className={styles.buttonContainer} style={{maxWidth: "90%"}}>
          <MyButton data={supportButton} onClick={() => navigate(`/chat/${adminChatId}`)} />
        </div>
      </div>
    </div>
  );
};
