import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { usersProvider } from '@/providers';
import { appSetNotification, authGetMyUser } from '@/store';
import { PropertyKindEnum, ProfilePropertyKindOldEnum, ProfilePropertyKindNewEnum } from '@/types';
import { AgencyField } from './AgencyField/AgencyField';
import { useEditInfoForm } from '../../utils/useFormLogic';
import { language } from '../../utils/fakeData';
import { Loader, MyButton } from '@/components';
import styles from './EditInfo.module.scss';
import { LicenseData } from './LicenseData';
import { PersonalData } from './PersonalData';
import { ContactData } from './ContactData';
import { WorkData } from './WorkData';


const EditInfo = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const userLicenseData = user.stateLicense;
  // const isUserBrokerOrManager = user.kind === UserKindEnum.Broker || user.kind === UserKindEnum.Manage // JSX to TSX
  const isUserBrokerOrManager = user.kind === 'broker' || user.kind === 'manager';

  const { handleSubmit, errors, control, setValue } = useEditInfoForm(user, userLicenseData);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const labelToNameMap = language.reduce((acc, item) => {
      acc[item.label] = item.name;
      return acc;
    }, {});
    const specialization = data?.specialization?.map((el) => el.toLowerCase());
    const link = [data.additionalLinks, data.additionalLinksSecond, data.additionalLinksThird]?.filter((el) => el !== null);
    const propertyType = data?.propertyType?.map((el) => ProfilePropertyKindNewEnum[el]);
    const gender = data.gender.toLowerCase();
    const languages = data.language.map(label => labelToNameMap[label] || label);

    const getOperationRegionData = async (region) => {
      const response = await usersProvider.getOperationRegion({ query: region });
      return response.data.items[0];
    };
    const fetchOperationRegions = async () => {
      const operationRegionsData = await Promise.all(data.regionOfOperation.map(region => getOperationRegionData(region)));
      return operationRegionsData;
    };

    const fetchDataAndPrepare = async () => {
      const regions = await fetchOperationRegions();
      const operationRegionIds = regions.map((region) => region.id);

      const dataSubmit = {
        id: user?.id || '',
        name: data?.userName || '',
        additionalPhones: data.phoneNumberSecond ? [data.phone, data.phoneNumberSecond] : [data.phone],
        additionalEmails: [data.email] || [],
        address: data.address || null,
        nationality: data.nationality || null,
        gender: gender || null,
        description: data.description || null,
        links: link || [],
        telegram: data.telegram || null,
        whatsapp: data.whatsapp || null,
        instagram: data.instagram || null,
        linkedin: data.linkedin || null,
        propertyKinds: propertyType || [],
        activityKinds: specialization || [],
        operationRegions: operationRegionIds || [],
        languages: languages || [],
      };
      if (dataSubmit) {
        const { ok, message, status } = await usersProvider.patchMyUser(dataSubmit);
        if (ok) {
          const { ok: okGetMyUser, data: dataGetMyUser, status: statusGetMyUser, message: messageGetMyUser } = await usersProvider.getMyUser();
          if (okGetMyUser) {
            dispatch(authGetMyUser(dataGetMyUser));
            navigate('/profile/view');
          } else {
            dispatch(appSetNotification('error', messageGetMyUser, statusGetMyUser));
          }
        } else {
          dispatch(appSetNotification('error', message, status));
        }
      }
    };
    fetchDataAndPrepare();
    setIsLoading(false);
  }

  const isCheckedBroker = user.kind === 'broker' || user.kind === 'agency';
  const isCheckedPerson = user.kind === 'broker' || user.kind === 'manager' || user.kind === 'foreign';
  const isCheckedForeign = user.kind === 'foreign';
  const isCheckedManager = user.kind === 'manager';
  const isCheckedSecondPhone = user?.additionalPhones?.length === 2;

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isCheckedBroker && (
            <LicenseData 
              errors={errors}
              control={control}
            />
          )}
          <PersonalData 
            errors={errors}
            control={control}
            isCheckedBroker={isCheckedBroker}
            isCheckedPerson={isCheckedPerson}
            isCheckedForeign={isCheckedForeign}
          />
          <ContactData 
            errors={errors}
            control={control}
            isCheckedBroker={isCheckedBroker}
            isCheckedSecondPhone={isCheckedSecondPhone}
            setValue={setValue}
          />
          {!isCheckedForeign && (
            <WorkData 
              errors={errors}
              control={control}
              isCheckedBroker={isCheckedBroker}
              isCheckedManager={isCheckedManager}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: '16px',
              marginTop: '50px',
            }}
          >
            <MyButton
              data={{
                buttonName: 'Save information',
                variant: 'contained',
                customWidth: '208px',
                buttonType: 'submit',
              }}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                variant: 'outlined',
                customWidth: '157px',
                styleType: 'cancel',
              }}
              onClick={() => navigate('/profile/view')}
            />
          </Box>
        </form>
        <AgencyField isUserBrokerOrManager={isUserBrokerOrManager} />
      </div>
    </>
  );
};

export default EditInfo;
