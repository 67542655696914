import { useEffect, useState, ChangeEvent } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { checkDetailsData, checkDetailsDataAgency, error409 } from '../Utils/signUpDescription';
import { UserKindEnum, UserAgencyStateLicense } from '@/types';
import { LabelComponent } from '@/components';
import { StyledPhoneInput, ErrorText } from '../../../uiComponents/styled';
import { typographyH4Style, typographyBody1Style } from '../Utils/styled';
import { StyledLink } from '@/components/uiComponents/styled';

export const CheckDetails = ({
  user,
  kind,
  errorFields,
  setErrorFields,
  setUser,
  newPhone,
  setNewPhone,
  newEmail,
  setNewEmail,
  error,
}: {
  user: UserAgencyStateLicense;
  kind: UserKindEnum;
  errorFields: string[];
  setErrorFields: (fields: string[]) => void;
  setUser: (user: UserAgencyStateLicense) => void;
  newPhone: string;
  setNewPhone: (value: string) => void;
  newEmail: string;
  setNewEmail: (value: string) => void;
  error: boolean;
}) => {
  const [valuePhone, setValuePhone] = useState<string>('');
  const [valueEmail, setValueEmail] = useState<string>('');
  const isBroker = kind === UserKindEnum.Broker;
  const detailsData = isBroker ? checkDetailsData : checkDetailsDataAgency;
  const inputValues = [
    { name: 'name', value: user.nameEn },
    {
      name: isBroker ? 'companyName' : 'officeNumber',
      value: !isBroker ? user.stateId : user.agency?.nameEn ? user.agency?.nameEn : '',
    },
    { name: 'email', value: user.email },
    { name: 'phone', value: user.phone },
  ];

  useEffect(() => {
    const fullPhone =
      user?.phone?.length < 11
        ? user.phone.startsWith('0')
          ? `+971${user.phone.slice(1)}`
          : `+971${user.phone}`
        : user.phone;
    setValuePhone(user.phone ? fullPhone : newPhone);
    setValueEmail(user.email ? user.email : newEmail);
  }, []);

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {checkDetailsData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBody1Style}>
          {checkDetailsData.bodyTitle.description}
        </Typography>
      </Stack>
      {error && (
        <Stack direction='row' alignItems='center' display='flex' sx={{ maxWidth: '100%' }}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '15px',
              textAlign: 'center',
            }}
          >
            {error409.passiveLabel}{' '}
            <StyledLink to='/logIn' fontSize='15px' fontWeight='800'>
              {error409.label}
            </StyledLink>{' '}
            {error409.passiveLabelFinish}{' '}
          </Typography>
        </Stack>
      )}
      <Stack spacing={1.5} sx={{ width: '100%' }}>
        {detailsData.bodyContent.map((content, index) => {
          const isError = errorFields.includes(content.data.name);
          switch (content.typeOfComponent) {
            case 'input':
              return (
                <Stack spacing={0.5} key={`${content.data.name}-${index}`}>
                  <LabelComponent label={content.data.label} />
                  <TextField
                    value={inputValues.find((el) => el.name === content.data.name)?.value}
                    error={isError}
                    helperText={isError && content.errorText}
                    disabled
                    fullWidth
                  />
                </Stack>
              );
            case 'inputEmail':
              return (
                <Stack spacing={0.5} key={`${content.data.name}-${index}`}>
                  <LabelComponent label={content.data.label} />
                  <TextField
                    value={valueEmail}
                    error={isError}
                    helperText={isError && content.errorText}
                    disabled={user.email ? true : false}
                    fullWidth
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setValueEmail(event.target.value);
                      setNewEmail(event.target.value);
                      if (event.target.value) {
                        setErrorFields(errorFields.filter((field) => field !== 'email'));
                      }
                    }}
                  />
                </Stack>
              );
            case 'phoneInput':
              return (
                <Stack spacing={0.5} key={`${content.data.name}-${index}`}>
                  <LabelComponent label={content.data.label} />
                  <StyledPhoneInput
                    error={isError}
                    defaultCountry='AE'
                    value={valuePhone}
                    placeholder={content.data.placeholder}
                    disabled={user.phone ? true : false}
                    onChange={(e: any) => {
                      if (e) {
                        setErrorFields(errorFields.filter((field) => field !== 'phone'));
                      }
                      setNewPhone(e);
                    }}
                  />
                  {isError && <ErrorText>{content.errorText}</ErrorText>}
                </Stack>
              );
          }
        })}
      </Stack>
    </>
  );
};
