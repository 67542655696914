import queryString from 'query-string';
import { apiClient } from '@/providers';
import { AuthGetTokenResponseType } from '@/types';
// import { AdminUserRespose, BlockHistory, User } from '@/components/Admin/Users/types/types';

export const licenseProvider = {
  searchDeveloper: async (limit: number, offset: number, query?: string) => {
    const paramsStr = queryString.stringify({ query, limit, offset });
    const response = await apiClient(
      `/stateLicenseRegistry/admin/developer/search?${paramsStr}`,
      'GET'
    );
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as any;
    return { ...response, data };
  },
  searchOffPlanDeveloper: async (limit: number = 10, offset: number = 0, query?: string) => {
    const paramsStr = queryString.stringify({ query, limit, offset });
    const response = await apiClient(
      `/newConstruction/developers/search?${paramsStr}`,
      'GET'
    );
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as any;
    return { ...response, data };
  },

  createDeveloperLicense: async (data: any) => {
    if (!data.nameAr) {
      data.nameAr = data.nameEn;
    }
    const response = await apiClient(`/stateLicenseRegistry/developer`, 'POST', data);
    return response;
  },

  editDeveloperLicense: async (data: any) => {
    const response = await apiClient(`/stateLicenseRegistry/developer/${data.id}`, 'PATCH', data);
    return response;
  },

  deleteDeveloperLicense: async (id: string) => {
    const response = await apiClient(`/stateLicenseRegistry/developer/${id}`, 'DELETE');
    return response;
  },

  searchAgency: async (limit: number, offset: number, query?: string) => {
    const paramsStr = queryString.stringify({ query, limit, offset });
    const response = await apiClient(
      `/stateLicenseRegistry/admin/agency/search?${paramsStr}`,
      'GET'
    );
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as any;
    return { ...response, data };
  },

  createAgencyLicense: async (data: any) => {
    if (!data.nameAr) {
      data.nameAr = data.nameEn;
    }
    const response = await apiClient(`/stateLicenseRegistry/agency`, 'POST', data);
    return response;
  },

  editAgencyLicense: async (data: any) => {
    const response = await apiClient(`/stateLicenseRegistry/agency/${data.id}`, 'PATCH', data);
    return response;
  },

  deleteAgencyLicense: async (id: string) => {
    const response = await apiClient(`/stateLicenseRegistry/agency/${id}`, 'DELETE');
    return response;
  },

  searchBroker: async (limit: number, offset: number, query?: string) => {
    const paramsStr = queryString.stringify({ query, limit, offset });
    const response = await apiClient(
      `/stateLicenseRegistry/admin/broker/search?${paramsStr}`,
      'GET'
    );
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as any;
    return { ...response, data };
  },

  createBrokerLicense: async (data: any) => {
    if (!data.nameAr) {
      data.nameAr = data.nameEn;
    }
    const response = await apiClient(`/stateLicenseRegistry/broker`, 'POST', data);
    return response;
  },

  editBrokerLicense: async (data: any) => {
    const response = await apiClient(`/stateLicenseRegistry/broker/${data.id}`, 'PATCH', data);
    return response;
  },

  deleteBrokerLicense: async (id: string) => {
    const response = await apiClient(`/stateLicenseRegistry/broker/${id}`, 'DELETE');
    return response;
  },
};
