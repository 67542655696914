import { useState, useEffect } from 'react';
import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { findYourselfData, findYourCompanyData } from '../Utils/signUpDescription';
import { UserKindEnum, LicensesGetAgenciesResponseType, UserAgencyStateLicense, StateLicense } from '@/types';
import { LabelComponent } from '@/components';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { stateLicenseProvider } from '@/providers';
import { typographyH4Style, typographyBody1Style } from '../Utils/styled';

const MyLabel = ({ user }: { user: UserAgencyStateLicense }) => {
  return (
    <Typography>
      <span style={{ fontWeight: 600 }}>{user.nameEn}</span>
      {` (${user.email}, stateId: ${user.stateId})`}
    </Typography>
  );
};

export const FindYourself = ({
  isUsersVisible,
  setIsUsersVisible,
  radioUserValue,
  setRadioUserValue,
  radioValue,
  setError,
  isMobile,
  kind,
  newAutocompleteValue,
  setNewAutocompleteValue,
  setUser,
  error,
  helperText,
  newAgency,
  inviteCode,
}: {
  isUsersVisible: boolean;
  setIsUsersVisible: (isUsersVisible: boolean) => void;
  radioUserValue: string;
  setRadioUserValue: (radioUserValue: string) => void;
  radioValue: string;
  setError: (error: boolean) => void;
  isMobile: boolean;
  kind: UserKindEnum;
  newAutocompleteValue: string | null;
  setNewAutocompleteValue: (value: string | null) => void;
  setUser: (user: UserAgencyStateLicense) => void;
  error: boolean;
  helperText: string;
  newAgency?: StateLicense;
  inviteCode?: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);
  const [inputAutocompleteValue, setAutocompleteValue] = useState<string>('');
  const [users, setUsers] = useState<UserAgencyStateLicense[] | undefined>();

  useEffect(() => {
    handleOnChangeAutocomplete(inputAutocompleteValue);
    setError(false);
  }, [inputAutocompleteValue]);

  useEffect(() => {
    const fetchRes = async () => {
      if (!newAutocompleteValue) return;
      try {
        const result = await switchUserType(newAutocompleteValue);
        if (result?.ok && result?.data) {
          const { data, status, message } = result;
          const { items } = result.data as LicensesGetAgenciesResponseType;
          if (items.length > 1) {
            setIsUsersVisible(true);
            setUsers(items);
          } else {
            const user = items[0];
            setUsers(undefined);
            setIsUsersVisible(false);
            setUser(user);
          }
        }
      } catch (error) {
        console.error('Error request:', error);
      }
    };
    fetchRes();
  }, [newAutocompleteValue]);

  const switchUserType = (param: string) => {
    let result;
    switch (kind) {
      case UserKindEnum.Broker:
        result = stateLicenseProvider.searchBroker({ query: param });
        break;
      case UserKindEnum.Agency:
        result = stateLicenseProvider.searchAgency({ query: param });
        break;
      case UserKindEnum.Developer:
        result = stateLicenseProvider.searchDeveloper({ query: param });
        break;
      case UserKindEnum.Manager:
        result = stateLicenseProvider.searchAgency({ query: param });
        break;
      case UserKindEnum.Foreign:
        break;
      default:
        console.log(`Unknown action: ${kind}`);
    }
    return result;
  };

  const handleOnChangeAutocomplete = async (param: string) => {
    if (param.length < 2) return;
    const result = await switchUserType(param);
    if (result?.ok && result?.data) {
      const { items } = result.data as any;
      const optionsBrokerAndAgency = Array.from(
        new Set(
          items
            ?.flatMap((el: any) => [
              el.stateId?.includes(param) ? el.stateId : null,
              el.nameEn?.toLowerCase().includes(param.toLowerCase()) ? el.nameEn : null,
              el.phone?.includes(param) ? el.phone : null,
              el.email?.toLowerCase().includes(param.toLowerCase()) ? el.email : null,
            ])
            .filter(Boolean)
        )
      ) as string[];
      setOptions(optionsBrokerAndAgency);
    }
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioUserValue(event.target.value);
    // setError(false);
  };

  const isManager = kind === UserKindEnum.Manager;

  const findYourData = isManager ? findYourCompanyData : findYourselfData;

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {isManager
            ? findYourCompanyData.bodyTitle.label
            : findYourselfData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBody1Style}>
          {isManager
            ? findYourCompanyData.bodyTitle.description
            : findYourselfData.bodyTitle.description}
        </Typography>
      </Stack>
      <Stack spacing={1.5} sx={{ width: '100%' }}>
        {findYourData.bodyContent.map((content, index) => {
          switch (content.typeOfComponent) {
            case 'input':
              return <TextField key={content.data.name} value={radioValue} disabled fullWidth />;
            case 'autocomplete':
              return (
                <Stack key={content.data.name} sx={{ width: '100%' }}>
                  <LabelComponent label={content.data.label} />
                  <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    clearIcon={null}
                    options={options}
                    disabled={inviteCode && isManager ? true : false}
                    freeSolo
                    value={inviteCode && isManager ? newAgency?.nameEn : newAutocompleteValue ? newAutocompleteValue : ''}
                    getOptionLabel={(option) => option}
                    onInputChange={(event, newInputValue) => {
                      setAutocompleteValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setNewAutocompleteValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        placeholder={content.data.placeholder}
                        fullWidth
                        error={error && helperText.length > 0}
                        helperText={error && helperText}
                        sx={{ '& .MuiInputBase-input': { height: '16px', lineHeight: '16px' } }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <div
                                onClick={() => setOpen(!open)}
                                style={{
                                  position: 'absolute',
                                  cursor: 'pointer',
                                  pointerEvents: 'auto',
                                  right: 15,
                                  height: '20px',
                                  width: '20px',
                                  transform: open ? 'rotate(180deg)' : 'none',
                                }}
                              >
                                {(options.length > 1 || newAutocompleteValue )&& <CaretDown />}
                              </div>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  {isUsersVisible && (
                    <Stack>
                      <Typography sx={{ margin: '10px 0 10px 32px' }}>
                        There are several users in the DLD database
                        <span style={{ color: '#1650FF', fontWeight: 600 }}>
                          {' '}
                          {newAutocompleteValue}
                          {'. '}
                        </span>
                        <br />
                        <span
                          style={{
                            color: error && helperText.length === 0 ? '#ff0000' : '#2A3842',
                            fontWeight: error && helperText.length === 0 ? 600 : 400,
                          }}
                        >
                          Select your account:
                        </span>
                      </Typography>
                      <FormControl>
                        <RadioGroup value={radioUserValue} onChange={handleChangeRadio}>
                          <Stack spacing={1} sx={{ width: '100%' }}>
                            {users &&
                              users.map((user, index) => (
                                <FormControlLabel
                                  key={user.id}
                                  value={user.nameEn}
                                  control={
                                    <Radio
                                      sx={{
                                        padding: '0',
                                        alignSelf: 'center',
                                        color: '#2A384280',
                                        '&.Mui-checked': {
                                          color: '#1650FF',
                                        },
                                      }}
                                    />
                                  }
                                  label={<MyLabel user={user} />}
                                  sx={{ justifyContent: 'flex-start' }}
                                  onClick={() => setUser(user)}
                                />
                              ))}
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  )}
                </Stack>
              );
          }
        })}
      </Stack>
    </>
  );
};
