export const messageActionBgStyle = () => {
  return {
    position: 'absolute',
    top: '-39px',
    zIndex: '1',
    height: '39px',
  };
};

export const messageActionStyle = ({ theme }) => {
  return {
    backgroundColor: `${theme.backgroundColor.white}`,
    borderRadius: '8px',
    border: `1px solid ${theme.borderColor.primary}`,
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    gap: '5px',
    margin: '0',
    padding: '0 10px',
  };
};

export const groupButtonStyle = (img, { theme }) => {
  return {
    outline: '0',
    border: '0',
    height: '24px',
    width: '24px',
    borderRadius: '4px',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}!important`,
    cursor: 'pointer',
  };
};
