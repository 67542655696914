import { useCallback, useEffect, useState } from 'react';
import { usersProvider } from '@/providers';
import { NotificationTypeEnum, UserSettingsResponseType } from '@/types';
import { appSetNotification } from '@/store';
import { useDispatch } from 'react-redux';

export const useUserSettings = () => {
  const dispatch = useDispatch();
  const [userSettings, setUserSettings] = useState<UserSettingsResponseType | null>(null);
  const [isLoadingUserSettings, setIsLoadingUserSettings] = useState<boolean>(false);

  const refetchUserSettings = useCallback(async () => {
    setIsLoadingUserSettings(true);

    const { ok, data, status, message } = await usersProvider.getUserSettings();
    if (ok && data) {
      setUserSettings(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }

    setIsLoadingUserSettings(false);
  }, [dispatch]);

  useEffect(() => {
    refetchUserSettings();
  }, [refetchUserSettings]);

  const invalidateUserSettings = useCallback(() => {
    setUserSettings(null);
    refetchUserSettings();
  }, [refetchUserSettings]);

  return {
    userSettings,
    isLoadingUserSettings,
    refetchUserSettings,
    invalidateUserSettings,
  };
};
