export const msgTimestampStyle = ({ theme }) => {
  return {
    display: 'flex',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: `${theme.color.primary}`,
    opacity: '0.4',
  };
};

export const iconStyle = (img, color) => {
  return {
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${color}`,
    display: 'inline-block',
    width: '20px',
    height: '20px',
  };
};
