import { FC, ReactNode } from 'react';
import { Container, Dialog, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialog, StyleDialogTitle } from '../styled';
import { IMyDialogTitleProps, IMyDialogProps } from '../types';

const MyDialogTitle: FC<IMyDialogTitleProps> = ({ dialogTitle, onClose, dataQa, titleAlign }) => {
  return (
    <StyleDialogTitle
      sx={{
        m: 0,
        color: dialogTitle === 'Error' ? 'red' : '#2A3842',
        textAlign: titleAlign ? titleAlign : 'center',
      }}
    >
      {dialogTitle}
      {onClose ? (
        <IconButton
          data-qa={dataQa}
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyleDialogTitle>
  );
};

export const MyDialog: FC<IMyDialogProps> = ({
  open,
  onClose,
  dialogTitle,
  width,
  children,
  dataQa,
  titleAlign,
}) => {
  if (!open) return null;
  return (
    <StyledDialog open={open} width={width}>
      <Container component='main' style={{ width: '100%' }}>
        <MyDialogTitle onClose={onClose} dialogTitle={dialogTitle} dataQa={dataQa} titleAlign={titleAlign}/>
        <DialogContent>{children}</DialogContent>
      </Container>
    </StyledDialog>
  );
};
