import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BannerOnlineProperty from '@/components/Dashboard/img/banner-online-property.webp';
import { MyButton } from '@/components';

const sectionStyle = { alignSelf: 'center', margin: '0 auto' };
const divStyle = {
  display: 'flex',
  gap: '40px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  minHeight: {
    xs: '650px',
    sm: '450px',
  },
  margin: '0 20px',
  flexDirection: 'row',
};

const stackStyle = {
  maxWidth: '480px',
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  justifyContent: 'center',
  gap: '14px',
};
const typographyH4Style = {
  textTransform: {
    xs: 'uppercase',
    sm: 'none',
  },
  fontSize: {
    xs: '20px',
    sm: '32px',
  },
  fontWeight: '700',
};
const boxStyle = {
  display: 'flex',
  gap: '20px',
  marginTop: '12px',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

export const Welcome = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section style={sectionStyle}>
      <Stack sx={divStyle}>
        {!isMobile && (
          <Box>
            <img
              style={{ width: '100%', maxWidth: '400px', objectFit: 'fill' }}
              src={BannerOnlineProperty}
              alt='banner online property'
            />
          </Box>
        )}
        <Stack sx={stackStyle}>
          <Typography variant='h4' sx={typographyH4Style}>
            We&rsquo;re thrilled to&nbsp;have you on board at&nbsp;
            <span style={{ color: '#1650FF' }}>Online Property!</span>
          </Typography>

          <Typography variant='body1' sx={{ fontSize: 16 }}>
            Please bear with&nbsp;us for any possible errors and bug 😄 We&rsquo;re in&nbsp;beta
            testing, and your input is&nbsp;invaluable!
          </Typography>
          <Box sx={boxStyle}>
            <MyButton
              data={{
                customWidth: '177px',
                buttonName: 'Sign up',
                variant: 'contained',
                buttonType: 'submit',
                dataQa: 'sign_up_btn',
              }}
              onClick={() => navigate('/signUp')}
            />
            <MyButton
              data={{
                customWidth: '177px',
                buttonName: 'Log in',
                variant: 'outlined',
                styleType: 'cancel',
                dataQa: 'login_btn',
              }}
              onClick={() => navigate('/logIn')}
            />
          </Box>
        </Stack>
      </Stack>
    </section>
  );
};
