import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useChangeQueryParams } from '@/hooks';
import { Controller, Control } from 'react-hook-form';
import { Stack, TextField, Typography, InputAdornment } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { createPasswordData } from './Utils/signUpDescription';
import { LabelComponent } from '@/components';
import { StyledIconButton } from '@/components/uiComponents/styled';
import { IMyTextFieldProps, IDataTextFieldProps } from '../../uiComponents/types';
import { useCreatePasswordForm } from '../utils/useFormLogic';
import { AuthResetPasswordRequestType } from '@/types';

const typographyH4Style = {
  textTransform: 'uppercase',
  fontSize: {
    xs: '24px',
    sm: '28px',
  },
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontWeight: '700',
};
const typographyBody1Style = {
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
  fontSize: 16,
  color: '#2A3842',
};

export const CreatePassword = ({
  onChange,
  helperText,
  error,
  setError,
  loginDataSubmit,
  type,
}: {
  onChange: (value: string, name: string) => void;
  helperText: string;
  error: boolean;
  setError: (error: boolean) => void;
  loginDataSubmit?: AuthResetPasswordRequestType;
  type?: string;
}) => {
  const isSignUp = type === 'signUp';
  const changeQueryParams = useChangeQueryParams(isSignUp ? 'signUp' : 'login');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  useEffect(() => {
    if (isSignUp) {
      changeQueryParams({ ...queryParams, step: 'four' });
    }
  }, []);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const typePassword = (name: string) => {
    return showPassword ? 'text' : 'password';
  };
  const IconComponent = showPassword ? VisibilityOffOutlined : VisibilityOutlined;

  const { handleSubmit, errors, control, resetForm } = useCreatePasswordForm();

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {createPasswordData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBody1Style}>
          {createPasswordData.bodyTitle.description}
        </Typography>
      </Stack>
      <form>
        <Stack spacing={1.5} sx={{ width: '100%', minWidth: '320px' }}>
          {createPasswordData.bodyContent.map((content, index) => {
            return (
              <Stack key={`${content.data.name}-${index}`} spacing={0.5}>
                <LabelComponent label={content.data.label} />
                <Controller
                  key={`${content.data.name}-${index}`}
                  control={control}
                  name={content.data.name as 'password' | 'confirmPassword'}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        variant='outlined'
                        fullWidth
                        placeholder={content.data.placeholder}
                        inputProps={{ maxLength: content.data.maxLength }}
                        type={typePassword(content.data.name)}
                        // error={content.data.name === 'password' ? passwordError : confirmPasswordError}
                        // helperText={error 
                        //   ? content.data.name === 'password' 
                        //     ? passwordHelperText
                        //     : confirmPasswordHelperText
                        //   : null}
                        error={error}
                        helperText={error ? helperText : null}
                        onClick={() => setError(false)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e?.target;
                          onChange(value, content.data.name);
                          field.onChange(e);
                        }}
                        InputProps={
                          content.data.endAdornment
                            ? {
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <StyledIconButton
                                      data-qa='open_pass_btn'
                                      // disabled={disabled}
                                      aria-label='toggle password visibility'
                                      onClick={() =>
                                        setShowPassword && setShowPassword((prev) => !prev)
                                      }
                                      edge='end'
                                    >
                                      <IconComponent />
                                    </StyledIconButton>
                                  </InputAdornment>
                                ),
                              }
                            : {}
                        }
                      />
                    );
                  }}
                />
              </Stack>
            );
          })}
        </Stack>
      </form>
    </>
  );
};
