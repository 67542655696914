import { FC } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ICircleNumberProps } from '../types';

export const CircleNumber: FC<ICircleNumberProps> = ({
  number,
  size,
  colorText,
  borderColor,
  backgroundColor,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const circleSize = isMobile ? '40px' : size;
  const fontSize = isMobile ? '18px' : '24px';
  return (
    <Typography
      component='div'
      style={{
        fontSize: fontSize,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: circleSize,
        height: circleSize,
        borderRadius: '50%',
        backgroundColor: backgroundColor,
        color: colorText,
        border: `1px solid ${borderColor}`,
      }}
    >
      {number}
    </Typography>
  );
};
