import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  State,
  NewBuildingModel,
  BuildingsType,
  FileKindEnum,
  NotificationTypeEnum,
  BuildingsDataType,
  CreateNewPlanType,
} from '@/types';
import { appSetNotification } from '@/store';
import { newBuildingsProvider } from '@/providers';
import { getKindLabel } from '@/utils';
import { Loader, MyButton, MyDialog } from '@/components';
import styles from './Ad.module.scss';

import { NumOfAd } from './styled';
import { icons } from '../Ad/Icons/icons';
import {
  BasicDataBlock,
  DescriptionBlock,
  ForecastBlock,
  ObjectFeatureBlock,
  PaymentPlanBlock,
  MapBlock,
  AmenitiesBlock,
  MediaBlock,
  ContactBlock,
  PlansBlock,
  NewBuildingModal,
} from '../PlanView';
import { Comments } from '../Ad/Comments/Comments';

export const PlanView = () => {
  const [page, setPage] = useState<NewBuildingModel>();
  const [plans, setPlans] = useState<CreateNewPlanType[]>();
  const [plan, setPlan] = useState<CreateNewPlanType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [isAddBuilding, setIsAddBuilding] = useState<boolean>(false);

  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { id, planId } = useParams<{ id: string; planId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getPlanById = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getPlanById(id!, planId);
    if (ok && data) {
      setPlan(data as CreateNewPlanType);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  // const getPlanById = async () => {
  //   setIsLoading(true);
  //   const { ok, data, status, message } = await newBuildingsProvider.getPlans(id!);
  //   if (ok && data) {
  //     const { items, total } = data;
  //     setPlans(items as CreateNewPlanType[]);
  //   } else {
  //     dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
  //   }
  //   setIsLoading(false);
  // };

  const getOffPlanData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getOffPlanById(id!);
    if (ok && data) {
      setPage(data as NewBuildingModel);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && id) {
      getOffPlanData();
      getPlanById();
    }
  }, []);

  // useEffect(() => {
  //   const newPlan = plans?.filter((plan: CreateNewPlanType) => plan.id === planId);
  //   if (newPlan && newPlan?.length > 0) {
  //     setPlan(newPlan[0]);
  //   }
  // }, [plans]);

  const videos = page?.media && page?.media.filter((item) => item.mediaType === FileKindEnum.Video);
  const brochures = page?.media && page?.media.filter((item) => item.mediaType === 'brochure');

  const formateDate = (value: string | null) =>
    value !== null ? moment(value).format('D, MMMM, YYYY') : null;

  const description = page?.description?.trim() || '';

  const handleCloseConfirmDialog = () => setIsShownConfirmDialog(false);

  const isVisible = plan && page;

  const isShowMedia = (videos && videos?.length > 0) || (brochures && brochures?.length > 0);

  return isLoading ? (
    <Loader />
  ) : !isVisible ? null : (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='flex-start'
      justifyContent='center'
      spacing={2}
      sx={{ '@media (max-width: 600px)': { width: 'inherit' } }}
    >
      <Stack
        alignItems='flex-start'
        spacing={2}
        sx={{ flexGrow: '1', width: '100%', minWidth: { sm: '580px' } }}
      >
        {plan && (
          <BasicDataBlock
            page={page}
            plan={plan}
            isMobile={isMobile}
            setIsShownConfirmDialog={setIsShownConfirmDialog}
          />
        )}
        {description.length > 0 && <DescriptionBlock description={description} />}
        {plan && (
          <PlansBlock
            page={page}
            isAddBuilding={isAddBuilding}
            setIsAddBuilding={setIsAddBuilding}
            plan={plan}
          />
        )}
        {page.forecast && <ForecastBlock page={page} />}
        <ObjectFeatureBlock page={page} />
        {page.paymentPlan && <PaymentPlanBlock paymentPlan={page.paymentPlan} />}
        {isShowMedia && <MediaBlock videos={videos} brochures={brochures} isMobile={isMobile} />}
        <MapBlock coordinatesLat={page?.coordinatesLat} coordinatesLong={page?.coordinatesLong} />
        <AmenitiesBlock page={page} />
      </Stack>
      <ContactBlock page={page} />
      <NewBuildingModal
        open={isShownConfirmDialog}
        onClose={handleCloseConfirmDialog}
        id={page.id}
        isAddBuilding={isAddBuilding}
        setIsAddBuilding={setIsAddBuilding}
        typeOfDialog='create'
      />
    </Stack>
  );
};
