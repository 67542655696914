import {
  ResponseType,
  PaginationType,
  FriendsRequestType,
  FriendsGetResponse,
  FriendsFilteringType,
  AdsSortingType,
} from '@/types';
import { apiClient } from '@/providers';
import {AdsGetFavoritesResponseType} from "@/types/responses";

export const friendsProvider = {
  isFriend: async (id: string) => {
    const response = await apiClient(`/user/friends/isFriendsWith?friendId=${id}`, 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  createRequest: async (payload: FriendsRequestType) => {
    const response = await apiClient('/user/friends/createRequest', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getFriends: async (
    filteringObj: FriendsFilteringType,
    sortingObj: AdsSortingType[],
    paginationObj: PaginationType
  ): Promise<ResponseType<FriendsGetResponse>> => {
    const payload = {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      filter: filteringObj,
      sort: sortingObj,
    }
    const response = await apiClient(`/user/me/friends`, 'POST', payload);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as FriendsGetResponse;
    return { ...response, data };
  },

  acceptFriendsRequest: async (id: string): Promise<ResponseType> => {
    const response = await apiClient(`/user/friends/accept/${id}`, 'PATCH');

    return { ...response, data: null };
  },

  removeFriends: async (id: string): Promise<ResponseType> => {
    const response = await apiClient(`/user/friends/${id}`, 'DELETE');

    return { ...response, data: null };
  },
};
