export const detailStyle = (context) => {
  return {
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: `${context.theme.fontFamily}`,
    '*': {
      boxSizing: 'border-box',
      fontFamily: `${context.theme.fontFamily}`,
    },
  };
};

export const headerStyle = (context) => {
  return {
    padding: '16px 20px',
    position: 'relative',
    borderBottom: `1px solid ${context.theme.borderColor.primary}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '78px',
  };
};

export const headerCloseStyle = (img, { theme }) => {
  return {
    cursor: 'pointer',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
    width: '24px',
    height: '24px',
  };
};

export const headerTitleStyle = ({ theme }) => {
  return {
    margin: '0',
    fontWeight: '600',
    fontSize: '24px',
    color: `${theme.color.primary}`,
  };
};

export const detailPaneStyle = () => {
  return {
    padding: '0 20px 16px',
    height: 'calc(100% - 100px)',
  };
};

export const sectionStyle = () => {
  return {
    width: '100%',
    marginTop: '25px',
    '&.section__members': {
      height: '104px',
    },
    '&.section__options': {
      height: '76px',
    },
  };
};

export const sectionHeaderStyle = (context) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    color: `${context.theme.color.primary}`,
    opacity: '0.4',
  };
};

export const sectionContentStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    margin: '6px 0',
  };
};

export const itemLinkStyle = ({ theme }, img, deleteLink) => {
  const color = deleteLink ? theme.color.red : theme.color.primary;

  return {
    display: 'inline-flex',
    gap: '8px',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    cursor: 'pointer',
    color: `${color}`,
    '&::before': {
      content: `""`,
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${color}`,
      width: '20px',
      height: '20px',
      position: 'relative',
      top: '-1px',
    },
  };
};
