import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum } from '@/types';
import { Loader } from '@/components';
import { locationProvider } from '@/providers/locationProvider';
import { VerificationRequestModel } from '@/types/location';
import { OptionsMenu } from './OptionsMenu';
import moment from 'moment';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FCFF',
  },
}));

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export const LocationListTable = ({
  handleClarify,
  isChangedRequestData,
}: {
  handleClarify: (row: VerificationRequestModel) => void;
  isChangedRequestData: boolean;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<VerificationRequestModel[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getLocationListData = async () => {
    setIsLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const requestData = {
      offset: offset,
      limit: limit,
    };
    const { ok, data, status, message } =
      await locationProvider.verificationRequestSearch(requestData);
    if (ok && data) {
      const { items, total } = data;
      setRows(items);
      setTotalCount(+total);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLocationListData();
  }, [page, rowsPerPage, isChangedRequestData]);

  if (isLoading) return <Loader />;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>User name</StyledTableCell>
            <StyledTableCell align='center'>User type</StyledTableCell>
            <StyledTableCell align='center'>Phone number</StyledTableCell>
            <StyledTableCell align='center'>District name</StyledTableCell>
            <StyledTableCell align='center'>Building name</StyledTableCell>
            <StyledTableCell align='center'>Listing id</StyledTableCell>
            <StyledTableCell align='center'>Date of request</StyledTableCell>
            <StyledTableCell align='center'></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <StyledTableRow key={row.id + index}>
              <StyledTableCell component='th' scope='row'>
                <strong style={{ fontWeight: 600 }}>{row.userName}</strong>
                <br />
                <strong style={{ fontSize: 12, fontWeight: 400, color: '#1650FF' }}>
                  {row.agency}
                </strong>
              </StyledTableCell>
              <StyledTableCell align='center'>{row.userKind}</StyledTableCell>
              <StyledTableCell align='center'>{row.phone}</StyledTableCell>
              <StyledTableCell align='center'>{row.districtName}</StyledTableCell>
              <StyledTableCell align='center'>{row.locationName}</StyledTableCell>
              <StyledTableCell align='center'>{row.propertyAdId}</StyledTableCell>
              <StyledTableCell align='center' sx={{ minWidth: 100 }}>
                {moment(row.createdAt).format('MMM D, YYYY')}
              </StyledTableCell>
              <StyledTableCell align='center'>
                <OptionsMenu onClarify={() => handleClarify(row)} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={7}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
