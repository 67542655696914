import { FileKindEnum, IPdfDocument } from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { GallerySection } from './GallerySection';

import {
  BedIcon,
  BuildingsBlue,
  BuildingsIcon,
  CalendarBlue,
  CurrencyCircleDollarIcon,
  FloorBlue,
  FloorIcon,
  FurnishingBlue,
  FurnishingIcon,
  HouseLineIcon,
  LocationWhiteIcon,
  NoteIcon,
  PaintRollerBlue,
  PaintRollerIcon,
  ParkingBlue,
  RulerIcon,
  ShowerIcon,
} from '@/assets/pdf';

import { ContactSection } from './ContactSection';
import { Picture } from './Picture';
import { IOffplanPdfDocument } from '@/types/pdf';
import { splitAndReformat } from '../ClassicView/UnitsPdfDocument';
import { IUnit } from '@/types/offplans';
import { UnitCard } from './UnitCard';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});
type GroupedUnits = {
  [key: string]: IUnit[];
};
const baseStyles = StyleSheet.create({
  poppinsBold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  informationInsideImage: {
    display: 'flex',
    borderRadius: 30,
    position: 'absolute',
    padding: 23,
  },
  blockTitleImageText: {
    backgroundColor: '#f9fcffcc',
    color: '#1650FF',
    padding: '32px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
  },
  featureBlock: {
    width: '48vw',
    color: '#1650FF',
  },
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    padding: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  bold: {
    fontSize: 80,
    ...baseStyles.poppinsBold,
  },
  price: {
    fontSize: 96,
    lineHeight: 1.4,
    color: '#1650FF',
    ...baseStyles.poppinsBold,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#1650FF',
  },
  blockTitleImageText: {
    fontSize: 106,
    maxWidth: '1800px',
    ...baseStyles.poppinsBold,
    ...baseStyles.blockTitleImageText,
  },
  blocksInsideImageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceWrapper: {
    ...baseStyles.poppinsBold,
    ...baseStyles.blockTitleImageText,
  },
  image: {
    height: '48vh',
    borderRadius: 10,
    objectFit: 'cover',
    position: 'relative',
  },
  mainImageWrapper: {
    flexDirection: 'column',
    gap: '8px',
    top: '34px',
    margin: 'auto',
    left: 0,
    textAlign: 'center',
    right: 0,
    ...baseStyles.informationInsideImage,
  },
  locationBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#1650FF',
    padding: '8px 19px 9px 20px',
    gap: '8px',
  },
  secondFeatureBlock: {
    display: 'flex',
    paddingLeft: '200px',
    ...baseStyles.featureBlock,
  },
  firstFeatureBlock: {
    ...baseStyles.featureBlock,
  },
  characteristicsContainer: {
    backgroundColor: '#F1F7FF',
    padding: '24px',
    borderRadius: '8px',
    width: '100%',
  },
  characteristicsMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    marginBottom: '30px',
  },
  characteristicsInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  characteristicsText: {
    color: '#2A3842',
    opacity: 0.5,
    fontSize: 80,
  },
  values: {
    color: '#1650FF',
    fontWeight: 600,
    fontSize: 106,
  },
  priceText: {
    color: '#2A3842',
    fontWeight: 600,
    fontSize: 80,
  },
});

export function PdfDocument({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  units,
}: IOffplanPdfDocument) {
  const characteristics = [
    {
      name: 'Developent status',
      value: offplanData?.developmentStatus
        ? splitAndReformat(offplanData?.developmentStatus)
        : null,
      icon: PaintRollerBlue,
    },
    {
      name: 'Property type',
      value: offplanData?.constructionType ? splitAndReformat(offplanData?.constructionType) : null,
      icon: BuildingsBlue,
    },
    {
      name: 'Floor',
      value: offplanData?.numberOfFloors,
      icon: FloorBlue,
    },
    {
      name: 'Handover',
      value: offplanData?.handover,
      icon: CalendarBlue,
    },
    {
      name: 'Furnishing',
      value: offplanData?.furnishing ? 'Yes' : 'No',
      icon: FurnishingBlue,
    },
    {
      name: 'Parking',
      value: offplanData?.coveredParking ? 'Yes' : 'No',
      icon: ParkingBlue,
    },
  ];
  const splitPoint = Math.ceil(characteristics.length / 2);

  const firstHalf = characteristics.slice(0, splitPoint);
  const secondHalf = characteristics.slice(splitPoint);

  const renderCharacteristics = (chars: any[]) =>
    chars.map((character, index, arr) => (
      <View key={character.name} style={styles.characteristicsContainer}>
        <View style={styles.characteristicsInnerContainer}>
          <IconText iconSrc={character.icon}>{character.name}</IconText>
          <Text style={styles.characteristicsText}> {character.value}</Text>
        </View>
        {arr.length > index + 1 && <Viewider />}
      </View>
    ));
  const groupAndSortUnits = (units: IUnit[] | undefined) => {
    if (!units) return {};

    const groupedUnits = units.reduce<GroupedUnits>((acc, unit) => {
      const beds = unit.beds;
      if (!acc[beds]) {
        acc[beds] = [];
      }
      acc[beds].push(unit);
      return acc;
    }, {});

    Object.keys(groupedUnits).forEach((beds) => {
      groupedUnits[beds].sort((a, b) => a.price - b.price);
    });

    return groupedUnits;
  };

  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
            {showContent.images ? (
              <>
                <View>
                  <Picture src={showContent.images[0].fileUrl} style={styles.image} />
                  <View style={styles.mainImageWrapper}>
                    <View style={styles.blocksInsideImageWrapper}>
                      <Text style={styles.blockTitleImageText}>{offplanData?.title}</Text>
                    </View>
                    <View>
                      <View style={styles.blocksInsideImageWrapper}></View>
                    </View>
                  </View>
                  <View style={styles.locationBlock}>
                    <Image src={LocationWhiteIcon} style={{ width: '98px', height: '92px' }} />
                    <Text style={{ fontSize: '82px', color: 'white' }}>{offplanData?.nameEn}</Text>
                  </View>
                </View>
                <View></View>
              </>
            ) : null}
          </View>
          <View style={styles.characteristicsMainContainer}>
            <View>{renderCharacteristics(firstHalf)}</View>
            <View>{renderCharacteristics(secondHalf)}</View>
          </View>
          <View style={{ flexDirection: 'column', gap: '16px' }}>
            <View style={{ flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
              <Text style={styles.priceText}>from</Text>
              <Text style={styles.values}>{offplanData?.convertedPriceFrom}</Text>
              <Text style={styles.priceText}>to</Text>
              <Text style={styles.values}>{offplanData?.convertedPriceTo}</Text>
              <Text style={styles.priceText}>{offplanData?.currency}</Text>
            </View>
            <View style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
              <Text style={styles.priceText}>from</Text>
              <Text style={styles.values}>{offplanData?.convertedBuildUpAreaFrom}</Text>
              <Text style={styles.priceText}>sqft</Text>
            </View>
          </View>
          <Section wrap={true}>
            <Text style={{ fontSize: '84' }}>{offplanData?.description}</Text>
          </Section>
          {Object.entries(groupAndSortUnits(units)).map(([beds, units]) => (
            <View key={beds}>
              <Text
                style={{
                  fontSize: 68,
                  fontWeight: 'bold',
                  color: '#1650FF',
                  marginVertical: 10,
                }}
              >
                {beds} Room unit
              </Text>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
                {units.map((unit) => (
                  <UnitCard
                    key={unit.id}
                    unit={unit}
                    offplanData={offplanData}
                    showPrice={showContent.showPrice}
                  />
                ))}
              </View>
            </View>
          ))}
          <FeaturesSection data={offplanData} />
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>

          <GallerySection wrap={true} title='Gallery' images={showContent.images} />
        </View>
      </Page>
    </Document>
  );
}

const Viewider = () => (
  <View
    style={{
      width: '100%',
      height: 2,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);
