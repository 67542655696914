import React from 'react';
import { FormCheckbox, SectionTitle } from '@/components';
import { IOffPlanModel } from '@/types';
import { IAmenity, IAmenitiesCategories } from '../OffPlanAdmin.utils';
import styles from './FeaturesAmenities.module.scss';

interface IAmenitiesColumnProps {
  groupTitle: string;
  amenities: IAmenity[];
}

const AmenitiesColumn: React.FC<IAmenitiesColumnProps> = ({ groupTitle, amenities }) => (
  <div className={styles.column}>
    <div className={styles.blockTitle}>{groupTitle}</div>
    {amenities.map((amentity: IAmenity) => (
      <FormCheckbox
        key={amentity.key}
        name={amentity.key}
        label={amentity.name}
        checked={amentity.value}
      />
    ))}
  </div>
);

interface IFeaturesAmenitiesProps {
  fields: IAmenitiesCategories;
  setNewValue?: (field: Partial<IOffPlanModel>) => void;
}

export const FeaturesAmenities: React.FC<IFeaturesAmenitiesProps> = ({ fields }) => (
  <div>
    <SectionTitle number={6} title='Amenities' />
    <div className={styles.container}>
      {Object.entries(fields).map(([groupTitle, amenities], index) => (
        <AmenitiesColumn key={groupTitle} groupTitle={groupTitle} amenities={amenities} />
      ))}
    </div>
  </div>
);
