import { useState, ChangeEvent } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { contactDataForeign, contactDataManager, error409 } from '../Utils/signUpDescription';
import { LabelComponent } from '@/components';
import { StyledPhoneInput, ErrorText } from '@/components/uiComponents/styled';
import { UserKindEnum } from '@/types';
import { typographyH4Style, typographyBody1Style } from '../Utils/styled';
import { PersonalDateOfForeign, ContactDateOfForeign, ContactDateOfManager } from '../Utils/types';
import { StyledLink } from '@/components/uiComponents/styled';

export const ContactDataForeign = ({
  contactDateOfForeign,
  setContactDateOfForeign,
  errorFields,
  setErrorFields,
  personalDateOfForeign,
  kind,
  contactDateOfManager,
  setContactDateOfManager,
  error,
  setError,
}: {
  setContactDateOfForeign: (value: ContactDateOfForeign) => void;
  contactDateOfForeign: ContactDateOfForeign;
  errorFields: string[];
  setErrorFields: (fields: string[]) => void;
  personalDateOfForeign: PersonalDateOfForeign;
  kind: UserKindEnum;
  setContactDateOfManager: (value: ContactDateOfManager) => void;
  contactDateOfManager: ContactDateOfManager;
  error: boolean;
  setError: (error: boolean) => void;
}) => {
  const isForeign = kind === UserKindEnum.Foreign;
  const contactData = isForeign ? contactDataForeign : contactDataManager;
  const [value, setValue] = useState(
    isForeign ? contactDateOfForeign.phone : contactDateOfManager.phone
  );

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {contactData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBody1Style}>
          {contactData.bodyTitle.description}
        </Typography>
      </Stack>
      {error && (
        <Stack direction='row' alignItems='center' display='flex' sx={{ maxWidth: '100%' }}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '15px',
              textAlign: 'center',
            }}
          >
            {error409.passiveLabel}{' '}
            <StyledLink to='/logIn' fontSize='15px' fontWeight='800'>
              {error409.label}
            </StyledLink>{' '}
            {error409.passiveLabelFinish}{' '}
          </Typography>
        </Stack>
      )}
      <Stack spacing={1.5} sx={{ width: '100%' }}>
        {contactData.bodyContent.map((content, index) => {
          const isError = errorFields.includes(content.data.name);
          const isName = content.data.name === 'name';
          const isPrivate = isName && personalDateOfForeign.formOfOrganization === 'Private person';
          switch (content.typeOfComponent) {
            case 'input':
              return (
                <Stack key={content.data.name} sx={{ width: '100%' }}>
                  <LabelComponent
                    label={
                      !isName || isPrivate || !isForeign
                        ? content.data.label
                        : content.data.labelSecond
                    }
                  />
                  <TextField
                    name={content.data.name}
                    fullWidth
                    placeholder={
                      !isName || isPrivate || !isForeign
                        ? content.data.placeholder
                        : content.data.placeholderSecond
                    }
                    error={isError}
                    helperText={isError && content.errorText}
                    inputProps={{ maxLength: content.data.maxLength }}
                    value={
                      isForeign
                        ? contactDateOfForeign[content.data.name as keyof ContactDateOfForeign]
                        : contactDateOfManager[content.data.name as keyof ContactDateOfManager]
                    }
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const fieldName = isForeign
                        ? (event.target.name as keyof ContactDateOfForeign)
                        : (event.target.name as keyof ContactDateOfManager);
                      const value = event.target.value;
                      const newStateForeign: ContactDateOfForeign = {
                        ...contactDateOfForeign,
                        [fieldName]: value,
                      };
                      const newStateManager: ContactDateOfManager = {
                        ...contactDateOfManager,
                        [fieldName]: value,
                      };
                      if (isForeign) {
                        setContactDateOfForeign(newStateForeign);
                      } else {
                        setContactDateOfManager(newStateManager);
                      }

                      if (value) {
                        setErrorFields(errorFields.filter((field) => field !== fieldName));
                        if (errorFields.length === 0) {
                          setError(false);
                        }
                      }
                    }}
                  />
                </Stack>
              );
            case 'phoneInput':
              return (
                <Stack key={content.data.name} sx={{ width: '100%' }}>
                  <LabelComponent label={content.data.label} />
                  <StyledPhoneInput
                    defaultCountry='AE'
                    placeholder={content.data.placeholder}
                    value={value}
                    error={isError}
                    onChange={(event: any) => {
                      const fieldName = isForeign
                        ? (content.data.name as keyof ContactDateOfForeign)
                        : (content.data.name as keyof ContactDateOfManager);
                      const newStateForeign: ContactDateOfForeign = {
                        ...contactDateOfForeign,
                        [fieldName]: event,
                      };
                      const newStateManager: ContactDateOfManager = {
                        ...contactDateOfManager,
                        [fieldName]: event,
                      };
                      if (isForeign) {
                        setContactDateOfForeign(newStateForeign);
                      } else {
                        setContactDateOfManager(newStateManager);
                      }
                      setValue(event);
                      if (event) {
                        setErrorFields(errorFields.filter((field) => field !== fieldName));
                        if (errorFields.length === 0) {
                          setError(false);
                        }
                      }
                    }}
                  />
                  {isError && <ErrorText>{content.errorText}</ErrorText>}
                </Stack>
              );
          }
        })}
      </Stack>
    </>
  );
};
