import { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Stack, Typography } from '@mui/material';
import { MyButton } from '@/components';

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null | undefined>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-ok`,
      },
    });

    if (error) {
      setMessage(error.message);
    }
    setIsProcessing(false);
  };

  return (
    <section style={{ margin: '0 auto' }}>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 600, marginBottom: '15px', textAlign: 'center' }}
      >
        Payment processing
      </Typography>
      <form id='payment-form' onSubmit={handleSubmit}>
        <Stack
          spacing={5}
          sx={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '14px',
            padding: '20px',
            border: '1px solid #E9EEF1',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <PaymentElement />
          <Stack spacing={2}>
            <MyButton
              disabled={isProcessing || !stripe}
              data={{
                buttonName: isProcessing ? 'Processing...' : 'Pay now',
                customWidth: '300px',
                variant: 'contained',
                buttonType: 'submit',
                styleType: 'edit',
              }}
            />
            {message && (
              <Typography sx={{ color: 'red', textAlign: 'center' }}>{message}</Typography>
            )}
          </Stack>
        </Stack>
      </form>
    </section>
  );
};
