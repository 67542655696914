import { CometChat } from '@cometchat-pro/chat';
import Translator from '../../../resources/localization/translator';

export const userDetailStyle = (context) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: `${context.theme.fontFamily}`,
    '*': {
      boxSizing: 'border-box',
      fontFamily: `${context.theme.fontFamily}`,
    },
  };
};

export const headerStyle = (context) => {
  return {
    padding: '16px 20px',
    position: 'relative',
    borderBottom: `1px solid ${context.theme.borderColor.primary}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '78px',
  };
};

export const headerCloseStyle = (img, { theme }) => {
  return {
    cursor: 'pointer',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
    width: '24px',
    height: '24px',
  };
};

export const headerTitleStyle = ({ theme }) => {
  return {
    margin: '0',
    fontWeight: '600',
    fontSize: '24px',
    color: `${theme.color.primary}`,
  };
};

export const sectionStyle = () => {
  return {
    padding: '16px 20px 0',
  };
};

export const actionSectionStyle = (context) => {
  return {
    width: '100%',
    '> div': {
      fontWeight: '600',
      cursor: 'pointer',
      fontSize: '12px',
    },
    '.item__link': {
      color: `${context.theme.color.blue}`,
    },
  };
};

export const participantSectionStyle = () => {
  return {
    width: '100%',
  };
};

export const privacySectionStyle = () => {
  return {
    width: '100%',
  };
};

export const mediaSectionStyle = () => {
  return {
    padding: '0 20px',
    height: 'calc(100% - 290px)',
  };
};

export const sectionHeaderStyle = (context) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    color: `${context.theme.color.primary}`,
    opacity: '0.4',
  };
};

export const sectionContentStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    margin: '6px 0',
  };
};

export const itemLinkStyle = ({ theme }) => {
  return {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    color: `${theme.color.red}`,
    cursor: 'pointer',
  };
};

export const userInfoSectionStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
};

export const userThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    display: 'inline-block',
    flexShrink: '0',
    margin: '0 16px 0 0',
  };
};

export const userNameStyle = ({ theme }) => {
  return {
    margin: '0',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: `${theme.color.primary}`,
  };
};

export const userStatusStyle = () => {
  return {
    width: 'calc(100% - 50px)',
  };
};

export const userPresenceStyle = (context, state) => {
  let status = state.status ? state.status.toLowerCase() : '';
  let compareTo = Translator.translate(
    CometChat.USER_STATUS.ONLINE.toUpperCase(),
    context.language
  ).toLowerCase();
  status =
    status === compareTo
      ? { color: `${context.theme.color.blue}` }
      : { color: `${context.theme.color.helpText}` };

  return {
    width: 'calc(100% - 50px)',
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '20px',
    ...status,
  };
};
