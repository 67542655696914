import { renovationValues } from '@/components/Admin/OffPlanAdmin/PropertyInformation/PropertyInformation.init';

interface ISelectItem {
  label: string;
  value: string;
}

export const rentSalesExtraChoices = (elType?: string) => {
  let choices: ISelectItem[] | undefined = undefined;
  let label = 'Select activity';

  if (elType === 'rent') {
    label = 'Rent Frequency';
    choices = [
      { label: 'Yearly', value: 'yearly' },
      { label: 'Monthly', value: 'monthly' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Daily', value: 'daily' },
    ];
  } else if (elType === 'sale') {
    label = 'Transaction type';
    choices = [
      { label: 'Ready', value: 'ready' },
      { label: 'Offplan', value: 'off-plan' },
    ];
  }

  return { choices, label };
};

export const postHandoverChoices = () => {
  return {
    label: 'Post Handover',
    choices: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  };
};

export const furnitureRenovationChoices = () => {
  return {
    furniture: {
      label: 'Furniture',
      data: [
        { label: 'Any', value: 'any' },
        { label: 'Furnished', value: 'furnished' },
        { label: 'Partly Furnished', value: 'partly furnished' },
        { label: 'Unfurnished', value: 'unfurnished' },
      ],
    },
    renovation: {
      label: 'Renovation',
      data: [
        { label: 'Any', value: 'any' },
        { label: 'Renovated', value: 'Renovation' },
        { label: 'Non renovated', value: 'Non renovation' },
      ],
    },
  };
};

export const handoverChoices = () => {
  const yearStart = new Date('01 Jan 2010 00:00:00 GMT').getFullYear();
  const years = Array.from({ length: 24 }, (_, i) => (yearStart + i).toString());

  const yearObjects = years.map((year) => ({ value: year, label: year }));
  return {
    quarters: {
      label: 'Quarters',
      data: [
        { label: 'Q1', value: 'Q1' },
        { label: 'Q2', value: 'Q2' },
        { label: 'Q3', value: 'Q3' },
        { label: 'Q4', value: 'Q4' },
      ],
    },
    years: {
      label: 'Years',
      data: [...yearObjects],
    },
  };
};

export const floorNumberOfFloorChoices = () => {
  return {
    floor: {
      label: 'Floor',
      data: [
        { label: 'Any', value: 'any' },
        { label: 'High', value: 'high' },
        { label: 'Middle', value: 'middle' },
        { label: 'Low', value: 'low' },
      ],
    },
    numberOfFloor: {
      label: 'Number of Floor',
      data: [
        { label: 'Floor from', value: '0', regex: /^([0-9]|[1-9][0-9]{1,2}|1000)$/ },
        { label: 'Floor to', value: '0', regex: /^([0-9]|[1-9][0-9]{1,2}|1000)$/ },
      ],
    },
  };
};

export const findListingsChoices = () => {
  return {
    label: 'Find by seller',
    topButtonText: 'Show my agency listings',
    inputPlaceholder: 'Search Agency / Agent',
  };
};
