import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LocationModel, MinimizedLocation } from '@/types/location';
import { useDebounce } from '@/hooks/useDebounce';
import { locationProvider } from '@/providers/locationProvider';

interface UseLocationAutocompleteProps {
  defaultValue: string;
  searchByDistrict?: boolean;
  searchWithProperty?: boolean;
}

export const useLocationAutocomplete = ({
  defaultValue,
  searchByDistrict,
  searchWithProperty,
}: UseLocationAutocompleteProps) => {
  const formContext = useFormContext();
  const propertyKind = formContext ? formContext.watch('propertyKind') : null;
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationModel | null>(null);
  const [minimizedLocations, setMinimizedLocations] = useState<MinimizedLocation[]>([]);
  const [inputAddressValue, setInputAddressValue] = useState<string>(defaultValue);
  const searchValue = useDebounce(inputAddressValue);

  useEffect(() => {
    if (searchValue) {
      if (searchByDistrict) {
        locationProvider.getAds({ name: searchValue, searchByDistrict }).then((res) => {
          setLocations(res?.data?.items ?? []);
          setSelectedLocation(res?.data?.items?.[0] ?? null);
        });
        return;
      }
      if (searchWithProperty) {
        locationProvider.getAds({ name: searchValue, propertyKind }).then((res) => {
          setLocations(res?.data?.items ?? []);
        });
      } else {
        locationProvider.getAds({ name: searchValue }).then((res) => {
          setLocations(res?.data?.items ?? []);
        });
      }
    }
  }, [searchValue]);

  return {
    locations,
    setLocations,
    inputAddressValue,
    setInputAddressValue,
    selectedLocation,
    setSelectedLocation,
    minimizedLocations,
    setMinimizedLocations,
  };
};
