import { FileKindEnum, IPdfDocument, ActivityKindEnum, RentPeriodEnum } from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { GallerySection } from './GallerySection';

import {
  BedIcon,
  BuildingsIcon,
  CurrencyCircleDollarIcon,
  FloorIcon,
  FurnishingIcon,
  HouseLineIcon,
  LocationWhiteIcon,
  NoteIcon,
  PaintRollerIcon,
  RulerIcon,
  ShowerIcon,
} from '@/assets/pdf';

import { ContactSection } from './ContactSection';
import { Picture } from './Picture';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const baseStyles = StyleSheet.create({
  poppinsBold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  informationInsideImage: {
    display: 'flex',
    borderRadius: 30,
    position: 'absolute',
    padding: 23,
  },
  blockTitleImageText: {
    backgroundColor: '#f9fcffcc',
    color: '#1650FF',
    padding: '32px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
  },
  featureBlock: {
    width: '48vw',
    color: '#1650FF',
  },
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    padding: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  bold: {
    fontSize: 80,
    ...baseStyles.poppinsBold,
  },
  price: {
    fontSize: 96,
    lineHeight: 1.4,
    color: '#1650FF',
    ...baseStyles.poppinsBold,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#1650FF',
  },
  blockTitleImageText: {
    fontSize: 106,
    maxWidth: '1800px',
    ...baseStyles.poppinsBold,
    ...baseStyles.blockTitleImageText,
  },
  blocksInsideImageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceWrapper: {
    ...baseStyles.poppinsBold,
    ...baseStyles.blockTitleImageText,
  },
  image: {
    height: '48vh',
    borderRadius: 10,
    objectFit: 'cover',
    position: 'relative',
  },
  mainImageWrapper: {
    flexDirection: 'column',
    gap: '8px',
    top: '34px',
    margin: 'auto',
    left: 0,
    textAlign: 'center',
    right: 0,
    ...baseStyles.informationInsideImage,
  },
  locationBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#1650FF',
    padding: '8px 19px 9px 20px',
    gap: '8px',
  },
  secondFeatureBlock: {
    display: 'flex',
    paddingLeft: '200px',
    ...baseStyles.featureBlock,
  },
  firstFeatureBlock: {
    ...baseStyles.featureBlock,
  },
});

export function PdfDocument({
  showContent,
  adsData,
  personalInfo,
  currentAgencyInfo,
  isNumberOfFloorsShown,
}: IPdfDocument) {
  const {
    title,
    nameEn,
    paymentPlan,
    defaultRentFrequency,
    activityKind,
    convertedPrice,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    convertedCurrency,
    floorsNum,
    floor,
    propertyAdMedia,
    propertyKind,
    completionStatus,
    convertedArea,
    plotArea,
    convertedAreaUnits,
    furniture,
    baths,
    beds,
    renovation,
    description,
    freeHold,
  } = adsData;

  const plans = propertyAdMedia.filter((item) => item.kind === FileKindEnum.Plan);
  const isSale = activityKind !== ActivityKindEnum.Rent;
  const textBeds = beds === 'Studio' ? '' : Number(beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  let newPrice = 0;

  if (isSale) {
    newPrice = convertedPrice || 0;
  } else {
    switch (defaultRentFrequency) {
      case RentPeriodEnum.Yearly:
        newPrice = convertedPriceYearly || 0;
        break;
      case RentPeriodEnum.Monthly:
        newPrice = convertedPriceMonthly || 0;
        break;
      case RentPeriodEnum.Weekly:
        newPrice = convertedPriceWeekly || 0;
        break;
      case RentPeriodEnum.Daily:
        newPrice = convertedPriceDaily || 0;
        break;
    }
  }

  const getFloorText = () => {
    if (!floorsNum && !floor) return null;
    if (floorsNum && isNumberOfFloorsShown) return `${floorsNum} floors`;
    if (floor) return `${capitalizeFirstLetter(floor)} floor`;
    return null;
  };

  const features = [
    {
      iconSrc: RulerIcon,
      text: `${plotArea ? `${plotArea} / ` : ''}${convertedArea ?? ''} ${convertedAreaUnits?.toLowerCase() ?? ''}`,
    },
    {
      iconSrc: BedIcon,
      text: beds ? `${beds} ${textBeds}` : null,
    },
    {
      iconSrc: FurnishingIcon,
      text: furniture ? `${capitalizeFirstLetter(furniture)}` : null,
    },
    {
      iconSrc: ShowerIcon,
      text: baths ? `${baths} ${textBaths}` : null,
    },
    {
      iconSrc: FloorIcon,
      text: getFloorText(),
    },
    {
      iconSrc: PaintRollerIcon,
      text: renovation ? `${capitalizeFirstLetter(renovation)}` : null,
    },
  ];

  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
            {showContent.images ? (
              <>
                <View>
                  <Picture src={showContent.images[0].fileUrl} style={styles.image} />
                  <View style={styles.mainImageWrapper}>
                    <View style={styles.blocksInsideImageWrapper}>
                      <Text style={styles.blockTitleImageText}>{title}</Text>
                    </View>
                    <View>
                      <View style={styles.blocksInsideImageWrapper}>
                        {showContent.showPrice ? (
                          <View style={styles.priceWrapper}>
                            <View style={styles.inline}>
                              <Text style={styles.bold}>Price</Text>
                              <Text style={styles.price}>{numberFormat.format(newPrice)}</Text>
                              <Text style={styles.bold}>{convertedCurrency}</Text>
                            </View>
                          </View>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View style={styles.locationBlock}>
                    <Image src={LocationWhiteIcon} style={{ width: '98px', height: '92px' }} />
                    <Text style={{ fontSize: '82px', color: 'white' }}>{nameEn}</Text>
                  </View>
                </View>
                <View>
                  <Section>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 56,
                      }}
                    >
                      <View style={{ flexDirection: 'row', gap: 28 }}>
                        <Badge iconSrc={CurrencyCircleDollarIcon}>
                          {defaultRentFrequency
                            ? `${capitalizeFirstLetter(activityKind)}/${defaultRentFrequency}`
                            : capitalizeFirstLetter(activityKind)}
                        </Badge>
                        <Badge iconSrc={BuildingsIcon}>{capitalizeFirstLetter(propertyKind)}</Badge>
                      </View>
                      <View style={{ flexDirection: 'row', gap: 28 }}>
                        <Badge
                          iconSrc={HouseLineIcon}
                          style={{ width: freeHold ? '50vw' : '100%' }}
                        >
                          {capitalizeFirstLetter(completionStatus)}
                        </Badge>
                        <Badge iconSrc={NoteIcon} style={{ width: '50vw' }}>
                          {freeHold || ''}
                        </Badge>
                      </View>
                    </View>
                    <View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        {features
                          .filter((el) => el.text)
                          .map((feature, index) => (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginVertical: '20px',
                              }}
                              key={index}
                            >
                              {feature.text ? (
                                index % 2 !== 0 ? (
                                  <>
                                    <Separator />
                                    <IconText
                                      style={styles.secondFeatureBlock}
                                      iconSrc={feature.iconSrc}
                                    >
                                      {feature.text}
                                    </IconText>
                                  </>
                                ) : (
                                  <IconText
                                    style={styles.firstFeatureBlock}
                                    iconSrc={feature.iconSrc}
                                  >
                                    {feature.text}
                                  </IconText>
                                )
                              ) : null}
                            </View>
                          ))}
                      </View>
                    </View>{' '}
                  </Section>
                </View>
              </>
            ) : null}
          </View>

          <Section wrap={true}>
            <Text style={{ fontSize: '84' }}>{description}</Text>
          </Section>

          <FeaturesSection data={adsData} />

          {plans ? <GallerySection title='Floor plan' images={plans} /> : null}

          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>

          <GallerySection wrap={true} title='Gallery' images={showContent.images} />
        </View>
      </Page>
    </Document>
  );
}

const Separator = () => (
  <View
    style={{
      height: '150px',
      width: 4,
      backgroundColor: '#1650FF',
      marginHorizontal: 0,
    }}
  />
);

const numberFormat = new Intl.NumberFormat('en', {
  maximumSignificantDigits: 3,
});

const capitalizeFirstLetter = (string?: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
