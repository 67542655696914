import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  State,
  UserKindEnum,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
  NotificationTypeEnum,
} from '@/types';
import { OpListItem } from './ListItem';
import { useUnreadChatCount } from '@/components/Sidebar/useUnreadChatCount.tsx';
import { useUnreadRequestCount } from '@/components/Sidebar/useUnreadRequestCount';

import {
  MessengerIcon,
  MyPropertiesIcon,
  MyTeamIcon,
  SecondaryIcon,
  Notifications,
  BuildingsIcon,
  XmlUploadIcon,
  FavoritesIcon,
  ClientRequestIcon,
  UserListIcon,
  LocationListIcon,
  RentIcon,
  PaymentIcon,
  FriendsIcon,
  ReferralsIcon,
} from './icons';
import InsightsIcon from '@mui/icons-material/Insights';
import { ComponentProps } from 'react';
import { List } from '@mui/material';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { Loader } from '@/components';

export const SidebarMenu = ({
  variant = 'primary',
  ...props
}: ComponentProps<typeof List> & {
  variant?: MenuVariant;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openDialog, setOpenDialog } = useDialogs();
  const { user } = useSelector((state: State) => state.auth);
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const isForeign = user.kind === UserKindEnum.Foreign;
  const isManager = user.kind === UserKindEnum.Manager;
  const isAdmin = user.kind === UserKindEnum.Admin;
  const isBroker = user.kind === UserKindEnum.Broker;
  const isAgency = user.kind === UserKindEnum.Agency;
  const isDeveloper = user.kind === UserKindEnum.Developer;

  const isForeignPermission = isForeign && user.verified;
  const isManagerPermission = isManager && user.employer !== null;
  const isBrokerPermission = isBroker && user.employer !== null;

  const isXmlUploadVisible = isBrokerPermission || isManagerPermission || isAgency || isDeveloper;

  const isOffPlansVisible = isXmlUploadVisible || isForeignPermission;
  const isAdsVisible = isOffPlansVisible || isAdmin;
  const isNotificationsVisible = isOffPlansVisible;
  const isClientRequestsVisible = isOffPlansVisible;
  const isFavoritesVisible = isOffPlansVisible;
  const isMyAdsVisible = isXmlUploadVisible;

  const { chatState } = useUnreadChatCount();
  const { appState } = useUnreadRequestCount(isXmlUploadVisible);

  const isReferralVisible = user.id === '395';

  const cometchatActivity = async (id: string) => {
    setIsLoading(true);
    const {
      ok,
      data: responseData,
      status,
      message,
    } = await usersProvider.cometchatActivity(id);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <List
      sx={{
        fontSize: '16px',
        padding: '0',
        flexShrink: '0',
        color: '#2A3842',
        width: variant === 'primary' ? '250px' : 'auto',
        position: 'sticky',
        top: 96,
        height: 'auto',
        maxHeight: '100vh',
        overflowY: 'auto',
        zIndex: 1000,
      }}
      {...props}
    >
      {isAdsVisible && (
        <OpListItem
          href='/'
          variant={variant}
          icon={<SecondaryIcon />}
          content={<span>Secondary</span>}
        />
      )}
      {isAdsVisible && (
        <OpListItem
          href='/ads/rent'
          variant={variant}
          icon={<RentIcon />}
          content={<span>Rent</span>}
        />
      )}
      {isClientRequestsVisible && (
        <OpListItem
          href='/client-requests'
          variant={variant}
          icon={<ClientRequestIcon />}
          content={<span>Client requests</span>}
        />
      )}
      {isMyAdsVisible && (
        <OpListItem
          href='/ads/my'
          variant={variant}
          icon={<MyPropertiesIcon />}
          content={<span>My properties</span>}
        />
      )}
      {/* {isOffPlansVisible && (
        <OpListItem
          href='/offplans'
          variant={variant}
          icon={<BuildingsIcon />}
          content={
            <span style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              Off Plan
            </span>
          }
        />
      )} */}
      {isAgency && (
        <OpListItem
          href='/myteam'
          variant={variant}
          icon={<MyTeamIcon />}
          content={<span>My team</span>}
        />
      )}
      <OpListItem
        href='/chat'
        onClick={() => cometchatActivity(user.id!)}
        variant={variant}
        icon={<MessengerIcon />}
        content={
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            Messaging {!!chatState.countOfUnread && <span>{chatState.countOfUnread}</span>}
          </span>
        }
      />
      {isNotificationsVisible && (
        <OpListItem
          href={isTariffFree ? undefined : '/notifications'}
          onClick={() => isTariffFree && handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
          variant={variant}
          icon={<Notifications />}
          content={
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              Notifications {!!appState.countOfUnread && <span>{appState.countOfUnread}</span>}
            </span>
          }
        />
      )}
      {isAdmin && (
        <OpListItem
          href='/xml-upload'
          variant={variant}
          icon={<XmlUploadIcon />}
          content={<span>XML Upload</span>}
        />
      )}
      {isReferralVisible && (
        <OpListItem
          href='/referrals'
          variant={variant}
          icon={<ReferralsIcon />}
          content={<span>Referrals</span>}
        />
      )}
      {isFavoritesVisible && (
        <OpListItem
          href='/friends'
          variant={variant}
          icon={<FriendsIcon />}
          content={<span>Friends</span>}
        />
      )}
      {isFavoritesVisible && (
        <OpListItem
          href='/favorites'
          variant={variant}
          icon={<FavoritesIcon />}
          content={<span>Favorites</span>}
        />
      )}
      {isAdmin && (
        <>
          <OpListItem
            href='/admin/users'
            variant={variant}
            icon={<UserListIcon />}
            content={<span>User List</span>}
          />
          <OpListItem
            href='/admin/agency/licenses'
            variant={variant}
            icon={<MyPropertiesIcon />}
            content={<span>Agency License</span>}
          />
          <OpListItem
            href='/admin/broker/licenses'
            variant={variant}
            icon={<MyPropertiesIcon />}
            content={<span>Broker License</span>}
          />
          <OpListItem
            href='/admin/developer/licenses'
            variant={variant}
            icon={<MyPropertiesIcon />}
            content={<span>Developer License</span>}
          />
          <OpListItem
            href='/admin/localizations'
            variant={variant}
            icon={<MyPropertiesIcon />}
            content={<span>Localizations</span>}
          />
          <OpListItem
            href='/admin/comments-report'
            variant={variant}
            icon={<MyPropertiesIcon />}
            content={<span>Comment reports</span>}
          />
          <OpListItem
            href='/admin/offplans'
            variant={variant}
            icon={<BuildingsIcon />}
            content={<span>Off Plan Creating</span>}
          />
          <OpListItem
            href='/admin/metrics'
            variant={variant}
            icon={<InsightsIcon sx={{ color: '#1650FF' }} />}
            content={<span>Metrics</span>}
          />
          <OpListItem
            href='/admin/locationList'
            variant={variant}
            icon={<LocationListIcon />}
            content={<span>Location list</span>}
          />
          <OpListItem
            href='/admin/payment-information'
            variant={variant}
            icon={<PaymentIcon />}
            content={<span>Payment information</span>}
          />
        </>
      )}
    </List>
  );
};

export type MenuVariant = 'primary' | 'icon' | 'mobile';
