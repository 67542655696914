import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '56px',
  color: '#2A3842',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const StyledTitle = styled('div')({
  fontSize: '20px',
  fontWeight: '700',

  sx: {
    fontSize: '32px',
  },
});

export const StyledDescription = styled('div')({
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: '500',
  padding: '5px 0 15px',
});

export const StyledButton = styled(Button)({
  background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  width: '100%',
  height: '40px',
  borderRadius: '14px',
  fontFamily: '"Poppins", sans-serif',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: '400',

  sx: {
    width: '252px',
  },

  img: {
    marginRight: '10px',
  },

  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
  },

  '&:focus': {
    backgroundColor: '#014EB4',
  },
});
