import { newBuildingsProvider } from '@/providers';
import { IUnitViewBulding, IUnitViewPlans } from '@/types/requests';
import { UNITS_MODAL_TYPE } from './UnitsView.init';

export const getBuildingList = async (id: string): Promise<IUnitViewBulding[] | undefined> => {
  const buildingsData = await newBuildingsProvider.getBuildings(id);
  return buildingsData?.data?.items?.map((el: any) => ({
    id: el.id,
    newConstructionId: el.newConstructionId,
    name: el.name,
  }));
};

export const getPlansList = async (id: string): Promise<IUnitViewPlans[] | undefined> => {
  const plansList = await newBuildingsProvider.getPlans(id);
  return plansList?.data?.items?.map((el: any) => ({
    id: el.id,
    planCode: el.planCode,
    buildingId: el.buildingId,
  }));
};

export const checkUnitOperationType = (modalType: string) => {
  if (
    [UNITS_MODAL_TYPE.deleteOne, UNITS_MODAL_TYPE.editOne, UNITS_MODAL_TYPE.saveAsPdfOne].includes(
      modalType
    )
  ) {
    return true;
  }

  if (
    [
      UNITS_MODAL_TYPE.deleteAll,
      UNITS_MODAL_TYPE.saveAsPdfAll,
      UNITS_MODAL_TYPE.selectAll,
      UNITS_MODAL_TYPE.unSelectAll,
    ].includes(modalType)
  ) {
    return false;
  }

  return;
};
