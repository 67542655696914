import React, { useContext } from 'react';
import dateFormat from 'dateformat';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatAvatar } from '../../Shared';

import { CometChatContext } from '../../../util/CometChatContext';

import { theme } from '../../../resources/theme';
import Translator from '../../../resources/localization/translator';

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageTxtWrapperStyle,
  messageTxtStyle,
  messageInfoWrapperStyle,
  messageTimeStampStyle,
  messageThumbnailStyle,
  messageDetailStyle,
} from './style';

const CometChatDeleteMessageBubble = (props) => {
  const context = useContext(CometChatContext);

  let message = null;
  const messageDate = props.message.sentAt * 1000;
  let avatar = null;
  if (props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
    avatar = (
      <div css={messageThumbnailStyle()} className='message__thumbnail'>
        <CometChatAvatar user={props.message.sender} />
      </div>
    );
  }

  message = (
    <React.Fragment>
      {avatar}
      <div css={messageDetailStyle(props)} className='message__details'>
        <div css={messageTxtWrapperStyle(context)} className='message__txt__wrapper'>
          <p css={messageTxtStyle(context)} className='message__txt'>
            {Translator.translate('THIS_MESSAGE_DELETED', context.language)}
          </p>
        </div>
        <div css={messageInfoWrapperStyle()} className='message__info__wrapper'>
          <span css={messageTimeStampStyle(context)} className='message__timestamp'>
            {dateFormat(messageDate, 'shortTime')}
          </span>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div css={messageContainerStyle()} className='message__deleted'>
      <div css={messageWrapperStyle()} className='message__wrapper'>
        {message}
      </div>
    </div>
  );
};

// Specifies the default values for props:
CometChatDeleteMessageBubble.defaultProps = {
  theme: theme,
};

CometChatDeleteMessageBubble.propTypes = {
  theme: PropTypes.object,
  message: PropTypes.object.isRequired,
};

export { CometChatDeleteMessageBubble };
