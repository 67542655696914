import { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, arraySwap, SortableContext } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import ReactPlayer from 'react-player';

import { AdMediaType, IAdImage } from '@/types';
import { FileUploader, SectionTitle } from '@/components';

import { MediaItem, MediaApplication } from './MediaItem';
import getRandomHex from '@/utils/getRandomHex';
import styles from './UploadMedia.module.scss';

import { ReactComponent as DeleteIcon } from '../UploadMedia/MediaItem/trash.svg';
import { IUploadPhotosProps } from '../OffPlanAdmin.types';

export const UploadMedia = ({
  disableUploader = false,
  entityType = 'newConstruction',
  forceCheck,
  formElement = 'media',
  maxMediaCount,
  mediaGallery,
  minMediaCount,
  offPlanId,
  onUploaded,
  required = false,
  sectionNumber,
  sectionTitle,
  uploadType = 'image',
  uploaderProps,
  id,
  validateItems, // for future implementation
}: IUploadPhotosProps) => {
  const [mediaItems, setMediaItems] = useState<IAdImage[]>([]);
  const [countMediaItems, setCountMediaItems] = useState(mediaGallery.length || 0);
  const [actionClick, setActionClick] = useState(false);
  const [touched, setTouched] = useState(false);
  const [customError, setCustomError] = useState<string | undefined>();

  const { getValues, setValue } = useFormContext();
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => setMediaItems(mediaGallery), [mediaGallery]);

  useEffect(() => {
    setCountMediaItems(mediaItems.length);

    const otherTypes =
      getValues(formElement)?.filter((item: AdMediaType) => item.mediaType !== uploadType) || [];

    const mItems = mediaItems.map((image, index) => ({
      id: image.id || `fakeId-${getRandomHex(6)}`,
      entityType,
      externalId: offPlanId,
      fileId: image.fileId,
      fileUrl: image.url,
      mediaType: uploadType,
      order: index + 1,
    }));
    setValue(formElement, [...otherTypes, ...mItems]);
    //validateItems(mediaItems);
  }, [mediaItems]);

  useEffect(() => {
    let errorMessage: string | undefined = undefined;

    if (forceCheck || touched) {
      if (minMediaCount && countMediaItems < minMediaCount) {
        errorMessage = `A minimum of ${minMediaCount} ${uploadType}s is required for a comprehensive listing`;
      } else if (maxMediaCount && countMediaItems > maxMediaCount) {
        errorMessage = `A maximum of ${maxMediaCount} ${uploadType}s is allowed`;
      } 
      setCustomError(errorMessage);
    }

    updateMediaItemsAndNotify(mediaItems, errorMessage);
  }, [
    countMediaItems,
    minMediaCount,
    maxMediaCount,
    uploadType,
    touched,
    forceCheck,
    customError,
  ]);

  const updateMediaItemsAndNotify = (
    updatedMediaItems: IAdImage[] | undefined,
    forceErrorMessage?: string
  ) => {
    onUploaded?.({
      mediaItems: updatedMediaItems,
      touched,
      forceCheck,
      uploadType,
      count: updatedMediaItems?.length || 0,
      customError: customError || forceErrorMessage,
    });
  };

  const handleItemUploaded = (id: string, url: string, fileOriginalName?: string) =>
    setMediaItems((els) => [
      ...els,
      { fileId: id, url, id: `fakeId-${getRandomHex(6)}`, fileOriginalName },
    ]);

  const handleRemoveItem = (index: number) => {
    setCountMediaItems(countMediaItems - 1);
    setMediaItems(mediaItems.filter((_, position) => index !== position + 1));
  };

  const handleSetCoverItem = (index: number) => {
    setActionClick(true);
    setMediaItems(arraySwap(mediaItems, 0, index - 1));
  };

  const handleReorderItem = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = mediaItems.findIndex(({ id }) => id === active.id);
      const overIndex = mediaItems.findIndex(({ id }) => id === over.id);
      if (!actionClick) {
        setMediaItems(arrayMove(mediaItems, activeIndex, overIndex));
      }
      setActionClick(false);
    }
  };

  const renderFileUploader = () => {
    return (
      <FileUploader
        type={uploaderProps.type || uploadType}
        labelButton={uploaderProps.labelButton}
        labelFormat={uploaderProps.labelFormat}
        labelSize={uploaderProps.labelSize}
        accept={uploaderProps.accept}
        limit={(maxMediaCount || 0) - countMediaItems}
        onUploaded={handleItemUploaded}
        disabled={disableUploader}
        hasAnError={!!customError}
        fileTypes={uploaderProps.fileTypes ?? undefined}
        fileTypeInfo={`${entityType}-${uploadType}`}
      />
    );
  };

  return (
    <div
      className={`${styles[`uploadmedia-${uploadType}`]} ${customError ? 'media-error' : ''}`}
      id={id}
    >
      {uploadType === 'video' && (
        <div>
          <SectionTitle number={10} title={sectionTitle} errorTitle={customError} />
          <div className={styles.videosContainer}>
            {mediaItems.map(({ url }, index) => (
              <div key={url + index} className={styles.container}>
                <ReactPlayer url={url} controls width='315px' height='190px' />
                <div className={styles.buttons}>
                  <div className={styles.delete} onMouseDown={() => handleRemoveItem(index + 1)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {renderFileUploader()}
        </div>
      )}
      {uploadType === 'image' && (
        <div>
          <SectionTitle
            number={sectionNumber}
            title={sectionTitle}
            errorTitle={customError}
            required={required}
          />
          <div className={styles.container}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleReorderItem}
            >
              <SortableContext items={mediaItems}>
                {mediaItems.map((image, index) => (
                  <MediaItem
                    key={index}
                    {...image}
                    position={index + 1}
                    remove={handleRemoveItem}
                    setCover={handleSetCoverItem}
                    covering={!actionClick}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          {renderFileUploader()}
        </div>
      )}
      {uploadType === 'brochure' && (
        <div>
          <SectionTitle
            number={sectionNumber}
            title={sectionTitle}
            errorTitle={customError}
            required={required}
          />
          <div className={styles.container}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleReorderItem}
            >
              <SortableContext items={mediaItems}>
                {mediaItems.map((image, index) => (
                  <MediaApplication
                    fileOriginalName={image.fileOriginalName}
                    key={index}
                    {...image}
                    position={index + 1}
                    remove={handleRemoveItem}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          {renderFileUploader()}
        </div>
      )}
    </div>
  );
};
