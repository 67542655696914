import {
  OffPlanPropertyKind,
} from '../../../types/enums';
import getRandomHex from '@/utils/getRandomHex';
import dayjs, { Dayjs } from 'dayjs';

import type { IButtonConfig, IFileUploaderProps } from './OffPlanAdmin.types';
import { IOffPlanModel } from '@/types';

const fakeOffPlanId = getRandomHex(6);

const isNotANumber = (value: any) => {
  return isNaN(value);
};

export const getInintOffPlanData = (data?: IOffPlanModel) => {
  return {
    stateData: {
      addressEn: data?.addressEn || undefined,
      balcony: data?.balcony || undefined,
      barbecueArea: data?.barbecueArea || undefined,
      buildInWardrobes: data?.buildInWardrobes || undefined,
      buildUpAreaFrom: data?.buildUpAreaFrom || undefined,
      buildUpAreaTo: data?.buildUpAreaTo || undefined,
      centralAC: data?.centralAC || undefined,
      childrenPlayArea: data?.childrenPlayArea || undefined,
      cityView: data?.cityView || undefined,
      concierge: data?.concierge || undefined,
      constructionType: data?.constructionType || OffPlanPropertyKind.Apartments,
      coordinatesLat: data?.coordinatesLat || undefined,
      coordinatesLong: data?.coordinatesLong || undefined,
      coveredParking: data?.coveredParking || undefined,
      currency: data?.currency || undefined,
      description: data?.description || undefined,
      developerId: data?.developerId || undefined,
      developerName: data?.developerName || undefined,
      developmentStatus: data?.developmentStatus || undefined,
      forecast: data?.forecast || {
        currentProgress: data?.currentProgress || undefined,
        plannedCompletion: data?.plannedCompletion || dayjs().toISOString(),
        estimatedCompletion: data?.estimatedCompletion || dayjs().add(1, 'year').toISOString(),
        lastUpdate: dayjs().toISOString(),
      },
      furnishing: data?.furnishing || undefined,
      gallery: data?.gallery || undefined,
      handover: data?.handover || undefined,
      id: data?.id || `fakeOffPlanId-${fakeOffPlanId}`,
      kitchenAppliances: data?.kitchenAppliances || undefined,
      landmarkView: data?.landmarkView || undefined,
      laundryFacility: data?.laundryFacility || undefined,
      lobbyInBuilding: data?.lobbyInBuilding || undefined,
      maidsRoom: data?.maidsRoom || undefined,
      media: data?.media || [],
      nameEn: data?.nameEn || undefined,
      numberOfFloors: data?.numberOfFloors || undefined,
      offPlanStatus: data?.offPlanStatus || false,
      paymentPlan: data?.paymentPlan || undefined,
      placeId: data?.placeId || undefined,
      plotArea: data?.plotArea || undefined,
      priceFrom: data?.priceFrom || undefined,
      priceTo: data?.priceTo || undefined,
      privateGarden: data?.privateGarden || undefined,
      privateGym: data?.privateGym || undefined,
      privatePool: data?.privatePool || undefined,
      privateSpa: data?.privateSpa || undefined,
      publicParking: data?.publicParking || undefined,
      pushInInstallments: (data?.paymentPlan?.items || []).length > 0 || false,
      quarters: data?.quarters || undefined,
      security: data?.security || undefined,
      sellingAgencyFeePercent: data?.sellingAgencyFeePercent || undefined,
      sharedGym: data?.sharedGym || undefined,
      sharedPool: data?.sharedPool || undefined,
      sharedSpa: data?.sharedSpa || undefined,
      status: data?.status || undefined,
      study: data?.study || undefined,
      tennisCourts: data?.tennisCourts || undefined,
      title: data?.title || undefined,
      video: data?.video || undefined,
      waterView: data?.waterView || undefined,
      years: data?.years || undefined,
      serviceCharge: data?.serviceCharge || undefined,
      projectNumber: data?.projectNumber || undefined,
      resalle: data?.resalle || undefined,
      reviewLinkGoogle: data?.reviewLinkGoogle || undefined,
      convertedServiceCharge: data?.convertedServiceCharge || undefined,
    },
    formData: {
      addressEn: data?.addressEn || undefined,
      balcony: data?.balcony || undefined,
      barbecueArea: data?.barbecueArea || undefined,
      buildInWardrobes: data?.buildInWardrobes || undefined,
      buildUpAreaFrom: data?.buildUpAreaFrom || undefined,
      buildUpAreaTo: data?.buildUpAreaTo || undefined,
      centralAC: data?.centralAC || undefined,
      childrenPlayArea: data?.childrenPlayArea || undefined,
      cityView: data?.cityView || undefined,
      concierge: data?.concierge || undefined,
      constructionType: data?.constructionType || OffPlanPropertyKind.Apartments,
      coordinatesLat: data?.coordinatesLat || undefined,
      coordinatesLong: data?.coordinatesLong || undefined,
      coveredParking: data?.coveredParking || undefined,
      currency: data?.currency || undefined,
      description: data?.description || undefined,
      developerId: data?.developerId || undefined,
      developerName: data?.developerName || undefined,
      developmentStatus: data?.developmentStatus || undefined,
      forecast: data?.forecast || {
        currentProgress: data?.currentProgress || undefined,
        plannedCompletion: data?.plannedCompletion || dayjs().toISOString(),
        estimatedCompletion: data?.estimatedCompletion || dayjs().add(1, 'year').toISOString(),
        lastUpdate: dayjs().toISOString(),
      },
      furnishing: data?.furnishing || undefined,
      gallery: data?.gallery || undefined,
      handover: data?.handover || undefined,
      id: data?.id || `fakeOffPlanId-${fakeOffPlanId}`,
      kitchenAppliances: data?.kitchenAppliances || undefined,
      landmarkView: data?.landmarkView || undefined,
      laundryFacility: data?.laundryFacility || undefined,
      lobbyInBuilding: data?.lobbyInBuilding || undefined,
      maidsRoom: data?.maidsRoom || undefined,
      media: data?.media || [],
      nameEn: data?.nameEn || undefined,
      numberOfFloors: data?.numberOfFloors || undefined,
      offPlanStatus: data?.offPlanStatus || false,
      parkView: data?.parkView || undefined,
      paymentPlan: data?.paymentPlan || undefined,
      placeId: data?.placeId || undefined,
      plotArea: data?.plotArea || undefined,
      priceFrom: data?.priceFrom || undefined,
      priceTo: data?.priceTo || undefined,
      privateGarden: data?.privateGarden || undefined,
      privateGym: data?.privateGym || undefined,
      privatePool: data?.privatePool || undefined,
      privateSpa: data?.privateSpa || undefined,
      publicParking: data?.publicParking || undefined,
      pushInInstallments: (data?.paymentPlan?.items || []).length > 0 || false,
      quarters: data?.quarters || undefined,
      security: data?.security || undefined,
      sellingAgencyFeePercent: data?.sellingAgencyFeePercent || undefined,
      sharedGym: data?.sharedGym || undefined,
      sharedPool: data?.sharedPool || undefined,
      sharedSpa: data?.sharedSpa || undefined,
      status: data?.status || undefined,
      study: data?.study || undefined,
      tennisCourts: data?.tennisCourts || undefined,
      title: data?.title || undefined,
      video: data?.video || undefined,
      waterView: data?.waterView || undefined,
      years: data?.years || undefined,
      serviceCharge: data?.serviceCharge || undefined,
      projectNumber: data?.projectNumber || undefined,
      resalle: data?.resalle || undefined,
      reviewLinkGoogle: data?.reviewLinkGoogle || undefined,
      convertedServiceCharge: data?.convertedServiceCharge || undefined,
    },
  };
};

export const buttonsConfig: Record<string, IButtonConfig[]> = {
  submitButtons: [
    {
      key: 'publish',
      buttonName: 'Publish',
      customWidth: '155px',
      variant: 'contained',
      buttonType: 'button',
      styleType: 'normal',
    },
    {
      key: 'saveDraft',
      buttonName: 'Save as a draft',
      customWidth: '242px',
      variant: 'outlined',
      buttonType: 'button',
      styleType: 'cancel',
    },
    {
      key: 'cancel',
      buttonName: 'Cancel',
      customWidth: '157px',
      variant: 'outlined',
      buttonType: 'button',
      styleType: 'cancel',
    }
  ],
  confirmDialogButtons: [
    {
      key: 'confirm',
      buttonName: 'Confirm',
      customWidth: '226px',
      variant: 'contained',
      buttonType: 'submit',
    },
    {
      key: 'cancel',
      buttonName: 'Cancel',
      customWidth: '226px',
      variant: 'outlined',
      buttonType: 'button',
      styleType: 'cancel',
    }
  ],
  publishedDialogButtons: [
    {
      key: 'done',
      buttonName: 'Confirm',
      customWidth: '351px',
      variant: 'contained',
      buttonType: 'button',
    }
  ],
};

export const photoUploaderProps: IFileUploaderProps = {
  type: 'image',
  labelButton: 'Upload photos',
  labelFormat: 'Only JPG, JPEG or PNG format, minimum size 800 x 600px',
  labelSize: 'Maximum 15 MB one photo',
  accept: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
  },
  fileTypes: ['image/jpeg', 'image/png'],
}

export const videoUploaderProps: IFileUploaderProps = {
  type: 'video',
  labelButton: 'Upload videos',
  labelFormat: 'Only MPEG4 format',
  labelSize: 'Maximum 300 MB',
  accept: { 'video/mp4': ['.mp4'] },
  fileTypes: ['video/mp4'],
}

export const brochureUploaderProps: IFileUploaderProps = {
  type: 'brochure',
  labelButton: 'Upload brochures',
  labelFormat: 'Only PDF format',
  labelSize: 'Maximum 300 MB',
  accept: { 'application/pdf': ['.pdf'] },
  fileTypes: ['application/pdf'],
}

export const initFormData = (formData: any, data: any, setValue: any) => {
  Object.entries(formData).forEach(([key, value]) => {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      setValue(key as any, value);
    }
  });
  if (data?.handover) {
    const [q = '', y = ''] = data.handover.split('/');
    setValue('handover', data.handover);
    setValue('quarters', q);
    setValue('years', y);
  }
  if (data?.forecast) {
    setValue('forecast.currentProgress', data.forecast?.currentProgress ?? undefined);
    setValue('forecast.estimatedCompletion', data.forecast?.estimatedCompletion);
    setValue('forecast.plannedCompletion', data.forecast?.plannedCompletion);
    setValue('forecast.lastUpdate', data.forecast?.lastUpdate);
  }
  setValue('media', data.media);
}