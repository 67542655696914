import { useState, MouseEvent, useRef, RefObject, createRef } from 'react';
import { Typography, Stack, Box, IconButton, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MyButton } from '@/components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OffPlanBuildingMenu } from './OffPlanBuildingMenu';
import { priceFormat } from '@/utils';
import { NewBuildingModal } from '../NewBuildingModal';
import { NewBuildingModel, CreateNewPlanType } from '@/types';
import { PlanContainer } from '../styled';
import styles from '../Ad.module.scss';
import { buildUrlFromParams } from '@/components/UnitsView/queryParamsParse';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  color: '#ffffff',
  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .3)',
    backgroundColor: '#1579FF33',
  },
}));

export const Plans = ({
  page,
  plans,
  name,
  isAdmin,
  isAddBuilding,
  setIsAddBuilding,
}: {
  page: NewBuildingModel;
  plans: CreateNewPlanType[];
  name: string;
  isAdmin: boolean;
  isAddBuilding: boolean;
  setIsAddBuilding: (value: boolean) => void;
}) => {
  const [isShownConfirmDialog, setIsShownConfirmDialog] = useState<boolean>(false);
  const [typeOfDialog, setTypeOfDialog] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const open = Boolean(anchorEl);

  const { id: offPlanId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleClickMenu = (planId: string) => (event: MouseEvent<HTMLElement>) => {
    setActiveMenu(planId);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePlanClick = (plan: any) => () => {
    const unitViewLinkObj = {
      limit: 10,
      offset: 0,
      newConstructionId: plan.newConstructionId,
      filter: [
        {
          planCode: [plan.planCode],
          buildingId: [plan.buildingId],
        },
      ],
      sort: [
        {
          field: 'price',
          order: 'ASC',
        },
      ],
    };
    const unitViewLink = buildUrlFromParams(unitViewLinkObj);
    return navigate(`/admin/units${unitViewLink}`);
  };

  return (
    <>
      <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#2A3842' }}>{name}</Typography>
      <Box
        position='relative'
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-start' },
          flexWrap: 'wrap',
          width: '100%',
          overflow: 'hidden',
          gap: '10px',
        }}
      >
        {plans?.map((plan) => {
          return (
            <PlanContainer
              key={plan.id}
              direction='column'
              isunits={(!!plan.units && plan.units.length > 0).toString()}
            >
              <Stack
                position='relative'
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                  width: '283px',
                  backgroundColor: '#ffffff',
                  padding: '20px',
                  borderRadius: '20px 20px 0 0',
                }}
              >
                <Link href={`/offplan/${page.id}/plan/${plan.id}`} className={styles.planImg}>
                  <img
                    src={plan?.media?.[0]?.fileUrl || page?.media?.[0]?.fileUrl}
                    alt={plan.planCode}
                  />
                </Link>
                <div className={styles.itemImgInfo}>
                  <div className={styles.itemImgIcons}>
                    {isAdmin && (
                      <StyledIconButton size='small' onClick={handleClickMenu(plan.id)}>
                        <MoreVertIcon sx={{ fontSize: '16px' }} />
                      </StyledIconButton>
                    )}
                    {activeMenu === plan.id && (
                      <OffPlanBuildingMenu
                        page={page}
                        open={open}
                        typeMenu='plan'
                        handleClose={handleCloseMenu}
                        anchorEl={anchorEl}
                        setIsShownConfirmDialog={setIsShownConfirmDialog}
                        setTypeOfDialog={setTypeOfDialog}
                        planId={activeMenu}
                      />
                    )}
                  </div>
                </div>
              </Stack>
              {plan.units && (
                <Stack
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  sx={{ padding: '10px 15px' }}
                >
                  <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#1650FF' }}>
                    {plan.beds} BEDROOM | {plan.planCode}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', color: '#2A3842', opacity: 0.5 }}>
                    {plan.beds} beds | {plan.units.length} units | from {plan.units[0].areaSQFT}{' '}
                    sqft
                  </Typography>
                  <Typography sx={{ fontSize: '15px', fontWeight: 600, color: '#2A3842' }}>
                    {priceFormat(plan.units[0].price)} AED
                  </Typography>
                </Stack>
              )}
              {plan.units && (
                <Stack sx={{ padding: '0 15px' }}>
                  <MyButton
                    data={{
                      buttonName: 'Show units',
                      variant: 'contained',
                      buttonType: 'button',
                    }}
                    onClick={handlePlanClick(plan)}
                  />
                </Stack>
              )}
              {!plan.units && (
                <Stack sx={{ padding: '7px 0' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#2A3842',
                      textAlign: 'center',
                    }}
                  >
                    Contact the manager for information about the availability of units
                  </Typography>
                </Stack>
              )}
              <NewBuildingModal
                open={isShownConfirmDialog}
                onClose={() => setIsShownConfirmDialog(false)}
                id={page.id}
                buildingId={activeMenu}
                typeOfDialog={typeOfDialog}
                typeMenu='plan'
                isAddBuilding={isAddBuilding}
                setIsAddBuilding={setIsAddBuilding}
              />
            </PlanContainer>
          );
        })}
      </Box>
    </>
  );
};
