import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Stack,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Link,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { appSetNotification } from '@/store';
import { usersProvider } from '@/providers';
import {
  NewBuildingModel,
  AdCreatorType,
  State,
  UserModel,
  UserKindEnum,
  ActivityKindEnum,
  NotificationTypeEnum,
} from '@/types';
import { stringAvatar } from '@/utils';
import { icons } from '../Ad/Icons/icons';
import { MyCommunicate } from '@/components';
import { UserName, StyledAccordion, UserType } from './styled';
import { Section } from './Section';

interface IContactBlockProps {
  page: NewBuildingModel;
}

interface IContactAccordionProps {
  contact: AdCreatorType;
  expanded: boolean;
  onExpandClick: () => void;
  user: UserModel;
}

export const ContactBlock: React.FC<IContactBlockProps> = ({ page }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [developer, setDeveloper] = useState<UserModel>();
  const user = useSelector(({ auth }: State) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      if (page.developerId) {
        const { ok, data, status, message } = await usersProvider.getUserById(page.developerId);
        if (ok && data) {
          setDeveloper(data as UserModel);
        } else {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        }
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const handleExpandClick = (id: string) => setExpanded((prev) => (prev === id ? false : id));

  //const announcerContact = page ? page.propertyAdAnnouncers : null;
  const adInfo = {
    name: page.developerName,
    activityKind: ActivityKindEnum.Sale,
    title: page.title,
    currency: page.convertedCurrency,
    price: page.priceFrom,
    location: page.nameEn,
    id: page.id,
  };
  const isCheckedMy = user?.kind === UserKindEnum.Admin;

  return (
    <Stack alignItems='flex-start' gap='20px' sx={{ width: '100%' }}>
      <Section>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          style={{ position: 'relative', marginBottom: '5px' }}
        >
          <Avatar
            src={developer?.media?.[0]?.fileUrl ? developer?.media?.[0]?.fileUrl : ''}
            {...stringAvatar(developer?.name)}
          />
          <Stack>
            <Link
              href={isCheckedMy ? '/profile/view' : `/users/${page.developerId}`}
              title={page.developerName}
              style={{ textDecoration: 'none', color: '#2A3842' }}
            >
              <UserName>{page.developerName}</UserName>
              <UserType>Developer</UserType>
            </Link>
          </Stack>
        </Stack>
        {/* <Stack direction='row' spacing={23}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <icons.ActiveAds />
            <Typography>Active ads</Typography>
          </Stack>
          <Stack alignItems='center'>
            <Typography>{page?.propertyAdCreator?.propertyAdsCount}</Typography>
          </Stack>
        </Stack> */}
        {!isCheckedMy && (
          <Stack>
            <MyCommunicate user={developer} />
          </Stack>
        )}
      </Section>

      {/* {announcerContact && announcerContact.length > 0 && (
        <Section>
          <Stack direction='row' alignItems='center' spacing={1}>
            <icons.Users />
            <Typography>Another contacts</Typography>
          </Stack>
          {announcerContact.map((contact) => (
            <ContactAccordion
              key={contact.id}
              contact={contact}
              expanded={expanded === contact.id}
              onExpandClick={() => handleExpandClick(contact.id)}
              user={user}
            />
          ))}
        </Section>
      )} */}
    </Stack>
  );
};

const ContactAccordion: React.FC<IContactAccordionProps> = ({
  contact,
  expanded,
  onExpandClick,
  user,
}) => {
  const isCheckedMy = user && contact && user.id === contact.id;
  return (
    <StyledAccordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={onExpandClick} />}
        aria-controls={`panel-${contact.id}`}
        id={`panel-${contact.id}`}
        sx={{ padding: 0 }}
      >
        <Link
          href={isCheckedMy ? '/profile/view' : `/users/${contact.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar src={contact?.avatar ? contact?.avatar : ''} {...stringAvatar(contact.name)} />
            <UserName sx={{ color: '#2A3842' }}>{contact.name}</UserName>
          </Stack>
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '4px' }}>
        <Stack alignItems='flex-start' justifyContent='center' spacing={2}>
          <Stack direction='row' spacing={23}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <icons.ActiveAds />
              <Typography>Active ads</Typography>
            </Stack>
            <Typography>{contact?.propertyAdsCount}</Typography>
          </Stack>
          <Stack sx={{ width: '100%' }}>{!isCheckedMy && <MyCommunicate user={contact} />}</Stack>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
};
