export const AUTH_ACCESSTOKEN = 'auth.accessToken';
export const AUTH_REFRESHTOKEN = 'auth.refreshToken';
export const AUTH_EXPIRESFROM = 'auth.expiresFrom';
export const AUTH_EXPIRESIN = 'auth.expiresIn';

export const REFRESH_TOKEN_TIME_GAP = 60;

export const GPT_API_URL = 'https://api.openai.com/v1/chat';

export const DEFAULT_COORDINATE_LAT = 25.276987;
export const DEFAULT_COORDINATE_LONG = 55.296249;

export const SYSTEM_NOTIFICATION_CHAT_NAME = 'System Notification';
export const CUSTOM_CARE_TEAM_GROUP_NAME = 'Customer Care Team';
