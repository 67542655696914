import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { INewConstructionUnit } from "@/types";

const StyledTableCell = styled(TableCell)({
  borderColor: '#016af8',
  borderWidth: '3px',
  borderStyle: 'solid',
  fontSize: '20px',
  textAlign: 'center',
  '&.MuiTableCell-head': {
    background: '#016af8',
    color: '#fff',
  },
});

const UnitAdminUploadTable = ({ units }: { units: INewConstructionUnit[] }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: '950px' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Checking</StyledTableCell>
            <StyledTableCell>Unit No</StyledTableCell>
            <StyledTableCell>Plan Code</StyledTableCell>
            <StyledTableCell>Area (sqft)</StyledTableCell>
            <StyledTableCell>Floor</StyledTableCell>
            <StyledTableCell>Price ($)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((row) => (
            <TableRow key={row.apartmentNumber}>
              <StyledTableCell>
                <Checkbox checked={row.checked} disabled />
              </StyledTableCell>
              <StyledTableCell>{row.apartmentNumber}</StyledTableCell>
              <StyledTableCell>{row.planCode}</StyledTableCell>
              <StyledTableCell>{row.areaSQFT}</StyledTableCell>
              <StyledTableCell>{row.floor}</StyledTableCell>
              <StyledTableCell>{row.price}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UnitAdminUploadTable;
