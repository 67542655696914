import * as yup from 'yup';

import { AdFieldCurrencyEnum, AdFieldStatusEnum, OffPlanPropertyKind } from '@/types/enums';
const itemSchema = yup.object().shape({
  name: yup.string().required('Item name is required'),
  description: yup.string().required('Item description is required'),
  value: yup.string().required('Item value is required'),
});

const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/;

const urlSchema = yup
  .string()
  .required('Enter a valid website')
  .matches(urlRegex, 'Enter a valid website')
  .nullable()
  .transform((value) => (value === '' ? null : value));

export const offPlanSchemaForm = yup.object().shape({
  constructionType: yup
    .string()
    .oneOf(
      [
        OffPlanPropertyKind.Apartments,
        OffPlanPropertyKind.Townhouse,
        OffPlanPropertyKind.Villa,
        OffPlanPropertyKind.Penthouse,
        OffPlanPropertyKind.ResidentialBuilding,
        OffPlanPropertyKind.HotelApartment,
      ],
      'Choose one value from the list'
    )
    .required('Unit type is a required field'),
  buildUpAreaFrom: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .when('constructionType', {
      is: (value: OffPlanPropertyKind) =>
        [
          OffPlanPropertyKind.Penthouse,
          OffPlanPropertyKind.Apartments,
          OffPlanPropertyKind.HotelApartment,
        ].includes(value),
      then: (schema) =>
        schema.min(1, 'Area from must be more than 0').required('Area from is a required field'),
      otherwise: (schema) =>
        schema
          .min(1, 'Built-up Area from must be more than 0')
          .required('Built-up Area from is a required field'),
    }),
  buildUpAreaTo: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .when('constructionType', {
      is: (value: OffPlanPropertyKind) =>
        [
          OffPlanPropertyKind.Penthouse,
          OffPlanPropertyKind.Apartments,
          OffPlanPropertyKind.HotelApartment,
        ].includes(value),
      then: (schema) =>
        schema.min(1, '"Area to" must be more than 0').required('"Area to" is a required field'),
      otherwise: (schema) =>
        schema
          .min(1, '"Built-up Area to" must be more than 0')
          .required('"Built-up Area to" is a required field'),
    }),
  plotArea: yup
    .number()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when('constructionType', {
      is: (value: OffPlanPropertyKind) =>
        [
          OffPlanPropertyKind.Villa,
          OffPlanPropertyKind.Townhouse,
          OffPlanPropertyKind.ResidentialBuilding,
        ].includes(value),
      then: (schema) => schema.min(1, '"Plot Area to" must be more than 0').notRequired(),
      otherwise: (schema) => schema,
    }),
  numberOfFloors: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable('Provide the number of floors.')
    .when('constructionType', {
      is: (value: OffPlanPropertyKind) =>
        [
          OffPlanPropertyKind.Apartments,
          OffPlanPropertyKind.Penthouse,
          OffPlanPropertyKind.HotelApartment,
        ].includes(value),
      then: (schema) =>
        schema
          .min(1, 'Floor count must exceed 0')
          .required('"Number of Floors" is a required field'),
      otherwise: (schema) => schema,
    }),

  reviewLinkGoogle: urlSchema.notRequired(),

  developerId: yup.string().required('Developer is a required field'),

  currency: yup
    .string()
    .oneOf([AdFieldCurrencyEnum.AED, AdFieldCurrencyEnum.USD], 'Choose one value from the list')
    .required('Currency is a required field'),
  priceFrom: yup.number().required('Price from is a required field'),
  priceTo: yup.number().required('Price to is a required field'),
  addressEn: yup.string().required('Location is a required field'),
  nameEn: yup.string().nullable(),

  placeId: yup.string().nullable(),

  coordinatesLong: yup.string().required('Location is a required field.'),
  coordinatesLat: yup.string().required('Location is a required field'),
  title: yup
    .string()
    .trim()
    .required('Title is a required field, provide a meaningful title for your listing')
    .min(3, 'Title must be longer than or equal to 3 characters')
    .max(256, 'Title must be shorter than or equal to 160 characters'),
  description: yup
    .string()
    .trim()
    .max(8000, 'Description must be shorter than or equal to 8000 characters'),
  status: yup
    .string()
    .oneOf([
      AdFieldStatusEnum.Published,
      AdFieldStatusEnum.Unpublished,
      AdFieldStatusEnum.Archived,
    ]),
  forecast: yup.object().shape({
    estimatedCompletion: yup
      .mixed()
      .test(
        'is-date',
        'Planned Date is required field',
        (value) => !value || yup.date().isValid(value)
      ),
    plannedCompletion: yup
      .mixed()
      .test(
        'is-date',
        'Actual Date is required field',
        (value) => !value || yup.date().isValid(value)
      ),
  }),
  pushInInstallments: yup.boolean(),
  paymentPlan: yup
    .mixed()
    .notRequired()
    .nullable()
    .test(
      'is-object',
      'paymentPlan must be an object',
      (value) => value === undefined || typeof value === 'object'
    )
    .test('required-fields', 'Missing required fields', function (value) {
      const { pushInInstallments } = this.parent;

      const paymentPlanValue = value as
        | { name?: string; items?: Array<{ name: string; description: string; value: string }> }
        | undefined;

      if (!pushInInstallments) return true;

      if (pushInInstallments && !paymentPlanValue) {
        return this.createError({ message: 'Payment plan is required when push in installments is true' });
      }

      const hasName = paymentPlanValue?.name;
      if (!hasName) return this.createError({ message: 'Payment plan name is required' });

      if (paymentPlanValue.items && Array.isArray(paymentPlanValue.items)) {
        const isValidItems = paymentPlanValue.items.every(
          (item) => item.name && item.description && item.value
        );
        if (!isValidItems) {
          return this.createError({
            message: 'Each item must have a name, description, and value',
          });
        }
      }

      return true;
    }), 
  
});
