import { ChangeEvent } from 'react';
import {
  Stack,
  TextField,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import { personalData } from '../Utils/signUpDescription';
import { LabelComponent } from '@/components';
import { PlaceholderTypography, StyledMenuItem } from '../../../uiComponents/styled';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { PersonalDateOfForeign, IconComponentProps } from '../Utils/types';
import { typographyH4Style, typographyBody1Style } from '../Utils/styled';

export const PersonalData = ({
  radioValue,
  isMobile,
  setPersonalDateOfForeign,
  personalDateOfForeign,
  errorFields,
  setErrorFields,
}: {
  radioValue: string;
  isMobile: boolean;
  setPersonalDateOfForeign: (value: PersonalDateOfForeign) => void;
  personalDateOfForeign: PersonalDateOfForeign;
  errorFields: string[];
  setErrorFields: (fields: string[]) => void;
}) => {
  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {personalData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={typographyBody1Style}>
          {personalData.bodyTitle.description}
        </Typography>
      </Stack>
      <Stack spacing={1.5} sx={{ width: '100%' }}>
        {personalData.bodyContent.map((content, index) => {
          switch (content.typeOfComponent) {
            case 'disableInput':
              return <TextField key={content.data.name} value={radioValue} disabled fullWidth />;
            case 'input':
              return (
                <Stack key={content.data.name} sx={{ width: '100%' }}>
                  <LabelComponent label={content.data.label} />
                  <TextField
                    name={content.data.name}
                    fullWidth
                    placeholder={content.data.placeholder}
                    error={errorFields.includes(content.data.name)}
                    helperText={errorFields.includes(content.data.name) && content.errorText}
                    inputProps={{ maxLength: content.data.maxLength }}
                    value={personalDateOfForeign[content.data.name as keyof PersonalDateOfForeign]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const fieldName = event.target.name as keyof PersonalDateOfForeign;
                      const value = event.target.value;
                      const newState: PersonalDateOfForeign = {
                        ...personalDateOfForeign,
                        [fieldName]: value,
                      };
                      setPersonalDateOfForeign(newState);
                      if (value) {
                        setErrorFields(errorFields.filter((field) => field !== fieldName));
                      }
                    }}
                  />
                </Stack>
              );
            case 'select':
              return (
                <Stack key={content.data.name} sx={{ width: '100%' }}>
                  <LabelComponent label={content.data.label} />
                  <FormControl fullWidth error={errorFields.includes(content.data.name)}>
                    <Select
                      name={content.data.name}
                      value={
                        personalDateOfForeign[content.data.name as keyof PersonalDateOfForeign]
                      }
                      onChange={(event: SelectChangeEvent<string>) => {
                        const fieldName = event.target.name as keyof PersonalDateOfForeign;
                        const value = event.target.value;
                        const newState: PersonalDateOfForeign = {
                          ...personalDateOfForeign,
                          [fieldName]: value,
                        };
                        setPersonalDateOfForeign(newState);
                        if (value) {
                          setErrorFields(errorFields.filter((field) => field !== fieldName));
                        }
                      }}
                      IconComponent={(_props: IconComponentProps) => {
                        const rotate = _props.className.toString().includes('iconOpen');
                        return (
                          <div
                            data-qa='dropdown_menu_btn'
                            style={{
                              position: 'absolute',
                              cursor: 'pointer',
                              pointerEvents: 'none',
                              right: 15,
                              height: '20px',
                              width: '20px',
                              transform: rotate ? 'rotate(180deg)' : 'none',
                            }}
                          >
                            <CaretDown />
                          </div>
                        );
                      }}
                      renderValue={(selected: unknown) => {
                        if (typeof selected === 'string') {
                          if (selected.length === 0) {
                            return (
                              <PlaceholderTypography variant='body1' color='textSecondary'>
                                {content.data.placeholder}
                              </PlaceholderTypography>
                            );
                          }
                          return selected;
                        } else if (typeof selected === 'number') {
                          return selected.toString();
                        }
                        return null;
                      }}
                    >
                      {content.data.options &&
                        content.data.options.map((item, index) => (
                          <StyledMenuItem
                            key={index}
                            value={item}
                            style={{ borderBottom: '1px solid #e0e0e0' }}
                          >
                            {item}
                          </StyledMenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errorFields.includes(content.data.name) && content.errorText}</FormHelperText>
                  </FormControl>
                </Stack>
              );
            }
        })}
      </Stack>
    </>
  );
};
