import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';

export interface IconTextProps {
  iconSrc?: string;
  children?: ReactNode;
  style?: Style;
}

export const Badge = ({ iconSrc, children, style }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 40,
      borderRadius: 92,
      borderWidth: 3,
      borderColor: '#1650FF',
      color: '#1650FF',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'row',
      gap: 20,
      lineHeight: 1.5,
      height: 80,
      ...style,
    },
  });

  return (
    <IconText iconSrc={iconSrc} style={styles.container}>
      {children}
    </IconText>
  );
};

export const IconText = ({ iconSrc, children, style }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
      ...style,
    },
    icon: {
      width: 56,
      height: 56,
    },
  });

  return (
    <View style={styles.container}>
      {iconSrc && <Image src={iconSrc} style={styles.icon} />}
      <Text>{children}</Text>
    </View>
  );
};
