import { FC, useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { selectUserTypeData } from '../Utils/signUpDescription';
import { UserKindEnum } from '@/types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useChangeQueryParams } from '@/hooks';
import { IMyLabelProps } from '../Utils/types';
import {
  typographyH4Style,
  typographyBody1Style,
  typographyBody1StyleError,
} from '../Utils/styled';

const MyLabel: FC<IMyLabelProps> = ({ data }) => {
  return (
    <>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{data.label}</Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: '400', maxWidth: '380px' }}>
        {data.description}
      </Typography>
    </>
  );
};

export const SelectUserType = ({
  radioValue,
  setRadioValue,
  setError,
  error,
  setKind,
}: {
  radioValue: string;
  setRadioValue: (value: string) => void;
  setError: (error: boolean) => void;
  error: boolean;
  setKind: (value: UserKindEnum) => void;
}) => {
  const changeQueryParams = useChangeQueryParams('signUp');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  useEffect(() => {
    changeQueryParams({ ...queryParams, step: 'one' });
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
    setError(false);
  };

  return (
    <>
      <Stack>
        <Typography variant='h4' sx={typographyH4Style}>
          {selectUserTypeData.bodyTitle.label}
        </Typography>
        <Typography variant='body1' sx={error ? typographyBody1StyleError : typographyBody1Style}>
          {selectUserTypeData.bodyTitle.description}
        </Typography>
      </Stack>
      <Stack spacing={3} sx={{ width: '100%', marginBottom: '20px' }}>
        {selectUserTypeData.bodyContent.map((content) => {
          return (
            <FormControl key={content.name}>
              <RadioGroup value={radioValue} onChange={handleChangeRadio}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  {content.radioGroup?.map((radio) => (
                    <FormControlLabel
                      key={radio.name}
                      value={radio.label}
                      control={
                        <Radio
                          sx={{
                            padding: '0',
                            alignSelf: 'flex-start',
                            color: '#2A384280',
                            '&.Mui-checked': {
                              color: '#1650FF',
                            },
                          }}
                        />
                      }
                      label={<MyLabel data={radio} />}
                      sx={{ justifyContent: 'flex-start' }}
                      onClick={() => setKind(radio.name)}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          );
        })}
      </Stack>
    </>
  );
};
