import { useState } from 'react';
import { Typography, Link, Stack } from '@mui/material';
import { styled } from '@mui/system';

const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>`
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '52px')};
`;

export const DescriptionBlock = ({ description }: { description: string }) => {
  const [isExpanded, setIsExpanded] = useState(description.length < 111);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        fontSize: {
          xs: '15px',
          sm: '16px',
        },
        color: '#2A3842',
      }}
    >
      <TextWrapper isExpanded={isExpanded}>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
      </TextWrapper>
      {description.length > 111 && (
        <Link onClick={handleToggle} sx={{ textDecoration: 'none', color: '#2A3842' }}>
          <span style={{ fontWeight: 600, color: '#1650FF', cursor: 'pointer' }}>
            {isExpanded ? 'Read less' : 'Read more'}
          </span>
        </Link>
      )}
    </Stack>
  );
};
