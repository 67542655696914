export const listItem = ({ backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  height: '60px',
  padding: '6px 16px',
  position: 'relative',

  '&:hover': {
    backgroundColor: `${backgroundColor.lightBlue3}`,
  },
});

export const itemThumbnailStyle = () => ({
  display: 'inline-block',
  width: '40px',
  height: '40px',
  flexShrink: '0',
});

export const itemDetailStyle = () => ({
  width: 'calc(100% - 45px)',
  flexGrow: '1',
  paddingLeft: '16px',

  '&[dir=rtl]': {
    paddingRight: '16px',
    paddingLeft: '0',
  },
});

export const itemRowStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

export const itemNameStyle = ({ color }) => ({
  fontSize: '16px',
  fontWeight: '500',
  display: 'block',
  width: 'calc(100% - 70px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: '22px',
  color: `${color.primary}`,
});

export const itemLastMsgStyle = ({ color }) => ({
  margin: '0',
  fontSize: '14px',
  fontWeight: '400',
  width: 'calc(100% - 50px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: '20px',
  color: `${color.primary}`,
  opacity: '0.7',

  span: {
    fontWeight: '600',
  },
});

export const itemLastMsgTimeStyle = ({ color }) => ({
  fontSize: '14px',
  width: '70px',
  textAlign: 'right',
  color: `${color.grey}`,
});
