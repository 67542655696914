import { AdMediaType, NewBuildingModel } from '@/types';
import { Picture } from '@/components/PdfManager/PdfDocument/ClassicView/Picture';
import { StyleSheet, View, Image, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { BedBlue, BuildingsBlue, DoorBlue, FloorBlue, RulerBlue } from '@/assets/pdf';
import { IUnit } from '@/types/offplans';

export interface UnitCardProps {
  unit: IUnit;
  images?: AdMediaType[];
  wrap?: boolean;
  style?: Style;
  offplanData?: NewBuildingModel;
  showPrice?: boolean;
}

const styles = StyleSheet.create({
  container: {
    width: '31vw',
    borderRadius: '8px',
    height: '650px',
    border: '1px solid #2A3842',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: 24,
    height: '500px',
  },
  featuresBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    padding: '32px',
  },
  divider: {
    height: '100%',
    width: 4,
    backgroundColor: '#2A384233',
    marginHorizontal: 0,
  },
  characteristicsContainer: {
    flexDirection: 'row',
    width: '220px',
    marginVertical: '8px',
    justifyContent: 'space-between',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  icon: {
    width: 48,
    height: 48,
  },
  text: {
    fontWeight: 400,
    fontSize: 32,
  },
  informationInsideImage: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
    position: 'absolute',
    bottom: '40px',
    right: '12px',
    color: '#fff',
    backgroundColor: '#1650FF',
    padding: 10,
    alignItems: 'center',
    fontSize: 30,
    gap: 8,
  },
  bold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 42,
  },
});

export const UnitCard = ({ unit, offplanData, showPrice }: UnitCardProps) => {
  const characteristics = [
    {
      id: 1,
      value: unit.areaSQFT ? `${unit.areaSQFT} sqft` : null,
      icon: RulerBlue,
    },
    {
      id: 2,
      value: unit.floor ? `${unit.floor} of ${offplanData?.numberOfFloors}` : null,
      icon: FloorBlue,
    },
    {
      id: 3,
      value: unit.beds ? `${unit.beds} beds` : null,
      icon: DoorBlue,
    },
    {
      id: 4,
      value: unit.apartmentNumber ? unit.apartmentNumber : null,
      icon: BedBlue,
    },
    {
      id: 5,
      value: offplanData?.constructionType ? offplanData?.constructionType : null,
      icon: BuildingsBlue,
    },
  ];
  return (
    <View style={styles.container}>
      <View>
        <Picture style={styles.image} src={unit?.media[0].fileUrl} />
        {showPrice ? (
          <View style={styles.informationInsideImage}>
            <Text>Price</Text>
            <Text style={styles.bold}>{unit.price}</Text>
            <Text>AED</Text>
          </View>
        ) : null}
      </View>
      <View style={styles.featuresBlock}>
        {characteristics.map((character, index, arr) => (
          <View key={character.id} style={styles.characteristicsContainer}>
            <View style={styles.innerContainer}>
              <Image src={character.icon} style={styles.icon} />
              <Text style={styles.text}>{character.value}</Text>
            </View>
            {arr.length > index + 1 && index !== 2 ? <View style={styles.divider} /> : null}
          </View>
        ))}
      </View>
    </View>
  );
};
