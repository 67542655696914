import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  AdsFilteringType,
  AdModel,
  PaginationType,
  AdsSortingType,
  AdsViewModeEnum,
  SortingOrderEnum,
  ActivityKindEnum,
  CompletionStatusEnum,
  PropertyKindEnum,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  AdsGetAllResponseType,
  NotificationTypeEnum,
  RentPeriodEnum,
  TransactionTypeEnum,
  AdFieldRenovationEnum,
  AdFieldFurnitureEnum,
  AdFieldFloorEnum,
  AdFieldOccupancyEnum,
  State,
} from '@/types';
import { setAddressParamsInFilteringObj } from '@/utils';
import { adsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination } from '@/components';

import { COUNT_ADS_PER_PAGE } from './constants';
import { AdsFilters } from './AdsFilters';
import { AdsListControls } from './AdsListControls';
import { EmptyList } from './EmptyList';
import { AdsListItem } from './AdsListItem';
import styles from './Ads.module.scss';
import { MapUi } from '@/components/Map/Map';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { useUserSettings } from '@/hooks/useUserSettings';

interface TotalCountsType {
  published: number;
  unpublished: number;
  archived: number;
}

interface AdsProps {
  isRent?: boolean;
}

export const Ads = ({ isRent }: AdsProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: State) => auth);
  const changeQueryParams = useChangeQueryParams(isRent ? 'ads/rent' : '');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [isLoadingGetAds, setIsLoadingGetAds] = useState<boolean>(true);

  const [viewMode, setViewMode] = useState<AdsViewModeEnum>(AdsViewModeEnum.List);
  const [ads, setAds] = useState<AdModel[]>();

  const [filteringObj, setFilteringObj] = useState<AdsFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [changedFilter, setChangeFilter] = useState<boolean>(false);
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const sortingFields = ['date', /* 'popular', */ 'price' /*'fee'*/];
  const [isClickOnMap, setIsClickOnMap] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);

  const [rowsPerPage, setRowsPerPage] = useState<number>(COUNT_ADS_PER_PAGE);

  const {
    locations,
    setLocations,
    minimizedLocations,
    setMinimizedLocations,
    selectedLocation,
    ...locationProps
  } = useLocationAutocomplete({ defaultValue: '' });

  const { userSettings } = useUserSettings();

  const getAds = async () => {
    const valueChange = (value: any) =>
      Array.isArray(value) ? value : typeof value === 'string' ? [value] : undefined;
    const address =
      queryParams.addressParamLat && queryParams.addressParamLong
        ? [
            {
              // addressEn: queryParams.addressParamAddress as string,
              coordinatesLat: queryParams.addressParamLat as string,
              coordinatesLong: queryParams.addressParamLong as string,
              nameEn: queryParams.addressParamName as string,
              // placeId: queryParams.addressParamPlaceId as string,
            },
          ]
        : [];
    const newFilteringObj: AdsFilteringType = {
      //! read URL params
      address,
      activityKind: isRent ? ActivityKindEnum.Rent : ActivityKindEnum.Sale,
      transactionType: queryParams.transactionType
        ? valueChange(queryParams.transactionType)
        : undefined,
      rentFrequency: queryParams.rentFrequency as RentPeriodEnum,
      propertyKind: queryParams.propertyKind
        ? [queryParams.propertyKind as PropertyKindEnum]
        : undefined,

      beds: queryParams.beds ? valueChange(queryParams.beds) : undefined,
      areaMin: parseInt(queryParams.areaMin as string) || undefined,
      areaMax: parseInt(queryParams.areaMax as string) || undefined,

      baths: queryParams.baths ? [queryParams.baths as AdFieldBathsEnum] : undefined,
      priceMin: parseInt(queryParams.priceMin as string) || undefined,
      priceMax: parseInt(queryParams.priceMax as string) || undefined,
      locationId: queryParams.locationId ? [queryParams.locationId as string] : undefined,
      status: undefined,

      completionStatus: queryParams.completionStatus
        ? [queryParams.completionStatus as CompletionStatusEnum]
        : undefined,
      creator:
        queryParams['creator.name'] && queryParams['creator.kind'] && queryParams['creator.id']
          ? {
              name: queryParams['creator.name']?.toString() || undefined,
              kind: queryParams['creator.kind']?.toString() || undefined,
              id: queryParams['creator.id']?.toString() || undefined,
            }
          : undefined,
      postHandover:
        queryParams.postHandover === 'true'
          ? true
          : queryParams.postHandover === 'false'
            ? false
            : undefined,
      handover:
        queryParams['handover.quarter'] || queryParams['handover.year']
          ? {
              quarter: queryParams['handover.quarter']?.toString() || undefined,
              year: queryParams['handover.year']?.toString() || undefined,
            }
          : undefined,
      downPayment: parseInt(queryParams.downPayment as string) || undefined,
      furniture: queryParams.furniture
        ? valueChange(queryParams.furniture)
        : undefined,
      renovation: queryParams.renovation
        ? valueChange(queryParams.renovation)
        : undefined,
      floor: queryParams.floor ? valueChange(queryParams.floor) : undefined,
      occupancy: queryParams.occupancy
        ? [queryParams.occupancy as AdFieldOccupancyEnum]
        : undefined,
      floorsNumFrom: parseInt(queryParams.floorsNumFrom as string) || undefined,
      floorsNumTo: parseInt(queryParams.floorsNumTo as string) || undefined,
      keywords: (queryParams.keywords as string) || undefined,
      isMyAgency:
        queryParams.isMyAgency === 'true'
          ? true
          : queryParams.isMyAgency === 'false'
            ? false
            : undefined,
      myFriends:
        queryParams.myFriends === 'true'
          ? true
          : queryParams.myFriends === 'false'
            ? undefined
            : undefined,
    };

    const newSortingObj = {
      field: (queryParams.field as string) || sortingFields[0],
      order: (queryParams.order as SortingOrderEnum) || SortingOrderEnum.Desc,
    };

    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * rowsPerPage
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: rowsPerPage,
      total: 0,
    };

    setIsLoadingGetAds(true);
    const isMyAds = false;
    const { ok, data, status, message } = await adsProvider.getAds(
      isMyAds,
      newFilteringObj,
      newSortingObj,
      newPaginationObj
    );
    if (ok) {
      const { items, total, locations: minimizedLocations } = data as AdsGetAllResponseType;
      if (!isClickOnMap) {
        setMinimizedLocations(minimizedLocations);
        setSelectedMarker(null);
      }
      setIsClickOnMap(false);
      setAds(items);
      setFilteringObj({ ...newFilteringObj });
      setSortingObj({ ...newSortingObj });
      setPaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetAds(false);
  };

  useEffect(() => {
    getAds();
  }, [queryParamsStr, rowsPerPage]);

  const handleChangeFilter = (newFilteringObj: AdsFilteringType, shouldSendRequest?: boolean) => {
    // console.log(newFilteringObj);
    //! to URL
    if (newFilteringObj.activityKind === ActivityKindEnum.Rent) {
      newFilteringObj.completionStatus = undefined;
    } else if (newFilteringObj.activityKind === ActivityKindEnum.Sale) {
      newFilteringObj.rentFrequency = undefined;
    } else {
      newFilteringObj.completionStatus = undefined;
      newFilteringObj.rentFrequency = undefined;
      newFilteringObj.activityKind = undefined;
    }

    if (!newFilteringObj.propertyKind?.length) {
      newFilteringObj.propertyKind = undefined;
    }

    if (
      !newFilteringObj?.creator?.name &&
      !newFilteringObj?.creator?.kind &&
      !newFilteringObj?.creator?.id
    ) {
      newFilteringObj.creator = undefined;
    }

    if (
      !newFilteringObj?.handover?.quarter &&
      !newFilteringObj?.creator?.kind &&
      !newFilteringObj?.creator?.id
    ) {
      newFilteringObj.creator = undefined;
    }

    if (newFilteringObj?.creator) {
      if (newFilteringObj?.creator?.name)
        newFilteringObj['creator.name'] = newFilteringObj?.creator?.name;
      if (newFilteringObj?.creator?.kind)
        newFilteringObj['creator.kind'] = newFilteringObj?.creator?.kind;
      if (newFilteringObj?.creator?.id)
        newFilteringObj['creator.id'] = newFilteringObj?.creator?.id;
    }

    if (newFilteringObj?.handover) {
      if (newFilteringObj?.handover?.quarter)
        newFilteringObj['handover.quarter'] = newFilteringObj?.handover?.quarter;
      if (newFilteringObj?.handover?.year)
        newFilteringObj['handover.year'] = newFilteringObj?.handover?.year;
    }

    setFilteringObj(newFilteringObj);
    if (shouldSendRequest) {
      const { creator, handover, ...rest } = newFilteringObj;

      changeQueryParams({
        ...queryParams,
        ...setAddressParamsInFilteringObj(rest),
        page: 1,
      });
    }
    setChangeFilter(true);
  };

  const handleChangeSort = (newSortingObj: AdsSortingType) =>
    changeQueryParams({
      ...queryParams,
      ...newSortingObj,
    });

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  const onLocationClick = (locationId: string) => {
    handleChangeFilter({ ...filteringObj, locationId }, true);
    setIsClickOnMap(true);
    setSelectedMarker(locationId);
  };

  if (isLoadingGetAds) {
    return <Loader />;
  }

  return (
    <div className={styles.adsContainer}>
      {filteringObj && (
        <AdsFilters
          user={user}
          filteringObj={filteringObj}
          onChangeFilters={handleChangeFilter}
          locations={locations}
          setLocations={setLocations}
          selectedLocation={selectedLocation}
          setChangeFilter={setChangeFilter}
          isRent={isRent}
          userSettings={userSettings}
          {...locationProps}
        />
      )}

      <div className={styles.templateAdsContainer}>
        <div className={styles.listContainer}>
          {sortingObj && (
            <AdsListControls
              viewMode={viewMode}
              setViewMode={setViewMode}
              sortingFields={sortingFields}
              sortingObj={sortingObj}
              onChangeSort={handleChangeSort}
            />
          )}
          <div className={styles.content}>
            {!ads?.length ? (
              <EmptyList changedFilter={changedFilter} />
            ) : (
              <div
                className={classnames({
                  [styles.mapMode]: viewMode === AdsViewModeEnum.Map,
                  [styles.adsList]: viewMode === AdsViewModeEnum.List,
                })}
              >
                {(ads as AdModel[]).map((item) => (
                  <AdsListItem
                    key={item.id}
                    item={item}
                    filteringObj={filteringObj}
                    userId={user.id}
                    tariffPlan={user.tariffPlan?.name}
                  />
                ))}
              </div>
            )}
            {viewMode === AdsViewModeEnum.Map && (
              <MapUi
                selectedLocation={selectedLocation}
                minimizedLocations={minimizedLocations}
                onLocationClick={onLocationClick}
                selectedMarker={selectedMarker}
              />
            )}
          </div>
          {!!paginationObj?.total &&
          paginationObj?.total > COUNT_ADS_PER_PAGE &&
          !isLoadingGetAds ? (
            <Pagination paginationObj={paginationObj} onChangePage={handleChangePage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
