import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import { BrokerType } from '../types/types';

interface UserTableProps {
  licenses: BrokerType[];
  onRowClickHandler: (id: string) => void;
}

const BrokerLicenseTable: React.FC<UserTableProps> = ({ licenses, onRowClickHandler }) => {
  const StyledTableCell = styled(TableCell)({
    border: 0,
    borderRight: '1px solid #E9EEF1',
    '&:last-child': {
      borderRight: 'none',
    },
    verticalAlign: 'middle',
  });

  const StyledTableContainer = styled(TableContainer)({
    background: 'transparent',
    boxShadow: 'none',
  });

  const StyledTableRow = styled(TableRow)({
    border: '1px solid #E9EEF1',
    background: 'inherit',
  });

  const StyledTableHead = styled(TableHead)({
    background: '#fff',
  });

  const formatDate = (value: string | null) =>
    value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell>Lic. ID</TableCell>
            <TableCell>State ID</TableCell>
            <TableCell>Name Eng</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell width='150'>Email</TableCell>
            <TableCell>Lic. Start</TableCell>
            <TableCell>Lic. End</TableCell>
            <TableCell>Agency Name</TableCell>
            <TableCell>Agency Phone</TableCell>
            <TableCell>Manual</TableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {licenses.map((license, index) => (
            <StyledTableRow key={index} onClick={() => onRowClickHandler(license.id)}>
              <StyledTableCell>{license.id}</StyledTableCell>
              <StyledTableCell>{license.stateId}</StyledTableCell>
              <StyledTableCell>{license.nameEn}</StyledTableCell>
              <StyledTableCell>{license.phone}</StyledTableCell>
              <StyledTableCell width='150'>{license.email}</StyledTableCell>
              <StyledTableCell>{formatDate(license.licenseStart)}</StyledTableCell>
              <StyledTableCell>{formatDate(license.licenseEnd)}</StyledTableCell>
              <StyledTableCell>{license.agency.nameEn}</StyledTableCell>
              <StyledTableCell>{license.agency.phone}</StyledTableCell>
              <StyledTableCell>{license.manual ? 'manual' : ''}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default BrokerLicenseTable;
