import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TransactionTypeEnum, AdFieldOccupancyEnum } from '@/types';
import { SectionTitle, FormButtons } from '@/components';

export const TransactionType = ({
  isPropertyTypeVisible,
  setIsPropertyTypeVisible,
  setIsLocationVisible,
  setIsBtnVisible,
  setIsPageChanged,
}: {
  isPropertyTypeVisible: boolean;
  setIsPropertyTypeVisible: (value: boolean) => void;
  setIsLocationVisible: (value: boolean) => void;
  setIsBtnVisible: (value: boolean) => void;
  setIsPageChanged: (value: boolean) => void;
}) => {
  const { getValues, setValue } = useFormContext();
  const formValues = getValues();
  const { transactionType } = formValues;

  const [transactionTypeValue, setTransactionTypeValue] = useState<TransactionTypeEnum>();

  useEffect(() => {
    setTransactionTypeValue(transactionType);
  }, [])
  

  const transactionTypeValues = [
    { label: 'Ready', value: TransactionTypeEnum.Ready },
    { label: 'Resale', value: TransactionTypeEnum.Resale },
    { label: 'Distress', value: TransactionTypeEnum.Distress },
  ];

  const onHandlerClick = (value: TransactionTypeEnum) => {
    if (value !== transactionType) {
      setValue('transactionType', value);
      setValue('propertyKind', null);
      setValue('coordinatesLat', null);
      setValue('coordinatesLong', null);
      setValue('addressEn', null);
      setIsLocationVisible(false);
      setIsBtnVisible(false);
      setTransactionTypeValue(value);
      setIsPageChanged(false);
      if (!isPropertyTypeVisible) {
        setIsPropertyTypeVisible(true);
      }
    }
  };

  return (
    <div>
      <SectionTitle number={2} title='Select transaction type' />
      <FormButtons 
        options={transactionTypeValues}
        onClick={onHandlerClick}
        activeButton={transactionTypeValue}
        size='middle'
      />
    </div>
  );
};
