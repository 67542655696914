import { styled } from '@mui/system';
import {
  Accordion,
  Button,
  IconButton,
  Stack,
  Typography,
  Tab,
  Box,
  Collapse,
} from '@mui/material';

export const Title = styled(Box)({
  color: '#2A3842',
  maxWidth: '385px',
  fontSize: '20px',
  fontWeight: '600',
  '@media (max-width: 600px)': {
    fontSize: '18px',
  },
});

export const StyledPriceBlock = styled('div')({
  color: '#2A3842',
  fontWeight: 600,
  fontSize: '16px',
  display: 'flex',
  gap: '6px',
  alignItems: 'end',
  '@media (max-width: 600px)': {
    gap: '4px',
    alignItems: 'center',
    fontSize: '16px',
  },
});

export const StyledPriceSpan = styled('span')({
  fontSize: '16px',
  color: '#1650FF',
  lineHeight: '1.5',
  '@media (max-width: 600px)': {
    fontSize: '16px',
  },
});

export const Term = styled(Typography)({
  fontSize: '14px',
  color: '#1650FF',
});

export const Percent = styled(Typography)({
  color: '#1650FF',
  fontSize: 'inherit',
});

export const PercentDescr = styled(Typography)({
  fontSize: 'inherit',
});

export const NumOfAd = styled(Typography)(({ theme }) => ({
  opacity: 0.5,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#1579FF33',
  width: '32px',
  height: '32px',

  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .3)',
    background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
    color: '#ffffff',
  },
  [theme.breakpoints.down('sm')]: {
    background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
    color: '#ffffff',
    '&:hover': {
      boxShadow: '0 0 7px 2px rgba(1, 92, 213, .3)',
      backgroundColor: '#1579FF33',
    },
  },
}));

export const Shell = styled(Stack)({
  width: '100px',
  height: '32px',
  borderRadius: '14px',
  marginLeft: '5px',
  border: `1px solid #1650FF`,
});

export const SmallShell = styled(Stack)({
  width: '100px',
  height: '24px',
  borderRadius: '14px',
  marginLeft: '5px',
  border: `1px solid #1650FF`,
});

export const ShellBlack = styled(Box)(({}) => ({
  display: 'flex',
  height: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '32px',
  border: `1px solid #2A3842`,
  padding: '0 13px 0 15px',
  gap: '10px',
}));

export const SpecificationsContainer = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'left',

  '& .MuiDivider-root': {
    backgroundColor: '#2A3842',
    opacity: 0.3,
    width: '1px',
  },
});

export const UserName = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  color: '#1650FF',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const UserType = styled(Typography)({
  opacity: 0.5,
});

export const StyledAccordion = styled(Accordion)(({}) => ({
  backgroundColor: 'transparent',
  width: '340px',
  boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
}));

export const StyledButton = styled(Button)({
  background: 'transparent',
  color: '#015cd5',
  border: '2px solid #015cd5',
  fontSize: 'inherit',
  width: '100%',
  height: '48px',
  textTransform: 'none',
  borderRadius: '14px',
  fontWeight: 500,
  '&:hover': {
    boxShadow: '0 0 3px 2px rgba(1, 92, 213, .3)',
    background: 'transparent',
  },
});

export const StyledTab = styled(Tab)({
  fontSize: '24px',
  fontFamily: 'Arial, sans-serif',
  fontWeight: '800',
  '&.Mui-selected': {},
  '&:not(.Mui-selected)': {
    opacity: 0.4,
  },
});

export const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>`
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '100px')};
  position: relative;

  &::after {
    content: ${({ isExpanded }) => (isExpanded ? 'none' : '""')};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to top, #f1f7ff, transparent);
  }
`;

export const PriceValuesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  [`@media (min-width: 600px)`]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const TopUpContainer = styled('div')({
  border: '1px solid #E9EEF1',
  borderRadius: '12px',
  padding: '14px',
});

export const TopUpLabel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  color: '#1650FF',

  '& label': {
    marginRight: '0',
  },
});

export const TopUpConditions = styled(Collapse)({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#2A3842',
});

export const CheckedIcon = styled('span')({
  backgroundRepeat: 'no-repeat',
  backgroundSize: '65%',
  backgroundPosition: '50%',
  backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>')`,
});
