import { Avatar, Link, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { UserKindEnum } from '@/types';
import { stringAvatar, getKindLabel } from '@/utils';
import styles from './MemberItem.module.scss';
import activeAdsImg from './activeAdsIcon.svg';
import adParticipantImg from './adParticipantIcon.svg';
import removeMember from './removeMemberIcon.svg';

interface MemberModel {
  id: string;
  name: string;
  avatar: string;
  isRegistration: boolean;
  isPayment: boolean;
}

interface MemberItemProps {
  item: MemberModel;
}

export const MemberItem = ({
  item: { id, avatar, name, isRegistration, isPayment },
}: MemberItemProps) => {
  const isInvite = isRegistration && isPayment;

  return (
    <Stack className={styles.container} direction='row' spacing={3}>
      <Stack className={styles.blockAvatar} direction='row'>
        <Stack className={styles.avatarImage}>
          <Link href={`/users/${id}`} title={name}>
            {avatar ? (
              <img src={avatar} alt={name} />
            ) : (
              <Avatar {...stringAvatar(name)} sx={{ width: 48, height: 48 }} />
            )}
          </Link>
        </Stack>
        <Stack className={styles.nameLabel}>
          <Link href={`/users/${id}`} title={name}>
            {name}
          </Link>
        </Stack>
      </Stack>

      <Stack alignItems='center' direction={{ xs: 'column', sm: 'row' }} spacing={{ sm: 3, xs: 1 }}>
        <Stack direction='column' alignItems='center'>
          <Stack className={`${isRegistration ? styles.indicatorFull : styles.indicator}`} />
          <Typography sx={{ fontSize: '12px', color: '#2A384260' }}>registration</Typography>
        </Stack>
        <Stack direction='column' alignItems='center'>
          <Stack className={`${isPayment ? styles.indicatorFull : styles.indicator}`} />
          <Typography sx={{ fontSize: '12px', color: '#2A384260' }}>payment</Typography>
        </Stack>
        <Stack
          sx={{
            width: 20,
            height: 20,
            backgroundColor: isInvite ? '#1EDA00' : '#E9EEF1',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check sx={{ color: 'white', fontSize: 16, fontWeight: 600 }} />
        </Stack>
      </Stack>
    </Stack>
  );
};
