export const sectionStyle = (props) => {
  const containerHeightProp = props.containerHeight
    ? {
        height: `calc(100% - ${props.containerHeight})`,
      }
    : {
        height: 'calc(100% - 25px)',
      };

  return {
    width: '100%',
    marginTop: '25px',
    ...containerHeightProp,
  };
};

export const sectionHeaderStyle = (props) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500!important',
    lineHeight: '21px',
    color: `${props.theme.color.primary}`,
    opacity: '0.4',
  };
};

export const sectionContentStyle = () => {
  return {
    width: '100%',
    margin: '6px 0',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 30px)',
  };
};

export const mediaBtnStyle = () => {
  return {
    display: 'flex',
    margin: '6px 0',
  };
};

export const buttonStyle = ({ theme }, state, type) => {
  const activeBtn =
    state.messagetype === type
      ? {
          opacity: '1',
          borderBottom: `2px solid ${theme.color.primary}`,
        }
      : {};

  return {
    padding: '7px 20px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
    color: `${theme.color.primary}`,
    lineHeight: '18px',
    opacity: '0.4',
    ...activeBtn,
  };
};

export const mediaItemStyle = ({ theme }) => {
  return {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '180px 180px',
    gridGap: '14px',
    color: `${theme.color.primary}`,
  };
};

export const itemStyle = ({ messagetype }, { theme }, img) => {
  let itemTypeStyle = {
    position: 'relative',
    width: '180px',
    height: '110px',
    borderRadius: '14px',
    backgroundColor: `${theme.backgroundColor.lightGrey2}`,
    overflow: 'hidden',
    '> img, > video, > a': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };

  if (messagetype === 'video') {
    itemTypeStyle = {
      ...itemTypeStyle,
      '&::after': {
        content: `""`,
        display: 'block',
        width: '40px',
        height: '40px',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        background: `url(${img}) center center no-repeat`,
      },
    };
  } else if (messagetype === 'file') {
    itemTypeStyle = {
      ...itemTypeStyle,
      height: '56px',
      backgroundColor: `${theme.backgroundColor.lightBlue3}`,
      '> a': {
        display: 'block',
        padding: '18px 10px 0 40px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '14px',
        fontWeight: '700',
        color: `${theme.color.primary}`,
      },
      '> a::before': {
        content: `""`,
        display: 'block',
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: 'calc(50% - 12px)',
        left: '10px',
        mask: `url(${img}) center center no-repeat`,
        backgroundColor: `${theme.color.primary}`,
      },
    };
  }

  return {
    ...itemTypeStyle,
  };
};
