export const chatsWrapperStyle = (props, theme) => {
  const borderStyle =
    props._parent === ''
      ? {
          border: `1px solid ${theme.borderColor.primary}`,
        }
      : {};

  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    ...borderStyle,
    '*': {
      boxSizing: 'border-box',
      '::-webkit-scrollbar': {
        width: '8px',
        height: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#ffffff00',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#ccc',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  };
};

export const chatsHeaderStyle = (theme) => ({
  padding: '16px',
  display: 'none',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderColor.primary}`,
  height: '69px',
});

export const chatsHeaderCloseStyle = (img, theme) => {
  const mq = [...theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    mask: `url(${img}) no-repeat left center`,
    backgroundColor: `${theme.primaryColor}`,
    height: '24px',
    width: '33%',
    [`@media ${mq[0]}`]: {
      display: 'block!important',
    },
  };
};

export const chatsHeaderTitleStyle = (props) => {
  const alignment =
    props.hasOwnProperty('enableCloseMenu') && props.enableCloseMenu.length > 0
      ? {
          width: '33%',
          textAlign: 'center',
        }
      : {};

  return {
    margin: '0',
    display: 'inline-block',
    width: '100%',
    textAlign: 'left',
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '26px',
    ...alignment,
    '&[dir=rtl]': {
      textAlign: 'right',
    },
  };
};

export const chatsMsgStyle = () => ({
  overflow: 'hidden',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
});

export const chatsMsgTxtStyle = (theme) => ({
  margin: '0',
  minHeight: '36px',
  color: `${theme.color.secondary}`,
  fontSize: '20px!important',
  fontWeight: '600',
  lineHeight: '30px',
  wordWrap: 'break-word',
  padding: '0 16px',
});

export const chatsListStyle = () => ({
  height: 'calc(100% - 65px)',
  width: '100%',
  overflowY: 'auto',
  margin: '0',
  padding: '0',
});

export const chatsCustomHeaderStyle = () => ({
  display: 'flex',
  gap: '8px',
  margin: '0px 10px 16px 16px',
});

export const messagesCustomHeaderStyle = () => ({
  margin: '0px 10px 16px 16px',
});

export const chatSearchStyle = ({ theme }, fullWidth) => ({
  borderRadius: '36px',
  border: `1px solid ${theme.borderColor.primary}`,
  backgroundColor: theme.backgroundColor.lightBlue2,
  width: fullWidth ? '100%' : '240px',
  height: '44px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 15px',
});

export const chatSearchInputStyle = (img, { theme }) => ({
  width: 'calc(100% - 25px)',
  height: '100%',
  padding: '8px 8px 8px 40px',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
  outline: 'none',
  border: 'none',
  color: theme.color.primary,
  background: `url(${img}) 7px center no-repeat`,
  opacity: '0.5',
  '&:focus': {
    opacity: '1',
  },
});

export const groupAddStyle = (img, theme) => ({
  width: '44px',
  height: '44px',
  border: `1px solid ${theme.borderColor.primary}`,
  backgroundColor: theme.backgroundColor.lightBlue2,
  cursor: 'pointer',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  i: {
    width: '20px',
    height: '20px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.primary}`,
    marginRight: '-0.5rem',
    [`@media (min-width: 600px) `]: {
      marginRight: '0',
    },
  },
  fontSize: '0',
  [`@media (min-width: 600px) `]: {
    fontSize: 'inherit',
    width: '150px',
    borderRadius: '36px',
  },
});

export const clearBtnStyle = (img, theme) => ({
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  mask: `url(${img}) center center no-repeat`,
  backgroundColor: `${theme.color.primary}`,
});

export const searchMessagesBtnStyle = (img, theme) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.color.blueActive,
  fontWeight: '500',
  margin: '10px 0 6px',
  i: {
    width: '32px',
    height: '32px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${theme.color.blueActive}`,
  },
});
