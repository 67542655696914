import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Divider } from '@mui/material';
import { commentsProvider } from '@/providers';
import {
  AdModel,
  NotificationTypeEnum,
  CommentModel,
  CommentsResponseType,
  UserModel,
  HeaderDialogsEnum,
  UserTariffPlanEnum,
} from '@/types';
import { Title } from '../styled';
import { appSetNotification } from '@/store';
import { WriteComment } from './WriteComment';
import { CommentItem } from './CommentItem';
import { CommentReport } from './CommentReport';
import { Loader } from '@/components';

export const Comments = ({
  page,
  user,
  handleClickOpenDialog,
}: {
  page: AdModel;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const [newComment, setNewComment] = useState('');
  const [totalComments, setTotalComments] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [like, setLike] = useState('');
  const [openCommentReportDialog, setOpenCommentReportDialog] = useState(false);

  const dispatch = useDispatch();

  const getCommentsData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await commentsProvider.getComments(page.id!);
    if (ok && data) {
      const { items, total } = data as CommentsResponseType;
      if (items && total) {
        setComments(items);
        setTotalComments(total);
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCommentsData();
  }, [like, newComment]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Stack sx={{ width: '100%', gap: '16px', color: '#2A3842' }}>
        <Title>Comments {totalComments && `(${totalComments})`}</Title>
        <Divider sx={{ border: `1px solid #E9EEF1` }} />
        <WriteComment
          id={page.id}
          setNewComment={setNewComment}
          type='create'
          user={user}
          handleClickOpenDialog={handleClickOpenDialog}
        />
        <Divider sx={{ border: `1px solid #E9EEF1` }} />
        {comments &&
          comments?.map((comment) => {
            return (
              <CommentItem
                key={comment.id}
                comment={comment}
                setLike={setLike}
                setNewComment={setNewComment}
                id={page.id}
                setOpenCommentReportDialog={setOpenCommentReportDialog}
                user={user}
                handleClickOpenDialog={handleClickOpenDialog}
              />
            );
          })}
      </Stack>
      <CommentReport
        open={openCommentReportDialog}
        handleClose={() => setOpenCommentReportDialog(false)}
      />
    </>
  );
};
