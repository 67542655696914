import { FormControl, FormHelperText, SxProps, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import styles from './FormInputNumeric.module.scss';

export const FormInputNumeric = ({
  name,
  suffix,
  handleChange,
  handleFocus,
  handleBlur,
  uiVariant,
  placeholder,
  readOnly = false,
  limiter,
  labelWeight,
  error,
  errorsName,
  ...otherProps
}: {
  name: string;
  suffix?: string;
  handleChange?: (value: number) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  uiVariant?: 'blank';
  readOnly?: boolean;
  limiter?: number;
  labelWeight?: boolean;
  error?: boolean;
  errorsName?: string | null;
} & TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const isPercent = suffix === '%';

  const rentPrice =
    name === 'priceYearly' ||
    name === 'priceMonthly' ||
    name === 'priceWeekly' ||
    name === 'priceDaily';

  const rentError = error && errorsName === name;

  const errorText = (name: string) => {
    if (name === 'price') {
      if (errors?.['price']?.message === '10000AED') {
        return 'The price must be at least 10000 AED or equivalent in USD';
      } else {
        return 'Enter a numeric property price to continue';
      }
    }
    if (name === 'sellingPrice') {
      if (errors?.['sellingPrice']?.message === '5%') {
        return '';
      } else {
        return 'Enter a numeric property price to continue';
      }
    }
    if (name === 'toSeller') return 'Enter To Seller';
    if (rentPrice) return 'Price must be more than 0';
    if (name === 'priceFrom') return 'Price from is a required field';
    if (name === 'priceTo') return 'Price to is a required field';
  };

  const defaultStyles: SxProps =
    uiVariant === 'blank' ? { width: '100%' } : { m: 1, width: '100%', margin: '0 20px 14px 0' };

  const helperText = errors[name] || rentError ? errorText(name) : '';

  return (
    <FormControl sx={defaultStyles}>
      {otherProps.label && (
        <p className={`${labelWeight ? styles.labelWeight : styles.label}`}>
          {otherProps.label}
          {otherProps.required && <span>*</span>}
        </p>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            valueIsNumericString={true}
            allowNegative={false}
            placeholder={placeholder}
            decimalScale={isPercent ? 1 : 0}
            value={
              (name === 'toDeveloper' || name === 'toSeller') &&
              Number(otherProps.defaultValue) >= 0
                ? otherProps.defaultValue
                : value
            }
            suffix={suffix}
            disabled={readOnly}
            // inputProps={{ readOnly: readOnly }}
            isAllowed={(values) => {
              const { value } = values;
              const numericValue = parseInt(value, 10);
              if (isPercent) {
                if (numericValue > 100) {
                  return false;
                }
                if (value.startsWith('0') && value.length > 1) {
                  return false;
                }
              }
              if (limiter) {
                if (Number(value) > limiter) {
                  return false;
                }
              }
              return true;
            }}
            onValueChange={({ value }) => {
              onChange(value);
              if (handleChange) {
                const newValue = value ? value : '0';
                if (rentPrice) {
                  handleChange(parseFloat(newValue));
                } else {
                  handleChange(parseFloat(value));
                }
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={!!errors[name] || rentError}
            helperText={helperText?.toString()}
          />
        )}
      />
    </FormControl>
  );
};
