import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ValidationError } from 'yup';

const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/;

export const schemaCreatePassword = yup.object().shape({
  password: yup
    .string()
    .required('Specify a password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/^\S*$/, 'Password should not contain any spaces'),
  confirmPassword: yup
    .string()
    .required('Confirm Password')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export const schemaForeignContactData = yup.object().shape({
  name: yup
    .string()
    .required('name')
    .test(
      'is-not-blank', 
      'name', 
      value => value?.trim().length > 0
    ),
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'email'
    ),
  link: yup
    .string()
    .required('link')
    .matches(urlRegex, 'link')
    .transform((value) => (value === '' ? null : value)),
  phone: yup
    .string()
    .required('phone')
    .test('isValidPhoneNumber', 'phone', (value) => isValidPhoneNumber(value)),
});

export const schemaBroker = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'email'
    ),
  phone: yup
    .string()
    .required('phone')
    .test('isValidPhoneNumber', 'phone', (value) => isValidPhoneNumber(value)),
});

export const schemaManagerContactData = yup.object().shape({
  name: yup
    .string()
    .required('name')
    .test(
      'is-not-blank', 
      'name', 
      value => value?.trim().length > 0
    ),
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'email'
    ),
  phone: yup
    .string()
    .required('phone')
    .test('isValidPhoneNumber', 'phone', (value) => isValidPhoneNumber(value)),
});

export const schemaLogIn = yup.object({
  emailOrPhone: yup.string()
    .nullable()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email format is not correct'
    ),
  phoneNumber: yup.string()
    .nullable()
    .test(
      'isValidPhoneNumber',
      'Phone number is not valid',
      value => !value || isValidPhoneNumber(value)
    ),
  password: yup.string().required('Enter your password'),
  });
  // .test(
  //   'emailOrPhoneOrPhoneNumber',
  //   'Either email or phone number must be provided',
  //   function(value) {
  //     const { emailOrPhone, phoneNumber } = value;
  //     // Проверяем, что хотя бы одно поле заполнено корректно
  //     if (!emailOrPhone && (!phoneNumber || !isValidPhoneNumber(phoneNumber))) {
  //       return new ValidationError('Either email or phone number must be provided');
  //     }
  //     return true; // Всегда возвращаем true, если нет ошибки
  //   }
  // );