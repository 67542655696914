import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  MenuItem,
  Checkbox,
  Stack,
  Container,
  DialogContent,
  DialogActions,
  Link,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supportProvider } from '@/providers';
import classNames from 'classnames';

import { State, NotificationTypeEnum, UserKindEnum, UserTariffPlanEnum } from '@/types';
import { appSetNotification, authAddTariffPrice } from '@/store';

import { MyButton, MyDialog, Loader } from '@/components';
import { TariffBlock } from './TariffBlock';
import { StyleLabel } from '../uiComponents/styled';

export const DialogTariffPlan = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, tariff } = useSelector(({ auth }: State) => auth);
  const [namePlan, setNamePlan] = useState<string>('');
  const [yearPriceId, setYearPriceId] = useState<string>();
  const [monthPriceId, setMonthPriceId] = useState<string>();
  const [yearPriceAmount, setYearPriceAmount] = useState<string>();
  const [monthPriceAmount, setMonthPriceAmount] = useState<string>();
  const [teamsTariffPlan, setTeamsTariffPlan] = useState<string[]>([]);
  const [selectedTariffPlan, setSelectedTariffPlan] = useState<string | undefined>();
  const [openDialogSuccess, setOpenDialogSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const userKind = user.kind;
  const isBroker = userKind === UserKindEnum.Broker;
  const isManager = userKind === UserKindEnum.Manager;
  const isAgency = userKind === UserKindEnum.Agency;
  const userTariffPlan = user.tariffPlan?.name;
  const isTariffFree = userTariffPlan === UserTariffPlanEnum.Free;
  const isTariffTrial = userTariffPlan === UserTariffPlanEnum.Trial;

  const isFreeTariff = isTariffFree || isTariffTrial;

  useEffect(() => {
    if (tariff && tariff !== null && isFreeTariff) {
      if (isBroker || isManager) {
        const writeState = () => {
          const tariffPlan = tariff.map((el: any) => {
            const namePlan = el.name;
            const priceYear = el?.prices?.find((price: any) => price.interval === 'year');
            const yearPriceId = priceYear?.id;
            const yearPriceAmount = priceYear?.unitAmount;
            const priceMonth = el?.prices?.find(
              (price: any) => price.interval === 'day' || price.interval === 'month'
            );
            const monthPriceId = priceMonth?.id;
            const monthPriceAmount = priceMonth?.unitAmount;
            setNamePlan(namePlan);
            setYearPriceId(yearPriceId || '');
            setMonthPriceId(monthPriceId || '');
            setYearPriceAmount(yearPriceAmount || '0');
            setMonthPriceAmount(monthPriceAmount || '0');
          });
          return tariffPlan;
        };
        writeState();
      }
      if (isAgency) {
        const names = tariff?.map((el: any) => el.name);
        const teamsTariffPlan = tariff?.map((el: any) => el.description);
        const prices = tariff?.map((el: any) => el.prices);
        const priceYear = prices[0]?.find((price: any) => price.interval === 'year');
        const priceMonth = prices[0]?.find(
          (price: any) => price.interval === 'day' || price.interval === 'month'
        );
        const yearPriceId = priceYear?.id;
        const yearPriceAmount = priceYear?.unitAmount;
        const monthPriceId = priceMonth?.id;
        const monthPriceAmount = priceMonth?.unitAmount;
        setNamePlan(names[0]);
        setTeamsTariffPlan(teamsTariffPlan);
        setYearPriceId(yearPriceId || '');
        setMonthPriceId(monthPriceId || '');
        setYearPriceAmount(yearPriceAmount || '0');
        setMonthPriceAmount(monthPriceAmount || '0');
        setSelectedTariffPlan(teamsTariffPlan[0]);
      }
    }
  }, [tariff]);

  useEffect(() => {
    if (selectedTariffPlan) {
      const selectedPlan = tariff.find((el: any) => el.description === selectedTariffPlan);
      const namePlan = selectedPlan.name;
      const priceYear = selectedPlan?.prices?.find((price: any) => price.interval === 'year');
      const priceMonth = selectedPlan?.prices?.find(
        (price: any) => price.interval === 'day' || price.interval === 'month'
      );
      const yearPriceId = priceYear?.id;
      const yearPriceAmount = priceYear?.unitAmount;
      const monthPriceId = priceMonth?.id;
      const monthPriceAmount = priceMonth?.unitAmount;
      setNamePlan(namePlan);
      setYearPriceId(yearPriceId || '');
      setMonthPriceId(monthPriceId || '');
      setYearPriceAmount(yearPriceAmount || '0');
      setMonthPriceAmount(monthPriceAmount || '0');
    }
  }, [selectedTariffPlan]);

  const permissionsFreeTariffPlan = [
    'Add listing',
    'Updating listing',
    'Use favorites',
    'Use messaging',
    'Use filters',
  ];

  const permissions = [
    'Add listing',
    'Add client request',
    'View contact details',
    'Search by seller',
    'Updating listing',
    'Updating client request',
    'Save PDF',
    'Write comments',
    'View profiles of users',
    'Creates groups in messaging',
    'Notification of new client request',
    'View transactions of DLD',
    'Use filters',
    'View all photos',
    'View notification',
    'Use favorites',
    'Use messaging',
  ];

  const handleClick = (name: string) => {
    if (name === 'year') {
      dispatch(authAddTariffPrice(yearPriceId));
    } else {
      dispatch(authAddTariffPrice(monthPriceId));
    }
    handleClose();
    navigate('/payment');
  };

  const freeTariffPlan = {
    name: userTariffPlan,
    price: '0',
    interval: 'year/month',
    permissions: isTariffFree ? permissionsFreeTariffPlan : permissions,
    buttonName: 'Current',
  };

  const tariffPlan = [
    {
      name: namePlan,
      price: yearPriceAmount || '0',
      interval: 'year',
      permissions: permissions,
      handleClick: handleClick,
      buttonName: 'Upgrade',
    },
    {
      name: namePlan,
      price: monthPriceAmount || '0',
      interval: 'month',
      permissions: permissions,
      handleClick: handleClick,
      buttonName: 'Upgrade',
    },
  ];

  const handleClose = () => {
    setSelectedTariffPlan(teamsTariffPlan[0]);
    onClose();
  };

  const handleConfirmTariff = async () => {
    const dataSubmit = {
      name: user.name || '',
      email: user.email || undefined,
      phone: user.phone || undefined,
      text: 'The user, contacts above, left a request to organize a meeting with his agency, so that the latter would pay the subscription! Contact and arrange a meeting!',
    };
    if (dataSubmit) {
      setIsLoading(true);
      const { ok, message, status } = await supportProvider.support(dataSubmit);
      if (ok) {
        setOpenDialogSuccess(true);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MyDialog open={open} onClose={handleClose} dialogTitle='Unlock all features' width='100%'>
        <Container component='main'>
          <DialogContent
            sx={{ maxHeight: '100%', overflowY: 'auto', textAlign: 'center', margin: '0' }}
            data-qa={'1111'}
          >
            <Stack
              spacing={7}
              justifyContent='space-between'
              direction={{ xs: 'column', sm: 'row' }}
            >
              {freeTariffPlan.name && (
                <TariffBlock
                  name={freeTariffPlan.name}
                  price={freeTariffPlan.price}
                  interval={freeTariffPlan.interval}
                  permissions={freeTariffPlan.permissions}
                  buttonName={freeTariffPlan.buttonName}
                />
              )}
              <Stack
                spacing={{ sm: 1, xs: 6 }}
                justifyContent='space-between'
                direction={{ xs: 'column', sm: 'row' }}
              >
                {yearPriceAmount &&
                  tariffPlan.map((tariff) => (
                    <TariffBlock
                      key={tariff.interval}
                      name={tariff.name}
                      price={tariff.price}
                      interval={tariff.interval}
                      permissions={tariff.permissions}
                      handleClick={tariff.handleClick}
                      buttonName={tariff.buttonName}
                      selectedTariffPlan={selectedTariffPlan}
                      setSelectedTariffPlan={setSelectedTariffPlan}
                      teamsTariffPlan={teamsTariffPlan}
                    />
                  ))}
              </Stack>
            </Stack>
          </DialogContent>
          {!isAgency && (
            <Stack justifyContent='center'>
              <Link
                onClick={handleConfirmTariff}
                sx={{
                  textAlign: 'center',
                  color: '#2A3842',
                  textDecoration: 'none',
                  whiteSpace: 'normal',
                  cursor: 'pointer',
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                }}
              >
                <Typography>Let your agency take care of it! Schedule a meeting now.</Typography>
              </Link>
            </Stack>
          )}
        </Container>
      </MyDialog>
      <MyDialog
        open={openDialogSuccess}
        onClose={() => setOpenDialogSuccess(false)}
        dialogTitle='Last step'
        width='650'
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack direction='row' justifyContent='center' display='flex'>
            <img src='/Img/Pictures/operatorSuccess.svg' style={{ width: '230px' }} />
          </Stack>
          <StyleLabel style={{ textAlign: 'center' }}>
            Our support manager will contact your agency to pay for your subscription!
          </StyleLabel>

          <MyButton
            data={{
              buttonName: 'Okay',
              variant: 'contained',
              customWidth: '358px',
              styleType: 'dialog',
            }}
            onClick={() => setOpenDialogSuccess(false)}
          />
        </Stack>
      </MyDialog>
    </>
  );
};
