import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled, shouldForwardProp } from '@mui/system';
import { icons } from '../Icons/icons';
import UserAvatarInfo from './UserAvatarInfo';
import { mainData } from '../../utils/UserProfileSettingsDescription';
import { UserModel } from '@/types';
import { MyCommunicate, SectionHeader } from '@/components';
import styles from './MainInfo.module.scss';

const EditProfileButtonStyle = styled(Button)(({ theme }) => ({
  fontSize: '15px',
  width: '150px',
  height: '40px',
  textTransform: 'none',
  borderRadius: '14px',
  fontWeight: '500',
  backgroundColor: '#ffffff',
  color: '#2A3842',
  colorIcon: '#2A3842',
  '&:hover': {
    background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
    color: '#ffffff',
    colorIcon: '#ffffff',
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '40px',
  },
}));

const ButtonContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
});

interface MainInfoProps {
  user: UserModel;
  type?: 'view' | 'settings' | 'edit' | 'user';
  userType?: string;
  dataQa?: string;
}

const MainInfo: FC<MainInfoProps> = ({ user, type, userType, dataQa }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const isViewMode = type === 'view';
  const isSettingsMode = type === 'settings';
  const isCommunicate = type === 'user';
  const isEdit = type === 'edit';
  return (
    <>
      {isSettingsMode ? (
        <SectionHeader title='User profile settings' dataQa='settings'/>
      ) : (
        <div
          className={`${styles.mainInfoContainer} ${
            isEdit ? styles.mainInfoEditContainer : styles.mainInfoViewContainer
          }`}
          data-qa={`${dataQa}_header`}
        >
          <div className={isEdit ? styles.viewRow : styles.row}>
            <UserAvatarInfo
              user={user}
              userType={userType}
              type={type}
              avatarMarginTop={isEdit ? '34px' : undefined}
              data-qa={`${dataQa}_user`}
            />
            {isViewMode && (
              <ButtonContainer>
                <EditProfileButtonStyle
                  type='submit'
                  variant='contained'
                  startIcon={<icons.EditProfile />}
                  onClick={() => navigate('/profile/edit')}
                  data-qa={`${dataQa}_edit_btn`}
                >
                  Edit Profile
                </EditProfileButtonStyle>
              </ButtonContainer>
            )}
            <Stack sx={{ marginTop: isMobile ? '30px' : 0 }} data-qa={`${dataQa}_contacts_btn_block`}>
              {isCommunicate && <MyCommunicate user={user} dataQa={dataQa}/>}
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default MainInfo;
