export const modalWrapperStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `${x}`);

  return {
    minWidth: '350px',
    width: '100%',
    maxWidth: '700px',
    minHeight: '250px',
    height: '40%',
    maxHeight: '600px',
    overflow: 'hidden',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '4',
    margin: '0 auto',
    boxShadow: 'rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px',
    borderRadius: '14px',
    display: 'block',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      width: '100%',
      height: '100%',
    },
  };
};

export const modalCloseStyle = (img, context) => {
  return {
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '25px',
    right: '30px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.color.primary}`,
    cursor: 'pointer',
  };
};

export const modalBodyStyle = () => {
  return {
    height: '100%',
    padding: '24px 34px',
  };
};

export const modalCaptionStyle = ({ theme }, dir) => {
  const textAlignStyle =
    dir === 'rtl'
      ? {
          textAlign: 'right',
          paddingRight: '32px',
        }
      : {
          textAlign: 'left',
        };

  return {
    fontSize: '24px',
    marginBottom: '15px',
    fontWeight: 'bold',
    color: `${theme.color.primary}`,
    paddingBottom: '27px',
    ...textAlignStyle,
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      backgroundColor: `${theme.borderColor.primary}`,
      position: 'absolute',
      left: '0px',
      right: '0px',
      top: '85px',
    },
  };
};

export const modalErrorStyle = (context) => {
  return {
    fontSize: '12px',
    color: `${context.theme.color.red}`,
    textAlign: 'center',
    padding: '8px 0',
  };
};

export const modalListStyle = () => {
  return {
    height: 'calc(100% - 100px)',
  };
};

export const listStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '100%',
    overflowY: 'auto',
  };
};

export const contactMsgStyle = () => {
  return {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55%',
  };
};

export const contactMsgTxtStyle = (context) => {
  return {
    margin: '0',
    height: '30px',
    color: `${context.theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
  };
};
