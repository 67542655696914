import { FormInputNumeric, SectionTitle } from '@/components/uiComponents';
import { Box, useMediaQuery, useTheme } from '@mui/material';

export const AgencyFee = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <SectionTitle number={5} title='Agency fee' />
      <Box sx={{ maxWidth: isMobile ? '100%' : '230px' }}>
        <FormInputNumeric
          uiVariant='blank'
          name='sellingAgencyFeePercent'
          fullWidth
          suffix='%'
          label='Enter % of fee'
          placeholder='Enter your fee value'
          sx={{ width: '250px' }}
        />
      </Box>
    </Box>
  );
};
