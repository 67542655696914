import { ChangeEvent, useState } from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tabs,
  Tab,
  Button,
  Grid,
} from '@mui/material';
import classNames from 'classnames';

import {
  ActivityKindEnum,
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  PropertyKindEnum,
  RentPeriodEnum,
  FilterAddressType,
  ClientRequestFilteringType,
} from '@/types';
import { classifyPrice } from '@/utils';
import { MyButton, FormSelectCustom } from '@/components';

import { SelectItemButton } from './SelectItemButton';
import { DoubleButtonBlock } from './DoubleButtonBlock';
import styles from './RequestsFilters.module.scss';
import resetFilters from './resetFilters.svg';
import { ReactComponent as CaretDown } from './caretDown.svg';
import { useUserSettings } from '@/hooks/useUserSettings';
import {
  getDepthLabel,
  LocationAutocomplete,
} from '@/components/LocationAutocomplete/LocationAutocomplete';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';

export const RequestsFilters = ({
  filteringObj,
  onChangeFilters,
  handleClientReqBtnClick,
  handleMyReqBtnClick,
  btnClientRequest,
  isForeign,
}: {
  filteringObj: ClientRequestFilteringType;
  onChangeFilters: (value: ClientRequestFilteringType, shouldSendRequest?: boolean) => void;
  btnClientRequest: boolean;
  isForeign: boolean;
  handleClientReqBtnClick: () => void;
  handleMyReqBtnClick: () => void;
}) => {
  const { userSettings } = useUserSettings();
  const emptyFilteringObj: ClientRequestFilteringType = {
    address: [],
    typeOfActivity: undefined,
    period: undefined,
    propertyKind: undefined,
    areaFrom: undefined,
    areaTo: undefined,
    beds: undefined,
    baths: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    locationId: undefined,
    myRequests: !btnClientRequest,
  };
  const {
    address,
    typeOfActivity,
    period,
    propertyKind,
    areaFrom = 0,
    areaTo = 0,
    beds,
    baths,
    priceFrom = 0,
    priceTo = 0,
    locationId = undefined,
  } = filteringObj;

  const activityKindValues = [
    { label: 'Buy', value: ActivityKindEnum.Sale },
    { label: 'Rent', value: ActivityKindEnum.Rent },
  ];
  const rentFrequencyValues = [
    { label: 'Daily', value: RentPeriodEnum.Daily },
    { label: 'Weekly', value: RentPeriodEnum.Weekly },
    { label: 'Monthly', value: RentPeriodEnum.Monthly },
    { label: 'Yearly', value: RentPeriodEnum.Yearly },
  ];
  const propertyKindValues = [
    { label: 'Apartment', value: PropertyKindEnum.Apartment },
    { label: 'Townhouse', value: PropertyKindEnum.Townhouse },
    { label: 'Villa', value: PropertyKindEnum.Villa },
    { label: 'Floor', value: PropertyKindEnum.Floor },
    { label: 'Penthouse', value: PropertyKindEnum.Penthouse },
    { label: 'Residential Land', value: PropertyKindEnum.ResidentialLand },
    { label: 'Hotel Apartment', value: PropertyKindEnum.HotelApartment },
    { label: 'Whole Building', value: PropertyKindEnum.CommercialBuilding },
    { label: 'Commercial Villa', value: PropertyKindEnum.CommercialVilla },
    { label: 'Warehouse', value: PropertyKindEnum.Warehouse },
    { label: 'Industrial Land', value: PropertyKindEnum.IndustrialLand },
    { label: 'Commercial Land', value: PropertyKindEnum.CommercialLand },
    { label: 'Office', value: PropertyKindEnum.Office },
    { label: 'Shop', value: PropertyKindEnum.Shop },
  ];
  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];
  const bathsValues = [
    { label: '1', value: AdFieldBathsEnum.One },
    { label: '2', value: AdFieldBathsEnum.Two },
    { label: '3', value: AdFieldBathsEnum.Three },
    { label: '4', value: AdFieldBathsEnum.Four },
    { label: '5', value: AdFieldBathsEnum.Five },
    { label: '6', value: AdFieldBathsEnum.Six },
    { label: '7', value: AdFieldBathsEnum.Seven },
    { label: '8+', value: AdFieldBathsEnum.EightPlus },
  ];

  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);
  const {
    inputAddressValue,
    setInputAddressValue,
    selectedLocation,
    locations,
    setSelectedLocation,
  } = useLocationAutocomplete({
    defaultValue: address?.[0]?.nameEn || '',
  });

  const bedsLabel =
    beds === AdFieldBedsEnum.Studio
      ? 'Studio'
      : beds === AdFieldBedsEnum.EightPlus
        ? `${beds} Beds`
        : beds
          ? Number(beds) > 1
            ? `${beds} Beds`
            : `${beds} Bed`
          : '';

  const bathsLabel =
    baths === AdFieldBathsEnum.EightPlus
      ? `${baths} Baths`
      : baths
        ? Number(baths) > 1
          ? `${baths} Baths`
          : `${baths} Bath`
        : '';

  const bedsBathsText =
    !bedsLabel && !bathsLabel
      ? 'Beds & Baths'
      : bedsLabel && bathsLabel
        ? `${bedsLabel} / ${bathsLabel}`
        : bedsLabel || bathsLabel;

  const areaLabel =
    !areaFrom && !areaTo
      ? `Area (${userSettings?.areaUnits.toLowerCase()})`
      : `${areaFrom} - ${areaTo}`;

  const priceLabel =
    !priceFrom && !priceTo
      ? `Price (${userSettings?.currency})`
      : `${classifyPrice(priceFrom)} - ${classifyPrice(priceTo)}`;

  const handleChangeAreaMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaFrom: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangeAreaMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      areaTo: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMin = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceFrom: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };

  const handleChangePriceMax = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const parsedInt = parseInt(target.value);
    onChangeFilters({
      ...filteringObj,
      priceTo: isNaN(parsedInt) || parsedInt < 0 ? 0 : parsedInt,
    });
  };
  const handlePlaceChanged = (location: LocationModel | null) => {
    const addressObject: FilterAddressType = {
      coordinatesLat: String(location?.latitude),
      coordinatesLong: String(location?.longitude),
      nameEn: location?.[location.kind] ?? '',
      addressEn: location ? `${getDepthLabel(location)}, ${location[location.kind]}` : undefined,
    };
    setSelectedLocation(location);
    if (location?.[location?.kind]) {
      setInputAddressValue(location[location.kind] ?? '');
    }
    onChangeFilters(
      { ...filteringObj, locationId: location?.id, address: [{ ...addressObject }] },
      true
    );
  };

  const handleInputAddressClear = () => {
    setInputAddressValue('');
    setSelectedLocation(null);
    onChangeFilters({ ...filteringObj, address: [], locationId: undefined }, true);
  };

  const handleResetFilters = () => {
    setInputAddressValue('');
    onChangeFilters({ ...emptyFilteringObj }, true);
  };

  const handleSearchSubmit = () => {
    onChangeFilters(
      {
        ...filteringObj,
        address: [
          {
            nameEn: selectedLocation?.[selectedLocation?.kind] ?? locations[0]?.[locations[0].kind],
          },
        ],
        locationId: selectedLocation?.id ?? locations?.[0]?.id ?? undefined,
      },
      true
    );
  };

  const shouldBeShownResetFilters =
    !!address?.length ||
    typeOfActivity ||
    period ||
    propertyKind?.length ||
    !!areaFrom ||
    !!areaTo ||
    !!beds ||
    !!baths ||
    !!priceFrom ||
    !!priceTo ||
    !!locationId;

  const propertyKindValueItem = propertyKindValues.find((item) => item.value === propertyKind);
  const propertyKindValueLabel = propertyKindValueItem?.label || 'Property type';

  const rentFrequencyItem = rentFrequencyValues.find((item) => item.value === period);

  const activityKindValueItem = activityKindValues.find((item) => item.value === typeOfActivity);
  const activityKindValueLabel =
    typeOfActivity === ActivityKindEnum.Rent && !!period
      ? `${activityKindValueItem?.label} / ${rentFrequencyItem?.label}`
      : typeOfActivity === ActivityKindEnum.Rent
        ? activityKindValueItem?.label
        : typeOfActivity === ActivityKindEnum.Sale
          ? activityKindValueItem?.label
          : 'Type of activity';

  return (
    <div className={styles.filtersContainer}>
      <DoubleButtonBlock
        handleClientReqBtnClick={handleClientReqBtnClick}
        handleMyReqBtnClick={handleMyReqBtnClick}
        btnClientRequest={btnClientRequest}
      />
      <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
        <Grid item xs={9.6}>
          <LocationAutocomplete
            locations={locations}
            inputAddressValue={inputAddressValue}
            setInputAddressValue={setInputAddressValue}
            onSelect={handlePlaceChanged}
            handleClear={handleInputAddressClear}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Box
            sx={{
              width: {
                sm: '166px',
              },
            }}
          >
            <MyButton
              data={{
                buttonName: 'Search',
                styleType: 'button',
                variant: 'contained',
              }}
              onClick={handleSearchSubmit}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
        sx={{ marginTop: '4px' }}
      >
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <FormSelectCustom label='' value={activityKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <div className={classNames(styles.selectInnerContainer, styles.filterActivityKind)}>
                <div className={styles.selectInnerContent}>
                  {activityKindValues.map(({ label, value }) => (
                    <Button
                      key={value}
                      className={classNames({
                        [styles.selectButtons]: true,
                        [styles.activityKindButton]: true,
                        [styles.selectButtonsActive]: typeOfActivity === value,
                      })}
                      variant='contained'
                      onClick={() =>
                        onChangeFilters(
                          {
                            ...filteringObj,
                            typeOfActivity:
                              filteringObj.typeOfActivity === value ? undefined : value,
                          },
                          true
                        )
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </div>
                {typeOfActivity === ActivityKindEnum.Rent && (
                  <FormHelperText>
                    <span className={styles.selectInnerTitle}>Rent Frequency</span>
                  </FormHelperText>
                )}
                {typeOfActivity === ActivityKindEnum.Rent && (
                  <div className={styles.selectInnerContent}>
                    {rentFrequencyValues.map(({ label, value }) => (
                      <Button
                        key={value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.activityKindButton]: true,
                          [styles.selectButtonsActive]: period === value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              period: filteringObj.period === value ? undefined : value,
                            },
                            true
                          )
                        }
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </FormSelectCustom>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
            <div className={styles.floor}>
              <Tabs
                value={propertyTypeTab}
                onChange={(_, val) => setPropertyTypeTab(val)}
                aria-label='property type tabs'
                className={styles.tabsContainer}
              >
                <Tab
                  label='Residential'
                  id='property-type-tab-0'
                  aria-controls='property-type-tabpanel-0'
                  className={styles.tabItem}
                />
                <Tab
                  label='Commercial'
                  id='property-type-tab-1'
                  aria-controls='property-type-tabpanel-1'
                  className={styles.tabItem}
                />
              </Tabs>
              {propertyTypeTab === 0 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 0}
                  id='property-type-tabpanel-0'
                  aria-labelledby='property-type-tab-0'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[0, 1, 2, 3].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind === propertyKindValues[item].value
                                  ? undefined
                                  : propertyKindValues[item].value,
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[4, 5, 6, 7].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind === propertyKindValues[item].value
                                  ? undefined
                                  : propertyKindValues[item].value,
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              {propertyTypeTab === 1 && (
                <div
                  role='tabpanel'
                  hidden={propertyTypeTab !== 1}
                  id='property-type-tabpanel-1'
                  aria-labelledby='property-type-tab-1'
                  className={styles.buttonsContainer}
                >
                  <div>
                    {[8, 9, 10].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind === propertyKindValues[item].value
                                  ? undefined
                                  : propertyKindValues[item].value,
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    {[11, 12, 13].map((item) => (
                      <Button
                        key={propertyKindValues[item].value}
                        className={classNames({
                          [styles.selectButtons]: true,
                          [styles.propertyKindButton]: true,
                          [styles.selectButtonsActive]:
                            propertyKind === propertyKindValues[item].value,
                        })}
                        variant='contained'
                        onClick={() =>
                          onChangeFilters(
                            {
                              ...filteringObj,
                              propertyKind:
                                filteringObj.propertyKind === propertyKindValues[item].value
                                  ? undefined
                                  : propertyKindValues[item].value,
                            },
                            true
                          )
                        }
                      >
                        {propertyKindValues[item].label}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </FormSelectCustom>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {areaLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterArea)}>
              <div className={classNames(styles.selectInnerContent, styles.filterArea)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Minimum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaFrom}
                    onChange={handleChangeAreaMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>
                      Maximum {userSettings?.areaUnits.toLowerCase()}
                    </span>
                  </FormHelperText>
                  <TextField
                    value={areaTo}
                    onChange={handleChangeAreaMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={areaFrom > areaTo && areaTo !== 0}
                    helperText={
                      areaFrom > areaTo && areaTo !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {bedsBathsText}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Beds</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {bedsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={beds}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          beds:
                            filteringObj.beds === value ? undefined : (newValue as AdFieldBedsEnum),
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
              <FormHelperText>
                <span className={styles.selectInnerTitle}>Baths</span>
              </FormHelperText>
              <div className={styles.selectInnerContent}>
                {bathsValues.map(({ label, value }) => (
                  <SelectItemButton
                    key={value}
                    label={label}
                    value={value}
                    currentValue={baths}
                    onChange={(newValue) =>
                      onChangeFilters(
                        {
                          ...filteringObj,
                          baths:
                            filteringObj.baths === value
                              ? undefined
                              : (newValue as AdFieldBathsEnum),
                        },
                        true
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </Select>
        </Grid>
        <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
          <Select
            value={'-1'}
            className={styles.filterSelect}
            IconComponent={({ className }) => (
              <CaretDown
                className={classNames(styles.filterSelectCaret, {
                  [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                  [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                })}
              />
            )}
          >
            <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
              {priceLabel}
            </MenuItem>
            <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
              <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Minimum</span>
                  </FormHelperText>
                  <TextField
                    value={priceFrom}
                    onChange={handleChangePriceMin}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <FormHelperText>
                    <span className={styles.selectInnerLabel}>Maximum</span>
                  </FormHelperText>
                  <TextField
                    value={priceTo}
                    onChange={handleChangePriceMax}
                    onKeyDown={(e) => e.stopPropagation()}
                    error={priceFrom > priceTo && priceTo !== 0}
                    helperText={
                      priceFrom > priceTo && priceTo !== 0
                        ? 'max value should be more or equal than min value'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </Select>
        </Grid>
      </Grid>
      {shouldBeShownResetFilters && (
        <div className={styles.rowControls}>
          <div className={styles.buttonLink} onClick={handleResetFilters}>
            <img src={resetFilters} alt='Reset filters' />
            Reset filters
          </div>
        </div>
      )}
    </div>
  );
};
