export const modalRowStyle = ({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: `${theme.backgroundColor.lightBlue3}`,
    },
  };
};

export const userStyle = () => {
  return {
    display: 'flex',
    gap: '10px',
    width: 'calc(100% - 70px)',
    alignItems: 'center',
  };
};

export const avatarStyle = () => {
  return {
    width: '40px',
    height: '40px',
    flexShrink: '0',
  };
};

export const nameStyle = ({ theme }) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: '500',
    color: `${theme.color.primary}`,
  };
};

export const actionStyle = (img, { theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    i: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${theme.color.primary}`,
    },
  };
};
