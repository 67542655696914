import { useState, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Avatar,
  Link,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { commentsProvider } from '@/providers';
import { StyledButtonComment } from '../styled';
import { Section } from './Section';
import {
  NotificationTypeEnum,
  CommentModel,
  UserModel,
  HeaderDialogsEnum,
  UserTariffPlanEnum,
} from '@/types';
import { stringAvatar } from '@/utils';
import { icons } from '../Icons/icons';
import { appSetNotification } from '@/store';
import { WriteComment } from './WriteComment';
import { CommentMenu } from './CommentMenu';
import e from 'express';

export const CommentBlock = ({
  comment,
  setLike,
  setNewComment,
  setOpenCommentReportDialog,
  id,
  setIsVisibleAnswer,
  setButtonType,
  setIsReplay,
  isReplay,
  type,
  buttonType,
  parentCommentId,
  user,
  handleClickOpenDialog,
}: {
  comment: CommentModel;
  setLike: (like: string) => void;
  setNewComment: (comment: string) => void;
  setOpenCommentReportDialog: (open: boolean) => void;
  setIsVisibleAnswer: (open: boolean) => void;
  setIsReplay: (replay: boolean) => void;
  setButtonType: (type: string) => void;
  id: string;
  isReplay: boolean;
  type: string;
  buttonType?: string;
  parentCommentId?: string;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const [isVisibleAnswerReplies, setIsVisibleAnswerReplies] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [idComment, setIdComment] = useState('');
  const dispatch = useDispatch();
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClickMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isCheckedMyComment = user.id === comment.commentCreator.id;

  const capitalizeFirstLetter = (string: string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const onHandlerClickLike = async () => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    const { ok, message, status } = comment.isLiked
      ? await commentsProvider.unlikeComment(comment.id)
      : await commentsProvider.likeComment(comment.id);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setLike(`${!comment.isLiked}, ${comment.id}`);
    }
  };

  const onHandlerClickMessage = (typeMessage: string) => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    if (typeMessage === 'edit') {
      type === 'comment' ? setIsVisibleAnswer(true) : setIsVisibleAnswerReplies(true);
      setIdComment(comment.id);
    } else {
      if (type === 'comment') {
        setIsVisibleAnswer(true);
        setIdComment(comment.id);
      } else {
        setIsVisibleAnswerReplies(true);
        setIdComment(parentCommentId!);
      }
    }
    setButtonType(typeMessage);
  };

  const onHandlerClickDelete = async () => {
    if (isTariffFree) {
      return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    }
    const { ok, message, status } = await commentsProvider.deleteComment(id, comment.id);
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      setNewComment(`Deleted ${comment.id}`);
    }
  };

  return (
    <>
      <Section>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Link
            href={isTariffFree ? undefined : isCheckedMyComment ? `/profile/view` : `/users/${comment.commentCreator.id}`}
            title={comment.commentCreator.name}
            sx={{ textDecoration: 'none' }}
            onClick={() => isTariffFree && handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              <Avatar
                src={comment.commentCreator.avatar || undefined}
                alt={comment.commentCreator.name}
                {...stringAvatar(comment.commentCreator.name)}
              />
              <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#2A3842' }}>
                {capitalizeFirstLetter(comment.commentCreator.name)}
              </Typography>
            </Stack>
          </Link>
          <Typography sx={{ whiteSpace: 'pre-wrap', opacity: '50%', fontSize: '14px' }}>
            {moment(comment.updatedAt).format('DD MMMM, YYYY,  h:mm a')}
          </Typography>
        </Stack>

        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{comment.message}</Typography>

        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          justifyContent={isMobile ? 'space-between' : 'flex-start'}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            {!isCheckedMyComment && (
              <StyledButtonComment
                startIcon={<icons.Answer />}
                onClick={() => onHandlerClickMessage('answer')}
              >
                Answer
              </StyledButtonComment>
            )}
            {comment.replyCount > 0 && type === 'comment' && (
              <StyledButtonComment
                startIcon={<icons.Replay />}
                onClick={() => setIsReplay(!isReplay)}
              >
                {comment.replyCount}
              </StyledButtonComment>
            )}
            <Stack direction='row' alignItems='center'>
              <IconButton aria-label='delete' onClick={onHandlerClickLike}>
                {comment.isLiked ? <icons.Like /> : <icons.Unlike />}
              </IconButton>
              {comment.likeCount > 0 && (
                <Typography sx={{ marginRight: '5px' }}>{comment.likeCount}</Typography>
              )}
            </Stack>
          </Stack>

          {isMobile ? (
            <StyledButtonComment startIcon={<icons.More />} onClick={handleClickMenu}>
              More
            </StyledButtonComment>
          ) : (
            <Stack direction='row' alignItems='center' spacing={2}>
              {isCheckedMyComment && (
                <StyledButtonComment startIcon={<icons.Delete />} onClick={onHandlerClickDelete}>
                  Delete
                </StyledButtonComment>
              )}
              {isCheckedMyComment && (
                <StyledButtonComment
                  startIcon={<icons.EditComment />}
                  onClick={() => onHandlerClickMessage('edit')}
                >
                  Edit
                </StyledButtonComment>
              )}
              <StyledButtonComment
                startIcon={<icons.Report />}
                onClick={() =>
                  isTariffFree
                    ? handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)
                    : setOpenCommentReportDialog(true)
                }
              >
                Report
              </StyledButtonComment>
            </Stack>
          )}
        </Stack>
      </Section>
      <CommentMenu
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        isCheckedMyComment={isCheckedMyComment}
        onHandlerClickDelete={onHandlerClickDelete}
        onHandlerClickMessage={onHandlerClickMessage}
        setOpenCommentReportDialog={setOpenCommentReportDialog}
      />
      {isVisibleAnswerReplies && buttonType && (
        <WriteComment
          id={id}
          setNewComment={setNewComment}
          type={buttonType}
          setIsVisibleAnswer={setIsVisibleAnswerReplies}
          message={comment.message}
          idComment={idComment}
          user={user}
          handleClickOpenDialog={handleClickOpenDialog}
        />
      )}
    </>
  );
};
