import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, Theme } from '@mui/material/styles';

export const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      background: {
        default: '#F9FCFF',
      },
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // '--TextField-brandBorderColor': '#E9EEF1',
            // '--TextField-brandBorderHoverColor': '#E9EEF1',
            // '--TextField-brandBorderFocusedColor': '#2A3842',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '&.Mui-disabled': {
              backgroundColor: '#F8F8F8',
            },
            borderRadius: '14px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: '14px',
            border: '1px solid #E9EEF1',
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #E9EEF1',
            },
          },
          root: {
            borderRadius: '14px',
            fontSize: '15px',
            fontFamily: "'Poppins', sans-serif",
            backgroundColor: '#FFF',
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #E9EEF1',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #2A3842',
            },
            '&.Mui-disabled': {
              backgroundColor: '#F8F8F8',
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                border: '1px solid #E9EEF1',
              },
            },
            [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #E9EEF1',
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#FF0000',
            },
            input: {
              padding: '13px 20px',
              height: '22px',
            },
            [`& div.MuiSelect-select`]: {
              padding: '12px 20px',
              height: '22px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // borderRadius: '0 0 14px 14px',
            borderRadius: '14px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            '& input[type=checkbox] + *': {
              borderRadius: '7px',
              width: 32,
              height: 32,
              border: '2px solid #E9EEF1',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            '& input + *': {
              width: 32,
              height: 32,
              backgroundColor: '#FFF',
            },
            width: '52px',
            height: '52px',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            '& input + *': {
              width: 32,
              height: 32,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            color: 'var(--primary-dark-blue, #2A3842)',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 400,
            margin: '0 0 5px 0',
            [`&.Mui-error`]: {
              color: 'var(--secondary, #F00)',
              fontSize: '12px !important',
              fontStyle: 'normal',
              textAlign: 'right',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            [`&.MuiTextField-root`]: {
              marginBottom: 0,
            },
            // textarea: {
            //   minHeight: '224px',
            // },
          },
        },
      },
      /*
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: '1px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '1px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '1px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '1px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      */
    },
  });
