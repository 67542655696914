export const convertToJPG = (file: File): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const targetWidth = 800;
      const targetHeight = 600;

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Canvas context could not be created'));
        return;
      }

      let newWidth;
      let newHeight;
      let offsetX = 0;
      let offsetY = 0;

      const imageAspectRatio = image.width / image.height;
      const targetAspectRatio = targetWidth / targetHeight;

      if (imageAspectRatio > targetAspectRatio) {
        newHeight = image.height;
        newWidth = image.height * targetAspectRatio;
        offsetX = (image.width - newWidth) / 2;
      } else {
        newWidth = image.width;
        newHeight = image.width / targetAspectRatio;
        offsetY = (image.height - newHeight) / 2;
      }

      ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight, 0, 0, targetWidth, targetHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const jpgFile = new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          resolve(jpgFile);
        } else {
          reject(new Error('Failed to convert and crop image to JPEG'));
        }
      }, 'image/jpeg');
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    image.src = URL.createObjectURL(file);
  });
};

export const addWatermark = (file: File, watermarkSrc: string): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const watermark = new Image();

    image.onload = () => {
      watermark.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Canvas context could not be created'));
          return;
        }

        ctx.drawImage(image, 0, 0);
        // Позиционирование и размер водяного знака
        const watermarkX = (canvas.width - watermark.width) / 2;
        const watermarkY = (canvas.height - watermark.height) / 2;
        ctx.drawImage(watermark, watermarkX, watermarkY, watermark.width, watermark.height);

        canvas.toBlob((blob) => {
          if (blob) {
            const watermarkedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(watermarkedFile);
          } else {
            reject(new Error('Failed to add watermark'));
          }
        }, 'image/jpeg');
      };

      watermark.onerror = () => {
        reject(new Error('Failed to load watermark image'));
      };

      watermark.src = watermarkSrc;
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    image.src = URL.createObjectURL(file);
  });
};

export const convertToPNG = (file: File): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const targetWidth = 800;
      const targetHeight = 600;

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Canvas context could not be created'));
        return;
      }

      let newWidth;
      let newHeight;
      let offsetX = 0;
      let offsetY = 0;

      const imageAspectRatio = image.width / image.height;
      const targetAspectRatio = targetWidth / targetHeight;

      if (imageAspectRatio > targetAspectRatio) {
        newHeight = image.height;
        newWidth = image.height * targetAspectRatio;
        offsetX = (image.width - newWidth) / 2;
      } else {
        newWidth = image.width;
        newHeight = image.width / targetAspectRatio;
        offsetY = (image.height - newHeight) / 2;
      }

      ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight, 0, 0, targetWidth, targetHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const pngFile = new File([blob], file.name, {
            type: 'image/png',
            lastModified: Date.now(),
          });
          resolve(pngFile);
        } else {
          reject(new Error('Failed to convert and crop image to PNG'));
        }
      }, 'image/png');
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    image.src = URL.createObjectURL(file);
  });
};

export const convertToWEBP = (file: File): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const targetWidth = 800;
      const targetHeight = 600;

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Canvas context could not be created'));
        return;
      }

      let newWidth;
      let newHeight;
      let offsetX = 0;
      let offsetY = 0;

      const imageAspectRatio = image.width / image.height;
      const targetAspectRatio = targetWidth / targetHeight;

      if (imageAspectRatio > targetAspectRatio) {
        newHeight = image.height;
        newWidth = image.height * targetAspectRatio;
        offsetX = (image.width - newWidth) / 2;
      } else {
        newWidth = image.width;
        newHeight = image.width / targetAspectRatio;
        offsetY = (image.height - newHeight) / 2;
      }

      // Center and draw the image into the canvas
      ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight, 0, 0, targetWidth, targetHeight);

      // Use 'image/webp' instead of 'image/png'
      canvas.toBlob((blob) => {
        if (blob) {
          const webpFile = new File([blob], file.name.replace(/\.[^/.]+$/, "") + ".webp", {
            type: 'image/webp',
            lastModified: Date.now(),
          });
          resolve(webpFile);
        } else {
          reject(new Error('Failed to convert and crop image to WEBP'));
        }
      }, 'image/webp');
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    image.src = URL.createObjectURL(file);
  });
};
