import { useEffect, useState, SyntheticEvent } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import {
  AdsFilteringType,
  AdModel,
  PaginationType,
  AdsSortingType,
  SortingOrderEnum,
  ActivityKindEnum,
  PropertyKindEnum,
  AdFieldBedsEnum,
  AdFieldBathsEnum,
  AdsGetAllResponseType,
  AdFieldStatusEnum,
  AdsGetTotalsResponseType,
  NotificationTypeEnum,
  RentPeriodEnum,
  TransactionTypeEnum,
  State,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
} from '@/types';
import { setAddressParamsInFilteringObj } from '@/utils';
import { adsProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { useChangeQueryParams } from '@/hooks';
import { Loader, Pagination, MyDialog, MyButton } from '@/components';
import { MyPropertyFilters } from './MyPropertyFilters';
import { EmptyList } from './EmptyList';
import { AdsMyListItem } from './AdsMyListItem';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { schemaAdForm } from '@/schemas';
import { AdsListSelect } from './AdsListSelect/AdsListSelect';
import styles from './AdsMyProperty.module.scss';
import { COUNT_ADS_PER_PAGE } from './constants';
import { useUserSettings } from '@/hooks/useUserSettings';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

interface TotalCountsType {
  published: number;
  unpublished: number;
  archived: number;
}

interface AdsProps {
  isRent?: boolean;
}

export const AdsMyProperty = ({ isRent }: AdsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openDialog, setOpenDialog } = useDialogs();
  const { user } = useSelector(({ auth }: State) => auth);
  const changeQueryParams = useChangeQueryParams('ads/my');
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [isLoadingGetMyTotals, setIsLoadingGetMyTotals] = useState<boolean>(false);
  const [isLoadingGetAds, setIsLoadingGetAds] = useState<boolean>(true);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  const [lastPages, setLastPages] = useState({
    published: '1',
    unpublished: '1',
    archived: '1',
  });

  const [ads, setAds] = useState<AdModel[]>();

  const [changeStatus, setChangeStatus] = useState<string>();

  const [filteringObj, setFilteringObj] = useState<AdsFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [changedFilter, setChangeFilter] = useState<boolean>(false);
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const sortingFields = ['date', /* 'popular', */ 'price' /*'fee'*/];
  const [totals, setTotals] = useState<TotalCountsType>({
    published: 0,
    unpublished: 0,
    archived: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAds, setSelectedAds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [openMyDialog, setOpenMyDialog] = useState<boolean>(false);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [isShownPublishedDialog, setIsShownPublishedDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [countSelected, setCountSelected] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(COUNT_ADS_PER_PAGE);

  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const {
    locations,
    setLocations,
    minimizedLocations,
    setMinimizedLocations,
    selectedLocation,
    ...locationProps
  } = useLocationAutocomplete({ defaultValue: '' });

  const getMyTotals = async () => {
    setIsLoadingGetMyTotals(true);
    const { ok, data: responseData, status, message } = await adsProvider.getMyTotals();
    if (ok) {
      const data = responseData as AdsGetTotalsResponseType;
      setTotals(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetMyTotals(false);
  };

  const { userSettings } = useUserSettings();

  const getAds = async () => {
    const address =
      queryParams.addressParamLat && queryParams.addressParamLong
        ? [
            {
              addressEn: queryParams.addressParamAddress as string,
              coordinatesLat: queryParams.addressParamLat as string,
              coordinatesLong: queryParams.addressParamLong as string,
              nameEn: queryParams.addressParamName as string,
              placeId: queryParams.addressParamPlaceId as string,
            },
          ]
        : [];

    const newFilteringObj: AdsFilteringType = {
      //! read URL params
      address,
      activityKind: queryParams.activityKind as ActivityKindEnum,
      transactionType: queryParams.transactionType as TransactionTypeEnum,
      rentFrequency: queryParams.rentFrequency as RentPeriodEnum,
      propertyKind: queryParams.propertyKind
        ? [queryParams.propertyKind as PropertyKindEnum]
        : undefined,
      areaMin: parseInt(queryParams.areaMin as string) || undefined,
      areaMax: parseInt(queryParams.areaMax as string) || undefined,
      beds: queryParams.beds as AdFieldBedsEnum,
      baths: queryParams.baths as AdFieldBathsEnum,
      priceMin: parseInt(queryParams.priceMin as string) || undefined,
      priceMax: parseInt(queryParams.priceMax as string) || undefined,
      locationId: (queryParams.locationId as string) || undefined,
      status: (queryParams.status as AdFieldStatusEnum) || AdFieldStatusEnum.Published,
    };

    const newSortingObj = {
      field: (queryParams.field as string) || sortingFields[0],
      order: (queryParams.order as SortingOrderEnum) || SortingOrderEnum.Desc,
    };

    const newOffset = queryParams.page
      ? (parseInt(queryParams.page as string) - 1) * rowsPerPage
      : 0;
    const newPaginationObj = {
      page: parseInt(queryParams.page as string) || 1,
      offset: newOffset || 0,
      limit: rowsPerPage,
      total: 0,
    };

    setIsLoadingGetAds(true);
    const isMyAds = true;
    const { ok, data, status, message } = await adsProvider.getAds(
      isMyAds,
      newFilteringObj,
      newSortingObj,
      newPaginationObj
    );
    if (ok) {
      const { items, total, locations: minimizedLocations } = data as AdsGetAllResponseType;
      if (items.length === 0 && newPaginationObj.page > 1) {
        const newPage =
          parseInt(queryParams.page as string) - 1 !== 0
            ? parseInt(queryParams.page as string) - 1
            : 1;
        handleChangePage(newPage);
      } else {
        setAds(items);
        setFilteringObj({ ...newFilteringObj });
        setSortingObj({ ...newSortingObj });
        setPaginationObj({
          ...newPaginationObj,
          total,
        });
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoadingGetAds(false);
  };

  useEffect(() => {
    getMyTotals();
  }, []);

  useEffect(() => {
    getAds();
  }, [queryParamsStr, changeStatus, rowsPerPage]);

  useEffect(() => {
    if (selectedAds.length > 0) {
      setCountSelected(selectedAds.length);
    } else {
      setCountSelected(null);
    }
    if (ads && ads?.length > 0 && selectedAds.length === ads?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedAds]);

  const handleChangeFilter = (newFilteringObj: AdsFilteringType, shouldSendRequest?: boolean) => {
    //! to URL
    // console.log(newFilteringObj);
    if (newFilteringObj.activityKind === ActivityKindEnum.Rent) {
      newFilteringObj.completionStatus = undefined;
    } else if (newFilteringObj.activityKind === ActivityKindEnum.Sale) {
      newFilteringObj.rentFrequency = undefined;
    } else {
      newFilteringObj.completionStatus = undefined;
      newFilteringObj.rentFrequency = undefined;
      newFilteringObj.activityKind = undefined;
    }

    if (!newFilteringObj.propertyKind?.length) {
      newFilteringObj.propertyKind = undefined;
    }

    setFilteringObj(newFilteringObj);
    if (shouldSendRequest) {
      changeQueryParams({
        ...queryParams,
        ...setAddressParamsInFilteringObj(newFilteringObj),
        page: 1,
      });
    }
    setChangeFilter(true);
  };

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
    setSelectedAds([]);
    setSelectAll(false);
  };

  const handleChangeTab = (_: SyntheticEvent, newStatus: AdFieldStatusEnum) => {
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as AdFieldStatusEnum]: page });
    setSelectAll(false);
    setSelectedAds([]);
    return changeQueryParams({
      ...queryParams,
      page: lastPages[newStatus],
      status: newStatus,
    });
  };

  const handleChangeStatus = async (id: string, newStatus: AdFieldStatusEnum, index?: number) => {
    const performUpdate = async (newData: Partial<AdModel>) => {
      const { ok, status, message } = await adsProvider.updateAdById(id, newData as AdModel);
      if (ok) {
        if (!index || index + 1 === selectedAds.length) {
          setChangeStatus(newStatus + id);
          getMyTotals();
          // const newAds = (ads as AdModel[]).filter((item) => item.id !== id);
          // setAds(newAds);
        }
        setIsLoadingChangeStatus(false);
        return { status: true, id: id };
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setIsLoadingChangeStatus(false);
        return { status: false, id: id };
      }
    };

    setIsLoadingChangeStatus(true);
    if (newStatus === AdFieldStatusEnum.Published) {
      const validateData = async (data: AdModel) => {
        try {
          await schemaAdForm.validate(data, { abortEarly: false });
          return { status: true, id: id };
        } catch (error) {
          return { status: false, id: id };
        }
      };

      const { ok, data, status, message } = await adsProvider.getAdById(id);
      if (!ok) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        setIsLoadingChangeStatus(false);
        return { status: false, id: id };
      }

      const newData: AdModel = {
        ...data,
        status: newStatus,
        publishedAt: new Date().toISOString(),
      };
      const isValid = await validateData(newData);

      if (
        (!isValid.status && !selectedAds.length) ||
        (!isValid.status && selectedAds.length === 1)
      ) {
        setIsLoadingChangeStatus(false);
        navigate(`/ads/edit/${id}`);
        return { status: false, id: id };
      }
      if (!isValid.status) {
        setIsLoadingChangeStatus(false);
        return { status: false, id: id };
      }
      if (!index) {
        return performUpdate(newData);
      }
      return { status: true, id: id };
    } else {
      return performUpdate({ status: newStatus });
    }
  };

  const handleChangeStatusList = async (newStatus: AdFieldStatusEnum) => {
    if (newStatus !== AdFieldStatusEnum.Published) {
      const { ok, status, message } = await adsProvider.massUpdateAdById(selectedAds, {
        status: newStatus,
      });
      if (ok) {
        getAds();
        getMyTotals();
        setSelectedAds([]);
        setSelectAll(false);
        setIsLoadingChangeStatus(false);
      }
      return;
    }
    let successCount = 0;
    let errorCount = 0;
    const validAdIds: string[] = [];

    const promises = selectedAds.map(async (id, index) => {
      const result = await handleChangeStatus(id, newStatus, index);
      if (result?.status) {
        successCount += 1;
        validAdIds.push(result.id);
      } else {
        errorCount += 1;
      }
      return result;
    });

    await Promise.all(promises);

    const validAds: AdModel[] = validAdIds
      .map((id) => (ads as AdModel[]).find((ad) => ad.id === id))
      .filter((ad): ad is AdModel => ad !== undefined);

    if (validAds.length > 0) {
      await adsProvider.updateAdStatus({
        list: [{ ...validAds[0], status: newStatus }, ...validAds],
      });
    }

    await getAds();
    await getMyTotals();
    setSelectedAds([]);
    setSelectAll(false);
    setIsLoadingChangeStatus(false);

    if (newStatus === AdFieldStatusEnum.Published) {
      // if (user.tariffPlan?.name === UserTariffPlanEnum.Free) {
      //   return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
      // }
      setSuccessCount(successCount);
      setErrorCount(errorCount);
      setOpenMyDialog(true);
    }
  };

  const handleChangeList = async (newData: Partial<AdModel>) => {
    setIsLoading(true);
    const { ok, status, message } = await adsProvider.massUpdateAdById(selectedAds, newData);
    if (ok) {
      getAds();
      getMyTotals();
      setSelectedAds([]);
      setSelectAll(false);
    }
    setIsLoading(false);
  };

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const handleUpdateList = async () => {
    // if (user.tariffPlan?.name === UserTariffPlanEnum.Free) {
    //   return handleClickOpenDialog?.(HeaderDialogsEnum.Tariff);
    // }
    const newData = { updatedAt: new Date().toISOString() };
    handleChangeList(newData);
  };

  const handleAssign = (id: string) => {
    const newData = { creatorId: id };
    handleChangeList(newData);
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedAds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedAds([]);
    } else {
      setSelectedAds((ads as AdModel[]).map((ad) => ad.id));
    }
    setSelectAll(!selectAll);
  };

  const handleClosePublishedDialog = () => setIsShownPublishedDialog(false);
  const handleDonePublishedDialog = () => {
    setIsShownPublishedDialog(false);
  };

  if (isLoadingGetMyTotals || isLoadingChangeStatus || isLoadingGetAds) {
    return <Loader />;
  }

  return (
    <div className={styles.adsContainer}>
      {filteringObj && (
        <MyPropertyFilters
          filteringObj={filteringObj}
          onChangeFilters={handleChangeFilter}
          locations={locations}
          setLocations={setLocations}
          selectedLocation={selectedLocation}
          setChangeFilter={setChangeFilter}
          userSettings={userSettings}
          {...locationProps}
        />
      )}

      <div className={styles.templateMyAdsContainer}>
        <Box className={styles.tabsContainer}>
          <Tabs
            value={filteringObj?.status || AdFieldStatusEnum.Published}
            onChange={handleChangeTab}
            aria-label='my ads tabs'
            className={styles.tabsInner}
          >
            <Tab
              id='tab-published'
              aria-controls='tabpanel-published'
              label={`Active${totals.published > 0 ? ` (${totals.published})` : ''}`}
              value={AdFieldStatusEnum.Published}
              className={styles.tabItem}
            />
            <Tab
              id='tab-unpublished'
              aria-controls='tabpanel-unpublished'
              label={`Draft${totals.unpublished > 0 ? ` (${totals.unpublished})` : ''}`}
              value={AdFieldStatusEnum.Unpublished}
              className={styles.tabItem}
            />
            <Tab
              id='tab-archived'
              aria-controls='tabpanel-archived'
              label={`Archived${totals.archived > 0 ? ` (${totals.archived})` : ''}`}
              value={AdFieldStatusEnum.Archived}
              className={styles.tabItem}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                },
              }}
            />
          </Tabs>
        </Box>
        {!ads?.length ? (
          <EmptyList
            changedFilter={changedFilter}
            description='No search results. Change the search criteria to display advertisements.'
          />
        ) : (
          <>
            <AdsListSelect
              filteringObj={filteringObj}
              selectedAds={selectedAds}
              selectAll={selectAll}
              handleSelectAllChange={handleSelectAllChange}
              handleUpdateList={handleUpdateList}
              handleChangeStatusList={handleChangeStatusList}
              countSelected={countSelected}
              handleAssign={handleAssign}
            />
            <div className={styles.myAdsList}>
              {(ads as AdModel[]).map((item) => (
                <AdsMyListItem
                  key={item.id}
                  item={item}
                  selectedAds={selectedAds}
                  onChangeStatus={handleChangeStatus}
                  filteringObj={filteringObj}
                  setChangeStatus={setChangeStatus}
                  handleCheckboxChange={handleCheckboxChange}
                  user={user}
                  handleClickOpenDialog={handleClickOpenDialog}
                />
              ))}
            </div>
          </>
        )}
        {!!paginationObj && Number(paginationObj?.total) > Number(rowsPerPage) ? (
          <Pagination
            paginationObj={paginationObj}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
          />
        ) : null}
      </div>

      <MyDialog
        open={openMyDialog}
        onClose={() => setOpenMyDialog(false)}
        dialogTitle='Result'
        width='750'
      >
        <Typography sx={{ fontSize: '18px', textAlign: 'center', marginBottom: '10px' }}>
          <strong style={{ fontSize: '20px', color: '#1650FF' }}>{successCount}</strong> listings
          have been published.
        </Typography>
        {errorCount > 0 && (
          <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
            <strong style={{ fontSize: '20px', color: 'red' }}>{errorCount}</strong> listings have
            not been published because they are incomplete. <br />
            Please go to each listing and fill in the required fields.`
          </Typography>
        )}
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '351px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => setOpenMyDialog(false)}
          />
        </div>
      </MyDialog>
      <MyDialog
        open={isShownPublishedDialog}
        onClose={handleClosePublishedDialog}
        dialogTitle='Your list has been successfully published'
        width='750'
      >
        <div className={styles.dialogDescription}>
          Your listing <strong style={{ color: '#1650FF' }}>{`"${title}"`}</strong> has been
          successfully posted in the system and is available for other users to view. Your listing
          will be published for a <strong style={{ color: '#1650FF' }}>period of 30 days. </strong>
          You can update it through the{' '}
          <strong style={{ color: '#1650FF' }}>{`"My properties"`}</strong> section by clicking the{' '}
          <strong style={{ color: '#1650FF' }}>{`"Update"`}</strong> button.
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '351px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleDonePublishedDialog}
          />
        </div>
      </MyDialog>
    </div>
  );
};
