import { IconButton } from '@mui/material';
import { FacebookIcon, InstagramIcon, LinkedInIcon } from '@/components/Footer/Icons';

export const SocialSection = () => (
  <section
    style={{
      display: 'flex',
      gap: '18px',
      alignItems: 'center',
    }}
  >
    <IconButton
      style={{ backgroundColor: '#1E293B', padding: '10px' }}
      component='a'
      href='https://www.linkedin.com/company/onlineproperty/'
      target='_blank'
    >
      <LinkedInIcon style={{ color: '#FFF' }} />
    </IconButton>
    <IconButton
      style={{ backgroundColor: '#1E293B', padding: '10px' }}
      component='a'
      href='https://www.facebook.com/onlineproperty.io'
      target='_blank'
    >
      <FacebookIcon style={{ color: '#FFF' }} />
    </IconButton>
    <IconButton
      style={{ backgroundColor: '#1E293B', padding: '10px' }}
      component='a'
      href='https://www.instagram.com/onlineproperty.io/'
      target='_blank'
    >
      <InstagramIcon style={{ color: '#FFF' }} />
    </IconButton>
  </section>
);
