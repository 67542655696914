import { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import styles from './FormSelect.module.scss';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { CompletionStatusEnum, ActivityKindEnum } from '@/types';

interface DataList {
  label: string;
  value: any;
}

export const DumbFormSelect = ({
  data,
  completionStatus,
  isMoreFiltersVisible,
  handleChange,
  placeholder,
  defaultSelectedValue,
  activityKindValueLabel,
  disabled = false,
  ...props
}: {
  data: DataList[];
  handleChange?: (value: any) => void;
  placeholder?: string;
  defaultSelectedValue?: string | boolean;
  activityKindValueLabel?: string;
  disabled?: boolean;
  completionStatus?: CompletionStatusEnum;
  isMoreFiltersVisible?: boolean;
} & SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<any>(defaultSelectedValue || '');
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  useEffect(() => {
    if (
      activityKindValueLabel !== ActivityKindEnum.Sale ||
      completionStatus !== CompletionStatusEnum.OffPlan
    ) {
      setSelectedValue('');
    }
  }, [activityKindValueLabel, completionStatus]);

  const handleSelection = (e: any, index: number) => {
    const value = e.target.value as string;
    if (handleChange) {
      if (value === selectedValue) {
        // console.log('11111111111');
        setSelectedValue('');
        setSelectedItemIndex(null);
        handleChange('');
      } else {
        // console.log('22222222222222');
        // console.log(value, selectedValue);
        setSelectedValue(value);
        setSelectedItemIndex(index);
        handleChange(value);
      }
    }
  };
  // console.log(data, placeholder, defaultSelectedValue, activityKindValueLabel);
  return (
    <Select
      {...props}
      displayEmpty
      disabled={disabled}
      fullWidth
      sx={{
        borderRadius: '14px',
        border: `1px solid #E9EEF1`,
        width: '100%',
      }}
      renderValue={(selected) => {
        // console.log(selected);
        if (selected === '' || selected === undefined) {
          return placeholder;
        }
        const item = data.find((item) => item.value === selected);
        return item ? item.label : placeholder;
      }}
      className={styles.fieldset}
      IconComponent={CaretDownComponent}
      onChange={(e) => handleSelection(e, -1)}
      value={selectedValue}
    >
      <MenuItem disabled value=''>
        {placeholder}
      </MenuItem>

      {data.map(({ value, label }: DataList, index: number) => (
        <MenuItem
          divider={data.length - 1 !== index}
          sx={{
            fontFamily: "'Poppins', sans-serif",
            textTransform: 'capitalize',
            backgroundColor: selectedItemIndex === index ? '#c5dfff' : 'inherit',
          }}
          key={index}
          value={value}
          onClick={(e) => handleSelection(e, index)}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

const CaretDownComponent = (props: any) => {
  const rotate = props.className.includes('iconOpen');
  return (
    <div
      className={styles.caretDown}
      style={{
        transform: rotate ? 'rotate(180deg)' : 'none',
      }}
    >
      <CaretDown />
    </div>
  );
};
