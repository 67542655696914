import { useState } from 'react';
import { IconButton, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { AdModel } from '@/types';
import { Section } from '@/components/Ad/Section';
import { TooltipComponent } from '@/components';

import {
  TopUpContainer,
  TopUpLabel,
  TopUpConditions,
  CheckedIcon,
} from './styled';

export const CommissionBlock = ({
  page,
}: {
  page: AdModel;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <Section>
      {page.topUp && (
        <TopUpContainer>
          <TopUpLabel>
            <Checkbox checkedIcon={<CheckedIcon />} icon={<span />} checked={true} />
            Top up
            <TooltipComponent tooltip='Price boost opportunity' />
            {page.topUpConditions && (
              <IconButton
                onClick={() => setIsExpanded(!isExpanded)}
                aria-label='expand'
                size='small'
              >
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TopUpLabel>
          {page.topUpConditions && (
            <TopUpConditions in={isExpanded}>{page.topUpConditions}</TopUpConditions>
          )}
        </TopUpContainer>
      )}
    </Section>
  );
};
