import queryString from 'query-string';
import {
  ResponseType,
  AdsFilteringType,
  AdsSortingType,
  PaginationType,
  AdsGetAllResponseType,
  IOffPlanModel,
  AdModelStatus,
  AdsGetTotalsResponseType,
  PriceType,
  IMedia,
  IUnitsImportPayload,
  IUnitPatchProps,
} from '@/types';
import { apiClient } from '@/providers';
import { TOrganizedMedia } from '@/types/offplans';
import { IPatchUnitProps, IUnitState } from '@/components/UnitsView/ModalDialogs/ModalDialogs.types';

export const offPlanProvider = {
  getAds: async (
    isMyAds: boolean,
    filteringObj: AdsFilteringType,
    sortingObj: AdsSortingType,
    paginationObj: PaginationType
  ): Promise<ResponseType> => {
    const response = await apiClient(`/newConstruction${isMyAds ? '/me' : ''}/search`, 'POST', {
      limit: paginationObj.limit,
      offset: paginationObj.offset,
      sort: [{ ...sortingObj }],
      filter: [{ ...filteringObj }],
    });
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetAllResponseType;
    return { ...response, data };
  },

  getAdById: async (id: string) => {
    const response = await apiClient<IOffPlanModel>(`/newConstruction/${id}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data;
    return { ...response, data };
  },

  createMdeia: async (payload: TOrganizedMedia): Promise<ResponseType> => {
    const response = await apiClient('/media', 'POST', (payload as any));

    return { ...response, data: response?.data || null };
  },
  postUnitImport: async (payload: IUnitsImportPayload): Promise<ResponseType> => {
    const response = await apiClient('/newConstruction/unit/import', 'POST', (payload as any));

    return { ...response, data: response?.data || null };
  },

  patchUnit: async (props: IPatchUnitProps) => { // IPatchUnitProps
    const { id, ...rest } = props;
    const response = await apiClient(`/newConstruction/unit/${id}`, 'PATCH', (rest as any));

    return { ...response, data: response?.data || null };
  },

  deleteUnitsBatch: async(ids: string[]) => {
    const response = await apiClient(`/newConstruction/unit/batch`, 'DELETE', { ids } as any);

    return { ...response, data: response?.data || null };
  },

  updateById: async (
    id: string,
    payload: IOffPlanModel | AdModelStatus
  ): Promise<ResponseType> => {
    const response = await apiClient(`/newConstruction/${id}`, 'PATCH', payload);

    return { ...response, data: response?.data || null };
  },

  create: async (payload: IOffPlanModel): Promise<ResponseType> => {
    const response = await apiClient('/newConstruction', 'POST', payload);

    return { ...response, data: response?.data || null };
  },

  getMyTotals: async (): Promise<ResponseType> => {
    const response = await apiClient('/newConstruction/me/count', 'POST');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetTotalsResponseType;
    return { ...response, data };
  },

  getPriceById: async (id: string): Promise<ResponseType> => {
    const limit = 50;
    const offset = 0;
    const paramsStr = queryString.stringify({ limit, offset });

    const response = await apiClient(`/newConstruction/${id}/prices?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as PriceType;
    return { ...response, data };
  },
};
