import { useState, useEffect } from 'react';
import { useFormContext, UseFormClearErrors } from 'react-hook-form';
import { Button, Typography, FormHelperText } from '@mui/material';
import { AdFieldCurrencyEnum, CompletionStatusEnum, AdFormModel } from '@/types';
import { FormInputNumeric, FormSelect, FormSelectCustom } from '@/components';

import classnames from 'classnames';
import styles from './PropertyPrice.module.scss';

const generateHandoverUsingQuartersAndYears = ({
  handover,
  quarter,
  year,
}: {
  handover?: string | null;
  quarter?: string | null;
  year?: string | null;
}) => {
  if (!quarter && !year) return '';
  if (quarter && !year) return `${quarter?.trim()}`;
  if (!quarter && year) return `${year?.trim()}`;
  return `${quarter?.trim()}/${year?.trim()}`;
};

export const PropertyPriceResale = () => {
  const currencyValues = [
    { label: 'AED', value: AdFieldCurrencyEnum.AED },
    { label: 'USD', value: AdFieldCurrencyEnum.USD },
  ];
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();

  const price = watch('price');

  const [yearsValues, setYearsValues] = useState<string[]>([]);
  const [handover, setHandover] = useState<string>('');
  const [quarter, setQuarter] = useState<string | null | undefined>('');
  const [year, setYear] = useState<string | null | undefined>('');
  const [toSeller, setToSeller] = useState<number | string | null>();
  const [toDeveloper, setToDeveloper] = useState<number | string | null>(null);
  const [currentQuarter, setCurrentQuarter] = useState<number>(0);
  const [currentYear, setCurrentYear] = useState<string>();
  const [postHandover, setPostHandover] = useState<boolean | undefined>()

  const postHandoverValues = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const quarterValues = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
  ];

  useEffect(() => {
    const values = getValues();
    setToSeller(values.toSeller);
    setToDeveloper(values.toDeveloper);
    const yearStart = new Date().getFullYear();
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentQuarterNum = Math.floor(currentMonth / 3) + 1;
    const currentQuarterString = `Q${Math.floor(currentMonth / 3) + 1}`;
    const years = Array.from({ length: 10 }, (_, i) => (yearStart + i).toString());
    setYearsValues(years);
    setCurrentQuarter(currentQuarterNum);
    setCurrentYear(yearStart.toString());
    const handoverValue = values?.handover?.quarter && values?.handover?.year ? `${values?.handover?.quarter}/${values?.handover?.year}` : '';
    setHandover(handoverValue);
    setYear(values?.handover?.year);
    setQuarter(values?.handover?.quarter);
  }, []);

  useEffect(() => {
    if (errors['defaultRentFrequency'] && Object.keys(errors).length === 1) {
      const container = document.querySelector(`.${styles.rentFrequencyContainer}`);
      if (container instanceof HTMLElement) {
        container.focus();
        setTimeout(() => {
          container.focus();
        }, 100);
      }
    }
  }, [errors]);

  useEffect(() => {
    if (quarter && year) {
      const value = { quarter: quarter, year: year };
      setValue('handover', value);
    }
  }, [handover]);

  const handleChangePrice = (newValue: number) => {
    const value = newValue || 0;
    if (!newValue || value === 0) {
      setValue('toSeller', 0);
      setToSeller(0);
      setToDeveloper(0);
    } else if (value <= Number(toSeller) && value !== 0) {
      setValue('toSeller', value);
      setToSeller(value);
      setToDeveloper(0);
    } else {
      setToDeveloper(value - Number(toSeller));
    }
  };

  const handleChangeQuarters = (value: string) => {
    const newValue = value === quarter ? undefined : value;
    setQuarter(newValue);
    setHandover(generateHandoverUsingQuartersAndYears({ handover, quarter: newValue, year: year }));
  };

  const handleChangeYears = (value: string) => {
    const newValue = value === year ? undefined : value;
    if (quarter && newValue === currentYear && Number(quarter.replace(/\D/g, '')) < currentQuarter) {
      setQuarter(`Q${currentQuarter}`);
      setHandover(
        generateHandoverUsingQuartersAndYears({
          handover,
          quarter: `Q${currentQuarter}`,
          year: newValue,
        })
      );
    } else {
      setHandover(generateHandoverUsingQuartersAndYears({ handover, quarter: quarter, year: newValue }));
    }
    setYear(newValue);
  };

  const handleChangeToSeller = (value?: number) => {
    setValue('toSeller', value);
    setToSeller(value);
    const newToDeveloper = Number(price) - Number(value);
    setToDeveloper(newToDeveloper);
    clearErrors(['toSeller']);
    setValue('toDeveloper', newToDeveloper);
  };

  return (
    <div className={styles.container}>
      <div>
        <Typography sx={{ fontSize: '18px', fontWeight: 800, marginBottom: '10px' }}>
          Price
        </Typography>
        <div className={styles.rowSeller}>
          <div className={styles.currency}>
            <FormSelect
              uiVariant='blank'
              name='currency'
              data={currencyValues}
              defaultValue={currencyValues[0].value}
              label='Currency'
              required
            />
          </div>
          <div className={styles.priceResale}>
            <FormInputNumeric
              uiVariant='blank'
              name='price'
              fullWidth
              placeholder='Price'
              label='Price'
              required
              handleChange={handleChangePrice}
            />
          </div>

          <div className={styles.allHandover}>
            <FormInputNumeric
              uiVariant='blank'
              name='toSeller'
              fullWidth
              label='To Seller'
              placeholder='To Seller'
              required
              readOnly={price && price > 0 ? false : true}
              limiter={price || 0}
              handleChange={(value) => handleChangeToSeller(value)}
              defaultValue={Number(toSeller)}
            />
          </div>
          <div className={styles.allHandover}>
            <FormInputNumeric
              uiVariant='blank'
              name='toDeveloper'
              fullWidth
              label='To Developer'
              placeholder='To Developer'
              readOnly={true}
              defaultValue={Number(toDeveloper)}
            />
          </div>
          <div className={styles.allHandover}>
            <FormSelect
              uiVariant='blank'
              name='postHandover'
              data={postHandoverValues}
              // required
              label='Post handover'
              defaultValue={postHandover}
              handleChange={(value) => setValue('postHandover', value as boolean)}
            />
          </div>
          <div className={styles.onlyHandover}>
            <FormSelectCustom label='Handover' value={handover}>
              <div className={styles.handover}>
                <FormHelperText>
                  <span className={styles.handoverHeading}>Quarters</span>
                </FormHelperText>
                <div style={{ display: 'flex' }}>
                  {quarterValues.length > 0 &&
                    quarterValues?.map(({ value, label }, index) => (
                      <Button
                        disabled={index + 1 < currentQuarter && year === currentYear}
                        key={index}
                        className={classnames({
                          [styles.selectButtons]: true,
                          [styles.bedsBathsButton]: true,
                          [styles.selectButtonsActive]: quarter === value,
                        })}
                        onClick={() => handleChangeQuarters(value)}
                        variant='contained'
                      >
                        {label}
                      </Button>
                    ))}
                </div>
                <FormHelperText>
                  <span className={styles.handoverHeading}>Years</span>
                </FormHelperText>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '15px' }}>
                  {yearsValues.length > 0 &&
                    yearsValues.map((value, index) => {
                      return (
                        <Button
                          key={index}
                          className={classnames({
                            [styles.selectButtons]: true,
                            [styles.bedsBathsButton]: true,
                            [styles.selectButtonsActive]: value.toString() === year?.toString(),
                          })}
                          style={{ flexBasis: 'auto', maxWidth: 'calc(100% / 6 - 10px)' }}
                          onClick={() => handleChangeYears(value)}
                          variant='contained'
                        >
                          {value}
                        </Button>
                      );
                    })}
                </div>
              </div>
            </FormSelectCustom>
          </div>
        </div>
      </div>
    </div>
  );
};
