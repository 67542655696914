export const badgeStyle = (props) => {
  return {
    display: 'block',
    fontSize: '14px',
    width: 'auto',
    height: '23px',
    borderRadius: '20px',
    background: `${props.theme.backgroundColor.blueGradient}`,
    color: `${props.theme.color.white}`,
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '18px',
    marginLeft: '4px',
    padding: '3px 8px',
    marginRight: '2px',
    opacity: '1',
    transition: 'opacity .1s',
  };
};
