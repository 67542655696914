import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { icons } from '../Profile/Icons/icons';

import { IMainData, ISectionData } from './types';

const mainData: IMainData = {
  label: 'User profile settings',
  spacing: true,
  width: '100%',
};

const authenticationData: ISectionData = {
  groupSizes: [3],
  data: [
    {
      name: 'passwordSettings',
      label: 'Password',
      isRequired: true,
      customWidth: '635px',
      placeholder: '*************',
      disabled: true,
      //endAdornment: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'password',
      buttonName: 'Change password',
      buttonsIcon: true,
      icon: <icons.EditProfile />,
      colorIcons: '#ffffff',
      variant: 'contained',
      customWidth: '230px',
      actionKey: 'passwordAction',
      typeOfComponent: 'button',
    },
  ],
};

const authorizationData: ISectionData = {
  sectionName: {
    number: 1,
    name: 'authenticationData',
    label: 'Authentication Data',
  },
  groupSizes: [3],
  data: [
    {
      name: 'email',
      label: 'Email',
      isRequired: true,
      customWidth: '395px',
      placeholder: 'Enter your email',
      maxLength: 80,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      name: 'phone',
      label: 'Phone',
      isRequired: true,
      customWidth: '230px',
      placeholder: 'Enter your phone number',
      maxLength: 80,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      name: 'authorization',
      buttonName: 'Change email/phone',
      buttonsIcon: true,
      icon: <icons.EditProfile />,
      colorIcons: '#ffffff',
      variant: 'contained',
      customWidth: '230px',
      actionKey: 'authorizationAction',
      typeOfComponent: 'button',
    },
  ],
};

const verificationData: ISectionData = {
  sectionName: { number: 2, name: 'verification', label: 'Verification' },
  groupSizes: [3],
  data: [
    {
      name: 'verificationStatus',
      label: 'Verification status',
      placeholder: 'Verified as Agent',
      customWidth: '200px',
      disabled: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'verification',
      label: 'Verification',
      buttonName: 'Re-Verification',
      buttonsIcon: true,
      icon: <VerifiedOutlinedIcon />,
      color: 'primary',
      variant: 'contained',
      iconInfo: true,
      customWidth: '197px',
      background: true,
      opacity: 1,
      actionKey: 'verificationAction',
      tooltip:
        'Re-verification allows you to update your account type. Click the button to change your role, e.g., from manager to agent, or other options.',
      typeOfComponent: 'button',
    },
  ],
};

const viewSettingsData: ISectionData = {
  sectionName: { number: 2, name: 'viewSettings', label: 'View settings' },
  groupSizes: [2],
  data: [
    {
      name: 'currency',
      label: 'Currency',
      options: ['AED', 'USD'],
      customWidth: '200px',
      typeOfComponent: 'select',
    },
    {
      name: 'areaUnits',
      label: 'Area',
      options: ['SQFT', 'SQMT'],
      customWidth: '200px',
      typeOfComponent: 'select',
    },
  ],
};

const viewSettingsManagerData: ISectionData = {
  sectionName: { number: 3, name: 'viewSettings', label: 'View settings' },
  groupSizes: [2],
  data: [
    {
      name: 'currency',
      label: 'Currency',
      options: ['AED', 'USD'],
      customWidth: '200px',
      typeOfComponent: 'select',
    },
    {
      name: 'areaUnits',
      label: 'Area',
      options: ['SQFT', 'SQMT'],
      customWidth: '200px',
      typeOfComponent: 'select',
    },
  ],
};

const tariffSettingsData: ISectionData = {
  sectionName: {
    number: 3,
    name: 'tariffData',
    label: 'Tariff settings',
  },
  groupSizes: [2],
  data: [
    {
      name: 'tariff',
      label: 'My tariff',
      customWidth: '635px',
      placeholder: 'Enter your tariff',
      maxLength: 80,
      disabled: true,
      typeOfComponent: 'input',
    },
    {
      name: 'cancelTariff',
      buttonName: 'Cancel tariff',
      variant: 'outlined',
      customWidth: '230px',
      actionKey: 'cancelTariff',
      styleType: 'cancel',
      typeOfComponent: 'button',
    },
  ],
};

const actions: ISectionData = {
  groupSizes: [2],
  data: [
    {
      name: 'save',
      buttonName: 'Save',
      color: 'primary',
      variant: 'contained',
      customWidth: '230px',
      buttonType: 'submit',
      actionKey: 'saveAction',
      typeOfComponent: 'button',
    },
    {
      name: 'cancel',
      buttonName: 'Cancel',
      color: 'primary',
      variant: 'outlined',
      customWidth: '230px',
      styleType: 'cancel',
      actionKey: 'cancelAction',
      typeOfComponent: 'button',
    },
  ],
};

export {
  mainData,
  authenticationData,
  authorizationData,
  verificationData,
  viewSettingsData,
  viewSettingsManagerData,
  actions,
  tariffSettingsData,
};
