/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { InputBase, List, ListItem, Box } from '@mui/material';

interface SearchResult {
  id: string;
  name: string;
}

interface IFormSelectWithFetchProps {
  cbSearchBy: (str: string) => Promise<any>;
  inputPlaceholder: string;
  handleChange?: (value: any) => void;
  defaultData?: any;
  isMyAgency?: boolean;
  changeMyAgency?: (value: boolean) => void;
  dataQa?: string;
}

export const FormSelectWithFetch = (props: IFormSelectWithFetchProps) => {
  const {
    cbSearchBy,
    inputPlaceholder,
    handleChange,
    defaultData,
    dataQa,
    isMyAgency,
    changeMyAgency,
  } = props;
  const [curData, setCurData] = useState<any>(defaultData);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const resetCreator = {
    id: undefined,
    name: undefined,
    kind: undefined,
  };

  useEffect(() => {
    if (isMyAgency) {
      setSearchTerm('');
      setSearchResult('');
      handleChange?.(resetCreator);
    }
  }, [isMyAgency]);

  useEffect(() => {
    if (!isMyAgency) {
      setCurData(defaultData);
      setSearchTerm(defaultData.name);
      setSearchResult(defaultData.name);
    }
  }, [defaultData]);

  useEffect(() => {
    if (!isMyAgency && searchTerm !== searchResult) {
      searchCreators(searchTerm);
    }
  }, [searchTerm]);

  const searchCreators = async (searchTerm: string) => {
    if (searchTerm?.length > 2) {
      const res = await cbSearchBy(searchTerm);
      if (res) {
        setSearchResults(res);
      }
    } else if (searchTerm?.length === 0) {
      setCurData(undefined);
      setSearchResults([]);
    }
  };

  const handleSelection = (result: any) => {
    if (result.name === curData?.name) {
      if (handleChange) handleChange(undefined);
    } else {
      if (handleChange) {
        handleChange(result);
        setSearchTerm(result.name);
        setSearchResult(result.name);
      }
    }
    setCurData(result);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length === 0) {
      handleChange?.(resetCreator);
    }
    changeMyAgency?.(false);
  };

  return (
    <Box
      sx={{ minWidth: '300px', padding: '8px' }}
      data-qa={dataQa}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <InputBase
        placeholder={inputPlaceholder}
        value={searchTerm}
        onChange={handleSearchChange}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onMouseDown={(event) => event.stopPropagation()}
        fullWidth
        sx={{ border: '1px solid rgb(233, 238, 241)', borderRadius: '9px', padding: '5px' }}
      />
      {searchTerm !== searchResult && searchResults?.length > 0 && (
        <List sx={{ maxHeight: '200px', overflow: 'auto' }} data-qa={`${dataQa}_list`}>
          {searchResults.map((result) => (
            <ListItem
              key={result.id}
              button
              onClick={() => handleSelection(result)}
              data-qa={`${dataQa}_${result.id}`}
            >
              {result.name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
