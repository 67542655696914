import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { teamProvider, usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { State, NotificationTypeEnum, TeamGetMyRequestResponseType, UserModel } from '@/types';
import WaitImage from '@/components/Dashboard/img/wait.svg';
import { ReactComponent as Pushpin } from '@/components/Dashboard/img/pushpin.svg';
import { Loader } from '@/components';

export function ManagerWithoutEmployer() {
  const [employer, setEmployer] = useState('');
  const [employerEmail, setEmployerEmail] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user = useSelector(({ auth }: State) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const getAgencyValue = async () => {
      setIsLoading(true);
      const {
        ok: okRequest,
        data: dataRequest,
        status: statusRequest,
        message: messageRequest,
      } = await teamProvider.getMyRequest();
      if (!okRequest) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, messageRequest, statusRequest));
        return;
      }
      const request = (dataRequest as TeamGetMyRequestResponseType)?.items?.[0]?.employer;
      if (request) {
        setEmployer(request.name);
        const { ok, data, status, message } = await usersProvider.getUserById(request.id);
        if (ok && data) {
          setEmployerEmail((data && data.stateLicense && data?.stateLicense?.email) || '');
        } else {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        }
      }
      setIsLoading(false);
    };
    if (user) {
      getAgencyValue();
    }
  }, [user]);

  return (
    <>
      {isLoading && <Loader />}
      <section style={{ alignSelf: 'center', margin: '0 auto' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '980px',
          }}
        >
          <Box>
            <img
              style={{ width: '100%', maxWidth: '520px', objectFit: 'fill' }}
              src={WaitImage}
              alt='wait image'
            />
          </Box>
          <Stack
            spacing={1}
            sx={{
              maxWidth: '430px',
              textAlign: 'left',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h4'
              sx={{
                textTransform: {
                  xs: 'uppercase',
                  sm: 'none',
                },
                fontSize: {
                  xs: '20px',
                  sm: '32px',
                },
                fontWeight: '700',
              }}
            >
              Dear
              <span style={{ color: '#1650FF' }}> {user.name}</span>
            </Typography>

            <Typography
              variant='body1'
              sx={{
                fontSize: 14,
              }}
            >
              We appreciate your registration as an agent representing
              <span style={{ color: '#1650FF', fontWeight: 600 }}> {employer} </span>
              Agency in our system.
            </Typography>

            <Typography
              variant='body1'
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              To fully activate your account and continue your work, please follow one of the
              following steps:
            </Typography>

            <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
              <Stack sx={{ width: '20px' }}>
                <Pushpin style={{ width: '20px', height: '20px' }} />
              </Stack>
              <Typography
                variant='body1'
                sx={{
                  fontSize: 14,
                }}
              >
                Register your
                <span style={{ color: '#1650FF', fontWeight: 600 }}> {employer} </span>
                Agency within our system and confirm your role as a manager.
              </Typography>
            </Stack>

            <Divider>
              <span style={{ color: '#1650FF', fontWeight: 600 }}> or </span>
            </Divider>

            <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
              <Stack sx={{ width: '20px' }}>
                <Pushpin style={{ width: '20px', height: '20px' }} />
              </Stack>
              <Typography
                variant='body1'
                sx={{
                  fontSize: 14,
                }}
              >
                Respond to our email sent to your official email address:
                <span style={{ color: '#1650FF', fontWeight: 600 }}> {employerEmail}, </span>
                confirming your managerial position within
                <span style={{ color: '#1650FF', fontWeight: 600 }}> {employer} </span>
                Agency. Our support team will approve your account shortly.
                <br />
                <span style={{ fontWeight: 600 }}>Our email:</span>
                <span style={{ color: '#1650FF', fontWeight: 600 }}> info@onlineproperty.io </span>
              </Typography>
            </Stack>

            <Typography
              variant='body1'
              sx={{
                fontSize: 14,
              }}
            >
              Warm regards,
              <span style={{ color: '#1650FF', fontWeight: 600 }}> Online Property Team</span>
            </Typography>
          </Stack>
        </Stack>
      </section>
    </>
  );
}
