import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DndFileUploader, MyButton, SectionHeader } from '@/components/uiComponents';
import Box from '@mui/material/Box';
import UnitUploadTable from './UnitAdminUploadTable';
import { offPlanProvider } from '@/providers';
import { IUnitsImportPayload, NotificationTypeEnum } from '@/types';
import { appSetNotification } from '@/store';
import { useDispatch } from 'react-redux';

export const UnitsAdmin = () => {
  const [units, setUnits] = useState<any[]>([]);
  const { id: newConstructionId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  
  const navigate = useNavigate()

  const handleItemUploaded = (data: any) => {
    if (data?.items?.length > 0) {
      setUnits(data.items);
    }
  };

  const handlePublishClick = async () => {
    if (newConstructionId) {
      const payload: IUnitsImportPayload = {
        newConstructionId,
        units,
      }

      try {
        await offPlanProvider.postUnitImport(payload)
        dispatch(appSetNotification(NotificationTypeEnum.Success, `${units.length} were loaded successfully to #${newConstructionId}` ));
      } catch (error) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, "An error occurred while loading units"));
      } finally {
        setUnits([]);
        navigate(-1);
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        alignItems: 'center',
        margin: '48px',
        minWidth: '950px',
      }}
    >
      <SectionHeader title='Data preview' />
      <DndFileUploader
        url={`${process.env.REACT_APP_API_URL}/newConstruction/unit/upload`}
        method='POST'
        type='file'
        labelButton='Upload File'
        labelFormat='Only XLSX format supported'
        labelSize='Maximum file size 10 MB'
        accept={{
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        }}
        onUpload={handleItemUploaded}
        limit={1}
        uploadFileVarNameForFetch={'xlsFile'}
        bodyData={{
          newConstructionId: newConstructionId,
        }}
      />
      {(units?.length > 0 && newConstructionId) && (
        <>
          <UnitUploadTable units={units}/>
          <Box sx={{ display: 'flex', gap: '16px', alignSelf: 'flex-start' }}>
            <MyButton
              data={{
                buttonName: 'Publish',
                customWidth: '160px',
                variant: 'contained',
                buttonType: 'button',
                sx: { height: '40px' },
              }}
              onClick={handlePublishClick}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '160px',
                variant: 'outlined',
                styleType: 'cancel',
                buttonType: 'button',
                sx: { height: '40px' },
              }}
              onClick={() => {
                navigate(-1)
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
