import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './MediaItem.module.scss';
import { ReactComponent as DeleteIcon } from './trash.svg';

interface Image {
  url: string;
  position: number;
  id: string;
  remove?: (index: number) => void;
  setCover?: (index: number) => void;
  covering?: boolean;
}

export const MediaItem = ({ url, position, id, remove, setCover, covering }: Image) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={styles.container}
      style={{
        backgroundImage: `url("${url}")`,
        transform: covering ? CSS.Transform.toString(transform) : '',
        width: remove ? '315px' : '285px',
        height: remove ? '190px' : '170px',
        margin: remove ? '0 16px 16px 0' : '0 0 20px 0',
      }}
      id={id}
    >
      <div className={styles.position}>{position}</div>
      <div className={styles.buttons}>
        {covering && position !== 1 && (
          <div className={styles.cover} onMouseDown={() => setCover && setCover(position)}>
            Cover
          </div>
        )}
        <div className={styles.delete} onMouseDown={() => remove && remove(position)}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};
