import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { AgencyType } from '../types/types';

interface Props {
  licenses: AgencyType[];
  onRowClickHandler: (id: string) => void;
}

const AgencyLicenseTable: React.FC<Props> = ({ licenses, onRowClickHandler }) => {
  const StyledTableCell = styled(TableCell)({
    border: 0,
    borderRight: '1px solid #E9EEF1',
    '&:last-child': {
      borderRight: 'none',
    },
    verticalAlign: 'middle',
  });

  const StyledTableContainer = styled(TableContainer)({
    background: 'transparent',
    boxShadow: 'none',
  });

  const StyledTableRow = styled(TableRow)({
    border: '1px solid #E9EEF1',
    background: 'inherit',
  });

  const StyledTableHead = styled(TableHead)({
    background: '#fff',
  });

  const formatDate = (value: string | null) =>
    value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell>Agency State Lic. ID</TableCell>
            <TableCell>State ID</TableCell>
            <TableCell>Licence ID</TableCell>
            <TableCell>Name Eng</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Lic. Start</TableCell>
            <TableCell>Lic. End</TableCell>
            <TableCell>Manual</TableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {licenses.map((license, index) => (
            <StyledTableRow key={index} onClick={() => onRowClickHandler(license.id)}>
              <StyledTableCell>{license.id}</StyledTableCell>
              <StyledTableCell>{license.stateId}</StyledTableCell>
              <StyledTableCell>{license.licenseId}</StyledTableCell>
              <StyledTableCell>{license.nameEn}</StyledTableCell>
              <StyledTableCell>{license.phone}</StyledTableCell>
              <StyledTableCell>{license.email}</StyledTableCell>
              <StyledTableCell>{formatDate(license.licenseStart)}</StyledTableCell>
              <StyledTableCell>{formatDate(license.licenseEnd)}</StyledTableCell>
              <StyledTableCell>{license.manual ? 'manual' : ''}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default AgencyLicenseTable;
