import { Box, Tabs, Tab } from '@mui/material';
import {
  State,
  PaginationType,
  FriendsGetResponse,
  FriendsModel,
  NotificationTypeEnum,
  FriendsFilteringType,
  AdsSortingType,
  SortingOrderEnum,
} from '@/types';
import styles from './Friends.module.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { EmptyList } from './EmptyList';
import { FriendItem } from './FriendItem';
import { friendsProvider } from '@/providers';
import { COUNT_FRIENDS_PER_PAGE, SEPARATOR } from '@/components/Ads/constants';
import { useChangeQueryParams } from '@/hooks';
import queryString from 'query-string';
import { appSetNotification } from '@/store';
import { TabPage } from '../Team/TabPage';
import { Loader, MyDialog, MyButton } from '@/components';

export const Friends = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: State) => auth);
  const [friends, setFriends] = useState<FriendsModel[]>([]);
  const [totalFriends, setTotalFriends] = useState<number>(0);
  const [isDialogTerminateOpened, setIsDialogTerminateOpened] = useState<string>('');
  const changeQueryParams = useChangeQueryParams('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [filteringObj, setFilteringObj] = useState<FriendsFilteringType>();
  const [sortingObj, setSortingObj] = useState<AdsSortingType>();
  const [paginationObj, sePaginationObj] = useState<PaginationType>({
    page: 1,
    limit: COUNT_FRIENDS_PER_PAGE,
    offset: 0,
    total: 0,
  });

  const [activeTab, setActiveTab] = useState<number>(0);
  const [newStatus, setNewStatus] = useState<string>('accepted');

  const getFriends = async (newPage?: number, newOffset?: number) => {
    const newPaginationObj = {
      ...paginationObj,
      page: newPage || paginationObj.page,
      offset: newPage && newOffset !== undefined ? newOffset : paginationObj.offset,
    };
    const newFilteringObj: FriendsFilteringType = {
      ...filteringObj,
      status: newStatus,
    };
    const newSortingObj: AdsSortingType[] = [
      {
        field: 'acceptedAt',
        order: SortingOrderEnum.Asc,
      },
    ];
    setIsLoading(true);
    const { ok, data, status, message } = await friendsProvider.getFriends(
      newFilteringObj,
      newSortingObj,
      newPaginationObj
    );
    if (ok) {
      const { list, total } = data as FriendsGetResponse;
      setFriends(list);
      setTotalFriends(total);
      sePaginationObj({
        ...newPaginationObj,
        total,
      });
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleChangePage = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    changeQueryParams({
      ...queryParams,
      page,
    });
  };

  useEffect(() => {
    getFriends();
  }, [newStatus]);

  const handleCloseDialogTerminate = () => setIsDialogTerminateOpened('');

  const handleRemoveFriend = async (id: string) => {
    if (!friends?.length) {
      return;
    }
    setIsLoading(true);
    const { ok, status, message } = await friendsProvider.removeFriends(id);
    if (ok) {
      await getFriends();
      handleCloseDialogTerminate();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const handleAcceptFriend = async (id: string) => {
    setIsLoading(true);
    const { ok, status, message } = await friendsProvider.acceptFriendsRequest(id);
    if (ok) {
      await getFriends();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Box className={styles.title}>
        <span className={styles.text}>Friends</span>
      </Box>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue: number) => {
            setActiveTab(newValue);
            if (newValue === 0) {
              setNewStatus('accepted');
            } else {
              setNewStatus('pending');
            }
          }}
          aria-label='members requests tabs'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-friends'
            aria-controls='tabpanel-friends'
            label={`My friends`}
            className={styles.tabItem}
          />
          <Tab
            id='tab-requests'
            aria-controls='tabpanel-requests'
            label={`Friend requests`}
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      <TabPage value={activeTab} index={0}>
        {!friends.length ? (
          <EmptyList description='You still don’t have any friends? You can send a friend request directly from another user’s profile and start building your network today.' />
        ) : (
          <div className={styles.list}>
            {friends.map((friend: FriendsModel) => (
              <FriendItem
                key={friend.id}
                friend={friend}
                status={newStatus}
                userMeId={user.id}
                onRemoveFriend={() =>
                  setIsDialogTerminateOpened(`${friend.id}${SEPARATOR}${friend.name}`)
                }
              />
            ))}
          </div>
        )}
      </TabPage>
      <TabPage value={activeTab} index={1}>
        {!friends.length ? (
          <EmptyList description='No incoming friend requests yet? It might be because no one is planning to add you. But don’t wait—take the initiative! You can send a friend request directly from another user’s profile and start building your network today.' />
        ) : (
          <div className={styles.list}>
            {friends.map((friend: FriendsModel) => (
              <FriendItem
                key={friend.id}
                friend={friend}
                status={newStatus}
                userMeId={user.id}
                handleAcceptFriend={handleAcceptFriend}
                onRemoveFriend={() =>
                  setIsDialogTerminateOpened(`${friend.id}${SEPARATOR}${friend.name}`)
                }
              />
            ))}
          </div>
        )}
      </TabPage>
      <MyDialog
        open={!!isDialogTerminateOpened}
        onClose={handleCloseDialogTerminate}
        dialogTitle='Are you sure you want to unfriend the user?'
        width='800'
      >
        <div className={styles.dialogDescription}>
          You are about to remove the user{' '}
          <strong>{isDialogTerminateOpened.split(SEPARATOR)[1]}</strong> from your friends list. To
          restore the friendly connection, you will need to resend the request.
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={() => handleRemoveFriend(isDialogTerminateOpened.split(SEPARATOR)[0])}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              styleType: 'cancel',
              buttonType: 'button',
            }}
            onClick={handleCloseDialogTerminate}
          />
        </div>
      </MyDialog>
    </div>
  );
};
