import { FC, useState } from 'react';
import { FormControl, FormHelperText, Select, SelectChangeEvent } from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledSelect, PlaceholderTypography, StyledMenuItem } from '../styled';
import { ISelectComponentProps } from '../types';
import { ReactComponent as CaretDown } from './CaretDown.svg';

interface IconComponentProps {
  className: string;
}

const SelectComponent: FC<ISelectComponentProps> = ({
  control,
  options,
  placeholder,
  name,
  error,
  helperText,
  onClick,
  disabled,
  selectedSelect,
  sx,
  dataQa,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
    if (name === 'selectUserType') {
      onClick?.();
      event.preventDefault();
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field: { onChange, value } }) => (
        <FormControl
          fullWidth
          error={error}
          sx={{ '.MuiOutlinedInput-root': { borderRadius: '14px' } }}
        >
          <StyledSelect
            data-qa={dataQa}
            id='MySelect'
            name={name}
            displayEmpty
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            disabled={disabled}
            sx={name === 'selectUserType' ? sx : null}
            IconComponent={(_props: IconComponentProps) => {
              const rotate = _props.className.toString().includes('iconOpen');
              return (
                <div
                  data-qa='dropdown_menu_btn'
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    pointerEvents: 'none',
                    right: 15,
                    height: '20px',
                    width: '20px',
                    transform: rotate ? 'rotate(180deg)' : 'none',
                  }}
                >
                  <CaretDown />
                </div>
              );
            }}
            onChange={(event: SelectChangeEvent<unknown>) => {
              onChange(event);
              const selValue = event.target.value;
              selectedSelect?.(selValue);
            }}
            renderValue={(selected: unknown) => {
              if (typeof selected === 'string') {
                if (selected.length === 0) {
                  return (
                    <PlaceholderTypography variant='body1' color='textSecondary'>
                      {placeholder}
                    </PlaceholderTypography>
                  );
                }
                return selected;
              } else if (typeof selected === 'number') {
                return selected.toString();
              }
              return null;
            }}
          >
            {options.map((item, index) => (
              <StyledMenuItem
                key={index}
                value={item}
                style={index !== options.length - 1 ? { borderBottom: '1px solid #e0e0e0' } : {}}
              >
                {item}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
