import { styled } from '@mui/system';
import { Button, IconButton } from '@mui/material';

export const StyledLogo = styled('img')({
  height: '48px',
  margin: '12px 0 5px',
});

export const StyledButtonsAvatar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  alignItems: 'center',
});

export const StyledButton = styled(Button)({
  background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  width: '137px',
  height: '40px',
  borderRadius: '14px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: '500',
  img: {
    marginRight: '10px',
  },

  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
  },

  '&:focus': {
    backgroundColor: '#014EB4',
  },
});

export const StyledButtonSecondary = styled(Button)({
  backgroundColor: '#f0f5ff',
  width: '137px',
  height: '40px',
  borderRadius: '14px',
  fontFamily: '"Poppins", sans-serif',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: '500',
  color: '#2a3842',

  '&:hover': {
    backgroundColor: '#c9d7f2',
  },
});

export const StyledButtonOutlined = styled(Button)({
  backgroundColor: '#ffffff',
  width: '200px',
  height: '40px',
  border: '2px solid #015cd5',
  borderRadius: '14px',
  fontFamily: '"Poppins", sans-serif',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: '500',
  color: '#015cd5',
  img: {
    marginRight: '10px',
  },

  '&:hover': {
    backgroundColor: '#ffffff',
    border: '2px solid #015cd5',
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
  },
});

export const StyledIconButton = styled(IconButton)({
  padding: '0',
});
