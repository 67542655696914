import { FormControl, FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';
import styles from './PropertyLocation.module.scss';
import {
  getDepthLabel,
  LocationAutocomplete,
} from '@/components/LocationAutocomplete/LocationAutocomplete';
import { useLocationAutocomplete } from '@/components/LocationAutocomplete';
import { LocationModel } from '@/types/location';
import { SectionTitle } from '@/components/uiComponents';

const OPFormHelperText = styled(FormHelperText)`
  margin: 0 0 5px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  span {
    color: var(--secondary, #f00);
  }
`;

interface PropertyLocationProps {
  addressEn?: string;
  setAddressEn: (value: string) => void;
  address?: string;
  setAddress: (value: string) => void;
  coordinatesLat?: number;
  setCoordinatesLat: (value: number) => void;
  coordinatesLong?: number;
  setCoordinatesLong: (value: number) => void;
}

export const PropertyLocation = ({
  addressEn = '',
  setAddressEn,
  setCoordinatesLat,
  setCoordinatesLong,
}: PropertyLocationProps) => {
  const { inputAddressValue, setInputAddressValue, locations, setSelectedLocation } =
    useLocationAutocomplete({
      defaultValue: addressEn || '',
    });

  const {
    formState: { errors },
    setValue,
    register,
    clearErrors,
  } = useFormContext();

  const updateLocationDetails = (location: LocationModel) => {
    setAddressEn(addressEn);
    setSelectedLocation(location);
    setValue('addressEn', `${getDepthLabel(location)}, ${location[location.kind]}`);
    setValue('nameEn', location ? location[location.kind] : null);
    setCoordinatesLat(location.latitude);
    setValue('coordinatesLat', String(location.latitude));
    setValue('locationId', String(location.id));
    setCoordinatesLong(location.longitude);
    setValue('coordinatesLong', String(location.longitude));
  };

  const handleClear = () => {
    setSelectedLocation(null);
    setInputAddressValue('');
    setValue('addressEn', undefined);
    setValue('nameEn', undefined);
    setValue('coordinatesLat', undefined);
    setValue('locationId', undefined);
    setValue('coordinatesLong', undefined);
  };

  return (
    <div style={{ width: '100%' }}>
      <SectionTitle number={4} title='Choose location' />
      <FormControl sx={{ width: '100%' }}>
        <OPFormHelperText className={styles.label}>
          Enter location<span>*</span>
        </OPFormHelperText>
        <LocationAutocomplete
          locations={locations}
          inputAddressValue={inputAddressValue}
          setInputAddressValue={setInputAddressValue}
          onSelect={updateLocationDetails}
          handleClear={handleClear}
        />
      </FormControl>
    </div>
  );
};
