import { ReactComponent as AvailabilityIcon } from './availabilityIcon.svg';
import { ReactComponent as BathsIcon } from './bathsIcon.svg';
import { ReactComponent as BedsIcon } from './bedsIcon.svg';
import { ReactComponent as CompletionStatusIcon } from './completionStatusIcon.svg';
import { ReactComponent as FindListingsIcon } from './findListingsIcon.svg';
import { ReactComponent as FloorIcon } from './floorIcon.svg';
import { ReactComponent as FurnitureIcon } from './furnitureIcon.svg';
import { ReactComponent as HandoverIcon } from './handoverIcon.svg';
import { ReactComponent as KeywordsIcon } from './keywordsIcon.svg';
import { ReactComponent as PriceIcon } from './priceIcon.svg';
import { ReactComponent as PropertyTypeIcon } from './propertyTypeIcon.svg';
import { ReactComponent as SizeIcon } from './sizeIcon.svg';
import { ReactComponent as StatusIcon } from './statusIcon.svg';
import { ReactComponent as TransactionTypeIcon } from './transactionTypeIcon.svg';

export const icons = {
  Availability: AvailabilityIcon,
  Baths: BathsIcon,
  Beds: BedsIcon,
  CompletionStatus: CompletionStatusIcon,
  FindListings: FindListingsIcon,
  Floor: FloorIcon,
  Furniture: FurnitureIcon,
  Handover: HandoverIcon,
  Keywords: KeywordsIcon,
  Price: PriceIcon,
  PropertyType: PropertyTypeIcon,
  Size: SizeIcon,
  Status: StatusIcon,
  TransactionType: TransactionTypeIcon,
}