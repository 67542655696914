import React, { ReactNode } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const styles = StyleSheet.create({
  section: {
    overflow: 'hidden',
  },
});

export const Section = ({
  title,
  children,
  style,
  wrap = false,
}: {
  title?: string;
  children: ReactNode;
  style?: Style;
  wrap?: boolean;
}) => {
  return (
    <View wrap={wrap} style={style ? [styles.section, style] : [styles.section]}>
      <View style={{ gap: 24 }}>
        {title && <Title>{title}</Title>}
        {children && <View style={{ gap: 24 }}>{children}</View>}
      </View>
    </View>
  );
};

export const Title = ({ children, style }: { children?: string; style?: Style }) => {
  if (!children) return null;

  return (
    <Text
      style={{
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 64,
        ...style,
      }}
    >
      {children}
    </Text>
  );
};
