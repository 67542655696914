import { adminProvider } from '@/providers/adminProvider';
import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum } from '@/types';

interface EmploymentRequest {
  employer: {
    id: number;
    name: string;
    avatar?: string;
  };
}

interface UseEmploymentRequestsResult {
  employmentRequests: EmploymentRequest[];
  isLoading: boolean;
  acceptEmploymentRequest: (id: number) => void;
  rejectEmploymentRequest: (id: number) => void;
}

export const useEmploymentRequests = (
  selectedUserId: number,
  open: boolean,
  onClose: () => void,
  onConfirm: () => void
): UseEmploymentRequestsResult => {
  const [employmentRequests, setEmploymentRequests] = useState<EmploymentRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await adminProvider.getEmploymentRequests(selectedUserId);
        setEmploymentRequests(res?.items);
      } catch (error) {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, 'Error fetching employment request')
        );
        console.error('Error fetching employment requests:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open && selectedUserId) {
      fetchData();
    }
  }, [selectedUserId, open]);

  const acceptEmploymentRequest = (id: any) => {
    setIsLoading(true);
    adminProvider
      .acсeptEmploymentRequest(selectedUserId, id)
      .then(() => {
        adminProvider.getEmploymentRequests(selectedUserId);
        onClose();
        onConfirm();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const rejectEmploymentRequest = (id: any) => {
    setIsLoading(true);
    adminProvider
      .rejectEmploymentRequest(selectedUserId, id)
      .then(() => {
        adminProvider.getEmploymentRequests(selectedUserId);
        onClose();
        onConfirm();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    employmentRequests,
    isLoading,
    acceptEmploymentRequest,
    rejectEmploymentRequest,
  };
};
