import { Text, View } from '@react-pdf/renderer';
import { AdModel, PaymentPlanType } from '@/types';
import { Section } from './Section';

export function PaymentPlanSection({ paymentPlan }: { paymentPlan?: PaymentPlanType }) {
  const plans = paymentPlan?.items.map((plan, index) => {
    const numberOfPlan = index + 1;
    return (
      <View
        key={index}
        style={{ flexDirection: 'column', width: '100%', alignItems: 'center', gap: 32 }}
        wrap={false}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '99%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          wrap={false}
        >
          <View
            style={{ flexDirection: 'row', width: '100%', alignItems: 'center', gap: 32 }}
            wrap={false}
          >
            <View style={{ flexDirection: 'row', gap: 50, alignItems: 'center' }} wrap={false}>
              <View
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 64,
                  alignItems: 'center',
                  alignContent: 'center',
                  backgroundColor: '#1650FF',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontFamily: 'Poppins', color: '#FFFFFF', fontSize: 40 }}>
                  {numberOfPlan}
                </Text>
              </View>
              <View style={{ flexDirection: 'column', gap: 2 }} wrap={false}>
                <Text style={{ fontFamily: 'Poppins', fontSize: 40 }}>{plan.name}</Text>
                <Text style={{ fontFamily: 'Poppins', fontSize: 32, opacity: 0.5 }}>
                  {plan.description}
                </Text>
              </View>
            </View>
          </View>
          <Text style={{ fontFamily: 'Poppins', fontSize: 40 }}>{plan.value}</Text>
        </View>
        {numberOfPlan < paymentPlan.items.length && (
          <View
            style={{
              width: '100%',
              height: 3,
              backgroundColor: '#2A384233',
              marginHorizontal: 0,
            }}
          />
        )}
      </View>
    );
  });

  return (
    <Section title='Payment plan' style={{ marginBottom: '80px', marginTop: '80px' }} wrap={true}>
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'column',
          gap: 15,
          marginTop: 36,
          backgroundColor: '#F1F7FF',
          borderRadius: '60px',
          padding: 70,
        }}
      >
        <Text
          style={{
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: 70,
            marginBottom: 30,
          }}
        >
          {paymentPlan?.name}
        </Text>
        {plans}
      </View>
    </Section>
  );
}

interface Feature {
  name: string;
  src: string;
}

type PropertyFeaturesBase = {
  [K in keyof AdModel]: Feature;
};
