export const messageContainerStyle = () => {
  return {
    alignSelf: 'flex-start',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '65%',
    minWidth: '340px',
    clear: 'both',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
  };
};

export const messageWrapperStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageThumbnailStyle = () => {
  return {
    width: '40px',
    height: '40px',
    margin: '0 5px 10px',
    float: 'left',
    flexShrink: '0',
  };
};

export const messageDetailStyle = () => {
  return {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  };
};

export const messageImgContainerStyle = () => {
  return {
    width: 'auto',
    flex: '1 1',
    alignSelf: 'flex-start',
    display: 'flex',
  };
};

export const messageImgWrapperStyle = (context) => {
  const mq = [...context.theme.breakPoints];

  return {
    display: 'inline-block',
    alignSelf: 'flex-start',
    maxWidth: '128px',
    height: '128px',
    cursor: 'pointer',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      maxWidth: '128px',
      height: '128px',
      padding: '2px 2px',
    },
  };
};

export const messageInfoWrapperStyle = () => {
  return {
    alignSelf: 'flex-start',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '25px',
    gap: '7px',
  };
};

export const messageReactionsWrapperStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '5px',
    paddingTop: '6px',
  };
};
