import { ChatState, ActionsTypes, ChatGetCountOfUnreadResponseType } from '@/types';
import { CHAT_GET_COUNT_OF_UNREAD } from '@/store';

const initialState: ChatState = {
  countOfUnread: null,
};

const chatGetCountOfUnread = (
  state: ChatState,
  { countOfUnread }: ChatGetCountOfUnreadResponseType
): ChatState => ({
  ...state,
  countOfUnread,
});

export const chatReducer = (state = initialState, action: ActionsTypes) => {
  switch (action.type) {
    case CHAT_GET_COUNT_OF_UNREAD:
      return chatGetCountOfUnread(state, action.payload as ChatGetCountOfUnreadResponseType);
    default:
      return state;
  }
};
