import { Image, StyleSheet, Link } from '@react-pdf/renderer';

export interface SocialIconProps {
  iconSrc?: string;
  link?: string;
}

export const SocialIcon = ({ iconSrc, link }: SocialIconProps) => {
  const styles = StyleSheet.create({
    icon: {
      width: 80,
      height: 80,
    },
  });

  return (
    <Link src={link}>
      <Image src={iconSrc} style={styles.icon} />
    </Link>
  );
};
