import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, useMediaQuery, useTheme, Divider, Link } from '@mui/material';
import { StyledPriceBlock, Title, Term, Shell, StyledPriceSpan } from './styled';
import styles from './Ad.module.scss';
import {
  RequestMyModel,
  AdFieldRentFrequencyEnum,
  State,
  RentPeriodEnum,
  ActivityKindEnum,
  PropertyKindEnum,
  TransactionTypeEnum,
  AdFieldMultilistingEnum,
} from '@/types';
import { getKindLabel, capitalizeFirstLetter, priceFormat } from '@/utils';
import { icons } from '../../Ad/Icons/icons';
import ItemFee from '../../Ads/AdsListItem/svg/itemFee.svg';
import { CommissionBlock, ContactBlock } from '../Ad';
import { Section } from '@/components/Ad/Section';

type PreferredPropertyKinds =
  | 'shop'
  | 'office'
  | 'warehouse'
  | 'residentialLand'
  | 'industrialLand'
  | 'commercialLand';

export const Ad = ({ page }: { page: RequestMyModel | undefined }) => {
  const [rentFrequency, setRentFrequency] = useState<RentPeriodEnum | null | undefined>(
    page?.defaultRentFrequency
  );
  const [convertedSellingAgencyFee, setConvertedSellingAgencyFee] = useState<number>();
  const [convertedListingAgencyFee, setConvertedListingAgencyFee] = useState<number>();
  const [price, setPrice] = useState<number>();

  const user = useSelector(({ auth }: State) => auth.user);
  const theme = useTheme();
  const _isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const _userType = page ? getKindLabel(page?.propertyAdCreator?.kind || '') : '';

  const images = page?.propertyAdMedia && `${page?.propertyAdMedia?.fileUrl}_385x190`;

  const isRent = page?.activityKind === ActivityKindEnum.Rent;

  useEffect(() => {
    if (isRent) {
      if (rentFrequency === RentPeriodEnum.Yearly && page.convertedPriceYearly) {
        setPrice(page.convertedPriceYearly);
      }
      if (rentFrequency === RentPeriodEnum.Monthly && page.convertedPriceMonthly) {
        setPrice(page.convertedPriceMonthly);
      }
      if (rentFrequency === RentPeriodEnum.Weekly && page.convertedPriceWeekly) {
        setPrice(page.convertedPriceWeekly);
      }
      if (rentFrequency === RentPeriodEnum.Daily && page.convertedPriceDaily) {
        setPrice(page.convertedPriceDaily);
      }
    } else {
      if (page?.transactionType === TransactionTypeEnum.Distress) {
        setPrice(page?.convertedSellingPrice);
      } else {
        setPrice(page?.convertedPrice);
      }
    }
  }, [rentFrequency]);

  const creator = {
    name: page?.propertyAdCreator?.name,
    id: page?.propertyAdCreator?.id,
    avatar: page?.propertyAdCreator?.avatar,
  };

  const employer = page?.propertyAdCreatorEmployer
    ? {
        name: page?.propertyAdCreatorEmployer?.name,
        id: page?.propertyAdCreatorEmployer?.id,
        avatar: page?.propertyAdCreatorEmployer?.avatar,
      }
    : undefined;

  const rentFrequencyLabels = {
    [AdFieldRentFrequencyEnum.Monthly]: 'Monthly',
    [AdFieldRentFrequencyEnum.Annual]: 'Yearly',
  };

  const rentPeriodLabel = [
    { label: 'Yearly', value: RentPeriodEnum.Yearly, convertedPrice: page?.convertedPriceYearly },
    {
      label: 'Monthly',
      value: RentPeriodEnum.Monthly,
      convertedPrice: page?.convertedPriceMonthly,
    },
    { label: 'Weekly', value: RentPeriodEnum.Weekly, convertedPrice: page?.convertedPriceWeekly },
    { label: 'Daily', value: RentPeriodEnum.Daily, convertedPrice: page?.convertedPriceDaily },
  ];

  const convertedPlotArea = page?.plotArea
    ? page?.convertedAreaUnits === 'SQFT'
      ? page?.plotArea
      : Math.round(Number(page?.plotArea) / 10.764)
    : 0;
  const aria = page?.convertedArea ? page?.convertedArea : page?.plotArea ? convertedPlotArea : 0;

  const preferredPropertyKinds = [
    PropertyKindEnum.Shop,
    PropertyKindEnum.Office,
    PropertyKindEnum.Warehouse,
    PropertyKindEnum.ResidentialLand,
    PropertyKindEnum.IndustrialLand,
    PropertyKindEnum.CommercialLand,
  ];
  const propertyKindLabel: Record<PreferredPropertyKinds, string> = {
    shop: 'Shop',
    office: 'Office',
    warehouse: 'Warehouse',
    residentialLand: 'Residential Land',
    industrialLand: 'Industrial Land',
    commercialLand: 'Commercial Land',
  };
  const isPrefTitle = page?.propertyKind && preferredPropertyKinds.includes(page?.propertyKind);
  const titlePref =
    page?.propertyKind && propertyKindLabel[page?.propertyKind as PreferredPropertyKinds];
  const titleText = isPrefTitle ? `${titlePref} at ${page?.nameEn}` : page?.nameEn;

  const multilistingLabel = {
    [AdFieldMultilistingEnum.Covered]: 'Covered',
    [AdFieldMultilistingEnum.NotCovered]: 'Not Covered',
  };
  const multilistingText = page?.multilisting && multilistingLabel[page?.multilisting];

  return !page ? null : (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='flex-start'
      justifyContent='center'
      spacing={2}
      sx={{
        color: '#2A3842',
        '@media (max-width: 600px)': { width: 'inherit' },
      }}
    >
      <Stack
        alignItems='flex-start'
        spacing={2}
        sx={{
          flexGrow: '1',
          width: '100%',
          maxWidth: '385px',
          '@media (max-width: 600px)': { width: 'inherit' },
        }}
      >
        <Stack className={styles.list}>
          <Stack className={styles.listItem}>
            {images && images?.length > 0 ? (
              <div className={styles.itemImg}>
                <img src={images} alt={page.title} />
              </div>
            ) : (
              <div className={styles.itemWithoutImg}></div>
            )}
            <Section>
              <Stack direction='row' alignItems='start' justifyContent='space-between' spacing={2}>
                <Title>{titleText}</Title>
              </Stack>

              <Stack direction='row' alignItems='end' justifyContent='space-between'>
                <StyledPriceBlock>
                  {capitalizeFirstLetter(page.activityKind)}&nbsp;
                  <StyledPriceSpan>{priceFormat(price ? price : 0)}</StyledPriceSpan>
                  <div>{page.convertedCurrency}</div>
                </StyledPriceBlock>
                <div className={styles.itemFee}>
                  <img src={ItemFee} alt='' />
                  {multilistingText}
                </div>
              </Stack>
              <Stack direction='row' className={styles.rentContainer}>
                {isRent &&
                  rentPeriodLabel.map((period, index) =>
                    period.convertedPrice && period.convertedPrice > 0 ? (
                      <Stack
                        key={index}
                        className={`${styles.rent} ${period.value === rentFrequency ? styles.rentActive : styles.rentPassive}`}
                        onClick={() => setRentFrequency(period.value)}
                      >
                        <span>{period.label}</span>
                      </Stack>
                    ) : null
                  )}
              </Stack>

              <Divider className={styles.divider} />
              <Link
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'left',
                  color: 'inherit',
                  gap: '8px',
                  textDecoration: 'none',
                }}
              >
                <icons.Location className={styles.locationIcon} />
                <span>{page.addressEn}</span>
              </Link>
              {aria > 0 && (
                <Stack direction='row' justifyContent='left' alignItems='flex-start' spacing={1}>
                  <icons.Ruler className={styles.locationIcon} />
                  <span>{`${aria} ${page.convertedAreaUnits.toLowerCase()}`}</span>
                </Stack>
              )}
              <Divider className={styles.divider} />
              <Stack direction='row' justifyContent='left' alignItems='flex-start' spacing={1}>
                <ContactBlock user={creator} userMe={user} />
                {employer && <ContactBlock user={employer} />}
              </Stack>
            </Section>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
