import { StyledButton } from '@/components/Header/stylesHeader';
import styles from './EmptyList.module.scss';
import Image from './emptyList.png';
import Search from './search.svg';
import { useNavigate } from 'react-router-dom';

export const EmptyList = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img className={styles.containerImg} src={Image} alt='Empty list' />
      <div>
        You haven’t saved any listings yet. Visit the showcase and save properties your clients will
        love. Don’t miss out!
      </div>
      <StyledButton
        style={{ maxWidth: '220px', width: 'auto', marginTop: '20px', color: '#fff' }}
        type='submit'
        variant='outlined'
        color='primary'
        onClick={() => navigate('/')}
      >
        <img style={{ color: '#fff' }} src={Search} alt='Add client request' />
        Search property
      </StyledButton>
    </div>
  );
};
