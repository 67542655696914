import { useEffect, useState, SyntheticEvent, CSSProperties } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Box, Tabs, Tab, Link, Stack, Paper, Typography } from '@mui/material';
import { AdminPaymentEnum, ISubscription, ITransactions } from '@/types';
import { transactions } from './fakeData';
import { SubscriptionsTable } from './SubscriptionsTable';
import { TransactionsTable } from './TransactionsTable';
import { TariffPlanTable } from './TariffPlanTable';
import { PaymentInformationDialog } from './Dialogs/PaymentInformationDialog';
import { TransactionInformationDialog } from './Dialogs/TransactionInformationDialog';
import { CreateTariffDialog } from './Dialogs/CreateTariffDialog';
import { useChangeQueryParams } from '@/hooks';
import styles from './PaymentInformation.module.scss';
import {
  transactionsTable,
  subscriptionsTable,
  paymentHistoryTable,
  tariffPlanTable,
} from './PaymentInformation.init';
import AddAd from './addAd.svg';
import { StyledButton } from '../../Header/stylesHeader';
import { MyButton } from '@/components';

export const PaymentInformation = () => {
  const changeQueryParams = useChangeQueryParams(`admin/payment-information/`);
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);
  const [activeTab, setActiveTab] = useState(AdminPaymentEnum.Subscriptions);
  const [openPaymentInfo, setOpenPaymentInfo] = useState<boolean>(false);
  const [openTransactionInfo, setOpenTransactionInfo] = useState<boolean>(false);
  const [openCreateTariff, setOpenCreateTariff] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<ISubscription>();
  const [transactionInfo, setTransactionInfo] = useState<ITransactions>();
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [totalTariffPlan, setTotalTariffPlan] = useState(0);
  const [lastPages, setLastPages] = useState({
    listings: '1',
    staff: '1',
    request: '1',
  });

  const isSubscriptions = activeTab === AdminPaymentEnum.Subscriptions;
  const isTransactions = activeTab === AdminPaymentEnum.Transactions;
  const isTariffs = activeTab === AdminPaymentEnum.Tariffs;
  const isCoupons = activeTab === AdminPaymentEnum.Coupons;

  // useEffect(() => {
  //   console.log(activeTab, queryParamsStr);
  // }, [activeTab, queryParamsStr]);

  const handleChangeTab = (_: SyntheticEvent, newStatus: AdminPaymentEnum) => {
    setActiveTab(newStatus);
    const { page, status } = queryParams;
    setLastPages({ ...lastPages, [status as AdminPaymentEnum]: page });
    return changeQueryParams({
      ...queryParams,
      page: '1',
      status: newStatus,
    });
  };

  const handleClickTable = (row: ISubscription | ITransactions, type: string) => {
    if (type === 'subscription') {
      setPaymentInfo(row as ISubscription);
      setOpenPaymentInfo(true);
    } else if (type === 'transaction') {
      setTransactionInfo(row as ITransactions);
      setOpenTransactionInfo(true);
    }
  };

  return (
    <div>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label='view tabs'
          className={styles.tabsInner}
        >
          <Tab
            id='tab-subscriptions'
            aria-controls='tabpanel-subscriptions'
            label='Subscriptions'
            value={AdminPaymentEnum.Subscriptions}
            className={styles.tabItem}
          />
          <Tab
            id='tab-transactions'
            aria-controls='tabpanel-transactions'
            label='Transactions'
            value={AdminPaymentEnum.Transactions}
            className={styles.tabItem}
          />
          <Tab
            id='tab-tariffs'
            aria-controls='tabpanel-tariffs'
            label='Tariffs'
            value={AdminPaymentEnum.Tariffs}
            className={styles.tabItem}
          />
          <Tab
            id='tab-coupons'
            aria-controls='tabpanel-coupons'
            label='Coupons'
            value={AdminPaymentEnum.Coupons}
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      {isSubscriptions ? (
        <Stack>
          <Typography sx={{ fontSize: '28px', margin: '30px 0 20px' }}>
            Total
            <span style={{ fontWeight: 600, color: '#1650FF' }}>{` ${totalSubscriptions} `}</span>
            subscriptions
          </Typography>
          <SubscriptionsTable
            onClick={handleClickTable}
            setTotalSubscriptions={setTotalSubscriptions}
            subscriptionsTable={subscriptionsTable}
          />
        </Stack>
      ) : null}
      {isTransactions ? (
        <TransactionsTable transactionsTable={transactionsTable} onClick={handleClickTable} />
      ) : null}
      {isTariffs ? (
        <Stack>
          <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '28px', margin: '30px 0 20px' }}>
              Total
              <span style={{ fontWeight: 600, color: '#1650FF' }}>{` ${totalTariffPlan} `}</span>
              tariffs
            </Typography>
            <CreateTariffButton handleClickOpenDialog={() => setOpenCreateTariff(true)}/>
          </Stack>
          <TariffPlanTable tariffPlanTable={tariffPlanTable} setTotalTariffPlan={setTotalTariffPlan}/>
        </Stack>
      ) : null}
      {isCoupons ? <Stack>Coupons</Stack> : null}
      {paymentInfo && (
        <PaymentInformationDialog
          open={openPaymentInfo}
          onClose={() => setOpenPaymentInfo(false)}
          paymentInfo={paymentInfo}
          paymentHistoryTable={paymentHistoryTable}
        />
      )}
      {transactionInfo && (
        <TransactionInformationDialog
          open={openTransactionInfo}
          onClose={() => setOpenTransactionInfo(false)}
          transactionInfo={transactionInfo}
        />
      )}
      <CreateTariffDialog
        open={openCreateTariff}
        onClose={() => setOpenCreateTariff(false)}
      />
    </div>
  );
};

const CreateTariffButton = ({
  style,
  handleClickOpenDialog,
}: {
  style?: CSSProperties;
  handleClickOpenDialog?: () => void;
}) => {
  return (
    <StyledButton
      style={{ width: '205px', ...style }}
      type='submit'
      variant='contained'
      color='primary'
      onClick={handleClickOpenDialog}
    >
      <img src={AddAd} alt='Add Ad' />
      Create tariff plan
    </StyledButton>
  );
}
