import { FileKindEnum, IPdfDocument, ActivityKindEnum, RentPeriodEnum } from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { PaymentPlanSection } from './PaymentPlanSection';
import { GallerySection } from './GallerySection';

import {
  BedIcon,
  BuildingsIcon,
  CurrencyCircleDollarIcon,
  FloorIcon,
  FurnishingIcon,
  HouseLineIcon,
  LocationWhiteIcon,
  NoteIcon,
  PaintRollerIcon,
  RulerIcon,
  ShowerIcon,
} from '@/assets/pdf';

import { AddressIconText } from './AddressIconText';
import { ContactSection } from './ContactSection';
import { TopGallery } from './TopGallery';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  bold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 40,
  },
  price: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 56,
    lineHeight: 1.3,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#1650FF',
    // marginBottom: 64,
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2A3842',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 40,
  },
  pageTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '35%',
  },
  basicAndSpecificationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '50 0 50 0',
  },
});

export function PdfDocument({
  showContent,
  adsData,
  personalInfo,
  currentAgencyInfo,
  isNumberOfFloorsShown,
}: IPdfDocument) {
  const {
    title,
    nameEn,
    paymentPlan,
    defaultRentFrequency,
    activityKind,
    convertedPrice,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    convertedCurrency,
    floorsNum,
    floor,
    propertyAdMedia,
    propertyKind,
    completionStatus,
    convertedArea,
    plotArea,
    convertedAreaUnits,
    furniture,
    baths,
    beds,
    renovation,
    description,
    freeHold,
  } = adsData;

  const plans = propertyAdMedia.filter((item) => item.kind === FileKindEnum.Plan);
  const newImage = showContent.images?.slice(0, 4);
  const isSale = activityKind !== ActivityKindEnum.Rent;
  const textBeds = beds === 'Studio' ? '' : Number(beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  let newPrice = 0;

  if (isSale) {
    newPrice = convertedPrice || 0;
  } else {
    switch (defaultRentFrequency) {
      case RentPeriodEnum.Yearly:
        newPrice = convertedPriceYearly || 0;
        break;
      case RentPeriodEnum.Monthly:
        newPrice = convertedPriceMonthly || 0;
        break;
      case RentPeriodEnum.Weekly:
        newPrice = convertedPriceWeekly || 0;
        break;
      case RentPeriodEnum.Daily:
        newPrice = convertedPriceDaily || 0;
        break;
    }
  }

  const getFloorText = () => {
    if (!floorsNum) {
      return null;
    }
    if (isNumberOfFloorsShown && floorsNum) {
      return `${floorsNum} floors`;
    }
    if (floor) {
      return `${capitalizeFirstLetter(floor)} floor`;
    }
    return null;
  };

  const floorText = getFloorText();
  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View>
          <TopGallery wrap={true} images={showContent.images} />

          <View style={styles.basicAndSpecificationWrapper}>
            <View style={{ gap: 40 }}>
              <AddressIconText iconSrc={LocationWhiteIcon}>{nameEn}</AddressIconText>
              <View style={styles.pageTitleWrapper}>
                <Text style={styles.pageTitle}>{title}</Text>
              </View>
              {showContent.showPrice ? (
                <View style={styles.inline}>
                  <Text style={styles.bold}>Price</Text>
                  <Text style={styles.price}>{numberFormat.format(newPrice)}</Text>
                  <Text style={styles.bold}>{convertedCurrency}</Text>

                  {defaultRentFrequency && (
                    <Badge>{capitalizeFirstLetter(defaultRentFrequency)}</Badge>
                  )}
                </View>
              ) : null}
            </View>

            <Section style={{ maxWidth: '60%' }}>
              <View
                style={{
                  flexDirection: 'row',
                  gap: 30,
                  marginBottom: 30,
                  justifyContent: 'center',
                }}
              >
                <Badge iconSrc={CurrencyCircleDollarIcon}>
                  {capitalizeFirstLetter(activityKind)}
                </Badge>
                <Badge iconSrc={BuildingsIcon}>{capitalizeFirstLetter(propertyKind)}</Badge>
                <Badge iconSrc={HouseLineIcon}>{capitalizeFirstLetter(completionStatus)}</Badge>
                <Badge iconSrc={NoteIcon}>{freeHold || ''}</Badge>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  gap: 48,
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <IconText style={{ minWidth: 330 }} iconSrc={RulerIcon}>
                  {`${plotArea ? `${plotArea} / ` : ''}${convertedArea ?? ''} ${convertedAreaUnits?.toLowerCase() ?? ''}`}
                </IconText>
                {beds && <Separator />}
                {beds && (
                  <IconText style={{ minWidth: 350 }} iconSrc={BedIcon}>
                    {`${beds} ${textBeds}`}
                  </IconText>
                )}
                {furniture && <Separator />}
                {furniture && (
                  <IconText style={{ minWidth: 350 }} iconSrc={FurnishingIcon}>
                    {capitalizeFirstLetter(furniture)}
                  </IconText>
                )}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  gap: 48,
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                {baths && (
                  <IconText style={{ minWidth: 330 }} iconSrc={ShowerIcon}>
                    {`${baths} ${textBaths}`}
                  </IconText>
                )}
                {floorText && <Separator />}
                {floorText && (
                  <>
                    <IconText style={{ minWidth: 350 }} iconSrc={FloorIcon}>
                      {floorText}
                    </IconText>
                  </>
                )}
                {renovation && <Separator />}
                <IconText style={{ minWidth: 350 }} iconSrc={PaintRollerIcon}>
                  {capitalizeFirstLetter(renovation)}
                </IconText>
              </View>
            </Section>
          </View>

          {newImage && (
            <GallerySection
              wrap={true}
              images={newImage}
              style={{ width: newImage.length > 3 ? '24%' : '32%' }}
              position='middle'
            />
          )}

          <Section wrap={true} style={{ marginTop: 50, maxWidth: '99%' }}>
            <Text>{description}</Text>
          </Section>

          <FeaturesSection data={adsData} />

          {plans ? (
            <GallerySection title='Floor plan' style={{ width: '40%' }} images={plans} />
          ) : null}

          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
              marginBottom: '80px',
              marginTop: '80px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>

          {paymentPlan && <PaymentPlanSection paymentPlan={paymentPlan} />}

          <GallerySection
            wrap={true}
            title='Gallery'
            images={showContent.images}
            style={{ width: '49%' }}
            position='bottom'
          />
        </View>
      </Page>
    </Document>
  );
}

const Separator = () => (
  <View
    style={{
      height: '100%',
      width: 4,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);

const numberFormat = new Intl.NumberFormat('en', {
  maximumSignificantDigits: 3,
});

const capitalizeFirstLetter = (string?: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
