import { ResponseType, MetricsChurnRateType, MetricsNotLoggedInType } from '@/types';
import { apiClient } from '@/providers';

export const metricsProvider = {
  churnRate: async ( data: MetricsChurnRateType): Promise<ResponseType> => {
    const response = await apiClient('/metrics/churn-rate', 'POST', data);

    if (!response.ok) {
      return { ...response, data: null };
    }
    
    return { ...response };
  },

  lastAdPublished: async ( data: MetricsChurnRateType): Promise<ResponseType> => {
    const response = await apiClient('/metrics/last-property-ad-published', 'POST', data);

    if (!response.ok) {
      return { ...response, data: null };
    }
    
    return { ...response };
  },

  lastClientRequestPublished: async ( data: MetricsChurnRateType): Promise<ResponseType> => {
    const response = await apiClient('/metrics/last-client-requests-published', 'POST', data);

    if (!response.ok) {
      return { ...response, data: null };
    }
    
    return { ...response };
  },

  notLoggedIn: async (data: MetricsNotLoggedInType): Promise<ResponseType> => {
    const response = await apiClient(`/metrics/not-logged-in`, 'POST', data);
    if (!response.ok) {
      return { ...response, data: null };
    }

    return { ...response };
  },
};
