import { useState } from 'react'
import { Grid, Stack, Typography, useMediaQuery, useTheme, Link } from "@mui/material";
import { styled, shouldForwardProp } from '@mui/system';
import { icons } from '../Icons/icons';
import { formOfOrganization, language } from '../../utils/fakeData';
import { StyledLink } from '../../../uiComponents/styled';
import styles from './PersonalInfo.module.scss';

const FONT_COLOR = '#2A3842';
const nameLinks = ['instagram', 'linkedin', 'links']

const PersonalInfoContainerStyle = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => ({
  backgroundColor: '#F1F7FF',
  width: '986px',
  margin: '24px 10px 10px 6px',
  borderRadius: '25px',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  gap: '16px',
  padding: '24px',
  [theme.breakpoints.down('sm')]: {
    width: '370px',
    margin: type === 'user' ? '120px 0 0 2px' : '60px 0 0 2px',
    borderRadius: '14px',
  },
}));

const PersonalInfoStyle = styled(Typography)({
  fontSize: '22px',
  fontWeight: '600',
  color: FONT_COLOR,
});

const InfoNameStyle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  color: FONT_COLOR,
});

const InfoStyle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  color: FONT_COLOR,
  whiteSpace: 'pre-wrap',
});

export const ShowInfoStyle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  color: '#1650FF',
});

const UserInfoBlock = ({ icon: Icon, title, value, width, name, isMobile, dataQa }) => {
  const formatEmail = (email) => {
    if (email.length > 25) {
      const atIndex = email.indexOf('@');
      if (atIndex !== -1) {
        return email.slice(0, atIndex + 1) + ' ' + email.slice(atIndex + 1);
      }
    }
    // if (email.length > 25) {
    //   return email.slice(0, 20) + ' ' + email.slice(20);
    // }
    return email;
  }
  let displayValue;
  if (name === 'operationRegions') {
    displayValue = value.map((val) => val.name).join(', ');
  } else {
    displayValue = Array.isArray(value) ? value.join(', ') : value;
  }
  // if (name === 'additionalEmails') {
  //   displayValue = formatEmail(displayValue);
  // }

  const madeLink = (link) => {
    if (!link) return '';
    let noProtocol = link?.replace(/^https?:\/\//, '');
    noProtocol = noProtocol.replace(/^www\./, '');
    let title = noProtocol.split('/')[0];
    return { title: title, value: noProtocol };
  };

  const processedLinks = (name === 'links' && value?.length > 0) ?
    value.map(link => madeLink(link)) :
    (name === 'instagram' || name === 'linkedin') ?
      [madeLink(value)] :
      [];

  return (
    <Stack spacing={isMobile ? 1 : 0.5} sx={{ minWidth: isMobile ? '100%' : width, maxWidth: isMobile ? '100%' : width }}>
      <Stack direction="row" justifyContent='flex-start' spacing={0.5} data-qa={`${dataQa}_personal_info_${title.toLowerCase()}_title`}>
        <Icon />
        <InfoNameStyle>{title}:</InfoNameStyle>
      </Stack>
      {
        processedLinks.length ? (
          processedLinks.map((link, index) => (
            link && (
              <StyledLink
                data-qa={`${dataQa}_personal_info_${title.toLowerCase()}_value`}
                key={link.value + index}
                fontSize='16px'
                to={`https://${link.value}`}
                target='_blank'
              >
                {`www.${link.title}`}
              </StyledLink>
            )
          ))
        ) : (
          <InfoStyle data-qa={`${dataQa}_personal_info_${title.toLowerCase()}_value`}>{displayValue}</InfoStyle>
        )
      }
    </Stack>
  )
};

const PersonalInfo = ({ user, personalInfo, type, dataQa }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const allControls = personalInfo && personalInfo.data || [];
  const inputGroups = personalInfo?.groupSizes.reduce((acc, size, index) => {
    const start = personalInfo?.groupSizes.slice(0, index).reduce((sum, value) => sum + value, 0);
    const end = start + size;
    acc.push(allControls.slice(start, end));
    return acc;
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className={styles.container} data-qa={`${dataQa}_info_block`}>
      {/* <PersonalInfoContainerStyle container type={type}> */}
      <Stack alignItems='flex-start' spacing={isMobile ? 4 : 2.5} sx={{ width: '100%' }}>
        <PersonalInfoStyle data-qa={`${dataQa}_info_block_title`}>Personal information</PersonalInfoStyle>
        {inputGroups?.map((group, groupIndex) => (
          <Stack key={groupIndex} direction={{ xs: 'column', sm: 'row' }} spacing={isMobile ? 4 : 0} justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
            {group?.map(({ name, label, icon, width }) => {
              if (name === 'gender' && user.gender) {
                user.gender = capitalizeFirstLetter(user.gender);
              }
              if (name === 'activityKinds' && user.activityKinds) {
                user.activityKinds = user.activityKinds.map(capitalizeFirstLetter);
              }
              if (name === 'formOfOrganization' && user.personKind) {
                user.formOfOrganization = formOfOrganization.find((el) => el.name === user?.personKind)?.label;
              }
              if (name === 'languages' && user.languages) {
                const nameToLabelMap = language.reduce((acc, item) => {
                  acc[item.name] = item.label;
                  return acc;
                }, {});
                user.languages = user.languages.map(name => nameToLabelMap[name] || name);
              }
              return (
                <UserInfoBlock
                  key={name}
                  icon={icons[icon]}
                  title={label}
                  value={user[name]}
                  name={name}
                  width={width}
                  isMobile={isMobile}
                  dataQa={dataQa}
                />
              )
            }
            )}
          </Stack>
        ))}
      </Stack>
      {/* </PersonalInfoContainerStyle> */}
    </div>
  )
}

export default PersonalInfo;