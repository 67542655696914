import { FormInput, SectionTitle } from '@/components';
import styles from './PropertyDescription.module.scss';

export const PropertyDescription = () => {
  return (
    <div>
      <SectionTitle number={6} title='Request description' />
      <div className={styles.rowTitle}>
        <FormInput
          uiVariant='blank'
          name='title'
          required
          placeholder='Enter title'
          label='Title'
        />
      </div>
      <div className={styles.rowDescription}>
        <FormInput
          label='Description'
          uiVariant='blank'
          name='description'
          multiline
          placeholder='Add a description of your property'
          minRows={undefined}
          rows={10}
          inputProps={{ maxLength: 1200 }}
        />
      </div>
    </div>
  );
};
