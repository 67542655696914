import { FileKindEnum, IPdfDocument } from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Section, Title } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';
import { PaymentPlanSection } from './PaymentPlanSection';
import { GallerySection } from './GallerySection';

import {
  BedIcon,
  BuildingsBlue,
  BuildingsIcon,
  CalendarBlue,
  CurrencyCircleDollarIcon,
  FloorBlue,
  FloorIcon,
  FurnishingBlue,
  FurnishingIcon,
  HouseLineIcon,
  LocationWhiteIcon,
  NoteIcon,
  PaintRollerBlue,
  PaintRollerIcon,
  ParkingBlue,
  RulerIcon,
  ShowerIcon,
} from '@/assets/pdf';

import { AddressIconText } from './AddressIconText';
import { ContactSection } from './ContactSection';
import { TopGallery } from './TopGallery';
import { IOffplanPdfDocument } from '@/types/pdf';
import { splitAndReformat } from '../ClassicView/UnitsPdfDocument';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 40,
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  bold: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 40,
  },
  price: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 56,
    lineHeight: 1.3,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 32,
    color: '#1650FF',
    // marginBottom: 64,
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2A3842',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 40,
  },
  pageTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '35%',
  },
  basicAndSpecificationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '50 0 50 0',
  },
  values: {
    color: '#1650FF',
    fontWeight: 600,
    fontSize: 56,
  },
  priceText: {
    color: '#2A3842',
    fontWeight: 600,
    fontSize: 40,
  },
  characteristicsContainer: {
    backgroundColor: '#F1F7FF',
    padding: '24px',
    borderRadius: '8px',
    width: '48vw',
  },
  characteristicsMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  characteristicsInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  characteristicsText: {
    color: '#2A3842',
    opacity: 0.5,
  },
});

export function PdfDocument({
  showContent,
  offplanData,
  personalInfo,
  currentAgencyInfo,
  units,
}: IOffplanPdfDocument) {
  const newImage = showContent.images?.slice(0, 4);

  const characteristics = [
    {
      name: 'Developent status',
      value: offplanData?.developmentStatus
        ? splitAndReformat(offplanData?.developmentStatus)
        : null,
      icon: PaintRollerBlue,
    },
    {
      name: 'Property type',
      value: offplanData?.constructionType ? splitAndReformat(offplanData?.constructionType) : null,
      icon: BuildingsBlue,
    },
    {
      name: 'Floor',
      value: offplanData?.numberOfFloors,
      icon: FloorBlue,
    },
    {
      name: 'Handover',
      value: offplanData?.handover,
      icon: CalendarBlue,
    },
    {
      name: 'Furnishing',
      value: offplanData?.furnishing ? 'Yes' : 'No',
      icon: FurnishingBlue,
    },
    {
      name: 'Parking',
      value: offplanData?.coveredParking ? 'Yes' : 'No',
      icon: ParkingBlue,
    },
  ];
  const splitPoint = Math.ceil(characteristics.length / 2);

  const firstHalf = characteristics.slice(0, splitPoint);
  const secondHalf = characteristics.slice(splitPoint);

  const paymentPlan = offplanData?.paymentPlan;
  const renderCharacteristics = (chars: any[]) =>
    chars.map((character, index, arr) => (
      <View key={character.name} style={styles.characteristicsContainer}>
        <View style={styles.characteristicsInnerContainer}>
          <IconText iconSrc={character.icon}>{character.name}</IconText>
          <Text style={styles.characteristicsText}> {character.value}</Text>
        </View>
        {arr.length > index + 1 && <Viewider />}
      </View>
    ));

  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page}>
        <View>
          <TopGallery wrap={true} images={showContent.images} />
          <View style={styles.basicAndSpecificationWrapper}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <AddressIconText iconSrc={LocationWhiteIcon}>{offplanData?.nameEn}</AddressIconText>
              <View style={styles.pageTitleWrapper}>
                <Text style={styles.pageTitle}>{offplanData?.title}</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', gap: '16px', alignItems: 'flex-end' }}>
              <View style={{ flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                <Text style={styles.priceText}>from</Text>
                <Text style={styles.values}>{offplanData?.convertedPriceFrom}</Text>
                <Text style={styles.priceText}>to</Text>
                <Text style={styles.values}>{offplanData?.convertedPriceTo}</Text>
                <Text style={styles.values}>{offplanData?.currency}</Text>
              </View>
              <View style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                <Text style={styles.priceText}>from</Text>
                <Text style={styles.values}>{offplanData?.convertedBuildUpAreaFrom}</Text>
                <Text style={styles.priceText}>sqft</Text>
              </View>
            </View>
          </View>
          <View style={styles.characteristicsMainContainer}>
            <View>{renderCharacteristics(firstHalf)}</View>
            <View>{renderCharacteristics(secondHalf)}</View>
          </View>
          {newImage && (
            <GallerySection
              wrap={true}
              images={newImage}
              style={{ width: newImage.length > 3 ? '24%' : '32%' }}
              position='middle'
            />
          )}

          <Section wrap={true} style={{ marginTop: 50, maxWidth: '99%' }}>
            <Text>{offplanData?.description}</Text>
          </Section>
          {paymentPlan && <PaymentPlanSection paymentPlan={paymentPlan} />}

          <FeaturesSection data={offplanData} />

          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '48px',
              marginBottom: '80px',
              marginTop: '80px',
            }}
          >
            {(showContent.isBrokersInformationVisible ||
              showContent.isAgencyInformationVisible) && <Title>Contacts</Title>}
            {showContent.isBrokersInformationVisible && (
              <ContactSection contactInfo={personalInfo} />
            )}
            {showContent.isAgencyInformationVisible && (
              <ContactSection contactInfo={currentAgencyInfo} />
            )}
          </View>


          <GallerySection
            wrap={true}
            title='Gallery'
            images={showContent.images}
            style={{ width: '49%' }}
            position='bottom'
          />
        </View>
      </Page>
    </Document>
  );
}

const Separator = () => (
  <View
    style={{
      height: '100%',
      width: 4,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);

const Viewider = () => (
  <View
    style={{
      width: '100%',
      height: 2,
      backgroundColor: '#2A384233',
      marginHorizontal: 0,
    }}
  />
);
