import { DialogActions, DialogContent, List, Typography, Stack, Divider } from '@mui/material';
import { StyledButtonText } from '../../uiComponents/styled';
import { MyDialog } from '@/components';
import { privacyFirst, privacyTable, privacySecond, ListBlock, TableBlock } from '../utils/AgreementPolicy';

const PrivacyPolicy = ({ open, onClose }: { open: boolean; onClose: () => void }) => {

  return (
    <MyDialog open={open} onClose={onClose} dialogTitle='Privacy Policy' width='900'>
      <>
        <DialogContent
          dividers
          sx={{
            maxHeight: '600px',
            overflowY: 'auto',
            margin: 0,
            color: '#2A3842',
          }}
        >
          {privacyFirst.map((privacy, index) => (
            <ListBlock key={index} listBlock={privacy} />
          ))}
          <Typography sx={{ fontWeight: 600, marginBottom: '15px' }}>
            5. Legal grounds for processing and purpose
          </Typography>
          <Stack sx={{ marginLeft: '10px' }}>
            {privacyTable.map((privacy, index) => (
              <Stack key={index}>
                <TableBlock tableBlock={privacy} />
                {privacyTable.length > index + 1 && <Divider />}
              </Stack>
            ))}
          </Stack>
          {privacySecond.map((privacy, index) => (
            <ListBlock key={index} listBlock={privacy} />
          ))}
        </DialogContent>
        <DialogActions>
          <StyledButtonText onClick={onClose}>Back</StyledButtonText>
        </DialogActions>
      </>
    </MyDialog>
  );
};

export default PrivacyPolicy;
