import { CSSProperties, ReactElement } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import { AdModel } from '@/types';
import { Section } from '@/components/Ad/Section';
import { priceFormat } from '@/utils';
import { icons } from './Icons/icons';

export const ResaleBlock = ({ page }: { page: AdModel }) => {
  const toSeller = priceFormat(page.convertedToSeller || 0);
  const toDeveloper = priceFormat(page.convertedToDeveloper || 0);
  const postHandover = page.postHandover ? 'Yes' : 'No';
  const handover = `${page.handover?.quarter}/${page.handover?.year}`;
  const currency = page.convertedCurrency;

  return (
    <Section>
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Stack spacing={2}>
          <IconText icon={<icons.Seller />} label='To Seller: ' value={` ${toSeller} ${currency}`} />
          <IconText icon={<icons.Developer />} label='To Developer: ' value={` ${toDeveloper} ${currency}`} />
        </Stack>
        <Stack spacing={2}>
          {page.postHandover !== null && <IconText icon={<icons.PostHandover />} label='Post handover: ' value={` ${postHandover}`} />}
          {page.handover && <IconText icon={<icons.Handover />} label='Handover: ' value={` ${handover}`} />}
        </Stack>
      </Stack>
    </Section>
  );
};

const IconText = ({
  icon,
  label,
  value,
}: {
  icon: ReactElement;
  label: string;
  value: string;
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='left' spacing={1}>
      {icon}
      <Typography sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
        {label}
        <span style={{color: '#1650FF', fontWeight: 600}}>{value}</span>
      </Typography>
    </Stack>
  );
};
