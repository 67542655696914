import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationIcon from './clarify.svg';

interface OptionsMenuProps {
  onClarify: () => void;
}

export const OptionsMenu: React.FC<OptionsMenuProps> = ({ onClarify }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClarifyClick = () => {
    onClarify();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClarifyClick} sx={{ gap: '12px' }}>
          <img src={LocationIcon} alt='' />
          Clarify location
        </MenuItem>
      </Menu>
    </div>
  );
};
