import { Container, DialogActions, DialogContent } from '@mui/material';
import { MyDialog, MyButton } from '@/components';
import styles from './../../AdForm/AdForm.module.scss';

const ImpersonateUser = ({
  open,
  userName,
  onClose,
  onConfirm,
}: {
  open: boolean;
  userName: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Confirm login as a different user'
      width='900'
    >
      <Container component='main'>
        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', textAlign: 'center' }}>
          <div>
            You want to log in as <b>"{userName}"</b>, confirm your actions for further
            authorization in the system under the guise of this user.
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className={styles.dialogButtons}>
            <MyButton
              data={{
                buttonName: 'Log In',
                customWidth: '226px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={() => onConfirm()}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '226px',
                variant: 'outlined',
                buttonType: 'button',
                styleType: 'cancel',
              }}
              onClick={onClose}
            />
          </div>
        </DialogActions>
      </Container>
    </MyDialog>
  );
};

export default ImpersonateUser;
