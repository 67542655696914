import {
  AdFieldBathsEnum,
  QuartersEnum,
  CompletionStatusEnum,
  AdFieldFurnitureEnum,
  OffPlanPropertyKind,
  AdFieldRenovationEnum,
  AdFieldOccupancyEnum,
  DevelopmentStatusEnum,
  OffPlanFurnitureEnum,
} from '@/types';

export const propertyKindValues = [
  { label: 'Apartment', value: OffPlanPropertyKind.Apartments },
  { label: 'Townhouse', value: OffPlanPropertyKind.Townhouse },
  { label: 'Villa', value: OffPlanPropertyKind.Villa },
  { label: 'Penthouse', value: OffPlanPropertyKind.Penthouse },
  {
    label: 'Residential Building',
    value: OffPlanPropertyKind.ResidentialBuilding,
  },

  { label: 'Hotel Apartment', value: OffPlanPropertyKind.HotelApartment },
];

export const quarterValues = [
  { label: '1Q', value: '1Q' },
  { label: '2Q', value: '2Q' },
  { label: '3Q', value: '3Q' },
  { label: '4Q', value: '4Q' },
];

export const furnitureValues = [
  { label: 'Furnished', value: OffPlanFurnitureEnum.Furnished },
  { label: 'Unfurnished', value: OffPlanFurnitureEnum.Unfurnished },
];
export const renovationValues = [
  { label: 'Renovation', value: AdFieldRenovationEnum.Renovation },
  { label: 'Non renovation', value: AdFieldRenovationEnum.NonRenovation },
];

export const completionStatusValues = [
  { label: 'Ready', value: CompletionStatusEnum.Ready },
  { label: 'OffPlan', value: CompletionStatusEnum.OffPlan },
];
export const developentStatusValues = [
  { label: 'Planned', value: DevelopmentStatusEnum.PLANNED },
  { label: 'In Progress', value: DevelopmentStatusEnum.IN_PROGRESS },
  { label: 'Completed', value: DevelopmentStatusEnum.COMPLETED },
  { label: 'Stopped', value: DevelopmentStatusEnum.STOPPED },
];
export const offplanStatusValues = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
