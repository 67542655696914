import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { State } from '@/types';
import waitForeign from '@/components/Dashboard/img/waitForeign.svg';

export function ForeignBrokerWithoutVerification() {
  const user = useSelector(({ auth }: State) => auth.user);

  return (
    <section style={{ alignSelf: 'center', margin: '0 auto' }}>
      <Stack
        direction='column'
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '980px',
          textAlign: 'center',
        }}
      >
        <Box>
          <img style={{ width: '100%', objectFit: 'fill' }} src={waitForeign} alt='wait image' />
        </Box>
        <Typography
          variant='h4'
          sx={{
            textTransform: {
              xs: 'uppercase',
              sm: 'none',
            },
            fontSize: {
              xs: '20px',
              sm: '32px',
            },
            fontWeight: '700',
          }}
        >
          Dear
          <span style={{ color: '#1650FF' }}> {user.name}</span>
        </Typography>
        <Typography
          variant='body1'
          sx={{
            fontSize: 16,
          }}
        >
          Verification in progress. Expect confirmation shortly.
        </Typography>
      </Stack>
    </section>
  );
}
