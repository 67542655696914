import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MyTextField, MyButton, MyPhoneInput, MyLink } from '@/components';

export const LogInContentRenderer = ({
  control,
  setValue,
  dialogData,
  onSubmit,
  errors,
  // handleOpenDialog,
  onChange,
  onClick,
  emailOrPhoneInput,
  setLogInPhoneValue,
  setStepValue,
  stepValue,
  setError,
}) => {
  const { isLoading } = useSelector(({ app }) => app);
  const navigate = useNavigate();

  const handleButtonClick = (actionKey) => {
    switch (actionKey) {
      case 'confirmAction':
        break;
      case 'createPassword':
        break;
      case 'resetPassword':
        break;
      case 'signUpAction':
        // onSubmit();
        break;
      case 'logInAction':
        break;
      case 'supportAction':
        break;
      case 'okAction':
        // handleOpenDialog('success');
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const handleLinkClick = (actionKey) => {
    switch (actionKey) {
      case 'supportAction':
        // handleOpenDialog('operator');
        break;
      case 'forgotPasswordAction':
        setError(false);
        setStepValue(stepValue+1)
        break;
      case 'signUpAction':
        navigate('/signUp')
        // handleOpenDialog('logIn');
        // handleOpenDialog('signUp');
        break;
      case 'logInAction':
        // handleOpenDialog('logIn');
        // handleOpenDialog('signUp');
        break;
      case 'agreementAction':
        // handleOpenDialog('agreement');
        break;
      default:
        console.log(`Unknown action: ${actionKey}`);
    }
  };

  const onClickSecondLink = (action) => {
    if(action==='privacyPolicyAction'){
      // handleOpenDialog('privacyPolicy')
    }
  }

  return (
    <>
      <form onSubmit={onSubmit} style={{ width: '100%', margin: '5px 0' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {dialogData.logInContent.map((content, index) => {
            const commonProps = {
              data: content,
              control,
              errors: errors,
              disabled: content.disabled,
            };
            if (!content.initialVisibility) {
              return null;
            }
            switch (content.typeOfComponent) {
              case 'input':
                return (
                  <MyTextField
                    key={`${content.name}-${index}`}
                    {...commonProps}
                    emailOrPhoneInput={emailOrPhoneInput}
                    onChange={onChange}
                    onClick={onClick}
                  />
                );
              case 'phoneInput':
                return <MyPhoneInput 
                  key={content.name}
                  emailOrPhoneInput={emailOrPhoneInput}
                  setLogInPhoneValue={setLogInPhoneValue}
                  onChange={onChange}
                  setValue={setValue}
                  {...commonProps} 
                />;
              case 'button':
                return (
                  <MyButton
                    key={`${content.name}-${index}`}
                    data={content}
                    onClick={() => handleButtonClick(content.actionKey)}
                  />
                );
              case 'link':
                return (
                  <MyLink
                    key={`${content.name}-${index}`}
                    data={content}
                    onClickSecondLink={() => onClickSecondLink(content.actionKeySecond)}
                    onClick={() => handleLinkClick(content.actionKey)}
                  />
                );
            }
          })}
        </Stack>
      </form>
    </>
  );
};
