import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import ReactPlayer from 'react-player';
import { Title } from './styled';
import { SortableContext } from '@dnd-kit/sortable';
import { MediaApplication } from '../Admin/OffPlanAdmin/UploadMedia/MediaItem';
import { IAdImage } from '@/types';
import styles from './Ad.module.scss';

interface TabPanelProps {
  children: any;
  index?: number;
  value?: number;
  isMobile?: boolean;
  onImageClick?: (i: number) => void;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, isMobile, onImageClick, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children && children.length > 0 && (
        <Box>
          {children?.map((el: any, index: number) => (
            <ReactPlayer key={index} url={el.fileUrl} controls width='100%' height='auto' />
          ))}
        </Box>
      )}
    </div>
  );
};

export const MediaBlock = (props: any) => {
  const { videos, brochures, isMobile } = props;
  const [value, setValue] = useState(0);
  const [lightboxVideos, setLightboxVideos] = useState<any>(null);
  const mediaItems = brochures.map((el: any) => ({
    id: el.id,
    url: el.fileUrl,
    mediaType: el.mediaType,
    fileId: el.fileId,
  }));

  const handleVideoClick = (index: number) => lightboxVideos?.loadAndOpen(index);

  const openPdfInNewTab = async (pdfUrl: string) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      if (blob.type === 'application/pdf') {
        const pdfBlobUrl = URL.createObjectURL(blob);
        window.open(pdfBlobUrl);
      }
    } catch (error) {
      console.error('Ошибка при загрузке PDF:', error);
    }
  };

  return (
    <Stack alignItems='flex-start' spacing={1} sx={{ width: '100%', paddingTop: '10px' }}>
      {videos?.length > 0 && <Title>Video</Title>}
      {videos?.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <CustomTabPanel
            onImageClick={handleVideoClick}
            value={value}
            index={0}
            isMobile={isMobile}
          >
            {videos}
          </CustomTabPanel>
        </Box>
      )}
      {brochures?.length > 0 && <Title>Brochures</Title>}
      {brochures?.length > 0 && (
        <div className={styles.container}>
          <SortableContext items={mediaItems}>
            {mediaItems.map((image: IAdImage, index: number) => (
              <MediaApplication
                fileOriginalName={image.fileOriginalName}
                key={index}
                {...image}
                position={index + 1}
                openPdf={openPdfInNewTab}
              />
            ))}
          </SortableContext>
        </div>
      )}
    </Stack>
  );
};
