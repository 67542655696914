import { styled, shouldForwardProp } from '@mui/system';
import { Paper, Button, ButtonGroup } from '@mui/material';
import { ReactComponent as ArrowSquareDown } from './ArrowSquareDown.svg';
import { ReactComponent as ArrowSquareUp } from './ArrowSquareUp.svg';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  height: '40px',
  position: 'relative',
  overflow: 'visible',
  textTransform: 'none',
  color: active ? 'white' : '#1650FF',
  backgroundColor: active ? '#1650FF' : 'white',
  colorIcon: active ? 'green' : '#1650FF',
  padding: '8px 24px 8px 20px',
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    backgroundColor: active ? '#1650FF' : 'white',
    transform: active ? 'scale(1.05, 1.2)' : 'scale(1)',
    transition: 'transform 0.25s, background-color 0.25s',
    transformOrigin: active ? 'right center' : 'left center',
    zIndex: -1,
  },
}));

export const DoubleButtonBlock = ({
  btnReqForMe,
  handleMyReqBtnClick,
  handleReqForMeBtnClick,
}: {
  btnReqForMe: boolean;
  handleReqForMeBtnClick: () => void;
  handleMyReqBtnClick: () => void;
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
        backgroundColor: 'transparent',
        paddingBottom: '24px',
        margin: 'auto',
      }}
    >
      <ButtonGroup variant='contained' sx={{ p: 0, borderRadius: '22px 0 0 22px' }}>
        <StyledButton
          endIcon={<ArrowSquareDown />}
          active={btnReqForMe}
          sx={{
            zIndex: btnReqForMe ? 1 : 0,
            borderRadius: '22px 0 0 22px',
          }}
          onClick={() => handleReqForMeBtnClick()}
        >
          My Inbox
        </StyledButton>
      </ButtonGroup>

      <ButtonGroup variant='contained' sx={{ p: 0, borderRadius: '0 20px 20px 0' }}>
        <StyledButton
          endIcon={<ArrowSquareUp />}
          active={!btnReqForMe}
          sx={{
            zIndex: !btnReqForMe ? 1 : 0,
            borderRadius: '0 22px 22px 0',
          }}
          onClick={() => handleMyReqBtnClick()}
        >
          My Outbox
        </StyledButton>
      </ButtonGroup>
    </Paper>
  );
};
