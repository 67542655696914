import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';

import markerIcon from '@/components/AdForm/PropertyLocation/markerIcon.svg';
import { useMemo } from 'react';
import { DEFAULT_COORDINATE_LAT, DEFAULT_COORDINATE_LONG } from '@/constants';
import { Title } from '@/components/Ad/styled';
import { googleMapsOptions } from '@/utils/googleMapsOptions';

export const MapBlock = ({
  coordinatesLat,
  coordinatesLong,
}: {
  coordinatesLat?: string;
  coordinatesLong?: string;
}) => {
  const { isLoaded } = useJsApiLoader(googleMapsOptions);

  const coordinates = useMemo(
    () => ({
      lat: (coordinatesLat && Number(coordinatesLat)) || DEFAULT_COORDINATE_LAT,
      lng: (coordinatesLong && Number(coordinatesLong)) || DEFAULT_COORDINATE_LONG,
    }),
    [coordinatesLat, coordinatesLong]
  );

  const handleMapClick = () => {
    window.open(
      `https://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}/@${coordinates.lat},${coordinates.lng},17z`,
      '_blank'
    );
  };

  if (!isLoaded) return null;

  return (
    <section style={{ width: '100%' }}>
      <Title>Object on the map</Title>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '280px',
          borderRadius: '8px',
          marginTop: '5px',
          border: '1px solid #CFD5D9',
        }}
        center={coordinates}
        zoom={16.75}
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        onClick={handleMapClick}
      >
        <MarkerF position={coordinates} icon={markerIcon} onClick={handleMapClick} />
      </GoogleMap>
    </section>
  );
};
