import { styled, shouldForwardProp } from '@mui/system';
import {
  TextField,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
  DialogTitle,
  Paper,
  Dialog,
  ListItem,
  List,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';

interface IStyledTextFieldProps {
  fillColor?: string;
  customWidth?: string;
}

interface IStyleLabelProps {
  opacity?: number;
}

interface IStyledDialogProps {
  wide?: boolean;
  extraWide?: boolean;
}

interface StyledDialogProps {
  width?: string;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) && prop !== 'fillColor' && prop !== 'customWidth',
})<IStyledTextFieldProps>(({ theme, fillColor, customWidth }) => ({
  width: customWidth || '100%',
  '& .MuiInputBase-input': {
    fontSize: '16px',
    height: '48px',
    padding: '0 12px',
    width: '100%',
    color: '#2A3842',
  },
  '& .MuiInputBase-inputMultiline': {
    lineHeight: '28px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    backgroundColor: fillColor || '#ffffff',
  },
  // '& .MuiOutlinedInput-root.Mui-error .MuiInputBase-input': {
  //   fontSize: '12px !important',
  // },
  '& input::placeholder': {
    fontSize: '16px',
    color: '#2A3842',
  },
  '& input:focus::placeholder': {
    fontSize: '16px',
    color: '#2A3842',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0px !important',
  },
}));

export const StyledIconButton = styled(IconButton)({
  color: '#c5c5c5',
  position: 'absolute',
  right: '15px',
});

export const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    fontSize: '16px',
    height: '16px',
    lineHeight: '24px',
    padding: '12px',
    color: '#2A3842',
  },
  '& .MuisSelect-root': {
    height: '48px',
  },
});

export const StyledPhoneInput = styled(PhoneInput, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'error',
})(({ theme, error }) => ({
  width: '100%',
  '& .PhoneInputInput': {
    fontSize: '16px',
    height: '48px',
    borderRadius: '14px',
    marginLeft: '5px',
    paddingLeft: '10px',
    border: error ? '1px solid red' : '1px solid #E9EEF1',
    color: '#2A3842',
    outline: 'none',
    '&:focus': {
      border: error ? '1px solid red' : '1px solid #2A3842',
    },
  },
  '& .PhoneInputCountryIcon': {
    width: '38px',
    height: '25px',
    marginRight: '2px',
  },
}));

export const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginTop: '5px',
  textAlign: 'right',
}));

export const PlaceholderTypography = styled(Typography)({
  color: '#2A3842',
  fontSize: '16px',
});

export const StyledMenuItem = styled(MenuItem)({
  color: '#2A3842',
  fontSize: '16px',
});

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: StyledDialogProps) => ({
  '& .MuiDialog-paper': {
    width: width ? `${width}px` : '488px',
    maxWidth: '100%',
    borderRadius: '25px',
  },
  '& .MuiContainer-root' : {
    padding: '1px',
}
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) &&
    prop !== 'size' &&
    prop !== 'customWidth' &&
    prop !== 'styleType' &&
    prop !== 'colorIcons',
})<{
  size: string;
  customWidth?: string | number;
  styleType?: string;
  colorIcons?: string;
}>(({ size, theme, customWidth, styleType, colorIcons }) => ({
  background:
    styleType === 'unread'
      ? 'rgba(255, 255, 255, 0)'
      : styleType === 'cancel' || styleType === 'white'
        ? '#ffffff'
        : styleType === 'onlyIcon' || styleType === 'delete' || styleType === 'filters'
          ? '#c5dfff'
            : styleType === 'reset'
              ? '#e1eeff'
              : 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  color:
    styleType === 'cancel' || styleType === 'unread' || styleType === 'white'
      ? '#015cd5'
      : colorIcons
        ? colorIcons
        : styleType === 'delete' || styleType === 'filters'  || styleType === 'reset'
          ? '#2A3842'
          :undefined,
  border: styleType === 'cancel' || styleType === 'unread' ? '2px solid #015cd5' : undefined,
  fontFamily: '"Poppins", sans-serif',
  fontSize: size === 'medium' ? '16px' : size === 'small' ? '14px' : '20px',
  width: styleType === 'OPchat' ? '40px' : customWidth || '100%',
  minWidth: styleType === 'OPchat' ? '40px' : styleType === 'reset' ? '58px' : '64px',
  height:
    styleType === 'OPchat'
      ? '36px'
      : styleType === 'OP_CHAT' || styleType === 'unread' || styleType === 'edit' || styleType === 'delete' || styleType === 'white'
        ? '40px'
        : size === 'medium'
          ? '48px'
          : size === 'small'
            ? '32px'
            : '96px',
  padding: styleType === 'OPchat' || styleType === 'filters' || styleType === 'reset' ? '6px' : '6px 16px',
  textTransform: 'none',
  borderRadius:
    styleType === 'onlyIcon' || styleType === 'withoutIcon'
      ? '48px'
      : size === 'medium'
        ? '14px'
        : size === 'small'
          ? '8px'
          : '28px',
  fontWeight: 500,

  '&:hover': {
    boxShadow: '0 0 7px 2px rgba(1, 92, 213, .6)',
    background:
      styleType === 'unread'
        ? 'rgba(255, 255, 255, 0)'
        : styleType === 'cancel' || styleType === 'white'
          ? '#ffffff'
          : styleType === 'delete' || styleType === 'filters'
            ? '#c5dfff'
              : styleType === 'reset'
                ? '#e1eeff'
                : 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
    color: colorIcons ? '#ffffff' : undefined,
  },

  '&:disabled': {
    background: '#CCCCCC',
  },

  [theme.breakpoints.down('sm')]: {
    width:
      styleType === 'onlyIcon' ||
      styleType === 'withoutIcon' ||
      styleType === 'unread' ||
      styleType === 'OP_CHAT'  
        ? customWidth
        : '100%',
    margin: 0,
    minWidth: styleType === 'edit' || styleType === 'delete' ? '160px' : '100%',
  },
}));

export const StyledButtonIcon = styled(IconButton)({
  color: '#2A3842',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const ButtonContainer = styled(Grid)({
  paddingRight: '24px',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
});

export const StyledSquareTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    width: '64px',
    height: '64px',
    backgroundColor: '#F1F7FF',
  },
  '& .MuiInputBase-input': {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '24px',
    fontWeight: '800',
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '&.Mui-focused fieldset': {
      borderColor: '#F1F7FF',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F1F7FF',
  },
});

export const StyleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '700',
  color: '#2A3842',
  marginBottom: '10px',
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const StyleLabel = styled(Typography)<IStyleLabelProps>(({ opacity }) => ({
  color: '#2A3842',
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '3px',
  opacity: opacity ? 0 : 1,
}));

export const StyledButtonSubmit = styled(Button)({
  background: 'linear-gradient(90deg, #015cd5 0%, #016af8 100%)',
  fontSize: '16px',
  width: '100%',
  height: '56px',
  textTransform: 'none',
  borderRadius: '14px',
  fontWeight: 'normal',
});

export const StyledButtonText = styled(Button)({
  color: '#1650FF !important',
  fontWeight: '700',
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

export const CenterAlignStyleText = styled(Typography)({
  color: '#2A3842',
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: '10px',
});

export const RightAlignStyleText = styled(Typography)({
  color: '#2A3842',
  fontSize: '16px',
  textAlign: 'right',
});

export const LeftAlignStyleText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'left',
  textIndent: '20px',
  color: '#2A3842',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'fontSize' && prop !== 'fontWeight',
})<{ fontSize?: string | number; fontWeight?: string | number }>(
  ({ fontSize = '12px', fontWeight = '400' }) => ({
    color: '#1650FF',
    fontWeight,
    fontSize,
    textDecoration: 'none',
    '&:hover': {
      color: '#1650FF',
      textDecoration: 'underline',
    },
  })
);

export const RoundedPaper = styled(Paper)({
  borderRadius: '25px',
});

export const StyledListItemNum = styled(ListItem)(({ theme }) => ({
  fontSize: '16px',
  position: 'relative',
  color: '#2A3842',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '0px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#2A3842',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledListItemCirc = styled(ListItem)(({ theme }) => ({
  fontSize: '16px',
  position: 'relative',
  color: '#2A3842',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '0px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    borderColor: '#2A3842',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  listStyleType: 'none',
  counterReset: 'item',
  '& li': {
    position: 'relative',
    marginLeft: '20px',
    '&::before': {
      content: 'counter(item) "."',
      counterIncrement: 'item',
      position: 'absolute',
      left: '-5px',
      top: '8px',
    },
  },
}));
