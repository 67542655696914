import { AdCreatorType, AdMediaType, UserModel } from '@/types';
import { useMemo } from 'react';
import { Picture } from '@/components/PdfManager/PdfDocument/VerticalView/Picture';
import { StyleSheet, View, Image, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export interface GallerySectionProps {
  title?: string;
  images?: AdMediaType[] | NewBuildingsMediaType[];
  wrap?: boolean;
  style?: Style;
  contactInfo?: AdCreatorType | UserModel;
}

const styles = StyleSheet.create({
  infoText: {
    position: 'absolute',
    color: '#fff',
    top: 40,
    right: 40,
    fontSize: 40,
    width: '95%',
    textAlign: 'right',
  },
  galleryImage: {
    width: '100%',
    height: '718px',
    objectFit: 'cover',
    borderRadius: 8,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '10px',
  },
  left: {
    flex: 2,
    gap: 10,
  },
  right: {
    flex: 2,
    gap: 10,
  },
  topImg: {
    height: '1436px',
  },
});

export const TopGallery = ({ images = [], contactInfo }: GallerySectionProps) => {
  if (!images.length) return null;

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Picture src={images[0].fileUrl} style={styles.galleryImage} />
        <Picture src={images[1].fileUrl} style={styles.galleryImage} />
      </View>
      <View style={styles.right}>
        <Picture src={images[2].fileUrl} style={images.length < 4 ? [styles.galleryImage, styles.topImg] : styles.galleryImage} />
        {images.length >= 4 && <Picture src={images[3].fileUrl} style={styles.galleryImage} />}
        <Text style={styles.infoText}>PDF belongs to the {contactInfo?.name}</Text>
      </View>
    </View>
  );
};
