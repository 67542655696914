import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Modal, Typography } from '@mui/material';
import { State, UserKindEnum } from '@/types';
import PersonalInfo from './PersonalInfo';
import MainInfo from './MainInfo';
import { ListingsAndAgencyStaff } from './ListingsAndAgencyStaff';
import CloseIcon from '@mui/icons-material/Close';

import {
  brokerPersonalInfo,
  managerPersonalInfo,
  foreignBrokerPersonalInfo,
  companyPersonalInfo,
} from '../../utils/ViewProfileDescription';
import { dialogUserTypeData } from '../../utils/dialogDescription';
import { getKindLabel } from '@/utils';
import styles from './MainInfo.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { default as EmailBg } from './email-unsubscribe-bg.png';
import { MyButton } from '@/components';
import { emailNotificationProvider } from '@/providers/emailNotificationProvider';

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ViewProfile = () => {
  const user = useSelector(({ auth }: State) => auth.user);
  const userType = user.kind ? getKindLabel(user.kind) : '';
  const { pathname, search } = useLocation();
  const { type } = useParams();
  const isUnsubscribe = pathname.includes('me/unsubscribe');
  const [modalOpen, setModalOpen] = useState(isUnsubscribe);
  const navigate = useNavigate();

  const isAgency = user.kind === UserKindEnum.Agency;

  useEffect(() => {
    localStorage.removeItem('unsubscribe');
    localStorage.removeItem('pathName');
    if (isUnsubscribe) {
      emailNotificationProvider.unsubscribe(`${type}${search}`);
    }
  }, []);

  const personalInfo = () => {
    switch (user?.kind) {
      case 'broker':
        return brokerPersonalInfo;
      case 'agency':
        return companyPersonalInfo;
      case 'developer':
        return companyPersonalInfo;
      case 'manager':
        return managerPersonalInfo;
      case 'foreign':
        return foreignBrokerPersonalInfo;
      case 'admin':
        return null;
      default:
        console.log(`Unknown action: ${user?.kind}`);
    }
  };

  return (
    <>
      <Grid className={styles.container}>
        <MainInfo user={user} userType={userType} type='view' dataQa='view_profile'/>
        <PersonalInfo user={user} personalInfo={personalInfo()} type='view' dataQa='view_profile'/>
        {/* {isAgency && <ListingsAndAgencyStaff />} */}
        {modalOpen && (
          <Modal sx={modalStyle} open={true}>
            <div className={styles.modalBody}>
              <CloseIcon className={styles.closeIcon} onClick={() => navigate('/')} />
              <img src={EmailBg} alt={'...'} />
              <Typography
                sx={{
                  fontSize: '28px',
                  fontWeight: '700',
                  textAlign: 'center',
                  color: '#2A3842',
                }}
              >
                You have successfully unsubscribed
              </Typography>
              <Typography>
                "Congratulations! You've successfully unsubscribed from our email newsletter. We'll
                miss having you as part of our community, but respect your decision. Should you ever
                decide to rejoin, we'll be here with open arms. Thank you for being with us!"
              </Typography>
              <MyButton
                data={{
                  buttonName: 'Main Page',
                  customWidth: '500px',
                  size: 'medium',
                  variant: 'contained',
                  buttonType: 'button',
                }}
                onClick={() => navigate('/')}
              />
            </div>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default ViewProfile;
