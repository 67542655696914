import PreviewVersion1 from '../../pdfPreviews/allInOne.png';
import classicView from '../../pdfPreviews/classicView.png';
import horizontalView from '../../pdfPreviews/horizontalView.png';
import verticalView from '../../pdfPreviews/verticalView.png';
import allInOneView from '../../pdfPreviews/allInOne.png';
import luxView from '../../pdfPreviews/luxView.png';
import portableView from '../../pdfPreviews/portableView.png';
import onePageView from '../../pdfPreviews/onePage.png';

import { StyledPdfPreviewCard } from './PdfPreviewCard.styles';

const thumbImages = {
  stubThumb: PreviewVersion1,
  'horizontal-view': horizontalView,
  'vertical-view': verticalView,
  // 'classic-view': classicView,
  // 'all-in-one-view': allInOneView,
  // 'lux-view': luxView,
  // 'one-page': onePageView,
  // 'portable-view': portableView,
};

export const PdfPreviewCard = ({ cardName = 'stubThumb' }: { cardName: string }) => {
  const thumb = thumbImages[cardName as keyof typeof thumbImages] || thumbImages.stubThumb;
  return (
    <StyledPdfPreviewCard>
      <img src={thumb} alt={`${cardName} preview`} />
    </StyledPdfPreviewCard>
  );
};
