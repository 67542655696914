import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { formOfOrganization, country } from './fakeData';
import { IDialogData, IDialogContent } from './types';
import { PhoneCallIcon, WhatsappIcon, TelegramIcon, EnvelopeIcon, ChatIcon } from './icons';

const dialogConfig = {
  dataQa: 'close_btn',
  width: '488',
  dialogTitle: {
    label: 'Registration',
  },
};

const userType: IDialogContent = {
  dataQa: 'select_user_field',
  name: 'selectUserType',
  label: '',
  placeholder: 'Select user type',
  options: [],
  initialVisibility: true,
  typeOfComponent: 'select',
};
const profileVerificationOptions: IDialogContent = {
  dataQa: 'profile_option_field',
  name: 'profileVerificationOptions',
  label: 'Profile Verification Options',
  placeholder: 'Enter your name or phone number or license ID',
  options: [],
  initialVisibility: true,
  typeOfComponent: 'autocomplete',
};
const support: IDialogContent = {
  dataQa: 'support_link',
  name: 'support',
  label: 'or request a support',
  iconInfo: true,
  background: true,
  actionKey: 'supportAction',
  tooltip: 'This button requests a support team',
  fontSize: '16px',
  fontWeight: '600',
  alignment: 'flex-end',
  initialVisibility: true,
  typeOfComponent: 'link',
};
const name: IDialogContent = {
  dataQa: 'name_field',
  name: 'name',
  label: 'Your full name',
  placeholder: 'Enter your full name',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const managerName: IDialogContent = {
  dataQa: 'name_field',
  name: 'name',
  label: 'Your full name',
  placeholder: 'Enter your full name',
  initialVisibility: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const companyName: IDialogContent = {
  dataQa: 'company_field',
  name: 'companyName',
  label: 'Company name',
  placeholder: 'Enter your company name',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const organization: IDialogContent = {
  dataQa: 'name_field',
  name: 'companyName',
  label: 'Company name',
  placeholder: 'Enter your company name',
  initialVisibility: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const emailOrPhone: IDialogContent = {
  dataQa: 'email_field',
  name: 'emailOrPhone',
  label: 'Your email or phone number',
  placeholder: 'Enter your email or phone number',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const email: IDialogContent = {
  dataQa: 'email_field',
  name: 'email',
  label: 'Your email',
  placeholder: 'Your email',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const phone: IDialogContent = {
  dataQa: 'phone_field',
  name: 'phone',
  label: 'Your phone number',
  placeholder: 'Your phone number',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'phoneInput',
};
const managerEmailOrPhone: IDialogContent = {
  dataQa: 'email_field',
  name: 'managerEmailOrPhone',
  label: 'Your email',
  placeholder: 'Enter your email',
  initialVisibility: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const passwordDisabled: IDialogContent = {
  dataQa: 'create_pass_field',
  name: 'password',
  label: 'Create password',
  placeholder: 'Create your password',
  endAdornment: true,
  initialVisibility: true,
  disabled: true,
  maxLength: 20,
  typeOfComponent: 'input',
};
const confirmPasswordDisabled: IDialogContent = {
  dataQa: 'confirm_pass_field',
  name: 'confirmPassword',
  label: 'Confirm password',
  placeholder: 'Confirm your password',
  endAdornment: true,
  initialVisibility: true,
  disabled: true,
  maxLength: 20,
  typeOfComponent: 'input',
};
const password: IDialogContent = {
  dataQa: 'create_pass_field',
  name: 'password',
  label: 'Create password',
  placeholder: 'Create your password',
  endAdornment: true,
  initialVisibility: true,
  maxLength: 20,
  typeOfComponent: 'input',
};
const confirmPassword: IDialogContent = {
  dataQa: 'confirm_pass_field',
  name: 'confirmPassword',
  label: 'Confirm password',
  placeholder: 'Confirm your password',
  endAdornment: true,
  initialVisibility: true,
  maxLength: 20,
  typeOfComponent: 'input',
};
const agreement: IDialogContent = {
  name: 'agreement',
  label: 'Terms of the User Agreement',
  labelSecond: 'Privacy Policy',
  passiveLabel: 'By registration you accept our',
  passiveLabelFinish: 'and',
  background: true,
  actionKey: 'agreementAction',
  actionKeySecond: 'privacyPolicyAction',
  fontSize: '14px',
  fontWeight: '800',
  alignment: 'flex-end',
  initialVisibility: true,
  typeOfComponent: 'link',
};
const signUp: IDialogContent = {
  dataQa: 'pop_up_sign_btn',
  name: 'signUp',
  buttonName: 'Get Started!',
  variant: 'contained',
  buttonType: 'submit',
  actionKey: 'signUpAction',
  initialVisibility: true,
  disabled: true,
  typeOfComponent: 'button',
};
const logIn: IDialogContent = {
  name: 'logIn',
  label: 'Log in.',
  passiveLabel: 'Already registered?',
  background: true,
  actionKey: 'logInAction',
  fontSize: '16px',
  fontWeight: '800',
  alignment: 'center',
  initialVisibility: true,
  typeOfComponent: 'link',
};
const error409: IDialogContent = {
  name: 'error409',
  label: 'Log in',
  passiveLabel:
    'This phone or email is already registered. Enter a different phone or email number or',
  passiveLabelFinish: 'to your account.',
  fontSize: '12px',
  fontWeight: '800',
  alignment: 'center',
  background: true,
  actionKey: 'logInAction',
  initialVisibility: false,
  typeOfComponent: 'link',
};
const error: IDialogContent = {
  name: 'error',
  label: '',
  passiveLabel: '',
  fontSize: '12px',
  fontWeight: '800',
  alignment: 'center',
  background: true,
  initialVisibility: false,
  typeOfComponent: 'link',
};
const phoneNumber: IDialogContent = {
  dataQa: 'phone_field',
  name: 'phoneNumber',
  label: 'Your phone number',
  placeholder: 'Enter your phone number',
  initialVisibility: false,
  typeOfComponent: 'phoneInput',
};
const officeNumber: IDialogContent = {
  name: 'officeNumber',
  label: 'Office number',
  placeholder: 'Office number',
  initialVisibility: true,
  disabled: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const company: IDialogContent = {
  name: 'company',
  label: 'Your agency',
  options: [],
  placeholder: 'Enter name of your agency',
  initialVisibility: true,
  typeOfComponent: 'autocomplete',
};
const formOrganization: IDialogContent = {
  name: 'formOfOrganization',
  label: 'Form of organization',
  placeholder: 'Select form of organization',
  options: formOfOrganization.map((el) => el.label),
  initialVisibility: true,
  typeOfComponent: 'select',
};
const userCountry: IDialogContent = {
  name: 'country',
  label: 'Choose your country',
  placeholder: 'Select country',
  options: country,
  initialVisibility: true,
  typeOfComponent: 'select',
};
const city: IDialogContent = {
  name: 'city',
  label: 'Choose your city',
  placeholder: 'Enter city name',
  initialVisibility: true,
  maxLength: 80,
  typeOfComponent: 'input',
};
const social: IDialogContent = {
  name: 'social',
  label: 'Your social media (Instagram / Linkedin)',
  placeholder: 'Enter links to your social networks',
  initialVisibility: true,
  maxLength: 500,
  typeOfComponent: 'input',
};
const other: IDialogContent = {
  name: 'other',
  label: 'Other',
  placeholder: 'Telegram channel, Whatsapp channel or other link',
  initialVisibility: true,
  maxLength: 500,
  typeOfComponent: 'input',
};

const additionalBlok = [password, confirmPassword, agreement, signUp, logIn];
const errorBlok = [error409, error];

const dialogSignUpData: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    userType,
    {
      name: 'profileVerificationOptions',
      dataQa: 'profile_option_field',
      label: 'Profile Verification Options',
      placeholder: 'Enter your name or phone number or license ID',
      options: [],
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'autocomplete',
    },
    support,
    name,
    companyName,
    email,
    phone,
    passwordDisabled,
    confirmPasswordDisabled,
    agreement,
    signUp,
    logIn,
  ],
};

const dialogSignUpDataBroker: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Licensed Agent',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    profileVerificationOptions,
    ...errorBlok,
    support,
    name,
    companyName,
    email,
    phone,
    ...additionalBlok,
  ],
};

const dialogSignUpDataAgency: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Real estate agency',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    profileVerificationOptions,
    ...errorBlok,
    support,
    companyName,
    officeNumber,
    email,
    phone,
    ...additionalBlok,
  ],
};

const dialogSignUpDataDeveloper: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Developer',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    profileVerificationOptions,
    ...errorBlok,
    support,
    companyName,
    officeNumber,
    email,
    phone,
    ...additionalBlok,
  ],
};

const dialogSignUpDataManager: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Agency Manager',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    ...errorBlok,
    managerName,
    company,
    emailOrPhone,
    phoneNumber,
    ...additionalBlok,
  ],
};

const dialogSignUpDataForeign: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Foreign Agent',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    ...errorBlok,
    formOrganization,
    name,
    // companyName,
    {
      name: 'country',
      label: 'Choose your country',
      placeholder: 'Select country',
      options: country,
      initialVisibility: true,
      disabled: true,
      typeOfComponent: 'select',
    },
    {
      name: 'city',
      label: 'Choose your city',
      placeholder: 'Enter city name',
      initialVisibility: true,
      disabled: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    emailOrPhone,
    {
      name: 'social',
      label: 'Your social media (Instagram / Linkedin)',
      placeholder: 'Enter links to your social networks',
      initialVisibility: true,
      disabled: true,
      maxLength: 500,
      typeOfComponent: 'input',
    },
    {
      name: 'other',
      label: 'Other',
      placeholder: 'Telegram channel, Whatsapp channel or other link',
      initialVisibility: true,
      disabled: true,
      maxLength: 500,
      typeOfComponent: 'input',
    },
    ...additionalBlok,
  ],
};

const dialogSignUpDataForeignPerson: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Foreign Agent',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    ...errorBlok,
    {
      name: 'formOfOrganization',
      label: 'Form of organization',
      placeholder: 'Private person',
      options: formOfOrganization.map((el) => el.label),
      initialVisibility: true,
      typeOfComponent: 'select',
    },
    managerName,
    userCountry,
    city,
    {
      dataQa: 'email_field',
      name: 'emailOrPhone',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    phoneNumber,
    social,
    other,
    ...additionalBlok,
  ],
};

const dialogSignUpDataForeignOrganization: IDialogData = {
  ...dialogConfig,
  dialogContent: [
    {
      name: 'selectUserType',
      label: '',
      placeholder: 'Foreign Agent',
      options: [],
      initialVisibility: true,
      typeOfComponent: 'select',
      dataQa: 'select_user_field',
    },
    ...errorBlok,
    {
      name: 'formOfOrganization',
      label: 'Form of organization',
      placeholder: 'Organization',
      options: formOfOrganization.map((el) => el.label),
      initialVisibility: true,
      typeOfComponent: 'select',
    },
    organization,
    userCountry,
    city,
    {
      name: 'emailOrPhone',
      dataQa: 'email_field',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    phoneNumber,
    social,
    other,
    ...additionalBlok,
  ],
};

const dialogLogInData: IDialogData = {
  width: '488',
  dialogTitle: {
    label: 'Authorization',
  },
  dialogContent: [
    {
      name: 'emailOrPhone',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'phoneNumber',
      label: 'Your phone number',
      placeholder: 'Enter your phone number',
      initialVisibility: false,
      typeOfComponent: 'phoneInput',
    },
    {
      name: 'error',
      label: 'Sign up',
      passiveLabel: 'Invalid login. Check your email/password or',
      passiveLabelFinish: '',
      background: true,
      actionKey: 'signUpAction',
      fontSize: '12px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'error400',
      label: '',
      passiveLabel: 'Login and password should not be empty.',
      background: true,
      fontSize: '12px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'password',
      label: 'Your password',
      placeholder: 'Password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'forgotPassword',
      label: 'Forgot password?',
      background: true,
      actionKey: 'forgotPasswordAction',
      fontSize: '14px',
      fontWeight: '500',
      alignment: 'flex-end',
      initialVisibility: true,
      typeOfComponent: 'link',
    },
    {
      name: 'logIn',
      buttonName: 'Log in',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'logInAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
    {
      name: 'signUp',
      label: 'Sign up',
      passiveLabel: "Don't have an account yet?",
      background: true,
      actionKey: 'signUpAction',
      fontSize: '16px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: true,
      typeOfComponent: 'link',
    },
  ],
};

const dialogResetPasswordData: IDialogData = {
  width: '488',
  dialogTitle: {
    label: 'Reset password',
    description: 'To reset your password, enter your email address or phone number.',
  },
  dialogContent: [
    {
      name: 'emailOrPhone',
      label: 'Your email or phone number',
      placeholder: 'Enter your email or phone number',
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'phoneNumber',
      label: 'Your phone number',
      placeholder: 'Enter your phone number',
      initialVisibility: false,
      typeOfComponent: 'phoneInput',
    },
    {
      name: 'error',
      label: '',
      passiveLabel: 'Please check your email or phone',
      background: true,
      actionKey: 'signUpAction',
      fontSize: '12px',
      fontWeight: '500',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'error400',
      label: '',
      passiveLabel: 'Login can only be email, phone.',
      background: true,
      fontSize: '12px',
      fontWeight: '800',
      alignment: 'center',
      initialVisibility: false,
      typeOfComponent: 'link',
    },
    {
      name: 'password',
      buttonName: 'Reset password',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'resetPassword',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

const dialogConfirmationData: IDialogData = {
  width: '656',
  dialogTitle: {
    label: 'Email Verification',
    descriptionEmail: `Enter the code from your email. Don't forget to check your spam folder.`,
    descriptionPhone:
      'A verification code has been sent to your mobile phone, please enter it in the field below.',
  },
  dialogContent: [
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      customWidth: '358px',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

const dialogConfirmationChangePasswordData: IDialogData = {
  width: '656',
  dialogTitle: {
    label: 'Change password',
    description: `Enter the code from your email. Don't forget to check your spam folder.`,
  },
  dialogContent: [
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      customWidth: '358px',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

const dialogConfirmationChangeLoginData: IDialogData = {
  width: '656',
  dialogTitle: {
    label: 'Change Login',
    description: `Enter the code from your email or phone. Don't forget to check your spam folder.`,
  },
  dialogContent: [
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      customWidth: '358px',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};


const dialogSuccessData: IDialogData = {
  width: '500',
  dialogTitle: {
    label: 'Success',
    description: `Your password was changed`,
  },
  dialogContent: [
    {
      name: 'ok',
      buttonName: 'Ok',
      variant: 'contained',
      customWidth: '358px',
      actionKey: 'okAction',
      initialVisibility: true,
      typeOfComponent: 'button',
      styleType: 'dialog',
    },
  ],
};

const dialogWarningData: IDialogData = {
  width: '500',
  dialogTitle: {
    label: 'Warning',
    description: `The user did not specify Telegram/Whatsapp`,
  },
  dialogContent: [
    {
      name: 'ok',
      buttonName: 'Ok',
      variant: 'contained',
      customWidth: '358px',
      actionKey: 'okAction',
      initialVisibility: true,
      typeOfComponent: 'button',
      styleType: 'dialog',
    },
  ],
};

const dialogCreatePasswordData: IDialogData = {
  width: '488',
  dialogTitle: {
    label: 'Reset password',
    description: 'Create a new strong password for further authorization in our system.',
  },
  dialogContent: [
    {
      name: 'password',
      label: 'New password',
      placeholder: 'Create new password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'confirmPassword',
      label: 'Confirm password',
      placeholder: 'Confirm your password',
      endAdornment: true,
      initialVisibility: true,
      maxLength: 20,
      typeOfComponent: 'input',
    },
    {
      name: 'createPassword',
      buttonName: 'Create password',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'createPassword',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

const dialogOperatorData: IDialogData = {
  width: '646',
  dialogTitle: {
    label: 'Formation of an application for consultation by the operator',
    description:
      'In order for the operator to be able to quickly and efficiently help you solve your problem, please describe its essence. This will speed up the process.',
  },
  dialogContent: [
    {
      name: 'name',
      label: 'Your Name',
      placeholder: 'Enter your name',
      isRequired: true,
      initialVisibility: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    {
      name: 'emailOrPhone',
      label: 'Your email',
      placeholder: 'Enter your email',
      initialVisibility: true,
      isRequired: true,
      maxLength: 80,
      typeOfComponent: 'input',
    },
    // {
    //   name: 'phoneNumber',
    //   label: 'Your phone number',
    //   placeholder: 'Enter your phone number',
    //   // isRequired: true,
    //   initialVisibility: true,
    //   typeOfComponent: 'phoneInput',
    // },
    {
      name: 'description',
      label: 'Describe the problem',
      placeholder: 'Enter your message',
      multiline: true,
      initialVisibility: true,
      maxLength: 2000,
      typeOfComponent: 'input',
    },
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      customWidth: '358px',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      styleType: 'dialog',
      typeOfComponent: 'button',
    },
  ],
};

const dialogOperatorSuccessData: IDialogData = {
  width: '646',
  dialogTitle: {
    label: 'Your request has been successfully generated',
  },
  dialogContent: [
    {
      name: 'picture',
      label: 'picture',
      initialVisibility: true,
      customWidth: '230px',
      typeOfComponent: 'picture',
    },
    {
      name: 'description',
      label:
        'Your request has been successfully generated and submitted for consideration to our operator. We will contact you as soon as possible and help you solve your problem.',
      multiline: true,
      initialVisibility: true,
      typeOfComponent: 'text',
    },
    {
      name: 'close',
      buttonName: 'Close',
      variant: 'contained',
      customWidth: '358px',
      actionKey: 'closeOperatorSuccessAction',
      initialVisibility: true,
      styleType: 'dialog',
      typeOfComponent: 'button',
    },
  ],
};

const dialogProfilePhoto: IDialogData = {
  width: '877',
  dialogTitle: {
    label: 'PROFILE PHOTO',
  },
  dialogContent: [
    // {
    //   name: 'edit',
    //   buttonName: 'Edit photo',
    //   variant: 'outlined',
    //   customWidth: '170px',
    //   styleType: 'cancel',
    //   actionKey: 'editPhotoAction',
    //   initialVisibility: true,
    //   buttonsIcon: true,
    //   icon: <EditOutlinedIcon />,
    //   typeOfComponent: 'button',
    // },
    {
      name: 'upload',
      buttonName: 'Upload new',
      variant: 'outlined',
      customWidth: '170px',
      styleType: 'cancel',
      actionKey: 'uploadPhotoAction',
      initialVisibility: true,
      buttonsIcon: true,
      icon: <FileUploadOutlinedIcon />,
      typeOfComponent: 'button',
    },
    {
      name: 'delete',
      buttonName: 'Delete',
      variant: 'outlined',
      customWidth: '170px',
      styleType: 'cancel',
      actionKey: 'deletePhotoAction',
      initialVisibility: true,
      buttonsIcon: true,
      icon: <DeleteOutlinedIcon />,
      typeOfComponent: 'button',
    },
  ],
};

const socialButtonsData: IDialogContent[] = [
  {
    name: 'phone',
    styleType: 'onlyIcon',
    icon: <PhoneCallIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '78px',
    actionKey: 'phoneAction',
    typeOfComponent: 'button',
    dataQa: 'phone_btn',
  },
  {
    name: 'whatsapp',
    styleType: 'onlyIcon',
    icon: <WhatsappIcon />,
    colorIcons: '#00AE13',
    variant: 'contained',
    customWidth: '78px',
    actionKey: 'whatsappAction',
    typeOfComponent: 'button',
    dataQa: 'whatsapp_btn',
  },
  {
    name: 'telegram',
    styleType: 'onlyIcon',
    icon: <TelegramIcon />,
    colorIcons: '#0080C1',
    variant: 'contained',
    customWidth: '78px',
    actionKey: 'telegramAction',
    typeOfComponent: 'button',
    dataQa: 'telegram_btn',
  },
  {
    name: 'email',
    styleType: 'onlyIcon',
    icon: <EnvelopeIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '78px',
    actionKey: 'emailAction',
    typeOfComponent: 'button',
    dataQa: 'email_btn',
  },
];

const smallSocialButtonsData: IDialogContent[] = [
  {
    name: 'chat',
    styleType: 'onlyIcon',
    icon: <ChatIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '40px',
    actionKey: 'opChatAction',
    typeOfComponent: 'button',
  },
  {
    name: 'phone',
    styleType: 'onlyIcon',
    icon: <PhoneCallIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '40px',
    actionKey: 'phoneAction',
    typeOfComponent: 'button',
  },
  {
    name: 'telegram',
    styleType: 'onlyIcon',
    icon: <TelegramIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '40px',
    actionKey: 'telegramAction',
    typeOfComponent: 'button',
  },
  {
    name: 'whatsapp',
    styleType: 'onlyIcon',
    icon: <WhatsappIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '40px',
    actionKey: 'whatsappAction',
    typeOfComponent: 'button',
  },
  {
    name: 'email',
    styleType: 'onlyIcon',
    icon: <EnvelopeIcon />,
    colorIcons: '#2A3842',
    variant: 'contained',
    customWidth: '40px',
    actionKey: 'emailAction',
    typeOfComponent: 'button',
  },
];

const dialogUserTypeData: IDialogData = {
  width: '488',
  dialogTitle: {
    label: 'Get Verified',
  },
  dialogContent: [
    {
      name: 'radioGroup',
      label: '',
      radioGroup: [
        {
          name: 'broker',
          label: 'Licensed Agent',
          type: 'broker',
          description: 'Dubai-licensed real estate professional conducting market transactions.',
        },
        {
          name: 'manager',
          label: 'Agency Manager',
          type: 'manager',
          description: 'Handles administrative tasks and client interactions.',
        },
        {
          name: 'agency',
          label: 'Real estate agency',
          type: 'agency',
          description: 'DLD-licensed company trading properties, hiring users to work under it.',
        },
        {
          name: 'developer',
          label: 'Developer',
          type: 'agency',
          description: 'Constructs, markets properties, and trades while employing personnel.',
        },
        {
          name: 'foreign',
          label: 'Foreign Agent',
          type: 'foreign',
          description: 'Operates as an individual or legal entity without a Dubai license.',
        },
      ],
      initialVisibility: true,
      typeOfComponent: 'radioGroup',
    },
    {
      name: 'errorText',
      label: 'Please select your type',
      initialVisibility: false,
      typeOfComponent: 'errorText',
    },
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

const dialogUserTypeDataAdmin: IDialogData = {
  width: '488',
  dialogTitle: {
    label: 'Get Verified',
  },
  dialogContent: [
    {
      name: 'radioGroup',
      label: '',
      radioGroup: [
        {
          name: 'manager',
          label: 'Agency Manager',
          type: 'manager',
          description:
            'An Agency Manager is a user responsible for administrative tasks and client interactions at an agency or developer but not involved in trading.',
        },
        {
          name: 'developer',
          label: 'Developer',
          type: 'agency',
          description:
            'A construction company that builds its properties, puts them on the market, and can also engage in trading while having its personnel.',
        },
      ],
      initialVisibility: true,
      typeOfComponent: 'radioGroup',
    },
    {
      name: 'errorText',
      label: 'Please select your type',
      initialVisibility: false,
      typeOfComponent: 'errorText',
    },
    {
      name: 'confirm',
      buttonName: 'Confirm',
      variant: 'contained',
      buttonType: 'submit',
      actionKey: 'confirmAction',
      initialVisibility: true,
      typeOfComponent: 'button',
    },
  ],
};

export {
  dialogSignUpData,
  dialogSignUpDataBroker,
  dialogSignUpDataAgency,
  dialogSignUpDataDeveloper,
  dialogSignUpDataManager,
  dialogSignUpDataForeign,
  dialogSignUpDataForeignPerson,
  dialogSignUpDataForeignOrganization,
  dialogUserTypeData,
  dialogOperatorData,
  dialogLogInData,
  dialogResetPasswordData,
  dialogConfirmationData,
  dialogCreatePasswordData,
  dialogOperatorSuccessData,
  dialogProfilePhoto,
  socialButtonsData,
  dialogConfirmationChangePasswordData,
  dialogSuccessData,
  dialogWarningData,
  dialogUserTypeDataAdmin,
  smallSocialButtonsData,
  dialogConfirmationChangeLoginData,
};
