import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import { User } from '../types/types';

const StyledTableCell = styled(TableCell)({
  border: 0,
  borderRight: '1px solid #E9EEF1',
  '&:last-child': {
    borderRight: 'none',
  },
  verticalAlign: 'middle',
  '&.header-cell': {
    fontWeight: 600
  },
  '&.user-type-cell, &.registration-cell, &.last-seen-cell': {
    minWidth: '150px'
  }
});

const TableCellWithMenu = styled(StyledTableCell)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: 2px solid #c5dfff;
  border-radius: 12px;

  &.foreign-item {
    color: #fff;
  }
`;

const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 230px;
`;

const UserName = styled('div')`
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.foreign-item {
    color: #fff;
  }
`;

const UserRole = styled('div')`
  color: gray;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserAgency = styled('span')`
  color: #01439b;
  font-size: 14px;
`;

const UserDate = styled('span')`
  font-size: 14px;
  &.foreign-item {
    color: #fff;
  }
`;

const StyledTableContainer = styled(TableContainer)({
  background: 'transparent',
  boxShadow: 'none',
  overflowX: 'auto',
  maxWidth: '1003px',
  '&::-webkit-scrollbar': {
    borderRadius: '9px',
    height: '6px',
    backgroundColor: 'rgba(233, 238, 241, 1)',
    width: '300px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(42, 56, 66, 1)',
    borderRadius: '9px',
    height: '6px'
  },
});

const StyledTableRow = styled(TableRow)(`
border: 1px solid #E9EEF1;
background: inherit;

&.foreign-row {
  background-color: #2A3842;
}
`);

const StyledTableHead = styled(TableHead)({
  background: '#fff',
});

const formatDate = (value: string | null) =>
  value !== null ? moment(value).format('D MMMM YYYY, h:mm a') : null;

interface UserTableProps {
  users: User[];
  total: number;
  handleMenuClick: (event: React.MouseEvent, userId: any) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, total, handleMenuClick }) => {
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <StyledTableRow  >
            <StyledTableCell className={`header-cell`}>All users ({total})</StyledTableCell>
            <StyledTableCell className={`header-cell user-type-cell`}>User type</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Phone number</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Email</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Active listings</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Draft listings</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Archived listings</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Notifications</StyledTableCell>
            <StyledTableCell className={`header-cell`}>Client requests</StyledTableCell>
            <StyledTableCell className={`header-cell registration-cell`}>Registration date</StyledTableCell>
            <StyledTableCell className={`header-cell last-seen-cell`}>Last seen</StyledTableCell>
            <StyledTableCell className={`header-cell`}></StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {users.map((user, index) => (
            <StyledTableRow key={index} className={`${user.kind}-row`}>
              <StyledTableCell>
                <UserInfo>
                  <UserName className={`${user.kind}-item`}>
                    {user.name}
                    {user.status === 'blocked' && <BlockIcon fontSize='small' />}
                  </UserName>
                  <UserRole>
                    {user.employer.name && <UserAgency>{user.employer.name}</UserAgency>}
                  </UserRole>
                </UserInfo>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.kind}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.phone}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.email}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.activeListings}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.draftListings}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.archivedListings}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.notifications}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{user.clientRequests}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{formatDate(user.createdAt)}</UserDate>
              </StyledTableCell>
              <StyledTableCell>
                <UserDate className={`${user.kind}-item`}>{formatDate(user.lastSeen)}</UserDate>
              </StyledTableCell>
              <TableCellWithMenu>
                <StyledIconButton
                  aria-label='more'
                  aria-controls='user-menu'
                  aria-haspopup='true'
                  onClick={(event) => handleMenuClick(event, user.id)}
                  style={{ float: 'right' }}
                  className={`${user.kind}-item`}
                >
                  <MoreVertIcon />
                </StyledIconButton>
              </TableCellWithMenu>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default UserTable;
