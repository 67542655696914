import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Loader } from '@/components';
import { usersProvider } from '@/providers';
import { NotificationTypeEnum, PaymentStatusEnum } from '@/types';
import { appSetNotification, authGetMyUser } from '@/store';

export const PaymentOk = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum>();
  const queryParams = new URLSearchParams(window.location.search);
  const paymentIntent = queryParams.get('payment_intent');
  const orderId = localStorage.getItem('orderId');
  localStorage.removeItem('orderId');
  const dispatch = useDispatch();

  const postSubscriptionOrderConfirmation = async () => {
    setIsLoading(true);
    if (orderId && paymentIntent) {
      const payload = {
        orderId: orderId,
        paymentIntentId: paymentIntent,
      };
      const { ok, data, status, message } = await usersProvider.postSubscriptionOrderConfirmation(payload);
      if (!ok) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      } else {
        const { status } = data;
        setPaymentStatus(status);
        if (status === PaymentStatusEnum.Payed) {
          const { ok: okUser, data: dataUser } = await usersProvider.getMyUser();
          if (okUser && dataUser) {
            dispatch(authGetMyUser(dataUser));
          }
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    postSubscriptionOrderConfirmation();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack>
      <Stack spacing={1}>
        <Typography variant='h4'>
          Payment was
          <span style={{ color: '#1650FF' }}> successful!</span>
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Now you can use all the features of our application.
        </Typography>
      </Stack>
    </Stack>
  );
};
