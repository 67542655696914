import { Style } from '@react-pdf/types';
import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';
import { TransactionTypeEnum } from '@/types';

const styles = StyleSheet.create({
  meddiumBold: {
    fontWeight: 'bold',
    fontSize: 56,
  },
  price: {
    fontWeight: 'bold',
    fontSize: 100,
    lineHeight: 1.3,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  badges: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  badge: {
    borderRadius: '16px',
    border: '2px solid #2A3842',
    padding: '0 40px',
    minWidth: '290px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '24px',
    height: '113px'
  },
  badgePrice: {
    fontSize: '40px',
    lineHeight: 1.3,
  },
  priceWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
});

export const Price = ({
  isSale,
  convertedPrice,
  convertedPriceYearly,
  convertedPriceMonthly,
  convertedPriceWeekly,
  convertedPriceDaily,
  convertedCurrency,
  convertedSellingPrice,
  transactionType
}: {
  isSale: boolean,
  convertedPrice?: number,
  convertedPriceYearly?: number,
  convertedPriceMonthly?: number,
  convertedPriceWeekly?: number,
  convertedPriceDaily?: number,
  convertedCurrency?: string,
  convertedSellingPrice?: number,
  transactionType?: TransactionTypeEnum,
}) => {

  const price = transactionType === TransactionTypeEnum.Distress ? convertedSellingPrice : convertedPrice;

  return (
    <>
      {
        isSale
          ? (
            <View style={styles.inline}>
              <Text style={styles.meddiumBold}>Price&nbsp;</Text>
              <Text style={styles.price}>{numberFormat(price)}</Text>
              <Text style={styles.meddiumBold}>&nbsp;{convertedCurrency}</Text>
            </View>
          )
          : (
            <View style={styles.badges}>
              {convertedPriceYearly && <View style={styles.badge}>
                <Text>Yearly</Text>
                <View style={styles.priceWrap}>
                  <Text style={styles.badgePrice}>{numberFormat(convertedPriceYearly)} </Text>
                  <Text>{convertedCurrency}</Text>
                </View>
              </View>}
              {convertedPriceMonthly && <View style={styles.badge}>
                <Text>Monthly</Text>
                <View style={styles.priceWrap}>
                  <Text style={styles.badgePrice}>{numberFormat(convertedPriceMonthly)} </Text>
                  <Text>{convertedCurrency}</Text>
                </View>
              </View>}
              {convertedPriceWeekly && <View style={styles.badge}>
                <Text>Weekly</Text>
                <View style={styles.priceWrap}>
                  <Text style={styles.badgePrice}>{numberFormat(convertedPriceWeekly)} </Text>
                  <Text>{convertedCurrency}</Text>
                </View>
              </View>}
              {convertedPriceDaily && <View style={styles.badge}>
                <Text>Daily</Text>
                <View style={styles.priceWrap}>
                  <Text style={styles.badgePrice}>{numberFormat(convertedPriceDaily)} </Text>
                  <Text>{convertedCurrency}</Text>
                </View>
              </View>}
            </View>
          )
      }
    </>
  );
};

const numberFormat = (number?: number) => {
  if (!number) return number;
  return number.toLocaleString('en-US').replace(/,/g, ' ')
};
