import styled from '@emotion/styled';
import Box from '@mui/material/Box/Box';

export const StyledPdfPreviewCard = styled(Box)({
  width: '400px',
  height: '254px',
  borderRadius: '12px',
  border: '1px solid #2a384233',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
});
