import { useEffect, useState } from 'react';
import { Typography, IconButton, Button } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { AdFieldCurrencyEnum, RentPeriodEnum, IntervalTariffPlan } from '@/types';
import { FormSelect, SectionTitle, FormButtons } from '@/components';
import { FormInputNumeric, NumberInput } from './FormInputNumeric';
import { useFormContext } from 'react-hook-form';

import styles from './PaymentInfo.module.scss';

const currencyValues = [
  { label: 'AED', value: AdFieldCurrencyEnum.AED },
  { label: 'USD', value: AdFieldCurrencyEnum.USD },
];

const periodValues = [
  { label: 'Days', value: RentPeriodEnum.Daily },
  { label: 'Weeks', value: RentPeriodEnum.Weekly },
  { label: 'Months', value: RentPeriodEnum.Monthly },
  { label: 'Years', value: RentPeriodEnum.Yearly },
];

const intervalValues = [
  { label: 'Daily', value: IntervalTariffPlan.Daily },
  { label: 'Weekly', value: IntervalTariffPlan.Weekly },
  { label: 'Monthly', value: IntervalTariffPlan.Monthly },
  { label: 'Yearly', value: IntervalTariffPlan.Yearly },
  { label: 'Every 3 months', value: IntervalTariffPlan.ThreeMonths },
  { label: 'Every 6 months', value: IntervalTariffPlan.SixMonths },
  { label: 'Custom', value: IntervalTariffPlan.Custom },
];

interface PropertyPriceProps {
  priceTo?: number;
  setPriceTo?: (value: number) => void;
  priceFrom?: number;
  setPriceFrom?: (value: number) => void;
}

export const PaymentInfo = ({
  priceTo,
  setPriceTo,
  priceFrom,
  setPriceFrom,
}: PropertyPriceProps) => {
  const { setValue, getValues } = useFormContext();
  const { usersKind } = getValues();

  const [isCustomFirstInterval, setIsCustomFirstInterval] = useState<boolean>(false);
  const [isCustomSecondInterval, setIsCustomSecondInterval] = useState<boolean>(false);
  const [secondPriceVisible, setSecondPriceVisible] = useState<boolean>(false);

  // useEffect(() => {
  // }, []);

  const handleChangeInterval = (value: string, name: string) => {
    if (name === 'firstInterval') {
      if (value === IntervalTariffPlan.Custom) {
        setIsCustomFirstInterval(true);
      } else {
        setIsCustomFirstInterval(false);
        setValue('firstPeriod', undefined);
        setValue('firstNumPeriod', undefined);
      }
    }
    if (name === 'secondInterval') {
      if (value === IntervalTariffPlan.Custom) {
        setIsCustomSecondInterval(true);
      } else {
        setIsCustomSecondInterval(false);
        setValue('secondPeriod', undefined);
        setValue('secondNumPeriod', undefined);
      }
    }
  };

  return (
    <div className={styles.container}>
      <SectionTitle number={2} title='Payment information' />
      <div className={styles.currency}>
        <FormSelect
          uiVariant='blank'
          name='currency'
          data={currencyValues}
          defaultValue={currencyValues[0].value}
          label='Currency'
        />
      </div>
      <div className={styles.row}>
        <div className={styles.price}>
          <FormInputNumeric
            value={priceFrom}
            uiVariant='blank'
            name='firstPrice'
            fullWidth
            label='Price'
            required
            placeholder='Enter price'
          />
        </div>
        <div className={styles.currency}>
          <FormSelect
            uiVariant='blank'
            name='firstInterval'
            required
            data={intervalValues}
            defaultValue={intervalValues[2].value}
            label='Interval'
            handleChange={(value: string) => handleChangeInterval(value, 'firstInterval')}
          />
        </div>
        {isCustomFirstInterval && (
          <div className={styles.currency}>
            <NumberInput
              uiVariant='blank'
              name='firstNumPeriod'
              required
              label='Every'
            />
          </div>
        )}
        {isCustomFirstInterval && (
          <div className={styles.currency}>
            <FormSelect
              uiVariant='blank'
              name='firstPeriod'
              required
              data={periodValues}
              defaultValue={periodValues[2].value}
              label='Period'
            />
          </div>
        )}
      </div>
      {secondPriceVisible ? (
        <div className={styles.row}>
          <div className={styles.price}>
            <FormInputNumeric
              value={priceFrom}
              uiVariant='blank'
              name='secondPrice'
              fullWidth
              label='Price'
              required
              placeholder='Enter price'
            />
          </div>
          <div className={styles.currency}>
            <FormSelect
              uiVariant='blank'
              name='secondInterval'
              required
              data={intervalValues}
              defaultValue={intervalValues[2].value}
              label='Interval'
              handleChange={(value: string) => handleChangeInterval(value, 'secondInterval')}
            />
          </div>
          {isCustomSecondInterval && (
            <div className={styles.currency}>
              <NumberInput
                uiVariant='blank'
                name='secondNumPeriod'
                required
                label='Every'
              />
            </div>
          )}
          {isCustomSecondInterval && (
            <div className={styles.currency}>
              <FormSelect
                uiVariant='blank'
                name='secondPeriod'
                required
                data={periodValues}
                defaultValue={periodValues[2].value}
                label='Period'
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {/* <IconButton
            aria-label='add'
            sx={{ color: '#1650FF', fontSize: '16px', gap: '5px' }}
            onClick={() => setSecondPriceVisible(true)}
          >
            <AddCircleOutlineOutlinedIcon />
            Add second price
          </IconButton> */}
          <Button
            sx={{ color: '#1650FF', fontSize: '16px' }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setSecondPriceVisible(true)}
          >
            Add second price
          </Button>
        </div>
      )}
    </div>
  );
};
