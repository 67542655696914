/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { SelectItemButton } from './SelectItemButton';
import classNames from 'classnames';
import styles from './DoubleDeckerListsSelect.module.scss';
import { ReactComponent as CaretDown } from './CaretDown.svg';

interface IDataList {
  label: string;
  value: any;
}

interface IDataListWithRegex extends IDataList {
  regex: RegExp;
}

interface ISecondSelectedArrayItem {
  [key: string]: string;
}

interface IDoubleDeckerListWithBottomInputsProps {
  placeholder: string;
  firstStageLabel: string;
  firstStageData: IDataList[];
  secondStageLabel: string;
  secondStageData: IDataListWithRegex[];
  defaultFirstValue?: string;
  defaultSecondArray?: ISecondSelectedArrayItem[];
  handleChange?: (value: any) => void;
}

export const DoubleDeckerListWithBottomInputs = (props: IDoubleDeckerListWithBottomInputsProps) => {
  const {
    placeholder,
    firstStageLabel,
    firstStageData,
    secondStageLabel,
    secondStageData,
    defaultFirstValue,
    defaultSecondArray,
    handleChange,
  } = props;

  const [stageValues, setStageValues] = useState<{
    firstSelectedValue?: string;
    secondSelectedArray?: ISecondSelectedArrayItem[];
  }>({
    firstSelectedValue: defaultFirstValue,
    secondSelectedArray: defaultSecondArray,
  });

  const [newPlaceholder, setNewPlaceholder] = useState<string | undefined>(placeholder);

  useEffect(() => {
    setStageValues({
      firstSelectedValue: defaultFirstValue,
      secondSelectedArray: defaultSecondArray,
    });
  }, [defaultFirstValue, defaultSecondArray]);

  const handleFirstStageChange = (newValue: string) => {
    setStageValues((prev) => ({
      ...prev,
      firstSelectedValue: prev.firstSelectedValue === newValue ? undefined : newValue,
    }));
  };

  useEffect(() => {
    let tPlaceholder = '';
    let tSecondStr = '';

    const firstStageChoice = firstStageData.find(
      (el) => el.value === stageValues.firstSelectedValue
    )?.label;

    const firstSelectedArrayFirstValue = stageValues?.secondSelectedArray?.[0]
      ? Object.values(stageValues.secondSelectedArray[0])[0]
      : undefined;

    const secondSelectedArraySecondValue = stageValues?.secondSelectedArray?.[1]
      ? Object.values(stageValues.secondSelectedArray[1])[0]
      : undefined;

    if (
      stageValues?.secondSelectedArray &&
      stageValues?.secondSelectedArray?.length > 1 &&
      firstSelectedArrayFirstValue &&
      firstSelectedArrayFirstValue !== '0' &&
      secondSelectedArraySecondValue &&
      secondSelectedArraySecondValue !== '0'
    ) {
      tSecondStr = `(${firstSelectedArrayFirstValue}-${secondSelectedArraySecondValue})`;
    } else if (
      stageValues?.secondSelectedArray &&
      stageValues?.secondSelectedArray?.length > 0 &&
      firstSelectedArrayFirstValue &&
      firstSelectedArrayFirstValue !== '0'
    ) {
      const keyName0 = Object.keys(stageValues.secondSelectedArray[0])[0];
      tSecondStr = `(${keyName0}: ${firstSelectedArrayFirstValue})`;
    } else if (
      stageValues?.secondSelectedArray &&
      stageValues?.secondSelectedArray?.length > 1 &&
      secondSelectedArraySecondValue &&
      secondSelectedArraySecondValue !== '0'
    ) {
      const keyName1 = Object.keys(stageValues.secondSelectedArray[1])[0];
      tSecondStr = `(${keyName1}: ${secondSelectedArraySecondValue})`;
    }

    if (firstStageChoice && tSecondStr && tSecondStr !== '(0-0)') {
      tPlaceholder = `${firstStageChoice}${tSecondStr}`;
    } else if (firstStageChoice) {
      tPlaceholder = `${firstStageChoice}`;
    } else if (tSecondStr && tSecondStr !== '(0-0)') {
      tPlaceholder = `${tSecondStr}`;
    } else {
      tPlaceholder = placeholder;
    }

    setNewPlaceholder(tPlaceholder);
  }, [stageValues]);

  const handleSecondStageChange =
    (key: string, regex: RegExp) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (regex.test(newValue) || newValue === '') {
        setStageValues((prev) => {
          const existingIndex = prev.secondSelectedArray?.findIndex((item) =>
            Object.keys(item).includes(key)
          );
          let newSecondStage: ISecondSelectedArrayItem[];

          if (existingIndex !== -1 && existingIndex !== undefined) {
            newSecondStage = prev.secondSelectedArray!.map((item, index) =>
              index === existingIndex ? { ...item, [key]: newValue } : item
            );
          } else {
            newSecondStage = [...(prev.secondSelectedArray || []), { [key]: newValue }];
          }

          return { ...prev, secondSelectedArray: newSecondStage };
        });
      }
    };

  return (
    <Select
      value={'-1'}
      IconComponent={CaretDownComponent}
      onClose={() =>
        handleChange &&
        handleChange({
          firstStage: stageValues.firstSelectedValue,
          secondStage: stageValues.secondSelectedArray,
        })
      }
      sx={{
        width: '100%',
        '& .Mui-focused': {
          border: '1px solid rgb(233, 238, 241)',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(233, 238, 241) !important',
        },
      }}
    >
      <MenuItem
        sx={{
          display: 'none',
          '&&.Mui-focusVisible': {
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
          },
        }}
        hidden
        key={1}
        value={'-1'}
      >
        {newPlaceholder || placeholder}
      </MenuItem>
      <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
        <FormHelperText>
          <span className={styles.selectInnerTitle}>{firstStageLabel}</span>
        </FormHelperText>
        <div className={styles.selectInnerContent}>
          {firstStageData.map(({ label, value }) => (
            <SelectItemButton
              key={value}
              label={label}
              value={value}
              currentValue={stageValues.firstSelectedValue}
              onChange={handleFirstStageChange}
            />
          ))}
        </div>
        <FormHelperText>
          <span className={styles.selectInnerTitle}>{secondStageLabel}</span>
        </FormHelperText>
        <div className={styles.selectInnerContent}>
          {secondStageData.map(({ label, regex }) => {
            const foundItem = stageValues.secondSelectedArray?.find(
              (item) => item[label] !== undefined
            );
            const foundValue = foundItem ? foundItem[label] : '';

            return (
              <div key={label}>
                <FormHelperText>
                  <span className={styles.selectInnerLabel}>{label}</span>
                </FormHelperText>
                <TextField value={foundValue} onChange={handleSecondStageChange(label, regex)} />
              </div>
            );
          })}
        </div>
      </div>
    </Select>
  );
};

const CaretDownComponent = (props: any) => {
  const rotate = props.className.includes('iconOpen');
  return (
    <div
      className={styles.caretDown}
      style={{
        transform: rotate ? 'rotate(180deg)' : 'none',
      }}
    >
      <CaretDown />
    </div>
  );
};
