import { AdMediaType } from '@/types';
import { useMemo } from 'react';
import { Picture } from '@/components/PdfManager/PdfDocument/ClassicView/Picture';
import { StyleSheet, View, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export interface GallerySectionProps {
  title?: string;
  images?: AdMediaType[] | NewBuildingsMediaType[];
  wrap?: boolean;
  style?: Style;
}

const styles = StyleSheet.create({
  gallery: {
    width: '100%',
  },
  galleryItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 32,
  },
  galleryImage: {
    marginTop: 32,
    width: '49%',
    objectFit: 'cover',
    borderRadius: 24,
  },
  column: {
    flexDirection: 'column',
    width: '25%',
    gap: '10px',
  },
  threeImageItem: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    objectFit: 'cover',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: 10,
  },
  middleImage: {
    width: '50%',
    height: '100%',
    borderRadius: 10,
  },
  threeImagesContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '10px',
    height: '1000px',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '10px',
  },
  leftThreeImageColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
    height: '100%',
    gap: '10px',
  },
  rightThreeImageColumn: {
    width: '65%',
  },
});

export const TopGallery = ({ images = [], wrap = false }: GallerySectionProps) => {
  if (!images.length) return null;

  const pictures = useMemo(() => {
    return images.map((img) => (
      <Picture key={img.id} style={styles.galleryImage} src={img.fileUrl} />
    ));
  }, [images]);

  return (
    <View wrap={wrap}>
      {images.length > 2 && (
        <View style={styles.threeImagesContainer}>
          <View style={styles.leftThreeImageColumn}>
            <Picture src={images[0].fileUrl} style={styles.threeImageItem} />
            <Picture src={images[1].fileUrl} style={styles.threeImageItem} />
          </View>

          <View style={styles.rightThreeImageColumn}>
            <Picture src={images[2].fileUrl} style={styles.threeImageItem} />
          </View>
        </View>
      )}
    </View>
  );
};
