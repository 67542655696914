import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';

export interface IconTextProps {
  iconSrc?: string;
  children?: ReactNode;
  style?: Style;
}

export const Badge = ({ iconSrc, children, style }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      paddingHorizontal: 40,
      borderRadius: 92,
      borderWidth: 6,
      borderColor: '#2A3842',
      color: '#2A3842',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 20,
      width: '50vw',
      wrap: 'wrap',
      ...style,
    },
  });

  return (
    <IconText iconSrc={iconSrc} style={styles.container}>
      {children}
    </IconText>
  );
};

export const IconText = ({ iconSrc, children, style }: IconTextProps) => {
  if (!children) return null;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 60,
      ...style,
    },
    icon: {
      width: '112px',
      height: '112px',
    },
  });

  return (
    <View style={styles.container}>
      {iconSrc && <Image src={iconSrc} style={styles.icon} />}
      <Text style={{ fontSize: '96px' }}>{children}</Text>
    </View>
  );
};
