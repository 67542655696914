import queryString from 'query-string';

import { ResponseType, LicensesGetAgenciesResponseType } from '@/types';
import { apiClient } from '@/providers';

interface ICommonParams {
  query: string;
  limit?: number;
  offset?: number;
}

const URL_PREFIX = '/stateLicenseRegistry';

export const stateLicenseProvider = {
  searchBroker: async (params: ICommonParams): Promise<ResponseType> => {
    const { limit = 50, offset = 0, query } = params;
    const paramsStr = queryString.stringify({ limit, offset, query });
    const response = await apiClient(`${URL_PREFIX}/searchBroker?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as LicensesGetAgenciesResponseType;
    return { ...response, data };
  },

  searchAgency: async (params: ICommonParams): Promise<ResponseType> => {
    const { limit = 50, offset = 0, query } = params;
    const paramsStr = queryString.stringify({ limit, offset, query });
    const response = await apiClient(`${URL_PREFIX}/searchAgency?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as LicensesGetAgenciesResponseType;
    return { ...response, data };
  },

  searchDeveloper: async (params: ICommonParams) => {
    const { limit = 50, offset = 0, query } = params;
    const paramsStr = queryString.stringify({ limit, offset, query });
    const response = await apiClient(`${URL_PREFIX}/searchDeveloper?${paramsStr}`, 'GET');
    if (!response.ok || !response.data) {
      return { ...response, data: { items: [], total: 0 } };
    }

    const data = response.data as LicensesGetAgenciesResponseType;
    return { ...response, data };
  },
};
