import { Button } from '@mui/material';
import classnames from 'classnames';

import styles from './SelectItemButton.module.scss';

interface SelectItemButtonProps {
  label: string;
  value: string;
  currentValue: string | undefined;
  onChange: (val: string) => void;
}

export const SelectItemButton = ({
  label,
  value,
  currentValue,
  onChange,
}: SelectItemButtonProps) => (
  <Button
    className={classnames(styles.itemButton, {
      [styles.active]: currentValue === value,
    })}
    onClick={() => onChange(value)}
    variant='contained'
  >
    {label}
  </Button>
);
