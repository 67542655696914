import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import { State, AdModel, UserModel, UserTariffPlanEnum, HeaderDialogsEnum } from '@/types';
import { icons } from './Icons/icons';
import {Favorite} from "@mui/icons-material";
import {useState} from "react";
import {favoritesProvider} from "@/providers/favoritesProvider";

const StyledMenuItem = styled(MenuItem)({
  fontWeight: '400',
});

export const AdMenu = ({
  page,
  open,
  handleClose,
  anchorEl,
  user,
  handleClickOpenDialog,
}: {
  page: AdModel;
  open: boolean;
  handleClose: () => void;
  anchorEl: Element | null;
  user: UserModel;
  handleClickOpenDialog?: (value: HeaderDialogsEnum) => void;
}) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(page.isFavorite);
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;

  const handleFavoriteClick = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    favorite
      ? await favoritesProvider.removeFromFavorites(page.id)
      : await favoritesProvider.addToFavorites(page.id)
    setFavorite(s => !s)
  }
  const handleClickEdit = () => {
    if (page?.propertyAdCreator?.id && user.id === page.propertyAdCreator?.id.toString()) {
      navigate(`/ads/edit/${page.id}`);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {page?.propertyAdCreator?.id && user.id === page.propertyAdCreator.id.toString() && (
          <StyledMenuItem onClick={() => handleClickEdit()}>
            <ListItemIcon>
              <icons.Edit />
            </ListItemIcon>
            Edit
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={handleFavoriteClick}
        >
          <ListItemIcon>
            <Favorite  color='secondary' style={{fill: favorite ? '#1650FF' : "none", stroke: favorite ? '#1650FF' : '#2A3842', strokeWidth: 2}}/>
          </ListItemIcon>
          Favorite
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => 
            isTariffFree
              ? handleClickOpenDialog?.(HeaderDialogsEnum.Tariff)
              : navigate(`/ads/create-pdf/${page.id}`)
          }
        >
          <ListItemIcon>
            <icons.Pdf />
          </ListItemIcon>
          Save as PDF
        </StyledMenuItem>
      </Menu>
    </>
  );
};
