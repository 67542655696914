import { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { AdModel, NewBuildingModel } from '@/types';
import { Picture } from './Picture';
import { Section } from './Section';
import {
  BalconyIcon,
  MaidsRoomIcon,
  SharedPoolIcon,
  BuiltInWardrobesIcon,
  ChildrenPlayAreaIcon,
  SharedSpaIcon,
  SharedGymIcon,
  StudyIcon,
  CentralAcIcon,
  CoveredParkingIcon,
  BarbecueAreaIcon,
  ViewOfWaterIcon,
  ViewOfLandmarkIcon,
  PetsAllowedIcon,
  ConciergeIcon,
  PublicParkingIcon,
  KitchenAppliancesIcon,
  LobbyInBuildingIcon,
  MaidServiceIcon,
  PetsNotAllowedIcon,
  PrivateGardenIcon,
  PrivateGymIcon,
  PrivatePoolIcon,
  SecurityIcon,
  TennisCourtsIcon,
  ViewOfParkIcon,
  ViewOfCityIcon,
  PrivateSpaIcon,
  LaundryFacilityIcon,
} from '@/assets/pdf';

export function FeaturesSection({ data }: { data?: AdModel | NewBuildingModel }) {
  const feature = useMemo(() => {
    return Object.entries(propertyFeatures)
      .filter(([key]) => (data as any)[key])
      .map(([key, value]) => {
        return (
          <View key={key} style={{ width: '22vw', alignItems: 'center', gap: 32 }} wrap={false}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Picture src={value.src} style={{ height: 300 }} />
            </View>
            <Text style={{ fontSize: '72px', width: 320, textAlign: 'center' }}>{value.name}</Text>
          </View>
        );
      });
  }, [data]);

  if (!feature || feature.length === 0) return null;

  return (
    <Section
      title='Features / Amenities'
      style={{ marginBottom: '80px', marginTop: '80px' }}
      wrap={true}
    >
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: 35,
          marginTop: 36,
        }}
      >
        {feature}
      </View>
    </Section>
  );
}

interface Feature {
  name: string;
  src: string;
}

type PropertyFeaturesBase = {
  [K in keyof AdModel]: Feature;
};

const propertyFeatures: Partial<PropertyFeaturesBase> = {
  balcony: { name: 'Balcony', src: BalconyIcon },
  maidsRoom: { name: 'Maids room', src: MaidsRoomIcon },
  sharedPool: { name: 'Shared pool', src: SharedPoolIcon },
  builtInWardrobes: { name: 'Built in wardrobes', src: BuiltInWardrobesIcon },
  childrenPlayArea: { name: `Children's play area`, src: ChildrenPlayAreaIcon },
  sharedSpa: { name: 'Shared spa', src: SharedSpaIcon },
  sharedGym: { name: 'Shared gym', src: SharedGymIcon },
  study: { name: 'Study', src: StudyIcon },
  centralAc: { name: 'Central A/C', src: CentralAcIcon },
  coveredParking: { name: 'Covered parking', src: CoveredParkingIcon },
  barbecueArea: { name: 'Barbecue area', src: BarbecueAreaIcon },
  viewOfWater: { name: 'Water view', src: ViewOfWaterIcon },
  viewOfLandmark: { name: 'Landmark view', src: ViewOfLandmarkIcon },
  petsAllowed: { name: 'Pets\nallowed', src: PetsAllowedIcon },
  concierge: { name: 'Concierge', src: ConciergeIcon },
  publicParking: { name: 'Public parking', src: PublicParkingIcon },
  kitchenAppliances: { name: 'Kitchen tools', src: KitchenAppliancesIcon },
  lobbyInBuilding: { name: 'Lobby in building', src: LobbyInBuildingIcon },
  maidService: { name: 'Maid \nservice', src: MaidServiceIcon },
  petsNotAllowed: { name: 'Pets not \nallowed', src: PetsNotAllowedIcon },
  privateGarden: { name: 'Private garden', src: PrivateGardenIcon },
  privateGym: { name: 'Private gym', src: PrivateGymIcon },
  privatePool: { name: 'Private pool', src: PrivatePoolIcon },
  security: { name: 'Security', src: SecurityIcon },
  tennisCourts: { name: 'Tennis courts', src: TennisCourtsIcon },
  viewOfPark: { name: 'Park view', src: ViewOfParkIcon },
  viewOfCity: { name: 'City view', src: ViewOfCityIcon },
  privateSpa: { name: 'Private spa', src: PrivateSpaIcon },
  laundryFacility: { name: 'Laundry \nfacility', src: LaundryFacilityIcon },
};
