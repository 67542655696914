export const modalWrapperStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  return {
    minWidth: '350px',
    width: '100%',
    maxWidth: '700px',
    minHeight: '250px',
    height: '300px',
    maxHeight: '600px',
    overflow: 'hidden',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '4',
    margin: '0 auto',
    boxShadow: 'rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px',
    borderRadius: '14px',
    display: 'block',
  };
};

export const modalCloseStyle = (img, context) => {
  return {
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '25px',
    right: '30px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.color.primary}`,
    cursor: 'pointer',
  };
};

export const modalBodyStyle = () => {
  return {
    padding: '24px 34px',
    height: '100%',
    width: '100%',
  };
};

export const modalErrorStyle = (context) => {
  return {
    fontSize: '12px',
    color: `${context.theme.color.red}`,
    textAlign: 'center',
    margin: '8px 0',
    width: '100%',
  };
};

export const modalTableStyle = () => {
  return {
    borderCollapse: 'collapse',
    margin: '0',
    padding: '0',
    width: '100%',
    tr: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
  };
};

export const tableCaptionStyle = ({ theme }) => {
  return {
    fontSize: '24px',
    marginBottom: '15px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: `${theme.color.primary}`,
    paddingBottom: '27px',
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      backgroundColor: `${theme.borderColor.primary}`,
      position: 'absolute',
      left: '0px',
      right: '0px',
      top: '85px',
    },
  };
};

export const tableBodyStyle = () => {
  return {
    overflowY: 'auto',
    display: 'block',
    tr: {
      td: {
        padding: '8px 0',
        fontSize: '14px',
        input: {
          width: '100%',
          padding: '8px 16px',
          outline: 'none',
        },
        select: {
          outline: 'none',
          padding: '8px 16px',
        },
      },
    },
  };
};

export const tableFootStyle = ({ theme }, { creatingGroup }, img) => {
  const loadingState = creatingGroup
    ? {
        disabled: 'true',
        pointerEvents: 'none',
        background: `url(${img}) no-repeat right 10px center ${theme.primaryColor}`,
      }
    : {};

  const textMargin = creatingGroup ? { marginRight: '24px' } : {};

  return {
    display: 'inline-block',
    marginTop: '20px',
    button: {
      cursor: 'pointer',
      padding: '8px 16px',
      background: `${theme.backgroundColor.blueGradient}`,
      borderRadius: '14px',
      color: `${theme.color.white}`,
      fontSize: '16px',
      outline: '0',
      border: '0',
      width: '100%',
      [`@media (min-width: 600px) `]: {
        width: '248px',
      },
      height: '44px',
      ...loadingState,
      span: {
        ...textMargin,
      },
    },
    tr: {
      border: 'none',
      td: {
        textAlign: 'center',
      },
    },
  };
};

export const inputStyle = ({ theme }) => {
  return {
    display: 'block',
    width: '100%',
    border: `1px solid ${theme.borderColor.primary}`,
    borderRadius: '14px',
    color: `${theme.color.primary}`,
    fontSize: '16px',
    height: '48px',
  };
};

export const inputLabelStyle = ({ theme }) => {
  return {
    fontSize: '16px',
    color: `${theme.color.primary}`,
    marginBottom: '5px',
  };
};
