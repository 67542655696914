import { Avatar, styled } from '@mui/material';

const stringToColor = (string: string): string => {
  const hash = Array.from(string).reduce((acc, char) => {
    return (char.charCodeAt(0) + ((acc << 5) - acc)) | 0; 
  }, 0);

  return `#${[0, 8, 16] 
    .map(shift => `00${((hash >> shift) & 0xff).toString(16)}`.slice(-2)) 
    .join('')}`;
};

export const UserAvatarView = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'userName',
})<{ userName: string }>(({ theme, userName }) => ({
  width: 370,
  height: 370,
  fontSize: '50px',
  backgroundColor: stringToColor(userName),
  [theme.breakpoints.down('sm')]: {
    width: 150,
    height: 150,
    fontSize: '20px',
  },
}));