import { Typography } from '@mui/material';
import { AdFieldCurrencyEnum } from '@/types';
import { FormInputNumeric, FormSelect } from '@/components';

import styles from './PropertyPrice.module.scss';

export const PropertyPriceReady = () => {
  const currencyValues = [
    { label: 'AED', value: AdFieldCurrencyEnum.AED },
    { label: 'USD', value: AdFieldCurrencyEnum.USD },
  ];

  return (
    <div className={styles.container}>
      <div>
        <Typography sx={{ fontSize: '18px', fontWeight: 800, marginBottom: '10px' }}>
          Price<span style={{ color: 'red' }}>*</span>
        </Typography>
        <div className={styles.row}>
          <div className={styles.currency}>
            <FormSelect
              uiVariant='blank'
              name='currency'
              data={currencyValues}
              defaultValue={currencyValues[0].value}
            />
          </div>
          <div className={styles.price}>
            <FormInputNumeric
              uiVariant='blank'
              name='price'
              fullWidth
              placeholder='Price'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
