import { Divider, Box, Stack, Typography, DialogTitle } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import { MyDialog, MyButton, SectionHeader } from '@/components';
import { TariffPlanInfo } from './TariffPlanInfo';
import { PaymentInfo } from './PaymentInfo';
import { NumberOfUsers } from './NumberOfUsers';
import { TariffPlanModel } from '@/types';
import styles from '../PaymentInformation.module.scss';
interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateTariffDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const formMethods = useForm<TariffPlanModel>({});
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = formMethods;

  const handleClose = () => {
    reset();
    onClose();
  };

  const submitHandler: SubmitHandler<TariffPlanModel> = async (values) => {
    console.log(values);
    handleClose();
  };

  return (
    <MyDialog
      open={open}
      onClose={handleClose}
      dialogTitle='Create tariff plan'
      width='950'
      titleAlign='left'
    >
      <Divider />
      <FormProvider {...formMethods}>
        <Box component='form' autoComplete='off'>
          <TariffPlanInfo />
          <PaymentInfo />
          <NumberOfUsers />
          <div className={styles.submitButtons}>
            <MyButton
              data={{
                buttonName: 'Save',
                customWidth: '155px',
                variant: 'contained',
                buttonType: 'button',
              }}
              onClick={handleSubmit(submitHandler)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '157px',
                styleType: 'cancel',
                variant: 'outlined',
                buttonType: 'button',
              }}
              onClick={handleClose}
            />
          </div>
        </Box>
      </FormProvider>
    </MyDialog>
  );
};
