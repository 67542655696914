import { useEffect, useState, useRef } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, arraySwap, SortableContext } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';

import { AdMediaType, FileKindEnum } from '@/types';
import { FileUploader, SectionTitle } from '@/components';

import { Image } from '../';
import { PhotoItem } from './PhotoItem';
import styles from './UploadPhotos.module.scss';
import { Typography, Stack } from '@mui/material';

const MAX_PHOTOS = 50;

interface UploadPhotosProps {
  gallery: Image[];
}

export const UploadPhotos = ({ gallery }: UploadPhotosProps) => {
  const [images, setImages] = useState<Image[]>([]);
  const [countImage, setCountImage] = useState(gallery.length || 0);
  const [actionClick, setActionClick] = useState(false);
  const [error, setError] = useState('');

  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const sensors = useSensors(useSensor(PointerSensor));

  const sectionTitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => setImages(gallery), [gallery]);

  useEffect(() => {
    setCountImage(images.length);
    const itemsWithoutPhotos =
      getValues('propertyAdMedia')?.filter(
        (item: AdMediaType) => item.kind !== FileKindEnum.Image
      ) || [];
    const photos = images.map((image, index) => ({
      fileId: image.id,
      kind: FileKindEnum.Image,
      order: index + 1,
    }));
    setValue('propertyAdMedia', [...itemsWithoutPhotos, ...photos]);
  }, [images]);

  useEffect(() => {
    if (countImage < 4) {
      setError('A minimum of four photos is required for a comprehensive listing.');
    } else {
      setError('');
    }
  }, [countImage]);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length === 1 && errorKeys[0] === 'propertyAdMedia') {
      const trySetFocus = (attempts: number) => {
        if (attempts > 0 && sectionTitleRef.current) {
          sectionTitleRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          sectionTitleRef.current.focus();
          setTimeout(() => trySetFocus(attempts - 1), 50);
        }
      };
      setTimeout(() => trySetFocus(2), 100);
    }
  }, [errors]);

  const handlePhotoUploaded = (id: string, url: string) =>
    setImages((images) => [...images, { id, url }]);

  const handleRemoveItem = (index: number) => {
    setCountImage(countImage - 1);
    setImages(images.filter((_, position) => index !== position + 1));
  };

  const handleSetCoverItem = (index: number) => {
    setActionClick(true);
    setImages(arraySwap(images, 0, index - 1));
  };

  const handleReorderItem = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = images.findIndex(({ id }) => id === active.id);
      const overIndex = images.findIndex(({ id }) => id === over.id);
      if (!actionClick) {
        setImages(arrayMove(images, activeIndex, overIndex));
      }
      setActionClick(false);
    }
  };

  const errorMessage = String(errors['propertyAdMedia']?.message);

  return (
    <div>
      <SectionTitle ref={sectionTitleRef} number={6} title='Upload photo' required />
      <div className={styles.container}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleReorderItem}
        >
          <SortableContext items={images}>
            {images.map((image, index) => (
              <PhotoItem
                key={index}
                {...image}
                position={index + 1}
                remove={handleRemoveItem}
                setCover={handleSetCoverItem}
                covering={!actionClick}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <FileUploader
        type='image'
        labelButton='Upload photo'
        labelFormat='Only JPG, JPEG or PNG format, minimum size 800 x 600px'
        labelSize='Maximum 15 MB one photo'
        accept={{
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
        }}
        limit={MAX_PHOTOS - countImage}
        onUploaded={handlePhotoUploaded}
        disabled={images.length >= MAX_PHOTOS}
      />
      {!!errors['propertyAdMedia'] && error && (
        <Typography sx={{ fontSize: '12px', color: 'red', textAlign: 'right' }}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
