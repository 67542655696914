import { Divider, Stack, Typography, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { MyDialog } from '@/components';
import { ITransactions } from '@/types';
import { TransactionsTable } from '../TransactionsTable';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  transactionInfo: ITransactions;
}

interface Payment {
  name: string;
  title: string;
  value: string | number;
  width: string;
}

const StyleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '700',
  color: '#2A3842',
  marginBottom: '10px',
  textTransform: 'uppercase',
  padding: '15px 0 0',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const TransactionInformationDialog: React.FC<DialogProps> = ({ open, onClose, transactionInfo }) => {

  const transactionInformation: Payment[] = [
    {
      name: 'transId',
      title: 'Transaction ID',
      value: transactionInfo.transId,
      width: '435px',
    },
    {
      name: 'userId',
      title: 'User ID',
      value: transactionInfo.userId,
      width: '435px',
    },
    {
      name: 'userEmail',
      title: 'User Email',
      value: transactionInfo.userEmail,
      width: '435px',
    },
    {
      name: 'tariffPlan',
      title: 'Tariff Name',
      value: transactionInfo.tariffPlan,
      width: '435px',
    },
    {
      name: 'amount',
      title: 'Amount',
      value: transactionInfo.amount,
      width: '260px',
    },
    {
      name: 'currency',
      title: 'Currency',
      value: transactionInfo.currency,
      width: '160px',
    },
    {
      name: 'status',
      title: 'Transaction Status',
      value: transactionInfo.status,
      width: '260px',
    },
    {
      name: 'paymentDate',
      title: 'Payment Date',
      value: transactionInfo.paymentDate,
      width: '160px',
    },
    {
      name: 'userName',
      title: 'User Name',
      value: transactionInfo.userName,
      width: '435px',
    },
    {
      name: 'paymentMethod',
      title: 'Payment Method',
      value: transactionInfo.paymentMethod,
      width: '435px',
    },
    {
      name: 'subStatus',
      title: 'Sub. Status',
      value: transactionInfo.subStatus,
      width: '435px',
    },
    {
      name: 'interval',
      title: 'Interval',
      value: transactionInfo.interval,
      width: '435px',
    },
  ];

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      dialogTitle='Transaction information'
      width='950'
      titleAlign='left'
    >
      <Divider />
      <Stack direction='column' spacing={2} alignItems='flex-start' sx={{ marginTop: '15px' }}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {transactionInformation.slice(0, 2).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {transactionInformation.slice(2, 4).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {transactionInformation.slice(4, 8).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
      </Stack>
      <Stack direction='column' spacing={2} alignItems='flex-start' sx={{ marginTop: '15px' }}>
        <StyleDialogTitle>
          Detail information
        </StyleDialogTitle>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {transactionInformation.slice(8, 10).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          {transactionInformation.slice(10, 12).map((payment: Payment) => (
            <InfoBlock key={payment.name} payment={payment} />
          ))}
        </Stack>
      </Stack>
    </MyDialog>
  );
};

const InfoBlock = ({ payment }: { payment: Payment }) => {
  return (
    <Stack direction='column' alignItems='flex-start'>
      <Typography>{payment.title}</Typography>
      <Stack
        sx={{
          backgroundColor: '#F8F8F8',
          borderRadius: '12px',
          border: '1px solid #E9EEF1',
          width: payment.width,
          height: '40px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingLeft: '10px',
        }}
      >
        <Typography sx={{ color: '#2A384270' }}>{payment.value}</Typography>
      </Stack>
    </Stack>
  );
};
