import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  Button,
  Menu,
  MenuItem,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { supportProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { NotificationTypeEnum } from '@/types';
import { useOperatorForm } from '../../utils/useFormLogic';
import { ReactComponent as IconPhone } from '../Utils/Img/iconPhone.svg';
import { ReactComponent as IconPhoneMenu } from '../Utils/Img/iconPhoneMenu.svg';
import { ReactComponent as IconMessageMenu } from '../Utils/Img/iconMessageMenu.svg';
import { emailConfirmationData, dialogOperatorData, dialogOperatorSuccessData } from '../Utils/signUpDescription';
import { SupportContentRenderer } from './SupportContentRenderer';
import { MyDialog, MyButton } from '@/components';
import { StyleLabel } from '@/components/uiComponents/styled';
import {
  typographyH5Style,
  typographyBody2Style,
  boxInnerStyle,
  supportButtonStyle,
  StyledLogo,
} from '../Utils/styled';

export const Header = ({
  stepValue,
  headerName,
  headerNext,
  type,
  MAX_STEP
}: {
  stepValue: number;
  headerName?: string;
  headerNext?: string;
  type: string;
  MAX_STEP: number;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fifthStep = emailConfirmationData.header.numOfStep;
  const [openDialogSupport, setOpenDialogSupport] = useState<boolean>(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const progress = (stepValue / MAX_STEP) * 100;
  const isLogIn = type === 'logIn';

  const {
    handleSubmit: handleSubmitOperator,
    errors: errorsOperator,
    control: controlOperator,
    resetForm: resetOperatorForm,
    setValue: setValueOperatorForm,
  } = useOperatorForm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const supportPhone = '+971501355885';

  const onSubmitOperatorForm = async (data: any) => {
    if (Object.keys(errorsOperator).length) {
      console.log('Validation errors:', errorsOperator);
      return;
    }
    const dataSubmit = {
      name: data.name || '',
      email: data.emailOrPhone || null,
      phone: data.phoneNumber || null,
      text: data.description || 'Help me',
    };
    if (dataSubmit) {
      setIsLoading(true)
      const { ok, message, status } = await supportProvider.support(dataSubmit);
      if (ok) {
        setOpenDialogSupport(false);
        setOpenDialogSuccess(true);
        // resetOperatorForm();
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false)
    }
  };

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{
          padding: { sm: '10px 30px', xs: '15px 12px' },
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <Stack direction='row' spacing={1}>
          
            <Box position='relative' display='inline-flex'>
              <CircularProgress
                variant='determinate'
                value={100}
                size={48}
                thickness={3}
                sx={{ color: '#2A3842', opacity: 0.5 }}
              />
              <CircularProgress
                variant='determinate'
                value={progress}
                size='48px'
                thickness={3}
                sx={{
                  color: '#1650FF',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant='caption'
                  component='div'
                  sx={{ color: '#2A3842', fontSize: '12px', fontWeight: '600' }}
                  // onClick={() => navigate('/')}
                >
                  {`${stepValue} of ${MAX_STEP}`}
                </Typography>
              </Box>
            </Box>
          
          <Stack>
            <Typography variant='h5' sx={typographyH5Style}>
              {headerName}
            </Typography>
            <Typography variant='body1' sx={typographyBody2Style}>
              {headerNext}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={boxInnerStyle}>
          <Button
            variant='contained'
            startIcon={<IconPhone />}
            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            sx={supportButtonStyle}
          >
            Support
          </Button>
          <Menu
            sx={{
              marginTop: '10px',
              '.MuiMenuItem-root': {
                display: 'flex',
                gap: '8px',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              sx={{ '&:hover': { fontWeight: 600, color: '#1650FF' } }}
              onClick={() => window.location.href = `tel:${supportPhone}`}
            >
              <IconPhoneMenu />
              Call us
            </MenuItem>
            <MenuItem
              sx={{ '&:hover': { fontWeight: 600, color: '#1650FF' } }}
              onClick={() => setOpenDialogSupport(true)}
            >
              <IconMessageMenu />
              Message us
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
      <SupportContentRenderer
        open={openDialogSupport}
        onClose={() => setOpenDialogSupport(false)}
        control={controlOperator}
        setValue={setValueOperatorForm}
        dialogData={dialogOperatorData}
        errors={errorsOperator}
        onSubmit={handleSubmitOperator(onSubmitOperatorForm)}
      />
      <MyDialog 
        open={openDialogSuccess} 
        onClose={() => setOpenDialogSuccess(false)} 
        dialogTitle={dialogOperatorSuccessData.dialogTitle.label} 
        width={dialogOperatorSuccessData.width}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack
            direction='row'
            justifyContent='center'
            display='flex'
          >
            <img 
              src='/Img/Pictures/operatorSuccess.svg'
              style={{width: '${content.customWidth}'}}
            />
          </Stack>
          <Typography 
            style={{textAlign: 'center', fontSize: '16px'}}
          >
            {dialogOperatorSuccessData.dialogContent[1].label}
          </Typography>
          
          <MyButton
            data={{ 
              buttonName: 'Close',
              variant: 'contained',
              customWidth: '358px',
              actionKey: 'closeOperatorSuccessAction',
              styleType: 'dialog',
            }}
            onClick={() => setOpenDialogSuccess(false)}
          />
        </Stack>
      </MyDialog>
    </>
  );
};
