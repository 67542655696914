/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './KeywordSearch.module.scss';
import debounce from '@/utils/debounce';

interface IKeywordSearchProps {
  disabled?: boolean;
  defaultKeywords?: string;
  placeholder?: string;
  handleChange: (value: any) => void;
  dataQa?: string;
}

export const KeywordSearch = ({
  placeholder,
  disabled = false,
  defaultKeywords,
  handleChange,
  dataQa,
}: IKeywordSearchProps) => {
  const [keywords, setKeywords] = useState(defaultKeywords || '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (defaultKeywords !== keywords) {
      setKeywords(defaultKeywords || '');
    }
  }, [defaultKeywords]);

  // const debouncedHandleChange = useCallback(
  //   debounce((value: string) => {
  //     if (value.length >= 3) {
  //       handleChange(value);
  //     }
  //   }, 700),
  //   [handleChange]
  // );

  // const handleSearchChange = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const newValue = event.target.value;
  //     setKeywords(newValue);
  //     debouncedHandleChange(newValue);
  //   },
  //   [debouncedHandleChange]
  // );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Paper
      component='form'
      className={styles.keywordSearchContainer}
      elevation={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '13px',
        padding: '0 10px',
        boxShadow: 'none',
        border: '1px solid rgb(233, 238, 241)',
        background: disabled ? '#f0f0f0' : '',
        position: 'relative',
      }}
    >
      {keywords.length === 0 && !disabled && !isFocused && (
        <SearchIcon
          sx={{ position: 'absolute', marginLeft: '10px', pointerEvents: 'none', opacity: 0.3 }}
        />
      )}
      <InputBase
        className={styles.input}
        placeholder={placeholder || 'Keywords'}
        inputProps={{ 'aria-label': 'search keywords' }}
        value={keywords}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          paddingLeft: keywords.length > 0 || isFocused ? '10px' : '40px',
          paddingTop: '6px',
          paddingBottom: '6px',
        }}
        data-qa={dataQa}
      />
    </Paper>
  );
};
