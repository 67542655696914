/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Avatar, Typography, Stack, Divider } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  UserRequestsModel,
  RequestMyModel,
  RequestsStatusEnum,
  NotificationTypeEnum,
} from '@/types';
import { dialogWarningData } from '../../AccountManagement/utils/dialogDescription';
import { MyButton, MyDialog } from '@/components';
import { stringAvatar, getKindLabel } from '@/utils';
import styles from './RequestsListItem.module.scss';
import IconCalendar from './iconCalendar.svg';
import IconClock from './iconClock.svg';
import { ReactComponent as IconUnread } from './iconUnread.svg';
import { propertyAdRequestProvider } from '@/providers';
import { appSetNotification, appGetRequestCount } from '@/store';
import { StyleLabel } from '../../uiComponents/styled';

export const RequestsListItem = ({
  item,
  page,
}: {
  item: UserRequestsModel;
  page: RequestMyModel | undefined;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCheckedRead, setIsCheckedRead] = useState(item.status === RequestsStatusEnum.Read);
  const [openDialog, setOpenDialog] = useState(false);

  const isCheckedOpChat = item.chanelKind === 'chat';

  const capitalizeFirstLetter = (string: string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const messageMainText = `Hello ${page?.propertyAdCreator?.name}! 
  I'd like more details about your ${page?.activityKind} listing: ${page?.title}, ${page?.convertedCurrency} ${page?.convertedPrice}, located ${page?.nameEn}.
  Can you provide me with more information? `;
  const messageChanelText = `Left a message in `;
  const chanelText = isCheckedOpChat ? 'OP Chat' : capitalizeFirstLetter(item.chanelKind);
  const userType = item.kind ? getKindLabel(item.kind) : '';

  const onHandleClick = async () => {
    if (!isCheckedRead) {
      const {
        ok,
        data,
        status: statusRequest,
        message,
      } = await propertyAdRequestProvider.patchRequest(item.requestId!, {
        status: 'read',
      });
      if (ok) {
        navigate(`/chat/${item.chatId}`);
        getAllUnreadCount();
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, statusRequest));
      }
    } else {
      navigate(`/chat/${item.chatId}`);
    }
  };

  const onHandleUnreadClick = async () => {
    const {
      ok,
      data,
      status: statusRequest,
      message,
    } = await propertyAdRequestProvider.patchRequest(item.requestId!, {
      status: 'unread',
    });
    if (ok) {
      setIsCheckedRead(false);
      getAllUnreadCount();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, statusRequest));
    }
  };

  const switchButtons = () => {
    switch (item.chanelKind) {
      case 'phone':
        if (item.additionalPhones) {
          window.location.href = `tel:${item.additionalPhones?.[0]}`;
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'whatsapp':
        if (item.whatsapp) {
          window.open(`https://wa.me/${item.whatsapp}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'telegram':
        if (item.telegram) {
          window.open(`https://t.me/${item.telegram}?start=${'message'}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'email':
        if (item.additionalEmails && item.name) {
          const subject = encodeURIComponent('question');
          window.location.href = `mailto:${item.additionalEmails?.[0]}?subject=${subject}`;
        } else {
          console.error('Email address not available!');
        }
        break;
      default:
        console.log(`Unknown action: ${item.chanelKind}`);
    }
  };

  const getAllUnreadCount = async () => {
    const { ok, data, status, message } = await propertyAdRequestProvider.getAllUnreadCount();
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    } else {
      dispatch(appGetRequestCount(data as number));
    }
  };

  const onHandleLinkClick = async () => {
    if (!isCheckedRead) {
      const {
        ok,
        data,
        status: statusRequest,
        message,
      } = await propertyAdRequestProvider.patchRequest(item.requestId!, {
        status: 'read',
      });
      if (ok) {
        setIsCheckedRead(true);
        switchButtons();
        getAllUnreadCount();
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, statusRequest));
      }
    } else {
      switchButtons();
    }
  };

  return (
    <div
      className={`${styles.listItem} ${
        !isCheckedRead ? styles.listItemUnread : styles.listItemRead
      }`}
    >
      <Stack direction='row' alignItems='center' spacing={1} style={{ position: 'relative' }}>
        <Link
          href={`/users/${item.userId}`}
          title={item.name}
          sx={{ textDecoration: 'none', color: '#2A3842' }}
        >
          <Avatar src={item.avatar || undefined} alt={item.name} {...stringAvatar(item.name)} />
        </Link>
        <Stack>
          <Link
            href={`/users/${item.userId}`}
            title={item.name}
            className={styles.itemName}
            sx={{ fontSize: '18px', fontWeight: '600', color: '#2A3842' }}
          >
            {item.name}
          </Link>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Link
              href={`/users/${item.userId}`}
              title={item.name}
              className={styles.itemName}
              sx={{ fontSize: '14px', opacity: 0.5, color: '#2A3842' }}
            >
              {userType}
            </Link>
            {item.employerName && <Divider sx={{ height: 20 }} orientation='vertical' />}
            {item.employerName && (
              <Link
                href={`/users/${item.employerId}`}
                title={item.employerName}
                className={styles.itemName}
                sx={{ fontSize: '14px', color: '#1650FF' }}
              >
                {item.employerName}
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Typography sx={{ fontSize: '15px', fontWeight: '400', whiteSpace: 'pre-wrap' }}>
          {isCheckedOpChat ? messageMainText : messageChanelText}
        </Typography>

        {!isCheckedOpChat && (
          <Link
            onClick={onHandleLinkClick}
            title={item.name}
            className={styles.chanelText}
            sx={{ color: '#1650FF' }}
          >
            <span style={{ fontWeight: 600, color: '#1650FF', cursor: 'pointer' }}>
              {chanelText}
            </span>
          </Link>
        )}
      </Stack>
      <div className={styles.dateContainer}>
        <div className={styles.dateBlock}>
          <div className={styles.date}>
            <img src={IconCalendar} alt='' className={styles.menuIcon} />
            <span>{moment(item.createdAt).format('D MMMM, YYYY')}</span>
          </div>
          <div className={styles.time}>
            <img src={IconClock} alt='' className={styles.menuIcon} />
            <span>{moment(item.createdAt).format('hh:mm a')}</span>
          </div>
        </div>
        <div className={styles.buttons}>
          {isCheckedRead && (
            <MyButton
              data={{
                styleType: 'unread',
                buttonName: 'Unread',
                variant: 'contained',
                customWidth: '124px',
                buttonsIcon: true,
                icon: <IconUnread />,
                label: 'chat',
              }}
              onClick={onHandleUnreadClick}
            />
          )}
          <MyButton
            data={{
              styleType: 'OP_CHAT',
              buttonName: 'OP Chat',
              variant: 'contained',
              customWidth: '124px',
              label: 'chat',
            }}
            onClick={() => onHandleClick()}
          />
        </div>
      </div>
      <MyDialog
        open={openDialog}
        dialogTitle={dialogWarningData.dialogTitle.label}
        width={dialogWarningData.width}
      >
        <StyleLabel style={{ textAlign: 'center', marginBottom: '15px' }}>
          {dialogWarningData.dialogTitle.description}
        </StyleLabel>
        <MyButton data={dialogWarningData.dialogContent[0]} onClick={() => setOpenDialog(false)} />
      </MyDialog>
    </div>
  );
};
