import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationTypeEnum, State } from '@/types';
import { authSetIsLoggedIn, appSetNotification } from '@/store';
import { Loader, Pagination } from '@/components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { styled } from '@mui/system';

import { Box, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { MyButton } from '@/components/uiComponents';

import { localizationsProvider } from '@/providers';

import { Comment } from './Components/Comment';

import { PaginationType } from '@/types';
import { useChangeQueryParams } from '@/hooks';

const StyledRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '12px',
  gap: '12px',
});

const ComplaintsBlock = styled('span')({
  color: '#2A3842',
  fontFamily: 'Poppins',
  fontSize: 28,
});

const ComplaintsBlockBlue = styled('span')({
  color: '#1650FF',
  fontSize: 28,
  fontFamily: 'Poppins',
  fontWeight: '600',
  wordWrap: 'break-word',
});

export const CommentsReport = (): JSX.Element => {
  const dispatch = useDispatch();

  const sortByList = [
    {
      key: 'created_at',
      label: 'Date of creation',
    },
    {
      key: 'id',
      label: 'ID',
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean | null>(false);
  const [limit, setLimit] = useState<number>(30);
  const [offset, setOffset] = useState<number>(0);

  const [sortBy, setSortBy] = useState(sortByList[0].key);

  const [itemsTotal, setItemsTotal] = useState<number>(0);

  const changeQueryParams = useChangeQueryParams('admin/comments-report');
  const [paginationObj, setPaginationObj] = useState<PaginationType>();
  const { search: queryParamsStr } = useLocation();
  const queryParams = queryString.parse(queryParamsStr);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div style={{ width: '100%' }}>
          <StyledRow>
            <ComplaintsBlock>
              Total complaints <ComplaintsBlockBlue>12</ComplaintsBlockBlue>
            </ComplaintsBlock>
            <Box
              sx={{
                width: {
                  sm: '166px',
                  marginLeft: 'auto',
                },
              }}
            >
              <Select value={sortBy}>
                {sortByList.map((field) => (
                  <MenuItem key={field.key} value={field.key} onClick={() => setSortBy(field.key)}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </StyledRow>

          <Comment />
          <Comment />
          <Comment />
          <Comment />
          <Comment />
        </div>
      )}
    </>
  );
};
