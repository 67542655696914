import { Ruler } from '@/assets/pdf';
import { Box, Button, Typography } from '@mui/material';

import classnames from 'classnames';

interface DataCardBlockProps {
  title: string;
  icon: string;
  data: string | undefined;
}

export const DataCardBlock = ({ title, icon, data }: DataCardBlockProps) => (
  <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <img src={icon} />
      <Typography color='#2A3842' fontSize='14px'>
        {title}
      </Typography>
    </Box>
    <Typography color='#1650FF' fontSize='16px' fontWeight='bold'>
      {data}
    </Typography>
  </Box>
);
