import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Link,
  Typography,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
import classnames from 'classnames';
import { appSetNotification } from '@/store';
import { newBuildingsProvider, offPlanProvider } from '@/providers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  State,
  NewBuildingModel,
  NotificationTypeEnum,
  BuildingsDataType,
  AdFieldBedsEnum,
  CreateNewPlanType,
} from '@/types';
import { getMediaGallery } from '../../Admin/OffPlanAdmin/OffPlanAdmin.utils';
import { photoUploaderProps } from '../../Admin/OffPlanAdmin/OffPlanAdmin.init';
import {
  SectionHeader,
  SectionTitle,
  FormInput,
  FormSelectCustom,
  MyButton,
  Loader,
} from '@/components';
import { UploadMedia, generateJsomToPostMedia } from '../../Admin/OffPlanAdmin/UploadMedia';
import { schemaNewPlanForm } from '@/schemas';
import IconArrow from './iconArrow.svg';
import { ReactComponent as CaretDown } from './caretDown.svg';
import styles from './CreatePlan.module.scss';

const MIN_PHOTOS = 1;

export const CreatePlan = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const [page, setPage] = useState<NewBuildingModel>();
  const [buildings, setBuildings] = useState<BuildingsDataType[]>();
  const [buildingPlan, setBuildingPlan] = useState<(string | null)[]>([]);
  const [plan, setPlan] = useState<CreateNewPlanType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [beds, setBeds] = useState<AdFieldBedsEnum | null>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, planId } = useParams<{ id: string; planId: string }>();
  const isEditPlan = !!planId;

  const getOffPlanData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getOffPlanById(id!);
    if (ok && data) {
      setPage(data as NewBuildingModel);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getPlanById = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getPlanById(id!, planId);
    if (ok && data) {
      setPlan(data as CreateNewPlanType);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  const getBuildingsData = async () => {
    setIsLoading(true);
    const { ok, data, status, message } = await newBuildingsProvider.getBuildings(id!);
    if (ok && data) {
      const { items } = data;
      setBuildings(items as BuildingsDataType[]);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && id) {
      getOffPlanData();
      getBuildingsData();
    }
    if (isEditPlan) {
      getPlanById();
    }
  }, []);

  const buildingsValues = buildings?.map((building) => {
    return { label: building.name, value: building.id };
  });

  const formMethods = useForm<CreateNewPlanType>({
    resolver: yupResolver(schemaNewPlanForm) as any,
    context: { isBuildingVisible: !!buildingsValues?.length },
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    if (plan && plan.beds) {
      reset({
        planCode: plan.planCode,
        buildingId: plan.buildingId,
        media: plan.media,
      });
      setBeds(plan.beds);
      setValue('beds', plan.beds);
    }
  }, [plan, reset]);

  const getLabelsForBuildingIds = (buildingIds: string[], buildings: BuildingsDataType[]) => {
    return buildingIds.map((id) => {
      const building = buildings.find((building: BuildingsDataType) => building.id === id);
      return building ? building.name : null;
    });
  };

  useEffect(() => {
    if (plan && buildings) {
      const buildingIds = plan?.buildingId || [];
      const newBuild = getLabelsForBuildingIds(buildingIds, buildings);
      setBuildingPlan(newBuild);
    }
  }, [plan, buildings]);

  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];

  const textBeds =
    beds === 'Studio' ? '' : beds === '8+' ? 'Beds' : Number(beds) > 1 ? 'Beds' : 'Bed';

  const bedsValueLabel = beds ? `${beds} ${textBeds}` : '';

  const handleBackClick = () => navigate(-1);

  const bedsErrorText = errors?.beds && errors?.beds.message;

  const handleChangeBeds = (value: AdFieldBedsEnum) => {
    const newValue = beds !== value ? value : null;
    setBeds(newValue);
    setValue('beds', newValue);
    clearErrors(['beds']);
  };

  useEffect(() => {
    const firstErrorElement = Object.keys(errors)[0];
    if (firstErrorElement) {
      console.error('Form errors:', errors);
      const elementForFocus = document.getElementsByClassName('Mui-error')[0];
      if (elementForFocus) {
        const offset =
          elementForFocus.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          150;
        window.scrollTo({
          behavior: 'smooth',
          top: offset,
        });
      }
    }
  }, [errors]);

  const submitHandler: SubmitHandler<CreateNewPlanType> = async (values) => {
    const { media, beds, planCode, buildingId } = values;
    if (media && media.length < MIN_PHOTOS) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'Upload at least 3 photos'));
      return;
    }
    let newData;
    if (!buildingId) {
      if (page) {
        setIsLoading(true);
        const { ok, data, status, message } = await newBuildingsProvider.createNewBuilding(
          id!,
          page?.title
        );
        if (!ok) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        } else {
          newData = {
            beds: beds,
            planCode: planCode,
            buildingId: [String(data)],
          };
        }
        setIsLoading(false);
      }
    } else {
      newData = {
        beds: beds,
        planCode: planCode,
        buildingId: buildingId,
      };
    }

    let response;
    let offPlanId;
    setIsLoading(true);
    if (Object.keys(errors).length === 0) {
      if (isEditPlan && id) {
        response = await newBuildingsProvider.editPlan(id, planId, newData as CreateNewPlanType);
        offPlanId = planId;
      } else {
        response = await newBuildingsProvider.createNewPlan(
          id!,
          newData as unknown as CreateNewPlanType
        );
        offPlanId = response?.data as string;
      }

      if (offPlanId && Number(offPlanId) > 0 && media) {
        const newMedia = generateJsomToPostMedia(media, offPlanId.toString());
        offPlanProvider.createMdeia(newMedia);
      }
    }
    const { ok, status, message } = response || {};
    if (!ok) {
      const mewMessage = status === 409 ? `Plan code ${planCode} already exists.` : message;
      dispatch(appSetNotification(NotificationTypeEnum.Error, mewMessage, status));
    } else {
      handleBackClick();
    }
    setIsLoading(false);
  };

  const handleBuildingChange = (event: SelectChangeEvent<typeof buildingPlan>) => {
    const selectedBuildingLabels = event.target.value as string[]; // Приведение типа для TypeScript
    const selectedBuildingIds = selectedBuildingLabels
      .map((label) => buildings?.find((building) => building.name === label)?.id || null)
      .filter((id): id is string => id !== null);

    setBuildingPlan(selectedBuildingLabels);
    setValue('buildingId', selectedBuildingIds);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <Box component='form' autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
          <SectionHeader title={!isEditPlan ? 'Create a new plan' : 'Edit plan'} />
          <Link
            onClick={handleBackClick}
            sx={{ textDecoration: 'none', color: '#2A3842', marginTop: '10px' }}
            className={styles.pageName}
          >
            <img src={IconArrow} alt='' style={{ cursor: 'pointer' }} />
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{page?.title}</Typography>
          </Link>
          <SectionTitle number={1} title='Information about the plan' />
          <div className={styles.fieldsContainer}>
            <FormSelectCustom
              label='Beds'
              value={bedsValueLabel}
              required
              errorText={bedsErrorText}
            >
              <div className={styles.floor}>
                <FormHelperText>
                  <span className={styles.floorHeading}>Beds</span>
                </FormHelperText>
                <div style={{ display: 'flex' }}>
                  {bedsValues.map(({ value, label }, index) => (
                    <Button
                      key={index}
                      className={classnames({
                        [styles.selectButtons]: true,
                        [styles.bedsBathsButton]: true,
                        [styles.selectButtonsActive]: beds === value,
                      })}
                      onClick={() => handleChangeBeds(value)}
                      variant='contained'
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              </div>
            </FormSelectCustom>
            <div className={styles.featuresContainer}>
              <p className={styles.label}>
                Plan code
                <span>*</span>
              </p>
              <FormInput
                uiVariant='blank'
                name='planCode'
                fullWidth
                placeholder='Enter plan code'
              />
            </div>
            {buildingsValues && buildingsValues?.length > 0 && (
              <div className={styles.featuresContainer}>
                <p className={styles.label}>
                  Choose building
                  <span>*</span>
                </p>
                <Select
                  name='buildingId'
                  multiple
                  displayEmpty
                  value={buildingPlan}
                  onChange={handleBuildingChange}
                  renderValue={(selected) => selected.join(', ')}
                  sx={{ width: '100%' }}
                  error={!!errors['buildingId']}
                  IconComponent={(_props) => {
                    const rotate = _props.className.toString().includes('iconOpen');
                    return (
                      <div
                        className={styles.caretDown}
                        style={{
                          transform: rotate ? 'rotate(180deg)' : 'none',
                        }}
                      >
                        <CaretDown />
                      </div>
                    );
                  }}
                >
                  {buildingsValues?.map((building) => (
                    <MenuItem key={building.value} value={building.label}>
                      <Checkbox
                        icon={<span />}
                        checkedIcon={<span className={styles.checked} />}
                        style={{ transform: 'scale(0.65)' }}
                        checked={buildingPlan && buildingPlan.includes(building.label)}
                      />
                      <ListItemText primary={building.label} />
                    </MenuItem>
                  ))}
                </Select>
                {!!errors['buildingId'] && (
                  <Typography sx={{ fontSize: '12px', color: 'red', textAlign: 'right' }}>
                    {errors['buildingId']?.message}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <UploadMedia
            uploadType={'image'}
            entityType='newConstructionPlan'
            formElement={'media'}
            sectionNumber={2}
            sectionTitle={'Upload apartment layout'}
            mediaGallery={getMediaGallery(getValues(), 'image') || []}
            offPlanId={planId ? planId : '01'}
            uploaderProps={photoUploaderProps}
          />
          <div className={styles.submitButtons}>
            <MyButton
              disabled={isLoading}
              data={{
                buttonName: 'Publish',
                customWidth: '155px',
                variant: 'contained',
                buttonType: 'submit',
              }}
              onClick={() => handleSubmit(submitHandler)}
            />
            <MyButton
              data={{
                buttonName: 'Cancel',
                customWidth: '157px',
                styleType: 'cancel',
                variant: 'outlined',
                buttonType: 'button',
              }}
              onClick={handleBackClick}
            />
          </div>
        </Box>
      </FormProvider>
    </div>
  );
};
