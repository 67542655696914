import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// import { State } from '@/types';   // JSX >>> TSX
import { authProvider } from '@/providers';
import { authLogin, appSetNotification, appSetLoading } from '@/store';
import { Loader, MyDialog } from '@/components';

import { useLogInForm, useResetPasswordForm, useCreatePasswordForm } from '../utils/useFormLogic';
import {
  dialogLogInData,
  dialogResetPasswordData,
  dialogConfirmationData,
  dialogCreatePasswordData,
  dialogSuccessData,
} from '../utils/dialogDescription';
import DialogContentRenderer  from '../Dialogs/DialogContentRenderer';
import Confirmation from '../Dialogs/Confirmation';
import { StyleLabel } from '../../uiComponents/styled';

const LogIn = ({ openDialog, handleOpenDialog }) => {
  const { code: inviteLinkCode } = useParams();
  const navigate = useNavigate();

  const [dialogDatas, setDialogDatas] = useState({
    dialogData: dialogLogInData,
    dialogResetData: dialogResetPasswordData,
  });

  const [emailOrPhoneInput, setEmailOrPhoneInput] = useState('');
  const [logInPhoneValue, setLogInPhoneValue] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [userDataSubmit, setUserDataSubmit] = useState(null);
  const [code, setCode] = useState('');

  useEffect(() => {
    setEmailOrPhoneInput(null);
  }, [logInPhoneValue]);

  useEffect(() => {
    setLogInPhoneValue(null);
  }, [emailOrPhoneInput]);


  const {
    handleSubmit: handleSubmitLogIn,
    errors: errorsLogIn,
    control: controlLogIn,
    resetForm: resetLogInForm,
    setValue: setValueLogin,
  } = useLogInForm(dialogDatas.dialogData);

  const {
    handleSubmit: handleSubmitResetPassword,
    errors: errorsResetPassword,
    control: controlResetPassword,
    resetForm: resetResetPasswordForm,
    setValue: setValueResetPassword,
  } = useResetPasswordForm(dialogDatas.dialogResetData);

  const {
    handleSubmit: handleSubmitCreatePassword,
    errors: errorsCreatePassword,
    control: controlCreatePassword,
    resetForm: resetCreatePasswordForm
  } = useCreatePasswordForm();

  const dispatch = useDispatch();
  // const { error } = useSelector(({ auth }: State) => auth);   // JSX >>> TSX
  const { notification } = useSelector(({ app }) => app);
  const { isLoading } = useSelector(({ app }) => app);

  useEffect(() => {
    if (notification?.status === 401 || notification?.status === 404) {
      setDialogDatas(prevState => ({
        ...prevState,
        dialogData: updateErrorVisibility(dialogLogInData, 'error'),
        dialogResetData: updateErrorVisibility(dialogResetPasswordData, 'error'),
      }));
    }
    if (notification?.status === 400) {
      setDialogDatas(prevState => ({
        ...prevState,
        dialogData: updateErrorVisibility(dialogLogInData, 'error400'),
        dialogResetData: updateErrorVisibility(dialogResetPasswordData, 'error400'),
      }));
    }
  }, [notification]);

  const updateErrorVisibility = (data, name) => {
    const updatedDialogContent = data.dialogContent.map(item => {
      if (item.name === name) {
        return {
          ...item,
          initialVisibility: true,
        };
      }
      return item;
    });
  
    return {
      ...data,
      dialogContent: updatedDialogContent,
    };
  }

/******************** Submit and close Log In ********************/
  const onSubmitLogIn = async (submitData) => {
    if (Object.keys(errorsLogIn).length) {
      console.log('Validation errors:', errorsLogIn);
      return;
    }
    const { emailOrPhone, password, phoneNumber } = submitData;
    const login = emailOrPhoneInput !== null ? emailOrPhone : phoneNumber;
    dispatch(appSetLoading(true));
    const { ok, data, status, message } = await authProvider.login(login, password);
    if (ok) {
      dispatch(authLogin(data));
      handleCloseLogIn();
      setEmailOrPhoneInput(null);
      setLogInPhoneValue(null);
      if (inviteLinkCode) {
        navigate(`/invitelink/${inviteLinkCode}`);
      } else {
        navigate('/');
      }
    } else {
      dispatch(appSetNotification('error', message, status));
    }
    dispatch(appSetLoading(false));
  };

  const handleCloseLogIn = () => {
    resetLogInForm();
    setEmailOrPhoneInput(null);
    handleOpenDialog('logIn');
    setDialogDatas(prevState => ({
      ...prevState,
      dialogData: dialogLogInData,
      dialogResetData: dialogResetPasswordData,
    }));
  };
/*************************************************************************/

/******************** Submit and close Reset Password ********************/  
  const onSubmitResetPass = async (submitData) => {
    if (Object.keys(errorsResetPassword).length) {
      console.log('Validation errors:', errorsResetPassword);
      return;
    }
    const { emailOrPhone, phoneNumber } = submitData;
    const login = emailOrPhoneInput !== null ? emailOrPhone : phoneNumber;
    const dataSubmit = {login: login || null};
    setUserDataSubmit(dataSubmit);
    dispatch(appSetLoading(true));
    const { ok, data, status, message } = await authProvider.postRecoverPasswordRequest(dataSubmit);
    if (ok) {
      handleOpenDialog('resetPassword');
      setOpenConfirmation(true);
    } else {
      status !== 406 ? dispatch(appSetNotification('error', message, status)) : setOpenError(true);
    }
    setEmailOrPhoneInput(null);
    setLogInPhoneValue(null);
    dispatch(appSetLoading(false));
  };

  const handleCloseResetPassword = () => {
    resetResetPasswordForm();
    setEmailOrPhoneInput(null);
    resetLogInForm();
    handleOpenDialog('resetPassword');
    setDialogDatas(prevState => ({
      ...prevState,
      dialogData: dialogLogInData,
      dialogResetData: dialogResetPasswordData,
    }));
  };
/*************************************************************************/

/********************  Submit and close Create Password ******************/
  const onSubmitCreatePass = async (data, event) => {
    if (Object.keys(errorsCreatePassword).length) {
      console.log('Validation errors:', errorsCreatePassword);
      return;
    }
    const { password } = data;
    const dataSubmit = { ...userDataSubmit, code, password };
    dispatch(appSetLoading(true));
    const { ok, message, status } = await authProvider.postRecoverPasswordChange(dataSubmit);
    if (ok) {
      handleCloseCreatePassword();
      handleOpenDialog('success');
    } else {
      dispatch(appSetNotification('error', message, status));
    }
    dispatch(appSetLoading(false));
  };

  const handleCloseCreatePassword = () => {
    resetCreatePasswordForm();
    resetResetPasswordForm();
    resetLogInForm();
    handleOpenDialog('createPassword');
  };
/*************************************************************************/

  const handleCloseConfirm = () => {
    setEmailOrPhoneInput(null);
    resetCreatePasswordForm();
    resetResetPasswordForm();
    resetLogInForm();
    setOpenConfirmation(false);
  };

  const toggleVisibility = (state, name, visibility) => {
    state.dialogData.dialogContent.find(item => item.name === name).initialVisibility = visibility;
    state.dialogResetData.dialogContent.find(item => item.name === name).initialVisibility = visibility;
  };
  
  const handleInputChange = (value, name) => {
    let newValue = value.replace(/\s/g, '');
    const isNumber = /^\d{3,}$/.test(newValue);
    const isContainsLetter = /[a-zA-Z]/.test(newValue);
  
    setDialogDatas(prevState => {
      const newState = { ...prevState };
      if (name === 'emailOrPhone') {
        toggleVisibility(newState, 'emailOrPhone', !isNumber);
        toggleVisibility(newState, 'phoneNumber', isNumber);
        setEmailOrPhoneInput(isNumber ? '+971' + newValue : newValue);
      } else if (name === 'phoneNumber') {
        if (!isNumber || isContainsLetter) {
          toggleVisibility(newState, 'emailOrPhone', true);
          toggleVisibility(newState, 'phoneNumber', false);
          setEmailOrPhoneInput(newValue);
        }
      }
      return newState;
    });
  };

  const handleInputClick = (e) => {
    if (
      dialogDatas.dialogData.dialogContent.some(item => item.name === 'error' && item.initialVisibility === true) ||
      dialogDatas.dialogResetData.dialogContent.some(item => item.name === 'error' && item.initialVisibility === true)
    ) {
      setDialogDatas(prevState => ({
        ...prevState,
        dialogData: dialogLogInData,
        dialogResetData: dialogResetPasswordData,
      }));
    }
  };

  const onErrorClose = () => {
    setOpenError(false);
    handleCloseResetPassword();
  };

  return (
    <>
    {isLoading && <Loader />}
    <DialogContentRenderer
      open={openDialog.logIn} 
      onClose={handleCloseLogIn}
      control={controlLogIn}
      onSubmit={handleSubmitLogIn(onSubmitLogIn)}
      dialogData={dialogDatas.dialogData}
      errors={errorsLogIn}
      onChange={handleInputChange}
      onClick={handleInputClick}
      handleOpenDialog={handleOpenDialog}
      emailOrPhoneInput={emailOrPhoneInput}
      setLogInPhoneValue={setLogInPhoneValue}
      setValue={setValueLogin}
    />
    <DialogContentRenderer
      open={openDialog.resetPassword}
      onClose={handleCloseResetPassword}
      control={controlResetPassword}
      onSubmit={handleSubmitResetPassword(onSubmitResetPass)}
      dialogData={dialogDatas.dialogResetData}
      onChange={handleInputChange}
      onClick={handleInputClick}
      errors={errorsResetPassword}
      handleOpenDialog={handleOpenDialog}
      emailOrPhoneInput={emailOrPhoneInput}
      setLogInPhoneValue={setLogInPhoneValue}
      setValue={setValueResetPassword}
    />
    <DialogContentRenderer
      open={openDialog.createPassword}
      onClose={handleCloseCreatePassword}
      control={controlCreatePassword}
      onSubmit={handleSubmitCreatePassword(onSubmitCreatePass)}
      dialogData={dialogCreatePasswordData}
      errors={errorsCreatePassword}
      handleOpenDialog={handleOpenDialog}
    />
    <Confirmation 
      open={openConfirmation} 
      onClose={handleCloseConfirm}
      handleOpenDialog={handleOpenDialog}
      dialogData={dialogConfirmationData}
      emailOrPhoneInput={emailOrPhoneInput}
      userDataSubmit={userDataSubmit}
      type='loginResetPassword'
      setCode={setCode}
    />
    <DialogContentRenderer
      open={openDialog.success}
      handleOpenDialog={handleOpenDialog}
      dialogData={dialogSuccessData}
    />
    <MyDialog 
      open={openError}
      onClose={onErrorClose}
      dialogTitle='Error'
      width='500'
    >
      <StyleLabel style={{ textAlign: 'center', color: 'red' }}> 
        You will be able to request a new code in 2 minutes
      </StyleLabel>
    </MyDialog>
  </>
  );
};

export default LogIn;
