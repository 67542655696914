import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Avatar, Typography, Stack, Divider } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  UserRequestsModel,
  RequestMyModel,
  RequestsStatusEnum,
  UserModel,
  NotificationTypeEnum,
} from '@/types';
import { dialogWarningData } from '../../AccountManagement/utils/dialogDescription';
import { MyButton, MyDialog, Loader } from '@/components';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { stringAvatar, getKindLabel } from '@/utils';
import styles from './RequestsListItem.module.scss';
import IconCalendar from './iconCalendar.svg';
import IconClock from './iconClock.svg';
import { StyleLabel } from '../../uiComponents/styled';

export const RequestsListItem = ({
  item,
  page,
}: {
  item: UserRequestsModel;
  page: RequestMyModel | undefined;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCheckedRead, _setIsCheckedRead] = useState(item.status === RequestsStatusEnum.Read);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserModel>();

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      if (page?.propertyAdCreator?.id) {
        const { ok, data, status, message } = await usersProvider.getUserById(
          page?.propertyAdCreator?.id
        );
        if (ok && data) {
          setUser(data as UserModel);
        } else {
          dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
        }
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const isCheckedOpChat = item.chanelKind === 'chat';

  const capitalizeFirstLetter = (string: string) => {
    return string
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const messageMainText = `Hello ${page?.propertyAdCreator?.name}! 
  I'd like more details about your ${page?.activityKind} listing: ${page?.title}, ${page?.convertedCurrency} ${page?.convertedPrice}, located ${page?.nameEn}.
  Can you provide me with more information? `;
  const messageChanelText = `Left a message in `;
  const chanelText = isCheckedOpChat ? 'OP Chat' : capitalizeFirstLetter(item.chanelKind);
  const userType = page?.propertyAdCreator?.kind ? getKindLabel(page?.propertyAdCreator?.kind) : '';

  const onHandleLinkClick = () => {
    switch (item.chanelKind) {
      case 'phone':
        if (user?.additionalPhones) {
          window.location.href = `tel:${user?.additionalPhones?.[0]}`;
        } else {
          console.error('Phone number not available!');
        }
        break;
      case 'whatsapp':
        if (user?.whatsapp) {
          window.open(`https://wa.me/${user?.whatsapp}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'telegram':
        if (user?.telegram) {
          window.open(`https://t.me/${user?.telegram}?start=${'message'}`, '_blank');
        } else {
          setOpenDialog(true);
        }
        break;
      case 'email':
        if (user?.additionalEmails && user?.name) {
          const subject = encodeURIComponent('question');
          window.location.href = `mailto:${user?.additionalEmails?.[0]}?subject=${subject}`;
        } else {
          console.error('Email address not available!');
        }
        break;
      default:
        console.log(`Unknown action: ${item.chanelKind}`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={`${styles.listItem} ${
        !isCheckedRead ? styles.listItemUnread : styles.listItemRead
      }`}
    >
      <Stack direction='row' alignItems='center' spacing={1} style={{ position: 'relative' }}>
        <Link
          href={`/users/${page?.propertyAdCreator?.id}`}
          title={item.name}
          sx={{ textDecoration: 'none', color: '#2A3842' }}
        >
          <Avatar
            src={page?.propertyAdCreator?.avatar || undefined}
            alt={page?.propertyAdCreator?.name}
            {...stringAvatar(page?.propertyAdCreator?.name)}
          />
        </Link>
        <Stack>
          <Link
            href={`/users/${page?.propertyAdCreator?.id}`}
            title={item.name}
            className={styles.itemName}
            sx={{ fontSize: '18px', fontWeight: '600', color: '#2A3842' }}
          >
            {page?.propertyAdCreator?.name}
          </Link>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Link
              href={`/users/${page?.propertyAdCreator?.id}`}
              title={item.name}
              className={styles.itemName}
              sx={{ fontSize: '14px', opacity: 0.5, color: '#2A3842' }}
            >
              {userType}
            </Link>
            {page?.propertyAdCreatorEmployer?.name && (
              <Divider sx={{ height: 20 }} orientation='vertical' />
            )}
            {page?.propertyAdCreatorEmployer?.name && (
              <Link
                href={`/users/${page?.propertyAdCreatorEmployer?.id}`}
                title={page?.propertyAdCreatorEmployer?.name}
                className={styles.itemName}
                sx={{ fontSize: '14px', color: '#1650FF' }}
              >
                {page?.propertyAdCreatorEmployer?.name}
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Typography sx={{ fontSize: '15px', fontWeight: '400', whiteSpace: 'pre-wrap' }}>
          {isCheckedOpChat ? messageMainText : messageChanelText}
        </Typography>

        {!isCheckedOpChat && (
          <Link
            onClick={onHandleLinkClick}
            title={item.name}
            className={styles.chanelText}
            sx={{ color: '#2A3842' }}
          >
            <span style={{ fontWeight: 600, color: '#1650FF', cursor: 'pointer' }}>
              {chanelText}
            </span>
          </Link>
        )}
      </Stack>
      <div className={styles.dateContainer}>
        <div className={styles.dateBlock}>
          <div className={styles.date}>
            <img src={IconCalendar} alt='' className={styles.menuIcon} />
            <span>{moment(item.createdAt).format('D MMMM, YYYY')}</span>
          </div>
          <div className={styles.time}>
            <img src={IconClock} alt='' className={styles.menuIcon} />
            <span>{moment(item.createdAt).format('hh:mm a')}</span>
          </div>
        </div>
        <div className={styles.buttons}>
          <MyButton
            data={{
              styleType: 'OP_CHAT',
              buttonName: 'OP Chat',
              variant: 'contained',
              customWidth: '124px',
              label: 'chat',
            }}
            onClick={() => navigate(`/chat/${user?.chatId}`)}
          />
        </div>
      </div>
      <MyDialog
        open={openDialog}
        dialogTitle={dialogWarningData.dialogTitle.label}
        width={dialogWarningData.width}
      >
        <StyleLabel style={{ textAlign: 'center', marginBottom: '15px' }}>
          {dialogWarningData.dialogTitle.description}
        </StyleLabel>
        <MyButton data={dialogWarningData.dialogContent[0]} onClick={() => setOpenDialog(false)} />
      </MyDialog>
    </div>
  );
};
