import { useState } from 'react';
import { FormControl, MenuItem, FormHelperText, SxProps } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './FormSelect.module.scss';
import { ReactComponent as CaretDown } from './CaretDown.svg';

//! TODO: component must be refactored

interface DataList {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export const FormSelect = ({
  name,
  data,
  handleChange,
  uiVariant,
  placeholder,
  labelWeight,
  ...otherProps
}: {
  name: string;
  data: DataList[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (value: any) => void;
  uiVariant?: 'blank';
  labelWeight?: boolean;
} & SelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [selectedValue, setSelectedValue] = useState<any>(otherProps?.defaultValue || '');

  const defaultStyles: SxProps =
    uiVariant === 'blank' ? { width: '100%' } : { width: '100%', margin: '0 20px 0 0' };

  return (
    <FormControl sx={defaultStyles} error={!!errors[name]}>
      {otherProps.label && (
        <p className={`${labelWeight ? styles.labelWeight : styles.label}`}>
          {otherProps.label}
          {otherProps.required && <span>*</span>}
        </p>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={name === 'buildingId' ? '' : otherProps?.defaultValue || 'a'}
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{
              borderRadius: '14px',
              border: `1px solid ${errors[name] ? '#FF0000' : '#E9EEF1'}`,
            }}
            // placeholder={placeholder}
            className={styles.fieldset}
            IconComponent={(_props) => {
              const rotate = _props.className.toString().includes('iconOpen');
              return (
                <div
                  className={styles.caretDown}
                  style={{
                    transform: rotate ? 'rotate(180deg)' : 'none',
                  }}
                >
                  <CaretDown />
                </div>
              );
            }}
            value={value}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              onChange(e);
              if (handleChange) {
                handleChange(e.target.value);
              }
            }}
            onClose={(e) => {
              const target = e.target as HTMLElement;
              const value = target.dataset.value;
              if (name === 'creator' && selectedValue === value) {
                const newValue = '';
                setSelectedValue(newValue);
                onChange(newValue);
                if (handleChange) {
                  handleChange(newValue);
                }
              }
            }}
          >
            {data.map(({ value, label }: DataList, index: number) => (
              <MenuItem
                divider={data.length - 1 !== index}
                sx={{
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: 'capitalize',
                }}
                key={index}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {!!errors[name] && <FormHelperText>{errors[name]?.message?.toString()}</FormHelperText>}
    </FormControl>
  );
};
