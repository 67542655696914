import {
  AppState,
  ActionsTypes,
  AppNotificationPayloadType,
  RequestGetCountOfUnreadResponseType,
} from '@/types';
import { APP_SET_LOADING, APP_SET_NOTIFICATION, APP_GET_REQUEST_COUNT_OF_UNREAD } from '@/store';

const initialState: AppState = {
  isLoading: false,
  notification: {
    type: undefined,
    message: undefined,
    status: undefined,
  },
  countOfUnread: null,
};

const appSetLoading = (state: AppState, isLoading: boolean): AppState => ({
  ...state,
  isLoading,
});

const appSetNotification = (
  state: AppState,
  { type, status, message }: AppNotificationPayloadType
): AppState => ({
  ...state,
  notification: {
    ...state.notification,
    type,
    message,
    status,
  },
});

const appGetRequestCount = (state: AppState, countOfUnread: number | null): AppState => ({
  ...state,
  countOfUnread,
});

export const appReducer = (state = initialState, action: ActionsTypes) => {
  switch (action.type) {
    case APP_SET_LOADING:
      return appSetLoading(state, action.payload as boolean);
    case APP_SET_NOTIFICATION:
      return appSetNotification(state, action.payload as AppNotificationPayloadType);
    case APP_GET_REQUEST_COUNT_OF_UNREAD:
      return appGetRequestCount(state, action.payload as number | null);
    default:
      return state;
  }
};
