import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, TextField } from '@mui/material';

import { NotificationTypeEnum, TeamGetLinkResponseType } from '@/types';
import { teamProvider } from '@/providers';
import { appSetNotification } from '@/store';
import { MyButton, MyDialog } from '@/components';

import styles from './InvitationLink.module.scss';

export const InvitationLink = () => {
  const dispatch = useDispatch();
  const [linkValue, setLinkValue] = useState<string>('');
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

  useEffect(() => {
    const getLink = async () => {
      const { ok, data, status, message } = await teamProvider.getLink();
      if (ok) {
        const { link } = data as TeamGetLinkResponseType;
        const url = `${window.location.origin}/invitelink/${link}`;
        setLinkValue(url);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
    };
    getLink();
  }, []);

  const handleCloseDialog = () => setIsDialogOpened(false);

  const handleCopyLink = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(linkValue);
    } else {
      document.execCommand('copy', true, linkValue);
    }
  };

  const handleRefreshLink = async () => {
    const { ok, data, status, message } = await teamProvider.refreshLink();
    if (ok) {
      const { link } = data as TeamGetLinkResponseType;
      const url = `${window.location.origin}/invitelink/${link}`;
      setLinkValue(url);
      handleCloseDialog();
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  return (
    <div>
      <div className={styles.linkInputBlock}>
        <TextField className={styles.linkInput} placeholder='Your link' value={linkValue} />
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '145px',
            },
          }}
        >
          <MyButton
            data={{
              buttonName: 'Copy',
              styleType: 'button',

              variant: 'contained',
            }}
            onClick={handleCopyLink}
          />
        </Box>
      </div>
      <div className={styles.linkRefresh}>
        Would you like to change the invitation link?
        <span onClick={() => setIsDialogOpened(true)}>Yes</span>
      </div>

      <MyDialog
        open={isDialogOpened}
        onClose={handleCloseDialog}
        dialogTitle='Are you sure you want to create a new invite link?'
        width='750'
      >
        <div className={styles.dialogDescription}>
          After creating a new invitation link, the old one will be inactive and stop working. Are
          you sure you want to deactivate the old link and create a new one?
        </div>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'button',
            }}
            onClick={handleRefreshLink}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              styleType: 'cancel',
              buttonType: 'button',
            }}
            onClick={handleCloseDialog}
          />
        </div>
      </MyDialog>
    </div>
  );
};
