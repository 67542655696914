const separator = ',';

const reverseString = (str: string): string => {
  let reversedStr = '';
  for (let i = 0; i < str.length; i++) {
    reversedStr = str[i] + reversedStr;
  }

  return reversedStr;
};

export const priceFormat = (priceStr: string | number): string => {
  const str = String(priceStr);
  const numParts = str.split('.');
  let integerPart = numParts[0];
  const decimalPart = numParts[1];
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

export const classifyPrice = (price: string | number): string => {
  let classifiedPrice = String(price);
  const lastIndex = classifiedPrice.length;
  if (classifiedPrice.length > 6 && classifiedPrice.slice(-6) === '000000') {
    classifiedPrice = `${classifiedPrice.slice(0, lastIndex - 6)}M`;
  } else if (classifiedPrice.length > 3 && classifiedPrice.slice(-3) === '000') {
    classifiedPrice = `${classifiedPrice.slice(0, lastIndex - 3)}K`;
  }

  return classifiedPrice;
};
